import { showError } from '@components/app-error';
import { Button } from '@components/buttons';
import { completeCourseStep } from '@course/components/course-checklist';
import { showModalForm } from '@components/modal-form';
import { AppRouter, useRouter } from '@components/router';
import { showToast } from '@components/toaster';
import { Toggle } from '@components/toggle';
import { evt } from 'client/lib/app-evt';
import { rpx } from 'client/lib/rpx-client';
import { coursePrices } from 'shared/urls';
import { useEffect, useState } from 'preact/hooks';
import { CourseStatus, FullCourse, TenantRow } from 'server/types';
import { showAccessFormatModal } from '@course/components/access-format-radios';
import { courseLabel } from 'shared/terminology';
import { useCurrentTenant } from '@components/router/session-context';
import { Dialog } from '@components/dialog';

type CallbackArgs = {
  courseId: UUID;
  status: CourseStatus;
  askForAccessFormat: boolean;
};

const ENROLLMENT_STATUS_EVENT = '$enrollment_status_event';

export function dispatchNewCourseStatus(args: CallbackArgs) {
  evt.emit(ENROLLMENT_STATUS_EVENT, args);
}

export function onCourseStatusChange(courseId: UUID, callback: (args: CallbackArgs) => void) {
  return evt.on((name, args: CallbackArgs) => {
    if (name === ENROLLMENT_STATUS_EVENT && args.courseId === courseId) {
      callback(args);
    }
  });
}

export async function toggleCourseEnrollmentStatus({
  course,
  tenant,
  showMessage = false,
  askForAccessFormat = true,
  router,
}: {
  course: Pick<FullCourse, 'id' | 'status' | 'isBundle'>;
  tenant: Pick<TenantRow, 'terminology'>;
  showMessage?: boolean;
  askForAccessFormat?: boolean;
  router: AppRouter;
}) {
  try {
    const shouldPublish = course.status === 'draft';

    if (shouldPublish) {
      await rpx.courses.publishCourse({ id: course.id });
    } else {
      await rpx.courses.unpublishCourse({ id: course.id });
    }

    const newStatus = shouldPublish ? 'published' : 'draft';
    dispatchNewCourseStatus({ courseId: course.id, status: newStatus, askForAccessFormat });

    if (shouldPublish) {
      completeCourseStep(router, 'publishCourse');
    }

    if (showMessage) {
      const terminologyCourse = courseLabel({
        course,
        tenant,
      });
      showToast({
        type: 'ok',
        title: `${terminologyCourse} status updated`,
        message: `${terminologyCourse} status is set to ${newStatus} successfully.`,
      });
    }
  } catch (err) {
    // This means the course cannot be published, because there are no
    // active price points, so we'll show a specific modal for that.
    if (err.data === 'pricepoint') {
      showPricePointRequiredModal(course);
    } else {
      showError(err);
    }
  }
}

export function CourseEnrollmentToggle({
  course,
  dontAskForAccessFormat,
  padding = 'p-2 py-1',
  class: className,
}: {
  course: Pick<
    FullCourse,
    'id' | 'title' | 'status' | 'accessFormat' | 'isAbsoluteSchedule' | 'isBundle' | 'isProduct'
  >;
  dontAskForAccessFormat?: boolean;
  padding?: string;
  class?: string;
}) {
  const tenant = useCurrentTenant();
  const router = useRouter();
  const [isSaving, setIsSaving] = useState(false);
  const [status, setStatus] = useState(course.status);

  useEffect(
    () =>
      onCourseStatusChange(course.id, ({ status, askForAccessFormat }) => {
        setStatus(status);
        if (!course.isProduct && !course.isBundle && status === 'published' && askForAccessFormat) {
          showAccessFormatModal(course);
        }
      }),
    [],
  );

  async function togglePublished() {
    setIsSaving(true);
    try {
      await toggleCourseEnrollmentStatus({
        router,
        course: {
          ...course,
          status,
        },
        tenant,
        askForAccessFormat: !dontAskForAccessFormat,
      });
    } finally {
      setIsSaving(false);
    }
  }

  return (
    <label
      class={`${
        className || ''
      } ${padding} inline-flex items-center gap-2 cursor-pointer hover:bg-white/20 rounded-md`}
    >
      <span>Signups open</span>
      <Toggle checked={status === 'published'} disabled={isSaving} onClick={togglePublished} />
    </label>
  );
}

/**
 * showPricePointRequiredModal displays a modal indicating that the course
 * cannot be published without first creating a price point.
 */
export function showPricePointRequiredModal(course: Pick<FullCourse, 'id' | 'isBundle'>) {
  showModalForm(({ resolve }) => {
    const tenant = useCurrentTenant();
    const router = useRouter();
    const terminologyCourse = courseLabel({
      course,
      tenant,
    });
    const hide = () => resolve(undefined);
    const publishFreeCourse = async () => {
      try {
        const result = await rpx.prices.initForCourse({
          courseId: course.id,
        });
        const price = await rpx.prices.createPrice({
          productId: result.product.id,
          currency: 'USD',
          name: 'Free',
          paymentType: 'free',
          isCorePrice: false,
          priceInCents: 0,
          isEnabled: true,
        });
        await rpx.courses.publishCourse({ id: course.id });
        completeCourseStep(router, 'publishCourse');
        router.goto(
          coursePrices.priceUrl({
            courseId: course.id,
            priceId: price.id,
          }),
        );
        hide();
      } catch (err) {
        showError(err);
      }
    };
    return (
      <Dialog
        mode="info"
        onClose={hide}
        title="Price point required"
        confirmButtonText="Go to pricing page"
        onSubmit={async () => {
          router.goto(coursePrices.pricesUrl({ courseId: course.id }));
          hide();
        }}
      >
        <p>
          Before publishing a {terminologyCourse}, you need to create at least one price point, or
          you can{' '}
          <Button class="text-indigo-700" onClick={publishFreeCourse}>
            publish a free {terminologyCourse}
          </Button>
          .
        </p>
      </Dialog>
    );
  });
}
