import { AutosizeText } from '@components/autosize-text';
import { RpxResponse, rpx } from 'client/lib/rpx-client';
import {
  cardMiddleware,
  defaultToolbarActions,
  minidoc,
  minidocToolbar,
  on,
  placeholder,
} from 'minidoc-editor';
import { toolbarDropdown } from '@components/toolbar-dropdown';
import { redoArrow, undoArrow } from '@components/icons';
import { generateMediaCard, mediaMiddleware } from '@components/media-card';
import { rawHtmlCard, embedHtmlCard } from '@components/raw-html-card';
import { useIntl } from 'shared/intl/use-intl';
import { useState, useMemo } from 'preact/hooks';
import { EditorWrapper } from '@components/minidoc/minidoc-root';
import { ManualDom } from '@components/manual-dom';
import { delayedDebounce } from 'client/utils/debounce';
import { useBasicAutosaver } from '@components/autosaver';
import { DownloadsEditor } from '../guide-course-modules/downloads';
import { Downloads } from 'server/types';

export type Content = RpxResponse<typeof rpx.lessons.getLesson>;
export type ContentSetter = (fn: (content: Content) => Content) => void;

export const emptyDownloads: Downloads = { title: '', files: [] };

export function ContentEditor({
  courseId,
  content,
  setContent,
}: {
  courseId: string;
  content: Content;
  setContent: ContentSetter;
}) {
  const intl = useIntl();
  const [state, setState] = useState(() => ({
    id: content.id,
    content: content.content,
    title: content.title,
    downloads: content.downloads,
  }));
  const [toolbarEnabled, setToolbarEnabled] = useState(true);
  const editor = useMemo(() => {
    const editor = minidoc({
      doc: content.content,
      middleware: [
        placeholder(
          `Add the information you’d like your students to learn here! You can type text, choose formatting, or insert images, videos and documents.`,
        ),
        minidocToolbar([
          {
            id: 'undo',
            label: 'Undo',
            html: undoArrow(),
            init() {},
            run() {
              editor.undo();
            },
          },
          {
            id: 'redo',
            label: 'Redo',
            html: redoArrow(),
            init() {},
            run() {
              editor.redo();
            },
          },
          ...defaultToolbarActions.filter((x) => x.id !== 'h1'),
          {
            id: 'hr_insert',
            label: 'Horizontal Rule',
            html: `<span>━</span>`,
            init() {},
            run() {
              const root = editor.root;
              root.focus();
              const range = document.getSelection()?.getRangeAt(0);
              if (!range) {
                return;
              }
              let leaf = range?.startContainer as HTMLElement | null;
              while (leaf && leaf.parentElement !== root) {
                leaf = leaf.parentElement;
              }
              if (leaf) {
                leaf.insertAdjacentHTML('beforebegin', '<hr />');
              }
            },
          },
          toolbarDropdown({
            intl,
          }),
        ]),
        cardMiddleware([
          generateMediaCard({
            shouldRenderPdfViewer: true,
          }),
          rawHtmlCard,
          embedHtmlCard,
        ]),
        mediaMiddleware(),
      ],
    });
    let prevDoc = '';
    setTimeout(() => {
      prevDoc = editor.serialize(true);
    });
    on(
      editor.root,
      'mini:change',
      delayedDebounce(() => {
        const doc = editor.serialize(true);
        if (doc !== prevDoc) {
          prevDoc = doc;
          setState((s) => ({ ...s, content: doc }));
        }
      }, 200),
    );
    return editor;
  }, []);

  const autosaver = useBasicAutosaver(state, async (opts) => {
    await rpx.lessons.saveLesson({
      id: opts.id,
      content: opts.content,
      title: opts.title,
      courseId,
      isPrerequisite: false,
      downloads: state.downloads,
    });
  });

  return (
    <section class="flex flex-col gap-10 pb-40">
      <div
        class={`sticky top-0 bg-gray-100 rounded-xl sm:rounded-full flex-col sm:flex-row lesson-editor-toolbar flex sm:items-center z-20 px-2 md:whitespace-nowrap justify-center`}
      >
        <ManualDom el={editor.toolbar.root} />
        {!toolbarEnabled && <div class="absolute inset-0 bg-gray-50/50 z-20 rounded-full"></div>}
        <span class="text-right text-xs absolute hidden md:flex items-center inset-y-0 right-4 text-gray-400 z-20">
          {autosaver.isDirty ? 'saving...' : 'saved'}
        </span>
      </div>

      <div class="flex flex-col gap-4 w-2xl max-w-full mx-auto">
        <AutosizeText
          containerClass="text-3xl font-semibold font-studentcontent leading-snug w-full"
          focusSelf
          onFocusInCapture={() => setToolbarEnabled(false)}
          onFocusOutCapture={() => setToolbarEnabled(true)}
          class="border-0 p-0 ring-transparent!important focus:ring-transparent"
          placeholder="Title"
          onInput={(e: any) => {
            setContent((s) => ({ ...s, title: e.target.value }));
            setState((s) => ({ ...s, title: e.target.value }));
          }}
          value={state.title}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              e.preventDefault();
              editor.root.focus();
            } else if (e.code === 'ArrowDown') {
              setTimeout(() => {
                // This is a hacky approximation of detecting when the
                // user presses the down arrow on the last line of the
                // textarea. It's not 100%, but it's better than nothing.
                const ta = e.target as HTMLTextAreaElement;
                if (ta.selectionStart === ta.value.length) {
                  editor.root.focus();
                }
              });
            }
          }}
        />

        <EditorWrapper
          editor={editor}
          class="leading-7 font-studentcontent text-base w-full cursor-text text-gray-700"
        />

        <DownloadsEditor
          isVisible
          downloads={state.downloads || emptyDownloads}
          setDownloads={(args) => {
            setState((s) => ({
              ...s,
              downloads: typeof args === 'function' ? args(s.downloads || emptyDownloads) : args,
            }));
          }}
          editor={editor}
        />
      </div>
    </section>
  );
}
