import { on, h as htm, MinidocCore } from 'minidoc-editor';
import { useState, useEffect, useRef } from 'preact/hooks';
import { UserProfileIcon } from '@components/avatars';
import { Button } from '@components/buttons';
import { useAsyncData } from 'client/lib/hooks';
import { rpx } from 'client/lib/rpx-client';

interface MentionedUser {
  id: string;
  name: string;
  profilePhotoUrl?: string;
}

interface Coords {
  x: number;
  y: number;
  startOffset: number;
  startContainer?: Node;
}

/**
 * Determine if the user has just typed an ampersand that should open up
 * a mention autocomplete UI.
 */
function isLeadingAmpersand(e: InputEvent) {
  // This event belongs to an input other than a rich text editor.
  if (!(e.target as any).$editor) {
    return false;
  }
  if (e.data !== '@') {
    return false;
  }
  const sel = document.getSelection();
  if (!sel?.isCollapsed || !sel?.anchorNode) {
    return false;
  }
  // We're at the start of our containing element.
  if (sel.anchorOffset === 0) {
    return true;
  }
  const rng = document.createRange();
  rng.setStart(sel.anchorNode, sel.anchorOffset - 1);
  rng.setEnd(sel.anchorNode, sel.anchorOffset);
  const content = rng.cloneContents().textContent || '';
  // Return whether or not the caret is preceeded by a space
  return /^\s/.test(content);
}

function isBackspaceIntoAmpersand(e: KeyboardEvent) {
  if (e.key !== 'Backspace') {
    return false;
  }
  const sel = document.getSelection();
  if (
    !sel?.isCollapsed ||
    !sel?.anchorNode ||
    !(sel.anchorNode instanceof Text) ||
    !sel.anchorOffset
  ) {
    return false;
  }
  const text = (sel.anchorNode as Text).textContent?.[sel.anchorOffset - 2];
  return text === '@';
}

/**
 * Get the screen coordinates of the current caret.
 */
function caretCoords(): Coords | undefined {
  // This is hacky, but there does not appear to be a clean way to do this.
  // What we do is insert a 0-width element into the DOM at the current
  // caret position, take the bounds of that element, and remove the element.
  const el = document.createElement('span');
  el.style.display = 'inline-block';
  el.style.width = '0';
  const rng = document.getSelection()?.getRangeAt(0)?.cloneRange();
  if (!rng) {
    return;
  }
  rng.insertNode(el);
  const bounds = {
    x: el.offsetLeft,
    y: el.offsetTop,
  };
  el.remove();
  return {
    ...bounds,
    startOffset: rng.startOffset,
    startContainer: rng.startContainer,
  };
}

/**
 * Get a Range starting at the opening ampersand and ending at the current
 * caret position.
 */
function getAutocompleteRange(coords?: Coords) {
  const caret = document.getSelection()?.getRangeAt(0);
  if (!caret?.endContainer || !caret?.collapsed || !coords?.startContainer) {
    return;
  }
  const rng = document.createRange();
  rng.setStart(coords.startContainer, coords.startOffset);
  rng.setEnd(caret.endContainer, caret.endOffset);
  return rng;
}

/**
 * Get the text that has been typed from the ampersand (represented by coords)
 * to the current caret location.
 */
function autocompleteText(coords?: Coords) {
  const rng = getAutocompleteRange(coords);
  return rng?.cloneContents().textContent || '';
}

/**
 * Replace everything from the opening ampersand to the current caret location
 * with a link to the mentioned user.
 */
function insertUserLink(courseId: string, user: MentionedUser, coords: Coords) {
  const sel = document.getSelection();
  const rng = getAutocompleteRange(coords)?.cloneRange();
  if (!sel || !rng) {
    return '';
  }
  rng.deleteContents();
  const frag = document.createDocumentFragment();
  frag.append(
    htm(
      'a',
      { href: `/courses/${courseId}/people/${user.id}`, 'data-user-id': user.id },
      `@${user.name}`,
    ),
    document.createTextNode('\u00A0'),
  );
  rng.insertNode(frag);
  rng.collapse(false);
  sel.removeAllRanges();
  sel.addRange(rng);
}

/**
 * Display an autocompletion list of the course's members.
 */
function MentionAutocomplete({
  courseId,
  coords,
  editor,
  onCancel,
  onComplete,
}: {
  courseId: UUID;
  coords: NonNullable<ReturnType<typeof caretCoords>>;
  editor: MinidocCore;
  onCancel(): void;
  onComplete(value: MentionedUser): void;
}) {
  const [suggestions, setSuggestions] = useState<{ users: MentionedUser[]; index: number }>({
    users: [],
    index: 0,
  });
  const [text, setText] = useState(() => autocompleteText(coords));
  const selected = useRef(suggestions.users[suggestions.index]);
  selected.current = suggestions.users[suggestions.index];

  useAsyncData(async () => {
    try {
      const result = await rpx.members.getMentionAutocomplete({
        courseId,
        search: text.slice(1), // Get rid of the '@' prefix
      });
      setSuggestions({
        users: result.users,
        index: 0,
      });
    } catch (err) {
      // We don't want to spam the user with alerts if something goes wrong...
      console.error(err);
    }
  }, [text]);

  useEffect(() => {
    return on(
      // We try using parentElement instead of root so that we can capture
      // events before the underlying editor does, and cancel them if need be.
      editor.root.parentElement || editor.root,
      'keydown',
      (e) => {
        switch (e.code) {
          case 'Escape':
          case 'ArrowLeft':
          case 'ArrowRight':
            onCancel();
            return;
          case 'ArrowDown': {
            e.preventDefault();
            setSuggestions((s) => ({
              ...s,
              index: Math.min(s.index + 1, s.users.length - 1),
            }));
            return;
          }
          case 'ArrowUp': {
            e.preventDefault();
            setSuggestions((s) => ({
              ...s,
              index: Math.max(s.index - 1, 0),
            }));
            return;
          }
          case 'Tab':
          case 'Enter':
            e.preventDefault();
            if (selected.current) {
              onComplete(selected.current);
            } else {
              onCancel();
            }
            return;
        }
      },
      { capture: true },
    );
  }, [editor.root]);

  useEffect(() => {
    return on(
      editor.root,
      'input',
      () => {
        const newText = autocompleteText(coords);
        if (!newText) {
          onCancel();
        } else {
          setText(newText);
        }
      },
      { capture: true },
    );
  }, [editor.root]);

  useEffect(() => {
    return on(document, 'mouseup', onCancel, { capture: true });
  }, [editor.root]);

  if (!suggestions.users.length) {
    return null;
  }

  return (
    <div
      class="flex flex-col gap-2 p-2 rounded-md bg-white shadow-lg absolute z-10 min-w-60"
      style={{ top: `calc(${coords.y}px + 1rem)`, left: `${coords.x}px` }}
    >
      {suggestions.users.map((x, i) => (
        <Button
          key={x.id}
          class={`flex items-center p-2 gap-3 rounded-md ${
            i === suggestions.index ? 'bg-indigo-600 text-white' : ''
          }`}
          onMouseUp={() => onComplete(x)}
        >
          <UserProfileIcon user={x} size="w-6 h-6" />
          {x.name}
        </Button>
      ))}
    </div>
  );
}

export function Mentionable({ courseId, editor }: { courseId: UUID; editor: MinidocCore }) {
  const [coords, setCoords] = useState<ReturnType<typeof caretCoords>>(undefined);

  useEffect(
    () =>
      on(
        editor.root,
        'beforeinput',
        (e) => {
          if (isLeadingAmpersand(e)) {
            setCoords(caretCoords());
          }
        },
        { capture: true },
      ),
    [editor.root],
  );

  useEffect(
    () =>
      on(
        editor.root,
        'keydown',
        (e) => {
          if (isBackspaceIntoAmpersand(e)) {
            const coords = caretCoords();
            coords &&
              setCoords({
                ...coords,
                // Because we're backspacing, and the actual backspace has not
                // yet been applied, we need to adjust the start offset.
                startOffset: coords.startOffset - 2,
              });
          }
        },
        { capture: true },
      ),
    [editor.root],
  );

  if (!coords) {
    return null;
  }

  return (
    <MentionAutocomplete
      courseId={courseId}
      coords={coords}
      onCancel={() => setCoords(undefined)}
      onComplete={(user) => {
        coords && insertUserLink(courseId, user, coords);
        setCoords(undefined);
      }}
      editor={editor}
    />
  );
}
