import { Time } from '@vidstack/react';

export function TimeGroup() {
  return (
    <div class="ml-1.5 flex items-center text-sm font-medium">
      <Time class="time" type="current" />
      <div class="mx-1 text-white/80 hidden md:block">/</div>
      <Time class="time hidden md:block" type="duration" />
    </div>
  );
}
