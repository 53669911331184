import { Case } from '@components/conditional';
import { ProgressBar } from '@components/progress-bar';
import { StudentPollQuestion } from 'server/types';
import { useIntl } from 'shared/intl/use-intl';

interface Props {
  questions: StudentPollQuestion[];
  class?: string;
  showStudentChoice?: boolean;
  showResponseCounts?: boolean;
}

function QuestionItem({
  question,
  showStudentChoice,
  showResponseCounts,
}: {
  question: StudentPollQuestion;
  showStudentChoice?: boolean;
  showResponseCounts?: boolean;
}) {
  const intl = useIntl();
  const voters = question.voters || 0;

  return (
    <div>
      <h2 class="text-lg dark:text-gray-200 whitespace-pre-line">
        {question.content || 'Question'}
      </h2>
      <div class="mb-8 text-gray-400 dark:text-gray-500 text-sm">
        {showResponseCounts && (
          <span>
            {intl('{count:number} {count | pluralize response responses}', {
              count: voters,
            })}
          </span>
        )}
        <Case when={question.isMultipleChoice}>
          <span class="mx-2">|</span>
          <span>Multiple Choice</span>
        </Case>
      </div>
      <div class="space-y-8">
        {question.choices.map((choice) => {
          const percent = voters === 0 ? 0 : Math.round(((choice.votes || 0) / voters) * 100);
          const isStudentChoice = showStudentChoice && choice.isSelected;
          return (
            <div key={choice.id} class="flex">
              <span
                class={`inline-block w-8 mr-3 text-gray-500 dark:text-gray-400 ${
                  isStudentChoice ? 'font-bold' : ''
                }`}
              >
                {percent}%
              </span>
              <div class="flex flex-col gap-2 grow">
                <div class="flex gap-2 justify-between items-center dark:text-gray-200">
                  <span class={`${isStudentChoice ? 'font-bold' : 'font-medium'}`}>
                    {choice.content}
                  </span>
                  {isStudentChoice && (
                    <span class="px-1 border border-green-200 text-green-600 rounded-sm bg-green-50 text-xs font-semibold">
                      Student Choice
                    </span>
                  )}
                </div>
                <ProgressBar progress={percent} showPercent={false} />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export function PollResults({
  questions,
  showStudentChoice,
  showResponseCounts,
  class: className = '',
}: Props) {
  return (
    <div class={`mb-4 ${className}`}>
      {questions.map((question, i) => (
        <div key={question.id} class={`${i > 0 ? 'border-t pt-10' : ''} pb-10`}>
          <QuestionItem
            question={question}
            showStudentChoice={showStudentChoice}
            showResponseCounts={showResponseCounts}
          />
        </div>
      ))}
    </div>
  );
}
