/**
 * A banner that we show to guides who have Paypal prices, but no valid
 * Paypal integration (e.g. guides who have just been migrated from v1).
 */

import { Button } from '@components/buttons';
import { useCurrentUser } from '@components/router/session-context';
import { IcoExclamation } from '@components/icons';
import { intl } from 'shared/intl/use-intl';
import { useLocalStorageState } from 'client/lib/hooks';

const localStorageKey = 'dismissedInvalidPaypalBanner';

export function InvalidPaypalBanner() {
  const user = useCurrentUser();
  const [dismissed, setDismissed] = useLocalStorageState(localStorageKey, false);

  if (!user?.invalidPaypalIntegration) {
    return null;
  }

  if (dismissed) {
    return null;
  }

  return (
    <header class="p-4">
      <div class="rounded-xl bg-yellow-50 p-6 flex mx-auto shadow-sm max-w-4xl border border-yellow-200 gap-4 items-center">
        <span>
          <IcoExclamation class="w-10 h-10 fill-yellow-600 stroke-white" />
        </span>
        <p class="text-yellow-700">
          Your PayPal information is out of date. Until you{' '}
          <Button class="font-medium underline text-inherit" href="/account/integrations">
            update your PayPal information
          </Button>
          , students will be unable to use PayPal to register for your courses.
        </p>

        <span>
          <Button
            class="border border-yellow-400 p-1 px-2 text-yellow-700 rounded-md"
            onClick={() => setDismissed(true)}
          >
            {intl('Dismiss')}
          </Button>
        </span>
      </div>
    </header>
  );
}
