import { useDisposableMemo } from 'client/lib/hooks';
import { debounce } from 'client/utils/debounce';
import { on } from 'minidoc-editor';

export function AutoPager({ onLoadMore }: { onLoadMore(): void }) {
  const pager = useDisposableMemo(() => {
    let el: Element | undefined;
    let off = () => {};
    const result = {
      attach(x?: Element | null) {
        // The set timeout is a hacky way to wait for the element
        // to be attached to the DOM we should probably create a
        // real hook for that purpose, as there are a number of
        // places in the app we do this.
        setTimeout(() => {
          if (!x || !x.isConnected || el === x) {
            return;
          }
          el = x;
          off();
          const onScroll = debounce(() => {
            if (el && el.getBoundingClientRect().top < window.innerHeight + 60) {
              onLoadMore();
            }
          });
          off = on(document, 'scroll', onScroll);
          onScroll();
        });
      },
      dispose() {
        off();
      },
    };

    return result;
  }, []);

  return <span ref={pager.attach}>...</span>;
}
