import { DraggableProvider } from '@components/draggable';
import { ComponentChildren } from 'preact';

interface Props<TID extends string = 'id'> {
  table: string;
  children: ComponentChildren;
  prop?: TID;
  onReorder(reorderer: <T extends { [K in TID]: any }>(arr: T[]) => T[]): void;
}

export function SubsectionDragProvider<TID extends string = 'id'>(props: Props<TID>) {
  const prop = (props.prop || 'id') as unknown as TID;
  return (
    <DraggableProvider
      onDragComplete={() => {}}
      onTargetChange={(drag) => {
        props.onReorder((arr) => {
          const items = [...arr];
          const draggingIndex = items.findIndex((x) => x[prop] === drag.dragging.id);
          const targetIndex = items.findIndex((x) => x[prop] === drag.target.id);
          items.splice(draggingIndex, 1);
          items.splice(targetIndex, 0, arr[draggingIndex]);
          return items;
        });
      }}
      canHandleDrop={(_a, table) => table === props.table}
    >
      {props.children}
    </DraggableProvider>
  );
}
