import { useTimezone } from 'client/lib/hooks';
import { useCurrentUser } from '@components/router/session-context';
import { IcoExclamation } from '@components/icons';
import { timezoneCity, timezoneDiff } from 'shared/dateutil';

/**
 * Display a notification if the current timezone differs from the user's
 * original / saved time zone.
 */
export function TimezoneDiff(props: { date?: Date; class?: string }) {
  const user = useCurrentUser()!;
  const timezone = useTimezone();
  const timezoneDesc = timezoneDiff(timezone, user.timezone, props.date);

  if (!timezoneDesc) {
    return null;
  }

  return (
    <span
      class={`bg-yellow-50 border border-yellow-200 text-yellow-700 rounded-md p-2 px-3 flex items-center gap-2 ${
        props.class || ''
      }`}
    >
      <IcoExclamation />
      <span>
        Your current timezone is <strong>{timezoneCity(timezone)}</strong> {timezoneDesc}.
      </span>
    </span>
  );
}
