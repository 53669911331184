import { filepicker } from 'client/components/filepicker';
import { BtnSecondary } from '@components/buttons';
import { showError } from '@components/app-error';
import { IcoImage } from '@components/icons';

type UploadOptions = Partial<Parameters<typeof filepicker>[0]>;

type Props = UploadOptions & {
  /**
   * The image src of the image, if one has already been picked.
   */
  src?: string;

  placeholder?: string;

  onPick(result: NonNullable<Awaited<ReturnType<typeof filepicker>>>): void;
};

async function uploadImage(opts: UploadOptions) {
  const result = await filepicker({
    sources: ['filepicker', 'takephoto'],
    accept: 'image/*',
    ...opts,
  });
  return result;
}

export function ImgPicker({ src, onPick, placeholder = 'Pick an image...', ...opts }: Props) {
  return (
    <BtnSecondary
      class={`hover:bg-gray-50 ${src ? 'p-1' : ''}`}
      onClick={async () => {
        try {
          const result = await uploadImage(opts);
          result && onPick(result);
        } catch (err) {
          showError(err);
        }
      }}
    >
      {!src && (
        <span class="flex gap-2 items-center">
          <IcoImage />
          <span>{placeholder}</span>
        </span>
      )}
      {src && <img src={src} class="rounded-md" />}
    </BtnSecondary>
  );
}
