import { ComponentChildren } from 'preact';

interface Props {
  class?: string;
  children?: ComponentChildren;
}

/**
 * A container for breaking a form section up into multiple
 * subsections.
 */
export function FormSubSection({ class: className = '', children }: Props) {
  return <div class={className}>{children}</div>;
}
