import { ModalForm, showModalForm } from '@components/modal-form';
import { courseLabel } from 'shared/terminology';
import { Course } from 'server/types';
import { useCurrentTenant } from '@components/router/session-context';
import { rpx } from 'client/lib/rpx-client';

/**
 * showCourseTitleModal displays a modal for renaming the course.
 */
export function showCourseTitleModal(course: Pick<Course, 'id' | 'title' | 'isBundle'>) {
  showModalForm(() => {
    const tenant = useCurrentTenant();
    const renameCourse = async ({ title }: { title: string }) => {
      await rpx.courses.renameCourse({ id: course.id, title });
      location.reload();
    };
    return (
      <ModalForm
        title={
          <>
            Rename{' '}
            {courseLabel({
              course,
              tenant,
            })}
          </>
        }
        confirmButtonText="Save title"
        onSubmit={renameCourse}
      >
        <input type="text" value={course.title} name="title" class="ruz-input" />
      </ModalForm>
    );
  });
}
