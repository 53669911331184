import { MembershipStatus } from 'server/types';

type Props = {
  status: MembershipStatus;
};

export function AccessStatusBadge({ status }: Props) {
  return (
    <span class="flex gap-2 items-center">
      <span
        class={`rounded-full inline-flex w-2.5 h-2.5 border-2 ${
          status === 'enabled'
            ? 'bg-green-500 border-green-200'
            : status === 'disabled'
            ? 'bg-red-500 border-red-200'
            : status === 'expired'
            ? 'bg-yellow-50 border-yellow-200'
            : ''
        }`}
      ></span>
      {status === 'enabled' && <span class="text-green-600">Granted</span>}
      {status === 'disabled' && <span class="text-red-600">Revoked</span>}
      {status === 'expired' && <span class="text-yellow-600">Expired</span>}
    </span>
  );
}
