import { Dropdown, MenuItem } from '@components/dropdown';
import { ComponentChildren } from 'preact';
import { View } from './types';
import { Button } from '@components/buttons';
import { IcoArrowLeft, IcoChevronLeft, IcoChevronRight } from '@components/icons';

/**
 * The back / forward and title header element for the day, week, month views.
 */
export function CalendarNav(props: { onPrev(): void; onNext(): void; title: ComponentChildren }) {
  return (
    <div class="flex flex-row items-center gap-2">
      <Button class="p-2 rounded-xl hover:bg-gray-100 transition-all" onClick={props.onPrev}>
        <IcoChevronLeft />
      </Button>
      <Button class="p-2 rounded-xl hover:bg-gray-100 transition-all" onClick={props.onNext}>
        <IcoChevronRight />
      </Button>
      <h2 class="hidden sm:block text-xl">{props.title}</h2>
    </div>
  );
}

export function HeaderNav({
  view,
  children,
  showToday,
  gotoView,
}: {
  view: View;
  children: ComponentChildren;
  showToday?: boolean;
  gotoView(view: View): void;
}) {
  return (
    <header class="flex gap-4 font-medium items-start sm:items-center justify-between border-b p-3">
      <div class="flex gap-4 items-center">
        {children}
        {showToday && (
          <Button
            class="p-1 px-3 inline-flex items-center rounded-full transition-all hover:bg-gray-100 gap-2 border"
            onClick={() => gotoView(view)}
          >
            <IcoArrowLeft />
            Today
          </Button>
        )}
      </div>
      <Dropdown
        noPadding
        triggerClass="p-1 px-3 rounded-full transition-all hover:bg-gray-100 border capitalize gap-2"
        renderMenu={() => (
          <>
            <MenuItem onClick={() => gotoView('agenda')}>Agenda</MenuItem>
            <MenuItem onClick={() => gotoView('day')}>Day</MenuItem>
            <div class="hidden sm:flex flex-col">
              <MenuItem onClick={() => gotoView('week')}>Week</MenuItem>
              <MenuItem onClick={() => gotoView('month')}>Month</MenuItem>
            </div>
          </>
        )}
      >
        {view}
      </Dropdown>
    </header>
  );
}
