import { useCurrentTenant } from '@components/router/session-context';
import { useMemo } from 'preact/hooks';
import { AccessFormat } from 'server/types';
import { pluralize } from 'shared/formatting';
import { emptyModuleTitle } from 'shared/terminology';
import { scheduleDescription } from './fns';
import { Module } from './types';

export function ModuleNavTitle({
  isAbsoluteSchedule,
  module,
  accessFormat,
  membershipDate,
  titleClass = '',
  canManageCourse,
}: {
  isAbsoluteSchedule: boolean | undefined;
  module: Pick<Module, 'title' | 'startDate' | 'startOffset' | 'prices' | 'isDraft'>;
  accessFormat: AccessFormat;
  /**
   * The date the user became a member of the course. If specified, the
   * the available on date will be shown only for future modules, and
   * will be relative to membershipDate for 'ondemand' courses.
   */
  membershipDate?: Date;
  titleClass?: string;
  canManageCourse: boolean;
}) {
  const tenant = useCurrentTenant();
  const subTitle = useMemo(
    () =>
      accessFormat !== 'openaccess'
        ? scheduleDescription({
            isAbsoluteSchedule,
            omitPrefix: true,
            accessFormat,
            membershipDate,
            startDate: module.startDate,
            startOffset: module.startOffset,
          })
        : '',
    [module.startDate, module.startOffset],
  );
  const hasFooter = !!module.prices?.length || subTitle;

  return (
    <span class="flex flex-col text-left">
      <span class={`${titleClass} font-semibold`}>
        {module.isDraft && <span class="opacity-50">[Draft] </span>}
        {module.title || emptyModuleTitle(tenant)}
      </span>
      {hasFooter && (
        <span class="flex items-center gap-4 font-normal text-xs">
          {subTitle && <span>{subTitle}</span>}
          {canManageCourse && !!module.prices?.length && (
            <span>
              {module.prices.length} {pluralize('price point', module.prices.length)}
            </span>
          )}
        </span>
      )}
    </span>
  );
}
