import { uploadCount } from '@components/media-card/media-uploader';
import { onBeforeUnload } from '@components/router/before-unload';
import { useCtrlSaveKey } from 'client/lib/hooks';
import { delayedDebounce } from 'client/utils/debounce';
import { serialAsync } from 'client/utils/serial-async';
import { on, minidoc } from 'minidoc-editor';
import { useState, useEffect, useMemo, useRef } from 'preact/hooks';

type Minidoc = ReturnType<typeof minidoc>;

interface Props {
  editor: Minidoc;
  state: any;
  save(state: any, content: string): Promise<unknown>;
  autosaveInterval?: number;
}

interface Tracker {
  computeIsDirty(force?: boolean): boolean;
  wrapSave<T>(promise: Promise<T>): Promise<T>;
}

export function useUnsavedWarning(hasUnsavedChanges: () => boolean) {
  const ref = useRef(hasUnsavedChanges);
  ref.current = hasUnsavedChanges;
  useEffect(
    () =>
      onBeforeUnload(() => {
        if (!ref.current()) {
          return;
        }
        return `You have unsaved changes. If you leave this page, they will be lost.`;
      }),
    [],
  );
}

function useChangeTracker(state: any): Tracker {
  const tracker = useMemo(
    () => ({
      state,
      savedState: state,
      computeIsDirty() {
        return tracker.state !== tracker.savedState;
      },
      async wrapSave<T>(promise: Promise<T>) {
        const savingState = tracker.state;
        const result = await promise;
        tracker.savedState = savingState;
        return result;
      },
    }),
    [],
  );

  tracker.state = state;

  return tracker;
}

function useMinidocTracker(editor: Minidoc): Tracker {
  const [isDirty, setIsDirty] = useState(false);

  const tracker = useMemo(() => {
    let state = editor.serialize();

    return {
      isDirty,

      computeIsDirty(force?: boolean) {
        if (uploadCount(editor) > 0) {
          return true;
        }
        if (force) {
          return state !== editor.serialize();
        } else {
          return tracker.isDirty;
        }
      },

      async wrapSave<T>(promise: Promise<T>) {
        const savingState = editor.serialize();
        const result = await promise;
        state = savingState;
        if (!tracker.computeIsDirty(true)) {
          setIsDirty(false);
        }
        return result;
      },
    };
  }, []);

  tracker.isDirty = isDirty;

  useEffect(
    () =>
      on(
        editor.root,
        'mini:change',
        delayedDebounce(() => {
          if (tracker.isDirty) {
            return;
          }
          if (tracker.computeIsDirty(true)) {
            setIsDirty(true);
          }
        }),
      ),
    [editor],
  );

  return tracker;
}

function useUploadTracker(editor: Minidoc): Tracker {
  return useMemo(() => {
    return {
      computeIsDirty() {
        return uploadCount(editor) > 0;
      },

      async wrapSave<T>(promise: Promise<T>) {
        return promise;
      },
    };
  }, []);
}

/**
 * A preact hook that tracks save state for a rich content screen.
 * - Adds Ctrl+S support
 * - Autosaves
 * - Window before unload warning
 */
export function useAutosaver({ editor, state, save, autosaveInterval = 2500 }: Props) {
  const [saving, setSaving] = useState(false);
  const stateTracker = useChangeTracker(state);
  const minidocTracker = useMinidocTracker(editor);
  const uploadTracker = useUploadTracker(editor);
  const changeTracker = useMemo(() => {
    const trackers = [stateTracker, uploadTracker, minidocTracker];
    let latestState = state;
    return {
      set state(val: any) {
        latestState = val;
      },
      computeIsDirty(force?: boolean) {
        return trackers.some((t) => t.computeIsDirty(force));
      },
      save: serialAsync(async () => {
        setSaving(true);
        try {
          const result = await trackers.reduce(
            (acc, t) => t.wrapSave(acc),
            save(latestState, editor.serialize(true)),
          );
          return result;
        } finally {
          setSaving(false);
        }
      }),
    };
  }, []);

  changeTracker.state = state;

  useCtrlSaveKey(changeTracker.save);

  /**
   * When the window unloads, we'll warn of lost changes.
   */
  useUnsavedWarning(() => changeTracker.computeIsDirty(true));

  const isUploading = uploadTracker.computeIsDirty();
  const contentChanged = stateTracker.computeIsDirty() || minidocTracker.computeIsDirty();

  /**
   * When we have unsaved changes, we'll queue up a save operation.
   */
  useEffect(() => {
    let timeout: any;
    if (contentChanged && !saving) {
      setTimeout(changeTracker.save, autosaveInterval);
    }
    return () => clearTimeout(timeout);
  }, [contentChanged, saving]);

  /**
   * When the mouse leaves the minidoc root, we'll go ahead and save, if we
   * are in a dirty state. This improves the UX for users
   * who have made changes and are now about to click away
   * to another page befoure our autosave timeout.
   */
  useEffect(() => {
    on(document, 'mousemove', () => {
      if (stateTracker.computeIsDirty() || minidocTracker.computeIsDirty()) {
        changeTracker.save();
      }
    });
  }, []);

  return { isDirty: contentChanged || isUploading };
}
