import { showError } from '@components/app-error';
import { DialogHeader, StandardDialog } from '@components/dialog';
import { LoadingIndicator } from '@components/loading-indicator';
import { showModalForm } from '@components/modal-form';
import { DefaultSpinner } from '@components/spinner';
import { useAsyncEffect } from 'client/utils/use-async-effect';
import { ComponentChildren } from 'preact';

interface Props<T> {
  title: string;
  children?: ComponentChildren;
  promise: Promise<T>;
}

export function showProcessingModal<T>(props: Props<T>): Promise<T | undefined> {
  return showModalForm<T | undefined>(({ resolve }) => {
    useAsyncEffect(async () => {
      try {
        const result = await props.promise;
        resolve(result);
      } catch (err) {
        showError(err);
      } finally {
        resolve();
      }
    }, []);

    return (
      <StandardDialog onClose={() => {}}>
        <LoadingIndicator />
        <DialogHeader title={props.title} />
        <div class="flex flex-col py-8">
          {props.children}
          {!props.children && <DefaultSpinner />}
        </div>
      </StandardDialog>
    );
  });
}
