/**
 * The checkout page for purchasing Ruzuku.
 */

import { AuthForm, CheckoutHeader, CheckoutPage, PurchaseForm } from '@course/components/checkout';
import { rpx } from 'client/lib/rpx-client';
import { setUrlSearchParam } from 'client/utils/url';
import * as pmtmath from 'shared/payments/math';
import * as urls from 'shared/urls';
import { ErrorPage } from '@components/error-page';
import { useCurrentUser } from 'client/lib/auth';
import { CoreBanner } from './core-banner';
import { HyrosTracker } from './hyros-tracker';
import { useIntl } from 'shared/intl/use-intl';
import { LoadedProps, RouteLoadProps, defRoute } from '@components/router';

export const route = defRoute({
  isPublic: true,
  load,
  Page,
});

async function load(route: RouteLoadProps) {
  const opts = {
    priceId: route.params.priceId,
    couponId: route.params.couponId,
    user: route.auth.user,
  };
  const result = await rpx.payments.getPrice({
    priceId: opts.priceId,
    couponId: opts.couponId,
    expand: ['seller', 'product'],
  });
  return {
    seller: result.seller,
    price: result.price,
    coupon: result.coupon,
    product: result.product,
  };
}

type Props = LoadedProps<typeof load>;

function Page(props: Props) {
  const user = useCurrentUser();
  const intl = useIntl();
  const { product, price, coupon, seller } = props.state;
  const setCoupon = (value: typeof coupon) => {
    if (value) {
      props.router.rewrite(setUrlSearchParam('couponId', value.id));
    }
    props.setState((s) => ({ ...s, coupon: value }));
  };

  if (!price || !product) {
    return <ErrorPage title="404 | Not Found"></ErrorPage>;
  }

  const onPurchaseComplete = async () => {
    await props.router.redirectTo(
      urls.reverseRoute(urls.corePrices.provisionRoute, { priceId: price.id }),
    );
  };

  return (
    <CheckoutPage
      price={price}
      coupon={coupon}
      setCoupon={setCoupon}
      bannerContent={<CoreBanner />}
      showHeader={false}
    >
      <HyrosTracker />
      {!user && (
        <AuthForm
          subtitle={price.name}
          onSubmit={async () => {
            props.router.goto(
              location.pathname +
                setUrlSearchParam('auto', pmtmath.isFree({ price, coupon }).toString()),
            );
          }}
        />
      )}

      {user && (
        <>
          <CheckoutHeader title={intl('Sign Up')} subtitle={price.name} />
          <PurchaseForm
            collectAddress={seller.collectAddress}
            autoSubmit={props.params.auto === 'true'}
            price={price}
            coupon={coupon}
            beginPurchase={({ address, ...opts }) => {
              return rpx.myCorePayments.purchase({
                priceId: price.id,
                couponId: coupon?.id,
                ...opts,
              });
            }}
            onPurchaseComplete={onPurchaseComplete}
          />
        </>
      )}
    </CheckoutPage>
  );
}
