import { Button } from '@components/buttons';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { useMemo, useState } from 'preact/hooks';
import { ruzcal } from 'shared/urls';
import { CalendarEvent } from './types';
import { summarizeTime } from '../dateutil';
import { useInterval } from 'client/utils/use-interval';
import { getTailwindColorClasses } from '@components/color-palette';

/**
 * The ID of the element we use to draw the "now line". We want to
 * scroll it into view from the parent, so we export it here.
 */
export const nowLineId = 'now-line';

export function HoursSideBar() {
  const hours = new Array(24).fill(0);
  return (
    <div class="flex flex-col w-14">
      {hours.map((_, h) => (
        <div key={h} class="h-20 shrink-0 relative">
          {h > 0 ? (
            <span class="bg-white pl-0 p-2 absolute -top-5">{`${h % 12 || 12} ${
              h < 12 ? 'AM' : 'PM'
            }`}</span>
          ) : (
            ''
          )}
        </div>
      ))}
    </div>
  );
}

/**
 * Render a day-view of events.
 */
export function DayEvents({ day, events }: { events: CalendarEvent[]; day: Dayjs }) {
  const [now, setNow] = useState(() => dayjs());
  const isToday = now.toDate().toDateString() === day.toDate().toDateString();
  const nextDay = day.add(1, 'day');
  const hours = useMemo(() => new Array(24).fill(0).map((_, i) => day.add(i, 'hours')), []);
  events = events.filter((e) => day.isBefore(e.end) && nextDay.isAfter(e.start));

  useInterval(() => {
    if (!isToday) {
      return;
    }
    // Move the "now" line if we're in today
    setNow(dayjs());
  }, 60 * 1000);

  return (
    <section class="relative divide-y flex flex-col grow">
      {hours.map((h) => (
        <div key={h} class="h-20 shrink-0 relative border-l"></div>
      ))}
      {events.map((e) => {
        const isPast = now.isAfter(e.end);
        return (
          <Button
            key={e.id}
            id={e.id}
            href={ruzcal.existingBookingUrl({
              id: e.id,
            })}
            class={`${
              isPast
                ? 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                : `text-white ${getTailwindColorClasses(e.color)}`
            } transition-all rounded-md px-2 flex gap-4 absolute left-0 right-2 min-h-4 overflow-hidden text-nowrap text-left js-event`}
            style={{
              // Here, we use the same Tailwind CSS variable as our height (h-20) so
              // that we can properly position each event in the given day.
              //
              //   h-20 is "var(--spacing) * 20" and is the height of an hour
              //   / 60 gives us the height of each minute
              top: `calc(((var(--spacing) * 20) / 60) * ${
                e.start.getHours() * 60 + e.start.getMinutes()
              })`,
              height: `calc((var(--spacing) * 20) / 60 * ${dayjs(e.end).diff(e.start, 'minute')})`,
            }}
          >
            <span class="font-semibold">
              {e.name} with {e.attendee.name}
            </span>
            <span>{summarizeTime(e)}</span>
          </Button>
        );
      })}

      {
        // If today is being shown, draw the "now" line
        isToday && (
          <div
            id={nowLineId}
            class="h-4 flex absolute -inset-x-1.5 items-center"
            style={{
              top: `calc(((var(--spacing) * 20) / 60) * ${now.hour() * 60 + now.minute()})`,
            }}
          >
            <span class="inline-flex size-3 rounded-full bg-red-600"></span>
            <span class="inline-flex h-0.5 rounded-r-full bg-red-600 grow"></span>
          </div>
        )
      }
    </section>
  );
}
