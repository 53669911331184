/**
 * Displays a price + coupon as a one-line summary.
 */

import { CouponRow, PriceRow } from 'server/types';
import { useIntl } from 'shared/intl/use-intl';
import * as fmt from 'shared/payments/fmt';
import * as pmtmath from 'shared/payments/math';

type Price = Pick<
  PriceRow,
  'priceInCents' | 'paymentType' | 'currency' | 'isEnabled' | 'numPayments'
>;

export function PriceSummary({ price, coupon }: { price: Price; coupon?: CouponRow }) {
  const intl = useIntl();
  const { initial, final } = pmtmath.batches({
    price,
    coupon,
  });

  if (!price.isEnabled) {
    return null;
  }

  return (
    <>
      {coupon && <s class="opacity-75 mr-1">{fmt.price(price)}</s>}
      {initial && (
        <span class="font-semibold">
          {fmt.price(initial)}{' '}
          {fmt.firstBatchSuffix({
            item: initial,
            intl,
          })}
        </span>
      )}
      <span class={initial ? 'opacity-75' : coupon ? 'text-green-600 font-semibold' : ''}>
        {`${initial ? ', then ' : ''}${fmt.price(final)} ${fmt.priceSuffix({
          item: final,
          intl,
        })}`}
      </span>
    </>
  );
}
