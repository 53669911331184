import { useAsyncEffect } from 'client/utils/use-async-effect';
import { UpcomingEvents, WhatsNew } from '@course/components/course-overview';
import { GuideCoursePage } from '@course/components/guide-course-page';
import { CourseStats } from './course-stats';
import { RecentSignups } from './recent-signups';
import { IcoPencil } from '@components/icons';
import { RecentAssignments } from './recent-assignments';
import { RecentQuizSubmissions } from './recent-quiz-submissions';
import { PrimaryOverviewCard } from './overview-card';
import { ModuleCard } from '@course/components/module-helpers/module-card';
import { BundleOverview } from './bundle-overview';
import { rpx } from 'client/lib/rpx-client';
import { LoadedProps } from '@components/router';
import { load } from './load';
import { defCoursesRoute } from '@course/components/courses-app-router';

export const route = defCoursesRoute({
  load,
  Page,
  authLevel: 'guide',
});

function Page(props: LoadedProps<typeof load>) {
  const { course, modules, upcomingMeetings, recentSignups } = props.state;
  const { id: courseId } = course;

  useAsyncEffect(async () => {
    if (!courseId) {
      return;
    }
    try {
      await rpx.courses.setLastOpenedAt({ courseId });
    } catch (err) {
      console.warn('Could not set last_opened_at', err);
    }
  }, [courseId]);

  if (course.isBundle) {
    return <BundleOverview course={course} signupsData={recentSignups} />;
  }

  return (
    <GuideCoursePage course={course} type="dashboard">
      <div class="flex flex-col w-full max-w-7xl mx-auto p-8 gap-6">
        <CourseStats course={course} />
        <div class="grid grid-cols-1 xl:grid-cols-2 gap-8">
          <div class="grow">
            <PrimaryOverviewCard
              title="Modules & Lessons"
              headerActions={
                <a class="inline-flex items-center" href={`/manage/courses/${courseId}/lessons`}>
                  <IcoPencil class="w-4 h-4 opacity-75 mr-1" />
                  <span>
                    Edit<span class="hidden md:inline"> Modules & Lessons</span>
                  </span>
                </a>
              }
            >
              {modules.map((module, index) => (
                <ModuleCard key={module.id} module={module} index={index} />
              ))}
            </PrimaryOverviewCard>
          </div>

          <div class="flex flex-col gap-8">
            <RecentSignups
              course={course}
              signups={recentSignups.signups}
              hasMore={recentSignups.hasMore}
            />
            <RecentAssignments courseId={courseId} />
            <RecentQuizSubmissions courseId={courseId} />
            <UpcomingEvents meetings={upcomingMeetings} isGuide Wrapper={PrimaryOverviewCard} />
            <WhatsNew course={course} title="Recent Comments" Wrapper={PrimaryOverviewCard} />
          </div>
        </div>
      </div>
    </GuideCoursePage>
  );
}
