import { Case } from '@components/conditional';
import {
  IcoArrowCircleLeft,
  IcoBook,
  IcoCalendar,
  IcoChat,
  IcoHome,
  IcoPresentation,
  IcoSettings,
  IcoUsers,
  SVGProps,
} from '@components/icons';
import type { ComponentChildren, h } from 'preact';
import { Course, MembershipLevel } from 'server/types';
import { useIntl } from 'shared/intl/use-intl';
import { URLS } from 'shared/urls';
import { StudentTabs } from './props';

function TopNavLink(props: {
  Icon(p: SVGProps): h.JSX.Element;
  text: ComponentChildren;
  href: string;
  isThemable?: boolean;
  isSelected: boolean;
  class?: string;
}) {
  return (
    <a
      class={`theme-primary-nav-link group relative lg:text-xs p-2 tall:py-4 rounded-md ${
        props.class || ''
      } whitespace-nowrap flex lg:flex-col items-center ${
        props.isSelected
          ? 'text-inherit course-is-selected dark:text-sky-400'
          : 'text-inherit dark:text-gray-400'
      }`}
      href={props.href}
    >
      <props.Icon class="h-4 w-4 tall:h-5 tall:w-5 opacity-75 lg:opacity-80 mr-2 lg:mr-0 lg:mb-1" />
      {props.text}
      <span
        class={`hidden lg:block ${
          props.isSelected ? 'bg-current group-hover:opacity-20' : 'bg-transparent'
        } group-hover:bg-current opacity-10 dark:bg-gray-800 absolute inset-0.5 rounded-xl transition-all`}
      ></span>
    </a>
  );
}

export function CourseNavLinks({
  currentLink,
  isThemable,
  course,
  accessLevel,
}: {
  currentLink: StudentTabs;
  isThemable?: boolean;
  course: Pick<
    Course,
    'id' | 'title' | 'accessFormat' | 'hidePeople' | 'hideDiscussions' | 'isBundle' | 'hasMeetings'
  >;
  accessLevel: MembershipLevel;
}) {
  const intl = useIntl();
  const { isBundle, accessFormat } = course;
  const isPreview = accessLevel === 'preview' || accessLevel === 'limited';
  const baseUrl = URLS.student.course({
    course,
  });
  const showCalendar =
    course.hasMeetings || accessFormat === 'scheduled' || accessFormat === 'ondemand';

  return (
    <nav class="flex flex-col lg:bg-gray-800 dark:bg-gray-900 lg:text-gray-300 p-2 z-20  dark:lg:border-r dark:border-gray-800 dark:bg-none theme-primary-nav">
      <TopNavLink
        Icon={IcoArrowCircleLeft}
        text={intl('Courses')}
        isThemable={isThemable}
        isSelected={false}
        href="/"
      />
      <span class="border-b dark:border-gray-700 my-2 mx-2 opacity-50"></span>
      <TopNavLink
        Icon={IcoHome}
        text={intl('Home')}
        isThemable={isThemable}
        isSelected={currentLink === 'home'}
        href={baseUrl}
      />
      <Case when={!isBundle}>
        <TopNavLink
          Icon={IcoBook}
          text={intl('Modules')}
          isThemable={isThemable}
          isSelected={currentLink === 'modules'}
          href={`${baseUrl}/lessons`}
        />
        {showCalendar && (
          <TopNavLink
            Icon={IcoCalendar}
            text={intl('Calendar')}
            isThemable={isThemable}
            isSelected={currentLink === 'calendar'}
            href={`${baseUrl}/calendar`}
          />
        )}
        {!!course.hasMeetings && (
          <TopNavLink
            Icon={IcoPresentation}
            text={intl('Meetings')}
            isThemable={isThemable}
            isSelected={currentLink === 'meetings'}
            href={`${baseUrl}/meetings`}
          />
        )}
        {!course.hideDiscussions && (
          <TopNavLink
            Icon={IcoChat}
            text={intl('Discussions')}
            isThemable={isThemable}
            isSelected={currentLink === 'discussions'}
            href={`${baseUrl}/discussions`}
          />
        )}
        {!isPreview && !course.hidePeople && (
          <TopNavLink
            Icon={IcoUsers}
            text={intl('People')}
            isThemable={isThemable}
            isSelected={currentLink === 'people'}
            href={`${baseUrl}/people`}
          />
        )}
        {!isPreview && (
          <TopNavLink
            Icon={IcoSettings}
            text={intl('Preferences')}
            isThemable={isThemable}
            isSelected={currentLink === 'preferences'}
            href={`${baseUrl}/preferences`}
          />
        )}
      </Case>
    </nav>
  );
}
