import { useEffect, useMemo, useState } from 'preact/hooks';
import { timeago } from 'shared/dateutil';

interface Props {
  class?: string;
  date: string | Date;
}

// We'll update every minute until we hit an hour or more in the past, then we'll check every 30 minutes
const minute = 60000;
const elapsedMinutes = (date: Date) => (Date.now() - date.getTime()) / minute;
const interval = (date: Date) => (elapsedMinutes(date) > 60 ? minute * 30 : minute);

export function Timeago(props: Props) {
  const dt = useMemo(() => new Date(props.date), [props.date]);
  const [txt, setTxt] = useState(() => timeago(dt));

  useEffect(() => {
    let timeout: any;
    const reschedule = () => (timeout = setTimeout(updateTxt, interval(dt)));
    function updateTxt() {
      const newText = timeago(props.date);
      newText !== txt && setTxt(newText);
      reschedule();
    }
    reschedule();
    return () => clearTimeout(timeout);
  }, [props.date]);

  return <span class={props.class}>{txt}</span>;
}
