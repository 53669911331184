import { BtnPrimary, Button } from '@components/buttons';
import { Case } from '@components/conditional';
import { IcoSlides, IcoTrash } from '@components/icons';
import { Toggle } from '@components/toggle';
import { filepicker } from 'client/components/filepicker';
import { useImageUrl } from 'client/utils/cdn';
import { FileRec, Meeting } from 'server/types';

type RuzukuMeetingData = Pick<Meeting, 'slidesFile' | 'recordMeeting' | 'allowRecordingDownloads'>;

interface Props {
  meeting: RuzukuMeetingData;
  canEditDetails: boolean;
  onChange: (subset: Partial<RuzukuMeetingData>) => void;
  onSlideDelete: () => void;
}

export function RuzukuCallDetails({ meeting, canEditDetails, onChange, onSlideDelete }: Props) {
  async function addSlide() {
    const result = await filepicker({
      sources: ['filepicker'],
      // All the accepted file types by BigBlueButton
      accept:
        '.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt,.rtf,.odt,.ods,.odp,.odg,.odc,.odi,.jpg,.jpeg,.png',
    });

    if (result?.fileId) {
      onChange({
        slidesFile: {
          id: result.fileId,
          type: result.type,
          url: result.filePath,
          name: result.name,
        },
      });
    }
  }

  return (
    <div class="space-y-8">
      <Case when={canEditDetails}>
        {!meeting.slidesFile && (
          <div>
            <p class="text-gray-500 text-sm mb-2">
              You have the ability to upload any office document or PDF file. For best results, a
              PDF is recommended.
            </p>
            <BtnPrimary type="button" onClick={addSlide}>
              <IcoSlides class="w-4 h-4 mr-2" />
              Add Slides
            </BtnPrimary>
          </div>
        )}
      </Case>
      {meeting.slidesFile && (
        <UploadedSlideFile file={meeting.slidesFile} onDelete={onSlideDelete} />
      )}
      <div>
        <label
          class={`inline-flex items-center space-x-3 ${
            canEditDetails ? 'cursor-pointer' : 'opacity-50 cursor-not-allowed'
          }`}
        >
          <Toggle
            checked={meeting.recordMeeting}
            disabled={!canEditDetails}
            onClick={() =>
              onChange({
                recordMeeting: !meeting.recordMeeting,
              })
            }
          />
          <span>Record Event</span>
        </label>
        <p class="text-xs mt-2 mb-8">
          {canEditDetails
            ? 'Please note that you may start and pause the the session recording anytime after the event starts.'
            : 'This option is disabled because the meeting has started.'}
        </p>
        <label class="inline-flex items-center space-x-3 cursor-pointer">
          <Toggle
            checked={meeting.allowRecordingDownloads && (!canEditDetails || meeting.recordMeeting)}
            onClick={() =>
              onChange({
                allowRecordingDownloads: !meeting.allowRecordingDownloads,
              })
            }
          />
          <span>Allow students to download recordings</span>
        </label>
        <p class="text-xs mt-2 mb-8">
          This is the default setting for new recordings. If you would like to change this setting
          for a specific recording, you may do so in the recording tab.
        </p>
      </div>
    </div>
  );
}

interface SlideProps {
  file: FileRec;
  onDelete: () => void;
}

function UploadedSlideFile({ file, onDelete }: SlideProps) {
  const url = useImageUrl(file.url);

  return (
    <div class="flex items-center justify-between bg-green-50 border p-2 rounded-sm px-4 py-2">
      <div class="grow">
        <div class="flex">
          <IcoSlides class="w-6 h-6 mr-2 inline-block align-middle text-green-800" />
          <p class="font-bold mb-2 text-green-800">Uploaded Slides</p>
        </div>
        <a target="blank" href={url} class="grow">
          {file.name}
        </a>
      </div>
      <Button class="hover:text-red-500 hover:opacity-100 opacity-75" onClick={onDelete}>
        <IcoTrash />
      </Button>
    </div>
  );
}
