import { ComponentChildren } from 'preact';

interface Props {
  class?: string;
  flexClass?: string;
  isSelected?: boolean;
  href: string;
  children?: ComponentChildren;
}

/**
 * Vertical tab group.
 */
export function VTabs(props: { scrollClass?: string; children?: ComponentChildren }) {
  return <nav class={`relative z-0 ${props.scrollClass || ''}`}>{props.children}</nav>;
}

/**
 * Vertical tab link.
 */
export function VTab({
  class: className = '',
  flexClass = 'flex-col',
  isSelected,
  href,
  children,
}: Props) {
  return (
    <a
      class={`${className} ${flexClass} flex relative p-4 mb-2 outline-hidden focus:ring-1 focus:ring-inset focus:ring-blue-300 rounded ${
        isSelected
          ? 'bg-gray-200 text-gray-900'
          : 'text-gray-600 hover:bg-gray-100 hover:text-gray-900'
      }`}
      href={href}
    >
      {children}
    </a>
  );
}
