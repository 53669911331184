import { minidoc, h } from 'minidoc-editor';

export type Minidoc = ReturnType<typeof minidoc>;

const icoDelete = `<svg fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-4 h-4 opacity-75">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
</svg>
`;

/**
 * Get the current range from the editor so that when we insert a file,
 * it gets inserted in the right place.
 */
export function getCurrentRange(editor: Pick<Minidoc, 'root'>) {
  const sel = document.getSelection()!;
  let range = sel.getRangeAt(0).cloneRange();
  if (!range || !editor.root.contains(range.startContainer)) {
    range = document.createRange();
    range.surroundContents(editor.root.lastElementChild!);
    range.collapse(true);
  }
  return range;
}

/**
 * Restore the selection to the specified range.
 */
export function setCurrentRange(range: Range) {
  const sel = document.getSelection()!;
  sel.removeAllRanges();
  sel.addRange(range);
}

/**
 * This hackery is used to allow the user to navigate up into the title input
 * from the minidoc editor.
 */
export function keyboardEditorToTitle(e: KeyboardEvent, editor: Minidoc, titleSelector: string) {
  if (e.code === 'ArrowUp' || e.code === 'ArrowLeft') {
    const sel = document.getSelection()!;
    const range = sel.getRangeAt(0);
    if (!range.collapsed) {
      return;
    }
    const isStartOfDoc =
      range.startContainer === editor.root.firstElementChild && !range.startOffset;
    const selRect = range.getBoundingClientRect();
    const rootRect = editor.root.getBoundingClientRect();
    const topDiff = selRect.top - rootRect.top;
    const isFirstLine = isStartOfDoc || (topDiff > 0 && topDiff < 16);
    if (!isFirstLine) {
      return;
    }
    const isStartOfLine = isStartOfDoc || selRect.left - rootRect.left === 0;
    if (e.code === 'ArrowUp' || isStartOfLine) {
      e.preventDefault();
      document.querySelector<HTMLInputElement>(titleSelector)?.focus();
    }
  }
}

export function mediaContextMenu(opts: { label: string }, ...children: any[]) {
  return h(
    '.mini-context-wrapper.flex.justify-center.absolute.inset-x-0.-top-6.z-10',
    h('.mini-context-menu.whitespace-nowrap', ...children, btnDeleteMedia(opts)),
  );
}

function btnDeleteMedia(opts: { label: string }) {
  return h('button.mini-context-action.inline-flex.items-center.gap-2.p-2.px-4', {
    onclick: (e: any) => {
      e.preventDefault();
      const card = e.target.closest('mini-card');
      if (!card) {
        return;
      }
      const sel = document.getSelection()!;
      const next = card.nextSibling || card.parentElement;
      sel.selectAllChildren(next);
      card.nextSibling ? sel.collapseToStart() : sel.collapseToEnd();
      card.remove();
    },
    innerHTML: `${icoDelete} Delete ${opts.label}`,
  });
}
