import { useImageUrl } from 'client/utils/cdn';
import { Case } from '@components/conditional';
import { isAudio, isImage, isVideo } from 'shared/media';
import {
  IcoChevronLeft,
  IcoChevronRight,
  IcoMusic,
  IcoPaperclip,
  IcoPlay,
  IcoTrash,
} from '@components/icons';
import { FileRec } from 'server/types';
import { AttachmentProps } from '.';
import { Button } from '@components/buttons';
import type { h } from 'preact';

interface Props {
  attachment: FileRec;
  index: number;
  selected: boolean;
  isFullScreen?: boolean;
  isLast?: boolean;
  canDelete: boolean;
  onClick?: () => void;
  onDelete?: (attachment: FileRec) => void;
  onSort?: AttachmentProps['onSort'];
}

export function Thumbnail({
  attachment,
  index,
  selected,
  isFullScreen,
  isLast,
  canDelete,
  onClick,
  onDelete,
  onSort,
}: Props) {
  let icon: h.JSX.Element | undefined;
  let imagePath: string | undefined;

  if (isImage(attachment.type)) {
    imagePath = attachment.url;
  } else if (isVideo(attachment.type)) {
    imagePath = attachment.poster;
    icon = <IcoPlay class="w-10 h-10 text-white" />;
  } else if (isAudio(attachment.type)) {
    icon = <IcoMusic class="w-12 h-12 text-white" />;
  } else {
    icon = <IcoPaperclip class="w-12 h-12 text-white" />;
  }

  const imageUrl = useImageUrl(imagePath, {
    maxWidth: 800,
  });

  return (
    <div
      class={`${
        isFullScreen ? 'min-w-1/3 lg:min-w-48' : 'min-w-1/4 lg:min-w-1/5'
      } mr-2 relative group border-t-2 pt-2 ${
        selected ? 'border-indigo-500' : 'border-transparent'
      }`}
    >
      <Button
        class={`cursor-pointer p-0 w-full relative overflow-hidden ${
          isFullScreen ? 'h-24 lg:h-32' : 'h-12 lg:h-24'
        }`}
        onClick={onClick}
      >
        <Case when={!!imageUrl}>
          <img
            class="absolute top-0 bottom-0 m-auto w-full h-full object-cover rounded-sm"
            src={imageUrl}
            alt={attachment.name}
            loading="lazy"
          />
        </Case>
        <Case when={!!icon}>
          <div class="absolute inset-0 bg-black/50 flex justify-center items-center">{icon}</div>
        </Case>
        <div
          class={`absolute flex justify-center items-center top-0.5 left-0.5 bg-white shadow-sm rounded-full w-6 h-6 text-xs`}
        >
          {index + 1}
        </div>
      </Button>
      <Case when={canDelete}>
        <div class="flex justify-between absolute bottom-0 left-0 right-0 p-1 rounded-sm bg-black/75">
          <Button
            class="text-white disabled:opacity-30"
            disabled={index === 0}
            onClick={onSort ? () => onSort(attachment, 'prev') : undefined}
          >
            <IcoChevronLeft class="w-4 h-4 text-white" />
          </Button>
          <Button class="text-white" onClick={onDelete ? () => onDelete(attachment) : undefined}>
            <IcoTrash class="w-4 h-4 text-white" />
          </Button>
          <Button
            class="text-white disabled:opacity-30"
            disabled={isLast}
            onClick={onSort ? () => onSort(attachment, 'next') : undefined}
          >
            <IcoChevronRight class="w-4 h-4 text-white" />
          </Button>
        </div>
      </Case>
    </div>
  );
}
