/**
 * Utility functions from the original tiny-date-picker library.
 */

/**
 * Get whether or not the specified date represesnts a valid date.
 */
export function isValidDate(dt: Date) {
  return !isNaN(dt.getTime());
}

/**
 * An internal representation of the time state that allows for invalid time
 * values as the user is in the middle of text entry. We don't call onChange
 * until these become valid date time values.
 */
export function dateToTime(dt: Date) {
  const fmt = new Intl.DateTimeFormat('en-US', {
    hour12: true,
    hourCycle: 'h12',
    timeStyle: 'short',
  });
  const [hh, mm, ampm] = fmt.format(dt).split(/[:\s]/);
  return { hh, mm, ampm };
}

export type Time = ReturnType<typeof dateToTime>;

export function dateOrUndefined(value: any) {
  return value instanceof Date ? value : value ? new Date(value) : undefined;
}

/**
 * Return a new date with f changes applied to it.
 */
export function mkdate(f: (dt: Date) => void, dt?: string | Date) {
  dt = dt ? new Date(dt) : new Date();
  f(dt);
  return dt;
}

/**
 * Get the current date without any time values.
 */
export function today() {
  return mkdate((dt) => dt.setHours(0, 0, 0, 0));
}

/**
 * Convert the specified date to a number.
 * Example: Nov 10, 2023 becomes 20230910
 */
export function dateNum(dt: Date) {
  return dt.getFullYear() * 10000 + dt.getMonth() * 100 + dt.getDate();
}

/**
 * Compare two dates without considering the time.
 */
export function datesEq(a?: Date, b?: Date) {
  return (a && dateNum(a)) === (b && dateNum(b));
}

/**
 * shiftMonth shifts the specified date by a specified number of months
 */
export function shiftMonth(date: Date, n: number, wrap?: boolean) {
  return mkdate((dt) => {
    const dayOfMonth = dt.getDate();
    const month = dt.getMonth() + n;

    dt.setDate(1);
    dt.setMonth(wrap ? (12 + month) % 12 : month);
    dt.setDate(dayOfMonth);

    // If dayOfMonth = 31, but the target month only has 30 or 29 or whatever...
    // head back to the max of the target month
    if (dt.getDate() < dayOfMonth) {
      dt.setDate(0);
    }
  }, date);
}
