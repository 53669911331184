import { Button } from '@components/buttons';
import { Invitation } from './data';
import { useMemo } from 'preact/hooks';

export type Filter = 'all' | 'pending' | 'accepted';

export function FilterBar({
  filter,
  setFilter,
  items,
}: {
  filter: Filter;
  setFilter(filter: Filter): void;
  items: Invitation[];
}) {
  const counts = useMemo(
    () =>
      items.reduce(
        (acc, item) => {
          if (item.userId) {
            ++acc.accepted;
          } else {
            ++acc.pending;
          }
          return acc;
        },
        {
          all: items.length,
          pending: 0,
          accepted: 0,
        },
      ),
    [items],
  );

  return (
    <>
      <Button
        class={filter === 'pending' ? 'font-bold text-gray-700' : 'text-gray-500'}
        onClick={() => setFilter('pending')}
      >
        {counts.pending} pending
      </Button>
      <Button
        class={filter === 'accepted' ? 'font-bold text-gray-700' : 'text-gray-500'}
        onClick={() => setFilter('accepted')}
      >
        {counts.accepted} accepted
      </Button>
    </>
  );
}
