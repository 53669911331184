//** Basic form sub-header */
import { ComponentChildren } from 'preact';

interface Props {
  children?: ComponentChildren;
}

/**
 * The primary means for breaking a form / page into sections.
 */
export function Subheader(props: Props) {
  return <h3 class="text-xl font-normal text-gray-700 my-5">{props.children}</h3>;
}
