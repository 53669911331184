import { StripeCheckoutForm } from './stripe-checkout-form';
import { PaypalCheckoutForm, isCompleting } from './paypal-checkout-form';
import { ComponentChildren } from 'preact';
import { CouponRow, PriceRow } from 'server/types';
import { PurchaseFn, StripeHandlerFn } from 'shared/payments/types';
import { useIntl } from 'shared/intl/use-intl';
import { useRouteParams } from '@components/router';
import * as pmtmath from 'shared/payments/math';
import { CheckoutForm } from './checkout-form';
import { useEffect } from 'preact/hooks';
import { isUpsellable } from './upsell-stripe-checkout-form';
import { rpx } from 'client/lib/rpx-client';

/**
 * Display the forms for actually making the purchase during the checkout process.
 */
export function PurchaseForm(props: {
  collectAddress: boolean;
  price: PriceRow;
  coupon?: CouponRow;
  autoSubmit?: boolean;
  handleStripe?: StripeHandlerFn;
  beginPurchase: PurchaseFn;
  onPurchaseComplete(): Promise<unknown>;
  isGift?: boolean;
  footer?: ComponentChildren;
}) {
  const { price, coupon } = props;
  const intl = useIntl();
  const params = useRouteParams();
  const isProcessingPaypal = isCompleting(params);

  useEffect(() => {
    // This is a temporary hack to track folks who view upsellable prices so we can measure bounce rate
    if (isUpsellable(price)) {
      rpx.myCorePayments.trackUpsellBounces({ priceId: price.id }).catch(console.error);
    }
  }, []);

  if (pmtmath.isFree({ price, coupon })) {
    return (
      <CheckoutForm
        actionText={intl('Finish Checkout ⤑')}
        onSubmit={() => props.beginPurchase({}).then(props.onPurchaseComplete)}
        autoSubmit={props.autoSubmit}
      >
        {!isProcessingPaypal && props.footer}
      </CheckoutForm>
    );
  }

  return (
    <div class="flex flex-col gap-4">
      {!isProcessingPaypal && props.price.allowStripe && <StripeCheckoutForm {...props} />}
      {!isProcessingPaypal && props.price.allowStripe && props.price.allowPaypal && (
        <div class="flex items-center">
          <span class="grow border-b"></span>
          <span class="p-4">{intl('or pay with')}</span>
          <span class="grow border-b"></span>
        </div>
      )}
      {props.price.allowPaypal && <PaypalCheckoutForm {...props} urlParams={params} />}
      {!isProcessingPaypal && props.footer}
    </div>
  );
}
