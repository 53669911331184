import { h } from 'minidoc-editor';

const loadState: Record<string, undefined | Promise<unknown>> = {};

/**
 * Asynchronously load a script.
 * @param src the script to be loaded
 * @param css
 * @returns
 */
export function loadScript(src: string, css?: string) {
  let state = loadState[src];
  if (!state) {
    state = new Promise((r) => {
      if (css) {
        document.head.append(h('link', { rel: 'stylesheet', href: css }));
      }
      document.head.append(h('script', { src, onload: r }));
    });
    loadState[src] = state;
  }
  return state;
}
