import { ComponentChildren } from 'preact';

export function stripeHref(stripeId?: string) {
  if (!stripeId) {
    return;
  }

  // If you're not logged into Stripe as the guide, you'll need to modify these links by placing
  // the guide's account into the URL. And if you're viewing test data, you'll need to put /test
  // in the URL, too. See docs/payments.md
  // https://dashboard.stripe.com/GUIDE_ACCT/test/invoices/STRIPE_ID
  let collectionName = '';
  if (stripeId.startsWith('in_')) {
    collectionName = 'invoices';
  } else if (stripeId.startsWith('cus_')) {
    collectionName = 'customers';
  } else if (stripeId.startsWith('ch_')) {
    collectionName = 'charges';
  } else if (stripeId.startsWith('re_')) {
    collectionName = 'refunds';
  } else if (stripeId.startsWith('evt_')) {
    collectionName = 'events';
  } else if (stripeId.startsWith('pi_')) {
    collectionName = 'payments';
  }

  if (!collectionName) {
    return;
  }

  return `https://dashboard.stripe.com/${collectionName}/${stripeId}`;
}

export function StripeLink({
  stripeId,
  children,
}: {
  stripeId?: string;
  children: ComponentChildren;
}) {
  const href = stripeHref(stripeId);

  if (!href) {
    return null;
  }

  return (
    <a
      target="_blank"
      rel="noreferrer"
      href={href}
      class="text-inherit font-normal inline-flex items-center gap-1"
    >
      {children}
    </a>
  );
}
