import { mediaMiddleware, generateMediaCard } from '@components/media-card';
import { toolbarDropdown } from '@components/toolbar-dropdown';
import { useDisposableMemo } from 'client/lib/hooks';
import {
  cardMiddleware,
  defaultToolbarActions,
  minidoc,
  MinidocCore,
  minidocToolbar,
  placeholder,
} from 'minidoc-editor';
import { ComponentChildren, createContext } from 'preact';
import { useMinidoc } from '@components/minidoc';
import { rawHtmlCard } from '@components/raw-html-card';
import { useContext, useState } from 'preact/hooks';
import { EditorWrapper } from '@components/minidoc/minidoc-root';
import { useIntl } from 'shared/intl/use-intl';
import { ManualDom } from '@components/manual-dom';
import { Button } from '@components/buttons';
import { redoArrow, undoArrow } from '@components/icons';
import { embedHtmlCard } from '@components/raw-html-card';

export type ToolbarPosition = 'left' | 'center' | 'right';

/**
 * We have a root toolbar that needs to display the currently focused
 * rich text editor's controls. We use this context to set / unset it.
 */
export const RichTextToolbarContext = createContext<{
  toolbar: Element | undefined;
  setToolbar(el: Element | undefined): void;
}>({ toolbar: undefined, setToolbar: () => {} });

interface Props {
  doc: string;
  onChange(doc: string): void;
  isSelected?: boolean;
  class?: string;
  toolbarPosition?: ToolbarPosition;
}

const mediaCard = generateMediaCard({
  cdnWidth: 1600,
  loadImagesEagerly: true,
});

function Minidoc(props: { class?: string; editor: MinidocCore }) {
  return <EditorWrapper class={`text-inherit ${props.class || ''}`} editor={props.editor} />;
}

/**
 * Provides a container that has a toolbar at the top. The toolbar can be
 * changed by any of the child-components by using the wrapped context.
 */
export function RichTextWrapper({
  children,
  onUndo,
  onRedo,
}: {
  children: ComponentChildren;
  onUndo(): void;
  onRedo(): void;
}) {
  const intl = useIntl();
  const [toolbar, setToolbar] = useState<Element | undefined>(undefined);
  // If there is no toolbar, we'll display this one with a "disabled" overlay
  // to avoid having a big empty white gap at the top of the page.
  const defaultToolbar = useMinidoc({
    doc: '',
    middleware: () => [minidocToolbar([...defaultToolbarActions, toolbarDropdown({ intl })])],
    onChange() {},
  }).toolbar.root;

  return (
    <>
      <div class="lesson-editor-toolbar px-2 border-b shadow-xs text-gray-700 text-sm inline-flex flex-wrap items-center justify-center relative z-40">
        <div class="hidden lg:inline-flex">
          <div class="minidoc-toolbar">
            <Button
              class="minidoc-toolbar-btn"
              dangerouslySetInnerHTML={{ __html: undoArrow() }}
              onClick={onUndo}
              aria-label="Undo"
              tabIndex={-1}
            ></Button>
            <Button
              class="minidoc-toolbar-btn"
              dangerouslySetInnerHTML={{ __html: redoArrow() }}
              onClick={onRedo}
              aria-label="Redo"
              tabIndex={-1}
            ></Button>
          </div>
        </div>
        {toolbar && <ManualDom el={toolbar} />}
        {!toolbar && (
          <div class="relative">
            <ManualDom el={defaultToolbar} />
            <div class="absolute inset-0 bg-white z-10 opacity-50"></div>
          </div>
        )}
      </div>
      <RichTextToolbarContext.Provider value={{ toolbar, setToolbar }}>
        <div
          class="flex flex-col grow overflow-auto"
          onFocusCapture={(e: any) => {
            // If we enter a non-rich-text element, we'll hide the toolbar.
            if (!e.target.closest('.minidoc-editor')) {
              setToolbar(undefined);
            }
          }}
        >
          {children}
        </div>
      </RichTextToolbarContext.Provider>
    </>
  );
}

export function RichTextEditor(props: Props) {
  const intl = useIntl();
  const ctx = useContext(RichTextToolbarContext);
  // The minidoc instance for this block.
  const editor = useMinidoc({
    doc: props.doc,
    middleware: () => [
      /**
       * Adds placeholder text support to minidoc.
       */
      placeholder('Edit this text or add an image, video, or other media.'),
      /**
       * This adds toolbar support to minidoc. We don't want h1 support, so
       * we remove that from the defuault toolbar actions. And we *do* want
       * media support (the ability to place files within the document), so
       * we'll add our own media toolbar action.
       */
      minidocToolbar([...defaultToolbarActions, toolbarDropdown({ intl })]),
      /**
       * Here is where we register the cards we want to use. Media is for files.
       * In the future, we'll register surveys, quizes, etc here.
       */
      cardMiddleware([mediaCard, rawHtmlCard, embedHtmlCard]),
      /**
       * This extends the editor to have an `insertMedia` method which is used
       * by the media toolbar action.
       */
      mediaMiddleware({
        // Salespage is public so images should be public, too.
        isPublic: true,
      }),
    ],
    onChange: props.onChange,
  });

  const showToolbar = () => {
    if (ctx.toolbar !== editor.toolbar.root) {
      ctx.setToolbar(editor.toolbar.root);
    }
  };

  return (
    <div
      class="relative"
      tabIndex={-1}
      onMouseDownCapture={showToolbar}
      onFocusCapture={showToolbar}
    >
      <Minidoc class={props.class} editor={editor} />
    </div>
  );
}

export function RichTextViewer(props: Pick<Props, 'doc' | 'class'>) {
  const intl = useIntl();
  const editor = useDisposableMemo(() => {
    const result = minidoc({
      doc: props.doc,
      readonly: true,
      middleware: [
        minidocToolbar([...defaultToolbarActions, toolbarDropdown({ intl })]),
        cardMiddleware([mediaCard, rawHtmlCard, embedHtmlCard]),
        mediaMiddleware(),
      ],
    });

    // Open all external links in a new tab.
    const { origin } = window.location;
    result.root.querySelectorAll('a').forEach((el) => {
      const { href } = el;
      if (!href.startsWith(origin) || href.startsWith(`${origin}/files/`)) {
        el.target = '_blank';
        el.rel = 'noopener noreferrer';
      }
    });

    return result;
  }, []);

  return <Minidoc class={props.class} editor={editor} />;
}
