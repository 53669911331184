import { useMinidoc } from '@components/minidoc';
import { defaultToolbarActions, minidocToolbar, placeholder } from 'minidoc-editor';
import { ManualDom } from '@components/manual-dom';
import { EditorWrapper } from '@components/minidoc/minidoc-root';
import { useIntl } from 'shared/intl/use-intl';

interface Props {
  value: string;
  class?: string;
  onChange: (val: string) => void;
}

const wantedToolbarActions = ['bold', 'italic', 'blockquote', 'link'];

export function BioEditor({ value, class: className = '', onChange }: Props) {
  const intl = useIntl();

  const editor = useMinidoc({
    doc: value,
    middleware: () => [
      placeholder(intl('Bio')),
      minidocToolbar([...defaultToolbarActions.filter((a) => wantedToolbarActions.includes(a.id))]),
    ],
    onChange: (doc) => {
      onChange(doc);
    },
  });

  return (
    <div class={`${className} border`}>
      <ManualDom el={editor.toolbar.root} />
      <div class="bg-white dark:bg-gray-900 p-4 rounded-b-lg border-t">
        <EditorWrapper class="prose dark:prose-invert" editor={editor} />
      </div>
    </div>
  );
}
