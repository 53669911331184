import { useMemo, StateUpdater, Dispatch } from 'preact/hooks';

/**
 * A helper function for generating a state updater that
 * manages a subset of the state tree.
 */
export function substateUpdater<T, TSub>(
  setState: Dispatch<StateUpdater<T>>,
  getter: (state: T) => TSub,
  setter: (state: T, sub: TSub) => T,
): Dispatch<StateUpdater<TSub>> {
  return (v) => {
    const f: any = typeof v === 'function' ? v : () => v;
    setState((s) => setter(s, f(getter(s))));
  };
}

/**
 * A helper function for generating a state updater that
 * updates a partial version of the state.
 */
export function partialStateUpdater<T>(setState: Dispatch<StateUpdater<T>>) {
  return (newState: Partial<T>) => setState((s) => ({ ...s, ...newState }));
}

export function useSubstate<T, TSub>(
  setState: Dispatch<StateUpdater<T>>,
  getter: (state: T) => TSub,
  setter: (state: T, sub: TSub) => T,
) {
  return useMemo(() => substateUpdater(setState, getter, setter), [setState]);
}
