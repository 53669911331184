import register from 'preact-custom-element';
import { VidstackPlayer, Props as VidstackProps } from '@course/components/vidstack-player';

export type Props = VidstackProps;
export const tagName = 'vidstack-card';

function Wrapper(
  props: Props & {
    hascaptions?: string;
    hidecaptions?: string;
    captionsurl?: string;
  },
) {
  return (
    <VidstackPlayer
      {...props}
      // Re-assign the HTML attribute to the correct prop name
      hasCaptions={props.hascaptions === 'true'}
      captionsURL={props.captionsurl}
      hideCaptions={props.hidecaptions === 'true'}
    />
  );
}

register(
  Wrapper,
  tagName,
  ['url', 'poster', 'captions', 'downloadUrl', 'type', 'load', 'overlay', 'ratio', 'hidecaptions'],
  {
    shadow: false,
  },
);
