import { IcoLogo } from '@components/icons';
import { useCurrentTenant } from '@components/router/session-context';
import { BrandRow } from 'server/types';

/**
 * The application logo and branding.
 */
export function SiteNavLogo(props: {
  href?: string;
  brand?: Pick<BrandRow, 'name' | 'showName' | 'iconUrl' | 'logoColor'>;
}) {
  const tenant = useCurrentTenant();
  const brand = props.brand || tenant;
  return (
    <a
      href={props.href}
      class="theme-logo-color flex items-center gap-2"
      style={props.brand?.logoColor ? `color: ${props.brand.logoColor}` : ''}
    >
      <IcoLogo class={brand.showName ? 'h-8' : 'h-10'} url={brand.iconUrl} />
      {brand.showName && (
        <span class="text-lg leading-6">{brand.name || tenant.name || 'ruzuku'}</span>
      )}
    </a>
  );
}
