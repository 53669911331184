import { ComponentChildren } from 'preact';

export function ItemTitle({
  ico,
  title,
  subtitle,
}: {
  ico: ComponentChildren;
  title: string;
  subtitle: ComponentChildren;
}) {
  return (
    <header class="flex items-center mb-4">
      {ico && <span class="mr-4">{ico}</span>}
      <div>
        <h2 class="text-lg">{title}</h2>
        <span class="text-gray-500">{subtitle}</span>
      </div>
    </header>
  );
}
