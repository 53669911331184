import { StudentMeeting } from 'server/types';
import { BtnPrimary } from '@components/buttons';
import { useEffect, useState } from 'preact/hooks';
import { showError } from '@components/app-error';
import { useRouteParams, useRouter } from '@components/router';
import { rpx } from 'client/lib/rpx-client';
import { useIntl } from 'shared/intl/use-intl';
import dayjs from 'dayjs';
import { BBB_AUTORECORD_THRESHOLD, getMeetingStatus } from 'shared/meeting-utils';
import { Spinner } from '@components/spinner';
import { showDialog } from '@components/dialog';

interface Props {
  meeting: StudentMeeting;
  isGuide: boolean;
}

function openUrl({ url, errorText }: { url?: string; errorText: string }) {
  if (url) {
    const newWindow = window.open(url, '_blank');

    // This means we couldn't open the url in a new tab
    // bcause of the pop-up blocker.
    // So we should open it in the same tab.
    if (!newWindow) {
      window.location.href = url;
    }
  } else {
    showError(new Error(errorText));
  }
}

export function GuideMeetingActions({
  meeting,
  class: className = 'w-full text-lg leading-8 p-3',
}: {
  meeting: StudentMeeting;
  class?: string;
}) {
  const { courseId } = useRouteParams();
  const [isLoading, setIsLoading] = useState(false);
  const intl = useIntl();

  async function join() {
    if (
      meeting.recordMeeting &&
      (meeting.type === 'videoconference' || meeting.type === 'presentation') &&
      dayjs(meeting.scheduledAt).diff(dayjs(), 'minute') > BBB_AUTORECORD_THRESHOLD
    ) {
      const isConfirmed = await showDialog({
        mode: 'warn',
        title: 'Join meeting now?',
        children:
          'You are about to join the meeting early and the auto recording will not be started automatically. Please start it by clicking "start recording" button after the meeting starts.',
        confirmButtonText: 'Join meeting',
      });
      if (!isConfirmed) {
        return;
      }
    }

    setIsLoading(true);
    try {
      const url = await rpx.meetings.joinMeetingAsGuide({
        courseId,
        meetingId: meeting.id,
      });
      openUrl({
        url,
        errorText: intl('Something went wrong while joining the meeting room. Please try again.'),
      });
    } catch (err) {
      showError(err);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <BtnPrimary class={className} isLoading={isLoading} onClick={join}>
      {intl('Join Meeting')}
    </BtnPrimary>
  );
}

function StudentActions({ meeting }: { meeting: StudentMeeting }) {
  const { join: autoJoin, courseId } = useRouteParams();
  const intl = useIntl();
  const status = getMeetingStatus(meeting);
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);

  /*
   * Meeting is starting emails has a "join meeting" link
   * which is simply the url of this page with `join` query string parameter.
   * So we're joining the meeting automatically when that parameter is present.
   */
  useEffect(() => {
    if ((status === 'inProgress' || status === 'aboutToStart') && autoJoin) {
      joinMeeting();

      // Remove `join` from the url to prevent auto-joining
      // the meeting when students come back to this page
      // using browser back button.
      router.rewrite(window.location.pathname);
    }
  }, [autoJoin, status]);

  // Join the meeting automatically when the status changes to `inProgress`
  useEffect(() => {
    if (isWaiting && status === 'inProgress') {
      joinMeeting();
    }
  }, [status, isWaiting]);

  async function joinMeeting() {
    if (status === 'aboutToStart') {
      setIsWaiting(true);
      return;
    }

    setIsLoading(true);
    try {
      const result = await rpx.meetings.getMeetingJoinUrl({
        courseId,
        meetingId: meeting.id,
      });
      openUrl({
        url: result.joinUrl,
        errorText: intl('Something went wrong while joining the meeting room. Please try again.'),
      });
    } catch (err) {
      showError(err);
    } finally {
      setIsLoading(false);
    }
  }

  if (isWaiting) {
    return (
      <BtnPrimary class="w-full text-lg leading-8 p-3" disabled>
        <Spinner />
        <span class="ml-2">
          {intl('You will be redirected to the meeting at {time:string}', {
            time: dayjs(meeting.scheduledAt).format('HH:mm'),
          })}
        </span>
      </BtnPrimary>
    );
  }

  return (
    <BtnPrimary
      class="w-full text-lg leading-8 p-3"
      disabled={status !== 'aboutToStart' && status !== 'inProgress'}
      isLoading={isLoading}
      onClick={joinMeeting}
    >
      {intl('Join Meeting')}
    </BtnPrimary>
  );
}

export function MeetingActions({ meeting, isGuide }: Props) {
  return isGuide ? <GuideMeetingActions meeting={meeting} /> : <StudentActions meeting={meeting} />;
}
