import { ComponentChildren, JSX } from 'preact';
import { useEffect, useRef } from 'preact/hooks';

interface Props extends JSX.HTMLAttributes<HTMLDivElement> {
  scrollTo: string;
  /*
   * Moves the selected scroll element instead of the page scroll.
   */
  scrollSelector?: string;
  class?: string;
  scrollBehavior?: ScrollBehavior;
  children?: ComponentChildren;
}

/**
 * This component scrolls the specified child into view when it mounts.
 * The scrollTo is a CSS selector of the child to be scrolled into view.
 */
export function AutoScroll({
  scrollTo,
  scrollSelector,
  scrollBehavior = 'auto',
  class: className = '',
  children,
  ...props
}: Props) {
  const root = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!root.current || !scrollTo) {
      return;
    }

    const target = root.current.querySelector(scrollTo) as HTMLElement;
    if (!target) {
      return;
    }

    if (scrollSelector) {
      const scrollTarget = document.querySelector(scrollSelector) as HTMLElement;
      const navHeight = scrollTarget.getBoundingClientRect().top;

      const { top, height } = target.getBoundingClientRect();
      const targetPosition = top - navHeight;

      // adding the height two times to account for the top and bottom margin
      const threshold = targetPosition + 2 * height;
      if (threshold > window.innerHeight) {
        scrollTarget.scrollTop = targetPosition;
      }
    } else {
      root.current?.querySelector(scrollTo)?.scrollIntoView({
        behavior: scrollBehavior,
        block: 'center',
        inline: 'center',
      });
    }
  }, [root, scrollTo, scrollSelector]);

  return (
    <div {...props} class={className} ref={root}>
      {children}
    </div>
  );
}
