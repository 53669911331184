import { ComponentChildren } from 'preact';
import { useContext } from 'preact/hooks';
import { Subtext } from './subtext';
import { AutoFormContext, FormErrors } from './auto-form';

export function errClass(errs: FormErrors, prop: string) {
  return (errs && errs[prop] && 'err-section') || '';
}

interface ErrProps {
  errs: FormErrors;
  prop: string;
}

export function FieldError({ children }: { children: ComponentChildren }) {
  return <div className="err-message text-red-600 mt-2">{children}</div>;
}

/**
 * Form error message, displayed above a form element, and warning icon
 */
export function FormError({ errs, prop = '$message' }: ErrProps) {
  const msg = errs && errs[prop];
  if (!msg) {
    return null;
  }
  return <FieldError>{msg}</FieldError>;
}

/**
 * A container for forms inputs and labels, in a "stacked" vertical layout
 */
export function FormGroup({
  prop,
  class: className,
  children,
}: {
  prop: string;
  class?: string;
  children?: ComponentChildren;
}) {
  const { errors } = useContext(AutoFormContext);
  return (
    <section class={`${className} ${errClass(errors, prop)}`}>
      {children}
      <FormError errs={errors} prop={prop} />
    </section>
  );
}

export interface Props {
  prop: string;
  class?: string;
  labelText?: ComponentChildren;
  subText?: ComponentChildren;
  children?: ComponentChildren;
}

/**
 * A container for forms inputs plus labels, with a standard a two-column layout
 */
export function FormGroupTwoCol({
  prop,
  labelText,
  subText,
  class: className = '',
  children,
}: Props) {
  return (
    <section
      class={`flex flex-col md:grid md:grid-cols-2 md:border-b border-gray-200 py-4 md:space-x-8 ${className}`}
    >
      <div class="mb-1.5 md:mb-0">
        {labelText && <label class="text-gray-900">{labelText}</label>}
        {subText && (
          <div class="block mt-2">
            <Subtext>{subText}</Subtext>
          </div>
        )}
      </div>
      <FormGroup prop={prop}>{children}</FormGroup>
    </section>
  );
}
