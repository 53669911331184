import { IcoDuplicate, IcoGripperHorizontal, IcoTrash } from '@components/icons';
import { QuestionEditor } from '../assessment-question';
import { AssignmentQuestion } from 'server/types';
import { Button } from '@components/buttons';
import { RadioButton } from '@course/components/radio-button';

interface Props {
  question: AssignmentQuestion;
  canDelete: boolean;
  onChange: (newQuestion: AssignmentQuestion) => void;
  onDelete: () => void;
  onDuplicate: () => void;
}

export function AssignmentItem({ question, onChange, canDelete, onDelete, onDuplicate }: Props) {
  function updateQuestion(newQuestion: Partial<AssignmentQuestion>) {
    onChange({
      ...question,
      ...newQuestion,
    });
  }

  return (
    <div class="bg-white shadow-sm p-4 rounded-sm mb-4 border-l-4 border-indigo-600">
      <div class="absolute w-full flex justify-center text-gray-400 cursor-move" draggable>
        <IcoGripperHorizontal class="w-5 h-5" />
      </div>
      <QuestionEditor
        question={question}
        placeholder="Enter a question or prompt that your students will respond to as part of this assignment. In their private response to you, they can enter text, images, videos or files (such as PDFs)."
        minHeight="min-h-32"
        onChange={(updates) => updateQuestion(updates)}
      />
      <footer class="flex justify-end border-t pt-4">
        <div class="flex items-center divide-x-2">
          <div class="space-x-2 pr-4">
            <div class="flex items-center gap-2">
              <RadioButton
                class="focus:ring-0"
                name="required"
                m="m-0"
                textClass="ml-1 text-xs"
                checked={question.isRequired}
                onClick={(e) => {
                  e.preventDefault();
                  updateQuestion({
                    isRequired: true,
                  });
                }}
              >
                Mandatory
              </RadioButton>
              <RadioButton
                class="focus:ring-0"
                name="required"
                m="m-0"
                textClass="ml-1 text-xs"
                checked={!question.isRequired}
                onClick={(e) => {
                  e.preventDefault();
                  updateQuestion({
                    isRequired: false,
                  });
                }}
              >
                Optional
              </RadioButton>
            </div>
          </div>
          <div class="pl-4">
            <Button
              class="p-2 mr-2 text-gray-500 hover:bg-indigo-500 hover:text-white focus:ring-2 focus:ring-indigo-500 outline-hidden rounded-sm"
              onClick={onDuplicate}
              title="Duplicate"
            >
              <IcoDuplicate class="w-6 h-6" />
            </Button>
            <Button
              class="p-2 mr-2 text-gray-500 hover:bg-red-500 hover:text-white focus:ring-2 focus:ring-red-500 outline-hidden rounded-sm"
              disabled={!canDelete}
              onClick={onDelete}
              title="Delete"
            >
              <IcoTrash class="w-6 h-6" />
            </Button>
          </div>
        </div>
      </footer>
    </div>
  );
}
