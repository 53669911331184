import { moduleAvailableOn } from '@course/components/module-helpers';
import { LoadedProps, RouteLoadProps } from '@components/router';
import { globalConfig } from 'client/lib/auth';
import { rpx } from 'client/lib/rpx-client';
import { getMeetingStatus } from 'shared/meeting-utils';
import { emptyLessonTitle, emptyModuleTitle } from 'shared/terminology';
import { URLS } from 'shared/urls';
import { groupBy } from 'shared/utils';
import { Module } from '@course/components/module-helpers/module-card';

export async function load(route: RouteLoadProps) {
  const { courseId } = route.params;
  const [course, state, meetings, recentSignups] = await Promise.all([
    rpx.courses.getGuideCourse({ id: courseId }),
    rpx.lessons.getFullLessonState({ courseId }),
    rpx.meetings.getStudentMeetings({ courseId }),
    rpx.courseOverview.getRecentSignups({ courseId, limit: 3 }),
  ]);

  if (course.level !== 'guide') {
    route.router.goto(URLS.guide.lessons({ courseId }));
  }

  const { completedLessons } = state;

  const moduleLessons = groupBy((x) => x.moduleId, state.lessons);
  const modulesMap = state.modules.reduce((acc, module) => {
    const lessons = (moduleLessons[module.id] || []).map((lesson) => ({
      id: lesson.id,
      title: lesson.title || emptyLessonTitle(globalConfig().tenant),
      isCompleted: completedLessons.includes(lesson.id),
      isPrerequisite: lesson.isPrerequisite,
      assessmentType: lesson.assessmentType,
      hasDiscussion: !!lesson.discussion?.isEnabled,
      hasAssignment: lesson.assessmentType === 'assignment',
      href: URLS.guide.lesson({ courseId: course.id, lessonId: lesson.id }),
    }));
    const stats = lessons.reduce(
      (acc, lesson) => {
        if (lesson.hasDiscussion) {
          acc.discussions++;
        }
        if (lesson.hasAssignment) {
          acc.assignments++;
        }

        return acc;
      },
      {
        discussions: 0,
        assignments: 0,
      },
    );

    acc[module.id] = {
      id: module.id,
      title: module.title || emptyModuleTitle(globalConfig().tenant),
      availableOn: moduleAvailableOn({
        isAbsoluteSchedule: course.isAbsoluteSchedule,
        module,
        accessFormat: course.accessFormat,
        membershipDate: state.membershipDate,
      }),
      lessons,
      stats,
      isDraft: module.isDraft,
    };
    return acc;
  }, {} as Record<UUID, Module>);

  const modules = Object.values(modulesMap);

  return {
    ...state,
    course,
    modules,
    recentSignups,
    upcomingMeetings: meetings.filter((m) => {
      return getMeetingStatus(m) !== 'ended';
    }),
  };
}

export type Props = LoadedProps<typeof load>;
