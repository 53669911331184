import { Button } from '@components/buttons';
import { CreateCourseModal } from '@course/components/create-course-modal';
import { useState } from 'preact/hooks';
import { RUZUKU_ASSETS_BASE_URL } from 'shared/consts';
import { URLS } from 'shared/urls';

function Item({
  title,
  desc,
  imagePath,
  href,
  onClick,
}: {
  title: string;
  desc: string;
  imagePath: string;
  href?: string;
  onClick?: () => void;
}) {
  return (
    <Button
      class="flex lg:flex-col xl:flex-row items-center rounded-2xl overflow-hidden hover:border-indigo-400 hover:ring-2 hover:ring-indigo-400 px-4 py-3 gap-2 xl:gap-6 bg-white shadow-sm border"
      href={href}
      onClick={onClick}
    >
      <img src={`${RUZUKU_ASSETS_BASE_URL}/${imagePath}`} class="rounded-xl h-20 w-20" />
      <span class="flex flex-col text-left">
        <span class="text-base font-semibold text-gray-900">{title}</span>
        <span class="text-gray-500 text-sm">{desc}</span>
      </span>
    </Button>
  );
}

export function CreateWidget() {
  const [modal, setModal] = useState<'course' | 'newproduct' | undefined>(undefined);

  return (
    <div class="flex flex-col lg:flex-row gap-2 lg:gap-4">
      {modal !== undefined && (
        <CreateCourseModal initialPane={modal} isOpen hide={() => setModal(undefined)} />
      )}
      <Item
        title={`Create a course`}
        desc="Engage and support students with discussions, chats, meetings, and assessments."
        imagePath="new-course.svg"
        onClick={() => setModal('course')}
      />
      <Item
        title="Create a product"
        desc="Sell structured content (such as eBooks and videos) or take payment for a service package."
        imagePath="new-product.svg"
        onClick={() => setModal('newproduct')}
      />
      <Item
        title="Browse Instant Courses"
        desc="Explore 30+ professionally-designed courses that you can customize and make your own."
        imagePath="instant-courses.svg"
        href={URLS.guide.instantCourses()}
      />
    </div>
  );
}
