import dayjs from 'dayjs';
import { Meeting } from 'server/types';

type MeetingStatusProp = Pick<Meeting, 'scheduledAt' | 'durationMinutes'>;

type MeetingStatus = 'draft' | 'scheduled' | 'aboutToStart' | 'inProgress' | 'ended';

// We display a message to guides instead of auto-recording the meeting
// if the guide joins the meeting less than 1 minute before the meeting scheduled time
export const BBB_AUTORECORD_THRESHOLD = 1;

// We let students click on the join button 5 minutes before the meeting scheduled time
const ABOUT_TO_START_THRESHOLD = -5;

export function getMeetingStatus({
  scheduledAt,
  durationMinutes,
}: MeetingStatusProp): MeetingStatus {
  if (!scheduledAt) {
    return 'draft';
  }

  const now = dayjs();
  const diff = now.diff(scheduledAt, 'minute');

  // Mark the meeting as ended if when the meeting duration is passed
  if (diff > durationMinutes) {
    return 'ended';
  }

  if (now.isAfter(scheduledAt)) {
    return 'inProgress';
  }

  return diff < ABOUT_TO_START_THRESHOLD ? 'scheduled' : 'aboutToStart';
}

/*
 * Group meetings into past and upcoming lists.
 */
export function groupMeetingsByStatus<W extends MeetingStatusProp>(meetings: W[]) {
  return meetings.reduce(
    (acc, meeting) => {
      const status = getMeetingStatus(meeting);
      if (status === 'draft') {
        acc.draft.push(meeting);
      } else if (status === 'ended') {
        // Past meetings are listed on reverse order
        acc.past.unshift(meeting);
      } else {
        acc.upcoming.push(meeting);
      }
      return acc;
    },
    {
      draft: [] as W[],
      past: [] as W[],
      upcoming: [] as W[],
    },
  );
}
