import { render } from 'preact-render-to-string';
import { Line, BasicEmailWrapper, PrimaryAction, SupportFooter, Heading } from './shared';
import { Tenant } from 'server/types';

export function AccountRestricted({ tenant }: { tenant: Tenant }) {
  const actionUrl = `https://${tenant.domain}/account/billing`;

  return render(
    <BasicEmailWrapper footer={<SupportFooter />}>
      <Heading>We've converted your Ruzuku account to our limited plan.</Heading>
      <Line>
        You still have access to all of your courses. You're limited to a maximum of 5 student
        signups (across all of your courses). To register more students, you'll need to{' '}
        <a href={actionUrl}>sign up for a paid Ruzuku plan</a>.
      </Line>
      <Line>
        Questions? We're here to help anytime at{' '}
        <a href="mailto:support@ruzuku.com">support@ruzuku.com</a>
      </Line>
      <PrimaryAction href={actionUrl}>Manage your Ruzuku account</PrimaryAction>
    </BasicEmailWrapper>,
  );
}
