import { rpx, RpxResponse } from 'client/lib/rpx-client';
import {
  AssessmentType,
  CourseWithGuide,
  Downloads,
  MembershipLevel,
  ModuleRow,
} from 'server/types';

export type Course = CourseWithGuide & { modules: UUID[] };

export type Module = Pick<
  ModuleRow,
  'id' | 'title' | 'courseId' | 'seq' | 'prices' | 'startOffset' | 'isDraft'
> & {
  // We'll treat startDate as a date on the client, as it's the most
  // convenient option, but we'll treat it as a string in our communication
  // layer in order to prevent automatic translation from local time to UTC.
  // If the user says startDate='July 4, 2021' then we wan it to be stored
  // exactly as that date in UTC and in all time zones.
  startDate?: Date;
  lessons: UUID[];
};

export type FullLesson = RpxResponse<typeof rpx.lessons.getLesson> & { type: 'full' };
export type PartialLesson = {
  type: 'partial';
  id: UUID;
  title: string;
  isAvailable?: boolean;
  isPrerequisite: boolean;
  content?: string;
  moduleId: UUID;
  discussion?: {
    id: UUID;
    isEnabled: boolean;
  };
  hasCommented?: boolean;
  notifyAssignmentSubmissions?: boolean;
  requireAssignmentApproval?: boolean;
  assessmentType?: AssessmentType;
  downloads?: Downloads;
};

export type Lesson = FullLesson | PartialLesson;

export type State = {
  courseId: UUID;
  lessonId?: UUID;
  showCert?: boolean;
  membershipDate: Date;
  loadingLesson?: UUID;
  course: Course;
  modules: Record<UUID, Module>;
  lessons: Record<UUID, Lesson>;
  completedLessons: UUID[];
  orderedLessonIds: UUID[];
  accessLevel: MembershipLevel;
};

export interface ManageState extends State {
  moduleId?: UUID;
  deleting?: { type: 'lesson' | 'module'; id: UUID };
  adding?: {
    type: 'lesson' | 'module';
    /**
     * The id of the module after which the new module, or to
     * the end of which the new lesson will be inserted.
     */
    moduleId?: UUID;
  };
}
