import { AccessFormat } from 'server/types';

const accessFormats: Record<AccessFormat, string> = {
  ondemand: 'Drip (Individual)',
  openaccess: 'Full Access',
  scheduled: 'Drip (Calendar, Student Timezone)',
};

export function describeAccessFormat(accessFormat?: AccessFormat) {
  return accessFormat ? accessFormats[accessFormat] : '';
}
