import { LoadedProps, RouteLoadProps } from '@components/router';
import { rpx } from 'client/lib/rpx-client';
import { URLS } from 'shared/urls';

export type PageType = 'customers' | 'students' | 'progress' | 'gifts';

export const store = rpx.manageStudents;

export type FilterOpts = Parameters<(typeof store)['getNextStudents']>[0];

export type OrderBy = Pick<FilterOpts, 'orderBy' | 'orderDirection'>;

export type Props = LoadedProps<typeof load>;

export async function load(route: RouteLoadProps) {
  const { courseId, userId } = route.params;
  const opts: FilterOpts = {
    courseId,
    orderBy: 'signupDate',
    orderDirection: 'desc',
    searchTerm: '',
    access: 'all',
  };
  const result = await store.getInitialState({ courseId, userId, opts });

  if (result.course.level !== 'guide' && route.params.pageType !== 'progress') {
    route.router.goto(URLS.guide.progress({ courseId }));
  }

  return {
    ...result,
    terminology: result.course.isProduct ? 'customers' : 'students',
    opts,
  };
}

export function setOrderBy(setState: Props['setState'], orderBy: FilterOpts['orderBy']) {
  setState((s) => ({
    ...s,
    opts: {
      ...s.opts,
      orderBy,
      orderDirection:
        s.opts.orderBy !== orderBy || s.opts.orderDirection === 'asc' ? 'desc' : 'asc',
      startingAfter: undefined,
    },
  }));
}
