import { Button } from '@components/buttons';
import { showError } from '@components/app-error';
import { ModalForm, showModalForm } from '@components/modal-form';
import { useState } from 'preact/hooks';
import { showToast } from '@components/toaster';
import { Course } from 'server/types';
import { TenantUser, TenantUsersList } from '../admin-people/user-list';
import { UserProfileIcon } from '@components/avatars';
import { truncateId } from 'shared/utils';
import { rpx } from 'client/lib/rpx-client';
import { IcoChevronLeft } from '@components/icons';
import { StandardDialog } from '@components/dialog';

export function showEnrollAStudentModal(course: Pick<Course, 'id' | 'title'>) {
  showModalForm(({ resolve }) => {
    const [student, setStudent] = useState<TenantUser | undefined>(undefined);

    async function addStudentToCourse() {
      if (!student) {
        return;
      }

      try {
        await rpx.admin.addStudentToCourse({
          courseId: course.id,
          studentId: student.id,
        });
        showToast({
          title: 'Succeeded',
          message: `'${student.name}' has been added to '${course.title}' course!`,
          type: 'ok',
        });
        resolve(true);
        location.reload();
      } catch (err) {
        showError(err);
      }
    }

    if (student) {
      return (
        <ModalForm
          onClose={resolve}
          onSubmit={addStudentToCourse}
          title={
            <>
              Do you want to add <em>{student.name}</em> to
              <em class="mx-0.5">{course.title}</em> course?
            </>
          }
          confirmButtonText="Yes, add the user"
        >
          <Button class="inline-flex items-center" onClick={() => setStudent(undefined)}>
            <IcoChevronLeft class="w-4 h-4 opacity-75 mr-1" />
            Back
          </Button>
        </ModalForm>
      );
    }

    return (
      <StandardDialog onClose={resolve} title="Choose a student">
        <TenantUsersList
          type="all"
          userLevels={['student', 'guide', 'admin']}
          tableHeaders={['Student', 'Role / ID']}
          onSelect={setStudent}
          render={({ user, href }) => (
            <a
              class="table-row text-inherit hover:bg-indigo-50 text-sm"
              href={`#${href}`}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setStudent(user);
              }}
            >
              <span class="table-cell p-4 border-t">
                <span class="flex items-center">
                  <UserProfileIcon user={user} size="w-10 h-10" />
                  <span class="flex flex-col ml-4">
                    <strong>{user.name}</strong>
                    <span>{user.email}</span>
                  </span>
                </span>
              </span>
              <span class="table-cell p-4 border-t">
                <span class="flex flex-col">
                  <span>{user.level}</span>
                  <span>{truncateId(user.id)}</span>
                </span>
              </span>
            </a>
          )}
        />
      </StandardDialog>
    );
  });
}
