/*
 * Icons imported from https://vidstack.io/icons?lib=react
 */
import { SVGProps } from './index';

const closedCaptionsPath =
  'M4.6661 6.66699C4.29791 6.66699 3.99943 6.96547 3.99943 7.33366V24.667C3.99943 25.0352 4.29791 25.3337 4.6661 25.3337H27.3328C27.701 25.3337 27.9994 25.0352 27.9994 24.667V7.33366C27.9994 6.96547 27.701 6.66699 27.3328 6.66699H4.6661ZM8.66667 21.3333C8.29848 21.3333 8 21.0349 8 20.6667V11.3333C8 10.9651 8.29848 10.6667 8.66667 10.6667H14C14.3682 10.6667 14.6667 10.9651 14.6667 11.3333V12.6667C14.6667 13.0349 14.3682 13.3333 14 13.3333H10.8C10.7264 13.3333 10.6667 13.393 10.6667 13.4667V18.5333C10.6667 18.607 10.7264 18.6667 10.8 18.6667H14C14.3682 18.6667 14.6667 18.9651 14.6667 19.3333V20.6667C14.6667 21.0349 14.3682 21.3333 14 21.3333H8.66667ZM18 21.3333C17.6318 21.3333 17.3333 21.0349 17.3333 20.6667V11.3333C17.3333 10.9651 17.6318 10.6667 18 10.6667H23.3333C23.7015 10.6667 24 10.9651 24 11.3333V12.6667C24 13.0349 23.7015 13.3333 23.3333 13.3333H20.1333C20.0597 13.3333 20 13.393 20 13.4667V18.5333C20 18.607 20.0597 18.6667 20.1333 18.6667H23.3333C23.7015 18.6667 24 18.9651 24 19.3333V20.6667C24 21.0349 23.7015 21.3333 23.3333 21.3333H18Z';

export const LiteralCaptionsIcon = `<svg class="h-6 w-6" fill="none" viewBox="0 0 32 32" aria-hidden="true"><path d="${closedCaptionsPath}" fill="currentColor"/></svg>`;

/**
 * name: ClosedCaptionsIcon
 */
export function IcoClosedCaptions(props: SVGProps) {
  return (
    <svg class="w-6 h-6" viewBox="0 0 32 32" fill="none" aria-hidden="true" {...props}>
      <path d={closedCaptionsPath} fill="currentColor" />
    </svg>
  );
}

/**
 * name: ClosedCaptionsOnIcon
 */
export function IcoClosedCaptionsOn(props: SVGProps) {
  return (
    <svg class="w-6 h-6" viewBox="0 0 32 32" fill="none" aria-hidden="true" {...props}>
      <path
        d="M8 28.0003C8 27.6321 8.29848 27.3336 8.66667 27.3336H23.3333C23.7015 27.3336 24 27.6321 24 28.0003V29.3336C24 29.7018 23.7015 30.0003 23.3333 30.0003H8.66667C8.29848 30.0003 8 29.7018 8 29.3336V28.0003Z"
        fill="currentColor"
      />
      <path
        d="M4.66602 6.66699C4.29783 6.66699 3.99935 6.96547 3.99935 7.33366V24.667C3.99935 25.0352 4.29783 25.3337 4.66602 25.3337H27.3327C27.7009 25.3337 27.9994 25.0352 27.9994 24.667V7.33366C27.9994 6.96547 27.7009 6.66699 27.3327 6.66699H4.66602ZM8.66659 21.3333C8.2984 21.3333 7.99992 21.0349 7.99992 20.6667V11.3333C7.99992 10.9651 8.2984 10.6667 8.66659 10.6667H13.9999C14.3681 10.6667 14.6666 10.9651 14.6666 11.3333V12.6667C14.6666 13.0349 14.3681 13.3333 13.9999 13.3333H10.7999C10.7263 13.3333 10.6666 13.393 10.6666 13.4667V18.5333C10.6666 18.607 10.7263 18.6667 10.7999 18.6667H13.9999C14.3681 18.6667 14.6666 18.9651 14.6666 19.3333V20.6667C14.6666 21.0349 14.3681 21.3333 13.9999 21.3333H8.66659ZM17.9999 21.3333C17.6317 21.3333 17.3333 21.0349 17.3333 20.6667V11.3333C17.3333 10.9651 17.6317 10.6667 17.9999 10.6667H23.3333C23.7014 10.6667 23.9999 10.9651 23.9999 11.3333V12.6667C23.9999 13.0349 23.7014 13.3333 23.3333 13.3333H20.1333C20.0596 13.3333 19.9999 13.393 19.9999 13.4667V18.5333C19.9999 18.607 20.0596 18.6667 20.1333 18.6667H23.3333C23.7014 18.6667 23.9999 18.9651 23.9999 19.3333V20.6667C23.9999 21.0349 23.7014 21.3333 23.3333 21.3333H17.9999Z"
        fill="currentColor"
      />
    </svg>
  );
}

/**
 * name: FullscreenExitIcon
 */
export function IcoFullScreenExit(props: SVGProps) {
  return (
    <svg class="w-6 h-6" viewBox="0 0 32 32" fill="none" aria-hidden="true" {...props}>
      <path
        d="M19.3334 13.3333C18.9652 13.3333 18.6667 13.0349 18.6667 12.6667L18.6667 7.33333C18.6667 6.96514 18.9652 6.66666 19.3334 6.66666H21.3334C21.7015 6.66666 22 6.96514 22 7.33333V9.86666C22 9.9403 22.0597 10 22.1334 10L24.6667 10C25.0349 10 25.3334 10.2985 25.3334 10.6667V12.6667C25.3334 13.0349 25.0349 13.3333 24.6667 13.3333L19.3334 13.3333Z"
        fill="currentColor"
      />
      <path
        d="M13.3334 19.3333C13.3334 18.9651 13.0349 18.6667 12.6667 18.6667H7.33335C6.96516 18.6667 6.66669 18.9651 6.66669 19.3333V21.3333C6.66669 21.7015 6.96516 22 7.33335 22H9.86669C9.94032 22 10 22.0597 10 22.1333L10 24.6667C10 25.0349 10.2985 25.3333 10.6667 25.3333H12.6667C13.0349 25.3333 13.3334 25.0349 13.3334 24.6667L13.3334 19.3333Z"
        fill="currentColor"
      />
      <path
        d="M18.6667 24.6667C18.6667 25.0349 18.9652 25.3333 19.3334 25.3333H21.3334C21.7015 25.3333 22 25.0349 22 24.6667V22.1333C22 22.0597 22.0597 22 22.1334 22H24.6667C25.0349 22 25.3334 21.7015 25.3334 21.3333V19.3333C25.3334 18.9651 25.0349 18.6667 24.6667 18.6667L19.3334 18.6667C18.9652 18.6667 18.6667 18.9651 18.6667 19.3333L18.6667 24.6667Z"
        fill="currentColor"
      />
      <path
        d="M10.6667 13.3333H12.6667C13.0349 13.3333 13.3334 13.0349 13.3334 12.6667L13.3334 10.6667V7.33333C13.3334 6.96514 13.0349 6.66666 12.6667 6.66666H10.6667C10.2985 6.66666 10 6.96514 10 7.33333L10 9.86666C10 9.9403 9.94033 10 9.86669 10L7.33335 10C6.96516 10 6.66669 10.2985 6.66669 10.6667V12.6667C6.66669 13.0349 6.96516 13.3333 7.33335 13.3333L10.6667 13.3333Z"
        fill="currentColor"
      />
    </svg>
  );
}

/**
 * name: MuteIcon
 */
export function IcoMute(props: SVGProps) {
  return (
    <svg class="w-6 h-6" viewBox="0 0 32 32" fill="none" aria-hidden="true" {...props}>
      <path
        d="M17.5091 24.6594C17.5091 25.2066 16.8864 25.5208 16.4463 25.1956L9.44847 20.0252C9.42553 20.0083 9.39776 19.9991 9.36923 19.9991H4.66667C4.29848 19.9991 4 19.7006 4 19.3325V12.6658C4 12.2976 4.29848 11.9991 4.66667 11.9991H9.37115C9.39967 11.9991 9.42745 11.99 9.45039 11.973L16.4463 6.8036C16.8863 6.47842 17.5091 6.79259 17.5091 7.33977L17.5091 24.6594Z"
        fill="currentColor"
      />
      <path
        d="M28.8621 13.6422C29.1225 13.3818 29.1225 12.9597 28.8621 12.6994L27.9193 11.7566C27.659 11.4962 27.2368 11.4962 26.9765 11.7566L24.7134 14.0197C24.6613 14.0717 24.5769 14.0717 24.5248 14.0197L22.262 11.7568C22.0016 11.4964 21.5795 11.4964 21.3191 11.7568L20.3763 12.6996C20.116 12.9599 20.116 13.382 20.3763 13.6424L22.6392 15.9053C22.6913 15.9573 22.6913 16.0418 22.6392 16.0938L20.3768 18.3562C20.1165 18.6166 20.1165 19.0387 20.3768 19.299L21.3196 20.2419C21.58 20.5022 22.0021 20.5022 22.2624 20.2418L24.5248 17.9795C24.5769 17.9274 24.6613 17.9274 24.7134 17.9795L26.976 20.2421C27.2363 20.5024 27.6585 20.5024 27.9188 20.2421L28.8616 19.2992C29.122 19.0389 29.122 18.6168 28.8616 18.3564L26.599 16.0938C26.547 16.0418 26.547 15.9573 26.599 15.9053L28.8621 13.6422Z"
        fill="currentColor"
      />
    </svg>
  );
}

/**
 * name: PauseIcon
 */
export function IcoPause(props: SVGProps) {
  return (
    <svg class="w-6 h-6" viewBox="0 0 32 32" fill="none" aria-hidden="true" {...props}>
      <path
        d="M8.66667 6.66667C8.29848 6.66667 8 6.96514 8 7.33333V24.6667C8 25.0349 8.29848 25.3333 8.66667 25.3333H12.6667C13.0349 25.3333 13.3333 25.0349 13.3333 24.6667V7.33333C13.3333 6.96514 13.0349 6.66667 12.6667 6.66667H8.66667Z"
        fill="currentColor"
      />
      <path
        d="M19.3333 6.66667C18.9651 6.66667 18.6667 6.96514 18.6667 7.33333V24.6667C18.6667 25.0349 18.9651 25.3333 19.3333 25.3333H23.3333C23.7015 25.3333 24 25.0349 24 24.6667V7.33333C24 6.96514 23.7015 6.66667 23.3333 6.66667H19.3333Z"
        fill="currentColor"
      />
    </svg>
  );
}

/**
 * name: PictureInPictureIcon
 */
export function IcoPictureInPicture(props: SVGProps) {
  return (
    <svg class="w-6 h-6" viewBox="0 0 32 32" fill="none" aria-hidden="true" {...props}>
      <path
        d="M16 15.3333C15.6318 15.3333 15.3333 15.6318 15.3333 16V20C15.3333 20.3682 15.6318 20.6667 16 20.6667H21.3333C21.7015 20.6667 22 20.3682 22 20V16C22 15.6318 21.7015 15.3333 21.3333 15.3333H16Z"
        fill="currentColor"
      ></path>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.33333 7.33334C5.33333 6.96515 5.63181 6.66667 5.99999 6.66667H26C26.3682 6.66667 26.6667 6.96515 26.6667 7.33334V24.6667C26.6667 25.0349 26.3682 25.3333 26 25.3333H5.99999C5.63181 25.3333 5.33333 25.0349 5.33333 24.6667V7.33334ZM7.99999 10C7.99999 9.63182 8.29847 9.33334 8.66666 9.33334H23.3333C23.7015 9.33334 24 9.63182 24 10V22C24 22.3682 23.7015 22.6667 23.3333 22.6667H8.66666C8.29847 22.6667 7.99999 22.3682 7.99999 22V10Z"
        fill="currentColor"
      ></path>
    </svg>
  );
}

/**
 * name: PictureInPictureExitIcon
 */
export function IcoPictureInPictureExit(props: SVGProps) {
  return (
    <svg class="w-6 h-6" viewBox="0 0 32 32" fill="none" aria-hidden="true" {...props}>
      <path
        d="M5.33334 26V19.4667C5.33334 19.393 5.39304 19.3333 5.46668 19.3333H7.86668C7.94031 19.3333 8.00001 19.393 8.00001 19.4667V23.3333C8.00001 23.7015 8.29849 24 8.66668 24H23.3333C23.7015 24 24 23.7015 24 23.3333V8.66666C24 8.29847 23.7015 7.99999 23.3333 7.99999H19.4667C19.393 7.99999 19.3333 7.9403 19.3333 7.86666V5.46666C19.3333 5.39302 19.393 5.33333 19.4667 5.33333H26C26.3682 5.33333 26.6667 5.63181 26.6667 5.99999V26C26.6667 26.3682 26.3682 26.6667 26 26.6667H6.00001C5.63182 26.6667 5.33334 26.3682 5.33334 26Z"
        fill="currentColor"
      />
      <path
        d="M14.0098 8.42359H10.806C10.6872 8.42359 10.6277 8.56721 10.7117 8.6512L16.5491 14.4886C16.8094 14.7489 16.8094 15.171 16.5491 15.4314L15.3234 16.657C15.0631 16.9174 14.641 16.9174 14.3806 16.657L8.63739 10.9138C8.55339 10.8298 8.40978 10.8893 8.40978 11.0081V14.0236C8.40978 14.3918 8.1113 14.6903 7.74311 14.6903H6.00978C5.64159 14.6903 5.34311 14.3918 5.34311 14.0236L5.34311 6.02359C5.34311 5.6554 5.64159 5.35692 6.00978 5.35692L14.0098 5.35692C14.378 5.35692 14.6764 5.6554 14.6764 6.02359V7.75692C14.6764 8.12511 14.378 8.42359 14.0098 8.42359Z"
        fill="currentColor"
      />
    </svg>
  );
}

/**
 * name: PlayIcon
 */
export function IcoPlay(props: SVGProps) {
  return (
    <svg class="w-6 h-6" viewBox="0 0 32 32" fill="none" aria-hidden="true" {...props}>
      <path
        d="M10.6667 6.6548C10.6667 6.10764 11.2894 5.79346 11.7295 6.11862L24.377 15.4634C24.7377 15.7298 24.7377 16.2692 24.3771 16.5357L11.7295 25.8813C11.2895 26.2065 10.6667 25.8923 10.6667 25.3451L10.6667 6.6548Z"
        fill="currentColor"
      />
    </svg>
  );
}

/**
 * name: VolumeHighIcon
 */
export function IcoVolumeHigh(props: SVGProps) {
  return (
    <svg class="w-6 h-6" viewBox="0 0 32 32" fill="none" aria-hidden="true" {...props}>
      <path
        d="M17.5091 24.6595C17.5091 25.2066 16.8864 25.5208 16.4463 25.1956L9.44847 20.0252C9.42553 20.0083 9.39776 19.9992 9.36923 19.9992H4.66667C4.29848 19.9992 4 19.7007 4 19.3325V12.6658C4 12.2976 4.29848 11.9992 4.66667 11.9992H9.37115C9.39967 11.9992 9.42745 11.99 9.45039 11.9731L16.4463 6.80363C16.8863 6.47845 17.5091 6.79262 17.5091 7.3398L17.5091 24.6595Z"
        fill="currentColor"
      />
      <path
        d="M27.5091 9.33336C27.8773 9.33336 28.1758 9.63184 28.1758 10V22C28.1758 22.3682 27.8773 22.6667 27.5091 22.6667H26.1758C25.8076 22.6667 25.5091 22.3682 25.5091 22V10C25.5091 9.63184 25.8076 9.33336 26.1758 9.33336L27.5091 9.33336Z"
        fill="currentColor"
      />
      <path
        d="M22.1758 12C22.544 12 22.8424 12.2985 22.8424 12.6667V19.3334C22.8424 19.7016 22.544 20 22.1758 20H20.8424C20.4743 20 20.1758 19.7016 20.1758 19.3334V12.6667C20.1758 12.2985 20.4743 12 20.8424 12H22.1758Z"
        fill="currentColor"
      />
    </svg>
  );
}

/**
 * name: VolumeLowIcon
 */
export function IcoVolumeLow(props: SVGProps) {
  return (
    <svg class="w-6 h-6" viewBox="0 0 32 32" fill="none" aria-hidden="true" {...props}>
      <path
        d="M17.5091 24.6594C17.5091 25.2066 16.8864 25.5207 16.4463 25.1956L9.44847 20.0252C9.42553 20.0083 9.39776 19.9991 9.36923 19.9991H4.66667C4.29848 19.9991 4 19.7006 4 19.3324V12.6658C4 12.2976 4.29848 11.9991 4.66667 11.9991H9.37115C9.39967 11.9991 9.42745 11.99 9.45039 11.973L16.4463 6.80358C16.8863 6.4784 17.5091 6.79258 17.5091 7.33975L17.5091 24.6594Z"
        fill="currentColor"
      />
      <path
        d="M22.8424 12.6667C22.8424 12.2985 22.544 12 22.1758 12H20.8424C20.4743 12 20.1758 12.2985 20.1758 12.6667V19.3333C20.1758 19.7015 20.4743 20 20.8424 20H22.1758C22.544 20 22.8424 19.7015 22.8424 19.3333V12.6667Z"
        fill="currentColor"
      />
    </svg>
  );
}

/**
 * name: TranscriptIcon
 */
export function IcoTranscript(props: SVGProps) {
  return (
    <svg class="w-6 h-6" viewBox="0 0 32 32" fill="none" aria-hidden="true" {...props}>
      <path
        d="M12 14C12 13.6318 12.2985 13.3333 12.6667 13.3333H15.3333C15.7015 13.3333 16 13.6318 16 14V15.3333C16 15.7015 15.7015 16 15.3333 16H12.6667C12.2985 16 12 15.7015 12 15.3333V14Z"
        fill="currentColor"
      />
      <path
        d="M12 19.3333C12 18.9651 12.2985 18.6667 12.6667 18.6667H19.3333C19.7015 18.6667 20 18.9651 20 19.3333V20.6667C20 21.0349 19.7015 21.3333 19.3333 21.3333H12.6667C12.2985 21.3333 12 21.0349 12 20.6667V19.3333Z"
        fill="currentColor"
      />
      <path
        d="M7.33332 5.33333C6.96513 5.33333 6.66666 5.63181 6.66666 5.99999V26C6.66666 26.3682 6.96513 26.6667 7.33332 26.6667H24.6667C25.0348 26.6667 25.3333 26.3682 25.3333 26V13.3892C25.3333 13.3539 25.3193 13.32 25.2943 13.295L17.3724 5.37238C17.3474 5.34738 17.3135 5.33333 17.2781 5.33333H7.33332ZM9.99999 7.99999H17.2C17.2736 7.99999 17.3333 8.05969 17.3333 8.13333V12.6667C17.3333 13.0349 17.6318 13.3333 18 13.3333H22.5333C22.607 13.3333 22.6667 13.393 22.6667 13.4667V23.3333C22.6667 23.7015 22.3682 24 22 24H9.99999C9.6318 24 9.33332 23.7015 9.33332 23.3333V8.66666C9.33332 8.29847 9.6318 7.99999 9.99999 7.99999Z"
        fill="currentColor"
      />
    </svg>
  );
}

/**
 * name: RadioButtonIcon
 */
export function IcoRadioButton(props: SVGProps) {
  return (
    <svg class="w-6 h-6" viewBox="0 0 32 32" fill="none" aria-hidden="true" {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16 26.6667C21.891 26.6667 26.6666 21.891 26.6666 16C26.6666 10.109 21.891 5.33334 16 5.33334C10.1089 5.33334 5.33331 10.109 5.33331 16C5.33331 21.891 10.1089 26.6667 16 26.6667ZM16 23.3333C20.0501 23.3333 23.3333 20.0501 23.3333 16C23.3333 11.9499 20.0501 8.66668 16 8.66668C11.9499 8.66668 8.66665 11.9499 8.66665 16C8.66665 20.0501 11.9499 23.3333 16 23.3333Z"
        fill="currentColor"
      ></path>
    </svg>
  );
}

/**
 * name: RadioButtonSelectedIcon
 */
export function IcoRadioButtonSelected(props: SVGProps) {
  return (
    <svg class="w-6 h-6" viewBox="0 0 32 32" fill="none" aria-hidden="true" {...props}>
      <path
        d="M16 20.6667C18.5773 20.6667 20.6666 18.5773 20.6666 16C20.6666 13.4227 18.5773 11.3333 16 11.3333C13.4227 11.3333 11.3333 13.4227 11.3333 16C11.3333 18.5773 13.4227 20.6667 16 20.6667Z"
        fill="currentColor"
      ></path>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M26.6666 16C26.6666 21.891 21.891 26.6667 16 26.6667C10.1089 26.6667 5.33331 21.891 5.33331 16C5.33331 10.109 10.1089 5.33334 16 5.33334C21.891 5.33334 26.6666 10.109 26.6666 16ZM24 16C24 20.4183 20.4183 24 16 24C11.5817 24 7.99998 20.4183 7.99998 16C7.99998 11.5817 11.5817 8.00001 16 8.00001C20.4183 8.00001 24 11.5817 24 16Z"
        fill="currentColor"
      ></path>
    </svg>
  );
}

/**
 * name: OdometerIcon
 */
export function IcoOdometer(props: SVGProps) {
  return (
    <svg class="w-6 h-6" viewBox="0 0 32 32" fill="none" aria-hidden="true" {...props}>
      <path
        d="M25.14 25.1089C25.0171 25.2532 24.8356 25.3333 24.646 25.3333H22.8124C22.1084 25.3333 21.7734 24.1872 22.2745 23.6927C23.9161 22.0729 24.9336 19.822 24.9336 17.3333C24.9336 12.3997 20.9336 8.39973 16 8.39973C11.0664 8.39973 7.06641 12.3997 7.06641 17.3333C7.06641 19.822 8.08389 22.0729 9.72555 23.6927C10.2266 24.1872 9.89155 25.3333 9.18762 25.3333H7.35398C7.16436 25.3333 6.98294 25.2532 6.86001 25.1089C5.07703 23.015 4 20.2991 4 17.3333C4 10.7057 9.3724 5.33333 16 5.33333C22.6276 5.33333 28 10.7057 28 17.3333C28 20.2991 26.923 23.015 25.14 25.1089Z"
        fill="currentColor"
      />
      <path
        d="M21.1992 14.3399C21.4595 14.0796 21.4595 13.6575 21.1992 13.3971L20.2564 12.4543C19.996 12.194 19.5739 12.194 19.3136 12.4543L16.4492 15.3187C16.4185 15.3493 16.3749 15.3629 16.332 15.3568C16.2236 15.3414 16.1127 15.3334 16 15.3334C14.7113 15.3334 13.6667 16.378 13.6667 17.6667C13.6667 18.9554 14.7113 20 16 20C17.2887 20 18.3333 18.9554 18.3333 17.6667C18.3333 17.5464 18.3242 17.4283 18.3067 17.313C18.3001 17.2696 18.3136 17.2255 18.3446 17.1945L21.1992 14.3399Z"
        fill="currentColor"
      />
    </svg>
  );
}
