import { Button } from '@components/buttons';
import { IcoChat, IcoChevronDown, IcoChevronUp } from '@components/icons';
import { useEffect, useMemo, useRef, useState } from 'preact/hooks';
import { Case } from '@components/conditional';
import { CurrentUserProfileIcon } from '@components/avatars';
import { onNewMessageRequest, onCourseChatToggled } from './events';
import { ChatRoom } from './chat-room';
import { rpx, RpxResponse } from 'client/lib/rpx-client';
import { useConfiguration, useCurrentUser } from '@components/router/session-context';
import { useIntl } from 'shared/intl/use-intl';
import { useRouteDef } from '@components/router';
import { useImageUrl } from 'client/utils/cdn';
import { CourseChatsList } from './course-chats-list';
import { DefaultSpinner } from '@components/spinner';
import { playNotificationSound } from './helpers';
import { useAsyncInterval } from 'client/utils/use-interval';
import { useWindowDimensions } from 'client/lib/hooks/use-window-dimensions';
import { useBodyScrollLock } from 'client/lib/hooks/use-body-scroll-lock';
import { usePageVisibility } from 'client/lib/hooks';

const store = rpx.chats;

type ChatCourse = RpxResponse<typeof store.getChatCourses>[0];
// Room id is optional because it's missing for new rooms until the first message is sent.
type Room = Omit<RpxResponse<typeof store.getCourseRooms>[0], 'id'> & {
  id?: UUID;
};

type RoomUpdates = RpxResponse<typeof store.getRoomUpdates>;

type ActiveRoom = Room & {
  isExpanded: boolean;
  isFocused: boolean;
};

// 30 seconds
const UNREADS_REFRESH_FOREGROUND_INTERVAL = 30 * 1000;
// 3 minutes
const UNREADS_REFRESH_BACKGROUND_INTERVAL = 3 * 60 * 1000;

export function ChatWidget() {
  const configuration = useConfiguration();
  const { coreUser } = configuration;
  const user = useCurrentUser();

  if (!user) {
    return null;
  }

  // Do not let tenant admins view the chats of their members
  if (coreUser && coreUser.level === 'admin') {
    return null;
  }

  return <Widget />;
}

function Widget() {
  const intl = useIntl();
  const route = useRouteDef();
  const { isMobile } = useWindowDimensions();

  const [isLoading, setIsLoading] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false);
  const [courses, setCourses] = useState<ChatCourse[]>([]);
  const [selectedCourse, setSelectedCourse] = useState<ChatCourse | undefined>(undefined);
  const [roomUpdates, setRoomUpdates] = useState<RoomUpdates | undefined>(undefined);

  const [activeRooms, setActiveRooms] = useState<ActiveRoom[]>([]);
  const [refreshInterval, setRefreshInterval] = useState<number | undefined>(
    UNREADS_REFRESH_BACKGROUND_INTERVAL,
  );

  const isPageActive = usePageVisibility();
  const isWidgetVisible = courses.length > 0 && !route.isPublic;

  // Storing this in a ref because we want to access it in the `getUnreadCounts` interval.
  const lastIncomingMessageDate = useRef<Date | undefined>(undefined);

  const rootUnreadCounts = useMemo(() => {
    return (roomUpdates || []).reduce(
      (acc, room) => {
        if (!acc[room.courseId]) {
          acc[room.courseId] = 0;
        }
        acc[room.courseId] += room.unreadCount;

        acc.total += room.unreadCount;
        return acc;
      },
      {
        total: 0,
      } as {
        [courseId: string]: number;
        total: number;
      },
    );
  }, [roomUpdates]);

  // Lock the document scroll when the chat is expanded on mobile
  useBodyScrollLock(isExpanded && isMobile);

  useEffect(() => {
    setRefreshInterval(
      isPageActive ? UNREADS_REFRESH_FOREGROUND_INTERVAL : UNREADS_REFRESH_BACKGROUND_INTERVAL,
    );
  }, [isPageActive]);

  function addActiveRoom(room: Room) {
    setActiveRooms((rooms) => {
      // "room.id"s could be undefined so we also check the recipient id
      // to match the room.
      const index = rooms.findIndex(
        (r) => r.id === room.id && r.recipient.id === room.recipient.id,
      );
      const result = rooms.map((r) => ({ ...r, isFocused: false }));
      const focusedRoom = { ...room, isExpanded: true, isFocused: true };

      if (index > -1) {
        result[index] = focusedRoom;
        return result;
      }

      // We want to display 2 active rooms at most.
      // So we remove the oldest room from the list.
      return [...result.slice(-1), focusedRoom];
    });

    setRoomUpdates((u) => {
      const newUpdates = u || [];
      if (room.id) {
        const roomIndex = newUpdates.findIndex((x) => x.roomId === room.id);
        if (roomIndex > -1) {
          newUpdates[roomIndex].unreadCount = 0;
          return [...newUpdates];
        }
      }
      return newUpdates;
    });
  }

  useEffect(() => {
    // Listens `chat with X` events that are triggered
    // from a button in user profile pages.
    return onNewMessageRequest(async (data) => {
      const room = await rpx.chats.findRoom({
        toUserId: data.user.id,
        courseId: data.courseId,
      });
      addActiveRoom({
        id: room?.id,
        isMuted: room?.isMuted ?? false,
        recipient: {
          id: data.user.id,
          name: data.user.name || '',
          profilePhotoUrl: data.user.profilePhotoUrl,
        },
        courseId: data.courseId,
      });
    });
  }, []);

  useEffect(() => {
    async function fetchCourses() {
      try {
        const result = await store.getChatCourses();
        setCourses(result);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
      }
    }

    // Fetch initially
    fetchCourses();

    // Re-fetch courses when the chat is toggled by the guide on any course
    return onCourseChatToggled(async () => {
      const result = await store.getChatCourses();
      setCourses(result);
    });
  }, []);

  useAsyncInterval(
    async () => {
      try {
        const result = await store.getRoomUpdates();
        setRoomUpdates(result);

        // Find the latest incoming message for inactive chat rooms
        // as we don't play the notification sound for active rooms.
        const newestInactiveChat = result.find((c) => {
          const isActive = activeRooms.find((r) => r.id === c.roomId);
          return !isActive;
        });

        if (newestInactiveChat) {
          const lastMessageDate = new Date(newestInactiveChat.lastMessage.createdAt);
          if (
            newestInactiveChat.unreadCount > 0 &&
            lastIncomingMessageDate.current &&
            lastMessageDate > lastIncomingMessageDate.current
          ) {
            playNotificationSound();
          }
          lastIncomingMessageDate.current = lastMessageDate;
        }
      } catch (error) {
        console.error(error);
        if (error.statusCode === 403) {
          // If the user is not authorized to view the chat, we should not keep polling.
          setRefreshInterval(undefined);
        }
      }
    },

    // If visible, fetch the first round of room updates right away
    // and keep refreshing them every 30 seconds or 3 minutes based on the page visibility.
    isWidgetVisible && refreshInterval
      ? {
          interval: refreshInterval,
          initialDelay: 100,
        }
      : undefined,
  );

  if (!isWidgetVisible) {
    return null;
  }

  return (
    <div
      class={`flex hide-on-preview fixed z-50 md:bottom-0 right-0 md:in-[.overflow-hidden]:right-3.5 overflow-visible flex-row-reverse flex-wrap-nowrap items-end ${
        isExpanded ? 'bottom-0 h-[calc(100dvh)] md:h-auto' : 'bottom-4'
      }`}
    >
      <div
        class={`${
          isExpanded ? 'w-screen h-full' : ''
        } md:w-72 min-w-0 md:border md:rounded-t-lg md:bg-gray-50`}
      >
        <Button
          class={`flex items-center ${
            isExpanded
              ? 'w-full p-2 bg-gray-50 border-b md:rounded-t-lg'
              : 'md:w-full md:p-2 md:bg-gray-50 md:border-b md:rounded-t-lg'
          }`}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <div class="hidden md:block">
            <CurrentUserProfileIcon size="w-8 h-8 mr-2" />
          </div>
          <div class={`md:hidden p-2 rounded-full ${isExpanded ? '' : 'bg-indigo-100'}`}>
            <IcoChat class="w-6 h-6 md:hidden" />
          </div>
          <h2 class="grow text-base font-semibold text-left">
            <span class={isExpanded ? '' : 'hidden md:inline'}>{intl('Chats')}</span>
            <Case when={rootUnreadCounts.total > 0}>
              <span
                class={`${
                  isExpanded ? 'hidden md:inline-flex' : 'absolute'
                } right-3 -top-2 md:top-0 md:right-0 md:relative inline-flex items-center ml-2 justify-center rounded-full text-xs font-semibold leading-4 bg-red-500 text-white dark:border-none w-5 h-5`}
              >
                {rootUnreadCounts.total}
              </span>
            </Case>
          </h2>
          <span class="inline-flex self-end items-center">
            {refreshInterval === undefined && <span class="w-2 h-2 rounded-full bg-red-500" />}
            <span class="p-2 md:hover:bg-gray-200 rounded-full">
              {isExpanded && <IcoChevronDown class="w-5 h-5" />}
              {!isExpanded && <IcoChevronUp class="hidden md:inline w-5 h-5" />}
            </span>
          </span>
        </Button>
        <Case when={isExpanded}>
          <div class="flex flex-col h-full bg-gray-50 md:rounded-sm an-slide-up w-full">
            <div class="h-[calc(100dvh)] md:h-screen-minus-20 max-h-192 overflow-auto">
              {isLoading && <DefaultSpinner />}
              {selectedCourse && (
                <CourseChatsList
                  course={selectedCourse}
                  roomUpdates={roomUpdates?.filter((x) => x.courseId === selectedCourse.id)}
                  onBack={() => setSelectedCourse(undefined)}
                  onRoomClick={(room) => addActiveRoom(room)}
                />
              )}
              {!selectedCourse &&
                courses.map((course) => (
                  <CourseItem
                    key={course.id}
                    course={course}
                    unreadCount={rootUnreadCounts[course.id] || 0}
                    onClick={() => setSelectedCourse(course)}
                  />
                ))}
            </div>
          </div>
        </Case>
      </div>
      {activeRooms.map((room) => {
        const course = courses.find((c) => c.id === room.courseId);
        // This should never happen, but just in case.
        if (!course) {
          return null;
        }

        return (
          <ChatRoom
            key={room.id}
            room={room}
            toUser={room.recipient}
            course={course}
            shouldFocus={room.isFocused}
            isExpanded={room.isExpanded}
            isMobile={isMobile}
            setIsExpanded={(isExpanded) => {
              setActiveRooms((r) => {
                const newRooms = [...r];
                const index = newRooms.findIndex((item) => item.id === room.id);
                newRooms[index].isExpanded = isExpanded;
                return newRooms;
              });
            }}
            onClose={() => {
              setActiveRooms(activeRooms.filter((item) => item.id !== room.id));
            }}
          />
        );
      })}
    </div>
  );
}

function CourseItem({
  course,
  unreadCount,
  onClick,
}: {
  course: ChatCourse;
  unreadCount: number;
  onClick: () => void;
}) {
  const intl = useIntl();
  const imageUrl = useImageUrl(course.imagePath);

  return (
    <div class="px-3 py-2 flex items-center cursor-pointer hover:bg-gray-200" onClick={onClick}>
      <div class="relative">
        <span class="flex items-center justify-center text-center w-12 min-w-12 h-12 bg-gray-100 border-b overflow-hidden rounded-sm shadow-sm">
          {imageUrl && <img src={imageUrl} class="object-cover" />}
          {!imageUrl && <span class="opacity-50 text-xs">{intl('No image')}</span>}
        </span>
        <Case when={unreadCount > 0}>
          <span class="absolute -top-2 -right-2 inline-flex items-center justify-center rounded-full text-xs font-semibold leading-4 bg-red-500 text-white dark:border-none w-5 h-5 overflow-hidden">
            {unreadCount}
          </span>
        </Case>
      </div>
      <div class="flex items-center justify-center pl-4 min-h-16">
        <p class="text-gray-900 line-clamp-2 text-sm">{course.title}</p>
      </div>
    </div>
  );
}
