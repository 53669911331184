import { TenantRow } from 'server/types';
import { internationalize } from './index';
import { plugins } from './plugins';
import esTranslation from 'shared/intl/translations/es.json';
import { defaultTerminology } from 'shared/terminology';

export function tenantIntl(tenant: Pick<TenantRow, 'locale' | 'terminology'>) {
  const translation =
    tenant.locale === 'es'
      ? esTranslation
      : {
          phrases: {},
        };
  return internationalize(translation, plugins, {
    ...defaultTerminology,
    ...(tenant.terminology || {}),
  });
}

/**
 * This returns the default internationalized translations, bypassing the need for a
 * global ruzuku config, for use in the dev server.
 */
export function useMockIntl() {
  const translation = { phrases: {} };
  return internationalize(translation, plugins, defaultTerminology);
}
