import {
  IcoChartBar,
  IcoChat,
  IcoCheckCircle,
  IcoFile,
  IcoKey,
  IcoQuestion,
} from '@components/icons';
import { LessonRow } from 'server/types';

export function LessonBadges({
  lesson,
  hasDiscussion,
  isComplete,
}: {
  lesson: Pick<LessonRow, 'isPrerequisite' | 'assessmentType'>;
  hasDiscussion?: boolean;
  isSelected?: boolean;
  isComplete?: boolean;
  class?: string;
}) {
  return (
    <span class="inline-flex items-center gap-2">
      {lesson.isPrerequisite && (
        <span title="Prerequisite Lesson">
          <IcoKey
            class={`w-4 h-4 opacity-75 dark:opacity-100 ${
              isComplete ? 'text-green-400 dark:text-green-900' : ''
            }`}
          />
        </span>
      )}
      {hasDiscussion && (
        <span title="Discussion">
          <IcoChat class="w-4 h-4 opacity-75 dark:opacity-100" />
        </span>
      )}
      {lesson.assessmentType === 'quiz' && (
        <span title="Quiz">
          <IcoQuestion class="w-4 h-4 opacity-75 dark:opacity-100" />
        </span>
      )}
      {lesson.assessmentType === 'poll' && (
        <span title="Poll">
          <IcoChartBar class="w-4 h-4 opacity-75 dark:opacity-100" />
        </span>
      )}
      {lesson.assessmentType === 'assignment' && (
        <span title="Assignment">
          <IcoFile class="w-4 h-4 opacity-75 dark:opacity-100" />
        </span>
      )}
      {isComplete && (
        <span title="Completed">
          <IcoCheckCircle class="w-4 h-4 opacity-75 fill-green-500" />
        </span>
      )}
    </span>
  );
}
