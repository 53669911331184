import { BtnCopy } from '@components/buttons';
import { ComponentChildren } from 'preact';

interface Props {
  title: string;
  value?: string;
}

export function IntegrationRow(props: { icon: ComponentChildren; children: ComponentChildren }) {
  return (
    <div class="flex flex-col md:flex-row p-8">
      <div class="w-1/4">{props.icon}</div>
      <div class="flex items-center w-3/4 md:pl-14">{props.children}</div>
    </div>
  );
}

export function IntegrationValueItem({ title, value }: Props) {
  if (!value) {
    return null;
  }

  return (
    <div class="flex flex-col gap-2 mt-2">
      <strong>{title}</strong>
      <div class="flex justify-between items-center grow bg-gray-100 rounded-lg p-4">
        <span class="break-all">{value}</span>
        <BtnCopy value={value} />
      </div>
    </div>
  );
}
