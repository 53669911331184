import { Component, JSX } from 'preact';

type Props = JSX.HTMLAttributes<HTMLDivElement> & { el?: Element };

export class ManualDom extends Component<Props> {
  root: HTMLElement | undefined;

  shouldComponentUpdate(nextProps: Props) {
    // If the mounted element has changed and we have a root,
    // we need to mount again.
    if (nextProps.el !== this.props.el && this.root) {
      this.mount(this.root, nextProps);
      return false;
    }

    // If we don't have a root yet, we need to render.
    return !this.root;
  }

  mount = (root: HTMLElement | null, props = this.props) => {
    if (!root || !props.el || props.el.isConnected) {
      return;
    }
    this.root = root;
    root.innerHTML = '';
    root.appendChild(props.el);
    // This allows us to access the element programmatically from the root,
    // which is helpful when handling click events among other things.
    (root as any).el = props.el;
  };

  render() {
    return <div ref={this.mount} {...this.props}></div>;
  }
}
