import { ComponentChildren } from 'preact';
import { useState } from 'preact/hooks';
import { AsyncForm } from '@components/async-form';
import { BtnPrimary } from '@components/buttons';
import { showError } from '@components/app-error';
import { useAsyncEffect } from 'client/utils/use-async-effect';

/**
 * The checkout form provides a standard wrapper and submit behavior
 * for the various checkout forms.
 */
export function CheckoutForm({
  actionText,
  onSubmit,
  autoSubmit,
  children,
  footer,
}: {
  actionText: string;
  children?: ComponentChildren;
  footer?: ComponentChildren;
  /**
   * If specified, the form will automatically submit itself when it
   * loads. This is handy if the form is render post-login, and the
   * login screen was the "accept / submit" action.
   */
  autoSubmit?: boolean;
  onSubmit(data: any): Promise<unknown>;
}) {
  const [isLoading, setIsLoading] = useState(false);

  useAsyncEffect(async () => {
    if (!autoSubmit || isLoading) {
      return;
    }
    try {
      setIsLoading(true);
      await onSubmit(undefined);
    } catch (err) {
      showError(err);
      setIsLoading(false);
    }
  }, [autoSubmit]);

  return (
    <AsyncForm
      class="flex flex-col gap-4"
      onSubmit={async (data: any) => {
        try {
          setIsLoading(true);
          return await onSubmit(data);
        } finally {
          setIsLoading(false);
        }
      }}
    >
      {children}
      <div class="flex items-start">
        <BtnPrimary class="rounded-full px-10" isLoading={isLoading}>
          {actionText}
        </BtnPrimary>
      </div>
      {footer}
    </AsyncForm>
  );
}
