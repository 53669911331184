import type { RouteRule } from '@components/router/async-router';

export const routes: RouteRule[] = [
  [() => import('./cal-public-event-types'), 'meet/:urlPrefix'],
  [() => import('./cal-public'), 'meet/:urlPrefix/:urlSuffix'],
  [() => import('./cal-public-scheduled'), 'calendar/bookings/:eventId'],
  [() => import('./cal-bookings-list'), 'calendar', 'calendar/bookings'],
  [() => import('./cal-availability-list'), 'calendar/availability', 'calendar/overrides'],
  [() => import('./cal-availability-edit'), 'calendar/availability/:id'],
  [
    () => import('./cal-edit-event-types'),
    'calendar/event-types/:id',
    'calendar/event-types/:id/:tab',
  ],
  [() => import('./cal-event-types-list'), 'calendar/event-types'],
  [() => import('./cal-overrides-edit'), 'calendar/overrides/:id'],
  [
    () => import('./cal-integrations'),
    'calendar/integrations',
    'calendar/integrations/:integrationType',
  ],
  [() => import('./cal-settings'), 'calendar/settings'],
  [() => import('./cal-not-found'), 'calendar/*', 'meet/*'],
  [() => import('./cal-google'), 'calendar/oauth-google'],
  [() => import('./cal-onboarding'), 'calendar/onboarding'],
  [() => import('./cal-login'), 'calendar/login'],
];
