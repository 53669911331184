import { BtnPrimary } from '@components/buttons';
import { IcoSlides } from '@components/icons';
import { ComponentChildren } from 'preact';
import { useState } from 'preact/hooks';

interface Props {
  Ico: typeof IcoSlides;
  title: ComponentChildren;
  centeredDescription?: boolean;
  description: ComponentChildren;
  href?: string;
  onClick?(): void | Promise<unknown>;
  actionText?: ComponentChildren;
  actionDialog?: ComponentChildren;
  isManagement?: boolean;
}

export function EmptyGuidePage(props: Props) {
  return <EmptyPage {...props} isManagement />;
}

export function EmptyPage(props: Props) {
  const [isProcessing, setIsProcessing] = useState(false);
  const onClick =
    props.onClick &&
    (async () => {
      try {
        setIsProcessing(true);
        await props.onClick?.();
      } finally {
        setIsProcessing(false);
      }
    });

  return (
    <div class="grow flex items-center justify-center pb-80">
      <div class="text-gray-800 p-4 md:p-24 text-center">
        <span
          class={`${
            props.isManagement ? '' : 'bg-theme-100 text-theme-400'
          } bg-indigo-100 rounded-full w-52 h-52 mb-3 inline-flex items-center justify-center text-indigo-400 dark:bg-sky-100 dark:text-sky-500`}
        >
          <props.Ico class="w-16 h-16 md:w-32 md:h-32" />
        </span>
        <h1
          class={`${
            props.isManagement ? '' : 'text-theme-400'
          } text-indigo-500 dark:text-sky-500 text-4xl font-semibold mb-2 mt-10`}
        >
          {props.title}
        </h1>
        <div
          class={`text-gray-600 dark:text-gray-200 max-w-96 ${
            props.centeredDescription ? '' : 'text-left'
          } mb-8`}
        >
          {props.description}
        </div>
        {props.actionText && (
          <footer class="mt-8 relative">
            <BtnPrimary
              class="px-6 rounded-full"
              href={props.href}
              onClick={onClick}
              isLoading={isProcessing}
            >
              {props.actionText}
            </BtnPrimary>
            {props.actionDialog}
          </footer>
        )}
      </div>
    </div>
  );
}
