import { FormGroup } from '@components/async-form';
import { URLS } from 'shared/urls';
import { rpx } from 'client/lib/rpx-client';
import { ModalForm } from '@components/modal-form';
import { useRouter } from '@components/router';

type Props = {
  isProduct: boolean;
  onCancel: () => void;
};

const store = rpx.guideBundles;

export function CreateBundleModal({ onCancel }: Props) {
  const router = useRouter();
  async function onSubmit(data: any) {
    const courseId = await store.createBundle({
      title: data.title,
    });
    router.goto(
      URLS.guide.course({
        courseId,
      }),
    );
  }

  return (
    <ModalForm
      onClose={onCancel}
      onSubmit={onSubmit}
      title="Create a new bundle"
      confirmButtonText="Create bundle"
    >
      <FormGroup prop="title">
        <label>
          <span>Enter the title of your new bundle.</span>
          <input type="text" placeholder="Bundle title" name="title" class="ruz-input" />
        </label>
      </FormGroup>
    </ModalForm>
  );
}
