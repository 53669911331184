import { JSX } from 'preact';

type Props = JSX.InputHTMLAttributes<HTMLInputElement> & {
  // This is supposed to be in HTMLInputElement but
  // it's not due to a Preact problem: https://github.com/preactjs/preact/issues/2668
  // So I'm re-adding it manually here
  defaultChecked?: HTMLInputElement['defaultChecked'];
};

/**
 * A checkbox that is styled like a toggle button from TailwindUI
 * https://tailwindui.com/components/application-ui/forms/toggles#component-b3e0a15571300f79fced5845f97fa972
 *
 * We perform the styling logic in CSS, rather than using tailwind classes, since it's fairly
 * custom and we want this to work for both controlled and uncontrolled uses.
 */
export function Toggle(props: Props) {
  const { class: className = '', ...restOfProps } = props;
  return <input {...restOfProps} class={`ctl-toggle ${className}`} type="checkbox" />;
}
