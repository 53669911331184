import { loadScript } from 'client/lib/load-script';
import { useAsyncEffect } from 'client/utils/use-async-effect';
import { useMemo, useState } from 'preact/hooks';
import { Button } from '@components/buttons';
import { IcoSmile } from '@components/icons';
import { ManualDom } from '@components/manual-dom';
import { useEsc } from 'client/utils/use-esc';

interface Props {
  onPick: (emoji: string) => void;
}

export function EmojiPicker({ onPick }: Props) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useAsyncEffect(async () => {
    await loadScript('https://cdn.jsdelivr.net/npm/emoji-mart@latest/dist/browser.js');
    setIsLoaded(true);
  }, []);

  if (!isLoaded) {
    return null;
  }

  return (
    <div class="flex items-center relative">
      <Button class="text-gray-600 hover:opacity-80" onClick={() => setIsOpen(true)}>
        <IcoSmile class="w-5 h-5" />
      </Button>
      {isOpen && <InnerPicker onPick={onPick} onHide={() => setIsOpen(false)} />}
    </div>
  );
}

function InnerPicker({ onPick, onHide }: Props & { onHide: () => void }) {
  const el = useMemo(() => {
    const { EmojiMart } = window as any;
    const picker = new EmojiMart.Picker({
      onEmojiSelect: (emoji: any) => {
        onPick(emoji.native);
        onHide();
      },
      previewPosition: 'none',
    });
    return picker;
  }, []);

  useEsc(onHide);

  return (
    <ManualDom
      el={el}
      class="absolute -top-96 -mt-16"
      onClick={(e) => {
        // Prevent the click from closing the picker.
        e.stopPropagation();
      }}
    />
  );
}
