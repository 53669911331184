import { showError } from '@components/app-error';
import { BtnCopy, BtnPrimary } from '@components/buttons';
import { useAsyncEffect } from 'client/utils/use-async-effect';
import { useState } from 'preact/hooks';
import { FullCourse } from 'server/types';
import { coursePrices, URLS } from 'shared/urls';
import { rpx } from 'client/lib/rpx-client';
import { StepItem } from './step-item';
import { PriceRow } from 'server/types';
import { Case } from '@components/conditional';
import { fullyQualify } from 'client/utils/url';

interface Props {
  course: Pick<FullCourse, 'id' | 'title'>;
  onComplete: () => void;
}

const store = rpx.checklist;

export function ShareCourseStep({ course, onComplete }: Props) {
  const [pricePoint, setPricePoint] = useState<PriceRow | undefined>(undefined);
  const [hasSalesPage, setHasSalesPage] = useState(false);

  useAsyncEffect(async () => {
    try {
      const { firstPricePoint, hasSalesPage } = await store.getShareCourseData({
        courseId: course.id,
      });
      setPricePoint(firstPricePoint);
      setHasSalesPage(hasSalesPage);
    } catch (err) {
      showError(err);
    }
  }, []);

  if (!pricePoint) {
    return (
      <StepItem title="Share your sales page with prospective students" stepKey="share">
        <p>Please create a price point first.</p>
        <BtnPrimary
          class="w-full"
          href={URLS.guide.coursePage({
            courseId: course.id,
            page: 'prices?tour',
          })}
        >
          Create a new Price Point
        </BtnPrimary>
      </StepItem>
    );
  }

  return (
    <StepItem title="Share your sales page with prospective students" stepKey="share">
      <p>
        Ready to promote your course? You can share your full sales page with prospective students.
      </p>
      {!hasSalesPage && (
        <p>
          You don't have a course sales page yet. You can point your customer directly to the signup
          form until you{' '}
          <a
            href={URLS.guide.coursePage({
              courseId: course.id,
              page: 'salespage',
            })}
          >
            {' '}
            create a sales page
          </a>
          .
        </p>
      )}
      <div>
        <Case
          when={hasSalesPage}
          fallback={
            <BtnCopy
              class="text-center justify-center border border-gray-300 hover:bg-gray-100 active:bg-gray-200 hover:border-transparent rounded-md p-2"
              value={fullyQualify(
                coursePrices.checkoutUrl({
                  courseId: course.id,
                  priceId: pricePoint.id,
                }),
              )}
              margin="m-0"
            >
              Copy signup link to clipboard
            </BtnCopy>
          }
        >
          <BtnCopy
            class="text-center justify-center border border-gray-300 hover:bg-gray-100 active:bg-gray-200 hover:border-transparent rounded-md p-2"
            value={fullyQualify(
              URLS.student.salespage({
                course,
              }),
            )}
            margin="m-0"
          >
            Copy sales page link to clipboard
          </BtnCopy>
        </Case>
      </div>
      <BtnPrimary class="w-full" onClick={onComplete}>
        Mark as complete
      </BtnPrimary>
    </StepItem>
  );
}
