/**
 * This module contains a simple event emitter and helper component.
 * Instead of using Redux / complex state management, we'll have a
 * handful of events and helper functions to emit them. See app-error
 * for an example.
 */

/**
 * Event handlers take the event name and an optional single arg.
 */
type Handler = (name: string, arg: any) => any;

/**
 * The event emitter is a simple object for registering / unregistering
 * event handlers and emitting events.
 */
function emitter() {
  const handlers = new Set<Handler>();
  const result = {
    /**
     * Register an event handler. Returns a function
     * which unregisters the handler.
     * @param f
     */
    on(f: Handler) {
      handlers.add(f);
      return () => result.off(f);
    },
    /**
     * Unregister an event handler.
     * @param f
     */
    off(f: Handler) {
      handlers.delete(f);
    },
    /**
     * Emit an event.
     * @param name the event name
     * @param arg an optional event argument
     */
    emit(name: string, arg?: any) {
      handlers.forEach((f) => f(name, arg));
    },
  };
  return result;
}

/**
 * The application event emitter.
 */
export const evt = emitter();
