import { LoadedProps, defRoute } from '@components/router';
import { FixedContent, FixedPage } from '@course/components/fixed-page';
import { AdminNav, AdminTabHeader, AdminTabWrapper } from '@course/components/admin-nav';
import { TenantCoursesList } from './course-list';
import './course-detail';
import { load } from './load';
import { DownloadCsvButton } from '@course/components/download-csv-modal';

export const route = defRoute({
  load,
  authLevel: 'admin',
  Page,
});

function Page({ state }: LoadedProps<typeof load>) {
  return (
    <FixedPage>
      <FixedContent class="bg-white">
        <AdminNav currentPage="courses" pageTitle="Courses" />
        <AdminTabWrapper>
          <AdminTabHeader title="Courses">
            <DownloadCsvButton type="tenant" />
          </AdminTabHeader>
          <TenantCoursesList type="all" dontRewriteUrl {...state} />
        </AdminTabWrapper>
      </FixedContent>
    </FixedPage>
  );
}
