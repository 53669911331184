/**
 * A dismissable banner that indicates to the guides that their latest payment
 * failed or that their account has lapsed.
 */

import { useCurrentUser } from '@components/router/session-context';
import { useAsyncData } from 'client/lib/hooks';
import { rpx } from 'client/lib/rpx-client';
import { Button } from '@components/buttons';
import { useState } from 'preact/hooks';

function GuideBanner() {
  const storageKey = 'guide-banner';
  const { data } = useAsyncData(async () => {
    try {
      return rpx.myCorePayments.getAccountWarning();
    } catch (e) {
      // Displaying an error message wouldn't be helpful here
      // so we just log it.
      console.error(e);
    }
  }, []);
  const [isVisible, setIsVisible] = useState(true);

  if (!data) {
    return null;
  }

  // The message has already been dismissed.
  if (!isVisible || localStorage.getItem(storageKey) === data.id) {
    return null;
  }

  const hideBanner = () => {
    setIsVisible(false);
    localStorage.setItem(storageKey, data.id);
  };

  return (
    <header class="p-2 bg-red-600/80 text-white flex items-center justify-center gap-4">
      <div>
        {data.type === 'payment_failure' && (
          <>
            Your latest Ruzuku payment failed. You can{' '}
            <a href="/account/billing" class="underline text-inherit" onClick={hideBanner}>
              manage your payments here
            </a>
            .
          </>
        )}
        {data.type === 'restricted' && (
          <>
            You are now on a limited free account. You can enroll up to 5 total students.{' '}
            <a href="/account/billing" class="underline text-inherit" onClick={hideBanner}>
              Upgrade anytime to unlock full access
            </a>
            .
          </>
        )}
      </div>
      <Button class="border border-red-300 p-1 px-2 text-inherit rounded-md" onClick={hideBanner}>
        Dismiss
      </Button>
    </header>
  );
}

export function AcctBanner() {
  const user = useCurrentUser();
  if (!user || user.level === 'student') {
    return null;
  }
  return <GuideBanner />;
}
