import { Inputs } from 'preact/hooks';
import { useDidUpdateEffect } from './use-did-update-effect';

export function debounce(fn: (...args: any) => void, ms = 100) {
  let timeout: any;
  let finalArgs: any;
  const tick = () => {
    timeout = undefined;
    fn(...finalArgs);
    finalArgs = undefined;
  };
  return (...args: any) => {
    finalArgs = args;
    if (timeout) {
      return;
    }
    timeout = setTimeout(tick, ms);
  };
}

export function useDebouncedEffect(fn: () => void, inputs: Inputs = [], ms = 350) {
  useDidUpdateEffect(() => {
    const timeout = setTimeout(fn, ms);
    return () => clearTimeout(timeout);
  }, inputs);
}

export function delayedDebounce(fn: () => void, ms = 100) {
  let timeout: any;
  return () => {
    clearTimeout(timeout);
    timeout = setTimeout(fn, ms);
  };
}
