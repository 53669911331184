import { useCurrentUser } from 'client/lib/auth';
import { Props as AvailableProps, SummarizeAvailableTimes } from './availability-summary';
import { RuzcalMgmtPage } from './mgmt-page';
import {
  ListAddAction,
  ListContainer,
  PageContent,
  PageHeading,
  PageSection,
  defCalRoute,
} from './common';
import { rpx } from 'client/lib/rpx-client';
import dayjs from 'dayjs';
import { BtnSecondary, Button } from '@components/buttons';
import { IcoPlus, IcoTrash } from '@components/icons';
import { useState } from 'preact/hooks';
import { showDialog } from '@components/dialog';
import { showError } from '@components/app-error';
import { LoadedProps } from '@components/router';
import { isAllDay } from './dateutil';

type Props = LoadedProps<typeof load>;

type Data = Props['state'];

export const route = defCalRoute({ authLevel: 'guide', load, Page });

async function load() {
  const [availability, overrides] = await Promise.all([
    rpx.ruzcal.getAvailabilityList(),
    rpx.ruzcal.getOverridesList(),
  ]);
  return { availability, overrides };
}

function EditAvailabilityLink(props: AvailableProps & { id: string }) {
  return (
    <a
      href={`/calendar/availability/${props.id}`}
      class="block p-3 hover:bg-gray-100 transition-all text-inherit rounded-md"
    >
      <span class="flex flex-col justify-between">
        <span class="font-semibold">{props.label}</span>
        <SummarizeAvailableTimes {...props} />
      </span>
    </a>
  );
}

function OverrideItem({
  override,
  onDelete,
}: {
  override: Data['overrides'][0];
  onDelete: () => void;
}) {
  const user = useCurrentUser()!;

  async function handleDelete() {
    const confirmed = await showDialog({
      mode: 'warn',
      title: 'Delete override',
      children: 'Are you sure you want to delete this override? This action cannot be undone.',
      confirmButtonText: 'Delete',
    });

    if (!confirmed) {
      return;
    }

    try {
      await rpx.ruzcal.deleteOverride({ id: override.id });
      onDelete();
    } catch (err) {
      showError(err);
    }
  }

  const allDay = isAllDay(override);

  return (
    <div class="flex items-center justify-between hover:bg-gray-100 transition-all rounded-md">
      <a href={`/calendar/overrides/${override.id}`} class="text-inherit grow p-3 flex">
        <span>
          {dayjs(override.start)
            .tz(user.timezone)
            .format(allDay ? 'MMM DD, YYYY' : 'MMM DD, YYYY h:mma')}
        </span>
        {' - '}
        <span>
          {dayjs(override.end)
            .tz(user.timezone)
            .format(allDay ? 'MMM DD, YYYY' : 'MMM DD, YYYY h:mma')}
        </span>
        {allDay && ' (all day)'}
      </a>
      <Button
        onClick={handleDelete}
        title="Delete override"
        class="hover:bg-red-600 text-gray-500 hover:text-white p-3 rounded-md transition-all"
      >
        <IcoTrash class="size-4" />
      </Button>
    </div>
  );
}

function Page(props: Props) {
  const user = useCurrentUser()!;
  const [overrides, setOverrides] = useState(props.state.overrides);
  const { availability } = props.state;

  return (
    <RuzcalMgmtPage
      title="Availability"
      currentPage="availability"
      actions={
        <>
          <ListAddAction href="/calendar/availability/new" content="Add availability" />
        </>
      }
    >
      <PageContent>
        <PageSection>
          <PageHeading
            title="Manage your availability"
            subtitle="Define blocks of time when you're available for booking."
          />
          {availability.length > 0 && (
            <ListContainer>
              {availability.map((x) => (
                <EditAvailabilityLink
                  key={x.id}
                  id={x.id}
                  label={x.name}
                  isDefault={x.isDefault}
                  renderTimeZone={user.timezone}
                  scheduleTimeZone={user.timezone}
                  timeslots={x.timeslots}
                />
              ))}
            </ListContainer>
          )}
        </PageSection>
        <PageSection>
          <PageHeading
            title="Date overrides"
            subtitle="Remove specific dates and times from your public availability."
            action={
              <BtnSecondary href="/calendar/overrides/new">
                <IcoPlus class="w-4 h-4 mr-2" />
                Add override
              </BtnSecondary>
            }
          />
          {overrides.length > 0 && (
            <ListContainer>
              {overrides.map((x) => (
                <OverrideItem
                  key={x.id}
                  override={x}
                  onDelete={() => setOverrides(overrides.filter((o) => o.id !== x.id))}
                />
              ))}
            </ListContainer>
          )}
        </PageSection>
      </PageContent>
    </RuzcalMgmtPage>
  );
}
