/**
 * Components for the header bar in the pricing table view.
 */

import { Button } from '@components/buttons';
import { Dropdown, MenuItem } from '@components/dropdown';
import {
  IcoChevronLeft,
  IcoCreditCard,
  IcoFolder,
  IcoHome,
  IcoSearch,
  IcoTag,
} from '@components/icons';
import { SearchBox } from '@components/search-box';
import { Dispatch, StateUpdater, useState } from 'preact/hooks';
import { Folder } from './state';

export function BackButton({ disabled, href }: { disabled: boolean; href: string }) {
  return (
    <Button
      href={href}
      disabled={disabled}
      class={`hover:bg-gray-50 rounded-md text-gray-600 inline-flex items-center justify-center h-9 w-9 ${
        disabled ? 'opacity-50' : ''
      }`}
    >
      <IcoChevronLeft class="w-5 h-5 opacity-75" />
    </Button>
  );
}

export function NavBox({
  folder,
  baseUrl,
  searchTerm,
  setSearchTerm,
  onSubmit,
  onRenameFolder,
}: {
  baseUrl: string;
  folder?: Folder;
  searchTerm: string;
  setSearchTerm: Dispatch<StateUpdater<string>>;
  onSubmit(): void;
  onRenameFolder(): void;
}) {
  const [mode, setMode] = useState<'breadcrumb' | 'search'>('breadcrumb');

  return (
    <div class="flex items-center ml-1">
      {mode === 'breadcrumb' && (
        <div class="bg-gray-50 h-9 text-gray-500 px-3 flex font-medium items-center rounded-md min-w-80">
          <a href={baseUrl} class="inline-flex text-inherit items-center">
            <IcoHome class="h-4 w-4 opacity-75 mr-1" />
            Home
          </a>
          <span class="mx-2">/</span>
          <Button class="font-medium" onClick={onRenameFolder}>
            {folder?.name}
          </Button>
        </div>
      )}
      {mode === 'search' && (
        <SearchBox
          class="pl-10 min-w-80 ruz-input rounded-lg flex bg-transparent"
          onTermChange={setSearchTerm}
          value={searchTerm}
          placeholder="Search items"
          focusOnce
          onKeyDown={(e) => {
            if (e.code !== 'Enter') {
              return;
            }
            e.preventDefault();
            onSubmit();
            setMode('breadcrumb');
          }}
        />
      )}
      <Button
        class="hover:bg-gray-50 rounded-md w-9 h-9 inline-flex items-center justify-center ml-1"
        onClick={() => {
          // We're leaving search mode, so we should clear the term.
          if (mode === 'search') {
            setSearchTerm('');
          }
          setMode((m) => (m === 'breadcrumb' ? 'search' : 'breadcrumb'));
        }}
      >
        <IcoSearch />
      </Button>
    </div>
  );
}

export function CompactMenuItem({
  onClick,
  Ico,
  text,
}: {
  Ico: typeof IcoCreditCard;
  text: string;
  onClick(): void;
}) {
  return (
    <MenuItem class="p-1 px-2 flex items-center hover:bg-gray-50" onClick={onClick}>
      <Ico />
      <span class="ml-3">{text}</span>
    </MenuItem>
  );
}

export function CreateMenu(props: {
  onNewPrice(): void;
  onNewCoupon(): void;
  onNewFolder(): void;
}) {
  return (
    <Dropdown
      hideDownIcon
      renderMenu={() => (
        <div class="p-2 pb-0 space-y-2 flex flex-col">
          <CompactMenuItem Ico={IcoCreditCard} text="Price" onClick={props.onNewPrice} />
          <CompactMenuItem Ico={IcoTag} text="Coupon" onClick={props.onNewCoupon} />
          <CompactMenuItem Ico={IcoFolder} text="Folder" onClick={props.onNewFolder} />
        </div>
      )}
      class="items-center"
    >
      <span class="h-9 inline-flex items-center px-3 font-medium rounded-md hover:bg-gray-50 text-gray-500 relative">
        Create +
      </span>
    </Dropdown>
  );
}
