/**
 * Register the endpoint used to connect to Google Calendar via OAuth.
 *
 *   calendar/oauth-google
 */

import { PageProps, useRouter } from '@components/router';
import { Spinner } from '@components/spinner';
import { showToast } from '@components/toaster';
import { rpx } from 'client/lib/rpx-client';
import { useAsyncEffect } from 'client/utils/use-async-effect';
import { defCalRoute } from './common';

export const route = defCalRoute({ authLevel: 'guide', Page });

/**
 * If this is a return from the initial Google request, we'll have params like:
 *
 * state={application state}
 * code={code used to get an access and refresh token}
 * scope={the scope we passed to Google, we hope...}
 *
 * Or, if there was an error:
 *
 * error={message}
 */
function Page(props: PageProps) {
  const router = useRouter();
  useAsyncEffect(async () => {
    // Google OAuth failed
    if (props.params.error) {
      return;
    }
    // Google OAuth succeeded, and we need to complete it.
    if (props.params.code) {
      await rpx.google.finishOAuth({ code: props.params.code });
      router.goto('/calendar/integrations');
      showToast({
        type: 'ok',
        title: 'Google Calendar connected',
        message: 'Your Google calendar has been connected.',
      });
      return;
    }
    // We're initializing a Google OAuth flow.
    if (props.params.init) {
      const oauth = await rpx.google.beginOAuth();
      location.assign(oauth.url);
    }
  }, []);

  return (
    <section class="flex items-center justify-center fixed inset-0">
      <div class="flex items-center p-6 border rounded-2xl shadow-2xl gap-4 font-semibold">
        {props.params.error && <span>{props.params.error}</span>}
        {!props.params.error && (
          <>
            <Spinner class="border-sky-500" />
            Processing...
          </>
        )}
      </div>
    </section>
  );
}
