import { useRouteParams } from '@components/router';
import { PageNavLink } from '@course/components/page-nav-bar';
import { URLS } from 'shared/urls';
import { useIntl } from 'shared/intl/use-intl';
import { State } from './index';
import { useCurrentUser } from '@components/router/session-context';
import { CourseWithGuide } from 'server/types';
import { Button } from '@components/buttons';
import { IcoPencil } from '@components/icons';

interface Props {
  course: Pick<CourseWithGuide, 'id' | 'guide'>;
  categories: State['categories'];
}

function Item({ category }: { category: Props['categories'][0] }) {
  const { courseId, categoryId: selectedCategory = 'all', courseSlug } = useRouteParams();
  const isSelected = selectedCategory === category.id;

  return (
    <PageNavLink
      isSelected={isSelected}
      href={URLS.student.coursePage({
        course: {
          id: courseId,
          title: courseSlug,
        },
        page: `discussions/${category.id}`,
      })}
    >
      <span class="w-full inline-flex items-center py-2 whitespace-nowrap overflow-hidden text-ellipsis mr-2">
        <span
          style={{
            backgroundColor: category.color,
          }}
          class="block mr-2 text-lg rounded-full w-4 h-4"
        ></span>
        <span class="flex-1">{category.title}</span>
        {category.numDiscussions > 0 && <span class="text-xs">{category.numDiscussions}</span>}
      </span>
    </PageNavLink>
  );
}

export function NavBar(props: Props) {
  const { course, categories } = props;
  const intl = useIntl();
  const currentUser = useCurrentUser();
  const isCourseGuide = course.guide.id === currentUser?.id;

  return (
    <div class="flex flex-col grow overflow-hidden">
      <header class="flex items-center justify-between mb-6">
        <h2 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-200">
          {intl('Categories')}
        </h2>
        {isCourseGuide && (
          <Button
            href={URLS.guide.coursePage({
              courseId: course.id,
              page: 'discussions',
            })}
          >
            <IcoPencil class="w-5 h-5" />
          </Button>
        )}
      </header>
      {categories.map((category) => (
        <Item key={category.id} category={category} />
      ))}
    </div>
  );
}
