import { render } from 'preact-render-to-string';
import { ComponentChildren } from 'preact';
import { Line, BasicEmailWrapper, PrimaryAction, SupportFooter } from './shared';
import { Internationalize } from 'shared/intl';

/**
 * Props for the expiration email templates
 */
type ExpirationEmailProps = {
  intl: Internationalize;
  userName: string;
  guideName: string;
  courseTitle: string;
  courseLabel: string;
  actionUrl: string;
};

/**
 * Wrapper for course expiration date notifications
 */
function ExpirationDateEmail({
  intl,
  userName,
  guideName,
  courseTitle,
  courseLabel,
  actionUrl,
  children,
}: ExpirationEmailProps & {
  children: ComponentChildren;
}) {
  return (
    <BasicEmailWrapper footer={<SupportFooter />}>
      <Line>
        {intl('Hi {userName:string},', {
          userName,
        })}
      </Line>
      <Line>
        {intl('Thanks for participating in {course:string}.', {
          course: courseTitle,
        })}
      </Line>
      {children}
      <Line>{intl('Please reply and let me know if you have any questions.')}</Line>
      <Line>{intl('Best,')}</Line>
      <Line> -- {guideName}</Line>

      <PrimaryAction href={actionUrl}>
        {intl('Jump into the {courseLabel:string}', { courseLabel })}
      </PrimaryAction>
    </BasicEmailWrapper>
  );
}

/**
 * Notification that student's course access has been extended to a given date or indefinitely
 */
export function ExpirationExtended(props: ExpirationEmailProps & { expirationDate?: string }) {
  const { intl, courseLabel, expirationDate } = props;
  return render(
    <ExpirationDateEmail {...props}>
      {expirationDate
        ? intl("I've extended your {courseLabel:string} access to {date:string}.", {
            courseLabel,
            date: expirationDate,
          })
        : intl('I’ve extended your {courseLabel:string} access indefinitely.', {
            courseLabel,
          })}
    </ExpirationDateEmail>,
  );
}

/**
 * Notification that student's course access will expire on a given date
 */
export function AccessWillExpireOn(props: ExpirationEmailProps & { expirationDate: string }) {
  const { intl, courseLabel, expirationDate } = props;
  return render(
    <ExpirationDateEmail {...props}>
      {intl('Your {courseLabel:string} access will expire on {date:string}.', {
        courseLabel,
        date: expirationDate,
      })}
    </ExpirationDateEmail>,
  );
}
