import { RUZUKU_ASSETS_BASE_URL } from 'shared/consts';

export function CoreBanner() {
  return (
    <section class="flex items-center">
      <span class="mr-10 block">
        <img
          class="rounded-sm w-60"
          src={`${RUZUKU_ASSETS_BASE_URL}/undraw_welcoming_re_x0qosvg-ace81c0ca12f0c4e2852650c820a08acc20b295a8ffd2c759d.svg`}
        />
      </span>

      <div class="flex flex-col">
        <h2 class="text-2xl font-bold mb-4">Hi!</h2>
        <p>Ready to start building fantastic courses? You've come to the right place!</p>
      </div>
    </section>
  );
}
