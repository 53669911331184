import { Section } from './section';
import { Preference } from './preference';
import { RadioGroup } from '@course/components/radio-button/radio-group';
import { DigestEmailSubscription, StudentCoursePreferences } from 'server/types';
import { useIntl } from 'shared/intl/use-intl';

export function NotificationPreferences({
  courseTitle,
  guideName,
  preferences,
  save,
}: {
  courseTitle: string;
  guideName: string;
  preferences: StudentCoursePreferences;
  save: (preferences: StudentCoursePreferences) => void;
}) {
  const intl = useIntl();

  return (
    <>
      <Section
        title={intl('Email notifications')}
        description={intl(`Decide which communications you'd like to receive.`)}
      >
        <fieldset class="space-y-8">
          <Preference
            title={intl('Announcements')}
            description={intl(
              'Receive email announcements from {guide:string} re: {course:string}',
              {
                guide: guideName,
                course: courseTitle,
              },
            )}
            checked={preferences.subscribedToAnnouncements}
            onToggle={() => {
              save({
                ...preferences,
                subscribedToAnnouncements: !preferences.subscribedToAnnouncements,
              });
            }}
          />
          <Preference
            title={intl('Meeting Reminders')}
            description={intl(
              'Receive email reminders about scheduled meetings by {guide:string}',
              {
                guide: guideName,
              },
            )}
            checked={preferences.subscribedToMeetingReminders}
            onToggle={() => {
              save({
                ...preferences,
                subscribedToMeetingReminders: !preferences.subscribedToMeetingReminders,
              });
            }}
          />
          <Preference
            title={intl('Comment Replies')}
            description={intl(
              'Receive email notifications when someone comments on one of your posts or when they comment on a post that you have also commented on',
            )}
            checked={preferences.subscribedToCommentReplies}
            onToggle={() => {
              save({
                ...preferences,
                subscribedToCommentReplies: !preferences.subscribedToCommentReplies,
              });
            }}
          />
        </fieldset>
      </Section>
      <Section
        title={intl('Course Digest Email')}
        description={intl(
          "Receive a digest email of the most recent activity in the course. If nothing has happened, we won't send you anything.",
        )}
      >
        <div class="mb-8">
          <p class="text-xs font-bold pb-2">{intl('Digest Frequency')}</p>
          <RadioGroup
            name="digestEmail"
            items={[
              {
                key: 'weekly',
                title: intl('Weekly'),
              },
              {
                key: 'daily',
                title: intl('Daily'),
              },
              {
                key: 'immediate',
                title: intl('Immediate'),
              },
              {
                key: 'off',
                title: intl('None'),
              },
            ]}
            value={preferences.digestEmailSubscription}
            onChange={(newValue) => {
              save({
                ...preferences,
                digestEmailSubscription: newValue as DigestEmailSubscription,
              });
            }}
          />
        </div>
        <Preference
          title={intl(`Include replies to other people's conversations in your digest email`)}
          checked={preferences.includeRepliesInDigest}
          onToggle={() => {
            save({
              ...preferences,
              includeRepliesInDigest: !preferences.includeRepliesInDigest,
            });
          }}
        />
      </Section>
    </>
  );
}
