/**
 * This file contains a generic form component.
 */
import { ComponentChildren, createContext } from 'preact';
import { AutoForm, FormErrors, AutoFormProps, useAutoFormState } from './auto-form';

export const AsyncFormContext = createContext<{
  errors: FormErrors;
  clearErrors: (name: string) => void;
}>({
  errors: {},
  clearErrors: () => {},
});

interface Props {
  id?: string;
  class?: string;
  autoFocus?: boolean;
  onSubmit: AutoFormProps<any>['onSubmit'];
  children: ComponentChildren;
}

/**
 * The AsyncForm component allows us to write basic HTML forms, and have them
 * behave similarly to traditional forms, only asynchronously, w/ JSON API support.
 */
export function AsyncForm({ children, ...props }: Props) {
  const [, ctx] = useAutoFormState({
    initialState: {} as any,
    defaultState: {} as any,
  });

  return (
    <AsyncFormContext.Provider value={ctx}>
      <AutoForm {...props} ctx={{ ...ctx }}>
        {children}
      </AutoForm>
    </AsyncFormContext.Provider>
  );
}
