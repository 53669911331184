import { render } from 'preact-render-to-string';
import { Line, BasicEmailWrapper, PrimaryAction, SupportFooter, Blockquote } from './shared';
import { Internationalize } from 'shared/intl';

interface GiftEmailProps {
  intl: Internationalize;
  gifterEmail: string;
  guideName: string;
  customMessage?: string;
  courseTitle: string;
  actionUrl: string;
  courseLabel: string;
}

export function CourseGiftedEmail({
  intl,
  gifterEmail,
  guideName,
  courseTitle,
  actionUrl,
  customMessage,
  courseLabel,
}: GiftEmailProps) {
  return render(
    <BasicEmailWrapper footer={<SupportFooter />}>
      <Line>
        {intl("You've received a gift of an online {courseLabel:string}!", {
          courseLabel,
        })}
      </Line>
      <Line>
        {intl('From')}: <a href={`mailto:${gifterEmail}`}>{gifterEmail}</a>
      </Line>
      <Line style="text-transform:capitalize;">
        {intl('{courseLabel:string} Name', {
          courseLabel,
        })}
        :{' '}
        {intl('{courseTitle:string} by {guideName:string}', {
          courseTitle,
          guideName,
        })}
      </Line>
      {!!customMessage && <Blockquote>{customMessage}</Blockquote>}
      <PrimaryAction href={actionUrl}>
        {intl('Join your {courseLabel:string} now', {
          courseLabel,
        })}
      </PrimaryAction>
    </BasicEmailWrapper>,
  );
}
