import { Course, TenantRow, Terminology } from 'server/types';
import { Internationalize } from './intl';

export const defaultTerminology: Terminology = {
  course: 'course',
  courses: 'courses',
  module: 'module',
  modules: 'modules',
  lesson: 'lesson',
  lessons: 'lessons',
  meeting: 'meeting',
  meetings: 'meetings',
  discussion: 'discussion',
  discussions: 'discussions',
  notification: 'notification',
  notifications: 'notifications',
  person: 'person',
  people: 'people',
  guide: 'guide',
  guides: 'guides',
  facilitator: 'facilitator',
  facilitators: 'facilitators',
};

export function courseLabel({
  course,
  intl,
  tenant,
}: {
  course: Pick<Course, 'isBundle' | 'isProduct'>;
  intl?: Internationalize;
  tenant: Pick<TenantRow, 'terminology'>;
}) {
  if (intl) {
    return course.isProduct ? intl('product') : course.isBundle ? intl('bundle') : intl('course');
  }
  if (course.isProduct) {
    return 'product';
  }
  if (course.isBundle) {
    return 'bundle';
  }
  return tenant.terminology?.course || defaultTerminology.course;
}

export function emptyModuleTitle(tenant: Pick<TenantRow, 'terminology'>) {
  return `Untitled ${tenant.terminology?.module || defaultTerminology.module}`;
}

export function emptyLessonTitle(tenant: Pick<TenantRow, 'terminology'>) {
  return `Untitled ${tenant.terminology?.lesson || defaultTerminology.lesson}`;
}
