import { RPX } from '../../../server/rpx/types';
import { ajax } from '../ajax';

/**
 * This creates a proxy that mirrors the shape of the RPX endpoints
 * on the server.
 */
function makeProxy(path: string): any {
  // The function which will call the server
  const fn = async (opts: any) => {
    const parts = path.split('/');
    const isGet = parts[parts.length - 1].startsWith('get');
    const query = isGet && opts ? `?arg=${encodeURIComponent(JSON.stringify(opts))}` : '';
    return ajax(`/rpx${path}${query}`, {
      method: isGet ? 'GET' : 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: !isGet ? JSON.stringify(opts) : undefined,
    });
  };

  // The proxy magic which allows us to pretend to have the same
  // heirarchical object structure as our RPX endpoints.
  return new Proxy(fn, {
    get(_target, prop) {
      if (typeof prop === 'string') {
        return makeProxy(`${path}/${prop}`);
      }
    },
  });
}

export type RpxResponse<T extends (opts: any) => Promise<any>> = Awaited<ReturnType<T>>;

export const rpx: RPX = makeProxy('');
