import { useRef } from 'preact/hooks';
import { useDidUpdateEffect } from 'client/utils/use-did-update-effect';

/**
 * useScrollRef returns a function that can be used in a `ref` parameter.
 * It attempts to scroll the element half-way up the screen. If the element
 * is within a `mini-scroll` or `js-scroll-parent`, it will attempt to
 * scroll within that parent. Otherwise, it will scroll the screen.
 */
export function useScrollRef() {
  const ref = useRef<Element | null>(null);
  return (el: Element | null) => {
    if (!el || el === ref.current) {
      return;
    }
    ref.current = el;
    setTimeout(() => {
      const scrollParent = el.closest('.mini-scroll, .js-scroll-parent, .overflow-auto');
      if (!scrollParent) {
        el.scrollIntoView({ behavior: 'smooth' });
        return;
      }
      // visualViewport is not supported on older browsers
      const maxTop = window.visualViewport?.height || window.innerHeight;
      // Attempt to scroll the element roughly half-way up the screen.
      const scrollTo = maxTop / 2;
      const top = el.getBoundingClientRect().top;
      scrollParent.scrollTo({
        top: scrollParent.scrollTop + (top - scrollTo),
        behavior: 'smooth',
      });
    });
  };
}

/**
 * useScrollTo scrolls the ref into view if its argument changes from
 * false to true. It does not do an initial scroll on first render.
 * @param scrollTo - when this changes from false to true, the ref will be
 * scrolled into view.
 */
export function useScrollTo(scrollTo: boolean) {
  const ref = useRef<Element | null>(null);
  useDidUpdateEffect(() => {
    if (scrollTo && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [scrollTo]);
  return ref as any;
}
