/**
 * This file contains the call to action block.
 */

import { ComponentChildren } from 'preact';
import { Button } from '@components/buttons';
import { useState } from 'preact/hooks';
import { EditorProps, ViewerProps } from './types';
import { NavSectionHeader, NavSectionSubheader } from './nav-section-header';
import { BtnClear, BtnColor } from './block-settings';
import { SectionModal } from './section-modal';
import { getProp, setProp } from './editable';
import { RadioButton } from '@course/components/radio-button';
import { showModalForm } from '@components/modal-form';
import { Color, autoColor } from 'shared/colors';

export interface CTAState {
  bgcolor?: string;
  fgcolor?: string;
  text: string;
  url: string;
}

interface SettingsProps {
  fullWidth?: boolean;
  defaultChoice?: {
    url: string;
    description: ComponentChildren;
  };
  /**
   * If specified, this trumps whatever is stored in state.
   */
  urlOverride?: string;
}

export type CTAProps = EditorProps<CTAState> & SettingsProps;

export const initialState: CTAState = {
  bgcolor: Color.gray800,
  text: 'View Pricing ⤑',
  url: '#pricing',
};

function SettingsPane(props: CTAProps) {
  const callToAction = props.state;
  const fgcolor = callToAction.fgcolor || autoColor(callToAction.bgcolor);
  const [isDefaultChoice, setIsDefaultChoice] = useState(
    !props.defaultChoice || props.state.url === props.defaultChoice?.url,
  );

  return (
    <div class="p-4 text-gray-700" onClick={(e) => e.stopPropagation()}>
      <NavSectionHeader title="Link Settings"></NavSectionHeader>

      <div>
        <NavSectionSubheader title="Link Text" />
        <div class="mb-4">
          <input
            placeholder="Call to action text"
            class="ruz-input"
            type="text"
            value={callToAction.text}
            onInput={(e: any) => {
              props.setState((s) => ({ ...s, text: e.target.value }));
            }}
          />
        </div>

        {props.defaultChoice && (
          <>
            <NavSectionSubheader title="Behavior" />
            <div class="flex flex-col gap-2 mb-4">
              <RadioButton
                name="behavior"
                checked={isDefaultChoice}
                onClick={() => {
                  setIsDefaultChoice(true);
                  props.setState((s) => ({ ...s, url: props.defaultChoice?.url || '' }));
                }}
              >
                <span>{props.defaultChoice.description || 'Go to pricing table'}</span>
              </RadioButton>

              <RadioButton
                name="behavior"
                checked={!isDefaultChoice}
                onClick={() => setIsDefaultChoice(false)}
              >
                <span>Go to URL</span>
              </RadioButton>
              {!isDefaultChoice && (
                <input
                  class="ruz-input"
                  type="text"
                  placeholder={'URL'}
                  value={callToAction.url}
                  onInput={(e: any) => {
                    props.setState((s) => ({ ...s, url: e.target.value }));
                  }}
                />
              )}
            </div>
          </>
        )}
      </div>
      <div class="flex flex-col justify-center text-sm">
        <NavSectionSubheader title="Background color">
          {callToAction.bgcolor && (
            <BtnClear onClick={() => props.setState((s) => ({ ...s, bgcolor: undefined }))} />
          )}
        </NavSectionSubheader>
        <div class="mb-4">
          <BtnColor
            value={callToAction.bgcolor}
            bgcolor={callToAction.bgcolor}
            fgcolor={fgcolor}
            onPick={(color) => props.setState((s) => ({ ...s, bgcolor: color }))}
          />
        </div>
        <div class="flex flex-col justify-center">
          <NavSectionSubheader title="Text color">
            {callToAction.fgcolor && (
              <BtnClear
                onClick={() =>
                  props.setState((s) => ({
                    ...s,
                    fgcolor: undefined,
                  }))
                }
              />
            )}
          </NavSectionSubheader>
          <div class="mb-4">
            <BtnColor
              value={callToAction.fgcolor}
              bgcolor={callToAction.bgcolor}
              fgcolor={fgcolor}
              onPick={(color) => props.setState((s) => ({ ...s, fgcolor: color }))}
            >
              {callToAction.fgcolor ? 'Color' : 'Automatic'}
            </BtnColor>
          </div>
        </div>
      </div>
    </div>
  );
}

function CtaButtonEditor(props: CTAProps) {
  const callToAction = props.state;
  const fgcolor = callToAction.fgcolor || autoColor(callToAction.bgcolor);

  return (
    <Button
      class={`bg-gray-800 hover:shadow-lg text-white px-6 text-lg items-center font-medium rounded-full ${
        props.fullWidth ? 'w-full justify-center flex py-4' : 'inline-flex py-2 '
      }`}
      style={{ backgroundColor: callToAction.bgcolor, color: fgcolor }}
      onClick={() =>
        showModalForm(({ resolve }) => (
          <SectionModal position="middle" onHide={resolve}>
            <SettingsPane {...props} />
          </SectionModal>
        ))
      }
    >
      {callToAction.text || initialState.text}
    </Button>
  );
}

function CtaButtonViewer(props: { state: CTAState; fullWidth?: boolean; urlOverride?: string }) {
  const callToAction = props.state;
  const fgcolor = callToAction.fgcolor || autoColor(callToAction.bgcolor);
  const url = props.urlOverride || props.state.url;
  const isHash = url?.startsWith('#');

  return (
    <Button
      class={`${
        props.fullWidth ? 'flex w-full justify-center' : 'inline-flex text-left'
      } bg-gray-800 hover:shadow-lg text-white px-6 py-2 text-lg inline-flex items-center font-medium rounded-full btn-sales-page-cta`}
      style={{ backgroundColor: callToAction.bgcolor, color: fgcolor }}
      href={isHash ? undefined : url}
      onClick={
        isHash
          ? (e) => {
              e.preventDefault();
              document.querySelector(url)?.scrollIntoView({ block: 'center', behavior: 'smooth' });
            }
          : undefined
      }
    >
      {callToAction.text || initialState.text}
    </Button>
  );
}

export function CtaButton(
  props: (EditorProps<any> | ViewerProps<any>) &
    SettingsProps & {
      path: string | Array<string | number>;
    },
) {
  const readonly = !(props as EditorProps<CTAState>).setState;
  const val = getProp(props);
  if (readonly) {
    return (
      <CtaButtonViewer
        state={{ ...val, url: val.url || props.defaultChoice?.url }}
        fullWidth={props.fullWidth}
        urlOverride={props.urlOverride}
      />
    );
  }
  return (
    <CtaButtonEditor
      {...(props as EditorProps<CTAState> & SettingsProps)}
      state={val}
      setState={(f: any) => setProp(props, f)}
    />
  );
}
