import { ManualDom } from '@components/manual-dom';
import { useState } from 'preact/hooks';
import { useDisposableMemo } from 'client/lib/hooks';
import { StickyToolbar } from '@course/components/sticky-toolbar';
import { useAutosaver } from '@components/autosaver';
import { mediaCard, mediaMiddleware } from '@components/media-card';
import {
  cardMiddleware,
  defaultToolbarActions,
  minidoc,
  minidocToolbar,
  placeholder,
} from 'minidoc-editor';
import { LoadingIndicator } from '@components/loading-indicator';
import { SaveStatus } from '@components/save-status';
import { Meeting } from 'server/types';
import { toolbarDropdown } from '@components/toolbar-dropdown';
import { rawHtmlCard } from '@components/raw-html-card';
import { EditorWrapper } from '@components/minidoc/minidoc-root';
import { useIntl } from 'shared/intl/use-intl';
import { Internationalize } from 'shared/intl';
import { useCurrentTenant } from '@components/router/session-context';
import { embedHtmlCard } from '@components/raw-html-card';

type Props = {
  meeting: Pick<Meeting, 'id' | 'description'>;
  onChange: (description: string) => void;
};

/**
 * Create a new minidoc instance.
 */
export function createDescriptionEditor(description: string, intl: Internationalize) {
  return minidoc({
    doc: description,

    middleware: [
      /**
       * Adds placeholder text support to minidoc.
       */
      placeholder('Edit this meeting description.'),
      minidocToolbar([
        ...defaultToolbarActions.filter((x) => x.id !== 'h1'),
        toolbarDropdown({
          intl,
        }),
      ]),
      cardMiddleware([mediaCard, rawHtmlCard, embedHtmlCard]),
      mediaMiddleware({ hideContextMenu: true }),
    ],
  });
}

export function DescriptionTab({ meeting, onChange }: Props) {
  const intl = useIntl();
  const { terminology } = useCurrentTenant();
  const editor = useDisposableMemo(() => {
    return createDescriptionEditor(meeting.description || '', intl);
  }, []);
  const [isSaving, setIsSaving] = useState(false);

  const { isDirty } = useAutosaver({
    editor,
    // State is not required as we don't need
    // a title for the meeting description.
    state: undefined,
    async save(_, description) {
      try {
        setIsSaving(true);
        await onChange(description);
      } finally {
        setIsSaving(false);
      }
    },
  });

  return (
    <div class="p-2 pt-4">
      {isSaving && <LoadingIndicator />}
      <p class="text-gray-500 text-sm mb-3 -mt-1 ">
        This description appears on the {terminology.meeting} information page.
      </p>
      <div class="px-0 md:px-14 py-6">
        <StickyToolbar>
          <div class="max-w-5xl grow flex items-center">
            <ManualDom el={editor.toolbar.root} />
            <nav class="minidoc-toolbar ml-auto border-l border-gray-200 flex items-center px-8">
              <SaveStatus isDirty={isDirty} />
            </nav>
          </div>
        </StickyToolbar>
        <EditorWrapper editor={editor} class="mt-8 px-2 messages-content" />
      </div>
    </div>
  );
}
