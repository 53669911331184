import { useTimezone } from 'client/lib/hooks';
import { timezoneCity } from 'shared/dateutil';

function splitDate(date?: Date) {
  const format = new Intl.DateTimeFormat('en-US', { timeStyle: 'short', dateStyle: 'full' }).format(
    date,
  );
  return format.split(' at ');
}
type Props = { date?: Date; prefix: string; isLoading?: boolean };

export function SendButtonContent(props: Props) {
  const timezone = useTimezone();
  const [datePrefix, dateSuffix] = splitDate(props.date);
  return (
    <>
      <span class="flex gap-4 text-left">
        <span class="flex flex-col">
          <span class="text-gray-500">{props.prefix}</span>
          <span>
            {datePrefix} at {dateSuffix} <strong>{timezoneCity(timezone)} time</strong>
          </span>
        </span>
      </span>
    </>
  );
}
