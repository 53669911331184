import { ComponentChildren } from 'preact';

interface SectionProps {
  title: string;
  description: string;
  children: ComponentChildren;
}

export function Section({ title, description, children }: SectionProps) {
  return (
    <div class="md:grid md:grid-cols-3 md:gap-8 border dark:border-gray-700 rounded-md p-8 mb-8 text-zinc-500 dark:text-gray-400">
      <div class="md:col-span-1">
        <h3 class="text-lg font-medium leading-6 text-zinc-900 dark:text-white">{title}</h3>
        <p class="mt-1 text-sm text-zinc-500 dark:text-gray-400">{description}</p>
      </div>
      <div class="mt-5 md:mt-0 md:col-span-2">{children}</div>
    </div>
  );
}
