import { useState } from 'preact/hooks';
import * as fmt from 'shared/payments/fmt';
import { AsyncForm } from '@components/async-form';
import { CurrencyInput, defaultCurrency } from '../pmts/components/currency-input';
import { Toggle } from '@components/toggle';
import { BtnPrimary, Button } from '@components/buttons';
import { IcoArrowRight } from '@components/icons';
import { WizardSubtitle } from './upsell-wizard';
import { Price, ProductAndPricePreview, UpsellPriceSummary } from './product-price-preview';
import { Offer, UpsellProduct } from './types';

export type PriceType = 'single' | 'plan' | 'monthly' | 'annually';

type Props = {
  product: UpsellProduct;
  priceType: PriceType;
  listPrice?: number;
  price?: Offer['price'];
  onSave(price: Pick<Offer, 'price' | 'listPrice'>): void;
};

export function OfferPriceForm(props: Props) {
  const { product, priceType, price, onSave } = props;
  const [currency, setCurrency] = useState(() => defaultCurrency(price?.currency));
  const [amount, setAmount] = useState(() =>
    price ? fmt.price({ ...price, omitCurrency: true }) : '',
  );
  const [listPrice, setListPrice] = useState(() =>
    props.listPrice
      ? fmt.price({ priceInCents: props.listPrice, currency, omitCurrency: true })
      : '',
  );
  const [rawNumPayments, setNumPayments] = useState(price?.numPayments?.toString() || '');
  const [allowSelfCancel, setAllowSelfCancel] = useState(!!price?.allowSelfCancel);
  const [showListPrice, setShowListPrice] = useState(!!listPrice);
  const numPayments = rawNumPayments ? parseInt(rawNumPayments, 10) : undefined;

  const priceInCents = fmt.decimalToCents(amount, currency);
  const listPriceInCents = fmt.decimalToCents(listPrice, currency);
  const paymentType =
    priceInCents === 0
      ? 'free'
      : priceType === 'single'
      ? 'paid'
      : priceType === 'plan'
      ? 'paymentplan'
      : 'subscription';

  const offerPrice: Price = {
    currency,
    paymentType,
    priceInCents,
    numPayments,
    interval:
      priceType === 'plan' || priceType === 'monthly'
        ? 'month'
        : priceType === 'annually'
        ? 'year'
        : undefined,
  };

  return (
    <>
      <ProductAndPricePreview product={product} listPrice={listPriceInCents} price={offerPrice} />
      <WizardSubtitle>
        {priceType === 'single' && 'Set offer price'}
        {priceType === 'plan' && 'Set payment plan price'}
        {priceType === 'monthly' && 'Set monthly price'}
        {priceType === 'annually' && 'Set annual price'}
      </WizardSubtitle>
      <AsyncForm
        class="flex flex-col gap-6"
        onSubmit={async (data) => {
          const priceInCents = fmt.decimalToCents(amount, data.currency);
          onSave({
            listPrice: listPriceInCents,
            price: {
              id: props.price?.id || '',
              productId: props.product.id,
              name: props.price?.name || '',
              currency: data.currency,
              priceInCents,
              paymentType,
              interval:
                priceType === 'annually' ? 'year' : priceType === 'single' ? undefined : 'month',
              allowSelfCancel,
              allowPaypal: false,
              allowStripe: true,
              numPayments: data.numPayments ? parseInt(data.numPayments, 10) : undefined,
              isEnabled: true,
            },
          });
        }}
      >
        <section class="flex flex-col gap-6">
          <div class="flex flex-col">
            <label for="amount" class="cursor-pointer mb-1">
              Offer price
            </label>
            <div class="flex items-center gap-2">
              <CurrencyInput
                placeholder="e.g. 250"
                type="text"
                id="amount"
                name="amount"
                required
                autoFocus
                currency={currency}
                onCurrencyChange={setCurrency}
                value={amount}
                onInput={(e: any) => setAmount(e.target.value)}
              />
              {(priceType === 'plan' || priceType === 'monthly') && 'per month'}
              {priceType === 'annually' && 'per year'}
            </div>
          </div>
          <div class="flex flex-col">
            {!showListPrice && (
              <Button
                class="flex flex-col text-left text-indigo-600 underline rounded-md"
                onClick={() => {
                  setShowListPrice(true);
                  setListPrice(`${parseInt(amount || '50') * 2 || 100}`);
                }}
              >
                Improve your offer by adding a list price
              </Button>
            )}
            {showListPrice && (
              <>
                <div class="mb-1 flex gap-4">
                  <label for="listPrice" class="cursor-pointer">
                    List price
                  </label>
                  <button
                    type="button"
                    onClick={() => {
                      setShowListPrice(false);
                      setListPrice('');
                    }}
                    class="text-indigo-600"
                  >
                    Remove
                  </button>
                </div>
                <div class="flex gap-6 items-center">
                  <CurrencyInput
                    placeholder="e.g. 300"
                    type="text"
                    id="listPrice"
                    name="listPrice"
                    currency={currency}
                    autoFocus
                    onCurrencyChange={setCurrency}
                    value={listPrice}
                    onInput={(e: any) => setListPrice(e.target.value)}
                  />
                  <span class="flex flex-col text-gray-600">
                    <span>Price preview:</span>
                    <span>
                      <UpsellPriceSummary
                        price={offerPrice}
                        listPrice={listPriceInCents}
                        showPlaceholder
                      />
                    </span>
                  </span>
                </div>
              </>
            )}
          </div>

          {priceType === 'plan' && (
            <>
              <div class="flex flex-col">
                <label for="amount" class="cursor-pointer mb-1">
                  Number of months
                </label>
                <div>
                  <input
                    type="text"
                    class="ruz-input max-w-14"
                    placeholder="#"
                    name="numPayments"
                    value={rawNumPayments}
                    onInput={(e: any) => setNumPayments(e.target.value)}
                    required
                  />
                </div>
              </div>

              <label class="flex gap-3 items-center cursor-pointer">
                <Toggle checked={allowSelfCancel} onClick={() => setAllowSelfCancel((x) => !x)} />
                <span>Students can cancel their payment plan without contacting you</span>
              </label>
            </>
          )}
        </section>

        <footer class="pt-4 sticky bottom-0 w-full bg-white empty:hidden">
          <BtnPrimary class="rounded-full px-4 gap-2">
            Continue
            <IcoArrowRight />
          </BtnPrimary>
        </footer>
      </AsyncForm>
    </>
  );
}
