import { IcoCheckCircle, IcoDotsHorizontal, IcoLinkSlash, IcoRefresh } from '@components/icons';
import { BtnSecondary } from '@components/buttons';
import { Pill } from '@components/pill';
import { Dropdown, MenuItem } from '@components/dropdown';
import { ComponentChildren } from 'preact';

type Props = {
  name: string;
  description: ComponentChildren;
  isInstalled: boolean;
  icon: JSX.Element;
  isMultiline?: boolean;
  connectText?: string;
  connectedText?: string;
  reconnectText?: string;
  onIntegrate: () => void;
  onDisconnect: () => void;
};

export function IntegrationRow({
  name,
  description,
  isInstalled,
  icon,
  isMultiline,
  onIntegrate,
  onDisconnect,
  connectText = 'Connect',
  reconnectText = 'Reconnect',
  connectedText = 'Connected',
}: Props) {
  return (
    <div class="flex justify-between items-center p-4 border rounded-2xl gap-4 font-medium">
      <div class={`flex ${isMultiline ? '' : 'items-center'} gap-4`}>
        {icon}
        <div class="flex flex-col">
          <div class="flex gap-2 font-semibold">
            {name}
            {isInstalled && connectedText && (
              <Pill color="green" centered>
                <IcoCheckCircle /> {connectedText}
              </Pill>
            )}
          </div>
          <div class="text-sm text-gray-500">{description}</div>
        </div>
      </div>
      {!isInstalled && (
        <BtnSecondary class="rounded-full px-8 whitespace-nowrap" onClick={onIntegrate}>
          {connectText}
        </BtnSecondary>
      )}
      {isInstalled && (
        <Dropdown
          triggerClass="max-w-full"
          hideDownIcon
          noPadding
          class="max-w-full"
          renderMenu={() => (
            <div class="p-2 gap-2 flex flex-col">
              <MenuItem onClick={onIntegrate} regularCase>
                <IcoRefresh />
                {reconnectText}
              </MenuItem>
              <MenuItem onClick={onDisconnect} regularCase>
                <span class="flex gap-2 text-red-500">
                  <IcoLinkSlash />
                  Disconnect
                </span>
              </MenuItem>
            </div>
          )}
        >
          <IcoDotsHorizontal class="w-5 h-5 text-gray-500" />
        </Dropdown>
      )}
    </div>
  );
}
