/**
 * The ruzcal management page wrapper.
 */

import { ComponentChildren } from 'preact';
import { IcoCalendar, IcoClock, IcoCog, IcoCube, IcoSettings } from '@components/icons';
import { useDocumentTitle } from 'client/utils/use-document-title';
import { MenuItem } from '@components/dropdown';
import { RootNav, RootNavlink, RootPage, RootUserMenu } from '@components/root-page-layout';
import { Crumb } from '@course/components/breadcrumbs';

type PageType = 'bookings' | 'availability' | 'eventtypes' | 'integrations' | 'settings';

function PageLogo() {
  return (
    <a href="/calendar" class="text-2xl font-medium flex items-center gap-4">
      <span class="shrink-0 size-10 flex items-center justify-center bg-indigo-600 text-white rounded-full">
        <IcoCalendar class="size-6" />
      </span>
      ruzcal
    </a>
  );
}

export function RuzcalMgmtPage({
  title,
  actions,
  children,
  crumbs,
  currentPage,
}: {
  title: string;
  actions?: ComponentChildren;
  crumbs?: Crumb[];
  children: ComponentChildren;
  currentPage: PageType;
}) {
  useDocumentTitle([title]);
  return (
    <RootPage
      logo={<PageLogo />}
      title={title}
      crumbs={crumbs}
      nav={
        <RootNav>
          <RootNavlink
            Ico={IcoCalendar}
            text="Calendar"
            href="/calendar/bookings"
            isSelected={currentPage === 'bookings'}
          />
          <RootNavlink
            Ico={IcoClock}
            text="Availability"
            href="/calendar/availability"
            isSelected={currentPage === 'availability'}
          />
          <RootNavlink
            Ico={IcoCube}
            text="Event types"
            href="/calendar/event-types"
            isSelected={currentPage === 'eventtypes'}
          />
          <RootNavlink
            Ico={IcoCog}
            text="Integrations"
            href="/calendar/integrations"
            isSelected={currentPage === 'integrations'}
          />
          <RootNavlink
            Ico={IcoSettings}
            text="Settings"
            href="/calendar/settings"
            isSelected={currentPage === 'settings'}
          />
        </RootNav>
      }
      actions={actions}
      accountMenu={
        <RootUserMenu
          renderMenu={() => (
            <>
              <MenuItem href="/calendar/settings">Settings</MenuItem>
              <MenuItem href={`/logout?goto=${encodeURIComponent('/calendar/login')}`}>
                Logout
              </MenuItem>
            </>
          )}
        />
      }
    >
      {children}
    </RootPage>
  );
}
