import { FixedContent, FixedPage } from '@course/components/fixed-page';
import { AdminNav, AdminTabWrapper } from '@course/components/admin-nav';
import { rpx } from 'client/lib/rpx-client';
import { UserProfileIcon } from '@components/avatars';
import { useCurrentTenant, useCurrentUser } from '@components/router/session-context';
import { hasLevel } from 'shared/auth';
import { BtnCopy, BtnPrimary, BtnSecondary, Button } from '@components/buttons';
import { showError } from '@components/app-error';
import { toQueryString } from 'shared/urls';
import { IcoLock, IcoPencil, IcoSwitch } from '@components/icons';
import { ModalForm, showModalForm } from '@components/modal-form';
import { Password } from '@components/password';
import { showToast } from '@components/toaster';
import { FormGroup } from '@components/async-form';
import { UserLevelFormField } from './user-level-form-field';
import { useState } from 'preact/hooks';
import { TenantCoursesList } from '../admin-courses/course-list';
import { AdminNotes } from './admin-notes';
import { AccountEditor } from './account-editor';
import { showMergeStudentsModal } from './merge-students-modal';
import { UserAuditLogsSlider } from './user-detail-audit-logs';
import { SlideOver } from '@components/slide-over';
import { useAsyncData } from 'client/lib/hooks';
import { DefaultSpinner } from '@components/spinner';
import { BaseDialog, showDialog } from '@components/dialog';
import { TenantsList } from '../superadmin-manage-tenants/tenants-list';
import { Tenant } from 'server/types';
import { LoadedProps, RouteLoadProps, defRoute } from '@components/router';

export const route = defRoute({
  load,
  authLevel: 'admin',
  Page,
});

async function load(route: RouteLoadProps) {
  const { userId } = route.params;
  const user = await rpx.admin.getTenantUser({ userId });
  return {
    user,
  };
}

type Props = LoadedProps<typeof load>;

/**
 * List the tenants where the specified user has a profile.
 */
function TenantList(props: { user: Props['state']['user']; onHide(): void }) {
  const userId = props.user.id;
  const result = useAsyncData(() => rpx.admin.getUserTenants({ userId }), [userId]);
  return (
    <SlideOver close={props.onHide}>
      <h2 class="font-bold mb-4">{props.user.name} is in the following tenants</h2>
      {result.isLoading && <DefaultSpinner />}
      <div class="flex flex-col gap-4">
        {!result.isLoading &&
          result.data?.map((t) => (
            <a
              key={t.domain}
              href={`https://${t.domain}/admin/people/${userId}`}
              class="flex flex-col p-4 rounded-sm border hover:bg-gray-50"
            >
              <span>{t.name}</span>
              <strong>{t.domain}</strong>
            </a>
          ))}
      </div>
    </SlideOver>
  );
}

/**
 * For superadmins, display a user who does not belong to this tenant.
 */
function OtherTenantUser(props: Props) {
  const tenant = useCurrentTenant()!;
  const currentUser = useCurrentUser();

  // Toggle a slideover for user tenant memberhips for superadmins
  const [showTenants, setShowTenants] = useState(false);

  if (currentUser?.level !== 'superadmin') {
    return null;
  }

  return (
    <>
      {showTenants && <TenantList user={props.state.user} onHide={() => setShowTenants(false)} />}
      <p class="flex gap-2 items-center">
        <span>This user does not belong to {tenant.domain}.</span>
        <BtnSecondary class="" onClick={() => setShowTenants(true)}>
          View {props.state.user.numTenants} tenants
        </BtnSecondary>
      </p>
    </>
  );
}

/**
 * Display a user that bleongs to the current tenant.
 */
function CurrentTenantUser(props: Props) {
  const { state, setState } = props;
  const { user } = state;
  const currentUser = useCurrentUser();
  const isSuperAdmin = currentUser?.level === 'superadmin';
  const canManage = hasLevel(currentUser, user.level);

  // Toggle a slideover for user audit logs, for superadmin
  const [showUserLogs, setShowUserLogs] = useState(false);

  // Toggle a slideover for user tenant memberhips for superadmins
  const [showTenants, setShowTenants] = useState(false);

  async function moveToAnotherTenant(tenant?: Pick<Tenant, 'id' | 'name'>) {
    const isConfirmed = await showDialog({
      mode: 'warn',
      title: 'Move guide courses?',
      children: `Are you sure you want to move ${user.name}'s courses to ${
        tenant?.name || 'core tenant'
      }? This action cannot be undone.`,
      confirmButtonText: 'Move courses',
    });
    if (!isConfirmed) {
      return;
    }

    try {
      await rpx.admin.migrateCoursesAsSuperadmin({
        userId: user.id,
        fromTenantId: user.tenantId,
        toTenantId: tenant?.id,
      });
      showToast({
        title: 'Courses moved',
        message: `${user.name}'s courses were successfully moved.`,
        type: 'ok',
      });
    } catch (err) {
      showError(err);
    }
  }

  const mimic = async (gotoUrl: string) => {
    try {
      await rpx.admin.mimicUser({ userId: user.id });
      location.assign(gotoUrl);
    } catch (err) {
      showError(err);
    }
  };
  return (
    <>
      {isSuperAdmin && showUserLogs && (
        <UserAuditLogsSlider closeSlider={() => setShowUserLogs(false)} />
      )}
      {isSuperAdmin && showTenants && (
        <TenantList user={user} onHide={() => setShowTenants(false)} />
      )}
      <div class="flex flex-col border-b pb-8 mb-8">
        <div class="flex justify-between items-center">
          <div class="flex items-center my-8">
            <UserProfileIcon user={user} size="w-24 h-24 text-4xl" />
            <span class="flex flex-col ml-6">
              <strong>{user.name}</strong>
              <span>
                {user.email}
                <BtnCopy value={user.email} />
              </span>
            </span>
          </div>
          <div class="flex flex-col">
            <span>
              <span class="text-gray-400 inline-block w-12 mr-2">ID</span>
              {user.id}
              <BtnCopy value={user.id} />
            </span>
            <span>
              <span class="text-gray-400 inline-block w-12 mr-2">Role</span>
              {user.level}
              {canManage && (
                <Button
                  class="text-indigo-600 ml-2"
                  onClick={() => {
                    showModalForm(() => (
                      <ModalForm
                        title={`Change ${user.name}'s role`}
                        confirmButtonText="Set role"
                        onSubmit={async (data) => {
                          await rpx.admin.setTenantUserLevel({
                            userId: user.id,
                            level: data.level,
                          });
                          setState((s) => ({ ...s, user: { ...s.user, level: data.level } }));
                          showToast({
                            title: 'Role changed',
                            message: `${user.name}'s role is now ${data.level}.`,
                            type: 'ok',
                          });
                        }}
                      >
                        <UserLevelFormField level={user.level} currentUser={currentUser} />
                      </ModalForm>
                    ));
                  }}
                >
                  change
                </Button>
              )}
            </span>
            {user.stripeAcct && (
              <span>
                <span class="text-gray-400 inline-block w-12 mr-2">Stripe</span>
                <a href={`https://dashboard.stripe.com/connect/accounts/${user.stripeAcct}`}>
                  {user.stripeAcct}
                </a>
              </span>
            )}
            {!!user.ownedTenantDomain && (
              <span>
                <span class="text-gray-400 inline-block w-12 mr-2">Tenant</span>
                <a
                  href={`https://${user.ownedTenantDomain}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {user.ownedTenantDomain}
                </a>
              </span>
            )}
          </div>
        </div>
        <nav class="space-x-4 flex items-center mb-4">
          {canManage && (
            <>
              <BtnSecondary
                onClick={() => {
                  showModalForm(() => (
                    <ModalForm
                      title={`Change ${user.name}'s password`}
                      confirmButtonText="Set password"
                      onSubmit={async (data) => {
                        await rpx.admin.updateUserPassword({
                          userId: user.id,
                          password: data.password,
                          confirmPassword: data.password,
                        });
                        showToast({
                          title: 'Password saved',
                          message: `${user.name}'s password was successfully changed.`,
                          type: 'ok',
                        });
                      }}
                    >
                      <FormGroup prop="password">
                        <Password placeholder="New password" name="password" />
                      </FormGroup>
                    </ModalForm>
                  ));
                }}
              >
                <IcoLock class="w-4 h-4 mr-1 opacity-50" />
                Change password
              </BtnSecondary>
              <BtnSecondary onClick={() => mimic('/account')}>
                <IcoPencil class="w-4 h-4 mr-1 opacity-50" />
                Edit profile
              </BtnSecondary>
              {user.level === 'student' && (
                <BtnSecondary
                  onClick={() =>
                    showMergeStudentsModal({
                      ...user,
                      name: user.name || '',
                    })
                  }
                >
                  <IcoSwitch class="w-4 h-4 mr-1 opacity-50" />
                  Merge student
                </BtnSecondary>
              )}
              {isSuperAdmin && (
                <BtnSecondary onClick={() => setShowUserLogs(true)}>View logs</BtnSecondary>
              )}
              {isSuperAdmin && (
                <BtnSecondary onClick={() => setShowTenants(true)}>
                  View {user.numTenants} tenants
                </BtnSecondary>
              )}

              <BtnPrimary onClick={() => mimic('/')}>Mimic</BtnPrimary>
            </>
          )}
        </nav>
        {isSuperAdmin && (
          <div class="space-y-6 mt-6 pt-6 border-t">
            <h2 class="text-xl">Ruzuku Support</h2>
            {(user.level === 'admin' || user.level === 'guide') && (
              <BtnSecondary
                onClick={() => {
                  showModalForm(({ resolve }) => (
                    <BaseDialog contentWidth onClose={resolve}>
                      <header class="flex justify-between p-8 pb-0">
                        <h2 class="font-semibold text-lg">Select a tenant</h2>
                        <BtnPrimary
                          onClick={() => {
                            resolve();
                            moveToAnotherTenant();
                          }}
                        >
                          Select Core Tenant
                        </BtnPrimary>
                      </header>
                      <section class="p-8">
                        <TenantsList
                          onItemSelect={(tenant) => {
                            resolve();
                            moveToAnotherTenant(tenant);
                          }}
                        />
                      </section>
                    </BaseDialog>
                  ));
                }}
              >
                Move guide's courses to another tenant
              </BtnSecondary>
            )}
            {user.level !== 'student' && <AccountEditor userId={user.id} />}
            <AdminNotes userId={user.id} notes={user.adminNotes || ''} />
          </div>
        )}
      </div>
      <TenantCoursesList type="user" userId={user.id} dontRewriteUrl canMimic={canManage} />
    </>
  );
}

function Page(props: Props) {
  const tenant = useCurrentTenant();
  const hasTenantProfile = props.state.user.tenantId === tenant.id;
  return (
    <FixedPage>
      <FixedContent class="bg-white">
        <AdminNav currentPage="people" />
        <AdminTabWrapper>
          <h2 class="text-2xl">
            <a href={`/admin/people?${toQueryString({ q: props.params.q })}`}>People</a>
            {' / '}
            {props.state.user.name}
          </h2>
          {hasTenantProfile && <CurrentTenantUser {...props} />}
          {!hasTenantProfile && <OtherTenantUser {...props} />}
        </AdminTabWrapper>
      </FixedContent>
    </FixedPage>
  );
}
