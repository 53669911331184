import { showError } from '@components/app-error';
import { Button } from '@components/buttons';
import { IcoChevronRight, IcoPaypal } from '@components/icons';
import { DefaultSpinner, Spinner } from '@components/spinner';
import { useAsyncEffect } from 'client/utils/use-async-effect';
import { useState } from 'preact/hooks';
import { useIntl } from 'shared/intl/use-intl';
import { PurchaseFn } from 'shared/payments/types';

/**
 * Indicate whether or not the URL params contain the Paypal checkout response.
 *
 * Paypal returns to this page with route params like these:
 * token=3WW62681EA743092J&PayerID=P5EB7LKGYN3RA
 * If those params exist, we need to complete the payment process.
 */
export function isCompleting(params: Record<string, string>) {
  return params.token && params.PayerID;
}

function PaypalButton({ beginPurchase }: { beginPurchase: PurchaseFn }) {
  const [isLoading, setIsLoading] = useState(false);
  const redirectToCheckout = async () => {
    setIsLoading(true);
    try {
      const result = await beginPurchase({ paypal: 'begin' });
      if (!result) {
        return;
      }
      if (result.type === 'action') {
        location.assign(result.action);
      } else {
        console.error(result);
        throw new Error(`Invalid response.`);
      }
    } catch (err) {
      setIsLoading(false);
      showError(err);
    }
  };

  return (
    <Button
      type="button"
      class="border border-gray-300 rounded-sm w-full hover:bg-gray-50 p-2 relative"
      disabled={isLoading}
      data-test="btnpaypalpay"
      onClick={redirectToCheckout}
    >
      <span class="flex justify-center">
        <IcoPaypal class="h-8 w-auto" />
      </span>
      <span class="absolute inset-y-0 right-0 flex items-center justify-center p-4">
        {isLoading && <Spinner />}
        {!isLoading && <IcoChevronRight />}
      </span>
    </Button>
  );
}

function PaypalFinalizer({
  beginPurchase,
  onPurchaseComplete: completePurchase,
}: {
  beginPurchase: PurchaseFn;
  onPurchaseComplete(): Promise<unknown>;
}) {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(true);
  const finishCheckout = async () => {
    setIsLoading(true);
    try {
      await beginPurchase({ paypal: 'complete' });
      await completePurchase();
    } catch (err) {
      showError(err);
      setIsLoading(false);
    }
  };

  useAsyncEffect(finishCheckout, []);

  if (isLoading) {
    return <DefaultSpinner />;
  }

  return (
    <p>
      {intl('Checkout failed.')}
      <Button class="text-indigo-600" onClick={finishCheckout}>
        {intl('Try again.')}
      </Button>
    </p>
  );
}

export function PaypalCheckoutForm({
  beginPurchase,
  onPurchaseComplete,
  urlParams,
}: {
  beginPurchase: PurchaseFn;
  onPurchaseComplete(): Promise<unknown>;
  urlParams: Record<string, string>;
}) {
  if (isCompleting(urlParams)) {
    return (
      <PaypalFinalizer beginPurchase={beginPurchase} onPurchaseComplete={onPurchaseComplete} />
    );
  }

  return <PaypalButton beginPurchase={beginPurchase} />;
}
