import { IcoHeart, IcoHeartSolid } from '@components/icons';
import { Spinner } from '@components/spinner';
import { useState } from 'preact/hooks';
import { Case } from '@components/conditional';
import { Button } from '@components/buttons';
import { useIntl } from 'shared/intl/use-intl';
import { rpx, RpxResponse } from 'client/lib/rpx-client';

const store = rpx.comments;

interface LikeButtonProps {
  isLiked: boolean;
  onClick: () => void;
}

interface LikeCountProps {
  commentId: UUID;
  count: number;
}

type CommentLikesResponse = RpxResponse<typeof store.getCommentLikes>;

export function LikeCount({ commentId, count }: LikeCountProps) {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState<CommentLikesResponse | undefined>(undefined);

  async function loadUsers() {
    if (isLoading || !!users) {
      return;
    }

    setIsLoading(true);

    try {
      const results = await store.getCommentLikes({
        id: commentId,
      });
      setUsers(results);
    } finally {
      setIsLoading(false);
    }
  }

  if (!count) {
    return null;
  }

  return (
    <Button class="group ml-1 text-inherit" onClick={loadUsers} onMouseOver={loadUsers}>
      <span class="cursor-pointer group-hover:underline">
        {intl('{count:number} {count | pluralize like likes}', {
          count,
        })}
      </span>
      <span class="invisible absolute right-0 min-w-36 flex flex-col items-center group-hover:visible z-50 shadow-lg bg-black/75 text-white p-4 rounded-sm">
        <Case when={!isLoading} fallback={<Spinner />}>
          {users?.map(({ id, name }) => (
            <p key={id}>{name}</p>
          ))}
          {users && users.length < count && (
            <p>
              {intl('and {count:number} more...', {
                count: count - users.length,
              })}
            </p>
          )}
        </Case>
      </span>
    </Button>
  );
}

export function LikeButton({ isLiked, onClick }: LikeButtonProps) {
  const [animating, setAnimating] = useState(false);

  return (
    <Button
      class={`${animating ? 'animate-wiggle' : ''} ${
        isLiked ? 'text-red-600 hover:text-red-400' : 'text-red-400 hover:text-red-600'
      } focus:outline-hidden`}
      onClick={() => {
        setAnimating(true);
        onClick();
      }}
      onAnimationEnd={() => setAnimating(false)}
    >
      {isLiked ? <IcoHeartSolid class="w-5 h-5" /> : <IcoHeart class="w-5 h-5" />}
    </Button>
  );
}
