const colorPairs = [
  'EC4899',
  'DB2777',
  '8B5CF6',
  '6D28D9',
  '6366F1',
  '4F46E5',
  '60A5FA',
  '2563EB',
  '34D399',
  '10B981',
  'FCD34D',
  'F59E0B',
  'F87171',
  'EF4444',
];

export function bgcolor(initials: string) {
  let id = 0;
  const len = Math.min(initials.length, 25);
  for (let i = 0; i < len; ++i) {
    id += initials.charCodeAt(i);
  }
  const a = colorPairs[id % colorPairs.length];
  const b = colorPairs[(initials.charCodeAt(1) + id) % colorPairs.length];
  const deg = id % 360;
  return `linear-gradient(${deg}deg, #${a}, #${a} 30%, #${b} 70%, #${b})`;
}

/**
 * Extract initials from a username
 */
export function generateNameInitials(userName?: string) {
  if (!userName) {
    return '';
  }

  const allNames = userName.trim().split(' ');
  const initials = allNames.reduce((acc, curr, index) => {
    // Only pick the first and last block's initials
    if (index === 0 || index === allNames.length - 1) {
      acc += curr.charAt(0).toLocaleUpperCase();
    }
    return acc;
  }, '');
  return initials;
}
