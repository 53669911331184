import type { HTMLAttributes } from 'preact/compat';
import { useEffect, useMemo, useRef, useState } from 'preact/hooks';

function fzfScore(chars: string, str: string, size: number) {
  let score = '';
  for (const ch of chars) {
    const i = str.indexOf(ch);
    if (i < 0) {
      return '';
    }
    score += `${i}`.padStart(size, '0');
  }
  return score;
}

function fzf(value: string, values: string[]) {
  const size = `${values.reduce((acc, s) => Math.max(acc, s.length), 0)}`.length;
  const result = values.reduce(
    (acc, x) => {
      const score = fzfScore(value, x.toLowerCase(), size);
      if (!score) {
        return acc;
      }
      return !acc.score || score < acc.score ? { score, value: x } : acc;
    },
    { score: '', value: '' },
  );
  return result.value;
}

export function Combobox({
  value,
  onChange,
  values,
  class: className = 'inline-ruz-input absolute inset-0',
  wrapperClass = 'relative',
  ...props
}: Omit<HTMLAttributes<HTMLInputElement>, 'onChange' | 'value'> & {
  value: string;
  onChange(value: string): void;
  values: string[];
  wrapperClass?: string;
}) {
  const [showDropdown, setShowDropdown] = useState(false);
  const ref = useRef<HTMLElement | null>(null);
  const match = useMemo(() => {
    return fzf(value, values);
  }, [value, values]);

  useEffect(() => {
    if (!ref.current || !showDropdown) {
      return;
    }
    ref.current.querySelector<HTMLElement>(`[data-value="${match}"]`)?.scrollIntoView({
      block: 'center',
    });
  }, [ref.current, match, showDropdown]);

  return (
    <span
      class={`inline-flex flex-col gap-1 text-inherit ${wrapperClass || ''}`}
      ref={ref}
      onBlur={(e: any) => {
        if (ref.current?.contains(e.relatedTarget)) {
          return;
        }
        setShowDropdown(false);
      }}
    >
      <input
        value={value}
        class={className}
        onClick={() => setShowDropdown(true)}
        onFocus={() => setShowDropdown(true)}
        onKeyDown={(e: any) => {
          if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
            const index = Math.min(
              values.length - 1,
              Math.max(0, Math.max(0, values.indexOf(value)) + (e.key === 'ArrowDown' ? 1 : -1)),
            );
            onChange(values[index]);
            return;
          }
          if (showDropdown && e.key === 'Enter') {
            match && onChange(match);
            setShowDropdown(false);
            return;
          }
        }}
        onInput={(e: any) => onChange(e.target.value)}
        {...props}
      />
      {showDropdown && (
        <span
          class="border border-gray-300 rounded-md shadow-lg absolute bg-white top-full mt-2 left-0 z-50 flex flex-col w-32 h-64 overflow-auto py-2"
          tabIndex={-1}
        >
          {values.map((t) => {
            const selected = t === match;
            return (
              <button
                key={t}
                tabIndex={-1}
                type="button"
                class={`p-2 hover:bg-gray-100 text-left ${selected ? 'bg-gray-100' : ''}`}
                data-value={t}
                onClick={() => {
                  onChange(t);
                  ref.current?.querySelector('input')?.select();
                  setShowDropdown(false);
                }}
              >
                {t}
              </button>
            );
          })}
        </span>
      )}
    </span>
  );
}
