import { ModalForm, showModalForm } from '@components/modal-form';
import { useState } from 'preact/hooks';
import { Folder, store } from './state';
import { showToast } from '@components/toaster';
import { FormGroup } from '@components/async-form';
import { SearchBox } from '@components/search-box';
import { Button } from '@components/buttons';

export function showMoveItemsModal(props: {
  folders: Folder[];
  items: string[];
  productId: string;
  onMove(toFolder: Folder): void;
}) {
  return showModalForm(() => {
    const [searchTerm, setSearchTerm] = useState('');
    const [toFolderId, setToFolderId] = useState('');
    const [mode, setMode] = useState<'new' | 'existing'>('existing');
    const matchingFolders = !searchTerm
      ? props.folders
      : props.folders.filter((x) => x.name.includes(searchTerm));
    return (
      <ModalForm
        title="Move items"
        confirmButtonText="Move items"
        onSubmit={async () => {
          const newFolder =
            mode === 'new'
              ? await store.createFolder({ name: searchTerm, productId: props.productId })
              : undefined;
          const toFolder = newFolder || props.folders.find((x) => x.id === toFolderId);
          if (!toFolder) {
            throw new Error(`Could not find the requested folder.`);
          }
          await store.addFolderItems({
            id: toFolder.id,
            items: props.items,
          });
          props.onMove({ ...toFolder, type: 'folder' });
          showToast({
            title: 'Items moved',
            message: `Moved items to ${toFolder.name}.`,
            type: 'ok',
          });
        }}
      >
        <FormGroup
          class="flex-1 overflow-y-auto mini-scroll max-h-screen-minus-20 pl-1 pr-2"
          prop="toFolderId"
        >
          <label class="space-y-1 sticky top-0 bg-white block py-2">
            <SearchBox onTermChange={setSearchTerm} value={searchTerm} placeholder="Folder name" />
          </label>
          <div class="mt-2 border rounded-sm overflow-hidden">
            {matchingFolders.map((folder) => {
              return (
                <Button
                  key={folder.id}
                  onClick={() => setToFolderId(folder.id)}
                  class={`p-2 py-3 text-left w-full border-b ${
                    toFolderId === folder.id
                      ? 'bg-indigo-600 text-white'
                      : 'hover:bg-gray-50 border-b'
                  }`}
                >
                  {folder.name}
                </Button>
              );
            })}
            {searchTerm && (
              <Button
                onClick={() => setMode('new')}
                class="p-2 py-3 text-left hover:bg-gray-50 w-full"
              >
                Create new folder "{searchTerm}"
              </Button>
            )}
          </div>
        </FormGroup>
      </ModalForm>
    );
  });
}
