/**
 * Templates for checkout pages that involve pricing and those that don't.
 */

import { TopBar } from '../top-bar';
import { ComponentChildren } from 'preact';
import { useIntl } from 'shared/intl/use-intl';
import { CouponRow, PriceRow, BrandRow } from 'server/types';
import { Totals } from './totals';
import { WithAvailability } from 'server/payments/availability';
import { useDocumentTitle } from 'client/utils/use-document-title';

export type CheckoutPageProps = {
  brand?: Pick<BrandRow, 'name' | 'showName' | 'iconUrl' | 'logoColor'>;
  title?: string;
  docTitle?: string[];
  subtitle?: ComponentChildren;
  children: ComponentChildren;
  showHeader?: boolean;
  bannerContent?: ComponentChildren;
  footerContent?: ComponentChildren;
  price?: WithAvailability<PriceRow>;
  coupon?: WithAvailability<CouponRow>;
  setCoupon?(coupon?: WithAvailability<CouponRow>): void;
};

export function CheckoutHeader({
  title,
  subtitle,
}: {
  title: ComponentChildren;
  subtitle?: ComponentChildren;
}) {
  return (
    <header class="mb-4">
      <h2 class="text-lg font-bold">{title}</h2>
      {subtitle && <p class="opacity-50">{subtitle}</p>}
    </header>
  );
}

export function CheckoutPage({
  title,
  docTitle,
  subtitle,
  children,
  bannerContent,
  footerContent,
  showHeader = true,
  brand,
  price,
  coupon,
  setCoupon,
}: CheckoutPageProps) {
  const intl = useIntl();

  title ||= intl('Sign Up');

  useDocumentTitle(docTitle || [title]);

  return (
    <div class="min-h-screen">
      <TopBar brand={brand} />
      <div class="grow w-full mt-2 py-8">
        <section class="flex flex-col sm:flex-row mx-auto gap-8 max-w-5xl">
          <div class="flex grow flex-1 flex-col gap-8 p-6">
            <div class="flex flex-col gap-6">
              <>
                {bannerContent}
                {price && setCoupon && (
                  <Totals price={price} coupon={coupon} onCouponChange={setCoupon} />
                )}
                {footerContent}
              </>
            </div>
          </div>
          <div class="hidden sm:block w-px border-l"></div>
          <div class="border-t sticky bottom-0 bg-white left-0 flex-1 right-0 sm:border-none grow flex flex-col gap-4 p-6">
            {showHeader && <CheckoutHeader title={title} subtitle={subtitle} />}
            {children}
          </div>
        </section>
      </div>
    </div>
  );
}
