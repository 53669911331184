import { useIntl } from 'shared/intl/use-intl';
import { rpx } from 'client/lib/rpx-client';
import { IcoCheckCircle } from '@components/icons';
import { BtnCopy, BtnPrimary } from '@components/buttons';
import { toHumanTime } from 'shared/dateutil';
import { CourseCheckoutPage } from '@course/components/course-checkout';
import { LoadedProps, RouteLoadProps } from '@components/router';
import { defStudentRoute } from '@course/components/courses-app-router';

async function load({ params }: RouteLoadProps) {
  return rpx.gifts.getGift({ id: params.giftId });
}

type Props = LoadedProps<typeof load>;

export const route = defStudentRoute({
  load,
  Page,
  authLevel: 'student',
});

function Page(props: Props) {
  const intl = useIntl();
  const { gift, course, giftUrl } = props.state;
  const emailSent = props.params.emailSent === 'true';
  const emailDate = props.params.emailDate;

  return (
    <CourseCheckoutPage course={course} title={intl('Thank you!')}>
      <div class="flex flex-col">
        <section class="flex gap-4">
          <IcoCheckCircle class="w-12 h-12 text-green-500" />
          <div>
            <p class="text-gray-600 text-sm">
              {!emailSent
                ? intl('Your gift has been created.')
                : emailDate
                ? intl(
                    'Your gift has been scheduled to be sent to {email:string} at {date:string}.',
                    {
                      email: gift.email,
                      date: toHumanTime(emailDate),
                    },
                  )
                : intl('Your gift has been sent to {email}', { email: gift.email })}
            </p>
            <p class="text-gray-600 text-sm">
              {emailSent
                ? intl('You may also copy the gift link below and send it yourself.')
                : intl('You may copy the gift link below and send it yourself.')}
            </p>
          </div>
        </section>
      </div>
      <label class="flex border rounded-md">
        <input
          value={giftUrl}
          readonly
          onFocus={(e: any) => e.target.select()}
          class="border-none bg-transparent rounded-l w-full focus:ring-2 focus:z-10"
        />
        <BtnCopy value={giftUrl} class="bg-gray-50 rounded-r border focus:ring-2" margin="m-0">
          {intl('Copy')}
        </BtnCopy>
      </label>
      <BtnPrimary href="/account/gifts">{intl('Go to my gifts page')}</BtnPrimary>
    </CourseCheckoutPage>
  );
}
