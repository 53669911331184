import { BtnPrimary } from '@components/buttons';
import { ManualDom } from '@components/manual-dom';
import { useState } from 'preact/hooks';
import { StickyToolbar } from '@course/components/sticky-toolbar';
import { useBasicAutosaver } from '@components/autosaver';
import { mediaCard, mediaMiddleware, mediaToolbarAction } from '@components/media-card';
import {
  cardMiddleware,
  defaultToolbarActions,
  minidocToolbar,
  placeholder,
  scrubbable,
} from 'minidoc-editor';
import { keyboardNav } from 'client/lib/keyboard-nav';
import { SaveStatus } from '@components/save-status';
import { useConfiguration } from '@components/router/session-context';
import { showError } from '@components/app-error';
import { useMinidoc } from '@components/minidoc';
import { EditorWrapper } from '@components/minidoc/minidoc-root';
import { IcoMagnifyingGlass } from '@components/icons';
import { dataTemplateToolbarAction } from '@components/messages';
import { MessageTemplateField } from 'server/types';

type Props = {
  content?: string;
  title?: string;
  isSending: boolean;
  titleClass?: string;
  templateFields?: MessageTemplateField[];
  onSave(content: string, title: string): Promise<unknown>;
  onSend: () => void;
};

interface SaveState {
  title: string;
  content: string;
}

export function MessageEditor({
  content = '',
  title = 'Subject',
  isSending,
  titleClass = '',
  onSave,
  onSend,
}: Props) {
  const configuration = useConfiguration();
  const [state, setState] = useState<SaveState>(() => ({
    title,
    content,
  }));
  const templateFields: MessageTemplateField[] = [
    {
      key: 'event-type',
      title: 'Event Type',
    },
    {
      key: 'event-date',
      title: 'Event Date',
    },
    {
      key: 'host-name',
      title: 'Host Name',
    },
    {
      key: 'location',
      title: 'Location',
    },
    {
      key: 'recipient-name',
      title: 'Recipient Name',
    },
    {
      key: 'remaining-time',
      title: 'Remaining Time',
    },
    {
      key: 'duration',
      title: 'Duration',
    },
  ];

  const editor = useMinidoc({
    doc: state.content,
    middleware: () => [
      /**
       * Adds placeholder text support to minidoc.
       */
      placeholder('Edit this message.'),
      /**
       * This adds toolbar support to minidoc. We don't want h1 support, so
       * we remove that from the defuault toolbar actions. And we *do* want
       * media support (the ability to place files within the document), so
       * we'll add our own media toolbar action.
       */
      minidocToolbar([
        ...defaultToolbarActions.filter((x) => {
          if (x.id === 'link') {
            return false;
          }
          return x.id !== 'h1';
        }),
        // Modify quikpik to ignore non-image files
        mediaToolbarAction({
          label: 'Add image',
          accept: 'image/*',
          sources: ['filepicker', 'takephoto'],
        }),
        dataTemplateToolbarAction(templateFields),
      ]),
      /**
       * Here is where we register the cards we want to use. Media is for files.
       * In the future, we'll register surveys, quizes, etc here.
       */
      cardMiddleware([mediaCard]),
      /**
       * Prevent template fields from being scrubbed / erased by the
       * minidoc scrubber logic.
       */
      scrubbable.middleware(
        scrubbable.createScrubber({
          ...scrubbable.rules,
          leaf: {
            ...scrubbable.rules.leaf,
            FIGURE: {
              'data-state': true,
            },
          },
          child: {
            ...scrubbable.rules.child,
            'TEMPLATE-FIELD': {
              contenteditable: true,
            },
          },
        }),
      ),

      /**
       * This extends the editor to have an `insertMedia` method which is used
       * by the media toolbar action.
       */
      mediaMiddleware({
        isPublic: true,
        domain: configuration.tenant.domain,
        accept: 'image',
      }),
    ],
    onChange(doc) {
      setState((s) => ({ ...s, content: doc }));
    },
  });

  const autosaver = useBasicAutosaver(state, (val) => {
    const promise = onSave(val.content, val.title);
    promise.catch(showError);
    return promise;
  });

  return (
    <>
      <StickyToolbar>
        <div class="grow md:flex md:items-center">
          <ManualDom el={editor.toolbar.root} />

          <nav class="ml-auto md:border-l flex items-center py-1 gap-2">
            <SaveStatus isDirty={autosaver.isDirty} />

            <BtnPrimary
              class="rounded-full py-1"
              aria-label="Preview"
              isLoading={isSending}
              disabled={autosaver.isDirty}
              onClick={onSend}
            >
              <IcoMagnifyingGlass class="w-5 h-5 mr-1" />
              Preview
            </BtnPrimary>
          </nav>
        </div>
      </StickyToolbar>
      <div class="px-4" onMouseLeave={() => autosaver.save()}>
        <input
          class={`js-edit-lesson-title text-base font-semibold border-b border-0 w-full my-6 px-0 focus:ring-0 ${titleClass}`}
          placeholder="Subject"
          type="text"
          value={state.title}
          onInput={(e: any) => setState((s) => ({ ...s, title: e.target.value }))}
          onKeyDown={(e: any) =>
            keyboardNav(e, {
              down() {
                (editor.root as any).focus();
              },
            })
          }
        />
        <EditorWrapper editor={editor} class="messages-content" />
      </div>
    </>
  );
}
