/**
 * This file contains a generic ajax implementation built on the fetch API.
 */

interface AjaxOpts {
  headers?: { [k: string]: string };
  method?: string;
  data?: any;
  body?: string;
  plain?: boolean;
}

/*
 * The db library `postgres` returns Date objects for `timestamp` columns.
 * And they are converted to string while creating JSON on the backend.
 * That causes type inconsistency between the backend and the client.
 * So `jsonReviver` method is converting values of the keys below from string to Date.
 */
const dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
const isTimestampKey = (key: string) => key.endsWith('timestamp') || key.endsWith('At');

function jsonReviver(key: string, value: any) {
  if (typeof value === 'string' && dateFormat.test(value)) {
    if (isTimestampKey(key)) {
      return new Date(value);
    }
  }

  return value;
}

export function isNetworkError(err: Error) {
  return err.name === 'TypeError';
}

/**
 * Perform a JSON API request.
 */
export function ajax(url: string, opts: AjaxOpts = {}) {
  const promise = fetch(url, {
    method: opts.method || 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...(opts.headers || {}),
      csrf: (document.querySelector('meta[name=csrf]') as HTMLMetaElement)?.content,
    },
    body: opts.data ? JSON.stringify(opts.data) : opts.body,
  }).then(async (response) => {
    const result = await response.text();
    if (opts.plain && response.ok) {
      if (!response.ok) {
        throw new Error(`Invalid response`);
      }
      return result;
    }
    const obj = JSON.parse(result, jsonReviver);

    if (!response.ok || (obj && obj.status >= 300 && obj.message)) {
      throw obj;
    }
    return obj;
  });

  return promise;
}
