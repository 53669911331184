import { useContext } from 'preact/hooks';
import { ModalForm, ModalFormContext, showModalForm } from '@components/modal-form';
import { CouponRow } from 'server/types';
import { rpx } from 'client/lib/rpx-client';
import { ExpandableLimitsFields, FreeTrialPeriod } from './new-coupon-form';
import { formDataToPriceLimits } from './signup-limits-tab';

const store = rpx.coupons;

export function showUpdateCouponModal({
  coupon,
  stripeMetadata,
  onSave,
}: {
  coupon: CouponRow;
  stripeMetadata?: Record<string, string>;
  onSave(price: CouponRow): void;
}) {
  return showModalForm(() => {
    const { resolve } = useContext(ModalFormContext);
    const hide = () => resolve(coupon);

    return (
      <ModalForm
        onClose={hide}
        onSubmit={async (formData) => {
          const freeTrialPeriod = parseInt(formData.freeTrialPeriod) || 0;
          const updatedCoupon = await store.updateCoupon({
            ...formData,
            ...formDataToPriceLimits(formData),
            freeTrialPeriod,
            couponId: coupon.id,
            productId: coupon.productId,
            stripeMetadata: stripeMetadata,
          });
          onSave(updatedCoupon);
          resolve(updatedCoupon);
        }}
        confirmButtonText="Save coupon"
        title="Edit coupon"
      >
        <section class="flex flex-col gap-6">
          <FreeTrialPeriod value={coupon.freeTrialPeriod} />
          <ExpandableLimitsFields coupon={coupon} />
        </section>
      </ModalForm>
    );
  });
}
