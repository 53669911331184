import { BtnSecondary } from '@components/buttons';
import { useEffect } from 'preact/hooks';

interface Props {
  displayed: boolean;
  text: string;
  onClick: () => void;
  hide: () => void;
}

/**
 * A message that appears when an undo is available.
 */
export function UndoNotice({ displayed, hide, text, onClick }: Props) {
  /*
   * Hides itself after displayed for 2.5 seconds.
   */
  useEffect(() => {
    if (!displayed) {
      return;
    }
    const timeout = setTimeout(hide, 2500);
    return () => clearTimeout(timeout);
  }, [displayed]);

  if (!displayed) {
    return null;
  }

  return (
    <div class="an-slide-up fixed inset-x-0 flex justify-center bottom-28 z-50">
      <div class="bg-gray-800 text-gray-50 rounded-full p-2 px-4 shadow-xl inline-flex items-center gap-2">
        <span>{text} Press Ctrl + Z to undo, or </span>
        <BtnSecondary class="text-inherit" onClick={onClick}>
          Undo
        </BtnSecondary>
      </div>
    </div>
  );
}
