/**
 * A function which autofocuses the first input or the first button
 * in the element. It only does this once. Generally used like so:
 * ref={autoFocus}
 */
export function autoFocus(el?: any, selector?: string) {
  if (!el || el.$autoFocused) {
    return;
  }
  el.$autoFocused = true;
  const input =
    topDialogInput() ||
    el.querySelector(selector || 'input') ||
    el.querySelector(selector || 'button') ||
    (el.focus && el);

  autoFocusSelf(input);
}

/**
 * Get the input / button from the top-most dialog / modal.
 */
export function topDialogInput() {
  const dialogs = document.querySelectorAll('.js-dialog');
  return dialogs[dialogs.length - 1]?.querySelector('input, textarea, button');
}

function focus(el: Pick<HTMLInputElement, 'focus' | 'select' | 'setSelectionRange'>) {
  if (el.select) {
    el.select();
  } else if (el.focus) {
    el.focus();
  } else {
    el.setSelectionRange(0, 0);
  }
}

export function autoFocusSelf(el?: any, focusFn = focus) {
  if (!el || el.$autoFocused || (!el.select && !el.focus)) {
    return;
  }
  el.$autoFocused = true;
  setTimeout(() => {
    try {
      focusFn(el);
    } catch (err) {
      console.warn(err);
    }
  });
}

export function focusRef(el?: any) {
  if (!el || el.$autoFocused || (!el.select && !el.focus)) {
    return;
  }
  el.$autoFocused = true;
  setTimeout(() => {
    try {
      if (!el) {
        return;
      }
      if (el.focus) {
        el.focus();
      } else {
        el.setSelectionRange(0, 0);
      }
    } catch (err) {
      console.warn(err);
    }
  });
}
