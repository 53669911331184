/**
 * The about the guide / about me section.
 */
import { EditorProps, ViewerProps } from './types';
import { ImgProp, RichProp } from './editable';
import { h as htm } from 'minidoc-editor';
import { RUZUKU_ASSETS_BASE_URL } from 'shared/consts';
import { CurrentUser } from 'client/lib/auth';

interface State {
  nameContent?: string;
  // `name` and `label` are deprecated and kept for legacy data.
  // We should use `nameContent` instead that contains both the name and the label
  // in the same string as HTML.
  name?: string;
  label?: string;
  src: string;
  content: string;
}

export const name = 'About Me';

export const type = 'aboutme';

const PROFILE_PIC_PLACEHOLDER = `${RUZUKU_ASSETS_BASE_URL}/cat.jpeg?width=256`;

export const initialState: State = {
  src: PROFILE_PIC_PLACEHOLDER,
  content: htm(
    '.tmp',
    htm(
      'p',
      `I've been teaching for over 20 years. It's been a privilege to watch hundreds of students improve their lives and careers, achieve their dreams, and help one another in a community of mutual respect and support.`,
    ),
  ).innerHTML,
};

export function MiniView() {
  return (
    <span class="flex justify-center py-4">
      <span class="flex justify-end w-1/2 relative bg-gray-200 rounded-xl h-10">
        <span class="bg-gray-500 w-8 h-8 rounded-full mr-8 -mt-4"></span>
      </span>
    </span>
  );
}

function View(props: EditorProps<State> | ViewerProps<State>) {
  return (
    <div
      class={`${
        props.isBgLight ? 'bg-gray-50' : 'bg-black/20'
      } rounded-2xl md:rounded-4xl p-8 md:p-20 max-w-4xl w-full mx-auto relative about-me-block`}
    >
      <ImgProp
        wrapperClass="h-40 w-40 md:h-64 md:w-64 rounded-full mx-auto md:float-right mb-8 md:mb-0 md:-mt-40"
        class="w-full h-full rounded-full"
        placeholder={PROFILE_PIC_PLACEHOLDER}
        {...props}
        path="src"
      />
      <div class={`mb-8 ${props.isBgLight ? 'text-blue-600' : 'text-sky-200'}`}>
        <RichProp
          {...props}
          state={{
            ...props.state,
            // This is to support the old data format where the we used to store `label` and `name` separately.
            nameContent:
              props.state.nameContent ||
              `<p>${props.state.label || 'About the instructor'}</p><h2><strong>${
                props.state.name || ''
              }</strong></h2>`,
          }}
          path="nameContent"
        />
      </div>
      <div class="text-lg leading-8">
        <RichProp {...props} path="content" />
      </div>
    </div>
  );
}

export const Editor = View;

export const Viewer = View;

export function generateState(
  opts: { currentUser: NonNullable<CurrentUser> } & Partial<State>,
): State {
  const { currentUser, ...state } = opts;
  return {
    nameContent: `<p>About the instructor</p><h2><strong>${currentUser.name}</strong></h2>`,
    // This is a dynamic URL that always fetches the latest profile pic.
    // So the profile pic is always up to date unless it is explicitly changed in the sales page editor.
    src: `/profilepic/${currentUser.id}`,

    content:
      currentUser.bio ||
      htm(
        '.tmp',
        htm(
          'p',
          `I've been teaching for over 20 years. It's been a privilege to watch hundreds of students improve their lives and careers, achieve their dreams, and help one another in a community of mutual respect and support.`,
        ),
      ).innerHTML,
    ...state,
  };
}
