export function HyrosTracker() {
  return (
    <script type="text/javascript">{`
      var head = document.head;
      var script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = "https://t.ruzuku.com/v1/lst/universal-script?ph=8a6d21da9c7f530d934ed7d30582de18ebc4df35e28752126d4a5bc62f996b2f" ;
      head.appendChild(script);
    `}</script>
  );
}
