import { Internationalize } from './intl';
import { indexBy } from './utils';

interface AssessmentQuestion {
  id: UUID;
  isRequired: boolean;
}

interface AssessmentAnswer {
  questionId: UUID;
}

export function validateAssessmentAnswers({
  questions,
  answers,
  intl,
}: {
  questions: Pick<AssessmentQuestion, 'id' | 'isRequired'>[];
  answers: AssessmentAnswer[];
  intl: Internationalize;
}) {
  const answerMap = indexBy((a) => a.questionId, answers);
  const missingAnswers = questions.filter((q) => q.isRequired && !answerMap[q.id]);

  return missingAnswers.map((q) => ({
    field: q.id,
    message: intl('This question is required'),
  }));
}

export function calculateQuizScore(userPoints: number, totalPoints: number) {
  return Math.round((userPoints / totalPoints) * 100);
}

/*
 * Quiz total point is the sum of all questions' points.
 * If none of the questions have any points, then the default
 * point should be 1 point to be able to calculate a score.
 */
export function getDefaultQuestionPoints(questions: { points?: number }[]) {
  return questions.every((q) => !q.points) ? 1 : 0;
}
