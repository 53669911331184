import { showError } from '@components/app-error';
import { AsyncForm, FormGroup, InputLabel } from '@components/async-form';
import { Button } from '@components/buttons';
import { DateInput } from '@components/date-picker';
import { MeetingDuration } from '@course/components/duration-select';
import { IcoTrash } from '@components/icons';
import { useRouteParams } from '@components/router';
import { useCurrentTenant } from '@components/router/session-context';
import { Spinner } from '@components/spinner';
import { showToast } from '@components/toaster';
import { Toggle } from '@components/toggle';
import { rpx } from 'client/lib/rpx-client';
import { useState } from 'preact/hooks';
import { FullCourse, Meeting } from 'server/types';
import { fmtFullTime } from 'shared/dateutil';
import { MeetingTypeData, MeetingTypes } from './type-tab';
import { meetingUrl } from './urls';
import { DialogFooter, showDialog } from '@components/dialog';

interface Props {
  meeting: Meeting;
  course: Pick<FullCourse, 'id' | 'level'>;
  hasEnrolledStudents: boolean;
  onUpdate: (title: string, scheduledAt: Date) => void;
  onDelete: () => void;
  hide: () => void;
}

interface FormData {
  title: string;
  durationMinutes: number;
  scheduledAt: Date;
  sendNotification: boolean;
}

export function UpdateMeetingForm({
  meeting,
  course,
  hasEnrolledStudents,
  onUpdate,
  onDelete,
  hide,
}: Props) {
  const { courseId } = useRouteParams();
  const { terminology } = useCurrentTenant();
  const scheduledAt = meeting.scheduledAt || new Date();

  const [isDeleting, setIsDeleting] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState<FormData>({
    title: meeting.title,
    durationMinutes: meeting.durationMinutes,
    scheduledAt,
    sendNotification: true,
  });
  const [typeData, setTypeData] = useState<MeetingTypeData>({
    type: meeting.type,
    slidesFile: meeting.slidesFile,
    recordMeeting: meeting.recordMeeting,
    allowRecordingDownloads: meeting.allowRecordingDownloads,
    zoomHasWaitingRoom: meeting.zoomHasWaitingRoom,
  });

  const isRescheduling = formData.scheduledAt?.getTime() !== scheduledAt.getTime();

  async function updateMeeting() {
    setIsSubmitting(true);
    try {
      await rpx.meetings.updateMeeting({
        id: meeting.id,
        courseId,
        title: formData.title,
        durationMinutes: formData.durationMinutes,
        type: typeData.type,
        slidesFile: typeData.slidesFile,
      });

      if (isRescheduling) {
        await rpx.meetings.rescheduleMeeting({
          courseId,
          meetingId: meeting.id,
          scheduledAt: formData.scheduledAt.toISOString(),
          sendNotification: hasEnrolledStudents && formData.sendNotification,
        });
      }

      onUpdate(formData.title, formData.scheduledAt);
    } finally {
      setIsSubmitting(false);
    }
  }

  async function deleteMeeting() {
    try {
      const confirmed = await showDialog({
        mode: 'warn',
        title: `Permanently delete ${terminology.meeting}?`,
        children: `Do you want to delete "${meeting.title}"? Your changes will be lost and cannot be recovered.`,
        confirmButtonText: `Delete ${terminology.meeting}`,
      });
      if (!confirmed) {
        return;
      }
      setIsDeleting(true);
      await rpx.meetings.deleteMeeting({ courseId, id: meeting.id });
      showToast({
        type: 'ok',
        title: `Deleted ${terminology.meeting}`,
        message: meeting.title,
      });
      onDelete();
    } catch (err) {
      showError(err);
    } finally {
      setIsDeleting(false);
    }
  }

  return (
    <>
      <header class="flex justify-between mb-4">
        <h3 class="font-semibold">Edit {terminology.meeting}</h3>
        <nav class="flex text-gray-500">
          <Button disabled={isDeleting} onClick={deleteMeeting}>
            {!isDeleting && <IcoTrash />}
            {isDeleting && <Spinner class="inline-block" />}
          </Button>
          <span class="border-r mx-4"></span>
          <a
            class="text-sm mt-0.5 capitalize"
            href={meetingUrl({
              courseId,
              meetingId: meeting.id,
            })}
          >
            {terminology.meeting} details
          </a>
        </nav>
      </header>
      <AsyncForm class="flex flex-col gap-6" onSubmit={updateMeeting}>
        <FormGroup prop="title">
          <InputLabel>Title</InputLabel>
          <input
            type="text"
            placeholder={`${terminology.Meeting} title`}
            name="title"
            class="ruz-input"
            value={formData.title}
            onChange={(e: any) => setFormData((s) => ({ ...s, title: e.target.value }))}
          />
        </FormGroup>
        <FormGroup prop="scheduledAt">
          <InputLabel>Scheduled Start</InputLabel>
          <DateInput
            class="w-full"
            name="scheduledAt"
            value={formData.scheduledAt}
            includeTime
            placeholder="Scheduled start date"
            onChange={(newDate) => {
              if (newDate) {
                setFormData((s) => ({ ...s, scheduledAt: newDate }));
              }
            }}
          />
          {hasEnrolledStudents && isRescheduling && (
            <div class="flex items-center my-4">
              <Toggle
                class="mr-2"
                checked={formData.sendNotification}
                onClick={() =>
                  setFormData((s) => ({ ...s, sendNotification: !s.sendNotification }))
                }
              />
              <label class="block mb-2">
                You are rescheduling the {terminology.meeting} to{' '}
                <strong>{fmtFullTime(formData.scheduledAt)}.</strong> Notify enrolled students that
                you're rescheduling.
              </label>
            </div>
          )}
        </FormGroup>
        <FormGroup prop="durationMinutes">
          <InputLabel>Duration</InputLabel>
          <div class="mt-2">
            <MeetingDuration
              name="durationMinutes"
              value={formData.durationMinutes}
              onNewDuration={(newDuration) =>
                setFormData((s) => ({ ...s, durationMinutes: newDuration }))
              }
            />
          </div>
        </FormGroup>
        <FormGroup prop="type">
          <InputLabel>Choose the {terminology.meeting} type</InputLabel>
          <MeetingTypes
            meeting={typeData}
            course={course}
            hideDetails
            canEditDetails={false}
            onChange={(updates) => setTypeData({ ...typeData, ...updates })}
            onSlideDelete={() => setTypeData({ ...typeData, slidesFile: undefined })}
          />
        </FormGroup>
        <DialogFooter isLoading={isSubmitting} confirmButtonText="Save" onClose={hide} />
      </AsyncForm>
    </>
  );
}
