// These will be removed in a subsequent PR
export function arrToParagraphs(arr: string[], extraLine = true) {
  return arr.map((str) => `<p>${str}</p>${extraLine ? '<p><br></p>' : ''}`).join('');
}

export function templateField(name: string, templateOnly = false) {
  const template = `{{{${name}}}}`;

  if (templateOnly) {
    return template;
  }

  return `<template-field contenteditable="false">${template}</template-field>`;
}
