import { ComponentChildren } from 'preact';

/**
 * A little titled pane for displaying checkout information such as
 * totals, subcourses, etc
 */
export function CheckoutSection({
  title,
  children,
}: {
  title?: string;
  children: ComponentChildren;
}) {
  return (
    <div class="flex flex-col gap-4 bg-gray-50 rounded-md p-4">
      {title && <h2 class="text-base font-medium">{title}</h2>}
      {children}
    </div>
  );
}
