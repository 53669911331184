import { rpx } from 'client/lib/rpx-client';
import { useState } from 'preact/hooks';
import { showError } from '@components/app-error';
import { useAsyncEffect } from 'client/utils/use-async-effect';
import { Spinner } from '@components/spinner';
import { TenantSetupWizard } from './tenant-setup-wizard';
import { ExistingTenant, ManageExistingTenant } from './manage-existing-tenant';

export function SiteTab() {
  const [myTenant, setMyTenant] = useState<undefined | ExistingTenant>(undefined);
  const [isLoading, setIsLoading] = useState(true);

  useAsyncEffect(async () => {
    try {
      const result = await rpx.tenants.getMyTenant();
      setMyTenant(result);
      setIsLoading(false);
    } catch (err) {
      showError(err);
    }
  }, []);

  if (isLoading) {
    return (
      <div class="p-8 text-center">
        <Spinner class="border-indigo-400 w-16 h-16 inline-block" />
      </div>
    );
  }

  if (myTenant) {
    return <ManageExistingTenant myTenant={myTenant} setMyTenant={setMyTenant} />;
  }

  return <TenantSetupWizard />;
}
