import { couponStore, priceStore, Item } from './state';
import { showModalForm } from '@components/modal-form';
import { useState } from 'preact/hooks';
import { useAsyncEffect } from 'client/utils/use-async-effect';
import { pluralize } from 'shared/formatting';
import { ProgressBar } from '@components/progress-bar';
import { StandardDialog } from '@components/dialog';

export function showArchiveItemsModal({
  items,
  action,
}: {
  items: Item[];
  action: 'archive' | 'activate';
}) {
  return showModalForm(({ resolve }) => {
    const hide = () => resolve(undefined);
    const [numComplete, setNumComplete] = useState(0);

    useAsyncEffect(async () => {
      const start = Date.now();
      for (const item of items) {
        if (item.type === 'coupon') {
          await couponStore.updateCoupon({
            couponId: item.id,
            productId: item.productId,
            isEnabled: action === 'activate',
          });
        } else if (item.type === 'price') {
          await priceStore.updatePrice({
            priceId: item.id,
            productId: item.productId,
            isEnabled: action === 'activate',
          });
        }
        setNumComplete((x) => x + 1);
      }

      // This is a UX hack. It feels weird if the archive happens too fast,
      // so we artificially ensure it takes at least a second!
      if (Date.now() - start < 1000) {
        setTimeout(hide, 1000 - (Date.now() - start));
      }
    }, []);

    const progress = Math.round((numComplete / items.length) * 100);

    return (
      <StandardDialog
        onClose={() => {}}
        title={
          <>
            {action === 'activate' ? 'Activating ' : 'Archiving '}
            {items.length}
            {pluralize(' item', items.length)}
          </>
        }
      >
        <ProgressBar
          progress={progress}
          barClass={progress === 100 ? 'bg-green-400' : 'bg-indigo-400'}
          showPercent
        />
      </StandardDialog>
    );
  });
}
