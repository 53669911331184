import { useDocumentTitle } from 'client/utils/use-document-title';
import { LoadedProps, RouteLoadProps, useRouter } from '@components/router';
import { rpx } from 'client/lib/rpx-client';
import { AsyncForm } from '@components/async-form';
import { useEffect, useState } from 'preact/hooks';
import { BtnPrimary, Button } from '@components/buttons';
import { IcoArrowRight } from '@components/icons';
import { showError } from '@components/app-error';
import { Field, InputField, eventToState } from './form-helpers';
import { Auth, useAuth } from 'client/lib/auth';
import { detectTimezone } from 'shared/dateutil';
import { Password } from '@components/password';
import { ComponentChildren } from 'preact';
import { DefaultSpinner } from '@components/spinner';
import { RootUserMenu } from '@components/root-page-layout';
import { MenuItem } from '@components/dropdown';
import { TestimonialWall } from '@components/testimonial-wall';
import { defCalRoute } from './common';

export const route = defCalRoute({ isPublic: true, load, Page });

type Props = LoadedProps<typeof load>;

type Pane = 'email' | 'auth' | 'confirm';

async function load(props: RouteLoadProps) {
  const host = await rpx.ruzcal.getHost({});
  const pane: Pane = props.auth.user ? 'confirm' : 'email';
  return {
    host,
    email: props.auth.user?.email || '',
    name: props.auth.user?.name || '',
    profilePhotoUrl: props.auth.user?.profilePhotoUrl,
    exists: !!props.auth.user,
    pane: pane as unknown as Pane,
  };
}

function FormHeader(props: { title: ComponentChildren; subtitle?: ComponentChildren }) {
  return (
    <header class="flex flex-col gap-1">
      <h1 class="text-3xl font-medium leading-normal text-transparent bg-clip-text bg-gradient-to-r from-indigo-500 to-sky-500">
        {props.title}
      </h1>
      {props.subtitle && <p>{props.subtitle}</p>}
    </header>
  );
}

function EmailForm(props: {
  email: string;
  onSubmit(opts: { email: string; exists: boolean }): void;
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [email, setEmail] = useState(props.email);
  return (
    <div class="flex flex-col w-full max-w-96 gap-6">
      <FormHeader
        title="Welcome to Ruzuku Calendar!"
        subtitle={<>Make appointment scheduling easy for your customers.</>}
      />
      <AsyncForm
        class="flex flex-col gap-6"
        onSubmit={async () => {
          setIsSubmitting(true);
          try {
            const result = await rpx.auth.isEmailTaken({ email });
            props.onSubmit({ email, exists: result.exists });
          } catch (err) {
            showError(err);
          } finally {
            setIsSubmitting(false);
          }
        }}
      >
        <InputField
          name="email"
          fullWidth
          autoFocus
          title="Enter your email address to get started."
          value={email}
          onInput={(e: any) => setEmail(e.target.value)}
        />
        <BtnPrimary
          class="gap-2 items-center p-4 rounded-full text-base min-w-40"
          isLoading={isSubmitting}
        >
          Continue
          <IcoArrowRight class="size-4 shrink-0" />
        </BtnPrimary>
      </AsyncForm>
    </div>
  );
}

async function provisionUser(user: NonNullable<Auth['user']>) {
  const host = await rpx.ruzcal.ensureHost();
  user.level = host.level;
}

function AuthForm(props: {
  email: string;
  exists: boolean;
  onBack(): void;
  onSubmit(user: Auth['user']): void;
}) {
  const auth = useAuth();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [state, setState] = useState({
    name: '',
    password: '',
    sentResetEmail: false,
  });

  if (state.sentResetEmail) {
    return (
      <div class="flex flex-col w-full max-w-96">
        <FormHeader
          title="We sent you a magic link!"
          subtitle={
            <>Check the inbox for {props.email}, and click the link there to continue signing up.</>
          }
        />
      </div>
    );
  }

  return (
    <div class="flex flex-col w-full max-w-96 gap-6">
      <FormHeader
        title={props.exists ? 'Welcome back!' : `Let's get you set up...`}
        subtitle={
          props.exists
            ? `Enter your password to continue.`
            : `Just a few more things, and you'll be all set.`
        }
      />
      <AsyncForm
        class="flex flex-col gap-6 w-full max-w-80"
        onSubmit={async () => {
          setIsSubmitting(true);
          try {
            const user = props.exists
              ? await rpx.auth.login({ email: props.email, password: state.password })
              : await rpx.auth.registerNewUser({
                  email: props.email,
                  name: state.name,
                  password: state.password,
                  timezone: detectTimezone(),
                });
            await provisionUser(user);
            props.onSubmit(user);
          } catch (err) {
            showError(err);
          } finally {
            setIsSubmitting(false);
          }
        }}
      >
        {!props.exists && (
          <InputField
            name="name"
            title="Name"
            fullWidth
            autoFocus
            value={state.name}
            onInput={eventToState(setState)}
          />
        )}
        {!auth.user && (
          <InputField
            name="email"
            fullWidth
            disabled
            title="Email"
            value={props.email}
            suffix={
              <Button
                class="outline-none focus:ring-2 ring-indigo-500 rounded-sm"
                tabindex={10}
                onClick={props.onBack}
              >
                Change
              </Button>
            }
          />
        )}

        <Field name="password" title={props.exists ? 'Password' : 'Create a password'}>
          <Password
            name="password"
            class="p-4"
            placeholder=" "
            autoFocus={props.exists}
            value={state.password}
            onInput={eventToState(setState)}
          />
        </Field>
        <BtnPrimary
          class="gap-2 items-center p-4 rounded-full text-base min-w-40"
          isLoading={isSubmitting}
        >
          Start using Ruzuku Calendar
          <IcoArrowRight class="size-4 shrink-0" />
        </BtnPrimary>

        {props.exists && (
          <Button
            class="text-indigo-600 text-center hover:underline rounded-full outline-none focus:ring-2 ring-indigo-500"
            onClick={async () => {
              try {
                await rpx.auth.forgotPassword({
                  email: props.email,
                  returnURL: location.href,
                });
                setState((s) => ({ ...s, sentResetEmail: true }));
              } catch (err) {
                showError(err);
              }
            }}
          >
            Click here to sign in via email.
          </Button>
        )}
      </AsyncForm>
    </div>
  );
}

function FinishSignUpForm(props: { user: NonNullable<Auth['user']>; onSubmit(): void }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  return (
    <div class="flex flex-col w-full max-w-96 gap-6">
      <FormHeader
        title="Welcome to Ruzuku Calendar!"
        subtitle={<>Make appointment scheduling easy for your customers.</>}
      />
      <AsyncForm
        class="flex flex-col gap-6"
        onSubmit={async () => {
          setIsSubmitting(true);
          try {
            await provisionUser(props.user);
            props.onSubmit();
          } catch (err) {
            showError(err);
          } finally {
            setIsSubmitting(false);
          }
        }}
      >
        <BtnPrimary
          class="gap-2 items-center p-4 rounded-full text-base min-w-40"
          isLoading={isSubmitting}
        >
          Start using Ruzuku Calendar
          <IcoArrowRight class="size-4 shrink-0" />
        </BtnPrimary>
      </AsyncForm>
    </div>
  );
}

function Page({ state, auth, setState }: Props) {
  const router = useRouter();
  const { user } = auth;
  const { host } = state;

  useDocumentTitle(['Welcome to Ruzuku Calendar!']);

  useEffect(() => {
    if (host) {
      router.goto('/calendar');
    }
  }, []);

  if (host) {
    return (
      <div class="flex items-center justify-center min-h-screen">
        <DefaultSpinner />
      </div>
    );
  }

  return (
    <div class="grid lg:grid-cols-2 grow min-h-screen">
      <section class="flex flex-col grow items-center justify-center p-10 relative overflow-auto">
        {host && 'Redirecting to /calendar...'}
        {user && !host && (
          <FinishSignUpForm
            user={user}
            onSubmit={() => {
              router.goto('/calendar');
            }}
          />
        )}
        {!user && state.pane === 'email' && (
          <EmailForm
            email={state.email}
            onSubmit={(opts) =>
              setState((s) => ({ ...s, email: opts.email, exists: opts.exists, pane: 'auth' }))
            }
          />
        )}
        {!user && state.pane === 'auth' && (
          <AuthForm
            email={state.email}
            exists={state.exists}
            onBack={() => setState((s) => ({ ...s, pane: 'email' }))}
            onSubmit={(user) => {
              auth.user = user;
              auth.setUser(user);
              router.goto('/calendar');
            }}
          />
        )}

        <div class="hidden lg:block absolute right-0 inset-y-0 w-2 bg-gradient-to-b from-transparent to-transparent via-sky-400"></div>
      </section>
      <section class="bg-indigo-600 flex flex-col grow items-center justify-center p-10 text-white">
        <TestimonialWall />
      </section>

      {user && (
        <nav class="absolute top-8 right-8">
          <RootUserMenu
            renderMenu={() => (
              <>
                <MenuItem href="/logout">Logout</MenuItem>
              </>
            )}
          />
        </nav>
      )}
    </div>
  );
}
