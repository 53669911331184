import { useEffect, useState } from 'preact/hooks';

type Props = {
  isLoading?: boolean;
  delay?: number;
};

export function LoadingIndicator({ isLoading = true, delay = 1000 }: Props) {
  const [shouldRender, setShouldRender] = useState(false);

  /*
   * Delaying the render of the loading indicator by 1000ms
   * to avoid the annoying loading indicator flashing on the screen
   * for fast page loads.
   */
  useEffect(() => {
    if (!isLoading) {
      setShouldRender(false);
      return;
    }

    const timer = setTimeout(() => setShouldRender(true), delay);
    return () => clearTimeout(timer);
  }, [isLoading, delay]);

  if (!isLoading || !shouldRender) {
    return null;
  }
  return <div class="page-loading z-50"></div>;
}
