import { AutoForm, ClearFormState, useAutoFormState } from '@components/async-form';
import { Filter } from '@course/components/filter';
import { SearchBox } from '@components/search-box';
import { useMemo, useState } from 'preact/hooks';
import { rpx, RpxResponse } from 'client/lib/rpx-client';
import { serialAsync } from 'client/utils/serial-async';
import { showError } from '@components/app-error';
import { useDidUpdateEffect } from 'client/utils/use-did-update-effect';
import { useDebouncedEffect } from 'client/utils/debounce';
import { hasLevel } from 'shared/auth';
import { useCurrentUser } from '@components/router/session-context';
import { UserProfileIcon } from '@components/avatars';
import { truncateId } from 'shared/utils';
import { compactDate } from 'shared/dateutil';
import { Pill } from '@components/pill';
import { Toggle } from '@components/toggle';
import { BtnSecondary, Button } from '@components/buttons';
import { useAsyncData } from 'client/lib/hooks';
import { DefaultSpinner } from '@components/spinner';

const store = rpx.adminCourses;

type TData = RpxResponse<typeof store.getStudents>;

interface Props {
  courseId: UUID;
}

const defaultFilters = { q: '', sortBy: 'email' };

function find(opts: typeof defaultFilters & { courseId: string; cursor?: string }) {
  return store.getStudents({
    searchTerm: opts.q,
    courseId: opts.courseId,
    cursor: opts.cursor,
    sortBy: opts.sortBy as 'email' | 'membershipDate',
  });
}

export function StudentsTable({ courseId }: Props) {
  const { data } = useAsyncData(async () => {
    try {
      return await find({ ...defaultFilters, courseId });
    } catch (err) {
      showError(err);
    }
  }, [courseId]);
  if (!data) {
    return <DefaultSpinner />;
  }
  return <LoadedStudentsTable courseId={courseId} initialData={data} />;
}

function LoadedStudentsTable({ courseId, initialData }: Props & { initialData: TData }) {
  const currentUser = useCurrentUser()!;
  const [form, ctx] = useAutoFormState({
    defaultState: defaultFilters,
    initialState: defaultFilters,
  });
  const [state, setState] = useState<TData>(initialData);

  const mimicAndView = async (user: (typeof state)['users'][0]) => {
    const canMimic = hasLevel(currentUser, user.level);
    if (!canMimic) {
      return;
    }
    try {
      await rpx.admin.mimicUser({ userId: user.id });
      location.assign(`/courses/${courseId}`);
    } catch (err) {
      showError(err);
    }
  };

  const fetchNow = useMemo(() => {
    return serialAsync(async (opts: typeof form, cursor = '') => {
      try {
        ctx.setIsProcessing(true);
        const result = await find({ ...opts, courseId, cursor });
        if (cursor) {
          setState((s) => ({ ...s, ...result, users: [...s.users, ...result.users] }));
        } else {
          setState((s) => ({ ...s, ...result }));
        }
      } catch (err) {
        showError(err);
      } finally {
        ctx.setIsProcessing(false);
      }
    });
  }, []);

  useDidUpdateEffect(() => form.q === '' && fetchNow(form), [form.q]);
  useDidUpdateEffect(() => fetchNow(form), [form.sortBy]);
  useDebouncedEffect(() => fetchNow(form), [form]);

  return (
    <div>
      <AutoForm ctx={ctx} onSubmit={async () => {}} class="mb-8 space-y-8">
        <header class="flex ">
          <div class="inline-flex">
            <Filter
              label="Sort by"
              name="sortBy"
              value={form.sortBy}
              options={[
                { title: 'Email', value: 'email' },
                { title: 'Joined', value: 'membershipDate' },
              ]}
            />
          </div>
          <SearchBox
            name="q"
            containerClass="w-80 ml-4"
            placeholder="Search by id, name, or email"
            focusOnce
            value={form.q}
          />
        </header>

        <ClearFormState>Clear all filters and search terms.</ClearFormState>
      </AutoForm>

      {!!state.users.length && (
        <div class="table table-auto bg-white rounded-sm border w-full divide-y">
          <div class="table-row bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            <div class="table-cell pl-4 pr-2 py-2">User</div>
            <div class="table-cell pl-4 pr-2 py-2">User ID</div>
            <div class="table-cell pl-4 pr-2 py-2">Joined</div>
            <div class="table-cell pl-4 pr-2 py-2">Access</div>
            <div class="table-cell pl-4 pr-2 py-2"></div>
          </div>
          {state.users.map((user) => {
            const canMimic = hasLevel(currentUser, user.level);
            const href = `/admin/people/${user.id}`;
            return (
              <span
                class="table-row text-inherit hover:bg-indigo-50 text-sm relative"
                key={user.id}
              >
                <a href={href} class="table-cell p-4 text-inherit border-t">
                  <span class="flex items-center">
                    <UserProfileIcon user={user} size="w-10 h-10" />
                    <span class="flex flex-col ml-4">
                      <strong>{user.name}</strong>
                      <span data-private>{user.email}</span>
                    </span>
                  </span>
                </a>
                <a href={href} class="table-cell p-4 text-inherit border-t">
                  {truncateId(user.id)}
                </a>
                <a href={href} class="table-cell p-4 text-inherit border-t">
                  {compactDate(user.joinedOn)}
                </a>

                <span class="table-cell p-4 border-t">
                  {user.status === 'expired' && <Pill color="red">Expired</Pill>}
                  {user.status !== 'expired' && (
                    <Toggle
                      checked={user.status === 'enabled'}
                      onClick={async (e) => {
                        try {
                          await rpx.admin.updateUserMembershipAsAdmin({
                            courseId,
                            userId: user.id,
                            isEnabled: e.currentTarget.checked,
                          });
                        } catch (err) {
                          showError(err);
                        }
                      }}
                    />
                  )}
                </span>

                <span class="table-cell relative ml-auto p-4 group text-right border-t">
                  {canMimic && (
                    // Placeholder so our absolutely positioned button will work :/
                    <span class="inline-block px-2 text-sm py-1 whitespace-nowrap invisible">
                      Mimic &amp; view
                    </span>
                  )}
                  {canMimic && (
                    <Button
                      class="absolute top-0 right-0 bottom-0 group flex items-center p-4"
                      onClick={() => mimicAndView(user)}
                    >
                      <span class="inline-block -mt-0.5 px-2 text-sm py-1 bg-gray-50 border group-hover:bg-indigo-600 group-hover:text-white group-hover:border-indigo-600 rounded-sm whitespace-nowrap">
                        Mimic &amp; view
                      </span>
                    </Button>
                  )}
                </span>
              </span>
            );
          })}
        </div>
      )}

      {state.cursor && (
        <footer class=" text-center p-4">
          <BtnSecondary
            isLoading={ctx.isProcessing}
            onClick={(e: any) => {
              e.target.blur();
              fetchNow(form, state.cursor);
            }}
          >
            Load more
          </BtnSecondary>
        </footer>
      )}
    </div>
  );
}
