import { LoadedProps } from '@components/router';
import { rpx } from 'client/lib/rpx-client';

export async function load() {
  const [brands, schemes] = await Promise.all([
    rpx.branding.getBrands(),
    rpx.branding.getFontsAndColors(),
  ]);
  return { ...schemes, brands };
}

export type Props = LoadedProps<typeof load>;
export type Data = Props['state'];
export type Brand = Data['brands'][0];
