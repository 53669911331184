import { RouteLoadProps } from '@components/router';
import { rpx } from 'client/lib/rpx-client';
import { InvitationRow } from 'server/types';

export type BaseInvitation = Pick<
  InvitationRow,
  'id' | 'createdAt' | 'email' | 'userId' | 'code' | 'accessDuration'
>;
export type Invitation = BaseInvitation & { search: string };

export function normalizeInvitation(invitation: BaseInvitation) {
  return { ...invitation, search: invitation.email.toLocaleLowerCase() };
}

export async function load(route: RouteLoadProps) {
  const { courseId } = route.params;
  const [course, invitations] = await Promise.all([
    rpx.courses.getGuideCourse({ id: courseId }),
    rpx.invitations.getInvitations({ courseId }),
  ]);

  return {
    course,
    invitations: invitations.map(normalizeInvitation),
  };
}
