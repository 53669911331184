import { useDocumentTitle } from 'client/utils/use-document-title';
import { LoadedProps, RouteLoadProps, useRouter } from '@components/router';
import { rpx } from 'client/lib/rpx-client';
import { AsyncForm } from '@components/async-form';
import { useEffect, useState } from 'preact/hooks';
import { BtnPrimary, Button } from '@components/buttons';
import { IcoArrowRight } from '@components/icons';
import { showError } from '@components/app-error';
import { Field, InputField, eventToState } from './form-helpers';
import { Auth } from 'client/lib/auth';
import { Password } from '@components/password';
import { ComponentChildren } from 'preact';
import { DefaultSpinner } from '@components/spinner';
import { TestimonialWall } from '@components/testimonial-wall';
import { defCalRoute } from './common';

export const route = defCalRoute({ isPublic: true, load, Page });

type Props = LoadedProps<typeof load>;

type Pane = 'email' | 'auth' | 'confirm';

async function load(props: RouteLoadProps) {
  const host = await rpx.ruzcal.getHost({});
  const pane: Pane = props.auth.user ? 'confirm' : 'email';
  return {
    host,
    email: props.auth.user?.email || '',
    name: props.auth.user?.name || '',
    profilePhotoUrl: props.auth.user?.profilePhotoUrl,
    exists: !!props.auth.user,
    pane: pane as unknown as Pane,
  };
}

function FormHeader(props: { title: ComponentChildren; subtitle?: ComponentChildren }) {
  return (
    <header class="flex flex-col gap-1">
      <h1 class="text-3xl font-medium leading-normal text-transparent bg-clip-text bg-gradient-to-r from-indigo-500 to-sky-500">
        {props.title}
      </h1>
      {props.subtitle && <p>{props.subtitle}</p>}
    </header>
  );
}

function AuthForm(props: { onSubmit(user: Auth['user']): void }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [state, setState] = useState({
    email: '',
    password: '',
    sentResetEmail: false,
  });

  if (state.sentResetEmail) {
    return (
      <div class="flex flex-col w-full max-w-96">
        <FormHeader
          title="We sent you a magic link!"
          subtitle={
            <>Check the inbox for {state.email}, and click the link there to continue signing up.</>
          }
        />
      </div>
    );
  }

  return (
    <div class="flex flex-col w-full max-w-96 gap-6">
      <FormHeader title="Ruzuku Calendar" />
      <AsyncForm
        class="flex flex-col gap-6 w-full max-w-80"
        onSubmit={async () => {
          setIsSubmitting(true);
          try {
            const user = await rpx.auth.login({ email: state.email, password: state.password });
            props.onSubmit(user);
          } catch (err) {
            showError(err);
          } finally {
            setIsSubmitting(false);
          }
        }}
      >
        <InputField
          name="email"
          fullWidth
          autoFocus
          title="Email address"
          value={state.email}
          onInput={eventToState(setState)}
        />
        <Field name="password" title="Password">
          <Password
            name="password"
            class="p-4"
            placeholder=" "
            value={state.password}
            onInput={eventToState(setState)}
          />
        </Field>
        <BtnPrimary
          class="gap-2 items-center p-4 rounded-full text-base min-w-40 mt-2"
          isLoading={isSubmitting}
        >
          Sign in to Ruzuku Calendar
          <IcoArrowRight class="size-4 shrink-0" />
        </BtnPrimary>

        <Button
          class={`text-indigo-600 text-center hover:underline rounded-full outline-none focus:ring-2 ring-indigo-500 transition-all ${
            state.email ? '' : 'opacity-0'
          }`}
          disabled={!state.email}
          onClick={async () => {
            try {
              await rpx.auth.forgotPassword({
                email: state.email,
                returnURL: location.href,
              });
              setState((s) => ({ ...s, sentResetEmail: true }));
            } catch (err) {
              showError(err);
            }
          }}
        >
          Or get a sign in link sent to your email.
        </Button>
      </AsyncForm>
    </div>
  );
}

function Page({ params, state, auth }: Props) {
  const router = useRouter();
  const { host } = state;

  useDocumentTitle(['Sign in to Ruzuku Calendar']);

  const gotoRedirect = () => router.goto(decodeURIComponent(params.redirect || '/calendar'));

  useEffect(() => {
    if (host) {
      gotoRedirect();
    }
  }, []);

  if (host) {
    return (
      <div class="flex items-center justify-center min-h-screen">
        <DefaultSpinner />
      </div>
    );
  }

  return (
    <div class="grid md:grid-cols-2 grow min-h-screen">
      <section class="flex flex-col grow items-center justify-center p-10 relative overflow-auto">
        <AuthForm
          onSubmit={(user) => {
            auth.user = user;
            auth.setUser(user);
            gotoRedirect();
          }}
        />
        <div class="absolute right-0 inset-y-0 w-2 bg-gradient-to-b from-transparent to-transparent via-sky-400"></div>
      </section>
      <section class="bg-indigo-600 flex flex-col grow items-center justify-center p-10 text-white relative">
        <TestimonialWall />
      </section>
    </div>
  );
}
