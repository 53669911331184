/**
 * This file defines a toolbar item for the message editor.
 * It basically lets users select from the dynamic data options,
 * and insert it anywhere in the document.
 *
 * The inserted element is a simple non-contenteditable span.
 * e.g. `<span contenteditable="false">[User Name]</span>`
 *
 * The backend replaces the inner text with appropriate
 * dynamic data before sending the email.
 */
import { IcoDB } from '@components/icons';
import { getCurrentRange, setCurrentRange } from 'client/lib/minidoc';
import {
  h,
  MinidocToolbarEditor,
  ToolbarButton,
  Submenu,
  MinidocToolbarAction,
} from 'minidoc-editor';
import { renderToString } from 'preact-render-to-string';
import { MessageTemplateField } from 'server/types';

const icoCustomFields = renderToString(IcoDB({}));

function getCurrentSelection() {
  const sel = document.getSelection();
  const range = sel && sel.rangeCount > 0 ? sel.getRangeAt(0) : undefined;
  const node = range?.startContainer;
  const input = (node?.firstChild || node) as HTMLInputElement;

  return {
    node,
    titleInput: input?.classList?.contains('js-edit-lesson-title') ? input : undefined,
  };
}

interface TemplateMenuProps {
  editor: MinidocToolbarEditor;
  templateFields: MessageTemplateField[];
}

function DataTemplateMenu({ editor, templateFields }: TemplateMenuProps) {
  const hide = () => editor.toolbar.setMenu(undefined);

  const insertField = (fieldKey: string) => {
    try {
      const { titleInput, node } = getCurrentSelection();

      if (titleInput) {
        const text = `${fieldKey} `;
        const newCursorPosition = (titleInput.selectionStart || 0) + text.length;

        titleInput.setRangeText(text);
        // Trigger onInput event.
        titleInput.dispatchEvent(new Event('input'));
        titleInput.focus();

        // Set the cursor position right after insertion.
        titleInput.selectionStart = newCursorPosition;
        titleInput.selectionEnd = newCursorPosition;

        return;
      }

      const el = node instanceof Element ? node : node?.parentElement;
      const isInEditor = !!el?.closest('[contenteditable]');

      if (!isInEditor) {
        return;
      }

      const currentRange = getCurrentRange(editor);
      const insertEl = h('template-field', { contenteditable: 'false' }, fieldKey);
      currentRange.insertNode(insertEl);
      // Set the cursor positon right after the inserted element.
      currentRange.setStartAfter(insertEl);
      currentRange.setEndAfter(insertEl);
      setCurrentRange(currentRange);
    } finally {
      hide();
    }
  };

  const menu = Submenu({
    children: templateFields.map((field) =>
      ToolbarButton(editor, {
        html: `<span class="my-1 px-1 py-2 text-xs font-medium rounded-lg bg-indigo-100">${field.title}</span>`,
        // Wrap the key with {{{ }}}
        run: () => insertField(`{{{${field.key}}}}`),
      }),
    ),
    editor,
  });

  return menu;
}

export const dataTemplateToolbarAction = (
  templateFields: MessageTemplateField[],
): MinidocToolbarAction => {
  // This is needed to focus back to the title input after the menu is opened.
  let lastActive: Element | null;

  return {
    id: 'data-template',
    label: 'Insert Custom Fields',
    html: icoCustomFields,
    onMouseDown: () => {
      lastActive = document.activeElement;
    },
    run: (t) => {
      const { titleInput } = getCurrentSelection();
      const isTitle = !!titleInput;

      t.toolbar.setMenu(
        DataTemplateMenu({
          editor: t,
          // Filters out some menu items(course image) when the title input is selected.
          templateFields: isTitle
            ? templateFields.filter((f) => !f.notAvailableForSubject)
            : templateFields,
        }),
      );
      // Focus back to the last active element
      (lastActive as HTMLElement)?.focus();
    },
  };
};
