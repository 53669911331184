import { FormGroup } from '@components/async-form';
import { useState } from 'preact/hooks';
import { useCurrentTenant } from '@components/router/session-context';
import { Toggle } from '@components/toggle';
import { rpx } from 'client/lib/rpx-client';
import { DatePicker, TimezoneDiff, SendButtonContent } from '@components/date-picker';
import { ModalForm } from '@components/modal-form';

interface Props {
  scheduledAt?: Date;
  courseId: UUID;
  meetingId: UUID;
  hasEnrolledStudents: boolean;
  onSuccess: (newDate: Date) => void;
  hide(): void;
}

interface FormData {
  sendNotification: boolean;
}

export function RescheduleModal(props: Props) {
  const { terminology } = useCurrentTenant();
  const [selectedDate, setSelectedDate] = useState(() => props.scheduledAt || new Date());
  const isDraft = !props.scheduledAt;

  async function reschedule(formData: FormData) {
    await rpx.meetings.rescheduleMeeting({
      courseId: props.courseId,
      meetingId: props.meetingId,
      scheduledAt: selectedDate.toISOString(),
      sendNotification: !isDraft && props.hasEnrolledStudents ? formData.sendNotification : false,
    });
    props.onSuccess(selectedDate);
  }

  return (
    <ModalForm
      onClose={props.hide}
      onSubmit={reschedule}
      title={
        <>
          {isDraft ? 'Schedule' : 'Reschedule'} {terminology.meeting}
        </>
      }
      confirmButtonText="Schedule meeting"
    >
      <FormGroup prop="scheduledAt">
        <div class="inline-flex">
          <DatePicker value={selectedDate} onChange={(dt) => dt && setSelectedDate(dt)} />
        </div>
      </FormGroup>
      <TimezoneDiff date={selectedDate} class="my-4" />
      {!isDraft && props.hasEnrolledStudents && (
        <label class="flex my-4 cursor-pointer border rounded-2xl p-3 gap-2">
          <Toggle name="sendNotification" defaultChecked />
          <span class="block">Notify enrolled students that you're rescheduling</span>
        </label>
      )}

      <div class="flex border rounded-2xl p-3 gap-2">
        <SendButtonContent prefix={`Schedule ${terminology.meeting} for`} date={selectedDate} />
      </div>
    </ModalForm>
  );
}
