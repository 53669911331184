//  Email notification to guide for new course enrollments
import { render } from 'preact-render-to-string';
import { BasicEmailWrapper, Heading, Image, Italic, PrimaryAction, Table } from './shared';
import { pluralize } from 'shared/formatting';
import { URLS } from 'shared/urls';
import { Tenant, UserProfileRow } from 'server/types';
import { stripAllTags } from 'server/lib/xss';
import { RUZUKU_ASSETS_BASE_URL } from 'shared/consts';

const MAX_STUDENTS = 10;

type Student = Pick<UserProfileRow, 'name' | 'email' | 'timezone' | 'profilePhotoUrl' | 'bio'> & {
  id: UUID;
  priceName?: string;
  couponCode?: string;
  avatarUrl?: string;
};

/**
 * Email notification to guide for new course enrollments
 * (see: cron/scheduled-mailer/new-enrollments.ts)
 */
export function NewEnrollments({
  course,
  courseImage,
  students,
  tenant,
}: {
  course: { id: UUID; title: string };
  courseImage?: string;
  students: Student[];
  tenant: Tenant;
}) {
  const notificationSettingsUrl = URLS.guide.coursePage({
    courseId: course.id,
    domain: tenant.domain,
    page: 'settings/notifications',
  });
  // Manage students url
  const ctaUrl = URLS.guide.students({
    domain: tenant.domain,
    courseId: course.id,
  });
  return render(
    <BasicEmailWrapper
      footer={
        <span>
          Visit the <a href={notificationSettingsUrl}>course notifications settings</a> to modify
          email preferences for "new signups".
        </span>
      }
    >
      {courseImage && <Image imagePath={courseImage} />}
      <Heading>
        {students.length} new {pluralize('student', students.length)} signed up for {course.title}
      </Heading>
      <Table>
        {students.map((student, index) => (
          <StudentRow key={`row-${index}`} student={student} tenant={tenant} course={course} />
        ))}
      </Table>

      <PrimaryAction href={ctaUrl}>
        {students.length > MAX_STUDENTS
          ? `View all ${students.length} students`
          : 'View all students'}
      </PrimaryAction>
    </BasicEmailWrapper>,
  );
}

/**
 * Single row in a students table
 */
function StudentRow({
  student,
  tenant,
  course,
}: {
  student: Student;
  tenant: Tenant;
  course: { id: UUID; title: string };
}) {
  const manageStudentUrl = URLS.guide.student({
    domain: tenant.domain,
    courseId: course.id,
    userId: student.id,
  });

  return (
    <>
      <tr>
        {/* User avatar */}
        <td style="padding-top:24px;text-align:center;vertical-align:top;" width="32">
          <img
            src={student.avatarUrl || `${RUZUKU_ASSETS_BASE_URL}/user-placeholder.png`}
            style="width:32px;height:32px;border-radius:16px;"
          />
        </td>

        {/* Name, description of signup action, and manage student link */}
        <td
          colSpan={2}
          style="padding-top:24px;padding-left:8px;text-align:left;vertical-align:top;"
        >
          <a
            href={manageStudentUrl}
            style="font-weight:bold;font-size:16px;color:#0077cc;text-decoration:none;"
            target="_blank"
            rel="noreferrer"
          >
            {student.name} signed up through
            {student.priceName ? ` "${student.priceName}" price point` : ' invitation'}
            {student.couponCode ? ` and used "${student.couponCode}" coupon code` : ''}.
          </a>
        </td>
      </tr>

      <tr>
        <td colSpan={1}></td>
        <td colSpan={2} style="padding-left:8px;padding-bottom:8px;color:#6a737c;font-size:13px;">
          Email: <a href={`mailto:${student.email}`}>{student.email}</a>
        </td>
      </tr>

      {/* Bio */}
      {student.bio && (
        <tr>
          <td colSpan={1}></td>
          <td
            colSpan={2}
            style="padding-left:8px;padding-bottom:12px;color:#6a737c;font-size:13px;"
          >
            Bio: <Italic>{stripAllTags(student.bio)}</Italic>
          </td>
        </tr>
      )}
    </>
  );
}
