import { render } from 'preact-render-to-string';
import { ComponentChildren } from 'preact';
import { Internationalize } from 'shared/intl';
import { DoubleLine, Line, Mailto, TemplateField } from './shared';
import { minutesToTimeString } from 'shared/dateutil';
import { Course, MeetingRow, UserProfileRow } from 'server/types';
import { RUZUKU_ASSETS_BASE_URL } from 'shared/consts';

/**
 * Template fields for course emails.
 * These will be replaced with their equivalent template string (eg: {{course-title}}
 * if not passed to the template
 *
 * TODO: this will be moved into a /mailers/course-emails.tsx file
 */
export type CourseEmailTemplateFields = {
  courseName?: string; // {{course-name}}
  courseImage?: string; // {{course-image}}
  bundleImage?: string; // {{bundle-image}}
  guideName?: string; // {{guide-name}}
  moduleTitle?: string; // {{module-title}}
  recipientName?: string; // {{recipient-name}} || '%recipient.name%',
};

/**
 * Template fields for meeting emails.
 * These will be replaced with their equivalent template string (eg: {{meeting-title}}
 * if not passed to the template
 */
export type MeetingEmailTemplateFields = CourseEmailTemplateFields & {
  guideEmail?: string;
  meetingDuration?: string; // {{meeting-duration}}
  meetingTitle?: string; // {{meeting-title}}
  startHour?: string; // {{start-hour}}  || '%recipient.startHour%'
  startDay?: string; // {{start-day}}  || '%recipient.startDay%'
};

type MeetingEmailProps = {
  intl: Internationalize;
  recipientName?: string;
  course: Pick<Course, 'id' | 'title' | 'imagePath'>;
  meeting: Pick<MeetingRow, 'id' | 'title' | 'durationMinutes' | 'scheduledAt' | 'type'>;
  guide: { id: UUID } & Pick<UserProfileRow, 'name' | 'email'>;
};
/**
 * Content for "Meeting starting now" email
 *  Note: this still includes the following mustache template variables:
 *    {{recipient-name}}
 */
export const MeetingStartingNow = ({
  intl,
  recipientName,
  course,
  meeting,
  guide,
}: MeetingEmailProps) => {
  return render(
    <MeetingEmailWrapper intl={intl} guide={guide} recipientName={recipientName}>
      <DoubleLine>
        {intl(
          `I wanted to let you know that the event, {meetingTitle:string}, in the course {courseTitle:string}, is starting now. The event lasts {duration:string}.`,
          {
            meetingTitle: meeting.title || '',
            courseTitle: course.title,
            duration: minutesToTimeString(meeting.durationMinutes, intl),
          },
        )}
      </DoubleLine>
    </MeetingEmailWrapper>,
  );
};

/**
 * Default content for first meeting reminder email
 * Note: this still includes mustache template variables:
 *    {{recipient-name}}
 *    {{course-name}}
 *    {{start-hour}}
 *    {{start-day}}
 *    {{meeting-duration}}
 *    {{guide-name}}
 */
export const MeetingReminderContentFirst = ({
  intl,
  recipientName,
  courseName,
  guideName,
  guideEmail,
  meetingDuration,
  startHour,
  startDay,
}: MeetingEmailTemplateFields & {
  intl: Internationalize;
}) => {
  const guide = { name: guideName, email: guideEmail };

  // Split this longer phrase up so we can correctly insert the <template-field> tags.
  // Note: this is ugly, but we don't currently have a better solution for using `intl`
  // strings with JSX components.
  const greeting = intl.split('I am hosting an event for the course, <>{course:string}</>', {})[0];
  const mid = intl.split('starting at <>{startHour:string}</>', {})[0];
  const mid2 = intl.split('on <>{startDay:string}</>', {})[0];
  const last = intl.split('The event lasts <>{duration:string}</>', { duration: '' })[0];

  return render(
    <MeetingEmailWrapper
      intl={intl}
      guide={guide}
      recipientName={recipientName}
      footerText={intl('Join me!')}
    >
      <DoubleLine>
        {/* I am hosting an event for the course "Example Course" */}
        {greeting} "{courseName || <TemplateField name="course-name" />}",{' '}
        {/* starting at 12:00PM */}
        {mid} {startHour || <TemplateField name="start-hour" />} {/* on January 1 */}
        {mid2} {startDay || <TemplateField name="start-day" />}.{/* The event lasts 1 hour */}{' '}
        {last} {meetingDuration || <TemplateField name="meeting-duration" />}.
      </DoubleLine>
    </MeetingEmailWrapper>,
  );
};

/**
 * Default content for second meeting reminder email
 * Note: this still includes mustache template variables:
 *    {{recipient-name}}
 *    {{course-name}}
 *    {{start-hour}}
 *    {{meeting-duration}}
 *    {{guide-name}}
 */
export const MeetingReminderContentSecond = ({
  intl,
  recipientName,
  courseName,
  startHour,
  meetingDuration,
  guideName,
  guideEmail,
}: MeetingEmailTemplateFields & { intl: Internationalize }) => {
  const guide = { name: guideName, email: guideEmail };

  const greeting = intl.split('I am hosting an event for the course, <>{course:string}</>', {})[0];
  const mid = intl.split('starting at <>{startHour:string}</>', {})[0];
  const last = intl.split('The event lasts <>{duration:string}</>', { duration: '' })[0];
  return render(
    <MeetingEmailWrapper
      intl={intl}
      guide={guide}
      recipientName={recipientName}
      footerText={intl('Join me!')}
    >
      <DoubleLine>
        {/* I am hosting ... the course, "Example Course", */}
        {greeting} "{courseName || <TemplateField name="course-name" />}",{' '}
        {/* starting at 12:00PM.           */}
        {mid} {startHour || <TemplateField name="start-hour" />}
        {'. '}
        {/*  The event lasts 1 hour.       */} {last}{' '}
        {meetingDuration || <TemplateField name="meeting-duration" />}.
      </DoubleLine>
    </MeetingEmailWrapper>,
  );
};

/**
 * "Meeting rescheduled" email template
 * Note: this still includes mustache template variables:
 *    {{recipient-name}}
 */
export const MeetingRescheduled = ({
  intl,
  recipientName,
  course,
  meeting,
  guide,
}: MeetingEmailProps) => {
  return render(
    <MeetingEmailWrapper intl={intl} guide={guide} recipientName={recipientName}>
      <DoubleLine>
        {intl(
          'I wanted to let you know that the event, {meetingTitle:string}, in the course {courseTitle:string}, has been rescheduled to %recipient.startDay% at %recipient.startHour%. The event lasts {duration:string}.',
          {
            meetingTitle: meeting.title,
            courseTitle: course.title,
            duration: minutesToTimeString(meeting.durationMinutes, intl),
          },
        )}
      </DoubleLine>
    </MeetingEmailWrapper>,
  );
};

/**
 * Content for video meeting reminder emails
 * Note: this still includes mustache template variables:
 *    {{recipient-name}}
 */
export const VideoConferenceReminder = ({
  intl,
  course,
  meeting,
  guide,
  recipientName,
}: MeetingEmailProps) => {
  const [supportPrefix, supportUrl, supportSuffix] = intl.split(
    `You can check out our <>documentation page</> if you have any problems while joining.`,
  );

  return render(
    <MeetingEmailWrapper intl={intl} guide={guide} recipientName={recipientName}>
      <DoubleLine>
        {intl(
          `I wanted to let you know that the event, {meetingTitle:string}, in the course {courseTitle:string}, is starting now. The event lasts {duration:string}.`,
          {
            meetingTitle: meeting.title || '',
            courseTitle: course.title,
            duration: minutesToTimeString(meeting.durationMinutes, intl),
          },
        )}
      </DoubleLine>

      <DoubleLine>{intl('IMPORTANT:')}</DoubleLine>
      <DoubleLine>
        <ul>
          <li>
            {intl(`When you join the meeting, you'll be asked to allow access to your microphone.`)}
          </li>
          <li>
            {intl(
              `Please 'allow' or 'grant' access. If you don't, you won't be able to access the meeting. Your microphone will be muted automatically and your voice won't be transmitted unless you unmute yourself.`,
            )}
          </li>
        </ul>
      </DoubleLine>
      <DoubleLine>
        {intl(
          `You'll see a screen like this — please click 'allow' so you can listen to and participate in your upcoming meeting.`,
        )}
      </DoubleLine>
      <img alt="help" src={`${RUZUKU_ASSETS_BASE_URL}/allow-microphone.png`} />
      <DoubleLine>
        {supportPrefix}
        <a href="https://support.ruzuku.com/article/750-joining-a-meeting">{supportUrl}</a>
        {supportSuffix}
      </DoubleLine>
    </MeetingEmailWrapper>,
  );
};

/**
 * Generic wrapper for meeting reminder emails, with standard greeting and guide contact footer
 */
function MeetingEmailWrapper({
  intl,
  recipientName,
  guide,
  footerText, // eg: "Thanks!" or "Join me!" greeting message; defaults to "Thanks!" translation.
  children,
}: {
  intl: Internationalize;
  recipientName?: string;
  guide: {
    name?: string;
    email?: string;
  };
  footerText?: string;
  children?: ComponentChildren;
}) {
  return (
    <>
      <DoubleLine>
        {intl('Hi')} {recipientName || <TemplateField name="recipient-name" />},
      </DoubleLine>
      {children}
      <MeetingEmailFooter guide={guide} intl={intl}>
        {footerText}
      </MeetingEmailFooter>
    </>
  );
}
/**
 * Footer for meeting reminder emails
 */
const MeetingEmailFooter = ({
  intl,
  guide,
  children, // Eg: "Thanks!" or "Join me!" greeting message; defaults to "Thanks!" translation.
}: {
  intl: Internationalize;
  guide: {
    name?: string;
    email?: string;
  };
  children?: ComponentChildren;
}) => {
  const [contactPrefix, , middle, , suffix] = intl.split(
    `If you have any questions about the meeting, you can contact <>{guideName:string}</> at <>{guideEmail:string}</>.`,
    {},
  );
  return (
    <>
      <DoubleLine>
        {/* If you have any questions... contact Example Guide*/}
        {contactPrefix}
        {guide.name || <TemplateField name="guide-name" />}

        {/* at example@guide.com */}
        {middle}
        {guide.email ? <Mailto email={guide.email} /> : <TemplateField name="guide-email" />}
        {suffix}
      </DoubleLine>
      <Line>{children || intl('Thanks!')}</Line>
      <DoubleLine> -- {guide.name || <TemplateField name="guide-name" />}</DoubleLine>
    </>
  );
};
