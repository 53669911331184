/**
 * Extract the name and email from a line, with support
 * for several formats: name, email or gmail-style:
 * Chris Davies <chris@example.com>
 */
export function extractNameAndEmail(line: string) {
  let [name, email] = line.split(',', 2);
  if (!email) {
    email = line.match(/[^@<\s]+@[^@\s>]+/g)?.[0] || '';
    name = line.replace(/[^@\s]+@[^@\s]+/, '');
  }
  name = name?.trim();
  name = name || email.split('@', 1)[0];
  return { name, email: email?.trim().toLowerCase() };
}

/**
 * Extract distinct emails from the text, with support for
 * Gmail-style "Chris Davies <chris@ruzuku.com>" emails.
 */
export function extractEmails(text: string) {
  return Array.from(
    new Set(
      text
        .split(/[\s,]/g)
        .filter((email) => email.indexOf('@') >= 0)
        .map((email) => email.replace(/^<+|>+$/g, '')),
    ),
  );
}
