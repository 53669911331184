/**
 * This file is a catch-all for formatting system types in the UI.
 */

import { AccountTier } from 'server/types';

export const tiers: AccountTier[] = ['free', 'core', 'pro'];

export function tierName(tier: AccountTier) {
  const tiers: Record<AccountTier, string> = {
    core: 'Core',
    free: 'Free',
    pro: 'Pro',
  };

  return tiers[tier];
}
