import { useState } from 'preact/hooks';
import { AuditLogWithCourseInfo } from 'server/rpx/endpoints/audit-logs';
import { fmtFullDate } from 'shared/dateutil';
import { Button } from '@components/buttons';
import { useRouteParams } from '@components/router';
import { rpx } from 'client/lib/rpx-client';
import { SlideOver } from '@components/slide-over';
import { LoadingIndicator } from '@components/loading-indicator';
import { useAsyncData } from 'client/lib/hooks';

/**
 * Displays a slideover view for course audit log events
 */
export function CourseAuditLogsSlider({ closeSlider }: { closeSlider: () => void }) {
  const { courseId } = useRouteParams();
  const { data, isLoading } = useAsyncData(
    () => rpx.auditLogs.getCourseAuditLogEvents({ courseId }),
    [courseId],
  );

  return (
    <SlideOver
      close={() => {
        closeSlider();
      }}
    >
      <div class="py-12 px-8">
        <h2 class="text-2xl">Course logs</h2>
        {isLoading && (
          <div>
            Loading data
            <LoadingIndicator />
          </div>
        )}
        {data && (
          <div class="border-t-2 border-gray-200 py-12">
            <CourseAuditLogsTable items={data} />
          </div>
        )}
      </div>
    </SlideOver>
  );
}

/**
 * Displays a semi-paginated table of audit-log events for a user
 */
function CourseAuditLogsTable({ items }: { items: AuditLogWithCourseInfo[] }) {
  const [showingAll, setShowingAll] = useState(false);
  const maxRows = 5;
  // Return a slice of rows, truncated if we're not expanded.
  const sliceRows = (rows: AuditLogWithCourseInfo[], max = 5) => {
    return showingAll ? rows : rows.slice(0, max);
  };

  if (!items.length) {
    return <span class="my-4 text-gray-400">No log events for this course</span>;
  }
  return (
    <div>
      <div class="table table-auto bg-white rounded-sm border w-full divide-y mt-6">
        <div class="table-row bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
          <div class="table-cell w-30 pl-4 pr-2 py-2">Date</div>
          <div class="table-cell pl-4 pr-2 py-2">Action</div>
          <div class="table-cell pl-4 pr-2 py-2">Details</div>
        </div>
        {sliceRows(items, maxRows).map((r) => (
          <LogRow key={r.id} row={r} />
        ))}
      </div>
      {items.length > maxRows && (
        <div class="w-full flex justify-center">
          <span className="flex-1">
            <Button
              class="rounded-lg w-full py-3 hover:bg-gray-100 dark:hover:bg-black/25 hover:text-indigo-800 text-indigo-600 dark:text-sky-500 font-medium text-left mt-2 px-4"
              onClick={() => setShowingAll(!showingAll)}
            >
              {showingAll ? 'Show less' : `Show ${items.length - maxRows} more items`}
            </Button>
          </span>
        </div>
      )}
    </div>
  );
}

// This wraps the messaged details with human-formatted details.
const LogRow = ({ row }: { row: AuditLogWithCourseInfo }) => {
  const action = row.type.replaceAll('_', ' '); // ie: 'enrollment disabled by guide'
  return (
    <span class="table-row text-inherit hover:bg-indigo-50 text-sm">
      <span class="table-cell p-4 border-t align-top">{fmtFullDate(row.createdAt)}</span>
      <span class="table-cell p-4 border-t align-top">{action}</span>
      <span class="table-cell p-4 border-t align-top">
        {row.message}{' '}
        <span>
          for user <a href={`/admin/people/${row.toUserId}`}>{row.toUserEmail}</a>{' '}
          {(row.isGuide && 'by guide') || (row.isAdmin && 'by admin ') || 'by user'}{' '}
          {row.byUserEmail && <a href={`/admin/people/${row.byUserId}`}> ({row.byUserEmail})</a>}
        </span>
      </span>
    </span>
  );
};
