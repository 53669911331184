import { ComponentChildren } from 'preact';
import { LoadingIndicator } from '@components/loading-indicator';
import { useDocumentTitle } from 'client/utils/use-document-title';

/**
 * The page wrapper for guide / management screens where the layout is fixed
 * and the individual children overflow auto appropriately.
 */
export function FixedPage({
  bg = 'bg-gray-50',
  title,
  isLoading,
  children,
}: {
  bg?: string;
  title?: string;
  isLoading?: boolean;
  children: ComponentChildren;
}) {
  useDocumentTitle([title]);
  return (
    <div class={`min-h-screen flex text-gray-700 ${bg}`}>
      {children}
      {isLoading && <LoadingIndicator />}
    </div>
  );
}

export { FixedContent } from './fixed-content';
