import { ComponentChildren, JSX } from 'preact';

import { IcoChevronDown } from '@components/icons';

export function ColorPicker({
  name,
  color,
  onPick,
  children,
  indicator,
  class: className,
  ...props
}: JSX.LabelHTMLAttributes<HTMLLabelElement> & {
  name: string;
  color?: string;
  onPick(color: string): void;
  children?: ComponentChildren;
  indicator?: ComponentChildren;
}) {
  return (
    <label
      class={`relative flex items-center cursor-pointer ${
        children ? 'gap-2' : 'gap-1'
      } font-medium rounded p-2 px-3 hover:bg-gray-50 ${className || ''}`}
      {...props}
    >
      <span
        class="w-6 h-6 min-w-6 rounded-full inline-block border shrink-0"
        style={{ background: color }}
      ></span>
      {children && <span>{children}</span>}
      <IcoChevronDown class="h-4 w-4 ml-auto shrink-0" />
      {indicator}
      <input
        type="color"
        class="opacity-0 w-1 h-1 absolute top-0 left-0"
        onInput={(e: any) => onPick(e.target.value)}
      />
    </label>
  );
}
