export function pluralize(s: string, n: number, plural?: string) {
  return n !== 1 ? plural || s + 's' : s;
}

export function secondsToRelDateStr(t: number) {
  if (t < 60) {
    return `${t} ${pluralize('second', t)}`;
  }
  t = Math.round(t / 60);
  if (t < 60) {
    return `${t} ${pluralize('minute', t)}`;
  }
  t = Math.round(t / 60);
  if (t < 24) {
    return `${t} ${pluralize('hour', t)}`;
  }
  t = Math.round(t / 24);
  if (t < 31) {
    return `${t} ${pluralize('day', t)}`;
  }
  t = Math.round(t / 31);
  if (t < 12) {
    return `${t} ${pluralize('month', t)}`;
  }
  t = Math.round(t / 12);
  return `${t} ${pluralize('year', t)}`;
}

function labeledInterval(n: number, lbl: string) {
  return n ? `${n}${lbl} ` : '';
}

export function tohms(n: number) {
  const sign = n < 0 ? '-' : '';
  n = Math.abs(n);
  const ss = Math.floor(n % 60);
  const mm = Math.floor(n / 60) % 60;
  const hh = Math.floor(n / 60 / 60);
  const result = `${labeledInterval(hh, 'h ')}${labeledInterval(mm, 'm ')}${
    hh || mm ? '' : labeledInterval(ss, 's')
  }`.trim();
  return result ? `${sign}${result}` : '';
}

export function dateTohms(dt: Date | number | string) {
  return tohms((new Date(dt).getTime() - new Date().getTime()) / 1000);
}

export function toByteUnit(mb: number) {
  return mb < 1024 ? `${mb}MB` : `${(mb / 1025).toFixed(2)}GB`;
}

/**
 * Truncate string text to given length, with trailing ellipses
 */
export function truncateString(s: string, limit: number): string {
  if (s.length > limit) {
    return `${s.substring(0, limit).trim()}…`;
  }
  return s;
}
