import { showError } from '@components/app-error';
import { LoadingIndicator } from '@components/loading-indicator';
import { useEffect, useState } from 'preact/hooks';
import { SystemMessage, SystemMessageEditor, SystemMessageId } from './system-message-editor';
import { Course } from 'server/types';
import { rpx } from 'client/lib/rpx-client';
import { CustomMessage, CustomMessageEditor, Message, Updater } from '@components/messages';

const sysstore = rpx.systemMessages;
const store = rpx.messages;

interface Props {
  course: Pick<Course, 'id' | 'accessFormat' | 'isBundle' | 'isProduct' | 'isAbsoluteSchedule'>;
  messageId: UUID;
  onUpdate: Updater;
  onDelete(id: UUID): void;
  onDuplicate(message: Message): void;
}

export function EditorPane({ course, messageId, onUpdate, onDelete, onDuplicate }: Props) {
  const courseId = course.id;
  const isSystemMessage =
    messageId === 'moduleStart' || messageId === 'welcome' || messageId === 'invitation';

  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState<undefined | CustomMessage | SystemMessage>(undefined);
  const loadSystemMessage = async (id: SystemMessageId) => {
    const message = await sysstore.getSystemMessage({ courseId, messageType: id });
    return { id, ...message };
  };
  const loadMessage = (id: UUID) => store.getFullMessage({ courseId, id });

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const message = await (isSystemMessage
          ? loadSystemMessage(messageId as SystemMessageId)
          : loadMessage(messageId));
        const sanitized = {
          ...message,
          title: course.isProduct
            ? message.title.replaceAll('{{{course-', '{{{product-')
            : message.title,
          content: course.isProduct
            ? message.content.replaceAll('{{{course-', '{{{product-')
            : message.content,
        };
        setMessage(sanitized);
      } catch (err) {
        showError(err);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [messageId]);

  return (
    <div class="max-w-4xl mx-auto">
      {isLoading && <LoadingIndicator />}
      {message && !isSystemMessage && (
        <CustomMessageEditor
          key={message.id}
          course={course}
          message={message as CustomMessage}
          onUpdate={onUpdate}
          onDelete={onDelete}
          onDuplicate={onDuplicate}
        />
      )}
      {message && isSystemMessage && (
        <SystemMessageEditor key={message.id} course={course} message={message as SystemMessage} />
      )}
    </div>
  );
}
