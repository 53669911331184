/**
 * The Ruzuku pricing table. The prices are configured via environment
 * variables, which is a bit hacky, but was simple enough given the rarity
 * of change.
 */

import { DefaultSpinner } from '@components/spinner';
import { useAsyncData } from 'client/lib/hooks';
import { rpx } from 'client/lib/rpx-client';
import * as fmt from 'shared/payments/fmt';
import { ComponentChildren } from 'preact';
import { PriceRow } from 'server/types';
import { IcoCheck } from '@components/icons';
import { corePrices } from 'shared/urls';
import { Button } from '@components/buttons';
import { useCurrentUser } from '@components/router/session-context';
import { useEffect } from 'preact/hooks';
import { useIntl } from 'shared/intl/use-intl';

const store = rpx.pricingTable;

function yearlyDiscount(defaultPrice: PriceRow, yearlyPrice?: PriceRow) {
  if (!yearlyPrice) {
    return '';
  }
  return fmt.price({
    priceInCents: defaultPrice.priceInCents * 12 - yearlyPrice.priceInCents,
    currency: yearlyPrice.currency,
  });
}

function PricingTableSection({
  title,
  description,
  defaultPrice,
  yearlyPrice,
  bullets,
  ctaEl,
  hrefTarget,
}: {
  title: ComponentChildren;
  description: ComponentChildren;
  defaultPrice?: PriceRow;
  yearlyPrice?: PriceRow;
  bullets: string[];
  ctaEl?: ComponentChildren;
  hrefTarget: '_top' | '_self';
}) {
  const intl = useIntl();
  if (!defaultPrice) {
    return null;
  }

  return (
    <div class="border rounded-lg p-6">
      <h2 class="text-lg leading-6 font-medium text-zinc-900">{title}</h2>
      <p class="mt-4 text-sm text-gray-500 h-12">{description}</p>
      <div class="py-6">
        <span class="text-4xl font-extrabold text-zinc-900">{fmt.price(defaultPrice)}</span>
        <span class="text-base font-medium text-zinc-500 ml-1">
          {fmt.priceSuffix({ item: defaultPrice, compact: true, intl })}
        </span>
      </div>
      <div>
        {ctaEl}
        {!ctaEl && (
          <Button
            class="bg-indigo-600 rounded-sm w-full p-2 text-white text-center block"
            href={corePrices.checkoutUrl({ priceId: defaultPrice.id })}
            target={hrefTarget}
          >
            Choose {title}
          </Button>
        )}
        <div class={`my-2 ${yearlyPrice ? '' : 'invisible'}`}>
          <Button
            class="block w-full text-center p-2"
            href={yearlyPrice && corePrices.checkoutUrl({ priceId: yearlyPrice.id })}
            target={hrefTarget}
          >
            or <strong>save {yearlyDiscount(defaultPrice, yearlyPrice)} /yr </strong>
            <br />
            by paying yearly
          </Button>
        </div>
      </div>
      <div class="border-t pt-4">
        <h3 class="text-xs font-medium text-zinc-900 tracking-wide uppercase">What's included</h3>
        <ul class="mt-6 space-y-4">
          {bullets.map((s) => (
            <li key={s} class="flex space-x-3">
              <span>
                <IcoCheck class="h-5 w-5 text-green-400" />
              </span>
              <span class="text-sm text-zinc-500" dangerouslySetInnerHTML={{ __html: s }}></span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export function CorePricingTable({
  hrefTarget = '_self',
  onLoad,
}: {
  hrefTarget?: '_top' | '_self';
  onLoad?: () => void;
}) {
  const loader = useAsyncData(store.getCorePricing, []);
  const user = useCurrentUser();

  useEffect(() => {
    if (!loader.isLoading) {
      onLoad?.();
    }
  }, [loader.isLoading]);

  if (loader.isLoading) {
    return <DefaultSpinner />;
  }
  if (!loader.data) {
    return null;
  }
  const prices = loader.data;
  return (
    <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
      <PricingTableSection
        title="Free"
        description="Kick the tires with no commitment."
        defaultPrice={prices.free}
        hrefTarget={hrefTarget}
        ctaEl={user?.tier === 'free' ? <p class="py-2">You’re on the free plan</p> : undefined}
        bullets={[
          'Limited to 5 students',
          'Unlimited courses',
          'Host unlimited videos (up to 2GB per video)',
          'Live meetings and video conferences',
          'Online community + discussions',
          'Sales pages &amp; payments',
          'Friendly, helpful tech support for you &amp; your students',
        ]}
      />
      <PricingTableSection
        title="Core"
        description="Create, sell, and teach unlimited online courses."
        defaultPrice={prices.coreMonthly}
        hrefTarget={hrefTarget}
        yearlyPrice={prices.coreYearly}
        bullets={[
          '<strong>Unlimited</strong> students',
          'Unlimited courses',
          'Host unlimited videos (up to 2GB per video)',
          'Live meetings and video conferences',
          'Online community + discussions',
          'Sales pages &amp; payments',
          'Friendly, helpful tech support for you &amp; your students',
        ]}
      />
      <PricingTableSection
        title="Pro"
        description="Go pro with a custom domain and branding."
        defaultPrice={prices.proMonthly}
        hrefTarget={hrefTarget}
        yearlyPrice={prices.proYearly}
        bullets={[
          '<strong>Unlimited</strong> students',
          'Unlimited courses',
          'Host unlimited videos (up to <strong>4GB</strong> per video)',
          'Live meetings and video conferences',
          'Online community + discussions',
          'Sales pages &amp; payments',
          'Friendly, helpful tech support for you &amp; your students',
          '<strong>Custom domain</strong>',
          '<strong>Premium branding</strong>',
          '<strong>Multiple instructor accounts</strong>',
        ]}
      />
    </div>
  );
}
