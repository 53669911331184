import { Button } from '@components/buttons';
import { IcoPlus } from '@components/icons';
import { PageDef, defRoute } from '@components/router';
import { ComponentChildren } from 'preact';

export function defCalRoute<T>(def: PageDef<T>) {
  return defRoute({ loginPage: '/calendar/login', ...def });
}

export function PageHeading(props: {
  title: ComponentChildren;
  subtitle?: ComponentChildren;
  action?: ComponentChildren;
}) {
  return (
    <header class="flex justify-between items-end gap-4">
      <div class="text-gray-600">
        <h1 class="font-semibold text-lg text-gray-800">{props.title}</h1>
        {props.subtitle && <p>{props.subtitle}</p>}
      </div>
      {props.action}
    </header>
  );
}

export function PageContent({
  children,
  w = 'w-full max-w-4xl mx-auto',
}: {
  w?: string;
  children: ComponentChildren;
}) {
  return <section class={`flex flex-col grow gap-10 p-4 lg:py-8 ${w}`}>{children}</section>;
}

export function PageSection(props: { grow?: boolean; children: ComponentChildren }) {
  return <div class={`flex flex-col gap-6 ${props.grow ? 'grow' : ''}`}>{props.children}</div>;
}

export function ListContainer(props: { children: ComponentChildren }) {
  return <div class="flex flex-col gap-2 bg-white rounded-2xl border p-3">{props.children}</div>;
}

export function ListAddAction({
  content,
  ...props
}: {
  href?: string;
  onClick?(): void;
  content: ComponentChildren;
}) {
  return (
    <Button
      class="bg-indigo-600 text-white hover:bg-indigo-700 transition-all p-2 px-3 rounded-full font-medium whitespace-nowrap inline-flex items-center gap-1"
      {...props}
    >
      <IcoPlus /> {content}
    </Button>
  );
}
