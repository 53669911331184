/**
 * A general hook for displaying little pulsing circles by items
 * that require attention. These pulsing cirlces can be turned
 * off on a per-browser basis (local storage) via the `hide` function.
 */

import { useMemo, useState } from 'preact/hooks';

export function PulsingCircle() {
  return (
    <span class="flex h-3 w-3 relative">
      <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-300 opacity-75"></span>
      <span class="relative inline-flex rounded-full h-3 w-3 bg-red-400"></span>
    </span>
  );
}

function PulseIndicator() {
  return (
    <span class="absolute top-0.5 right-0.5">
      <PulsingCircle />
    </span>
  );
}

function loadPulsingIndicators(name: string): Record<string, boolean> {
  try {
    return JSON.parse(localStorage.getItem(name) || '{}');
  } catch (err) {
    console.error(err);
  }
  return {};
}

export function usePulseIndicators(pageName: string) {
  const [pulsingIndicators, setPulsingIndicators] = useState(() => loadPulsingIndicators(pageName));
  const result = useMemo(
    () => ({
      hide: (itemName: string) => {
        if (pulsingIndicators[itemName]) {
          return;
        }
        setPulsingIndicators((s) => {
          const newValue = { ...s, [itemName]: true };
          localStorage.setItem(pageName, JSON.stringify(newValue));
          return newValue;
        });
      },
      render: (itemName: string) => (pulsingIndicators[itemName] ? null : PulseIndicator()),
    }),
    [pulsingIndicators],
  );
  return result;
}
