import { ComponentChildren } from 'preact';

interface Props {
  color?: PillColor;
  centered?: boolean;
  children?: ComponentChildren;
}

export type PillColor = 'green' | 'gray' | 'red' | 'yellow' | 'blue';

const colorClasses: Record<PillColor, string> = {
  gray: 'bg-gray-50 text-gray-600 border-gray-200',
  red: 'bg-red-50 text-red-600 border-red-200',
  yellow: 'bg-yellow-50 text-yellow-600 border-yellow-200',
  green: 'bg-green-50 text-green-600 border-green-200',
  blue: 'bg-blue-50 text-blue-600 border-blue-200',
};

/**
 * A rounded, shaded pill.
 */
export function Pill(props: Props) {
  return (
    <span
      class={`p-0 px-1.5 border font-medium rounded-full text-xs capitalize ${
        colorClasses[props.color || 'green']
      } ${props.centered ? 'inline-flex items-center justify-center gap-1' : ''}`}
    >
      {props.children}
    </span>
  );
}
