import { ComponentChildren, JSX } from 'preact';
import { AutoScroll } from '@course/components/auto-scroll';
import { HeadingPrimary } from '@course/components/headings';
import { useState } from 'preact/hooks';
import { Button } from '@components/buttons';
import { IcoList, IcoX } from '@components/icons';
import { useEsc } from 'client/utils/use-esc';

export function PageNavBar({
  title,
  children,
  interactive,
}: {
  title: ComponentChildren;
  children: ComponentChildren;
  /**
   * If true, the nav bar will not hide on mouse down / click in mobile mode.
   * Interactive nav bars are not really "navigation". They contain editable
   * elements such as in the course style screen.
   */
  interactive?: boolean;
}) {
  const [showMobile, setShowMobile] = useState(false);
  useEsc(() => setShowMobile(false));
  return (
    <>
      <header class="flex p-1 border-b bg-white sticky top-0 z-40 md:hidden">
        <Button
          onKeyDown={(e) => e.stopPropagation()}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setShowMobile(true);
          }}
          class="inline-flex items-center gap-3 p-2 px-4 font-semibold capitalize"
        >
          <IcoList /> {title}
        </Button>
      </header>
      <AutoScroll
        scrollTo=".page-nav-link.is-selected"
        class={`absolute md:sticky inset-y-0 left-0 md:flex flex-col text-sm max-h-screen overflow-y-auto max-md:border md:border-r p-4 bg-white shadow-lg md:shadow-none z-40 w-3/4 max-w-80 ${
          showMobile
            ? 'max-md:animate-slide-in-sidenav flex'
            : 'hidden max-md:animate-slide-out-sidenav'
        }`}
        onMouseDown={interactive ? (e) => e.stopPropagation() : undefined}
        onClick={interactive ? (e) => e.stopPropagation() : undefined}
      >
        <Button
          onClick={() => setShowMobile(false)}
          class="absolute md:hidden p-6 pt-8 top-0 right-0"
        >
          <IcoX />
        </Button>
        <PageNavHeader title={title} />
        {children}
      </AutoScroll>
    </>
  );
}

function PageNavHeader({
  children,
  title,
  class: className = '',
}: {
  children?: ComponentChildren;
  title?: ComponentChildren;
  class?: string;
}) {
  return (
    <header class={className}>
      <HeadingPrimary title={title} />
      {children}
    </header>
  );
}

export function StudentPageNavHeader({
  title,
  class: className = 'mb-6',
}: {
  title?: ComponentChildren;
  class?: string;
}) {
  return (
    <header class={className}>
      <h2 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-200">{title}</h2>
    </header>
  );
}

export function PageNavBody({
  children,
  class: className,
  ...props
}: JSX.HTMLAttributes<HTMLElement>) {
  return (
    <nav {...props} class={`flex flex-col grow ${className}`}>
      {children}
    </nav>
  );
}

export function PageNavSegment({
  title,
  numItems,
  children,
}: {
  title: string;
  numItems?: number;
  children: ComponentChildren;
}) {
  return (
    <div class="py-4">
      <div class="font-display font-medium text-gray-900 dark:text-white flex justify-between mb-2 px-3 opacity-75">
        <span class="block uppercase text-gray-500 text-xs">{title}</span>
        {numItems !== undefined && <span class="opacity-75">{numItems}</span>}
      </div>
      <div class="flex flex-col gap-1">{children}</div>
    </div>
  );
}

export function PageNavLink({
  children,
  isSelected,
  actions,
  noRounding,
  ...props
}: JSX.AnchorHTMLAttributes<HTMLAnchorElement> & {
  isSelected?: boolean;
  actions?: ComponentChildren;
  noRounding?: boolean;
}) {
  return (
    <span
      class={`${
        isSelected
          ? 'bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-gray-100 is-selected'
          : 'text-gray-700 dark:hover:bg-gray-700 dark:text-gray-300'
      } hover:bg-gray-100 hover:text-gray-900 flex items-center ${noRounding ? '' : 'rounded-md'}`}
    >
      <a class="flex grow items-center p-2 px-3 text-inherit" {...props}>
        {children}
      </a>
      {isSelected && actions && <span class="flex items-center">{actions}</span>}
    </span>
  );
}
