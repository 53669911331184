import { useMemo } from 'preact/hooks';
import { Combobox } from './combobox';
import { h } from 'preact';

function parseTime(s: string, hour12: boolean) {
  const value = s.toLowerCase();
  const ampm = value.includes('a') ? 'am' : 'pm';
  const [h, m] = value.split(/[^0-9]/);
  const hh = parseInt(h, 10) || new Date().getHours();
  const mm = `${parseInt(m, 10) || 0}`.padEnd(2, '0');
  return `${hh}:${mm}${hour12 ? ampm : ''}`;
}

export function TimeCombobox({
  hour12,
  ...props
}: Omit<h.JSX.InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'value'> & {
  value: string;
  onChange(value: string): void;
  hour12: boolean;
}) {
  const times = useMemo(() => {
    return new Array(24).fill(0).flatMap((_, i) => {
      const hour = hour12 ? i % 12 || 12 : i;
      const ampm = hour12 ? (i >= 12 ? 'pm' : 'am') : '';
      return [`${hour}:00${ampm}`, `${hour}:30${ampm}`];
    });
  }, []);
  return (
    <div class="relative flex items-stretch">
      <Combobox
        wrapperClass="absolute inset-0"
        class="input bg-transparent border-transparent focus:border-indigo-600 absolute inset-0 rounded-md text-sm"
        values={times}
        onFocus={(e: any) => e.target.select()}
        {...props}
        onBlur={(e: any) => {
          props.onChange(parseTime(e.target.value, hour12));
        }}
      />
      <span class="inline-flex p-5 py-3 invisible border min-w-20">{props.value || '0'}</span>
    </div>
  );
}
