import { autoFocusSelf } from 'client/utils/autofocus';
import { JSX } from 'preact';

export type AutosizeProps = JSX.TextareaHTMLAttributes<HTMLTextAreaElement> & {
  containerClass?: string;
  minHeight?: string;
  focusSelf?: boolean;
};

export function AutosizeText(props: AutosizeProps) {
  return (
    <div
      class={`autosize ${props.containerClass} ${props.minHeight || 'min-h-6'} ${
        props.disabled ? 'opacity-75' : ''
      }`}
    >
      <textarea
        {...props}
        class={`${props.class || ''} autosize-textarea`}
        ref={props.focusSelf ? (el) => autoFocusSelf(el, (x) => x.focus()) : undefined}
      />
      <pre class={`${props.class} autosize-pre`}>
        {props.value}
        <br />
      </pre>
    </div>
  );
}
