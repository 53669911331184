import { BtnPrimary } from '@components/buttons';
import { defRoute, useRouteParams } from '@components/router';
import { rpx } from 'client/lib/rpx-client';
import { useEffect, useState } from 'preact/hooks';

export const route = defRoute({
  Page,
});

function Page() {
  const { id, courseId } = useRouteParams();
  const [error, setError] = useState<string | undefined>(undefined);

  async function mimic() {
    try {
      const { isStudent } = await rpx.admin.mimicUser({
        userId: id,
      });

      let redirectTo = isStudent ? '/courses' : '/manage/courses';
      if (courseId) {
        redirectTo = `/courses/${courseId}`;
      }

      window.location.href = redirectTo;
    } catch (err) {
      setError(err.message);
    }
  }

  async function tryAgain() {
    try {
      await rpx.admin.unmimicUser();
      await mimic();
    } catch (err) {
      setError(err.message);
    }
  }

  useEffect(() => {
    mimic();
  }, []);

  return (
    <div class="p-8">
      {!!error && <p class="mb-2 text-red-400">{error}</p>}
      <BtnPrimary onClick={tryAgain}>Stop Mimicking & Try Again</BtnPrimary>
    </div>
  );
}
