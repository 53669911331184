import { ModalForm, showModalForm } from '@components/modal-form';
import { store } from './state';
import { showToast } from '@components/toaster';
import { FormGroup } from '@components/async-form';
import { FolderRow } from 'server/types';

export function showNewFolderModal(props: {
  productId: string;
  onCreate(folder: FolderRow): void;
}) {
  return showModalForm(() => {
    return (
      <ModalForm
        title="Create folder"
        confirmButtonText="Create folder"
        onSubmit={async (data) => {
          const result = await store.createFolder({
            name: data.name,
            productId: props.productId,
          });
          props.onCreate(result);
          showToast({
            title: 'Folder created',
            message: `Created folder ${result.name}.`,
            type: 'ok',
          });
        }}
      >
        <FormGroup prop="name">
          <input class="ruz-input p-4 py-2 border" name="name" placeholder="Folder name" />
        </FormGroup>
      </ModalForm>
    );
  });
}
