import { TimeSlot } from 'server/types/cal-overrides';
import { summarizeAvailability } from 'shared/scheduling';

export type Props = {
  label: string;
  isDefault?: boolean;
  scheduleTimeZone: string;
  renderTimeZone: string;
  timeslots: TimeSlot[];
};

export function SummarizeAvailableTimes(
  props: Pick<Props, 'timeslots' | 'renderTimeZone' | 'scheduleTimeZone'>,
) {
  const availability = summarizeAvailability({
    timeslots: props.timeslots,
    hour12: true,
    scheduleTimeZone: props.scheduleTimeZone,
    renderTimeZone: props.renderTimeZone,
  });
  return (
    <span class="flex flex-col gap-2">
      {availability.map((x) => (
        <span key={`${x.days}-${x.times}`} class="flex gap-2">
          <span>{x.days}</span> <span>{x.times}</span>
        </span>
      ))}
    </span>
  );
}
