import { UserProfileIcon } from '@components/avatars';
import { BtnSecondary } from '@components/buttons';
import { useDocumentTitle } from 'client/utils/use-document-title';
import { ComponentChildren } from 'preact';
import { UserProfileRow } from 'server/types';

interface Props {
  title: string;
  subtitle?: ComponentChildren;
  button?: ComponentChildren;
  children?: ComponentChildren;
  guide?: Pick<UserProfileRow, 'email' | 'name' | 'profilePhotoUrl'>;
  onClick?(): void;
  toGradientColor?: string;
}

export function ErrorPage(props: Props) {
  const { guide } = props;
  useDocumentTitle([props.title]);

  return (
    <div
      class={`h-screen w-screen bg-linear-to-br from-indigo-400 ${
        props.toGradientColor || 'to-blue-500'
      } text-white flex items-center justify-center text-center flex-col`}
    >
      {props.button}
      {!props.button && (
        <BtnSecondary href="/" class="text-inherit hover:bg-transparent" onClick={props.onClick}>
          ← Back to home
        </BtnSecondary>
      )}
      <h1 class="text-6xl my-8 font-thin">{props.title}</h1>
      {props.subtitle && <p class="mb-6">{props.subtitle}</p>}

      {guide && (
        <div class="flex lg:flex-col items-center border rounded-lg relative px-8 py-4 m-4 lg:w-96">
          <div>
            <UserProfileIcon user={guide} size="w-20 h-20 lg:w-24 lg:h-24 text-2xl" />
          </div>
          <div class="ml-6 lg:ml-0 lg:mt-2">
            <div class="flex md:mb-1">
              <h2 class="text-lg font-bold lg:text-2xl mr-2">{guide.name}</h2>
            </div>
            <div class="mt-4">
              <BtnSecondary
                href={`mailto:${guide.email}`}
                class="text-inherit hover:bg-transparent"
              >
                Email guide
              </BtnSecondary>
            </div>
          </div>
        </div>
      )}
      {props.children}
    </div>
  );
}
