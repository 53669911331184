import { showDialog } from '@components/dialog';
import { rpx } from 'client/lib/rpx-client';
import { FullCourse } from 'server/types';
import { ERROR_CODES } from 'shared/consts';

export async function handleZoomIntegrationError({
  meetingId,
  course,
  err,
}: {
  meetingId?: UUID;
  course: Pick<FullCourse, 'id' | 'level'>;
  err: any;
}) {
  if (meetingId && err.data?.code === ERROR_CODES.zoomMeetingNotFound) {
    const isConfirmed = await showDialog({
      mode: 'warn',
      title: 'Zoom meeting not found',
      children:
        'This meeting is no longer available in your Zoom account. Do you want to delete it from Ruzuku?',
      confirmButtonText: 'Delete meeting',
    });
    if (isConfirmed) {
      await rpx.meetings.deleteMeeting({
        id: meetingId,
        courseId: course.id,
      });
      location.reload();
    }
    return true;
  }

  if (err.data?.code === ERROR_CODES.zoomNeedsRefresh) {
    if (course.level === 'facilitator') {
      showDialog({
        mode: 'warn',
        title: 'Zoom integration expired',
        children:
          'The connection to the Zoom account has expired. Please contact the course creator to re-integrate Zoom app under the account page.',
        hideCancel: true,
      });
      return true;
    }

    const result = await rpx.zoom.getZoomAccount();
    const isConfirmed = await showDialog({
      title: 'Zoom integration expired',
      children:
        'Our connection to your Zoom account has expired. Please re-integrate Zoom into your Ruzuku account by clicking the button.',
      mode: 'info',
      confirmButtonText: 'Re-integrate',
    });
    if (isConfirmed) {
      location.assign(result.oauth);
    }
    return true;
  }
  return false;
}
