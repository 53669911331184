import { showError } from '@components/app-error';
import { Button } from '@components/buttons';
import { PaymentType, PriceRow, PurchaseRow } from 'server/types';
import { rpx } from 'client/lib/rpx-client';
import { intl, useIntl } from 'shared/intl/use-intl';
import { showDialog } from '@components/dialog';

const store = rpx.subscriptionStatus;

export const planDescription = (paymentType: PaymentType) =>
  paymentType === 'paymentplan' ? intl('plan') : intl('subscription');

async function cancelPlan({
  purchase,
  price,
  courseId,
  asSeller,
}: {
  purchase: Pick<PurchaseRow, 'id'>;
  price: Pick<PriceRow, 'paymentType'>;
  courseId?: UUID;
  asSeller: boolean;
}) {
  const { paymentType } = price;
  const plan = planDescription(paymentType);
  const body = !courseId
    ? `You may resume this ${plan} at any time.`
    : asSeller
    ? intl(
        'You may resume this {plan:string} in the future by re-enrolling the student with the same price point they originally enrolled with and payments will resume from where they left off.',
        {
          plan,
        },
      )
    : intl('You can resume this {plan:string} in the future by re-enrolling, if you like.', {
        plan,
      });

  const confirmed = await showDialog({
    children: body,
    title: intl('Cancel {plan:string}', {
      plan: planDescription(paymentType),
    }),
    confirmButtonText: intl('Cancel {plan:string}', {
      plan,
    }),
    mode: 'warn',
    cancelButtonText: intl('Cancel'),
  });

  if (!confirmed) {
    return;
  }

  try {
    await store.cancelPurchase({ purchaseId: purchase.id, courseId });

    // Hacky way to reset all state
    location.reload();
  } catch (err) {
    showError(err);
  }
}

export function SubscriptionStatus(props: {
  purchase: Pick<PurchaseRow, 'id' | 'disableAccessWhenCanceled' | 'status'>;
  price: Pick<PriceRow, 'paymentType' | 'allowSelfCancel'>;
  courseId?: UUID;
  allowChanges: boolean;
  asSeller: boolean;
  guideEmail?: string;
}) {
  const { purchase, price, allowChanges, asSeller } = props;
  const { paymentType } = price;
  const { status } = purchase;
  const intl = useIntl();
  const isActiveSubscriptionLike = allowChanges && status === 'active';
  const mayCancel = paymentType === 'subscription' || asSeller || price.allowSelfCancel;
  const contactText = intl.split('<>Contact your guide</> for questions.');

  return (
    <div class="flex gap-2">
      <span>
        {intl('This {plan:string} is {status:string}.', {
          plan: planDescription(paymentType),
          status,
        })}
      </span>
      {!allowChanges && (
        <span>
          Contact{' '}
          <a href="mailto:billing@ruzuku.com" class="text-indigo-600 underline">
            billing
          </a>{' '}
          for details.
        </span>
      )}
      {isActiveSubscriptionLike && mayCancel && (
        <Button
          class="border border-red-200 text-red-500 rounded-sm px-2 hover:bg-red-50"
          onClick={() => cancelPlan(props)}
        >
          {intl('Cancel {plan:string}', {
            plan: planDescription(paymentType),
          })}
        </Button>
      )}
      {isActiveSubscriptionLike && !mayCancel && !!props.guideEmail && (
        <span>
          {contactText[0]} <a href={`mailto:${props.guideEmail}`}>{contactText[1]}</a>{' '}
          {contactText[2]}
        </span>
      )}
    </div>
  );
}
