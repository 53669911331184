import { ComponentChildren } from 'preact';
import { useRouteParams } from '@components/router';
import { rpx, RpxResponse } from 'client/lib/rpx-client';
import { PaginatedList } from '../pmts/components/paginated-list';
import { useEffect, useMemo, useState } from 'preact/hooks';
import { SearchBox } from '@components/search-box';
import { serialAsync } from 'client/utils/serial-async';
import { UserProfileIcon } from '@components/avatars';
import { LoadingIndicator } from '@components/loading-indicator';
import { BtnCopy, BtnSecondary, Button } from '@components/buttons';
import { IcoPencil, IcoRefresh } from '@components/icons';
import { ModalForm, showModalForm } from '@components/modal-form';
import { FormGroup } from '@components/async-form';
import { showToast } from '@components/toaster';
import { truncateId } from 'shared/utils';
import { showError } from '@components/app-error';
import { useTryAsyncData } from 'client/lib/hooks';
import { useCurrentTenant } from 'client/lib/auth';
import { DefaultSpinner } from '@components/spinner';
import { showDialog, StandardDialog } from '@components/dialog';

type ListState = RpxResponse<typeof store.getTenants> & {
  searchTerm: string;
  startingAfter?: string;
};

type TenantItem = ListState['data'][0];

interface Props {
  onItemSelect?: (tenant: ListState['data'][0]) => void;
}

const store = rpx.tenants;

function ListWrapper(props: { children: ComponentChildren }) {
  return (
    <table>
      <tr>
        <th>Domain</th>
        <th>Name / ID</th>
        <th>Owner</th>
      </tr>
      {props.children}
    </table>
  );
}

function BtnResetDomain({ tenantId, domain }: { tenantId: string; domain: string }) {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <BtnSecondary
      class="flex gap-1 items-center"
      isLoading={isLoading}
      onClick={async () => {
        try {
          setIsLoading(true);
          await rpx.tenants.resetTenantDomain({ tenantId, domain });
          showToast({
            type: 'ok',
            title: 'Domain reset',
            message: `Tenant ${tenantId} domain reset in Render.`,
          });
        } catch (err) {
          showError(err);
        } finally {
          setIsLoading(false);
        }
      }}
    >
      <IcoRefresh />
      <span>Reset Domain</span>
    </BtnSecondary>
  );
}

function ManageDomainsModal({
  tenant,
  onSave,
}: {
  tenant: TenantItem;
  onSave(tenant: TenantItem): void;
}) {
  const [domains, setDomains] = useState(() => {
    const uniqueDomains = new Set(
      [tenant.domain, ...(tenant.domains || [])].map((x) => x.toLowerCase()),
    );
    return Array.from(uniqueDomains).map((domain, localId) => ({
      localId,
      domain,
      isDefault: tenant.domain === domain,
      exists: true,
    }));
  });
  return (
    <ModalForm
      title={tenant.name}
      confirmButtonText="Apply Changes"
      onSubmit={async () => {
        const defaultDomain = domains.find((x) => x.isDefault)?.domain || '';
        const nonDefaultDomains = domains
          .filter((x) => !x.isDefault)
          .map((x) => x.domain.trim().toLowerCase())
          .filter((x) => !!x);
        await rpx.tenants.updateTenantDomains({
          tenantId: tenant.id,
          defaultDomain,
          domains: nonDefaultDomains,
        });

        onSave({
          ...tenant,
          domain: defaultDomain,
          domains: nonDefaultDomains,
        });
      }}
    >
      <div class="flex flex-col gap-4">
        <header class="font-semibold">Manage domains</header>
        {domains.map((x, i) => (
          <FormGroup key={x.localId} prop={`domain${i}`}>
            <div class="flex gap-2">
              <label class="flex grow flex-col gap-2">
                <input
                  class="ruz-input"
                  placeholder="hello.example.com"
                  type="text"
                  name="domain"
                  onInput={(e: any) =>
                    setDomains((s) =>
                      s.map((d) =>
                        d.localId === x.localId ? { ...d, domain: e.target.value } : d,
                      ),
                    )
                  }
                  value={x.domain}
                />
              </label>
              <label class="flex items-center rounded-sm px-3 gap-3">
                <input
                  name="isDefault"
                  checked={x.isDefault}
                  type="radio"
                  onClick={() =>
                    setDomains((s) => s.map((d) => ({ ...d, isDefault: d.localId === x.localId })))
                  }
                />
                Is default
              </label>
              <BtnResetDomain tenantId={tenant.id} domain={x.domain} />
            </div>
          </FormGroup>
        ))}
        <Button
          class="text-left text-indigo-600 px-3 rounded-sm"
          onClick={(e) => {
            const form = (e.target as HTMLElement).closest('form');
            setDomains((s) => [
              ...s,
              { localId: Date.now(), domain: '', isDefault: false, exists: false },
            ]);
            // Hacky way to set focus to the new domain input field
            setTimeout(() => {
              const inputs = form?.querySelectorAll<HTMLInputElement>('input[type=text]');
              inputs && inputs[inputs.length - 1].focus();
            });
          }}
        >
          Add Domain
        </Button>
      </div>
    </ModalForm>
  );
}

function SummarizeUser({ user }: { user: { name: string; email: string } }) {
  return (
    <span class="flex items-center gap-3 leading-5">
      <UserProfileIcon user={user} size="size-10" />
      <span class="flex flex-col">
        <span>{user.name}</span>
        <span class="text-gray-500 text-ellipsis overflow-hidden">{user.email}</span>
      </span>
    </span>
  );
}

function showChangeOwnerModal(tenant: ListState['data'][0]) {
  return showModalForm(({ resolve }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const currentTenant = useCurrentTenant();
    const users = useTryAsyncData(async () => {
      const result = await rpx.admin.getTenantUsers({
        tenantId: currentTenant.id,
        searchTerm,
        userLevels: ['superadmin', 'guide', 'admin'],
      });
      return result.users.slice(0, 10);
    }, [searchTerm]);

    const changeTenantOwner = async (user: NonNullable<typeof users.data>[0]) => {
      const shouldChange = await showDialog({
        mode: 'warn',
        title: `Change tenant owner?`,
        children: (
          <section>
            Are you sure you want to change the owner of <b>{tenant.domain}</b>
            <div class="flex flex-col gap-4 py-4">
              {tenant.owner && (
                <div class="flex flex-col gap-2 p-4 border rounded-md">
                  <b>From</b>
                  <SummarizeUser user={tenant.owner} />
                </div>
              )}
              <div class="flex flex-col gap-4 p-4 border rounded-md">
                <b>To</b>
                <SummarizeUser user={user} />
              </div>
            </div>
          </section>
        ),
        confirmButtonText: 'Yes, change tenant owner',
        cancelButtonText: 'No, keep the current owner',
      });
      if (!shouldChange) {
        return;
      }
      try {
        await rpx.admin.setTenantOwner({
          tenantId: tenant.id,
          ownerId: user.id,
        });
        location.reload();
      } catch (err) {
        showError(err);
      }
    };

    return (
      <StandardDialog onClose={resolve} title={<>Change owner of tenant: {tenant.name}</>}>
        <div class="flex flex-col gap-4">
          <SearchBox
            roundFull
            placeholder="Find a new tenant owner"
            onTermChange={setSearchTerm}
            focusOnce
          />
          {(!users.data || users.isLoading) && <DefaultSpinner />}
          {users.data && (
            <section class="flex flex-col rounded-2xl border overflow-hidden">
              {users.data.map((user) => (
                <Button
                  key={user.id}
                  class="flex gap-3 border-t first:border-t-0 p-4 text-left hover:bg-gray-50"
                  onClick={() => changeTenantOwner(user)}
                >
                  <SummarizeUser user={user} />
                </Button>
              ))}
            </section>
          )}
        </div>
      </StandardDialog>
    );
  });
}

export function TenantsList({ onItemSelect }: Props) {
  const { q } = useRouteParams();
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState<ListState>({
    data: [],
    hasMore: false,
    startingAfter: undefined,
    searchTerm: q || '',
  });

  const performSearch = useMemo(
    () =>
      serialAsync(
        async ({ startingAfter, searchTerm }: Pick<ListState, 'startingAfter' | 'searchTerm'>) => {
          setIsLoading(true);
          const result = await store.getTenants({
            searchTerm,
            startingAfter,
          });
          setState((s) => ({
            ...s,
            data: startingAfter ? [...s.data, ...result.data] : result.data,
            hasMore: result.hasMore,
          }));
          setIsLoading(false);
        },
      ),
    [setState],
  );

  useEffect(() => {
    performSearch({
      startingAfter: undefined,
      searchTerm: q || '',
    });
  }, []);

  return (
    <>
      {isLoading && <LoadingIndicator />}
      <header class="mb-4">
        <SearchBox
          containerClass="inline-flex"
          onTermChange={(searchTerm) => {
            setState((s) => ({ ...s, startingAfter: undefined, searchTerm }));
            performSearch({
              startingAfter: undefined,
              searchTerm,
            });
          }}
          placeholder="Find a tenant"
          value={state.searchTerm}
        />
      </header>
      <PaginatedList
        data={state.data}
        hasMore={state.hasMore}
        loadMore={async ({ startingAfter }) => {
          setState((s) => ({ ...s, startingAfter }));
          await performSearch({
            startingAfter,
            searchTerm: state.searchTerm,
          });
        }}
        RenderItem={(t) => (
          <tr
            key={t.id}
            class={`${onItemSelect ? 'hover:bg-indigo-50 cursor-pointer' : ''}`}
            onClick={() => onItemSelect?.(t)}
          >
            <td>
              <span class="flex items-center gap-4">
                <BtnSecondary
                  class="p-1"
                  onClick={() => {
                    showModalForm(() => (
                      <ManageDomainsModal
                        tenant={t}
                        onSave={(tenant) =>
                          setState((s) => ({
                            ...s,
                            data: s.data.map((x) => (x.id === tenant.id ? tenant : x)),
                          }))
                        }
                      />
                    ));
                  }}
                >
                  <IcoPencil />
                </BtnSecondary>
                <div class="flex flex-col gap-4">
                  <a
                    href={onItemSelect ? '' : `https://${t.domain}`}
                    class="overflow-hidden text-ellipsis flex gap-2"
                  >
                    <strong>{t.domain}</strong>
                    {t.domains?.length ? <span class="text-gray-500">(default)</span> : ''}
                  </a>
                  {t.domains?.map((domain) =>
                    domain === t.domain ? null : (
                      <a
                        href={onItemSelect ? '' : `https://${domain}`}
                        class="overflow-hidden text-ellipsis"
                        key={domain}
                      >
                        <strong>{domain}</strong>
                      </a>
                    ),
                  )}
                </div>
              </span>
            </td>
            <td>
              <span class="flex flex-col gap-2">
                <span>{t.name}</span>
                <span>
                  <BtnCopy class="border rounded-md" margin=" " value={t.id}>
                    ID: {truncateId(t.id)}
                  </BtnCopy>
                </span>
              </span>
            </td>
            <td>
              {!t.owner && !t.isCore && (
                <Button class="text-indigo-600 text-left" onClick={() => showChangeOwnerModal(t)}>
                  Add an owner
                </Button>
              )}
              {t.owner && (
                <>
                  <a href={`/admin/people/${t.owner.id}`} class="flex flex-col text-inherit">
                    <SummarizeUser user={t.owner} />
                  </a>
                  {!t.isCore && !onItemSelect && (
                    <Button
                      class="text-indigo-600 text-left pl-14 mt-2 -mx-1"
                      onClick={() => showChangeOwnerModal(t)}
                    >
                      Change owner
                    </Button>
                  )}
                </>
              )}
            </td>
          </tr>
        )}
        hasDisabled={false}
        wrapper={ListWrapper}
      />
    </>
  );
}
