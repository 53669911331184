/**
 * The testimonials section for the sales page builder.
 */
import { SubsectionDragProvider } from './subsection-drag-provider';
import { SubsectionEditor, SubsectionWrapper } from './subsection-toolbar';
import { EditorProps, SalesBlockInitialState, ViewerProps } from './types';
import { useIdGen } from './id-gen';
import { ImgProp, MultilineProp, RichProp } from './editable';
import { BtnAdd } from './btn-add';

interface Testimonial {
  id: number;
  src?: string;
  name: string;
  subtitle: string;
  quote: string;
}

interface State {
  richText: string;
  testimonials: Testimonial[];
}

function MiniTestimonial() {
  return (
    <span class="flex flex-col items-center justify-center">
      <span class="inline-block bg-gray-300 rounded-full w-4 h-4 mb-2"></span>
      <span class="inline-block bg-gray-400 rounded-sm w-1/4 h-1 mb-1"></span>
      <span class="inline-block bg-gray-300 rounded-sm w-3/4 h-1 mb-1"></span>
      <span class="inline-block bg-gray-300 rounded-sm w-1/2 h-1 mb-1"></span>
    </span>
  );
}

export const type = 'testimonials';

export const name = 'Testimonials';

export const initialBlockState: SalesBlockInitialState = {
  type,
  paddingt: 'md',
  paddingb: 'md',
};

export const initialState: State = {
  richText: `<h2>Some kind words from former students</h2><p>Here's what others are saying.</p>`,
  testimonials: [
    {
      id: 1,
      src: 'https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80',
      name: 'Fred Flinstone',
      subtitle: 'Lifelong learner',
      quote: '"Amazing!"',
    },
    {
      id: 2,
      src: 'https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=320&h=320&q=80',
      name: 'Lindsey Carter',
      subtitle: 'Singer, songwriter',
      quote: '"I went from knowing nothing to mastery in 3 months!"',
    },
    {
      id: 3,
      src: 'https://images.unsplash.com/photo-1562124638-724e13052daf?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8c21pbGV8ZW58MHwyfDB8fA%3D%3D&auto=format&fit=crop&w=256&h=256&q=80',
      name: 'Mike Tomson',
      subtitle: '',
      quote: `"This was a gamechanger. Highly recommend. 10/10"`,
    },
    {
      id: 4,
      src: 'https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3&w=256&h=256&q=80',
      name: 'Sammy Frantice',
      subtitle: 'Student, awesome guy',
      quote: `"It's a ton of fun, and you learn something new with every lesson."`,
    },
    {
      id: 5,
      src: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
      name: 'Lan Smith',
      subtitle: 'Barista, coffee snob',
      quote: `"Wow. All I can say is, you should buy this if you want to up your game!"`,
    },
    {
      id: 6,
      src: 'https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
      name: 'Sarah',
      subtitle: 'Entrepreneur',
      quote: '"I never knew what I was capable of!"',
    },
  ],
};

export function MiniView() {
  return (
    <span class="grid grid-cols-3">
      <MiniTestimonial />
      <MiniTestimonial />
      <MiniTestimonial />
    </span>
  );
}

export function Editor(props: EditorProps<State> | ViewerProps<State>) {
  const nextId = useIdGen(props.state.testimonials);
  const testimonialChunks: Array<Array<Testimonial & { i: number }>> = [[], [], []];
  const editProps = props as EditorProps<State>;
  const readonly = !editProps.setState;

  props.state.testimonials.forEach((t, i) => {
    testimonialChunks[i % testimonialChunks.length].push({ ...t, i });
  });

  return (
    <SubsectionDragProvider
      table={type}
      onReorder={(reorder) =>
        editProps.setState((s) => ({ ...s, testimonials: reorder(s.testimonials) }))
      }
    >
      <SubsectionWrapper>
        <RichProp
          {...props}
          path="richText"
          class="mb-10 text-lg text-center max-w-4xl w-full mx-auto"
          toolbarPosition="center"
        />
        <div class="grid lg:grid-cols-3 gap-6">
          {testimonialChunks.map((arr, chunk) => (
            <div
              class-x="flex flex-wrap flex-col md:flex-row lg:flex-col justify-center md:space-x-8 lg:space-x-0 space-y-8"
              class="flex flex-col space-y-6 sm:flex-row sm:space-y-0 sm:space-x-6 lg:space-x-0 lg:flex-col lg:space-y-6 justify-center"
              key={chunk}
            >
              {arr.map((t) => (
                <SubsectionEditor
                  key={t.id}
                  id={t.id}
                  disabled={readonly}
                  table={type}
                  onDelete={() =>
                    editProps.setState((s) => ({
                      ...s,
                      testimonials: s.testimonials.filter((x) => x.id !== t.id),
                    }))
                  }
                >
                  <div
                    class={`rounded-3xl shadow-lg p-8 ${
                      props.isBgLight ? 'bg-white border border-gray-100' : 'bg-black/30'
                    }`}
                  >
                    <p class="mb-6">
                      <MultilineProp {...props} path={['testimonials', t.i, 'quote']} />
                    </p>
                    <div class="flex items-center">
                      <ImgProp
                        {...props}
                        path={['testimonials', t.i, 'src']}
                        width="60"
                        class={`min-w-14 w-14 h-14 rounded-full mr-4 inline-flex items-center justify-center text-gray-400 ${
                          props.isBgLight ? 'bg-gray-100' : 'bg-gray-700'
                        }`}
                      />
                      <div class="flex flex-col grow">
                        <div class="font-medium">
                          <MultilineProp
                            {...props}
                            path={['testimonials', t.i, 'name']}
                            placeholder="Name"
                          />
                        </div>
                        <div class="opacity-75 text-sm">
                          <MultilineProp
                            {...props}
                            path={['testimonials', t.i, 'subtitle']}
                            placeholder="Title"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </SubsectionEditor>
              ))}
            </div>
          ))}
        </div>

        {editProps.isSelected && (
          <footer class="flex items-center justify-center w-full mt-8">
            <BtnAdd
              isBgLight={props.isBgLight}
              onClick={() => {
                editProps.setState((s) => ({
                  ...s,
                  testimonials: [
                    ...s.testimonials,
                    {
                      id: nextId(),
                      name: '',
                      quote: '',
                      subtitle: '',
                      src: '',
                    },
                  ],
                }));
              }}
            >
              + Add Testimonial
            </BtnAdd>
          </footer>
        )}
      </SubsectionWrapper>
    </SubsectionDragProvider>
  );
}

export const Viewer = Editor;
