/**
 * serialAsync converts an async function into an async function that
 * is guaranteed to only have one invocation running at a time.
 */
export function serialAsync<T extends (...args: any) => Promise<unknown>>(fn: T): T {
  let promise: undefined | Promise<unknown>;
  let next: undefined | (() => Promise<unknown>);

  const runFn = async () => {
    let result;
    while (next) {
      const resultPromise = next();
      next = undefined;
      result = await resultPromise;
    }
    promise = undefined;
    return result as T;
  };

  return ((...args: any) => {
    next = () => fn(...args);
    if (!promise) {
      promise = runFn();
    }
    return promise;
  }) as T;
}
