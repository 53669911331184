import { useEffect } from 'preact/hooks';
import { defRoute, useRouter } from '@components/router';
import { LoadingIndicator } from '@components/loading-indicator';
import { LAST_ROOT_PAGE_URL } from '@components/root-page-layout';

function RootRedirectPage() {
  const router = useRouter();
  useEffect(() => {
    const url = localStorage.getItem(LAST_ROOT_PAGE_URL);
    let redirectTo = url || '';
    // Redirect to /courses if no last root page URL is found
    if (!url || url === '/') {
      redirectTo = '/courses';
    }
    router.rewrite(redirectTo);
  }, []);

  return <LoadingIndicator />;
}

export const route = defRoute({
  Page: RootRedirectPage,
  authLevel: 'student',
});
