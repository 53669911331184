import { BtnSecondary } from '@components/buttons';
import { IcoCheck, IcoChevronDown, IcoChevronRight } from '@components/icons';
import { ComponentChildren } from 'preact';
import { useContext, useEffect, useRef } from 'preact/hooks';
import { CourseStep } from 'server/types';
import { ChecklistContext } from './index';

interface Props {
  title: string;
  stepKey: CourseStep;
  onSkip?: () => void;
  children?: ComponentChildren;
}

export function StepItem({ title, stepKey, onSkip, children }: Props) {
  const containerRef = useRef<HTMLDivElement>(null);
  const { step, setStep, completedSteps } = useContext(ChecklistContext);
  const isOpen = step === stepKey;
  const isCompleted = completedSteps.has(stepKey);

  useEffect(() => {
    if (isOpen && containerRef.current) {
      containerRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isOpen]);

  return (
    <div
      ref={containerRef}
      class={`${isOpen ? 'border shadow-xl m-2 mb-4 rounded-lg' : ''} ${
        step !== undefined && !isOpen ? 'blur-[1px]' : ''
      }`}
    >
      <div
        class={`flex items-center bg-gray-50 p-4 hover:bg-gray-100 cursor-pointer gap-4 z-10 ${
          isOpen ? 'sticky top-0' : ''
        }`}
        onClick={() => setStep(isOpen ? undefined : stepKey)}
      >
        <div
          class={`flex justify-center items-center rounded-full border min-w-8 h-8 ${
            isOpen && !isCompleted ? 'border-indigo-600' : 'border-gray-300'
          } ${isCompleted ? 'bg-green-600' : 'bg-gray-200'}`}
        >
          {isCompleted && <IcoCheck class="w-4 h-4 text-white" />}
        </div>
        <div class="grow">
          <p
            class={`text-base font-semibold decoration-green-600 ${
              isCompleted ? 'line-through text-green-600' : 'text-zinc-700'
            }`}
          >
            {title}
          </p>
        </div>
        <div>
          {isOpen && <IcoChevronDown class="w-5 h-5 opacity-75" />}
          {!isOpen && <IcoChevronRight class="w-5 h-5 opacity-75" />}
        </div>
      </div>
      {isOpen && (
        <div class="px-12 py-4 space-y-4 font-normal">
          {children}
          {!isCompleted && !!onSkip && (
            <BtnSecondary class="w-full" onClick={onSkip}>
              Skip this step
            </BtnSecondary>
          )}
        </div>
      )}
    </div>
  );
}
