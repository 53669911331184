/**
 * This file contains the password input component.
 */
import { JSX } from 'preact';
import { useRef, useState } from 'preact/hooks';
import { IcoShow, IcoHide } from '@components/icons';
import { autoFocusSelf } from 'client/utils/autofocus';
import { Button } from '@components/buttons';

type Props = JSX.InputHTMLAttributes<HTMLInputElement> & { wrapperClass?: string };

export function Password({
  name = 'password',
  placeholder = 'password',
  class: className = '',
  wrapperClass,
  autoFocus,
  autocomplete = 'off',
  ...props
}: Props) {
  const [isHidden, setIsHidden] = useState(true);
  const inputRef = useRef<HTMLInputElement | undefined>();

  return (
    <div class={`relative rounded-md ${wrapperClass}`}>
      <input
        {...props}
        autocomplete={isHidden ? autocomplete : 'off'}
        ref={(el) => {
          if (el) {
            inputRef.current = el;
          }
          if (autoFocus) {
            autoFocusSelf(el);
          }
        }}
        type={isHidden ? 'password' : 'text'}
        placeholder={placeholder}
        name={name}
        class={`${className} ruz-input block w-full pr-12 sm:text-sm sm:leading-5`}
        data-private
      />
      <Button
        tabIndex={-1}
        type="button"
        class="btn absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
        onClick={() => {
          setIsHidden(!isHidden);
          inputRef.current?.select();
        }}
      >
        <span class="text-gray-500 sm:text-sm sm:leading-5">
          {isHidden ? <IcoShow /> : <IcoHide />}
        </span>
      </Button>
    </div>
  );
}
