import { AutosizeText } from '@components/autosize-text';
import { EditorProps, ViewerProps } from './types';

export function TitleEditor<T extends { title: string }>(props: EditorProps<T>) {
  // If there is no title, and the current block is not the active block,
  // then we show an empty label, so that the block displays in WYSIWYG
  // fashion, but there is still a focusable element to tab into.
  if (!props.state.title && !props.isSelected) {
    return <label></label>;
  }

  return (
    <AutosizeText
      placeholder="Optional title"
      value={props.state.title}
      onInput={(e: any) => props.setState((s) => ({ ...s, title: e.target.value }))}
      containerClass="text-4xl mb-12"
      class="border-transparent placeholder-inherit rounded-sm border p-1 text-center"
    />
  );
}

export function TitleViewer<T extends { title: string }>(props: ViewerProps<T>) {
  if (!props.state.title) {
    return null;
  }

  return <h2 class="text-4xl mb-12 p-1 text-center">{props.state.title}</h2>;
}
