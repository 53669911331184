/**
 * LessonPane displays a little embedded pane that wraps togglable sections
 * such as discussions, downloads, quizzes, etc. The pane scrolls itself into
 * view if its state changes from hidden to visible.
 */

import { Button } from '@components/buttons';
import { IcoX } from '@components/icons';
import { useScrollTo } from 'client/lib/hooks';
import { ComponentChildren } from 'preact';

interface Props {
  title?: string;
  header?: ComponentChildren;
  isVisible: boolean;
  hide?(): void;
  children: ComponentChildren;
}

export function LessonPane(props: Props) {
  const { title, header } = props;
  const ref = useScrollTo(props.isVisible);

  return (
    <div
      ref={ref}
      class={`my-4 ${props.isVisible ? 'block' : 'hidden'} rounded-xl border relative z-10`}
    >
      <header class="flex justify-between items-center p-4 sticky top-0 z-20 bg-gray-50 border-b rounded-t-xl">
        {!header && title && <span class="block font-semibold capitalize">{props.title}</span>}
        {header}
        {props.hide && (
          <Button class="ml-2 rounded-sm" onClick={props.hide}>
            <IcoX class="w-4 h-4 opacity-75" />
          </Button>
        )}
      </header>
      <div class="p-2 md:p-4">{props.children}</div>
    </div>
  );
}
