import { useRouteDef } from '@components/router';
import { internationalize } from './index';
import { plugins } from './plugins';
import { globalConfig } from 'client/lib/auth';

const config = globalConfig();

export const intl = internationalize(
  config.translation || {
    phrases: {},
  },
  plugins,
  config.tenant.terminology,
);

export const defaultIntl = internationalize(
  {
    phrases: {},
  },
  plugins,
  config.tenant.terminology,
);

export function useIntl(alwaysUseStudentLocale = false) {
  const route = useRouteDef();
  if (route.isPublic || route.authLevel === 'student') {
    return intl;
  }

  if (alwaysUseStudentLocale) {
    return intl;
  }

  // Use English for non-public and non-student routes
  return defaultIntl;
}
