import { h } from 'minidoc-editor';
import { RenderOpts } from './media-uploader';
import { mediaContextMenu } from 'client/lib/minidoc';
import { getAlign, getContainerStyle, getSize, imageAlign, imageWidth } from 'shared/media';

// Render the image align or size select menu.
function selectMenu({ options, ...props }: any) {
  const result = h<HTMLSelectElement>(
    'select.mini-context-action.bg-transparent.border-none.rounded-lg',
    props,
    options,
  );

  if (props.value) {
    result.value = props.value.toString();
  }
  return result;
}

const getPercent = (opts: RenderOpts) => parseInt(getSize(opts));

/**
 * Apply the image size and alignment rules to the image container element.
 */
export function setContainerStyle(opts: RenderOpts) {
  const isMobile = window.innerWidth <= 768;
  Object.assign(
    opts.container.style,
    getContainerStyle({
      isMobile,
      state: opts.state,
    }),
  );
}

/**
 * Render the image context menu if the editor is ediable.
 */
export function imageContextMenu(opts: RenderOpts) {
  if (opts.readonly) {
    return;
  }

  function applyStyleChanges() {
    sizeMenu.value = getSize(opts);
    alignMenu.value = getAlign(opts);
    opts.stateChanged(opts.state);
    setContainerStyle(opts);
  }

  const sizeMenu = selectMenu({
    value: getSize(opts),
    oninput(e: any) {
      const size = e.target.value;
      opts.state.size = size;
      if (getPercent(opts) >= 100) {
        opts.state.align = 'center';
      }
      applyStyleChanges();
    },
    options: Object.keys(imageWidth)
      .reverse()
      .map((k) => h('option', { value: k }, `Width ${k}%`)),
  });

  const alignMenu = selectMenu({
    value: opts.state.align || 'center',
    oninput(e: any) {
      const align = e.target.value;
      opts.state.align = align;
      if (align !== 'center' && getPercent(opts) >= 100) {
        opts.state.size = '50';
      }
      applyStyleChanges();
    },
    options: Object.keys(imageAlign).map((k) => h('option', { value: k }, `Align ${k}`)),
  });

  return mediaContextMenu({ label: 'Image' }, sizeMenu, alignMenu);
}
