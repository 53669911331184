import dayjs from 'dayjs';

function unitsago(seconds: number, minUnit: number, maxUnit: number, unitName: string) {
  if (seconds >= maxUnit) {
    return;
  }
  const numUnits = Math.floor(seconds / minUnit);
  return `${numUnits}${unitName}`;
}

/**
 * Given a date, give a human-friendly description similar to the
 * timeago lib, but not live updating. We may consider just pulling
 * timeago in as an external dep.
 */
export function timeago(d: Date | string, suffix = '') {
  const seconds = Math.floor((Date.now() - new Date(d).getTime()) / 1000);
  const units = {
    second: 1,
    minute: 60,
    hour: 60 * 60,
    day: 60 * 60 * 24,
    week: 60 * 60 * 24 * 7,
    // This is imprecise, which is fine for our purposes
    month: 60 * 60 * 24 * 30,
    // This is also imprecise after 11 30-day periods, we'll call it a year.
    twelveMonths: 60 * 60 * 24 * 30 * 12,
    year: 60 * 60 * 24 * 365,
  };

  if (unitsago(seconds, units.second, units.minute, 's')) {
    return 'now';
  }

  const prefix =
    unitsago(seconds, units.minute, units.hour, 'm') ||
    unitsago(seconds, units.hour, units.day, 'h') ||
    unitsago(seconds, units.day, units.week, 'd') ||
    unitsago(seconds, units.week, units.month, 'w') ||
    unitsago(seconds, units.month, units.twelveMonths, 'mo') ||
    unitsago(seconds, units.twelveMonths, units.year, 'y') ||
    unitsago(seconds, units.year, Number.MAX_SAFE_INTEGER, 'y');

  return `${prefix}${suffix}`;
}

/**
 * Given a date, provide a short summary of it based on whether or
 * not the date is in the past.
 * Note: compareTo is purely for testing purposes.
 */
export function shortTimeAgo(dt?: Date, compareTo?: Date) {
  if (!dt) {
    return '';
  }

  const now = dayjs(compareTo);
  const date = dayjs(dt);
  const isThisYear = now.year() === date.year();

  // If the date is in the future, we'll show the date.
  if (date.isAfter(now)) {
    return date.format('MMM D' + (isThisYear ? '' : ', YYYY'));
  }

  // If the date is within a day of now, we'll just show the time.
  if (now.set('hour', 0).set('minute', 0).set('second', 0).isBefore(date)) {
    return date.format('h:mm A');
  }

  // If it's within this year, we'll show a short date
  if (isThisYear) {
    return date.format('MMM D');
  }

  // Otherwise, we'll show the month + year...
  return date.format('MMM YYYY');
}
