import { BtnPrimary } from '@components/buttons';
import {
  IcoArchiveBox,
  IcoCalendar,
  IcoChat,
  IcoCheckCircle,
  IcoUserCircle,
  IcoUserGroup,
  IcoVideoCamera,
} from '@components/icons';
import { Dispatch, StateUpdater } from 'preact/hooks';
import { RUZUKU_ASSETS_BASE_URL } from 'shared/consts';

type Props = {
  selectedItems: string[];
  setSelectedItems: Dispatch<StateUpdater<string[]>>;
  onContinue: () => void;
};

const GOAL_ITEMS = [
  {
    key: 'self-study-online-courses',
    title: 'Self-study online courses',
    description:
      'Structured courses with video, text, PDF that your customers can purchase and go through at their own pace. Optionally, could include discussions, quizzes, and student support.',
    icon: <IcoVideoCamera class="w-5 h-5" />,
  },
  {
    key: 'digital-products',
    title: 'Digital products',
    description:
      'Pure content offerings, such as eBooks, premium video packages, PDF workbooks, or audio recordings. No discussion, online community, or student interaction.',
    icon: <IcoArchiveBox class="w-5 h-5" />,
  },
  {
    key: 'cohort-based-online-courses',
    title: 'Cohort-based online courses',
    description:
      'Structured courses designed to enroll a group of participants on a set schedule — for example, an 8-week small-group mindfulness training. Can include live meetings (webinars), community, assignments and private chat.',
    icon: <IcoCalendar class="w-5 h-5" />,
  },
  {
    key: 'membership-program-or-community',
    title: 'Membership program or community',
    description:
      'Ongoing support through content, live meetings (webinars), and community — typically offered via a monthly or annual subscription.',
    icon: <IcoChat class="w-5 h-5" />,
  },
  {
    key: 'group-coaching',
    title: 'Group coaching',
    description:
      'Programs focused on coaching participants through a process rather than delivering a specific curriculum. Similar to cohort-based courses, these may include live meetings (webinars), community, assignments and private chat.',
    icon: <IcoUserGroup class="w-5 h-5" />,
  },
  {
    key: 'individual-coaching',
    title: 'Individual coaching',
    description: `Coach your private clients one-on-one. Provide access to assessments, templates and assignments in a secure space. Charge for access directly, or invite clients to join after they've paid you externally.`,
    icon: <IcoUserCircle class="w-5 h-5" />,
  },
];

export function GuideGoals({ selectedItems, setSelectedItems, onContinue }: Props) {
  return (
    <>
      <section class="mb-8">
        <header class="flex flex-col md:flex-row items-center gap-2">
          <img
            class="w-32 h-32 mr-4"
            data-attribution="https://undraw.co"
            src={`${RUZUKU_ASSETS_BASE_URL}/undraw_shared_goals_re_jvqd.svg`}
          />
          <div class="text-gray-600">
            <p>
              We’re excited to help you share your expertise, serve your clients and grow your
              revenue! Would you mind sharing a little bit about how you’re hoping to use Ruzuku?
              This will help us to get you started as quickly as possible :) You can select one or
              multiple options below…
            </p>
          </div>
        </header>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-2 mt-4">
          {GOAL_ITEMS.map((item) => {
            const isSelected = selectedItems.includes(item.key);
            return (
              <div
                key={item.key}
                class={`p-4 rounded-lg border-2 cursor-pointer relative ${
                  isSelected ? 'border-indigo-400' : 'border-gray-200'
                }`}
                onClick={() => {
                  setSelectedItems((prev) =>
                    prev.includes(item.key)
                      ? prev.filter((key) => key !== item.key)
                      : [...prev, item.key],
                  );
                }}
              >
                {isSelected && (
                  <IcoCheckCircle class="h-8 w-8 text-indigo-600 absolute right-4 top-4" />
                )}
                <h3 class="inline-flex items-start gap-2 font-bold text-lg leading-6">
                  <span class="pt-0.5">{item.icon}</span>
                  {item.title}
                </h3>
                <p class="text-gray-600 text-sm">{item.description}</p>
              </div>
            );
          })}
        </div>
      </section>
      <footer>
        <BtnPrimary class="rounded-full gap-3 pl-12 pr-10" onClick={onContinue}>
          <span>Continue</span>⤑
        </BtnPrimary>
      </footer>
    </>
  );
}
