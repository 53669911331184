import { MeetingType } from 'server/types';
import { useIntl } from 'shared/intl/use-intl';

export function useMeetingType() {
  const intl = useIntl();
  const meetingTypes: Record<MeetingType, string> = {
    zoom: '',
    external: intl('External'),
    presentation: intl('Presentation'),
    videoconference: intl('Video'),
  };
  return meetingTypes;
}
