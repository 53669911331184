import { ComponentChildren } from 'preact';
import { IcoChat, IcoCheck, IcoReply } from '@components/icons';
import { SelectedUser } from './index';
import { LessonCompleteFeedItem, CommentFeedItem } from 'server/types';
import { useRouteParams } from '@components/router';
import { Timeago } from '@components/timeago';
import { Attachments } from '@course/components/attachments';
import { URLS } from 'shared/urls';
import { ReadonlyMinidoc } from '@components/minidoc/readonly-minidoc';
import { useIntl } from 'shared/intl/use-intl';
import { useEffect, useState } from 'preact/hooks';
import { RpxResponse, rpx } from 'client/lib/rpx-client';
import { showError } from '@components/app-error';
import { Button } from '@components/buttons';

interface Props {
  user: SelectedUser;
}

interface FeedItemProps {
  title: ComponentChildren;
  href: string;
  Icon: typeof IcoChat | typeof IcoCheck;
  color: string;
  timestamp: ComponentChildren;
  children?: ComponentChildren;
  isLast: boolean;
}

function FeedItem({ title, href, children, color, timestamp, Icon, isLast }: FeedItemProps) {
  return (
    <div class="flex items-start pb-8 relative">
      {!isLast && (
        <span
          class="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-100 dark:bg-gray-700"
          aria-hidden="true"
        ></span>
      )}
      <div
        class={`w-8 h-8 mr-3 z-10 rounded-full flex items-center justify-center ring-8 ring-white dark:ring-gray-900 ${color}`}
      >
        <Icon class="w-5 h-5 text-white" />
      </div>
      <div class="flex-1 min-w-0 mt-1.5 text-sm leading-6 font-normal">
        <header class="flex justify-between">
          <a class="text-sm text-gray-500 dark:text-gray-400" href={href}>
            {title}
          </a>
          <span class="pl-4 text-sm text-gray-500 dark:text-gray-400 whitespace-nowrap">
            {timestamp}
          </span>
        </header>
        <div class="mt-2 text-gray-500 dark:text-gray-400">{children}</div>
      </div>
    </div>
  );
}

function LessonCompleteItem({ item, isLast }: { item: LessonCompleteFeedItem; isLast: boolean }) {
  const { courseId, courseSlug } = useRouteParams();

  return (
    <FeedItem
      isLast={isLast}
      title={
        <span>
          {'Completed lesson '}
          <span class="font-medium text-gray-900 dark:text-gray-200">"{item.lesson.title}"</span>
        </span>
      }
      timestamp={<Timeago date={item.date} />}
      href={URLS.student.lesson({
        course: {
          id: courseId,
          title: courseSlug,
        },
        lesson: item.lesson,
      })}
      color="bg-green-500"
      Icon={IcoCheck}
    />
  );
}

function CommentItem({
  item,
  user,
  isLast,
}: {
  item: CommentFeedItem;
  user: Props['user'];
  isLast: boolean;
}) {
  const intl = useIntl();
  const { courseId, courseSlug } = useRouteParams();
  const titleLocation = item.prompt ? `"${item.prompt}"` : `"${item.lesson.title}"`;

  return (
    <FeedItem
      isLast={isLast}
      title={
        <span>
          {item.parentCommentId ? intl('Replied to a comment on') : intl('Commented on')}{' '}
          <span class="font-medium text-gray-900 dark:text-gray-200">{titleLocation}</span>
        </span>
      }
      timestamp={<Timeago date={item.date} />}
      href={URLS.student.comment({
        course: {
          id: courseId,
          title: courseSlug,
        },
        lesson: item.lesson,
        commentId: item.commentId,
        parentCommentId: item.parentCommentId,
      })}
      Icon={item.parentCommentId ? IcoReply : IcoChat}
      color={
        item.parentCommentId ? 'bg-blue-400 dark:bg-blue-800' : 'bg-indigo-400 dark:bg-indigo-800'
      }
    >
      <Attachments
        attachments={item.attachments}
        user={user}
        timestamp={item.date}
        allowFullScreen
      />
      <ReadonlyMinidoc id={item.commentId} content={item.content} />
    </FeedItem>
  );
}

type SubmissionFeed = RpxResponse<typeof rpx.members.getMemberFeed>;

export function MemberFeed({ user }: Props) {
  const intl = useIntl();
  const { courseId } = useRouteParams();
  const [isLoading, setIsLoading] = useState(true);
  const [feed, setFeed] = useState<SubmissionFeed>({
    items: [],
    hasMore: true,
    cursor: '',
  });

  async function loadNextPage() {
    setIsLoading(true);
    try {
      const result = await rpx.members.getMemberFeed({
        courseId,
        userId: user.id,
        cursor: feed.cursor,
      });
      setFeed({
        items: feed.items.concat(result.items),
        hasMore: result.hasMore,
        cursor: result.cursor,
      });
    } catch (e) {
      showError(e);
    } finally {
      setIsLoading(false);
    }
  }

  // Load the first page
  useEffect(() => {
    loadNextPage();
  }, []);

  return (
    <div class="text-gray-900 dark:text-gray-200">
      <h2 class="text-lg font-medium mb-6">{intl('Recent activity')}</h2>
      {!isLoading && feed.items.length === 0 && <p class="p-6">{intl('No recent activity')}</p>}
      {feed.items.map((item, i) =>
        item.type === 'comment' ? (
          <CommentItem
            key={item.commentId}
            item={item}
            user={user}
            isLast={i === feed.items.length - 1}
          />
        ) : (
          <LessonCompleteItem
            key={item.lesson.id}
            item={item}
            isLast={i === feed.items.length - 1}
          />
        ),
      )}
      {feed.hasMore && (
        <Button
          class="text-indigo-600 outline-hidden focus:ring-2 focus:ring-indigo-400 ml-2"
          spinnerClass="border-indigo-800"
          isLoading={isLoading}
          onClick={loadNextPage}
        >
          {intl('View More')}
        </Button>
      )}
    </div>
  );
}
