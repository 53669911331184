/**
 * Colors used in the sales page builder. Names are tailwindish
 * for example, `indigo700` is `bg-indigo-700`
 */

export const Color = {
  indigo700: '#4338CA',
  indigo500: '#6366F1',
  gray50: '#f8fafc',
  gray200: '#e2e8f0',
  gray800: '#1e293b',
  teal400: '#24b8a6',
  white: '#FFFFFF',
};

export function isLight(bgcolor?: string) {
  // Based on this: https://awik.io/determine-color-bright-dark-using-javascript/
  if (!bgcolor) {
    return true;
  }
  let r = 0;
  let g = 0;
  let b = 0;

  if (bgcolor.startsWith('#')) {
    // Remove the leading #
    bgcolor = bgcolor.slice(1);
    // If it's a #ABC color, convert to #AABBCC
    bgcolor = bgcolor.length < 4 ? bgcolor.replace(/./g, '$&$&') : bgcolor;
    // Covert it to a number
    const color = +('0x' + bgcolor);

    // Extract the goods
    r = color >> 16;
    g = (color >> 8) & 255;
    b = color & 255;
  } else if (bgcolor.startsWith('rgb')) {
    [r, g, b] = (bgcolor.match(/\d+/g) || []).map((s) => +s);
  } else {
    return true;
  }

  if (r === undefined) {
    return true;
  }

  // From http://alienryderflex.com/hsp.html
  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  const lightThreshold = 150;
  return hsp > lightThreshold;
}

/**
 * Given a CSS background color, return a light or dark color that can be used as the text
 * color.
 */
export function autoColor(bgcolor?: string) {
  return isLight(bgcolor) ? Color.gray800 : Color.gray50;
}
