/**
 * Modules have special scheduling concerns based on
 * whether or not the course is absolutely scheduled.
 * This file contains service functions that perform
 * date transforms when reading modules from the
 * server and when writing them to the server so
 * we avoid bugs such as #5354.
 */

import { toISOString, toLocalDate, toNullableDate, toUTCDate } from 'shared/dateutil';
import { rpx } from 'client/lib/rpx-client';

type ScheduleParams = {
  isAbsoluteSchedule: boolean | undefined;
  startDate?: string | Date;
};

type CreateParams = Omit<Parameters<typeof rpx.modules.createModule>[0], 'startDate'> &
  ScheduleParams;

type UpdateParams = Omit<Parameters<typeof rpx.modules.updateModule>[0], 'startDate'> &
  ScheduleParams;

type ReorderParams = Omit<Parameters<typeof rpx.modules.reorderModules>[0], 'startDate'> &
  ScheduleParams;

function adjustStartDate<T extends ScheduleParams>({
  isAbsoluteSchedule,
  startDate,
  ...params
}: T): Omit<T, 'isAbsoluteSchedule' | 'startDate'> & { startDate?: string | undefined } {
  return {
    ...params,
    startDate: isAbsoluteSchedule ? toISOString(startDate) : toUTCDate(startDate),
  };
}

export function createModule(params: CreateParams) {
  return rpx.modules.createModule(adjustStartDate(params));
}

export function updateModule(params: UpdateParams) {
  return rpx.modules.updateModule(adjustStartDate(params));
}

export function reorderModules(params: ReorderParams) {
  return rpx.modules.reorderModules(adjustStartDate(params));
}

export function fixupModule<T extends ScheduleParams>({
  isAbsoluteSchedule,
  startDate,
  ...module
}: T): Omit<T, 'isAbsoluteSchedule' | 'startDate'> & { startDate: Date | undefined } {
  return {
    ...module,
    startDate: isAbsoluteSchedule ? toNullableDate(startDate) : toLocalDate(startDate),
  };
}
