import { useMemo } from 'preact/hooks';

/**
 * Create an animator helper that adds / removes a CSS class from the referenced
 * element based on the classname returned by fn.
 * @param fn Return an animation class based on the change in value over time.
 * @param ms The number of miliseconds the animation will run.
 * @param initialValue The value whose changes will be tracked over time to determine the animation.
 */
export function useAnimator<T>(
  fn: (val: T, prev?: T) => string | undefined,
  ms: number,
  initialValue: T,
) {
  const result = useMemo(() => {
    let el: Element | undefined;
    let timeout: any;
    let prev: T | undefined = initialValue;

    return {
      ref(val: Element | null) {
        if (val) {
          el = val;
        }
      },
      change(val: T) {
        try {
          if (!el || val === prev) {
            return;
          }
          const className = fn(val, prev);
          if (!className) {
            return;
          }
          clearTimeout(timeout);
          el.classList.add(className);
          timeout = setTimeout(() => el?.classList.remove(className), ms);
        } finally {
          prev = val;
        }
      },
    };
  }, []);

  result.change(initialValue);

  return result;
}
