import { render } from 'preact-render-to-string';
import { BasicEmailWrapper, DoubleLine, Heading } from './shared';

/**
 * This is the template for the email we send to support when a tenant's domain
 * has failed to validate more than N times.
 */
export function DomainFailed({ domain, id }: { domain: string; id: string }) {
  return render(
    <BasicEmailWrapper footer={null}>
      <Heading>A tenant's domain failed to validate.</Heading>
      <DoubleLine>
        The domain <em>{domain}</em> did not provide a valid response.
      </DoubleLine>
      <DoubleLine>
        You can{' '}
        <a href={`https://courses.ruzuku.com/admin/tenants?q=${id}`}>view the tenant in Ruzuku</a>
        {' or '}
        <a href="https://dashboard.render.com/web/srv-c52b3m9g7hpdkgp2f62g/settings">
          manage domains in Render
        </a>
        .
      </DoubleLine>
    </BasicEmailWrapper>,
  );
}
