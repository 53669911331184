import {
  CaptionButton,
  FullscreenButton,
  isTrackCaptionKind,
  MuteButton,
  PIPButton,
  PlayButton,
  useMediaState,
} from '@vidstack/react';
import { IcoDownload, IcoFullSize } from '@components/icons';
import { useIntl } from 'shared/intl/use-intl';
import { Dispatch, StateUpdater } from 'preact/hooks';
import {
  IcoClosedCaptions,
  IcoClosedCaptionsOn,
  IcoFullScreenExit,
  IcoMute,
  IcoPause,
  IcoPictureInPicture,
  IcoPictureInPictureExit,
  IcoPlay,
  IcoTranscript,
  IcoVolumeHigh,
  IcoVolumeLow,
} from '@components/icons/video-player';
import { Button } from '@components/buttons';

export function Play() {
  const intl = useIntl();
  const isPaused = useMediaState('paused');

  return (
    <PlayButton class="vidstack-btn" data-tooltip={isPaused ? intl('Play') : intl('Pause')}>
      {isPaused ? <IcoPlay /> : <IcoPause />}
    </PlayButton>
  );
}

export function Mute(props: { onMouseOver(): void }) {
  const intl = useIntl();
  const volume = useMediaState('volume');
  const isMuted = useMediaState('muted');

  return (
    <MuteButton
      class="vidstack-btn"
      data-tooltip={isMuted ? intl('Unmute') : intl('Mute')}
      onMouseOver={props.onMouseOver}
    >
      {isMuted || volume == 0 ? <IcoMute /> : volume < 0.5 ? <IcoVolumeLow /> : <IcoVolumeHigh />}
    </MuteButton>
  );
}

export function Caption() {
  const intl = useIntl();
  const track = useMediaState('textTrack');
  const isOn = track && isTrackCaptionKind(track);

  return (
    <CaptionButton
      class={`vidstack-btn hidden md:inline-flex`}
      data-tooltip={isOn ? intl('Closed-Captions Off') : intl('Closed-Captions On')}
    >
      {isOn ? <IcoClosedCaptionsOn class="w-6 h-6 text-sky-300" /> : <IcoClosedCaptions />}
    </CaptionButton>
  );
}

export function PIP() {
  const intl = useIntl();
  const isActive = useMediaState('pictureInPicture');

  return (
    <PIPButton class="vidstack-btn" data-tooltip={isActive ? intl('Exit PIP') : intl('Enter PIP')}>
      {isActive ? <IcoPictureInPictureExit /> : <IcoPictureInPicture />}
    </PIPButton>
  );
}

export function Fullscreen() {
  const intl = useIntl();
  const isActive = useMediaState('fullscreen');

  return (
    <FullscreenButton
      class="vidstack-btn"
      data-tooltip={isActive ? intl('Exit Fullscreen') : intl('Enter Fullscreen')}
      data-tooltip-x="left"
    >
      {isActive ? <IcoFullScreenExit /> : <IcoFullSize class="h-5 w-5" />}
    </FullscreenButton>
  );
}

export function Download({ downloadUrl }: { downloadUrl: string }) {
  const intl = useIntl();

  return (
    <a
      class={`vidstack-btn text-inherit`}
      href={downloadUrl}
      target="_blank"
      rel="noopener noreferrer"
      data-tooltip={intl('Download')}
      download
    >
      <IcoDownload class="w-6 h-6" />
    </a>
  );
}

export function Transcript({
  showTranscript,
  setShowTranscript,
}: {
  showTranscript: boolean;
  setShowTranscript: Dispatch<StateUpdater<boolean>>;
}) {
  const intl = useIntl();

  return (
    <Button
      class="vidstack-btn"
      type="button"
      data-tooltip={showTranscript ? intl('Hide Transcript') : intl('Show Transcript')}
      onClick={() => {
        setShowTranscript((v) => !v);
      }}
    >
      <IcoTranscript class={`w-6 h-6 ${showTranscript ? 'text-sky-300' : ''}`} />
    </Button>
  );
}
