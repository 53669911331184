/**
 * Generate a unique local id. These are all negative. Postgres ids are all
 * positive, so we can easily distinguish between generated (unsaved) ids
 * and server-provided ids.
 */
export const genId = (() => {
  let id = 0;
  return function _genId() {
    return --id;
  };
})();
