import { Dispatch, StateUpdater, useState } from 'preact/hooks';

/**
 * A useState-like function that persists changes to localStorage.
 */
export function useLocalStorageState<T>(
  key: string,
  initialState: T | (() => T),
): [T, Dispatch<StateUpdater<T>>] {
  const [state, setState] = useState(() => {
    const storedVal = localStorage.getItem(key);
    if (storedVal) {
      try {
        return JSON.parse(storedVal);
      } catch (err) {
        console.error(err);
      }
    }
    return typeof initialState === 'function' ? (initialState as any)() : initialState;
  });

  const setAndStore: typeof setState = (newState) =>
    setState((x: T) => {
      let result: any;
      if (typeof newState === 'function') {
        result = newState(x);
      } else {
        result = newState;
      }
      if (result === undefined) {
        localStorage.removeItem(key);
      } else {
        localStorage.setItem(key, JSON.stringify(result));
      }
      return result;
    });

  return [state, setAndStore];
}
