/**
 * This file contains the UI for editing a course's coupons.
 */

import { courseCoupons, URLS } from 'shared/urls';
import { rpx } from 'client/lib/rpx-client';
import { GuideCoursePage, GuideProductPage } from '@course/components/guide-course-page';
import { ManageCouponsPage } from './components/coupons-page';
import { urls as manageStudents } from '../guide-course-students/urls';
import { LoadedProps, RouteLoadProps } from '@components/router';
import { defCoursesRoute } from '@course/components/courses-app-router';

const store = rpx.coupons;

export const route = defCoursesRoute({
  load,
  Page,
  authLevel: 'guide',
});

/**
 * Load the initial page of coupons from the server. If no coupon was requested
 * in the URL, we'll do a rewrite and show the first coupon in the resultset.
 */
async function load(route: RouteLoadProps) {
  const { courseId } = route.params;
  const result = await store.initForCourse({
    courseId,
  });
  if (result.course.level !== 'guide') {
    route.router.goto(URLS.guide.lessons({ courseId }));
  }
  return result;
}

function PageContent(props: LoadedProps<typeof load>) {
  const courseId = props.state.course.id;
  const baseUrl = URLS.guide.baseUrl(props.state.course);
  const couponsUrl = `${baseUrl}/coupons`;

  return (
    <ManageCouponsPage
      {...props.state}
      courseId={courseId}
      supportsStripe={props.state.supportsStripe}
      supportsPaypal={props.state.supportsPaypal}
      isCoreCoupon={false}
      couponUrl={({ couponId }) => `${couponsUrl}/${couponId}`}
      priceUrl={({ priceId }) => `${baseUrl}/prices/${priceId}`}
      checkoutUrl={({ couponId, priceId }) =>
        courseCoupons.checkoutUrl({
          courseId,
          couponId,
          priceId,
          isProduct: props.state.course.isProduct,
        })
      }
      couponsUrl={couponsUrl}
      newUrl={`${couponsUrl}/new`}
      signupUrl={({ userId }) =>
        manageStudents.showStudent({
          userId,
          courseId,
          baseUrl: props.state.course.isProduct
            ? manageStudents.productsBaseUrl
            : manageStudents.coursesBaseUrl,
        })
      }
    />
  );
}

function Page(props: LoadedProps<typeof load>) {
  if (props.state.course.isProduct) {
    return (
      <GuideProductPage product={props.state.course} page="coupons">
        <PageContent {...props} />
      </GuideProductPage>
    );
  }
  return (
    <GuideCoursePage course={props.state.course} type="coupons">
      <PageContent {...props} />
    </GuideCoursePage>
  );
}
