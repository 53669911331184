import { JSX } from 'preact';
import { Dispatch, StateUpdater } from 'preact/hooks';
import { SalesBlockState } from 'server/types';

interface BaseProps<T> {
  class?: string;
  bgcolor?: string;
  fgcolor?: string;
  isBgLight: boolean;
  state: T;
}

export interface ViewerProps<T> extends BaseProps<T> {
  setState?: undefined;
}

export interface EditorProps<T> extends BaseProps<T> {
  isSelected: boolean;
  class?: string;
  setState: Dispatch<StateUpdater<T>>;
}

export interface SalesBlockDefinition<TState = any> {
  initialState: TState;

  /**
   * If specified, is used to set the block state when adding a new block.
   */
  initialBlockState?: SalesBlockInitialState<TState>;

  /**
   * A unique name for the sales block.
   */
  type: string;

  /**
   * The human-friendly display name.
   */
  name: string;

  /**
   * Render a miniature representation / icon of the component.
   */
  MiniView(): JSX.Element;

  /**
   * If defined, this will be called before deleting the block, so the block
   * can prompt the user, do any cleanup, etc. If it returns false, the block
   * will not be deleted. Any other return value will result in deletion.
   */
  onDelete?(props: { state: TState }): Promise<boolean | undefined>;

  /**
   * Render the block in editor mode.
   */
  Editor(props: EditorProps<TState>): JSX.Element | null;

  /**
   * Render the block in viewer mode.
   */
  Viewer(props: ViewerProps<TState>): JSX.Element | null;

  /**
   * Render the block-specific settings panel, if any.
   */
  SettingsPane?(props: EditorProps<TState>): JSX.Element | null;
}

export type SalesBlockInitialState<TState = any> = Omit<SalesBlockState<TState>, 'id' | 'state'>;
