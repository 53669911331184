import { Subtext } from '@components/async-form';
import { HeadingPrimary } from '@course/components/headings';
import { GuideCoursePage } from '@course/components/guide-course-page';
import { CourseProfileFieldsForm } from '@course/components/manage-course-profile-fields';
import { LoadedProps, RouteLoadProps } from '@components/router';
import { rpx } from 'client/lib/rpx-client';
import { defCoursesRoute } from '@course/components/courses-app-router';

export const route = defCoursesRoute({
  load,
  Page,
  authLevel: 'guide',
});

async function load(route: RouteLoadProps) {
  const course = await rpx.courses.getGuideCourse({ id: route.params.courseId });
  return { course };
}

export function Page({ state: { course } }: LoadedProps<typeof load>) {
  return (
    <GuideCoursePage course={course} type="profilefields">
      <div class="flex flex-col w-full max-w-7xl mx-auto p-8 gap-6">
        <div>
          <HeadingPrimary title="Profile fields" />
          <Subtext>Select custom profile fields that your students can fill out.</Subtext>
        </div>
        <CourseProfileFieldsForm courseId={course.id} />
      </div>
    </GuideCoursePage>
  );
}
