import { ComponentChildren } from 'preact';

interface Props {
  title: string;
  isNormalCase?: boolean;
  children?: ComponentChildren;
}

export function NavSectionHeader(props: Props) {
  return (
    <header class="flex justify-between items-center py-2">
      <h2
        class={`text-gray-500 font-bold  ${props.isNormalCase ? 'text-sm' : 'uppercase text-xs'}`}
      >
        {props.title}
      </h2>
      <nav class="ml-2 leading-tight">{props.children}</nav>
    </header>
  );
}

export function NavSectionSubheader(props: Props) {
  return <NavSectionHeader {...props} isNormalCase />;
}
