import { Comment } from 'server/types';
import { useEffect, useState } from 'preact/hooks';
import { Case } from '@components/conditional';
import { Button } from '@components/buttons';
import { CommentEditor } from './comment-editor';
import { CommentItem } from './comment-item';
import { IcoChevronRight, IcoReply } from '@components/icons';
import { on } from 'minidoc-editor';
import { useIntl } from 'shared/intl/use-intl';
import { rpx } from 'client/lib/rpx-client';
import { State } from './state';

const store = rpx.comments;

interface Props {
  state: State;
  courseId: UUID;
  comment: Comment;
  highlightedComment?: UUID;
  onDeleted(id: UUID): void;
  onLiked(id: UUID, liked: boolean): void;
  onLoad(comments: Comment[]): void;
  onSaved(comment: Comment): void;
}

const REPLY_PAGE_SIZE = 25;

export function RootComment({ comment, state, onLoad, ...props }: Props) {
  const intl = useIntl();
  const [highlight, setHighlight] = useState(props.highlightedComment === comment.id);
  const [isLoading, setIsLoading] = useState(false);
  const [isReplyFormActive, setIsReplyFormActive] = useState(false);

  const { replyIds } = comment;
  const loadedReplyIds = replyIds?.filter((id) => !!state.comments[id]) || [];
  const totalRepliesCount = replyIds?.length || 0;

  async function loadEarlierReplies() {
    if (!replyIds) {
      return;
    }

    const repliesToLoad = [...replyIds]
      .reverse()
      .filter((id) => !loadedReplyIds.includes(id))
      .slice(0, REPLY_PAGE_SIZE);

    setIsLoading(true);
    try {
      const result = await store.getCommentsWithIds({
        discussionId: state.discussionId,
        ids: repliesToLoad,
      });
      onLoad(result);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    // Remove the highlight when the user clicks anywhere.
    if (!highlight) {
      return;
    }
    const removeHighlight = () => setHighlight(false);
    const offs = [
      on(document, 'mousedown', removeHighlight),
      on(document, 'touchstart', removeHighlight),
    ];
    return () => offs.forEach((f) => f());
  }, [highlight]);

  // Replies should never be rendered as a RootComment
  // so it's possibly a data error if this is happening
  if (comment.parentId) {
    return null;
  }

  return (
    <div class="js-root-comment relative border bg-gray-50/75 dark:border-none mb-8 -mx-6 py-8 p-4 sm:p-8 sm:mx-0 sm:rounded-2xl dark:bg-gray-800">
      <div
        class={`relative ${
          highlight ? 'border-2 border-indigo-500 shadow-2xl rounded-2xl p-4 -mx-4 mb-10' : 'mb-8'
        }`}
      >
        <CommentItem
          courseId={props.courseId}
          discussionId={state.discussionId}
          comment={comment}
          highlightedComment={props.highlightedComment}
          guideId={state.guideId}
          hidePeople={state.hidePeople}
          onDeleted={props.onDeleted}
          onLiked={props.onLiked}
          onSaved={props.onSaved}
        />
      </div>
      <div class="dark:border-gray-700 ml-10">
        <Case when={totalRepliesCount > 0 && loadedReplyIds.length < totalRepliesCount}>
          <Button
            class="flex items-center w-full border dark:border-none rounded-2xl p-3 mb-8 theme-link text-left text-indigo-600 dark:bg-gray-700 dark:text-gray-200 dark:hover:text-white hover:bg-white dark:hover:bg-gray-600"
            isLoading={isLoading}
            onClick={loadEarlierReplies}
          >
            <IcoChevronRight />
            <span class="ml-2">
              {loadedReplyIds.length > 0 ? intl('Show earlier replies') : intl('Show Replies')}
            </span>
          </Button>
        </Case>

        {loadedReplyIds.length > 0 && (
          <section class="flex flex-col gap-y-8 mb-8">
            {loadedReplyIds.map((id) => (
              <CommentItem
                discussionId={state.discussionId}
                courseId={props.courseId}
                key={id}
                comment={state.comments[id]}
                highlightedComment={props.highlightedComment}
                parent={comment}
                guideId={state.guideId}
                hidePeople={state.hidePeople}
                onDeleted={props.onDeleted}
                onLiked={props.onLiked}
                onSaved={props.onSaved}
              />
            ))}
          </section>
        )}

        {!isReplyFormActive && (
          <footer>
            <Button
              class="flex border hover:bg-white dark:bg-gray-700 dark:border-none rounded-xl p-3 theme-link items-center w-full relative text-indigo-600 dark:hover:text-white dark:hover:bg-gray-600 dark:text-gray-200 group"
              onClick={() => setIsReplyFormActive(true)}
            >
              <IcoReply />
              <span class="ml-2">{intl('Write a reply')}</span>
            </Button>
          </footer>
        )}
      </div>
      {isReplyFormActive && (
        <CommentEditor
          courseId={props.courseId}
          discussionId={state.discussionId}
          class="rounded-xl bg-gray-50 dark:bg-gray-800 p-4 shadow-xl"
          parent={comment}
          buttonTitle={intl('Reply')}
          onHide={() => setIsReplyFormActive(false)}
          onSaved={(comment) => props.onSaved(comment)}
        />
      )}
    </div>
  );
}
