/**
 * The secondary nav bar for the meeting screens.
 */

import { BtnPrimary } from '@components/buttons';
import { meetingUrl } from './urls';
import {
  PageNavBar,
  PageNavBody,
  PageNavLink,
  PageNavSegment,
} from '@course/components/page-nav-bar';
import { Meeting } from 'server/types';
import { fmtFullTime } from 'shared/dateutil';
import { useRouteParams } from '@components/router';
import { Pill } from '@components/pill';
import { MeetingTypeIcons } from '../student-meetings/nav-bar';
import { useCurrentTenant } from '@components/router/session-context';
import { useMeetingStatus } from 'client/lib/hooks/use-meeting-status';

interface Props {
  draft: Meeting[];
  upcoming: Meeting[];
  past: Meeting[];
}

function Item({ meeting, isPast }: { meeting: Meeting; isPast: boolean }) {
  const { courseId, meetingId } = useRouteParams();
  const status = useMeetingStatus(meeting);

  return (
    <PageNavLink
      isSelected={meeting.id === meetingId}
      href={meetingUrl({ courseId, meetingId: meeting.id })}
    >
      <span class="flex grow py-2 gap-3">
        <span class="pt-1">{MeetingTypeIcons[meeting.type]}</span>
        <span class="flex grow flex-col">
          <strong class="flex justify-between gap-2">
            {meeting.title}
            {!isPast && status === 'inProgress' && <Pill color="green">ON AIR</Pill>}
          </strong>
          <span class="opacity-75 font-normal text-xs">
            {isPast ? 'Aired on' : ''} {fmtFullTime(meeting.scheduledAt)}
          </span>
        </span>
      </span>
    </PageNavLink>
  );
}

export function NavBar({ draft, upcoming, past }: Props) {
  const { courseId } = useRouteParams();
  const { terminology } = useCurrentTenant();

  return (
    <PageNavBar title={terminology.Meetings}>
      <BtnPrimary class="w-full py-3" href={meetingUrl({ courseId, page: 'new' })}>
        Create {terminology.Meeting}
      </BtnPrimary>
      <PageNavBody>
        {draft.length > 0 && (
          <PageNavSegment title="Draft" numItems={draft.length}>
            {draft.map((meeting) => (
              <Item key={meeting.id} meeting={meeting} isPast={false} />
            ))}
          </PageNavSegment>
        )}
        <PageNavSegment title="Upcoming" numItems={upcoming.length}>
          {upcoming.map((meeting) => (
            <Item key={meeting.id} meeting={meeting} isPast={false} />
          ))}
        </PageNavSegment>
        <PageNavSegment title="Past" numItems={past.length}>
          {past.map((meeting) => (
            <Item key={meeting.id} meeting={meeting} isPast />
          ))}
        </PageNavSegment>
      </PageNavBody>
    </PageNavBar>
  );
}
