import { ComponentChildren } from 'preact';

/**
 * The content portion of a fixed layout screen.
 * (e.g. a side bar or two and then this as the primary content)
 */
export function FixedContent({
  class: className,
  children,
}: {
  class?: string;
  children: ComponentChildren;
}) {
  return <div class={`${className || ''} min-h-screen flex flex-col grow`}>{children}</div>;
}
