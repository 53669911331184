import { showError } from '@components/app-error';
import { ManualDom } from '@components/manual-dom';
import { ModalForm, showModalForm } from '@components/modal-form';
import { Spinner } from '@components/spinner';
import { FilePickerResult, filepicker } from 'client/components/filepicker';
import { useMemo, useState } from 'preact/hooks';
import { renderMediaPlayer } from './media-player';
import { StandardDialog } from '@components/dialog';
import { makeUploader } from 'client/lib/rpx-client';
import { Button } from '@components/buttons';
import { ComponentChildren } from 'preact';
import { IcoImage, IcoPlay } from '@components/icons';

interface Props {
  url: string;
  type: string;
  isPublic: boolean;
}

async function getVideoImage(video: HTMLVideoElement) {
  const canvas = document.createElement('canvas');
  canvas.height = video.videoHeight;
  canvas.width = video.videoWidth;
  const ctx = canvas.getContext('2d')!;
  ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
  return new Promise<Blob>((resolve, reject) => {
    canvas.toBlob(
      (b) => (b ? resolve(b) : reject(new Error(`Failed to capture image`))),
      'image/jpeg',
    );
  });
}

function PosterMethod({
  text,
  isSelected,
  icon,
  onClick,
}: {
  text: string;
  isSelected: boolean;
  icon: ComponentChildren;
  onClick(): void;
}) {
  return (
    <Button
      type="button"
      class={`inline-flex items-center whitespace-nowrap px-4 py-2 gap-2 border-b-2 font-semibold text-sm ${
        isSelected
          ? 'border-indigo-500 text-indigo-600'
          : 'border-gray-200 text-gray-500 hover:text-gray-700 hover:border-gray-300'
      }`}
      onClick={onClick}
    >
      {icon}
      {text}
    </Button>
  );
}

export function showVideoPosterModal({ type, url, isPublic }: Props) {
  return showModalForm<FilePickerResult>(({ resolve }) => {
    const [selectedMethod, setSelectedMethod] = useState<'capture' | 'upload'>('capture');
    const [selectedFile, setSelectedFile] = useState<FilePickerResult | undefined>(undefined);
    const [video, onVideoElement] = useState<HTMLVideoElement | undefined>(undefined);
    const player = useMemo(
      () =>
        selectedMethod === 'capture' &&
        renderMediaPlayer({
          isResolved: url.startsWith('blob:'),
          type,
          url,
          // We always use a 100:X ratio, so 56 is roughly 16:9 which is good
          // for the poster picker regardless of the video's actual ratio. This
          // allows us to fit the video into the modal.
          ratio: 56,
          // Start loading the video immediately so we can capture a frame.
          load: 'visible',
          onVideoElement,
        }),
      [selectedMethod],
    );

    const [saving, setSaving] = useState(false);

    const captureImage = async () => {
      if (!video) {
        return;
      }
      setSaving(true);
      try {
        const img = await getVideoImage(video);
        const uploader = makeUploader({
          file: img,
          name: 'poster.jpg',
          onProgress() {},
          isPublic,
        });
        return await uploader.upload();
      } catch (err) {
        setSaving(false);
        showError(err);
      }
    };

    async function pickFile() {
      const result = await filepicker({
        sources: ['filepicker', 'takephoto'],
        accept: 'image/*',
        cropRatio: 16 / 9,
        requireCrop: true,
      });
      if (result) {
        setSelectedFile(result);
        setSelectedMethod('upload');
      }
    }

    const hide = () => resolve();

    if (saving) {
      return (
        <StandardDialog
          onClose={hide}
          title="Saving poster image..."
          subtitle={
            <div class="aspect-video flex items-center justify-center">
              <Spinner class="border-indigo-600" />
            </div>
          }
        />
      );
    }

    return (
      <ModalForm
        onClose={hide}
        title="Choose a poster image"
        onSubmit={async () => {
          if (selectedMethod === 'capture') {
            return captureImage();
          }
          return selectedFile;
        }}
        confirmButtonText="Set Poster Image"
      >
        <nav class="flex" aria-label="Tabs">
          <PosterMethod
            text="Capture poster from video"
            isSelected={selectedMethod === 'capture'}
            icon={<IcoPlay class="w-5 h-5" />}
            onClick={() => setSelectedMethod('capture')}
          />
          <PosterMethod
            text="Upload poster image"
            isSelected={selectedMethod === 'upload'}
            icon={<IcoImage class="w-5 h-5" />}
            onClick={() => {
              if (selectedFile) {
                setSelectedMethod('upload');
              } else {
                pickFile();
              }
            }}
          />
        </nav>
        {selectedMethod === 'capture' && (
          <section class="rounded-lg overflow-hidden relative">
            <p class="mb-8">
              Seek the video to the frame you want students to see before the video is played.
            </p>
            {player && <ManualDom el={player} class="aspect-video" />}
          </section>
        )}
        {selectedMethod === 'upload' && selectedFile && (
          <section class="rounded-lg overflow-hidden relative">
            <p class="mb-8">
              The image below will be used as the poster image for the video.
              <Button class="ml-1 underline text-indigo-500" onClick={pickFile}>
                Change Image
              </Button>
            </p>
            <img
              src={selectedFile.publicUrl}
              alt="Selected poster image"
              class="w-full h-auto object-cover"
            />
          </section>
        )}
      </ModalForm>
    );
  });
}
