/**
 * A form component that automatically serializes and tracks state based on
 * inputs and their names / values.
 */

import { Button } from '@components/buttons';
import { IcoX } from '@components/icons';
import { showToast } from '@components/toaster';
import { serializeForm } from 'client/lib/serialize-form';
import { useDidUpdateEffect } from 'client/utils/use-did-update-effect';
import { ComponentChildren, createContext } from 'preact';
import { Dispatch, StateUpdater, useRef, useState, useContext } from 'preact/hooks';
import { intl } from 'shared/intl/use-intl';
import { on } from 'minidoc-editor';
import { shallowEq } from 'shared/utils';
import { TargetedEvent } from 'preact/compat';
import { autoFocus } from 'client/utils/autofocus';
import { AppRouter } from '@components/router';

type SubmitEvent = Pick<TargetedEvent<HTMLFormElement>, 'preventDefault' | 'target'>;

export interface AutoFormProps<T> {
  id?: string;
  class?: string;
  autoFocus?: boolean;
  children?: ComponentChildren;
  onSubmit(data: T, e: SubmitEvent): Promise<unknown>;
  ctx: Context<T>;
}

export type FormErrors = {
  [k: string]: string;
};

export interface Context<T> {
  errors: FormErrors;
  setErrors: Dispatch<StateUpdater<FormErrors>>;
  clearErrors(name: string): void;
  defaultState: T;
  state: T;
  setState: Dispatch<StateUpdater<T>>;
  isProcessing: boolean;
  setIsProcessing: Dispatch<StateUpdater<boolean>>;
  reset(): void;
}

interface HookOpts<T> {
  defaultState?: T;
  initialState: T;
}

const CLEAR_ERROR: any = 'clearerror';

export function dispatchClearError(el: Element, prop: string) {
  const e = new CustomEvent(CLEAR_ERROR, {
    detail: prop,
    bubbles: true,
    composed: true,
  });

  el.dispatchEvent(e);
}

/**
 * The form state and state management object for an auto form.
 */
export function useAutoFormState<T>(opts: HookOpts<T>): [T, Context<T>] {
  const [state, setState] = useState(opts.initialState);
  const [errors, setErrors] = useState<FormErrors>({});
  const [isProcessing, setIsProcessing] = useState(false);

  const ref = useRef<Context<T>>({} as any);
  const result = ref.current;

  result.clearErrors = (name: string) => {
    const err = errors[name];
    if (err || errors.$message) {
      setErrors((s) => {
        const e = { ...s };
        delete e[name];
        delete e.$message;
        return e;
      });
    }
  };

  result.defaultState = opts.defaultState || opts.initialState;
  result.isProcessing = isProcessing;
  result.setIsProcessing = setIsProcessing;
  result.errors = errors;
  result.setErrors = setErrors;
  result.state = state;
  result.setState = setState;
  result.reset = () => {
    setState(result.defaultState);
  };

  return [state, result];
}

export const AutoFormContext = createContext<Context<any>>({} as any);

function formatErrorString(err: any) {
  return err?.data?.validationErrors?.[0]?.message ?? err?.message ?? 'An unknown error occurred';
}

/**
 * A form which syncs its state automatically on input.
 */
export function AutoForm<T>(props: AutoFormProps<T>) {
  const formRef = useRef<HTMLFormElement | null>(null);
  const ctx = props.ctx;
  const onError = (err: any) => {
    const validationErrors = err?.data?.errors as Array<{
      message: string;
      field: string;
    }>;

    console.error(err);

    showToast({
      type: 'warn',
      title: intl('An error occurred while processing your request.'),
      message: err?.message || '',
    });

    ctx.setErrors(
      (validationErrors || []).reduce(
        (acc: FormErrors, { message, field }) => {
          // Trim off the values. prefix, which allows us to handle rdb more simply.
          const sanitizedName = field.replace(/^values\./, '');
          acc[sanitizedName] = message;
          return acc;
        },
        {
          $message: formatErrorString(err),
        } as FormErrors,
      ),
    );

    setTimeout(() => {
      formRef.current
        ?.querySelector('.err-message')
        ?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    });
  };

  const submit = async (e: SubmitEvent) => {
    e.preventDefault();
    if (ctx.isProcessing || !formRef.current) {
      return;
    }
    ctx.setIsProcessing(true);
    try {
      await props.onSubmit(serializeForm(formRef.current) as unknown as T, e);
    } catch (err) {
      onError(err);
    } finally {
      ctx.setIsProcessing(false);
    }
  };

  const clearErrors = useRef<(name: string) => void>(() => {});

  clearErrors.current = (name: string) => {
    const err = ctx.errors[name];
    if (err || ctx.errors.$message) {
      ctx.setErrors((s) => {
        const e = { ...s };
        delete e[name];
        delete e.$message;
        return e;
      });
    }
  };

  return (
    <AutoFormContext.Provider value={ctx}>
      <form
        id={props.id}
        class={props.class}
        method="POST"
        action="/ajax"
        onSubmit={submit}
        onInput={(e: any) => {
          clearErrors.current(e.target.name);
          formRef.current && ctx.setState(serializeForm(formRef.current) as any);
        }}
        ref={(el: any) => {
          if (props.autoFocus) {
            autoFocus(el);
          }
          if (el && !el.$clearerror) {
            el.$clearerror = on(el, CLEAR_ERROR, (e: CustomEvent) => {
              clearErrors.current(e.detail);
            });
            formRef.current = el;
          }
        }}
        onKeyDown={(e: any) => {
          if ((e.ctrlKey || e.metaKey) && e.code === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            submit({
              preventDefault() {},
              target: e.target.closest('form'),
            });
          }
        }}
      >
        {props.children}
      </form>
    </AutoFormContext.Provider>
  );
}

/**
 * Synchronize the form state to the URL search params.
 */
export function useUpdateUrlSearchParamsEffect(router: AppRouter, state: any) {
  useDidUpdateEffect(() => {
    const search = new URLSearchParams(state);
    router.rewrite(location.pathname + `?${search.toString()}`);
  }, [state]);
}

/**
 * A button which, when clicked, resets the form to its default state.
 */
export function ClearFormState(props: { children?: ComponentChildren }) {
  const ctx = useContext(AutoFormContext);
  if (shallowEq(ctx.state, ctx.defaultState)) {
    return null;
  }
  return (
    <footer>
      <Button onClick={ctx.reset}>
        <span class="inline-flex p-1 items-center justify-center bg-gray-100 border rounded-sm mr-2">
          <IcoX class="w-3 h-3 opacity-75" />
        </span>
        {props.children || 'Clear all filters.'}
      </Button>
    </footer>
  );
}
