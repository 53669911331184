import xss from 'xss';
import { whiteList } from 'xss';

const validAttrs = [
  'href',
  'download',
  'data-state',
  'data-bg',
  'data-fg',
  'data-align',
  'src',
  'data-content',
  'data-size',
  'controls',
  'alt',
  'preload',
  'poster',
  'style',
  'width',
  'height',
  'title',
  'frameborder',
  'allow',
  'allowfullscreen',
  'contenteditable',
  'class',
];

const tags = Object.keys(whiteList).concat(
  'mark',
  'text-color',
  'raw-html',
  'embed-html',
  'style',
  'figure',
  'figcaption',
  'template-field',
  'hr',
  'u',
  's',
);

const toWhitelist = (xs: string[]) =>
  xs.reduce((acc, tag) => {
    acc[tag] = validAttrs;
    return acc;
  }, {} as Record<string, string[]>);

const customWhiteList = toWhitelist(tags);
const customWhiteListWithIframe = toWhitelist([...tags, 'iframe']);

/**
 * A list of tags that are acceptable in emails that might be sent by spammers.
 */
export const spammerWhiteList = toWhitelist([
  'mark',
  'figure',
  'figcaption',
  'template-field',
  'text-color',
  'p',
  'em',
  'strong',
  'b',
  'u',
  's',
  'i',
  'ol',
  'ul',
  'li',
  'blockquote',
  'h1',
  'h2',
  'h3',
  'br',
  'hr',
  'img',
]);

export function sanitizeHtml({
  html,
  allowAllStyleAttributes = false,
  allowIframe = false,
}: {
  html: string;
  allowAllStyleAttributes?: boolean;
  allowIframe?: boolean;
}) {
  return xss(html, {
    whiteList: allowIframe ? customWhiteListWithIframe : customWhiteList,
    css: !allowAllStyleAttributes,
  });
}

/**
 * Sanitize invitations HTML content with a tighter whitelist, for likely spammer abuseable content.
 */
export function sanitizeInvitationHTML({
  html,
  allowAllStyleAttributes = false,
}: {
  html: string;
  allowAllStyleAttributes?: boolean;
}) {
  return xss(html, {
    whiteList: spammerWhiteList,
    css: !allowAllStyleAttributes,
  });
}

export function sanitizeBioHtml(html: string) {
  return xss(html, {
    stripIgnoreTag: true,
    whiteList: {
      p: [],
      // Both of bold and italic tags are supported
      strong: [],
      b: [],
      em: [],
      i: [],
      u: [],
      s: [],
      br: [],
      blockquote: [],
      a: ['href'],
    },
  });
}

export function sanitizeCommentHtml(html: string) {
  return xss(html, {
    stripIgnoreTag: true,
    whiteList: {
      p: [],
      // Both of bold and italic tags are supported
      strong: [],
      b: [],
      u: [],
      s: [],
      em: [],
      i: [],
      br: [],
      blockquote: [],
      a: ['href'],
    },
  });
}

/*
 * Sanitizes MeiliSearch results.
 * Allows `strong` tags only for highlighting.
 */
export function sanitizeSearchResults(html: string) {
  return xss(html, {
    stripIgnoreTag: true,
    whiteList: {
      em: [],
    },
  });
}

/*
 * Strips all the html tags
 * and keeps only the plain text.
 */
export function stripAllTags(html: string) {
  return xss(html, {
    whiteList: {},
    stripIgnoreTag: true,
    // the script tag is a special case, we need
    // to filter out its content
    stripIgnoreTagBody: ['script'],
  });
}

/*
 * Cleans the HTML content by stripping all the tags,
 * replacing special characters and appending `...`
 * after the character limit.
 */
export function cleanHTMLContent({
  content,
  characterLimit = 140,
}: {
  content: string;
  characterLimit?: number;
}) {
  // Strip all html tags
  let str = stripAllTags(content);
  // Strip all &nbsp; characters caused by extra spaces within the comment
  str = str.replace(/(&nbsp;|&amp;|&quot;|&#34;|&#39;)+/g, (x) => {
    switch (x) {
      case '&nbsp;':
        return ' ';
      case '&amp;':
      case '&#38;':
        return '&';
      case '&quot;':
      case '&#34;':
        return '"';
      case '&#39;':
        return `'`;
      default:
        return '';
    }
  });
  // Append `...` after the character limit
  str = str.length > characterLimit ? `${str.substring(0, characterLimit)}...` : str;
  return str;
}
