import { BtnPrimary, Button } from '@components/buttons';
import { showError } from '@components/app-error';
import { IcoChevronLeft } from '@components/icons';
import { showModalForm } from '@components/modal-form';
import { useState } from 'preact/hooks';
import { showToast } from '@components/toaster';
import { TenantCoursesList } from '../admin-courses/course-list';
import { rpx } from 'client/lib/rpx-client';
import { BtnCancel, DialogHeader, StandardDialog } from '@components/dialog';
import type { InstantCourse } from './load';

interface Props {
  existingCourses: UUID[];
  onSuccess: (course: InstantCourse) => void;
}

const store = rpx.instantCourses;

export function showAddCourseModal({ existingCourses, onSuccess }: Props) {
  showModalForm(({ resolve }) => {
    const [selectedCourse, setSelectedCourse] = useState<InstantCourse | undefined>(undefined);
    const [isAdding, setIsAdding] = useState(false);

    async function addCourse() {
      if (!selectedCourse) {
        return;
      }

      setIsAdding(true);
      try {
        await store.addCourse({
          courseId: selectedCourse.id,
          title: selectedCourse.title,
          category: selectedCourse.category,
        });
        showToast({
          title: 'Succeeded',
          message: `'${selectedCourse.title}' has been added to Instant Courses!`,
          type: 'ok',
        });
        onSuccess(selectedCourse);
        resolve(true);
      } catch (err) {
        showError(err);
      } finally {
        setIsAdding(false);
      }
    }

    return (
      <StandardDialog contentWidth onClose={resolve}>
        {selectedCourse && (
          <>
            <DialogHeader
              title={
                <>
                  <Button
                    class="inline-flex items-center"
                    onClick={() => setSelectedCourse(undefined)}
                  >
                    <IcoChevronLeft class="w-4 h-4 opacity-75 mr-1" />
                    Back
                  </Button>
                </>
              }
            />
            <section>
              Do you want to add <em>{selectedCourse.title}</em> course to the Instant Courses list?
            </section>
            <footer class="flex items-center gap-6">
              <BtnPrimary class="rounded-full px-6" onClick={addCourse} isLoading={isAdding}>
                Yes, add the course
              </BtnPrimary>
              <BtnCancel onClick={resolve} />
            </footer>
          </>
        )}
        {!selectedCourse && (
          <>
            <DialogHeader
              title="Choose a template course to add it to Instant Courses list"
              subtitle="Make sure you mark the course as template before adding to instant courses."
            />

            <TenantCoursesList
              type="instantCourseSelections"
              onItemSelect={(course) => {
                if (existingCourses.includes(course.id)) {
                  showToast({
                    title: 'Could not add course',
                    message: 'This course is already in instant courses list.',
                    type: 'warn',
                  });
                  return;
                }
                setSelectedCourse({
                  id: course.id,
                  title: course.title,
                  category: 'General',
                  description: '',
                  isGlobal: false,
                  guideId: course.guide.id,
                });
              }}
            />
          </>
        )}
      </StandardDialog>
    );
  });
}
