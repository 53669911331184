/**
 * The section that shows a bullet / checklist of what you'll get with the course.
 */
import { ComponentChildren } from 'preact';
import { EditorProps, SalesBlockInitialState, ViewerProps } from './types';
import { IcoCheckCircle } from '@components/icons';
import { useEffect, useRef } from 'preact/hooks';
import { SubsectionDragProvider } from './subsection-drag-provider';
import { SubsectionEditor } from './subsection-toolbar';
import { MultilineProp, RichProp } from './editable';
import { useIdGen } from './id-gen';
import { BtnAdd } from './btn-add';

interface Bullet {
  id: number;
  text: string;
}

interface State {
  richText: string;
  bullets: Bullet[];
}

export const name = "What you'll learn";

export const type = 'checklist';

export const initialBlockState: SalesBlockInitialState = {
  type,
  skewed: true,
  paddingt: 'md',
};

export const initialState: State = {
  richText: `<h2>What you'll learn</h2>`,
  bullets: [
    { id: 1, text: 'How to build a color palette' },
    { id: 2, text: 'The 5 key qualities of fine art' },
    { id: 3, text: 'How to paint landscapes' },
    { id: 4, text: 'Advanced watercolor techniques' },
    { id: 5, text: 'Ratios and layout' },
    { id: 6, text: 'How to enjoy the journey' },
  ],
};

export function MiniView() {
  return (
    <span class="block py-4">
      <span class="w-4/6 mx-auto grid grid-cols-3 text-xs gap-2">
        <span class="flex bg-gray-200 rounded-full h-1"></span>
        <span class="flex bg-gray-200 rounded-full h-1"></span>
        <span class="flex bg-gray-200 rounded-full h-1"></span>
        <span class="flex bg-gray-200 rounded-full h-1"></span>
        <span class="flex bg-gray-200 rounded-full h-1"></span>
        <span class="flex bg-gray-200 rounded-full h-1"></span>
      </span>
    </span>
  );
}

function CheckLI({ children }: { children: ComponentChildren }) {
  return (
    <div class="flex min-h-full">
      <span class="text-green-400 mt-1 mr-4">
        <IcoCheckCircle class="w-5 h-5" />
      </span>
      <div class="grow">{children}</div>
    </div>
  );
}

export function Viewer(props: ViewerProps<State>) {
  return (
    <>
      <RichProp {...props} path="richText" />
      <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-10 text-lg mt-6">
        {props.state.bullets.map((x) => (
          <CheckLI key={x.id}>{x.text}</CheckLI>
        ))}
      </div>
    </>
  );
}

function ChecklistEditor(props: EditorProps<State>) {
  const nextId = useIdGen(props.state.bullets);
  const ref = useRef<HTMLElement | undefined>(undefined);

  useEffect(() => {
    if (!props.isSelected || !ref.current) {
      return;
    }
    const textareas = Array.from(ref.current.querySelectorAll('textarea'));
    textareas.find((ta) => !ta.value)?.select();
  }, [props.state.bullets.length, props.isSelected]);

  return (
    <SubsectionDragProvider
      table={type}
      onReorder={(reorder) => props.setState((s) => ({ ...s, bullets: reorder(s.bullets) }))}
    >
      <div class="grid grid-cols-3 gap-10 text-lg mt-8">
        {props.state.bullets.map((b, i) => (
          <SubsectionEditor
            key={b.id}
            id={b.id}
            table={type}
            onDelete={() =>
              props.setState((s) => ({ ...s, bullets: s.bullets.filter((x) => x.id !== b.id) }))
            }
          >
            <CheckLI>
              <MultilineProp {...props} path={['bullets', i, 'text']} />
            </CheckLI>
          </SubsectionEditor>
        ))}
        {props.isSelected && (
          <BtnAdd
            isBgLight={props.isBgLight}
            onClick={() =>
              props.setState((s) => ({
                ...s,
                bullets: [...s.bullets, { id: nextId(), text: '' }],
              }))
            }
          >
            + Add Item
          </BtnAdd>
        )}
      </div>
    </SubsectionDragProvider>
  );
}

export function Editor(props: EditorProps<State>) {
  return (
    <>
      <RichProp {...props} path="richText" />
      <ChecklistEditor {...props} />
    </>
  );
}
