import { ComponentChildren } from 'preact';

interface Props {
  class?: string;
  children?: ComponentChildren;
}

/**
 * The standard label for an input field.
 */
export function InputLabel({ class: className, children }: Props) {
  return (
    <span class={`${className || ''} mb-1 block font-medium text-gray-700`}>
      <label>{children}</label>
    </span>
  );
}
