import { basicRouter, RouteParams } from './basic-router';
import { triggerBeforeUnload } from './before-unload';

export type LocationChangeHandler = (name: string, params: RouteParams) => void;

/**
 * Create a new history router (using the browser's history API and location observers).
 * If preprocess is specified, it will be used to process route params before they are
 * passed into the route handlers.
 */
export function historyRouter(routes: string[]) {
  const route = basicRouter(routes);
  const locationChangeHandlers = new Set<LocationChangeHandler>();

  // This is produces an annoying UX, but if we use auto, forward
  // navigation isn't right (the page stays at the current scroll
  // position). So, we go with manual scroll positioning, and we'll
  // have to improve the app's scroll behavior over time.
  history.scrollRestoration = 'manual';

  function processUrl(): [string, RouteParams] {
    const { pathname, search } = location;
    const [name, params] = route(`${pathname}${search}`);
    locationChangeHandlers.forEach((f) => {
      f(name, params);
    });
    return [name, params];
  }

  function goto(url: string) {
    // We simulate beforeunload so that pages can cancel router navigation
    // and actual browser navigation in a mostly uniform way.
    const e = triggerBeforeUnload();
    if (e.prompt) {
      // If e.prompt has been assigned, some component wants us to show the
      // browser's "are you sure you want to leave" dialog, so we'll actually
      // set the location, which will prompt the native dialog.
      location.assign(url);
      return;
    }
    history.pushState('route', document.title, url);
    processUrl();
  }

  function rewrite(url: string) {
    history.replaceState('route', document.title, url);
    processUrl();
  }

  function onLocationChange(callback: LocationChangeHandler) {
    locationChangeHandlers.add(callback);
    return () => locationChangeHandlers.delete(callback);
  }

  document.addEventListener('click', (e: MouseEvent & { target: any }) => {
    const a = e.target.tagName === 'A' ? e.target : e.target.closest('a');
    if (
      a &&
      !a.download &&
      !a.dataset?.noroute &&
      !a.target &&
      !a.href.startsWith('mailto:') &&
      (!/^https?:\/\//.test(a.href) || a.href.startsWith(location.origin)) &&
      !a.closest('[contenteditable]')
    ) {
      // Let the browser do its default behavior
      // and open the link in a new tab when users CTRL+Click
      if (e.metaKey || e.ctrlKey) {
        return;
      }

      e.preventDefault();
      e.stopPropagation();
      goto(a.href);
    }
  });

  window.addEventListener('popstate', processUrl);

  return {
    goto,
    rewrite,
    onLocationChange,
    init: processUrl,
  };
}
