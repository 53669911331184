import { Attachments } from '@course/components/attachments';
import { Button } from '@components/buttons';
import { LessonRow, StudentQuizQuestion, User } from 'server/types';
import { useMemo, useState } from 'preact/hooks';
import { Case } from '@components/conditional';
import { showError } from '@components/app-error';
import { rpx } from 'client/lib/rpx-client';
import { QuestionItem } from './question-item';
import { useAsyncEffect } from 'client/utils/use-async-effect';
import { LoadingIndicator } from '@components/loading-indicator';
import { calculateQuizScore, getDefaultQuestionPoints } from 'shared/assessments';
import { IcoCheck, IcoX } from '@components/icons';
import { useIntl } from 'shared/intl/use-intl';
import { StandardDialog } from '@components/dialog';

interface Props {
  questions: StudentQuizQuestion[];
  lesson: Pick<LessonRow, 'id' | 'title' | 'minQuizScore'>;
  courseId: UUID;
  subject?: string;
  allowRetake: boolean;
  onRetake?: () => void;
}

export interface QuizStats {
  correct: number;
  userPoints: number;
  totalPoints: number;
  isPassed: boolean;
}

export function useQuizStats(
  questions: undefined | StudentQuizQuestion[],
  lesson: Pick<LessonRow, 'minQuizScore'>,
) {
  return useMemo((): QuizStats => {
    const stats = { correct: 0, userPoints: 0, totalPoints: 0, isPassed: true };
    if (!questions || !lesson) {
      return stats;
    }
    const defaultQuestionPoints = getDefaultQuestionPoints(questions);
    const result = questions.reduce((acc, question) => {
      const points = question.points || defaultQuestionPoints;
      acc.totalPoints += points;

      if (generateQuizQuestionResult(question).isCorrect) {
        acc.correct++;
        acc.userPoints += points;
      }

      return acc;
    }, stats);

    if (lesson.minQuizScore) {
      stats.isPassed = stats.userPoints >= lesson.minQuizScore;
    }

    return result;
  }, [questions, lesson]);
}

export function generateQuizQuestionResult(question: StudentQuizQuestion) {
  const correctChoices = question.choices.filter((c) => c.isCorrect);
  const selectedChoices = question.choices.filter((c) => c.isSelected);

  const isCorrect =
    selectedChoices.length > 0 &&
    correctChoices.length === selectedChoices.length &&
    correctChoices.every((c) => selectedChoices.includes(c));

  return {
    correctChoices: correctChoices.map((c) => c.id),
    isCorrect,
    correctFeedback: question.correctFeedback,
    incorrectFeedback: question.incorrectFeedback,
    correctFeedbackFile: question.correctFeedbackFile,
    incorrectFeedbackFile: question.incorrectFeedbackFile,
  };
}

function useQuizResultTitle(props: Props, stats: QuizStats) {
  const intl = useIntl();
  const subject = props.subject || intl('You');
  const lesson = props.lesson.title;
  const score = calculateQuizScore(stats.userPoints, stats.totalPoints);
  const minScore = Math.ceil(((props.lesson.minQuizScore || 0) / stats.totalPoints) * 100);

  if (stats.isPassed || !props.lesson.minQuizScore) {
    return intl.split(
      `{subject:string} completed {lesson:string} with a score of <>{score:number}%</>.`,
      { subject, lesson, score },
    );
  }

  return intl.split(
    `{subject:string} scored <>{score:number}%</>. You must score at least {minScore}% in order to continue.`,
    { subject, score, minScore },
  );
}

export function QuizResults(props: Props) {
  const { questions, lesson, subject, allowRetake, onRetake } = props;
  const intl = useIntl();
  const [activeQuestion, setActiveQuestion] = useState<StudentQuizQuestion | undefined>(undefined);
  const [isRetaking, setIsRetaking] = useState(false);

  const stats = useQuizStats(questions, lesson);

  async function retakeQuiz() {
    setIsRetaking(true);
    try {
      await rpx.assessments.retakeAssessment({
        lessonId: lesson.id,
      });
      onRetake?.();
    } catch (err) {
      showError(err);
    } finally {
      setIsRetaking(false);
    }
  }

  const [titlePrefix, titleScore, titleSuffix] = useQuizResultTitle(props, stats);

  return (
    <div class="bg-white dark:bg-gray-800">
      <header class="mb-8 text-gray-700 dark:text-gray-200">
        <h2 class="text-xl font-medium mb-2">
          {titlePrefix}
          <span class="text-3xl text-green-600 dark:text-green-200">{titleScore}</span>
          {titleSuffix}
        </h2>
        <p class="text-sm">
          <span class="text-gray-500 dark:text-gray-400">
            {intl(
              `{subject:string} answered {correct:number} out of {count:number} {count | pluralize question questions} correctly.`,
              {
                subject: subject || intl('You'),
                correct: stats.correct,
                count: questions.length,
              },
            )}
          </span>
          {allowRetake && (
            <Button
              class="text-indigo-600 dark:text-sky-500 ml-2"
              isLoading={isRetaking}
              onClick={retakeQuiz}
            >
              {intl('Retake Quiz')}
            </Button>
          )}
        </p>
      </header>
      {questions.map((q, index) => (
        <ResultQuestion
          key={q.id}
          question={q}
          index={index}
          isOpened={activeQuestion?.id === q.id}
          allowRetake={allowRetake}
          onClick={() => setActiveQuestion(q)}
        />
      ))}
    </div>
  );
}

export function QuizResultsModal({
  courseId,
  lesson,
  user,
  hide,
}: {
  courseId: UUID;
  lesson: Props['lesson'];
  user: Pick<User, 'id' | 'name'>;
  hide: () => void;
}) {
  const [questions, setQuestions] = useState<StudentQuizQuestion[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useAsyncEffect(async () => {
    setIsLoading(true);
    try {
      const questions = await rpx.assessments.getStudentsQuizSubmission({
        lessonId: lesson.id,
        userId: user.id,
      });
      setQuestions(questions);
    } catch (err) {
      showError(err);
    } finally {
      setIsLoading(false);
    }
  }, [lesson.id, user.id]);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <StandardDialog onClose={hide}>
      <div class="py-8">
        <QuizResults
          questions={questions}
          lesson={lesson}
          courseId={courseId}
          subject={user.name}
          allowRetake={false}
        />
      </div>
    </StandardDialog>
  );
}

function ResultQuestion({
  question,
  index,
  allowRetake,
  isOpened,
  onClick,
}: {
  question: StudentQuizQuestion;
  index: number;
  isOpened: boolean;
  allowRetake: boolean;
  onClick: () => void;
}) {
  const answerResult = generateQuizQuestionResult(question);
  const Ico = answerResult.isCorrect ? IcoCheck : IcoX;

  return (
    <div
      class={`${answerResult.isCorrect ? '' : ''} border ${
        isOpened
          ? 'border-indigo-500 dark:border-indigo-300'
          : 'border-gray-200 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-700/40'
      } text-zinc-900 dark:text-zinc-50 px-6 py-4 rounded mb-4 group relative cursor-pointer`}
      onClick={onClick}
    >
      <Case
        when={isOpened}
        fallback={
          <>
            <p class="flex items-center gap-4 text-base whitespace-pre-line">
              <span>
                <Ico
                  class={`w-6 h-6 p-0.5 rounded-full ${
                    answerResult.isCorrect ? 'bg-green-500 text-white' : 'bg-red-500 text-white'
                  }`}
                />
              </span>
              <span>
                {index + 1}. {question.content || 'Question'}
              </span>
            </p>
            {question.file && (
              <div class="mt-4">
                <Attachments attachments={[question.file]} />
              </div>
            )}
          </>
        }
      >
        <QuestionItem
          question={question}
          hideCorrectAnswer={allowRetake}
          answerResult={answerResult}
        />
      </Case>
    </div>
  );
}
