import { render } from 'preact-render-to-string';
import { BasicEmailWrapper, Heading, MarketingFooter } from './shared';
import { CourseRow, MeetingRow } from 'server/types';

interface BatchRow {
  meeting: Pick<MeetingRow, 'id' | 'title' | 'scheduledAt'>;
  course: Pick<CourseRow, 'id' | 'title'>;
}

export function RecordingAlert({ rows }: { rows: BatchRow[] }) {
  return render(
    <BasicEmailWrapper footer={<MarketingFooter />}>
      <Heading>Recordings are not uploaded for the following meetings</Heading>
      <table
        cellPadding="0"
        cellSpacing="0"
        style="font-family:Helvetica Neue, Helvetica, Arial, sans-serif;margin-bottom:24px; border:0; border-spacing:0;"
      >
        <thead>
          <tr>
            <td>Course ID</td>
            <td>Course Title</td>
            <td>Meeting ID</td>
            <td>Meeting Title</td>
            <td>Scheduled At</td>
          </tr>
        </thead>
        <tbody>
          {rows.map((row) => (
            <tr key={row.meeting.id}>
              <td>{row.course.id}</td>
              <td>{row.course.title}</td>
              <td>{row.meeting.id}</td>
              <td>{row.meeting.title}</td>
              <td>{row.meeting.scheduledAt?.toISOString() || '-'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </BasicEmailWrapper>,
  );
}
