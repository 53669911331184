import dayjs from 'dayjs';

interface Props {
  date?: Date;
  num?: number;
  bg?: string;
}

const thisYear = new Date().getFullYear();

export function CalendarDate(props: Props) {
  // TODO: add support for localized dates
  const date = props.date && dayjs(props.date);
  const sameYear = props.date?.getFullYear() === thisYear;

  return (
    <span>
      <span
        class={`inline-flex flex-col items-center justify-center w-16 h-16 rounded-lg ${
          props.bg || 'bg-gray-500 dark:bg-gray-700 text-white dark:text-gray-300'
        }`}
      >
        {date && sameYear && (
          <>
            <span class="font-light text-sm">{date.format('MMM')}</span>
            <strong class="text-xl">{date.format('DD')}</strong>
          </>
        )}
        {date && !sameYear && (
          <>
            <span class="font-light text-sm">{date.format('MMM DD')}</span>
            <strong class="text-xl">{date.format('YYYY')}</strong>
          </>
        )}
        {props.num && (
          <>
            <strong class="text-xl">{props.num}</strong>
          </>
        )}
      </span>
    </span>
  );
}
