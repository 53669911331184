import { getUtcOffset } from './date-formats';
import { Internationalize } from 'shared/intl';

export const fullTimeFormat = new Intl.DateTimeFormat(undefined, {
  weekday: 'short',
  month: 'short',
  day: 'numeric',
  year: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  timeZoneName: 'short',
});

/*
 * Extracts the hours and minutes from minutes.
 */
export function minutesToTime(offsetMinutes = 0) {
  const hours = Math.floor(offsetMinutes / 60);
  const minutes = offsetMinutes % 60;
  const days = Math.floor(hours / 24);

  return {
    days,
    hours: hours % 24,
    minutes,
  };
}

export function minutesToTimeString(offsetMinutes = 0, intl: Internationalize) {
  const { days, hours, minutes } = minutesToTime(offsetMinutes);

  let str = '';

  if (days > 0) {
    str += intl('{count:number} {count | pluralize day days}', {
      count: days,
    });
  }

  if (hours > 0) {
    str += intl('{count:number} {count | pluralize hour hours}', {
      count: hours,
    });
  }

  if (minutes > 0) {
    if (str) {
      str += intl(' and ');
    }

    str += intl('{count:number} {count | pluralize minute minutes}', {
      count: minutes,
    });
  }

  return str.trim();
}

// This method is located in the `shared` folder as the rest of
// `dateutil`s are also located here.
// It will always return UTC on the server.
export function detectTimezone(): string {
  try {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch {
    return getUtcOffset();
  }
}

export * from './timeago';
export * from './date-formats';
