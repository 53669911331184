import { RouteRule } from '@components/router';

export const routes: RouteRule[] = [
  [() => import('./errors').then((x) => x.page404), '*'],
  [() => import('./errors').then((x) => x.pageCourseErr), 'courses/:courseId/error'],
  [
    () => import('./errors').then((x) => x.pageCourse401),
    'courses/:courseId/not-authorized',
    'products/:courseId/not-authorized',
  ],
  [() => import('./default-page'), ''],
  [() => import('./guide-welcome'), 'welcome'],
  [() => import('./guide-overview'), 'overview'],
  [
    () => import('./user-my-courses'),
    'manage/courses',
    'manage',
    'courses',
    'products',
    'bundles',
    'home',
  ],
  [
    () => import('./guide-upsells/manage-upsell-offer-page').then((x) => x.existingUpsellPage),
    'upsells/:id/offers/:offerId',
  ],
  [
    () => import('./guide-upsells/manage-upsell-offer-page').then((x) => x.newUpsellPage),
    'upsells/:id/new',
  ],
  [() => import('./guide-upsells/new-upsell-page'), 'upsells/new'],
  [
    () =>
      import('./guide-upsells/manage-upsell-applied-to-page').then((x) => x.newUpsellAppliedPage),
    'upsells/:id/applied-to/new',
  ],
  [
    () =>
      import('./guide-upsells/manage-upsell-applied-to-page').then(
        (x) => x.existingUpsellAppliedToPage,
      ),
    'upsells/:id/applied-to/:productId',
  ],
  [() => import('./guide-upsells/manage-upsells-page'), 'upsells'],
  [
    () => import('./guide-upsells/manage-upsell-page'),
    'upsells/:id',
    'upsells/:id/offers',
    'upsells/:id/applied-to',
  ],
  [() => import('./guide-upsells/offer-by-price'), 'upsells/offer-by-price'],

  [() => import('./user-account').then((x) => x.accountPage), 'account', 'account/:tab'],

  [() => import('./user-account').then((x) => x.integrationsPage), 'account/:tab/:integrationType'],
  [
    () => import('./user-account').then((x) => x.stripeOauthRedirect),
    'account/integrations/stripe',
  ],
  [() => import('./user-account').then((x) => x.zoomOauthRedirect), 'account/integrations/zoom'],
  [
    () => import('./user-account').then((x) => x.v1AcctRedirect),
    'settings',
    'settings/picture',
    'settings/password',
  ],
  [() => import('./student-storefront'), 'store'],
  [
    () => import('./student-profile-fields'),
    'products/:courseId/profile-fields',
    'courses/:courseId/profile-fields',
  ],
  [
    () => import('./student-product'),
    'products/:courseId/content',
    'products/:courseId',
    'products/:courseId/content/:lessonId',
  ],

  [
    () => import('./student-discussions').then((x) => x.studentDiscussions),
    'courses/:courseId/discussions',
  ],
  [
    () => import('./student-discussions').then((x) => x.studentDiscussionsByCategory),

    'courses/:courseId/discussions/:categoryId',
  ],
  [
    () => import('./student-discussions').then((x) => x.studentDiscussion),

    'courses/:courseId/discussions/:categoryId/:discussionId',
  ],
  [
    () => import('./student-meetings'),
    'courses/:courseId/meetings',
    'courses/:courseId/meetings/:meetingId',
  ],
  [() => import('./student-course-calendar'), 'courses/:courseId/calendar'],
  [() => import('./student-course-unsubscribe'), 'course-unsubscribe'],
  [() => import('./student-course-preferences'), 'courses/:courseId/preferences'],
  [
    () => import('./student-course-salespage'),
    'courses/:courseId/salespage',
    'products/:courseId/salespage',
  ],
  [() => import('./student-course-overview'), 'courses/:courseId'],

  [
    () => import('./student-course-modules').then((x) => x.studentLessonPage),
    'courses/:courseId/lessons/:lessonId',
    'courses/:courseId/lessons',
  ],
  [
    () => import('./student-course-modules').then((x) => x.studentModulePage),
    'courses/:courseId/modules/:moduleId',
  ],
  [
    () => import('./student-course-members'),
    'courses/:courseId/people',
    'courses/:courseId/people/:userId',
  ],
  [
    () => import('./student-checkout/student-course-checkout'),
    'products/:courseId/checkout/:priceId',
    'courses/:courseId/checkout/:priceId',
  ],
  [() => import('./student-checkout/student-course-provision'), 'courses/:courseId/provision'],
  [
    () => import('./student-checkout/student-gift-purchased'),
    'courses/:courseId/gifts/:giftId/success',
  ],
  [() => import('./student-certificate'), 'courses/:courseId/certificate/:userId'],
  [() => import('./student-accept-invitation'), 'courses/:courseId/invitations/:invitation'],
  [() => import('./student-accept-gift'), 'courses/:courseId/gifts/accept/:gift'],
  [() => import('./image-viewer'), 'view-image'],
  [() => import('./guide-product-settings'), 'manage/products/:courseId/settings'],
  [() => import('./guide-product-overview'), 'manage/products/:courseId'],
  [
    () => import('./guide-product-content'),
    'manage/products/:courseId/content',
    'manage/products/:courseId/content/:lessonId',
  ],
  [() => import('./guide-instant-courses'), 'instant-courses'],
  [
    () => import('./guide-course-style'),
    'manage/products/:courseId/style',
    'manage/courses/:courseId/style',
  ],
  [
    () => import('./guide-course-students'),
    'manage/courses/:courseId/:pageType',
    'manage/courses/:courseId/:pageType/:userId',
    'manage/products/:courseId/:pageType',
    'manage/products/:courseId/:pageType/:userId',
  ],
  [
    () => import('./guide-course-settings'),
    'manage/courses/:courseId/settings/:settingsType',
    'manage/courses/:courseId/settings',
  ],
  [
    () => import('./guide-course-salespage'),
    'manage/courses/:courseId/salespage',
    'manage/products/:courseId/salespage',
  ],
  [() => import('./guide-course-profile-fields'), 'manage/courses/:courseId/profile-fields'],
  [() => import('./guide-course-overview'), 'manage/courses/:courseId'],
  [
    () => import('./guide-course-modules').then((x) => x.guideLessonPage),
    'manage/courses/:courseId/lessons/:lessonId',
    'manage/courses/:courseId/lessons',
  ],
  [
    () => import('./guide-course-modules').then((x) => x.guideModulePage),
    'manage/courses/:courseId/modules/:moduleId',
  ],
  [
    () => import('./guide-course-messages'),
    'manage/courses/:courseId/messages',
    'manage/courses/:courseId/messages/:messageId',
    'manage/products/:courseId/messages',
    'manage/products/:courseId/messages/:messageId',
  ],
  [
    () => import('./guide-course-meetings'),
    'manage/courses/:courseId/meetings',
    'manage/courses/:courseId/meetings/:meetingId',
    'manage/courses/:courseId/meetings/:meetingId/:tab',
  ],
  [() => import('./guide-course-invitations'), 'manage/courses/:courseId/invitations'],
  [() => import('./guide-course-discussions'), 'manage/courses/:courseId/discussions'],
  [() => import('./guide-course-certificate'), 'manage/courses/:courseId/certificate'],
  [() => import('./guide-course-calendar'), 'manage/courses/:courseId/calendar'],
  [() => import('./guide-course-assessments'), 'manage/courses/:courseId/assessments'],
  [
    () => import('./pmts/page-course-prices'),
    'manage/products/:courseId/prices',
    'manage/courses/:courseId/prices',
    'manage/products/:courseId/prices/:priceId',
    'manage/courses/:courseId/prices/:priceId',
  ],
  [
    () => import('./pmts/page-course-coupons'),
    'manage/products/:courseId/coupons',
    'manage/courses/:courseId/coupons',
    'manage/products/:courseId/coupons/:couponId',
    'manage/courses/:courseId/coupons/:couponId',
  ],

  [() => import('./pmts/page-public-pricing-plans'), 'pricing-plans'],
  [
    () => import('./pmts/page-superadmin-pricing'),
    'admin/pricing/:productId',
    'admin/pricing/:productId/:folderId',
  ],
  [() => import('./free-guide-onboarding'), 'onboarding/guides'],
  [() => import('./core-checkout/core-checkout'), 'checkout/:priceId'],
  [() => import('./core-checkout/core-provision'), 'checkout/:priceId/provision'],

  [() => import('./auth-logout'), 'logout'],
  [() => import('./auth-login'), 'login'],
  [() => import('./auth-one-click-login'), 'one-click-login'],
  [() => import('./auth-forgot-password'), 'forgot-password'],
  [() => import('./admin-terminology'), 'admin/terminology'],
  [() => import('./admin-storefront'), 'admin/store'],
  [() => import('./admin-settings'), 'admin/settings'],
  [() => import('./admin-people'), 'admin', 'admin/people'],
  [() => import('./admin-people/user-detail'), 'admin/people/:userId'],
  [() => import('./admin-mimic-user'), 'admin/mimic-user'],
  [() => import('./admin-invitations'), 'admin/invitations'],
  [() => import('./admin-instant-courses'), 'admin/instant-courses'],
  [() => import('./admin-gifts'), 'admin/gifts'],
  [() => import('./admin-fields'), 'admin/fields'],
  [() => import('./admin-courses'), 'admin/courses'],
  [() => import('./admin-courses/course-detail'), 'admin/courses/:courseId'],
  [() => import('./admin-branding'), 'admin/branding', 'admin/branding/:brandId'],
  [() => import('./superadmin-manage-tenants'), 'admin/tenants'],
];
