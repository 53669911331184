interface Handlers {
  down?(): void;
  up?(): void;
}

/**
 * Add keyboard navigation to the specified keyboard event, allowing the
 * user to navigate up or down via keydown events.
 */
export function keyboardNav(e: KeyboardEvent, handlers: Handlers) {
  const target = (e as any).target as HTMLInputElement;
  if (
    handlers.down &&
    (e.code === 'ArrowDown' ||
      e.code === 'Enter' ||
      (e.code === 'ArrowRight' && target.selectionEnd === target.value.length))
  ) {
    e.preventDefault();
    handlers.down();
  }
  if (
    handlers.up &&
    (e.code === 'ArrowUp' || (e.code === 'ArrowLeft' && target.selectionEnd === 0))
  ) {
    e.preventDefault();
    handlers.up();
  }
}
