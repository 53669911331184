export interface Message {
  id: UUID | string;
  subject: string;
  updatedAt?: Date;
  sentAt?: Date;
  editUrl: string;
  isSystem?: boolean;
  startOffset?: number;
  startDate?: string;
}
