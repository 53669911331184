import { AutosizeText } from '@components/autosize-text';
import { IcoBadge } from '@components/icons';
import { useState } from 'preact/hooks';
import {
  CertificateFields,
  CertificateRow,
  CertificateTemplate,
  CourseRow,
  UserProfileRow,
} from 'server/types';

interface CertificateData {
  course: {
    title: CourseRow['title'];
    guide: Pick<UserProfileRow, 'name'>;
  };
  student: Pick<UserProfileRow, 'name'>;
  issuedDate: Date | string;
}

interface TemplateProps {
  fields: CertificateFields;
  data: CertificateData;
  showBadge: boolean;
  badgeSrc?: string;
  accentColor?: string;
  onChange?(f: (x: CertificateFields) => CertificateFields): void;
}

interface Template {
  name: CertificateTemplate;
  Thumbnail(): JSX.Element;
  Render(props: TemplateProps): JSX.Element;
}

const formatIssuedDate = (date?: Date | string) => {
  return date
    ? new Intl.DateTimeFormat(undefined, {
        dateStyle: 'long',
      }).format(new Date(date))
    : '';
};

export const replacements: Record<string, (data: CertificateData) => string> = {
  STUDENT_NAME: (data) => data.student.name || '',
  COURSE_TITLE: (data) => data.course.title,
  ISSUED_DATE: (data) => formatIssuedDate(data.issuedDate),
  GUIDE_NAME: (data) => data.course.guide.name || '',
};

function expandTemplateString(s: string, data: CertificateData) {
  return s.replaceAll(/{{([^}]+)}}/g, (_, field) => {
    return replacements[field]?.(data) || '';
  });
}

function TemplateField(
  props: TemplateProps & {
    field: keyof CertificateFields;
    class?: string;
  },
) {
  const [focused, setFocused] = useState(false);
  const { onChange, fields, field } = props;
  const rawText = fields[field];
  const value = focused ? rawText : expandTemplateString(rawText, props.data);

  if (!onChange) {
    return <div>{value}</div>;
  }

  return (
    <AutosizeText
      containerClass={`${focused ? 'font-sans' : ''}`}
      class={`border-none p-0 pb-2 min-h-5 ${props.class || ''}`}
      name={props.field}
      placeholder="Enter text..."
      onFocusCapture={() => setFocused(true)}
      onBlurCapture={() => setFocused(false)}
      value={value}
      onInput={(e: any) => onChange((x) => ({ ...x, [props.field]: e.target.value }))}
    />
  );
}

function Badge(props: TemplateProps & { fill: string }) {
  if (!props.showBadge) {
    return null;
  }

  if (props.badgeSrc) {
    return <img src={props.badgeSrc} class="w-1/5" />;
  }

  return (
    <IcoBadge
      class={`w-1/5 aspect-square ${props.fill}`}
      style={props.accentColor ? { fill: props.accentColor } : undefined}
    />
  );
}

export const templates: Template[] = [
  {
    name: 'modern',
    Thumbnail() {
      return <span class="flex bg-green-600 h-6 w-6 rounded-sm"></span>;
    },
    Render(props) {
      return (
        <section
          class="certificate w-readable-container max-w-full font-sans border text-gray-600 mx-auto relative flex bg-linear-to-b from-green-500 via-green-400 to-green-500 pl-4 sm:pl-20 whitespace-pre-wrap"
          style={
            props.accentColor
              ? {
                  background: props.accentColor,
                }
              : undefined
          }
        >
          <div class="flex grow px-16 bg-white ml-auto">
            <div class="flex flex-col grow relative">
              <header class="flex flex-col justify-start text-left pt-28 sm:pt-48">
                <h1 class="font-serif font-extrabold leading-loose text-4xl sm:text-5xl">
                  <TemplateField {...props} field="title" />
                </h1>
                <p class="mt-10">
                  <TemplateField {...props} field="certifies" />
                </p>
                <h2 class="font-serif text-2xl font-bold">
                  <TemplateField {...props} field="subtitle" />
                </h2>
                <p class="mt-8">
                  <TemplateField {...props} field="description" />
                </p>
              </header>
              <footer class="flex justify-end items-end py-10 mt-10 w-full">
                <Badge {...props} fill="fill-green-500" />
                <aside class="text-right ml-auto">
                  <p>
                    <TemplateField {...props} field="issuer" />
                  </p>
                  <p>
                    <TemplateField {...props} field="issued" />
                  </p>
                </aside>
              </footer>
            </div>
          </div>
        </section>
      );
    },
  },
  {
    name: 'classic',
    Thumbnail() {
      return <span class="flex bg-amber-400 w-6 h-6 rounded-sm"></span>;
    },
    Render(props) {
      const styleHandwritten = "font-family: 'Great Vibes', handwriting;";
      const styleHeading = "font-family: 'Abril Fatface', display;";
      return (
        <section
          class="certificate max-w-readable-container w-full bg-linear-to-br from-amber-300 via-amber-200 to-amber-400 border border-amber-200 text-stone-600 md:p-8 mx-auto relative flex whitespace-pre-wrap"
          style={props.accentColor ? { background: props.accentColor } : undefined}
        >
          <link rel="preconnect" href="https://fonts.bunny.net" />
          <link
            href="https://fonts.bunny.net/css?family=abril-fatface:400|great-vibes:400"
            rel="stylesheet"
          />
          <div class="flex grow bg-white border-stone-400 border-4 p-0.5">
            <div class="flex flex-col grow justify-center items-center border-stone-400 border-2 relative">
              <header class="flex flex-col items-center justify-center text-center max-w-3/4 pt-48 ">
                <h1
                  class="font-serif font-extrabold leading-10"
                  style={styleHeading + 'font-size:2.75rem;'}
                >
                  <TemplateField {...props} class="text-center" field="title" />
                </h1>
                <p class="text-xl mt-10" style={styleHandwritten}>
                  <TemplateField {...props} class="text-center" field="certifies" />
                </p>
                <h2 class="text-2xl" style={styleHeading}>
                  <TemplateField {...props} class="text-center" field="subtitle" />
                </h2>
                <p class="text-xl mt-8" style={styleHandwritten}>
                  <TemplateField {...props} class="text-center" field="description" />
                </p>
              </header>
              <footer class="flex w-full justify-between items-end p-8">
                <Badge {...props} fill="fill-amber-400" />
                <aside class="text-xl text-right ml-auto" style={styleHandwritten}>
                  <p>
                    <TemplateField {...props} field="issuer" />
                  </p>
                  <p>
                    <TemplateField {...props} field="issued" />
                  </p>
                </aside>
              </footer>
            </div>
          </div>
        </section>
      );
    },
  },
];

export function Certificate({ cert, data }: { cert: CertificateRow; data: CertificateData }) {
  const template = templates.find((t) => t.name === cert.template);

  if (!template) {
    return <h2>Template {cert.template} not found</h2>;
  }
  return template.Render({
    data,
    fields: cert.customizedText,
    showBadge: cert.showBadge,
    badgeSrc: cert.badgeSrc,
    accentColor: cert.accentColor,
  });
}
