import { NotificationItem } from '@course/components/student-notifications/notification-item';
import { OverviewCard } from 'client/courses/pages/guide-course-overview/overview-card';
import { rpx, RpxResponse } from 'client/lib/rpx-client';
import { useAsyncEffect } from 'client/utils/use-async-effect';
import { useState } from 'preact/hooks';
import { Course } from 'server/types';

type NotificationsResponse = RpxResponse<typeof rpx.notifications.getNotifications>;

interface Props {
  title: string;
  course: Pick<Course, 'id' | 'title'>;
  Wrapper?: typeof OverviewCard;
}

export function WhatsNew({ title, course, Wrapper = OverviewCard }: Props) {
  const [{ notifications, hasMore, cursor }, setState] = useState<NotificationsResponse>({
    cursor: undefined,
    notifications: [],
    hasMore: true,
  });

  async function loadNextPage() {
    try {
      const result = await rpx.notifications.getNotifications({
        courseId: course.id,
        cursor,
        limit: 3,
      });
      setState({
        ...result,
        notifications: notifications.concat(result.notifications),
      });
    } catch (err) {
      // This is not a critical error, so we don't show it to the user.
      // It's also failing when the membership level is `preview`.
      if (err.data?.type !== 'noPreview') {
        throw err;
      }
    }
  }

  // Load the first page
  useAsyncEffect(async () => {
    loadNextPage();
  }, []);

  if (!notifications.length) {
    return null;
  }

  return (
    <Wrapper
      title={title}
      footerAction={
        hasMore
          ? {
              text: 'Show More',
              onClick: loadNextPage,
            }
          : undefined
      }
    >
      <ul role="list" class="px-2">
        {notifications.map((notification, index) => (
          <NotificationItem
            key={notification.id}
            course={course}
            item={notification}
            isLast={notifications.length === index + 1}
            unread={false}
          />
        ))}
      </ul>
    </Wrapper>
  );
}
