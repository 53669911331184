import dayjs from 'dayjs';
import { BtnSecondary } from '@components/buttons';
import { IcoCalendar, IcoGoogle } from '@components/icons';
import { useConfiguration } from '@components/router/session-context';
import { Course, StudentMeeting } from 'server/types';
import { URLS, toQueryString } from 'shared/urls';
import { toISO8601 } from 'shared/dateutil';
import { useRouteParams } from '@components/router';
import { useIntl } from 'shared/intl/use-intl';

interface Props {
  meeting: Pick<StudentMeeting, 'id' | 'title' | 'scheduledAt' | 'durationMinutes'>;
}

function buildGoogleCalendarLink({
  meeting,
  course,
  domain,
}: {
  meeting: Props['meeting'];
  course: Pick<Course, 'id' | 'title'>;
  domain: string;
}) {
  if (!meeting.scheduledAt) {
    return;
  }

  const endDate = dayjs(meeting.scheduledAt).add(meeting.durationMinutes, 'minutes').toDate();

  const params = toQueryString({
    text: meeting.title,
    details: `Link: ${URLS.student.meeting({
      course,
      domain,
      meeting,
    })}`,
    dates: `${toISO8601(meeting.scheduledAt)}/${toISO8601(endDate)}`,
    sf: true,
  });

  return `https://www.google.com/calendar/render?action=TEMPLATE&${params}`;
}

export function AddToCalendarButtons({ meeting }: Props) {
  const intl = useIntl();
  const configuration = useConfiguration();
  const { courseId, courseSlug } = useRouteParams();

  if (!meeting.scheduledAt) {
    return null;
  }

  const googleLink = buildGoogleCalendarLink({
    course: {
      id: courseId,
      title: courseSlug,
    },
    meeting,
    domain: configuration?.tenant.domain || '',
  });

  return (
    <div class="flex flex-col space-y-4 sm:space-y-0 sm:flex-row sm:justify-center sm:space-x-4">
      <BtnSecondary target="_blank" rel="noopener noreferrer" href={googleLink}>
        <IcoGoogle class="w-3 h-3 mr-2 opacity-75" />
        {intl('Add to Google Calendar')}
      </BtnSecondary>
      <BtnSecondary href={`/api/meeting/ical/${courseId}/${meeting.id}`} download={meeting.title}>
        <IcoCalendar class="w-4 h-4 mr-2 opacity-75" />
        {intl('Add to desktop calendar')}
      </BtnSecondary>
    </div>
  );
}
