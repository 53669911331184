import { Dropdown, MenuItem } from '@components/dropdown';
import { createContext, ComponentChildren } from 'preact';
import { IcoDesktop, IcoMoon, IcoSun } from '@components/icons';
import { useContext, useEffect, useState } from 'preact/hooks';
import { useIntl } from 'shared/intl/use-intl';
import { useDidUpdateEffect } from 'client/utils/use-did-update-effect';

type StudentTheme = 'dark' | 'light' | 'system';

const localStorageKey = 'studentTheme';

const themeQueryString = '(prefers-color-scheme: dark)';

const isDarkTheme = (theme: StudentTheme) =>
  theme === 'dark' || (theme === 'system' && window.matchMedia(themeQueryString).matches);

export const StudentThemeContext = createContext({
  theme: 'system' as StudentTheme,
  setTheme(_theme: StudentTheme) {},
});

export function useStudentTheme() {
  return useContext(StudentThemeContext);
}

export function StudentThemeProvider({ children }: { children: ComponentChildren }) {
  const [theme, setTheme] = useState<StudentTheme>(() => {
    // Start with light theme if this is embedded in the style preview browser
    if (document.body.classList.contains('preview')) {
      return 'light';
    }
    return (localStorage.getItem(localStorageKey) as StudentTheme) || 'system';
  });

  useDidUpdateEffect(() => {
    localStorage.setItem(localStorageKey, theme);
  }, [theme]);

  // Used to force a redraw when the system theme changes from light to
  // dark or the other way round.
  const [, setCount] = useState(0);

  useEffect(() => {
    const mediaQuery = window.matchMedia(themeQueryString);
    // addEventListener is not supported on older Safari versions
    if (!mediaQuery.addEventListener) {
      return;
    }

    const onSystemThemeChange = () => setCount((x) => ++x);

    mediaQuery.addEventListener('change', onSystemThemeChange);
    return () => {
      mediaQuery.removeEventListener('change', onSystemThemeChange);
    };
  }, []);

  return (
    <StudentThemeContext.Provider value={{ theme, setTheme }}>
      <div class={isDarkTheme(theme) ? 'dark' : 'light'}>{children}</div>
    </StudentThemeContext.Provider>
  );
}

export function StudentThemePicker() {
  const intl = useIntl();
  const { theme, setTheme } = useStudentTheme();

  return (
    <Dropdown
      hideDownIcon
      renderMenu={() => (
        <div class="p-2 pb-0 flex flex-col">
          <MenuItem isSelected={theme === 'light'} onClick={() => setTheme('light')}>
            <IcoSun class="w-5 h-5 opacity-75 mr-2" /> {intl('Light')}
          </MenuItem>
          <MenuItem isSelected={theme === 'dark'} onClick={() => setTheme('dark')}>
            <IcoMoon class="w-5 h-5 opacity-75 mr-2" /> {intl('Dark')}
          </MenuItem>
          <MenuItem isSelected={theme === 'system'} onClick={() => setTheme('system')}>
            <IcoDesktop class="w-4 h-4 opacity-75 mr-3" /> {intl('System')}
          </MenuItem>
        </div>
      )}
    >
      <span class="inline-flex items-center opacity-75 hover:opacity-100">
        {isDarkTheme(theme) ? <IcoMoon class="w-6 h-6 text-sky-400" /> : <IcoSun class="w-6 h-6" />}
      </span>
    </Dropdown>
  );
}
