import { RouteParams } from '@components/router';
import { reverseRoute } from 'shared/urls';

const coursesBaseUrl = '/manage/courses/';
const productsBaseUrl = '/manage/products/';
const makeUrl = (baseUrl: string, suffix = '') => `${baseUrl}:courseId/:pageType${suffix}`;

export const urls = {
  coursesBaseUrl,
  productsBaseUrl,
  students: makeUrl(coursesBaseUrl),
  student: makeUrl(coursesBaseUrl, '/:userId'),
  customers: makeUrl(productsBaseUrl),
  customer: makeUrl(productsBaseUrl, '/:userId'),
  showStudents({
    pageType = 'students',
    baseUrl = coursesBaseUrl,
    ...opts
  }: { courseId: string; pageType?: string; tab?: string; baseUrl: string } & RouteParams) {
    return reverseRoute(urls.students.replace(coursesBaseUrl, baseUrl), { ...opts, pageType });
  },
  showStudent({
    pageType = 'students',
    baseUrl = coursesBaseUrl,
    ...opts
  }: {
    courseId: string;
    userId: string;
    pageType?: string;
    tab?: string;
    baseUrl: string;
  } & RouteParams) {
    return reverseRoute(urls.student.replace(coursesBaseUrl, baseUrl), { ...opts, pageType });
  },
};
