import { IcoCaretLeft, IcoCaretRight } from '@components/icons';

interface Props {
  text: string;
  position?: string;
  arrow?: 'left' | 'right';
}

export function ChecklistDialog({ text, arrow = 'left', position = `left-32` }: Props) {
  return (
    <div
      class={`z-20 -mt-16 w-56 absolute transition duration-150 ease-in-out ${position} ml-8 shadow-lg bg-gray-700 p-4 rounded-2xl text-zinc-50`}
    >
      {arrow === 'left' && (
        <div class="absolute flex items-center -left-3 top-0 bottom-0">
          <IcoCaretLeft class="w-6 h-6 text-gray-700" />
        </div>
      )}
      {arrow === 'right' && (
        <div class="absolute flex items-center -right-3 top-0 bottom-0">
          <IcoCaretRight class="w-6 h-6 text-gray-700" />
        </div>
      )}
      <p class="text-lg">{text}</p>
    </div>
  );
}
