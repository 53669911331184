type Value = string | boolean | string[];
type NestedValues = Record<string, Value>;

/**
 * Given a form element, convert its inputs into a JSON object.
 */
export function serializeForm(el: HTMLFormElement) {
  const inputs = Array.from(el.querySelectorAll('[name]')) as HTMLInputElement[];
  return serializeInputs(inputs);
}

type Input = Pick<HTMLInputElement, 'name' | 'value' | 'checked' | 'type'>;

export function serializeInputs(inputs: Input[]) {
  return inputs.reduce((acc, el) => {
    let name = el.name;
    let value: Value | undefined = undefined;
    const isArray = name.startsWith('[]');

    // Fields that start with [] are converted into arrays.
    if (isArray) {
      if (el.checked) {
        name = name.slice(2);
        value = el.value;
      }
    } else if (el.type === 'checkbox') {
      value = el.checked;
    } else if (el.type === 'radio') {
      if (el.checked) {
        value = el.value;
      }
    } else {
      value = el.value;
    }

    // Form fields that start with _ are considered private / not serialized.
    if (name.startsWith('_') || value === undefined) {
      return acc;
    }

    /*
     * Checks the row keys and moves the value into a nested object
     * when the key includes a dot notation.
     * Example: { 'some_id.status': 'approved', 'some_id.feedback': 'My feedback', 'another_id.status': 'rejected' }
     * will be transformed into this:
     * { some_id: { status: 'approved', feedback: 'My feedback' },
     *  another_id: { status: 'rejected' } }
     * Please note that this is currently supporting one level deep with a single dot.
     */
    if (name.includes('.')) {
      const [parentKey, innerKey] = name.split('.');
      if (!acc[parentKey]) {
        acc[parentKey] = {};
      }
      const parent = acc[parentKey] as NestedValues;

      if (isArray) {
        const arr = (parent[innerKey] || []) as string[];
        parent[innerKey] = arr.concat([value as string]);
      } else {
        parent[innerKey] = value;
      }
    } else if (isArray) {
      const arr = (acc[name] || []) as string[];
      acc[name] = arr.concat([value as string]);
    } else {
      acc[name] = value;
    }

    return acc;
  }, {} as { [k: string]: NestedValues | Value });
}
