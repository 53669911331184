import { IcoWifi } from '@components/icons';

interface Props {
  padding?: string;
  isDirty: boolean;
  isConnected?: boolean;
  hasUploadErrors?: boolean; // if true, display a warning text in place of the save status text.
}

/**
 * Display the Save status on an editor
 */
export function SaveStatus({ padding = '', isConnected = true, isDirty, hasUploadErrors }: Props) {
  if (hasUploadErrors) {
    return (
      <span
        class={`text-sm text-red-500 inline-block m-0 justify-end w-20 whitespace-nowrap ${padding}`}
      >
        Upload errors
      </span>
    );
  }
  if (!isConnected) {
    return (
      <span
        class={`text-red-600 text-xs inline-flex items-center m-0 justify-end w-24 whitespace-nowrap ${padding}`}
      >
        <span>
          <IcoWifi />
        </span>
        <span class="ml-1.5">Connecting...</span>
      </span>
    );
  }
  return (
    <span
      class={`text-gray-400 text-xs inline-flex m-0 justify-center w-16 whitespace-nowrap ${padding}`}
    >
      {isDirty ? 'saving...' : 'saved'}
    </span>
  );
}
