import { LoadedProps } from '@components/router';
import { rpx } from 'client/lib/rpx-client';

export async function load() {
  const courses = await rpx.instantCourses.getAll();
  return courses;
}

export type Props = LoadedProps<typeof load>;
export type InstantCourse = Props['state'][0];
