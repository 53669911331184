/**
 * Helpers for scrolling sales page edit blocks into view based on contextual
 * actions (e.g. hovering over a toolbar, moving a block, selection, etc)
 */

export function scrollToSelectedSection(opts = { force: true }) {
  // When the mosuse enters the section context toolbar, we want to ensure
  // the section is scrolled into view so that it's clear *what* section
  // the user is about to modify.
  const el = document.querySelector<HTMLDivElement>('.sales-page-active-block');
  const parent = el?.closest('.overflow-auto, .salespage-wrapper') as HTMLDivElement;
  if (!el || !parent) {
    return;
  }

  const isScrolledUp = parent.scrollTop > el.offsetTop + el.offsetHeight;
  const isScrolledDown = parent.scrollTop + parent.offsetHeight < el.offsetTop;

  if (isScrolledUp || isScrolledDown || opts.force) {
    // Smooth scroll to the new location...
    parent.scrollTo({
      // The 128 is just to give the section a little top breathing room.
      top: el.offsetTop - 128,
      behavior: 'smooth',
    });
  }
}
