/*
 * This file contains a hook that locks or unlocks the body scroll.
 * It's useful when we'd like to display a full sized modal on top
 * of everything else.
 */
import { useEffect } from 'preact/hooks';

export function useBodyScrollLock(locked: boolean) {
  useEffect(() => {
    if (locked) {
      document.documentElement.classList.add('overflow-hidden', 'h-screen');
    }

    return () => {
      document.documentElement.classList.remove('overflow-hidden', 'h-screen');
    };
  }, [locked]);
}
