import { ComponentChildren } from 'preact';

export function SummarySection({
  label,
  children,
}: {
  label: string;
  children: ComponentChildren;
}) {
  return (
    <div class="inline-flex flex-col p-6 font-medium">
      <div class="flex justify-between">
        <label class="text-sm font-medium leading-6 text-gray-500">{label}</label>
      </div>
      {children}
    </div>
  );
}

export function SummaryStat({
  label,
  suffix,
  children,
}: {
  label: ComponentChildren;
  suffix?: string;
  children: ComponentChildren;
}) {
  return (
    <div class="flex flex-col py-4">
      <label class="text-sm font-medium leading-6 text-gray-500">{label}</label>
      <div>
        <span class="text-4xl font-semibold tracking-tight">{children}</span>
        {suffix && <span class="ml-2 font-medium text-sm text-gray-400">{suffix}</span>}
      </div>
    </div>
  );
}
