/**
 * The banner section for the sales page builder.
 */
import {
  defaultBgImg,
  EditorProps,
  RichProp,
  SalesBlockInitialState,
  ViewerProps,
} from '@course/components/page-builder';
import { CtaButton, CTAState } from '@course/components/page-builder/cta-button';
import { initialState as CTAInitialState, defaultChoice } from './cta-block';
import { Color } from 'shared/colors';

interface State {
  richText: string;
  callToAction: CTAState;
}

export const initialState: State = {
  richText: `<h1>A catchy title.</h1><p>Add some catchy subtext here!</p>`,
  callToAction: {
    ...CTAInitialState,
    bgcolor: Color.gray800,
  },
};

export const name = 'Banner';

export const type = 'banner';

export const initialBlockState: SalesBlockInitialState = {
  type,
  bgimg: defaultBgImg,
  bgcolor: Color.white,
};

export function MiniView() {
  return (
    <span class="flex justify-center bg-gray-500 py-4">
      <span class="flex flex-col items-center w-3/4">
        <span class="block bg-white opacity-75 rounded-sm h-2 mb-2 w-1/2"></span>
        <span class="block bg-white opacity-50 rounded-sm h-1 mb-2 w-3/4"></span>
        <span class="block bg-white opacity-75 rounded-sm h-2 w-1/6"></span>
      </span>
    </span>
  );
}

function View(props: EditorProps<State> | ViewerProps<State>) {
  return (
    <>
      <RichProp
        {...props}
        path="richText"
        class="content-block-body text-2xl mx-auto sales-page-hero"
      />
      <footer class="content-block-footer mt-8">
        <CtaButton {...props} path="callToAction" defaultChoice={defaultChoice} />
      </footer>
    </>
  );
}

export const Editor = View;

export const Viewer = View;

export function generateState({ richText }: { richText: State['richText'] }): State {
  return {
    richText,
    callToAction: {
      ...CTAInitialState,
      bgcolor: Color.gray800,
    },
  };
}
