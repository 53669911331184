import { AsyncForm } from '@components/async-form';
import { BtnPrimary, BtnSecondary, Button } from '@components/buttons';
import { Checkbox } from '@course/components/checkbox';
import { Case } from '@components/conditional';
import { IcoPlus, IcoTrash } from '@components/icons';
import { showModalForm } from '@components/modal-form';
import { RadioGroup } from '@course/components/radio-button/radio-group';
import { useCurrentUser } from '@components/router/session-context';
import { rpx, RpxResponse } from 'client/lib/rpx-client';
import { TenantCoursesList } from '../../admin-courses/course-list';
import { useAsyncEffect } from 'client/utils/use-async-effect';
import { useState } from 'preact/hooks';
import { PriceMetadata } from 'server/types';
import { AccountTier } from 'server/types';
import { URLS } from 'shared/urls';
import * as fmt from 'client/lib/fmt';
import { DialogFooter, StandardDialog } from '@components/dialog';

export interface Props {
  metadata: PriceMetadata;
  hideTier: boolean;
  onSave: (metadata: PriceMetadata) => void;
}

const pricesStore = rpx.prices;

type ConnectedCourse = RpxResponse<typeof pricesStore.getConnectedCourses>[0];

export function MetadataTab(props: Props) {
  const user = useCurrentUser();
  const [metadata, setMetadata] = useState(props.metadata);
  const [connectedCourses, setConnectedCourses] = useState<ConnectedCourse[]>([]);

  useAsyncEffect(async () => {
    const courseIds = metadata.coursesToEnroll || [];

    if (courseIds.length > 0) {
      const courses = await pricesStore.getConnectedCourses({
        courseIds,
      });
      setConnectedCourses(courses);
    }
  }, []);

  return (
    <AsyncForm
      onSubmit={async (data) => {
        const newMetadata = {
          tier: metadata.tier,
          bundleIds: data.bundleIds,
          coursesToEnroll: connectedCourses.map((course) => course.id),
          isLifetime: data.isLifetime,
          unlimitedInstantCourses: metadata.unlimitedInstantCourses,
          instantCourseCredits: metadata.instantCourseCredits,
        };
        props.onSave(newMetadata);
        setMetadata(newMetadata);
      }}
    >
      {!props.hideTier && (
        <section class="pb-6 border-b border-dashed space-y-6">
          <h2 class="text-lg text-gray-900 font-medium">Ruzuku Account Tier</h2>
          <div class="max-w-3xl">
            <RadioGroup
              name="accountTier"
              items={[
                ...fmt.tiers.map((t) => ({
                  key: t,
                  title: fmt.tierName(t),
                })),
                {
                  key: 'none',
                  title: 'None',
                },
              ]}
              value={metadata?.tier || 'none'}
              onChange={(newValue) => {
                setMetadata({
                  ...metadata,
                  tier: newValue === 'none' ? undefined : (newValue as AccountTier),
                });
              }}
            />
          </div>
          <div>
            <Checkbox name="isLifetime" checked={metadata?.isLifetime}>
              Is lifetime
            </Checkbox>
          </div>
        </section>
      )}
      <section class="py-6 border-dashed border-b">
        <h2 class="text-lg text-gray-900 font-medium mb-2">Instant Courses</h2>
        <Checkbox
          checked={metadata?.unlimitedInstantCourses}
          onClick={(e: any) =>
            setMetadata((m) => ({ ...m, unlimitedInstantCourses: e.target.checked }))
          }
        >
          Unlimited Instant Courses
        </Checkbox>
        {!metadata?.unlimitedInstantCourses && (
          <div class="mt-2 space-x-6">
            <label>IC Credits</label>
            <input
              type="number"
              class="inline-ruz-input w-48"
              placeholder="Instant Course Credits"
              value={metadata.instantCourseCredits || 0}
              onInput={(e: any) =>
                setMetadata((m) => ({ ...m, instantCourseCredits: parseInt(e.target.value, 10) }))
              }
            />
          </div>
        )}
      </section>
      <section class="py-6">
        <h2 class="text-lg text-gray-900 font-medium mb-2">
          Connected Courses
          <BtnSecondary
            class="ml-4"
            onClick={() =>
              showModalForm(({ resolve }) => {
                return (
                  <StandardDialog title="Choose a course" onClose={resolve} contentWidth>
                    <TenantCoursesList
                      type="allWithNoData"
                      dontRewriteUrl
                      onItemSelect={(course) => {
                        if (!connectedCourses.find((c) => c.id === course.id)) {
                          setConnectedCourses([...connectedCourses, course]);
                        }
                        resolve();
                      }}
                    />
                    <DialogFooter hideConfirm onClose={resolve} />
                  </StandardDialog>
                );
              })
            }
          >
            <IcoPlus class="w-4 h-4 mr-1 opacity-75" /> Add a Course
          </BtnSecondary>
        </h2>
        <p class="mb-6">
          Guides who purchase this product will be enrolled to those courses as a student.
        </p>
        <div class="space-y-4">
          {connectedCourses.map((course, index) => (
            <div key={course.id} class="flex items-center space-x-4">
              <span>
                {index + 1}. {course.title}
              </span>
              <span>|</span>
              <Case
                when={user?.id !== course.guide.id}
                fallback={
                  <Button
                    class="text-indigo-800"
                    href={URLS.guide.course({
                      courseId: course.id,
                    })}
                  >
                    View
                  </Button>
                }
              >
                <Button
                  class="text-indigo-600"
                  onClick={async () => {
                    await rpx.admin.mimicUser({ userId: course.guide.id });
                    location.assign(
                      URLS.guide.course({
                        courseId: course.id,
                      }),
                    );
                  }}
                >
                  Mimic &amp; view
                </Button>
              </Case>
              <span>|</span>
              <Button
                class="text-indigo-600"
                onClick={() =>
                  setConnectedCourses(connectedCourses.filter((c) => c.id !== course.id))
                }
              >
                <IcoTrash class="w-4 h-4" />
              </Button>
            </div>
          ))}
        </div>
      </section>
      <footer class="flex items-center mt-8">
        <BtnPrimary class="px-12 capitalize">Save</BtnPrimary>
      </footer>
    </AsyncForm>
  );
}
