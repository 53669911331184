import { on } from 'minidoc-editor';
import { useEffect, useMemo } from 'preact/hooks';

const MAX_HISTORY = 1024;
const HISTORY_INTERVAL = 150; // Milliseconds to wait before applying a new state change

export function useUndoRedo<T>(state: T, setState: (state: T) => void) {
  const undoRedo = useMemo(() => {
    let nextVal = state;
    let timeout: any = undefined;
    const hist = {
      index: 0,
      arr: [state],
    };

    function applyNextVal() {
      if (nextVal === hist.arr[hist.index]) {
        return;
      }
      hist.index = Math.min(hist.index + 1, MAX_HISTORY - 1);
      hist.arr = hist.arr.slice(0, hist.index).concat(nextVal);
    }

    function move(direction: 1 | -1) {
      if (!hist.arr.length) {
        return;
      }

      const index = Math.max(0, Math.min(hist.index + direction, hist.arr.length - 1));

      if (hist.index === index) {
        return;
      }

      applyNextVal();
      hist.index = index;
      nextVal = hist.arr[index];
      setState(hist.arr[index]);
    }

    return {
      set nextVal(val: T) {
        if (val === nextVal) {
          return;
        }
        nextVal = val;
        clearTimeout(timeout);
        timeout = setTimeout(applyNextVal, HISTORY_INTERVAL);
      },

      undo: () => move(-1),
      redo: () => move(1),
    };
  }, [setState]);

  undoRedo.nextVal = state;

  useEffect(
    () =>
      on(document, 'keydown', (e: KeyboardEvent) => {
        if (e.defaultPrevented) {
          return;
        }
        const isCtrl = e.metaKey || e.ctrlKey;
        const isRedo = isCtrl && e.shiftKey && e.code === 'KeyZ';
        const isUndo = isCtrl && e.code === 'KeyZ';
        if (!isRedo && !isUndo) {
          return;
        }
        e.preventDefault();
        isRedo ? undoRedo.redo() : undoRedo.undo();
      }),
    [undoRedo],
  );

  return undoRedo;
}
