import { ComponentChildren } from 'preact';

type Props = {
  title: string;
  description?: string;
  children?: ComponentChildren;
};

export function Section({ title, description, children }: Props) {
  return (
    <div class="py-6 border-b border-dashed">
      <p class="mb-4 font-bold text-gray-700 uppercase tracking-wider">{title}</p>
      {children}
      {!!description && <p class="text-gray-600 text-xs mt-3">{description}</p>}
    </div>
  );
}
