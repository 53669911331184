/**
 * The testimonial callout block.
 */
import { EditorProps, SalesBlockInitialState, ViewerProps } from './types';
import { ImgProp, MultilineProp } from './editable';

interface State {
  quote: string;
  pic: string;
  title: string;
  subtitle: string;
}

export const name = 'Highlighted Testimonial';

export const type = 'testimonial';

export const initialBlockState: SalesBlockInitialState = {
  type,
  bgcolor: 'rgb(37, 99, 235)',
};

export const initialState: State = {
  quote:
    '"Wow. I learned so much! I can now do things that I thought would take years of training. Thanks, so much!!"',
  pic: 'https://primer.tailwindui.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Favatar-1.fe0baac2.png&w=96&q=75',
  title: 'Flannel Sam',
  subtitle: 'Former student',
};

export function MiniView() {
  return (
    <span class="flex justify-center bg-gray-500 py-4">
      <span class="flex flex-col items-center w-3/4">
        <span class="block bg-white opacity-50 rounded-sm h-1 mb-2 w-1/2"></span>
        <span class="block bg-white opacity-50 rounded-sm h-1 mb-2 w-3/4"></span>
        <span class="flex items-center justify-center w-1/2">
          <span class="bg-white opacity-75 rounded-full h-2 w-2"></span>
          <span class="w-1/4 ml-2 bg-white opacity-75 rounded-full h-1"></span>
        </span>
      </span>
    </span>
  );
}

function View(props: EditorProps<State> | ViewerProps<State>) {
  return (
    <div class="lg:px-20 max-w-4xl w-full mx-auto relative">
      <p class="mb-8 text-2xl font-medium leading-10 text-center">
        <MultilineProp {...props} path="quote" class="text-center" />
      </p>
      <div class="flex items-center mx-auto justify-center">
        <ImgProp
          {...props}
          allowDelete
          path="pic"
          width="80"
          wrapperClass="mr-6 w-20 h-20 rounded-full border-4 border-white shadow-xl"
          class="w-full h-full flex items-center justify-center rounded-full"
        />
        <div class="flex flex-col">
          <div class="font-medium text-lg text-white leading-tight">
            <MultilineProp {...props} path="title" />
          </div>
          <div class="opacity-74 text-base">
            <MultilineProp {...props} path="subtitle" />
          </div>
        </div>
      </div>
    </div>
  );
}

export const Editor = View;

export const Viewer = View;
