/**
 * Module math contains functions for payment-related math.
 */
import { CouponRow, PriceRow, PaymentType } from 'server/types';

export type BatchPrice = Pick<
  PriceRow,
  'priceInCents' | 'currency' | 'paymentType' | 'numPayments'
>;

export type BatchCoupon = Pick<CouponRow, 'amountOff' | 'percentOff' | 'duration' | 'numPayments'>;

interface Batch<T extends BatchPrice> {
  initial?: T;
  final: T;
}

/**
 * discountPrice returns the discount amount in cents. This is the amount off,
 * *not* the amount the customer will pay. To compute how much the customer
 * will pay while the discount is applied, do something like this:
 *
 * const total = price.priceInCents - discountPrice({ price, coupon });
 */
export function discountPrice({
  price,
  coupon,
}: {
  price: Pick<PriceRow, 'priceInCents' | 'currency'>;
  coupon?: Pick<CouponRow, 'amountOff' | 'percentOff'>;
}) {
  if (!coupon?.percentOff && !coupon?.amountOff) {
    return 0;
  }
  if (coupon.percentOff === 100 || (coupon.amountOff && coupon.amountOff >= price.priceInCents)) {
    return price.priceInCents;
  }
  return coupon.amountOff
    ? coupon.amountOff
    : Math.ceil(price.priceInCents * ((coupon.percentOff || 0) / 100));
}

/**
 * Get the free trial period that applies to the given price / coupon combo.
 */
export function freeTrialPeriod({
  price,
  coupon,
}: {
  price?: { paymentType: PaymentType; freeTrialPeriod?: number };
  coupon?: { freeTrialPeriod?: number };
}) {
  if (price?.paymentType == 'paymentplan' || price?.paymentType === 'subscription') {
    return Math.max(price.freeTrialPeriod || 0, coupon?.freeTrialPeriod || 0);
  }
}

/**
 * totalPrice returns the initial price the customer will pay. This is the
 * price minus any discount.
 */
export function initialPrice(props: {
  price: Pick<PriceRow, 'priceInCents' | 'currency'>;
  coupon?: Pick<CouponRow, 'amountOff' | 'percentOff'>;
}) {
  return props.price.priceInCents - discountPrice(props);
}

function isFullDuration({ price, coupon }: { price: BatchPrice; coupon?: BatchCoupon }): boolean {
  if (!coupon) {
    return false;
  }
  return (
    price.paymentType === 'paid' ||
    coupon.duration === 'forever' ||
    (coupon.duration === 'repeating' &&
      !!coupon.numPayments &&
      !!price.numPayments &&
      coupon.numPayments >= price.numPayments)
  );
}

/**
 * isFree determines whether the price + coupon combo results in a free
 * product. This means, free in totality. That is, if the discount is 100% off
 * the first N payments, and the price is N+M, this will return false.
 */
export function isFree(props: { price: BatchPrice; coupon?: BatchCoupon }) {
  const { price, coupon } = props;
  if (price.priceInCents === 0 || price.paymentType === 'free') {
    return true;
  }
  if (!coupon) {
    return false;
  }
  return discountPrice(props) >= price.priceInCents && isFullDuration(props);
}

/*
 * Sums the final and initial(if exists) batches and multiplies the price with `numPayments`
 * to get an estimated purchase amount. This is used to calculate estimated income
 * from Ruzuku price points.
 */
export function expectedPurchaseAmount(props: { price: BatchPrice; coupon?: BatchCoupon }) {
  const { initial, final } = batches(props);

  let amount = (final.numPayments || 1) * final.priceInCents;

  if (initial) {
    amount += (initial.numPayments || 1) * initial.priceInCents;
  }

  return amount;
}

/**
 * batches converts a price and coupon into two payment batches if the
 * coupon does not cover the duration of the price. Otherwise, it returns
 * a single batch.
 */
export function batches<T extends BatchPrice>(props: {
  price: T;
  coupon?: Pick<CouponRow, 'amountOff' | 'percentOff' | 'duration' | 'numPayments'>;
}): Batch<T> {
  const { price, coupon } = props;
  if (isFree(props)) {
    return { final: { ...price, priceInCents: 0 } };
  }
  if (!coupon) {
    return { final: price };
  }
  const discountedPriceInCents = initialPrice(props);
  if (isFullDuration(props)) {
    return { final: { ...price, priceInCents: discountedPriceInCents } };
  }
  return {
    initial: {
      ...price,
      priceInCents: discountedPriceInCents,
      numPayments: coupon.duration === 'once' ? 1 : coupon.numPayments,
    },
    final: {
      ...price,
      numPayments: price.numPayments && price.numPayments - (coupon.numPayments || 1),
    },
  };
}
