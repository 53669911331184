// Emails for enrollment capacity management
// See server/dbx/enrollment-caps.ts
import { render } from 'preact-render-to-string';
import { Line, BasicEmailWrapper, PrimaryAction, SupportFooter, Heading, Link } from './shared';
import { URLS } from 'shared/urls';

type Props = {
  tenant: { domain: string };
  course: {
    id: UUID;
    title: string;
  };
  price?: {
    id: string;
    name: string;
  };
  state: string; // course capacity status: eg: "80% full" or "full"
};
export function EnrollmentCapNotice({ tenant, course, price, state }: Props) {
  // The call to action links either to the course page or the individual price edit screen
  const actionUrl = price
    ? URLS.guide.price({
        courseId: course.id,
        priceId: price.id,
        domain: tenant.domain,
      })
    : URLS.guide.coursePage({
        courseId: course.id,
        domain: tenant.domain,
        page: 'settings/limits',
      });

  return render(
    <BasicEmailWrapper footer={<SupportFooter />}>
      <Heading>
        {price ? 'Price point' : 'Course enrollment'} is {state}
      </Heading>
      <Line>
        Your {price ? `price point "${price.name}" for course` : 'course'} "{course.title}" is{' '}
        {state}.
      </Line>
      <Line>
        You can view current signups or edit enrollment limits at your manage{' '}
        {price ? 'price point' : 'course'} page.
      </Line>
      <PrimaryAction href={actionUrl}>Edit Limits</PrimaryAction>
      <Line>
        Have questions? Feel free to <Link href="mailto:support@ruzuku.com">contact support</Link>{' '}
        any time.
      </Line>
    </BasicEmailWrapper>,
  );
}
