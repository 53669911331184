/**
 * Manage courses view
 */

import { useEffect, useState } from 'preact/hooks';
import { CreateBundleModal } from './create-course-modal';
import { IcoPlus } from '@components/icons';
import { onCourseDeleted } from '@course/components/course-action-modals';
import { BtnPrimary, BtnSecondary } from '@components/buttons';
import { load, CourseType } from './types';
import { EmptyScreen } from './empty-screen';
import { useCurrentTenant, useCurrentUser } from '@components/router/session-context';
import { FreeTierBanner } from '@course/components/guide-course-page/free-tier-banner';
import { CourseList } from './course-list';
import CourseItem from './course-item';
import { useIntl } from 'shared/intl/use-intl';
import { CrossTenantCoursesBanner } from './cross-tenant-courses-banner';
import { CreateCourseModal } from '@course/components/create-course-modal';
import { RootPageLayout } from '@components/root-page-layout';
import { LoadedProps } from '@components/router';
import { ProTierBanner } from '@course/components/guide-course-page/pro-tier-banner';
import { defCoursesRoute } from '@course/components/courses-app-router';

function TenantMigrationNotice() {
  const [isVisible, setIsVisible] = useState(true);
  return (
    <div
      class={`${
        isVisible ? 'block' : 'hidden'
      } shadow-xl rounded-2xl border p-12 max-w-96 mx-auto my-20 relative space-y-4`}
    >
      <h2 class="text-2xl">Hold tight!</h2>
      <p>
        We're moving your courses to the newest version of our hosting platform. You'll see them
        here soon.
      </p>
      <footer class="mt-4" onClick={() => setIsVisible(false)}>
        <BtnSecondary>Dismiss</BtnSecondary>
      </footer>
    </div>
  );
}

function Page(props: LoadedProps<typeof load>) {
  const intl = useIntl();
  const tenant = useCurrentTenant();
  const { courses } = props.state;
  const me = useCurrentUser()!;
  const courseType: CourseType =
    props.url === 'bundles' ? 'bundles' : props.url === 'products' ? 'products' : 'courses';
  const [showNewBundleModal, setShowNewBundleModal] = useState(false);
  const [showNewCourseModal, setShowNewCourseModal] = useState(!!props.params.newRibbon);
  const isStudentOnly = me.level === 'student';

  useEffect(() => {
    return onCourseDeleted(({ courseId }) => {
      props.setState((s) => ({
        ...s,
        courses: s.courses.filter((c) => c.id !== courseId),
      }));
    });
  });

  return (
    <RootPageLayout
      courses={courses}
      activeTab={courseType}
      title={
        courseType === 'courses'
          ? intl('My Courses')
          : courseType === 'products'
          ? intl('My Products')
          : intl('My Bundles')
      }
      actions={
        !isStudentOnly && (
          <BtnPrimary
            onClick={() =>
              courseType === 'bundles' ? setShowNewBundleModal(true) : setShowNewCourseModal(true)
            }
            class="pl-4 pr-5 rounded-full gap-2"
          >
            <IcoPlus />
            {courseType === 'bundles' && intl('New bundle')}
            {courseType === 'courses' && intl('New course')}
            {courseType === 'products' && intl('New product')}
          </BtnPrimary>
        )
      }
    >
      <ProTierBanner />
      {!isStudentOnly && me.isRestricted && <FreeTierBanner class="mb-8" />}
      {!isStudentOnly && (
        <CreateCourseModal
          key={courseType}
          isOpen={showNewCourseModal}
          initialPane={courseType === 'courses' ? 'course' : 'newproduct'}
          hide={() => setShowNewCourseModal(false)}
        />
      )}
      {tenant.isMigrating && <TenantMigrationNotice />}
      {courses.length === 0 && (
        <EmptyScreen
          title={courseType === 'bundles' ? intl('No Bundles') : intl('No Courses')}
          subtext={
            isStudentOnly
              ? intl('When you register for a course, it will show up here.')
              : intl(`When you create courses, they'll show up here.`)
          }
          onNewCourse={isStudentOnly ? undefined : () => setShowNewCourseModal(true)}
        />
      )}
      {props.state.crossTenant.length > 0 && (
        <CrossTenantCoursesBanner tenants={props.state.crossTenant} />
      )}
      {courses.length > 0 && (
        <CourseList
          courses={courses}
          type={courseType}
          renderItem={({ course, listType, searchTerm, displayDate }) => (
            <CourseItem
              key={course.id}
              listType={listType}
              course={course}
              highlightTerm={searchTerm}
              displayDate={displayDate}
              isArchived={!isStudentOnly && course.isArchived}
            />
          )}
        />
      )}
      {showNewBundleModal && (
        <CreateBundleModal isProduct={false} onCancel={() => setShowNewBundleModal(false)} />
      )}
    </RootPageLayout>
  );
}

export const route = defCoursesRoute({
  Page,
  authLevel: 'student',
  load,
});
