/**
 * The rich content section for the sales page builder.
 */
import { EditorProps, ViewerProps } from './types';
import { RichProp } from './editable';

interface State {
  richText: string;
}

export const initialState: State = {
  richText: `<h2>Say something interesting!</h2>
  <p>You have interesting things to say, and this is a good place to say them. Add any content you like here.</p><p><br></p><p>Maybe describe the offering in more detail, or add a nice image or video, or your bio. Sales page content should be brief and direct.</p>`.replace(
    /\n/g,
    '',
  ),
};

export const name = 'Rich Content';

export const type = 'rich';

export function MiniView() {
  return (
    <span class="block py-4">
      <span class="w-4/6 mx-auto block">
        <span class="block bg-gray-500 opacity-75 rounded-sm h-2 mb-2 w-1/2"></span>
        <span class="block bg-gray-600 opacity-50 rounded-sm h-1 mb-2 w-full"></span>
        <span class="block bg-gray-600 opacity-50 rounded-sm h-1 mb-2 w-3/4"></span>
      </span>
    </span>
  );
}

function View(props: ViewerProps<State> | EditorProps<State>) {
  return (
    <div class="w-full max-w-3xl mx-auto">
      <RichProp {...props} path="richText" />
    </div>
  );
}

export const Viewer = View;

export const Editor = View;

export function generateState({ richText }: { richText: State['richText'] }): State {
  return { richText };
}
