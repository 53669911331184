import { Attachments } from '@course/components/attachments';
import { AttachmentContent } from '@course/components/attachments/attachment-item';
import { BtnPrimary, BtnSecondary } from '@components/buttons';
import { JSX } from 'preact';
import { StudentPollChoice, StudentPollQuestion } from 'server/types';
import { AsyncForm, FormGroup } from '@components/async-form';
import { rpx } from 'client/lib/rpx-client';
import { useRouteParams } from '@components/router';
import { validationError } from 'shared/utils';
import { showError } from '@components/app-error';
import { PollResults } from './poll-results';
import { useState } from 'preact/hooks';
import { Case } from '@components/conditional';
import { validateAssessmentAnswers } from 'shared/assessments';
import { useIntl } from 'shared/intl/use-intl';

interface Props {
  questions: StudentPollQuestion[];
  isSubmitted: boolean;
  allowRetake: boolean;
  onStatusChange: (questions: undefined | StudentPollQuestion[]) => void;
}

interface ChoiceItemProps extends JSX.InputHTMLAttributes<HTMLInputElement> {
  choice: StudentPollChoice;
  galleryView: boolean;
  handleChange: () => void;
}

function ChoiceItem(props: ChoiceItemProps) {
  const { choice, galleryView, checked, handleChange, ...inputProps } = props;

  return (
    <div class="mt-4 rounded-sm relative cursor-pointer dark:text-gray-200" onClick={handleChange}>
      <Case when={galleryView}>
        <div
          class={`lg:flex justify-center items-center mb-2 p-2 lg:h-60 lg:max-h-60 lg:bg-gray-100 lg:dark:bg-gray-900 rounded overflow-hidden ${
            choice.file ? 'border-2' : ''
          } ${props.checked ? 'border-indigo-500' : 'border-transparent'}`}
        >
          {choice.file && <AttachmentContent attachment={choice.file} />}
        </div>
      </Case>
      <input
        {...inputProps}
        class="ml-1 mr-3 h-5 w-5 border-gray-500 dark:bg-gray-600 checked:bg-indigo-600 dark:checked:bg-sky-500"
        tabIndex={-1}
        checked={checked}
        value={choice.id}
        onClick={(e) => {
          e.stopPropagation();
          handleChange();
        }}
      />
      <span>{choice.content}</span>
    </div>
  );
}

function QuestionItem({ question }: { question: StudentPollQuestion }) {
  const hasAttachment = question.choices.some((c) => c.file);
  const [selectedChoices, setSelectedChoices] = useState(() => new Set<UUID>());

  function toggleChoice(id: UUID) {
    if (!question.isMultipleChoice) {
      setSelectedChoices(new Set([id]));
      return;
    }

    const result = new Set(selectedChoices);
    result.has(id) ? result.delete(id) : result.add(id);
    setSelectedChoices(result);
  }

  return (
    <div>
      <FormGroup prop={question.id}>
        <p class="whitespace-pre-line mb-4">
          {question.content || 'Question'}
          {question.isRequired && <span class="text-red-600 ml-2 text-lg">*</span>}
        </p>
        {question.file && <Attachments attachments={[question.file]} />}
        <div class={hasAttachment ? 'lg:grid grid-cols-2 gap-8' : 'space-y-4'}>
          {question.choices.map((choice) => {
            return (
              <ChoiceItem
                key={choice.id}
                name={`[]${question.id}`}
                choice={choice}
                type={question.isMultipleChoice ? 'checkbox' : 'radio'}
                galleryView={hasAttachment}
                checked={selectedChoices.has(choice.id)}
                handleChange={() => toggleChoice(choice.id)}
              />
            );
          })}
        </div>
      </FormGroup>
    </div>
  );
}

export function Poll({ questions, isSubmitted, allowRetake, onStatusChange }: Props) {
  const intl = useIntl();
  const { lessonId } = useRouteParams();
  const [answeredQuestions, setAnsweredQuestions] = useState(questions);
  const [isRetaking, setIsRetaking] = useState(false);

  async function submit(values: Record<UUID, UUID[]>) {
    const answers = Object.keys(values).map((key) => ({
      questionId: key,
      choiceIds: values[key],
    }));
    const errors = validateAssessmentAnswers({
      questions,
      answers,
      intl,
    });
    if (errors.length) {
      throw validationError(...errors);
    }

    try {
      const response = await rpx.assessments.answerPollQuestions({
        lessonId,
        answers,
      });
      setAnsweredQuestions(response);
      onStatusChange(response);
    } catch (err) {
      showError(err);
    }
  }

  async function retakePoll() {
    setIsRetaking(true);
    try {
      await rpx.assessments.retakeAssessment({
        lessonId,
      });
      onStatusChange(undefined);
    } catch (err) {
      showError(err);
    } finally {
      setIsRetaking(false);
    }
  }

  return (
    <AsyncForm
      class="drop-shadow-xl shadow-xs bg-white dark:bg-gray-800 border border-gray-100 dark:border-transparent md:-mx-8 rounded-lg dark:border-gray-700 my-10 p-10"
      onSubmit={submit}
    >
      <header class="flex flex-col space-y-4 lg:space-y-0 lg:flex-row lg:justify-between">
        <h2 class="text-lg font-medium dark:text-gray-200">{intl('Poll')}</h2>
        {allowRetake && isSubmitted && (
          <BtnSecondary isLoading={isRetaking} onClick={retakePoll}>
            {intl('Retake Poll')}
          </BtnSecondary>
        )}
      </header>
      <section class="mt-6 dark:text-gray-400">
        <Case when={!isSubmitted} fallback={<PollResults questions={answeredQuestions} />}>
          <div class="space-y-10">
            {questions.map((question) => (
              <QuestionItem key={question.id} question={question} />
            ))}
          </div>
          <footer class="pt-10">
            <BtnPrimary class="w-full p-4 dark:bg-gray-900">{intl('Submit Poll')}</BtnPrimary>
          </footer>
        </Case>
      </section>
    </AsyncForm>
  );
}
