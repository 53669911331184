import { render } from 'preact-render-to-string';
import { Course, User } from 'server/types';
import { URLS } from 'shared/urls';
import { Internationalize } from 'shared/intl';
import { Line, BasicEmailWrapper, PrimaryAction, SupportFooter } from './shared';

type MailUser = Pick<User, 'id' | 'name' | 'email' | 'displayName'>;

interface Props {
  intl: Internationalize;
  course: Pick<Course, 'id' | 'title'>;
  guide: MailUser;
  student: MailUser;
  tenant: {
    domain: string;
  };
}

export function CertificationEarnedEmail({ intl, student, guide, course, tenant }: Props) {
  return render(
    <BasicEmailWrapper footer={<SupportFooter />}>
      <Line>
        {intl('Hi {userName:string},', {
          userName: student.displayName || student.name || '',
        })}
      </Line>
      <Line>
        {intl('You completed {course:string} and earned a certicate!', {
          course: course.title,
        })}
      </Line>
      <Line>{intl('Best,')}</Line>
      <Line> -- {guide.displayName || guide.name}</Line>

      <PrimaryAction
        href={URLS.student.certificate({
          course,
          userId: student.id,
          domain: tenant.domain,
        })}
      >
        {intl('View Certificate')}
      </PrimaryAction>
    </BasicEmailWrapper>,
  );
}
