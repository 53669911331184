import { LoadedProps, defRoute } from '@components/router';
import { RuzcalMgmtPage } from '../mgmt-page';
import { PageContent, PageSection } from '../common';
import { rpx } from 'client/lib/rpx-client';
import { ZoomIntegration } from './zoom-integration';
import { ExternalCalendarForm } from './external-calendars';
import { IcalShareUrlForm } from './ical-share-url-form';
import { ComponentChildren } from 'preact';

export const route = defRoute({ authLevel: 'guide', load, Page });

async function load() {
  const integrations = await rpx.ruzcal.getCalIntegrations();
  return { integrations };
}

function IntegrationSection(props: { title: string; children: ComponentChildren }) {
  return (
    <section class="flex flex-col gap-4 mb-4">
      <header>
        <h2 class="font-semibold text-lg">{props.title}</h2>
      </header>
      {props.children}
    </section>
  );
}

function Page({ state, setState }: LoadedProps<typeof load>) {
  const { integrations } = state;

  return (
    <RuzcalMgmtPage title="Integrations" currentPage="integrations">
      <PageContent>
        <PageSection>
          <IntegrationSection title="Video conferencing">
            <ZoomIntegration hasAccount={integrations.hasZoom} oauth={integrations.zoomOauth} />
          </IntegrationSection>
          <IntegrationSection title="Import external events">
            <ExternalCalendarForm
              externalIcalUrl={integrations.externalIcalUrl}
              setExternalIcalUrl={(externalIcalUrl) =>
                setState((s) => ({ ...s, integrations: { ...s.integrations, externalIcalUrl } }))
              }
              hasGoogle={integrations.hasGoogle}
              disconnectGoogle={() =>
                setState((s) => ({
                  ...s,
                  integrations: { ...s.integrations, hasGoogle: false },
                }))
              }
            />
          </IntegrationSection>
          <IntegrationSection title="Export Ruzuku calendar events">
            <IcalShareUrlForm
              icalShareUrl={integrations.icalShareUrl}
              setIcalShareUrl={(icalShareUrl) =>
                setState((s) => ({ ...s, integrations: { ...s.integrations, icalShareUrl } }))
              }
            />
          </IntegrationSection>
        </PageSection>
      </PageContent>
    </RuzcalMgmtPage>
  );
}
