import { useRef, useEffect, Inputs, useState } from 'preact/hooks';
import { on } from 'minidoc-editor';

/**
 * useAnyKeyDown calls the specified callback any time any keydown event fires on the window.
 */
export function useAnyKeyDown(callback: (e: KeyboardEvent) => void) {
  const ref = useRef(callback);
  ref.current = callback;
  useEffect(() => on(window, 'keydown', (e) => ref.current(e)), []);
}

/**
 * useCtrlKey allows applications to hook into ctrl / cmd + key shortcuts.
 */
export function useCtrlKey(key: string, callback: (e: KeyboardEvent) => void) {
  useAnyKeyDown((e) => (e.ctrlKey || e.metaKey) && e.code === key && callback(e));
}

/**
 * useCtrlSaveKey registers Ctrl / Cmd + S and calls the specified callback.
 * This does no error handling, so that is left up to the caller.
 */
export function useCtrlSaveKey(callback: (e: KeyboardEvent) => any, key = 'KeyS') {
  useCtrlKey(key, (e) => {
    e.preventDefault();
    callback(e);
  });
}

/*
 * Takes a list of items and allows the user to navigate them with the arrow keys.
 * When the user presses enter, the `onSelect` function is called with the selected item.
 */
export function useKeyNavigation({
  items,
  inputs,
  onSelect,
}: {
  items: string[];
  // The inputs that should trigger a reset of the index when they change.
  inputs: Inputs;
  onSelect: (id: string) => void;
}) {
  const [index, setIndex] = useState(0);

  useAnyKeyDown((e) => {
    if (index >= 0 && e.code === 'Enter') {
      e.preventDefault();
      const match = items[index];
      if (match) {
        onSelect(match);
      }
    } else if (e.code === 'ArrowUp' || e.code === 'ArrowDown') {
      e.preventDefault();
      const direction = e.code === 'ArrowUp' ? -1 : 1;
      const newIndex = Math.max(0, Math.min(items.length - 1, index + direction));
      setIndex(newIndex);
    }
  });

  useEffect(() => setIndex(0), inputs);

  return items[index];
}
