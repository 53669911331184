import { useEffect, useMemo, useRef } from 'preact/hooks';
import { serialAsync } from './serial-async';

export function useAsyncInterval(
  callback: () => Promise<void>,
  timing?: {
    interval: number;
    initialDelay: number;
  },
) {
  const savedCallback = useRef(callback);
  const serialLoad = useMemo(() => serialAsync(() => savedCallback.current()), []);

  savedCallback.current = callback;

  useEffect(() => {
    if (!timing) {
      return;
    }

    // Without this canceled boolean, there is an edge case where we clear
    // the timeout, and then the promises finish resolving, and then we re-
    // set up the timeout loop, never to be canceled until the end of time...
    let canceled = false;
    let timeout = setTimeout(function tick() {
      serialLoad()
        .catch()
        .then(() => {
          if (!canceled) {
            timeout = setTimeout(tick, timing.interval);
          }
        });
    }, timing.initialDelay);

    return () => {
      canceled = true;
      clearTimeout(timeout);
    };
  }, [timing?.interval, timing?.initialDelay]);
}

export function useInterval(callback: () => void, delay?: number) {
  const savedCallback = useRef(callback);

  useEffect(() => {
    if (delay === undefined) {
      return;
    }

    let timeout = setTimeout(function tick() {
      savedCallback.current();
      timeout = setTimeout(tick, delay);
    }, delay);

    return () => clearTimeout(timeout);
  }, [delay]);
}
