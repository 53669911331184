import { Toggle } from '@components/toggle';
import { CourseRow, Email, Meeting, MeetingReminder, MeetingReminderType } from 'server/types';
import { DefaultMeetingData, getDefaultMeetingMessages } from 'shared/messages';
import { useState } from 'preact/hooks';
import { Case } from '@components/conditional';
import { useDidUpdateEffect } from 'client/utils/use-did-update-effect';
import { minutesToTime, minutesToTimeString } from 'shared/dateutil';
import { showError } from '@components/app-error';
import { rpx } from 'client/lib/rpx-client';
import { useCurrentUser } from '@components/router/session-context';
import { showToast } from '@components/toaster';
import { useRouteParams } from '@components/router';
import { Button } from '@components/buttons';
import { useIntl } from 'shared/intl/use-intl';
import dayjs from 'dayjs';
import { TimeEditModal } from '@course/components/time-edit-modal';
import { MessageEditor, PreviewModal } from '@components/messages';

type MeetingRemindersData = Pick<
  Meeting,
  'scheduledAt' | 'startingReminderEnabled' | 'firstReminder' | 'secondReminder'
>;

interface Props {
  course: Pick<CourseRow, 'isProduct'>;
  meeting: MeetingRemindersData;
  onChange: (meeting: Partial<MeetingRemindersData>) => Promise<unknown>;
}

export function RemindersTab({ course, meeting, onChange }: Props) {
  const intl = useIntl(true);
  const { firstReminder, secondReminder } = meeting;
  const defaultMessages = getDefaultMeetingMessages(intl);

  return (
    <div class="py-4">
      <Reminder
        course={course}
        meeting={meeting}
        type="first"
        reminder={firstReminder}
        defaultReminder={defaultMessages.first}
        onChange={(data) => {
          onChange({
            firstReminder: data,
          });
        }}
      />
      <Reminder
        course={course}
        meeting={meeting}
        type="second"
        reminder={secondReminder}
        defaultReminder={defaultMessages.second}
        onChange={(data) => {
          onChange({
            secondReminder: data,
          });
        }}
      />
      <div class="bg-gray-50 rounded-sm mb-8 overflow-hidden relative">
        <div class="flex items-center p-4 border-b">
          <div class="grow">
            <h2 class="font-bold mr-4">Meeting is starting reminder</h2>
            <p>This email is sent to the student when the meeting is about to start.</p>
          </div>
          <Toggle
            checked={meeting.startingReminderEnabled}
            onClick={() => {
              onChange({
                startingReminderEnabled: !meeting.startingReminderEnabled,
              });
            }}
          />
        </div>
      </div>
    </div>
  );
}

interface ReminderProps {
  course: Pick<CourseRow, 'isProduct'>;
  meeting: MeetingRemindersData;
  type: MeetingReminderType;
  reminder: MeetingReminder;
  defaultReminder: DefaultMeetingData;
  onChange: (reminder: MeetingReminder) => void;
}

function Reminder({ course, meeting, type, reminder, defaultReminder, onChange }: ReminderProps) {
  const intl = useIntl();
  const user = useCurrentUser();
  const { courseId, meetingId } = useRouteParams();

  const [state, setState] = useState<MeetingReminder>(() => ({
    ...defaultReminder,
    ...reminder,
  }));
  const isCustomized =
    !!reminder.title &&
    !!reminder.content &&
    (reminder.title !== defaultReminder.title || reminder.content !== defaultReminder.content);
  const time = minutesToTime(state.offsetMinutes);

  const [isEditing, setIsEditing] = useState(false);
  const [isSendingPreview, setIsSendingPreview] = useState(false);
  const [previewEmail, setPreviewEmail] = useState<Email | undefined>(undefined);

  /*
   * Storing the reminder data in a state
   * because of a weird problem that reverts enabled
   * toggle whenever title or content changes.
   * It is working a lot more stable this way.
   *
   * Doing this with `useDidUpdateEffect` to skip the initial render.
   */
  useDidUpdateEffect(() => {
    onChange(state);
  }, [state]);

  async function displayEmailPreview() {
    setIsSendingPreview(true);
    try {
      const result = await rpx.meetings.getMeetingReminderPreview({
        courseId,
        meetingId,
        type,
      });
      setPreviewEmail(result);
    } catch (err) {
      showError(err);
    } finally {
      setIsSendingPreview(false);
    }
  }

  async function sendPreview() {
    setIsSendingPreview(true);
    try {
      await rpx.meetings.sendMeetingReminderPreview({
        courseId,
        meetingId,
        type,
      });
      showToast({
        type: 'ok',
        title: 'Reminder sent',
        message: 'Meeting reminder preview message sent successfully.',
      });
      setPreviewEmail(undefined);
    } catch (err) {
      showError(err);
    } finally {
      setIsSendingPreview(false);
    }
  }

  return (
    <div class="bg-gray-50 rounded-sm mb-8 overflow-hidden relative">
      <div class="flex items-center p-4 border-b">
        <div class="grow">
          <h2 class="font-bold mr-4">{type === 'first' ? 'First Reminder' : 'Second Reminder'}</h2>
          <Case when={state.enabled}>
            <p>
              Scheduled to send{' '}
              <Button
                class="text-indigo-600 disabled:cursor-not-allowed"
                title="Click to customize"
                onClick={() => setIsEditing(true)}
              >
                <span class="relative inline-flex items-center justify-center whitespace-nowrap underline">
                  {minutesToTimeString(state.offsetMinutes, intl)}
                </span>
              </Button>{' '}
              before the start date.
              {isEditing && (
                <TimeEditModal
                  days={time.days}
                  hours={time.hours}
                  minutes={time.minutes}
                  onHide={() => setIsEditing(false)}
                  onApply={(newOffset) => {
                    setState((s) => ({
                      ...s,
                      offsetMinutes: newOffset,
                    }));
                    setIsEditing(false);
                  }}
                />
              )}
            </p>
          </Case>
        </div>
        <Toggle
          class="block"
          checked={state.enabled}
          onClick={() => {
            setState((s) => ({
              ...s,
              enabled: !state.enabled,
            }));
          }}
        />
      </div>
      <div class="md:m-4 bg-gray-50 md:px-4 py-8 rounded-sm">
        <div class={`flex-1 relative ${!state.enabled ? 'opacity-20' : ''}`}>
          {previewEmail && (
            <PreviewModal
              email={previewEmail}
              recipientsText={user?.email || ''}
              isSending={isSendingPreview}
              replacementOverrides={{
                '%recipient.startHour%': dayjs(meeting.scheduledAt).format('hh:mm A (z)'),
                '%recipient.startDay%': dayjs(meeting.scheduledAt).format('MMMM D, YYYY'),
              }}
              onCancel={() => setPreviewEmail(undefined)}
              onSend={sendPreview}
            />
          )}
          <MessageEditor
            isProduct={course.isProduct}
            allowPreview
            content={state.content}
            title={state.title}
            isSending={isSendingPreview}
            titleClass="bg-gray-50"
            templateFields={[
              {
                key: 'start-hour',
                title: 'Start hour',
              },
              {
                key: 'start-day',
                title: 'Start Day',
              },
              {
                key: 'meeting-duration',
                title: 'Meeting Duration',
              },
            ]}
            onSave={async (content, title) => {
              setState((s) => ({
                ...s,
                content,
                title,
              }));
            }}
            onSend={displayEmailPreview}
          />
        </div>
        <Case when={isCustomized && state.enabled}>
          <p class="italic text-right">
            You've customized this template.{' '}
            <Button
              class="text-blue-400 font-bold"
              onClick={async () => {
                await rpx.meetings.deleteMeetingReminder({
                  courseId,
                  id: meetingId,
                  type,
                });
                // This is a hack but makes the resetting feature
                // a lot more stable on all browsers.
                location.reload();
              }}
            >
              Click here
            </Button>{' '}
            to reset it to the default version.
          </p>
        </Case>
      </div>
    </div>
  );
}
