/**
 * The form for managing the secret ics synchronization URL.
 */

import { IcoLock, IcoRefresh } from '@components/icons';
import { rpx } from 'client/lib/rpx-client';
import { IntegrationRow } from './integration-row';
import { BtnCopy } from '@components/buttons';
import { showToast } from '@components/toaster';

type Props = {
  icalShareUrl: undefined | string;
  setIcalShareUrl(value: string): void;
};

export function IcalShareUrlForm(props: Props) {
  return (
    <IntegrationRow
      isMultiline
      name="Synchronization URL"
      connectText="Generate synchronization URL"
      connectedText=""
      reconnectText="Re-generate URL"
      icon={
        <span class="bg-indigo-500 text-white rounded-full flex items-center justify-center aspect-square shrink-0 size-10 text-xl font-semibold">
          <IcoRefresh class="size-5" />
        </span>
      }
      description={
        props.icalShareUrl ? (
          <>
            <p>
              Your calendar synchronization URL can be used to synchronize your Ruzuku calendar
              events with an external calendar. Paste the URL into a trusted application such as
              Apple Calendar, and your Ruzuku calendar events will show up there.{' '}
            </p>
            <footer class="mt-2">
              <BtnCopy
                icon={<IcoLock />}
                class="bg-gray-100 hover:bg-gray-200 text-gray-700 rounded-full"
                padding="px-3 p-1"
                margin="mt-2"
                value={props.icalShareUrl}
              >
                Copy synchronization URL
              </BtnCopy>
            </footer>
          </>
        ) : (
          `Get a secret calendar link that can be used to share your Ruzuku Calendar events with other calendar aplications.`
        )
      }
      isInstalled={!!props.icalShareUrl}
      onIntegrate={async () => {
        const result = await rpx.ruzcal.generateIcalShareUrl();
        props.setIcalShareUrl(result.icalUrl);
        navigator.clipboard.writeText(result.icalUrl);
        showToast({
          type: 'ok',
          title: 'Secret URL created',
          message: 'Secret URL created and copied to clipboard.',
        });
      }}
      onDisconnect={async () => {
        await rpx.ruzcal.clearIcalShareUrl();
        props.setIcalShareUrl('');
      }}
    />
  );
}
