import { render } from 'preact-render-to-string';
import {
  BasicEmailWrapper,
  PrimaryAction,
  SupportFooter,
  Heading,
  Line,
  SubHeading,
  Blockquote,
  Italic,
  DoubleLine,
  Pill,
} from './shared';
import { AssignmentQuestionReview } from 'server/types';
import { Internationalize } from 'shared/intl';

type Props = {
  intl: Internationalize;
  ctaUrl: string;
  userName: string;
  guideName: string;
  courseName: string;
  questions: AssignmentQuestionReview[];
  hasRejected: boolean;
};

/**
 * Email to send student guide feedback on assignment
 */
export function AssignmentFeedback({
  intl,
  ctaUrl,
  userName,
  guideName,
  courseName,
  questions,
  hasRejected,
}: Props) {
  return render(
    <BasicEmailWrapper footer={<SupportFooter />}>
      <Heading>{intl('You have received a review for your assignment.')}</Heading>
      <Line>{intl('Hi {userName:string},', { userName })}</Line>
      <Line>
        {intl(
          'You have just received a review from {guide:string} for your assignment on {courseName:string}.',
          {
            guide: guideName,
            courseName,
          },
        )}
      </Line>
      <div>
        {questions.map((q, index) => (
          <QuestionFeedbackItem key={`q-${index}`} intl={intl} {...q} />
        ))}
      </div>
      <PrimaryAction href={ctaUrl}>
        {hasRejected ? intl('Modify rejected answers') : intl('View assignment')}
      </PrimaryAction>
    </BasicEmailWrapper>,
  );
}

/**
 * Single row student answer with guide feedback and rejected/accepted status
 */
function QuestionFeedbackItem({
  intl,
  index,
  content,
  answer,
  feedback,
  status,
}: AssignmentQuestionReview & {
  intl: Internationalize;
}) {
  const isRejected = status === 'rejected';
  return (
    <>
      <SubHeading>
        <strong>{intl('Question {index:string}.', { index: index.toString() })}</strong> {content}
      </SubHeading>
      <Line>{intl('Your answer:')}</Line>
      <Blockquote>{answer}</Blockquote>
      {feedback && (
        <div>
          <Line>
            <Italic>{intl('Guide feedback:')}</Italic>
          </Line>
          <Blockquote style={isRejected ? 'border-color:#991b1b;' : 'border-color:#22c55e;'}>
            <Italic>{feedback}</Italic>
          </Blockquote>
        </div>
      )}

      <DoubleLine>
        {intl('Status:')}
        {isRejected ? (
          <Pill color="red">{intl('Rejected')}</Pill>
        ) : (
          <Pill color="green">{intl('Approved')}</Pill>
        )}
      </DoubleLine>
    </>
  );
}
