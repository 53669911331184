import { MeetingReminderType } from 'server/types';
import { Internationalize } from '../intl';
import { MeetingReminderContentFirst, MeetingReminderContentSecond } from 'server/mailer/templates';

export interface DefaultMeetingData {
  title: string;
  content: string;
  offsetMinutes: number;
}

export const getDefaultMeetingMessages = (
  intl: Internationalize,
): Record<MeetingReminderType, DefaultMeetingData> => {
  return {
    first: {
      title: intl('An event with {guide:string} is scheduled for {startDay:string}', {
        guide: '{{{guide-name}}}',
        startDay: '{{{start-day}}}',
      }),
      content: MeetingReminderContentFirst({ intl }),
      offsetMinutes: meetingReminderConfigs.first.offsetMinutes,
    },
    second: {
      title: intl('Your event with {guide:string} is starting in an hour', {
        guide: '{{{guide-name}}}',
      }),
      content: MeetingReminderContentSecond({ intl }),
      offsetMinutes: meetingReminderConfigs.second.offsetMinutes,
    },
  };
};

export const meetingReminderConfigs = {
  first: {
    // One day
    offsetMinutes: 1440,
  },
  second: {
    // One hour
    offsetMinutes: 60,
  },
};
