import { rpx } from 'client/lib/rpx-client';
import { UserProfileIcon } from '@components/avatars';
import { showError } from '@components/app-error';
import { ModalForm, showModalForm } from '@components/modal-form';
import { useState } from 'preact/hooks';
import { showToast } from '@components/toaster';
import { TenantUsersList } from '../admin-people/user-list';
import { User } from 'server/types';
import { StandardDialog } from '@components/dialog';

export function showMergeStudentsModal(fromUser: Pick<User, 'id' | 'name'>) {
  showModalForm(({ resolve }) => {
    const [baseUser, setBaseUser] = useState<{ id: UUID; name?: string } | undefined>(undefined);

    const mergeStudents = async () => {
      if (!baseUser) {
        return;
      }
      try {
        await rpx.admin.mergeStudentAccounts({
          base: baseUser.id,
          from: fromUser.id,
        });
        showToast({
          title: 'Merge succeeded',
          message: `Merged ${fromUser.name} into ${baseUser.name}`,
          type: 'ok',
        });
        resolve(true);
      } catch (err) {
        showError(err);
      }
    };

    if (baseUser) {
      return (
        <ModalForm
          onClose={resolve}
          onSubmit={mergeStudents}
          title={
            <>
              Merge <em>{fromUser.name}</em> into <strong>{baseUser.name}</strong>?
            </>
          }
          subtitle="This action cannot be undone."
          confirmButtonText="Yes, merge students"
        />
      );
    }

    return (
      <StandardDialog
        contentWidth
        onClose={resolve}
        title={<>Choose a base user to merge {fromUser.name} into</>}
      >
        <TenantUsersList
          type="all"
          userLevels={['student', 'guide']}
          tableHeaders={['New Guide', 'ID']}
          onSelect={setBaseUser}
          render={({ user, href }) => (
            <a
              class="table-row text-inherit hover:bg-indigo-50 text-sm"
              href={`#${href}`}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setBaseUser(user);
              }}
            >
              <span class="table-cell p-4 border-t">
                <span class="flex items-center">
                  <UserProfileIcon user={user} size="w-10 h-10" />
                  <span class="flex flex-col ml-4">
                    <strong>{user.name}</strong>
                    <span>{user.email}</span>
                  </span>
                </span>
              </span>
              <span class="table-cell p-4 border-t">
                <span class="flex flex-col">
                  <span>{user.id}</span>
                </span>
              </span>
            </a>
          )}
        />
      </StandardDialog>
    );
  });
}
