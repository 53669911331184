import { IcoExternalLink } from '@components/icons';
import { RuzcalMgmtPage } from '../mgmt-page';
import { BtnCopy, BtnPrimary, Button } from '@components/buttons';
import { PageContent, PageHeading, PageSection } from '../common';
import { AsyncForm } from '@components/async-form';
import { rpx } from 'client/lib/rpx-client';
import { LoadedProps, RouteLoadProps, defRoute, useRouteParams } from '@components/router';
import { Tab, Tabs } from '@components/tabs';
import { useBasicAutosaver } from '@components/autosaver';
import { ruzcal } from 'shared/urls';
import { LimitsTab } from './limits-tab';
import type { Duration, State } from './types';
import { BasicsTab } from './basics-tab';
import { MessagesTab } from './messages-tab';
import { useState } from 'preact/hooks';
import { LoadingIndicator } from '@components/loading-indicator';
import { showError } from '@components/app-error';
import { Pill } from '@components/pill';

type TabName = 'basics' | 'limits' | 'messages';

export const route = defRoute({
  load,
  authLevel: 'guide',
  key: ({ params }) => `event_type_${params.id}`,
  Page,
});

function genSuffix(reservedURLSuffixes: string[], existing?: string) {
  if (existing) {
    return existing;
  }
  const suffixSuggestions = ['quick-1-on-1', 'coaching', 'discovery', 'chat'];
  return (
    suffixSuggestions.find((x) => !reservedURLSuffixes.includes(x)) ||
    `meet-${Date.now().toString(32)}`
  );
}

async function upsertEventType(state: State) {
  const bufferMinsBefore = durationToMins(state.bufferMinsBefore);
  const bufferMinsAfter = durationToMins(state.bufferMinsAfter);
  const minNotice = durationToMins(state.minNotice);
  const maxPerDay = parseInt(state.maxPerDay, 10);
  await rpx.ruzcalEventTypes.saveEventType({
    id: state.id || undefined,
    name: state.name || 'Untitled event type',
    description: state.description,
    urlSuffix: state.urlSuffix,
    duration: state.duration,
    maxPerDay,
    bufferMinsBefore,
    bufferMinsAfter,
    minNotice,
    isPrivate: state.isPrivate,
    availabilityId: state.availabilityId,
    location: state.location,
    locationDetail: state.locationDetail,
    color: state.color,
  });
}

function durationToMins(duration: Duration): number {
  const value = parseInt(duration.value, 10) || 0;
  const hour = 60;
  const day = hour * 24;
  if (duration.unit === 'day') {
    return value * day;
  } else if (duration.unit === 'hour') {
    return value * hour;
  }
  return value;
}

function minsToDuration(minutes: number): Duration {
  const value = minutes;
  const hour = 60;
  const day = hour * 24;
  if (value && value % day === 0) {
    return {
      value: (value / day).toString(),
      unit: 'day',
    };
  } else if (value && value % hour === 0) {
    return {
      value: (value / hour).toString(),
      unit: 'hour',
    };
  }
  return {
    value: value.toString(),
    unit: 'minute',
  };
}

async function load(opts: RouteLoadProps): Promise<State> {
  const { id } = opts.params;
  const isNew = id === 'new';
  const [availability, reservedURLSuffixes, existing, host, integrations] = await Promise.all([
    rpx.ruzcal.getAvailabilityList(),
    rpx.ruzcal.getURLSuffixList(),
    isNew ? undefined : rpx.ruzcalEventTypes.getEventType({ id }),
    rpx.ruzcal.getHost({}),
    rpx.ruzcal.getCalIntegrations(),
  ]);
  const defaultAvailability = availability.find((a) => a.isDefault) || availability[0];
  return {
    isNew,
    reservedURLSuffixes: reservedURLSuffixes.filter((x) => x !== existing?.urlSuffix),
    id: existing?.id,
    name: existing?.name ?? 'Quick 1:1',
    description: existing?.description ?? '',
    urlSuffix: genSuffix(reservedURLSuffixes, existing?.urlSuffix),
    duration: existing?.duration ?? 30,
    minNotice: minsToDuration(existing?.minNotice ?? 60),
    hasDailyLimit: existing ? !!existing.maxPerDay : false,
    maxPerDay: (existing?.maxPerDay ?? 0).toString(),
    isPrivate: existing?.isPrivate ?? true,
    bufferMinsAfter: minsToDuration(existing?.bufferMinsAfter ?? 15),
    bufferMinsBefore: minsToDuration(existing?.bufferMinsBefore ?? 10),
    availabilityId: existing?.availabilityId ?? defaultAvailability?.id ?? '',
    urlPrefix: host!.urlPrefix,
    availability,
    location: existing?.location ?? 'zoom',
    locationDetail: existing?.locationDetail,
    color: existing?.color ?? 'indigo',
    hasZoom: integrations.hasZoom,
    zoomOauthUrl: integrations.zoomOauth,
  };
}

function EditPage(props: LoadedProps<typeof load>) {
  const { state, setState } = props;
  const params = useRouteParams();
  const tab = (params.tab || 'basics') as TabName;
  const baseURL = `/calendar/event-types/${state.id}`;
  const name = state.name || 'Untitled event type';
  const bookingUrl = `${window.location.origin}${ruzcal.newBookingUrl({
    urlPrefix: state.urlPrefix,
    urlSuffix: state.urlSuffix,
  })}`;

  const [isSaving, setIsSaving] = useState(false);

  const autosaver = useBasicAutosaver(state, async (val) => {
    // Do not autosave if the URL suffix is not valid
    if (!val.urlSuffix || val.reservedURLSuffixes.includes(state.urlSuffix)) {
      return;
    }

    try {
      setIsSaving(true);
      await upsertEventType(val);
    } catch (err) {
      showError(err);
    } finally {
      setIsSaving(false);
    }
  });

  return (
    <RuzcalMgmtPage
      title={name}
      currentPage="eventtypes"
      crumbs={[
        {
          text: 'Event types',
          href: '/calendar/event-types',
        },
        { text: name },
      ]}
      actions={
        <div class="flex items-center">
          <Button
            class="p-1 px-3 inline-flex items-center rounded-full transition-all hover:bg-gray-100 gap-2 border text-gray-800"
            href={bookingUrl}
          >
            <IcoExternalLink />
            Preview
          </Button>
          <BtnCopy
            value={bookingUrl}
            copiedText="Booking link copied"
            padding="p-1 px-3"
            class="rounded-full border hover:bg-gray-50"
            inset
          >
            Copy link
          </BtnCopy>
        </div>
      }
    >
      {isSaving && <LoadingIndicator />}
      <PageContent>
        <PageSection>
          <PageHeading
            title={
              <div class="flex items-center gap-2">
                {name}
                {autosaver.isDirty && <Pill color="yellow">Saving...</Pill>}
                {!autosaver.isDirty && <Pill color="green">Saved</Pill>}
              </div>
            }
          />

          <Tabs>
            <Tab isSelected={tab === 'basics'} href={baseURL}>
              Basics
            </Tab>
            <Tab isSelected={tab === 'limits'} href={`${baseURL}/limits`}>
              Limits
            </Tab>
            <Tab isSelected={tab === 'messages'} href={`${baseURL}/messages`}>
              Messages
            </Tab>
          </Tabs>

          <div class="flex flex-col gap-14">
            {tab === 'basics' && <BasicsTab state={state} setState={setState} />}
            {tab === 'limits' && <LimitsTab state={state} setState={setState} />}
            {tab === 'messages' && state.id && <MessagesTab eventTypeId={state.id} />}
          </div>
        </PageSection>
      </PageContent>
    </RuzcalMgmtPage>
  );
}

function CreatePage(props: LoadedProps<typeof load>) {
  const { state, setState } = props;

  return (
    <RuzcalMgmtPage
      title="Create event type"
      currentPage="eventtypes"
      crumbs={[
        {
          text: 'Event types',
          href: '/calendar/event-types',
        },
        { text: 'Create event type' },
      ]}
    >
      <PageContent>
        <PageSection>
          <header>
            <PageHeading title="Create event type" />
          </header>
          <AsyncForm
            class="flex flex-col gap-14"
            onSubmit={async () => {
              await upsertEventType(state);
              props.router.goto('/calendar/event-types');
            }}
          >
            <BasicsTab state={state} setState={setState} />
            <footer class="flex gap-4">
              <BtnPrimary class="px-4 rounded-full">Create event type</BtnPrimary>
              <Button
                href={'/calendar/event-types'}
                class="text-inherit inline-flex items-center justify-center hover:bg-gray-100 px-4 transition-all rounded-full"
              >
                Cancel
              </Button>
            </footer>
          </AsyncForm>
        </PageSection>
      </PageContent>
    </RuzcalMgmtPage>
  );
}

function Page(props: LoadedProps<typeof load>) {
  if (props.state.availability.length) {
    if (props.state.isNew) {
      return <CreatePage {...props} />;
    }
    return <EditPage {...props} />;
  }

  return (
    <RuzcalMgmtPage title="Edit event type" currentPage="eventtypes">
      <PageContent>
        <PageSection>
          <h1 class="font-semibold text-xl">
            Before you can create an event type, you must first define your availability.
          </h1>
          <p>
            Go to <a href="/calendar/availability/new">the availability page</a> to define your
            availability, then come back here to create a new event type.
          </p>
        </PageSection>
      </PageContent>
    </RuzcalMgmtPage>
  );
}
