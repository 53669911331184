import { FormGroup } from '@components/async-form';
import { useEffect, useRef, useState } from 'preact/hooks';

type Props = {
  value: number;
  propName: string;
  onChange: (value: number) => void;
};

function DurationItem(props: {
  duration: number;
  onChange: (duration: number) => void;
  selected: boolean;
}) {
  return (
    <button
      type="button"
      class={`p-2 px-4 rounded-sm border cursor-pointer transition-all ${
        props.selected ? 'bg-indigo-100' : 'hover:bg-gray-50'
      }`}
      onClick={() => props.onChange(props.duration)}
    >
      {props.duration} minutes
    </button>
  );
}

const PREDEFINED_DURATIONS = [15, 30, 60];

export function DurationInput({ value, propName, onChange }: Props) {
  const customInputRef = useRef<HTMLInputElement>(null);
  const [isEditing, setIsEditing] = useState(false);
  const durations = PREDEFINED_DURATIONS.includes(value)
    ? PREDEFINED_DURATIONS
    : [...PREDEFINED_DURATIONS, value].sort((a, b) => a - b);

  useEffect(() => {
    if (isEditing && customInputRef.current) {
      customInputRef.current.focus();
    }
  }, [isEditing]);

  return (
    <FormGroup class="flex flex-col" prop={propName}>
      <span class="mb-1">Duration</span>
      <div class="flex flex-col md:flex-row gap-2">
        {durations.map((duration) => (
          <DurationItem
            key={duration}
            duration={duration}
            onChange={onChange}
            selected={duration === value}
          />
        ))}
        {!isEditing && (
          <button
            type="button"
            class={`p-2 px-4 rounded-sm border cursor-pointer transition-all hover:bg-gray-50`}
            onClick={() => setIsEditing(true)}
          >
            Custom
          </button>
        )}
        {isEditing && (
          <div class="flex grow">
            <input
              type="number"
              ref={customInputRef}
              class="border pl-3 pr-0 border-gray-300 rounded-sm text-sm [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none outline-none"
              placeholder="Custom (minutes)"
              onBlur={(e: any) => {
                const duration = parseInt(e.target.value, 10);
                setIsEditing(false);
                if (duration > 0) {
                  onChange(duration);
                }
              }}
              onKeyDown={(e: any) => {
                // Blur on enter
                if (e.key === 'Enter') {
                  e.target.blur();
                }
              }}
            />
          </div>
        )}
      </div>
    </FormGroup>
  );
}
