import { useCurrentUser } from 'client/lib/auth';
import { IcoArrowLeft } from '@components/icons';
import { RuzcalMgmtPage } from './mgmt-page';
import { Calendar } from './calendar';
import { useState } from 'preact/hooks';
import { TimeCombobox } from './time-combobox';
import dayjs from 'dayjs';
import { Toggle } from '@components/toggle';
import { datesEq, mkdate } from '@components/date-picker';
import { PageContent, PageHeading, PageSection, defCalRoute } from './common';
import { AsyncForm } from '@components/async-form';
import { rpx } from 'client/lib/rpx-client';
import { timeOfDay } from 'shared/scheduling';
import { LoadedProps, RouteLoadProps } from '@components/router';
import { BtnPrimary, Button } from '@components/buttons';
import { useEsc } from 'client/utils/use-esc';
import { isAllDay } from './dateutil';

type State = {
  id: string;
  showCal: 'none' | 'start' | 'end';
  startDate?: Date;
  allDay: boolean;
  startTime: string;
  endDate?: Date;
  endTime: string;
};

export const route = defCalRoute({ load, authLevel: 'guide', Page });

async function load(opts: RouteLoadProps): Promise<State> {
  const { id } = opts.params;
  const isNew = id === 'new';
  if (isNew) {
    return {
      id,
      showCal: 'none',
      allDay: false,
      startTime: '9:00am',
      endTime: '5:00pm',
    };
  }
  const override = await rpx.ruzcal.getOverride({ id });
  return {
    id,
    showCal: 'none',
    startDate: mkdate((dt) => dt.setHours(0, 0, 0, 0), override.start),
    allDay: isAllDay(override),
    startTime: dayjs(override.start).format('h:mma'),
    endDate: mkdate((dt) => dt.setHours(0, 0, 0, 0), override.end),
    endTime: dayjs(override.end).format('h:mma'),
  };
}

function getToday() {
  return mkdate((dt) => dt.setHours(0, 0, 0, 0));
}

function DateRangePicker(props: { start?: Date; end?: Date; onChange(val: Date): void }) {
  const [focusedDate, setFocusedDate] = useState(() => props.start || getToday());
  const start = dayjs(props.start);
  const end = dayjs(props.end);
  return (
    <div class="grid md:grid-cols-2 gap-20">
      <Calendar
        navClass="md:hidden"
        onSelect={props.onChange}
        focusedDate={focusedDate}
        setFocusedDate={setFocusedDate}
        renderDate={(opts) => {
          if (opts.date < opts.today) {
            return null;
          }

          const isStart = datesEq(opts.date, props.start);
          const isEnd = datesEq(opts.date, props.end);
          const isBetween = start.isBefore(opts.date) && end.isAfter(opts.date);
          const isInRange = isStart || isEnd || isBetween;

          return (
            <span
              class={`aspect-square group hover:z-10 p-1 relative flex font-medium text-gray-900 ${
                isBetween ? 'bg-gray-100' : ''
              }`}
            >
              {isInRange && (
                <span
                  class={`absolute inset-0 ${isBetween ? '-inset-x-2' : ''} bg-gray-100 ${
                    isStart ? `rounded-l-full ${!isEnd ? '-right-2' : ''}` : ''
                  } ${isEnd ? `rounded-r-full ${!isStart ? '-left-2' : ''}` : ''}`}
                ></span>
              )}
              <span
                class={`relative flex items-center justify-center rounded-full aspect-square group-hover:ring-2 ring-indigo-600 ${
                  isStart || isEnd ? 'bg-indigo-600 text-white' : ''
                }`}
              >
                {opts.date.getDate()}
              </span>
            </span>
          );
        }}
      />
      <div class="flex-col hidden md:flex">
        <Calendar
          onSelect={props.onChange}
          focusedDate={dayjs(focusedDate).add(1, 'month').toDate()}
          setFocusedDate={(dt) => setFocusedDate(dayjs(dt).add(-1, 'month').toDate())}
          renderDate={(opts) => {
            if (opts.date < opts.today) {
              return null;
            }

            const isStart = datesEq(opts.date, props.start);
            const isEnd = datesEq(opts.date, props.end);
            const isBetween = start.isBefore(opts.date) && end.isAfter(opts.date);
            const isInRange = isStart || isEnd || isBetween;

            return (
              <span
                class={`aspect-square group hover:z-10 p-1 relative flex font-medium text-gray-900 ${
                  isBetween ? 'bg-gray-100' : ''
                }`}
              >
                {isInRange && (
                  <span
                    class={`absolute inset-0 ${isBetween ? '-inset-x-2' : ''} bg-gray-100 ${
                      isStart ? `rounded-l-full ${!isEnd ? '-right-2' : ''}` : ''
                    } ${isEnd ? `rounded-r-full ${!isStart ? '-left-2' : ''}` : ''}`}
                  ></span>
                )}
                <span
                  class={`relative flex items-center justify-center rounded-full aspect-square group-hover:ring-2 ring-indigo-600 ${
                    isStart || isEnd ? 'bg-indigo-600 text-white' : ''
                  }`}
                >
                  {opts.date.getDate()}
                </span>
              </span>
            );
          }}
        />
      </div>
    </div>
  );
}

function Page({ router, state, setState }: LoadedProps<typeof load>) {
  const user = useCurrentUser()!;
  const isSingleDate = state.startDate?.toDateString() === state.endDate?.toDateString();

  useEsc(
    () => {
      setState((s) => ({ ...s, showCal: 'none' }));
    },
    { respectDefault: true },
  );

  return (
    <RuzcalMgmtPage title="Block out unavailable time" currentPage="availability">
      <PageContent>
        <PageSection>
          <header class="mb-4">
            <a href="/calendar/availability" class="inline-flex items-center gap-2">
              <IcoArrowLeft />
              Back to availability
            </a>
            <PageHeading
              title="Remove dates from your availability"
              subtitle={
                <>
                  Specify dates that you'll be unavailable (in{' '}
                  <span class="text-gray-500">{user.timezone.replaceAll('_', ' ')} time).</span>
                </>
              }
            />
          </header>
          <AsyncForm
            class="flex flex-col gap-8"
            onSubmit={async () => {
              if (!state.startDate || !state.endDate) {
                throw new Error('Please select a date');
              }
              const start = timeOfDay(
                state.startDate,
                state.allDay ? '12:00am' : state.startTime,
                user.timezone,
              ).toDate();
              const end = timeOfDay(
                state.endDate,
                state.allDay ? '11:59pm' : state.endTime,
                user.timezone,
              ).toDate();
              await rpx.ruzcal.saveBlockedTime({
                id: state.id === 'new' ? undefined : state.id,
                start,
                end,
              });
              router.goto('/calendar/availability');
              await new Promise((r) => setTimeout(r, 1000));
            }}
          >
            <div class="relative">
              <header class="flex justify-start">
                <div
                  class={`flex items-stretch gap-2 border border-gray-300 rounded-md ${
                    state.showCal === 'none' ? '' : ''
                  }`}
                >
                  <Button
                    class={`p-4 rounded-md ring-indigo-500 hover:bg-gray-200 transition-all focus:ring-2 outline-none ${
                      state.showCal === 'start' ? 'bg-gray-100' : ''
                    }`}
                    onClick={(e) => {
                      e.preventDefault();
                      setState((s) => ({
                        ...s,
                        showCal: s.showCal === 'start' ? 'none' : 'start',
                      }));
                    }}
                  >
                    {dayjs(state.startDate).format('MMM DD')}
                  </Button>
                  {!state.allDay && (
                    <TimeCombobox
                      hour12
                      value={state.startTime}
                      onChange={(startTime) => setState((s) => ({ ...s, startTime }))}
                      placeholder="12am"
                    />
                  )}
                  <span class="flex items-center">-</span>
                  {!state.allDay && (
                    <TimeCombobox
                      hour12
                      value={state.endTime}
                      onChange={(endTime) => setState((s) => ({ ...s, endTime }))}
                      placeholder="12pm"
                    />
                  )}
                  <Button
                    class={`p-4 rounded-md ring-indigo-500 hover:bg-gray-200 transition-all focus:ring-2 outline-none ${
                      state.showCal === 'end' ? 'bg-gray-100' : ''
                    }`}
                    onClick={(e) => {
                      e.preventDefault();
                      setState((s) => ({ ...s, showCal: s.showCal === 'end' ? 'none' : 'end' }));
                    }}
                  >
                    {isSingleDate && state.allDay && 'end of day'}
                    {(!isSingleDate || !state.allDay) && dayjs(state.endDate).format('MMM DD')}
                  </Button>
                </div>
              </header>
              {state.showCal !== 'none' && (
                <section class="flex flex-col border border-gray-300 rounded-md p-6 gap-8 absolute top-full mt-4 inset-x-0 bg-white z-50 shadow-2xl">
                  <div class="flex flex-col gap-6" onClick={(e) => e.preventDefault()}>
                    <DateRangePicker
                      start={state.startDate}
                      end={state.endDate}
                      onChange={(val) => {
                        if (state.showCal === 'start') {
                          setState((s) => ({
                            ...s,
                            startDate: val,
                            endDate: dayjs(s.endDate).isBefore(val) ? val : s.endDate,
                            showCal: 'end',
                          }));
                        } else {
                          setState((s) => ({
                            ...s,
                            endDate: val,
                            startDate: dayjs(s.startDate).isAfter(val) ? val : s.startDate,
                          }));
                        }
                      }}
                    />
                  </div>
                </section>
              )}
            </div>
            <footer class="flex flex-col gap-4">
              <div>
                <label class="inline-flex items-center gap-4 cursor-pointer select-none">
                  <Toggle
                    class="scale-110"
                    checked={state.allDay}
                    onClick={() => setState((s) => ({ ...s, allDay: !s.allDay }))}
                  />
                  All day
                </label>
              </div>
            </footer>

            {(state.startDate || state.endDate) && (
              <section>
                Mark {state.allDay && ' all day '}
                {state.startDate && (
                  <span class="font-semibold">{dayjs(state.startDate).format('MMM DD, YYYY')}</span>
                )}
                {!state.allDay && <span class="font-semibold ml-1">{state.startTime}</span>}
                {(!state.allDay || !isSingleDate) && ' - '}
                {state.endDate && !isSingleDate && (
                  <>
                    {state.endDate && (
                      <span class="font-semibold">
                        {dayjs(state.endDate).format('MMM DD, YYYY')}
                      </span>
                    )}
                  </>
                )}
                {!state.allDay && <span class="font-semibold ml-1">{state.endTime}</span>}
                {' as unavailable.'}
              </section>
            )}
            <footer class="flex gap-4">
              <BtnPrimary class="rounded-full">Save unavailability</BtnPrimary>
              <Button
                href="/calendar/availability"
                class="text-inherit inline-flex items-center justify-center hover:bg-gray-100 px-4 transition-all rounded-full"
              >
                Cancel
              </Button>
            </footer>
          </AsyncForm>
        </PageSection>
      </PageContent>
    </RuzcalMgmtPage>
  );
}
