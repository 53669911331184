/**
 * This contains a button that redirects to the Stripe OAuth flow when clicked.
 */
import { Button } from '@components/buttons';
import { ComponentChildren } from 'preact';
import { useState } from 'preact/hooks';
import { showError } from '@components/app-error';
import { rpx } from 'client/lib/rpx-client';

export function BtnStripeConnect({
  courseId,
  class: className = '',
  children,
}: {
  courseId?: string;
  class?: string;
  children: ComponentChildren;
}) {
  const [isRedirecting, setIsRedirecting] = useState(false);
  const connectStripe = async () => {
    setIsRedirecting(true);
    try {
      const { url } = await rpx.stripeConnect.getStripeConnectUrl({ courseId });
      location.assign(url);
      // Wait indefinitely so we don't get a flicker before the browser navigates away
      await new Promise(() => {});
    } catch (err) {
      showError(err);
    } finally {
      setIsRedirecting(false);
    }
  };

  return (
    <Button
      type="button"
      isLoading={isRedirecting}
      onClick={connectStripe}
      class={`rounded-md font-medium ${className}`}
      data-test="btnstripeconnect"
    >
      {children}
    </Button>
  );
}
