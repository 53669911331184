import { useTimezone } from 'client/lib/hooks';
import { fmtFullDate, fmtFullTime, timezoneCity } from 'shared/dateutil';
import { DatePicker } from './date-picker';
import { useState, useEffect, useRef } from 'preact/hooks';
import { createPortal } from 'preact/compat';
import { BtnPrimary, BtnSecondary } from '@components/buttons';
import { dateOrUndefined, isValidDate } from './date-util';

interface Props {
  name?: string;
  class?: string;
  wrapperClass?: string;
  placeholder?: string;
  min?: Date;
  value?: Date;
  allowClear?: boolean;
  includeTime?: boolean;
  onChange?(dt: Date | undefined): void;
}

/**
 * Used to catch the user tabbing out of the date picker in either direction
 * so we can return focus back to the input.
 */
function FocusCatcher({ input }: { input?: HTMLInputElement }) {
  return (
    <button
      class="w-0 h-0 opacity-0 absolute"
      onFocus={() => {
        input?.focus();
      }}
    ></button>
  );
}

/**
 * Display an input that shows a calendar / date / time picker when it gains
 * focus.
 */
export function DateInput(props: Props) {
  const [showPicker, setShowPicker] = useState<undefined | string>();
  const value = dateOrUndefined(props.value);
  const min = dateOrUndefined(props.min);
  const [text, setText] = useState(() =>
    props.includeTime ? fmtFullTime(value) : fmtFullDate(value),
  );
  const input = useRef<HTMLInputElement>();

  // Used to attach the date picker UI to a node that lives outside of any
  // modal / relative / absolute wrapper so that it can be properly positioned.
  const flyoutContainerRef = useRef<HTMLElement>();
  if (!flyoutContainerRef.current) {
    flyoutContainerRef.current = document.createElement('div');
  }

  const flyoutContainer = flyoutContainerRef.current!;

  const onBlur = () => {
    // Hide the date picker if neither it nor our input has focus.
    requestAnimationFrame(() => {
      const activeEl = document.activeElement;
      if (activeEl !== input.current && !flyoutContainerRef.current?.contains(activeEl)) {
        setShowPicker(undefined);
      }
    });
  };

  const focusPicker = () => {
    // When the date picker displays, we want to set focus to the selected date
    // or the "today" date so that the user can use the arrow keys / tab to
    // move around.
    requestAnimationFrame(() => {
      const btn =
        flyoutContainer.querySelector<HTMLButtonElement>('.dp-selected-date') ||
        flyoutContainer.querySelector<HTMLButtonElement>('.dp-today');
      btn?.focus();
    });
  };

  const positionPicker = (inputEl: HTMLElement) => {
    const rect = inputEl.getBoundingClientRect();
    setShowPicker(
      () => `top: calc(${Math.round(rect.bottom)}px + 0.5rem); left: ${Math.round(rect.left)}px;`,
    );
  };

  const showIfHidden = (e: any) => {
    // Display the picker, using the input (e.target) as a reference for its
    // position.
    if (!showPicker) {
      positionPicker(e.target as HTMLElement);
      focusPicker();
    }
  };

  useEffect(() => {
    // When the window resizes, we need to reposition our calendar.
    if (!showPicker) {
      return;
    }
    const onResize = () => {
      if (input.current) {
        positionPicker(input.current);
      }
    };
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [showPicker]);

  useEffect(() => {
    // Attach a root-level DOM element so we can display the calendar / date
    // picker outside of any modals or relative containers.
    document.body.append(flyoutContainer);
    return () => flyoutContainer.remove();
  }, []);

  useEffect(() => {
    // If the value has changed while we're focused, it's (probably) due to
    // the user input, and we want to keep the current text value.
    if (document.activeElement !== input.current) {
      setText(props.includeTime ? fmtFullTime(value) : fmtFullDate(value));
    }
  }, [value]);

  return (
    <div class={`${props.wrapperClass || ''} relative`}>
      <input
        class={`inline-ruz-input ${props.class || ''}`}
        type="text"
        placeholder={props.placeholder}
        autoComplete="off"
        name={props.name}
        value={text}
        onInput={(e: any) => {
          const value = e.target.value;
          const newDate = value ? new Date(value) : undefined;
          setText(value);
          if (!newDate || !isValidDate(newDate)) {
            props.onChange?.(newDate);
          }
        }}
        ref={(el) => {
          if (el) {
            input.current = el;
          }
        }}
        onClick={showIfHidden}
        onFocus={showIfHidden}
        onBlur={onBlur}
      />
      {showPicker &&
        createPortal(
          <div
            class="fixed z-max bg-white rounded-md shadow-lg"
            style={showPicker}
            tabIndex={1}
            onFocus={(e) => {
              // If we gain focus, we'll try to transfer focus to the selected
              // date so that it's a somewhat useful focus.
              const el = e.target as HTMLElement;
              if (el.tagName === 'DIV') {
                focusPicker();
              }
            }}
            onBlur={onBlur}
            ref={(el) => {
              if (el) {
                const rect = el.getBoundingClientRect();
                const winHeight = window.innerHeight;
                const winWidth = window.innerWidth;
                if (rect.bottom < winHeight) {
                  return;
                }
                const spacing = 16; // A reasonable pixel value
                const inputRect = input.current?.getBoundingClientRect();
                let top = inputRect ? inputRect.top - rect.height - spacing : -1;
                let left = Math.round(inputRect ? inputRect.left : rect.left);

                if (top < 0) {
                  top = spacing;
                }

                if (left + rect.width > winWidth) {
                  left = Math.max(0, Math.round((winWidth - rect.width) / 2));
                }

                setShowPicker(`top: ${top}px; left: ${left}px;`);
              }
            }}
          >
            <FocusCatcher input={input.current} />
            <DatePicker
              value={value}
              min={min}
              includeTime={props.includeTime}
              onChange={props.onChange}
              Footer={({ setState }) => (
                <footer class="flex gap-3 justify-end border-t mt-4 pt-4 border-dashed">
                  {props.allowClear && (
                    <BtnSecondary
                      onClick={() => {
                        setState((s) => ({ ...s, value: undefined }));
                        props.onChange?.(undefined);
                        setShowPicker(undefined);
                      }}
                    >
                      Clear
                    </BtnSecondary>
                  )}

                  <BtnPrimary
                    onClick={() => {
                      input.current?.select();
                      setShowPicker(undefined);
                    }}
                  >
                    Apply
                  </BtnPrimary>
                </footer>
              )}
            />
            <FocusCatcher input={input.current} />
          </div>,
          flyoutContainer,
        )}
    </div>
  );
}

export function DateInputWithTimezone(props: Props) {
  const timezone = useTimezone();
  return (
    <span class="inline-flex">
      <DateInput {...props} class={`${props.class || ''} z-10 rounded-r-none`} />
      <span class="flex p-2 px-4 items-center border border-gray-300 border-l-0 rounded-r bg-gray-50">
        {timezoneCity(timezone)} time
      </span>
    </span>
  );
}
