import { RouteParams } from '@components/router';

/*
 * Our student urls(lesson, meeting, people, etc) are all prefixed with
 * a slug of those entities. They are purely for the purpose of
 * making the url more readable, and we are ignoring them.
 * So this method checks if the parameter has a UUID and if it does
 * it sets the parameter value after stripping the slug.
 *
 * Exported for testing purposes.
 */
export function processRouteParams(source: RouteParams) {
  if (!source.courseId) {
    return source;
  }

  const UUID_LEN = 36; // The length of a v4 UUID
  const DELIM = '--';
  const result = source;
  // The ID section of the slug always starts with a --,
  // which is disallowed in the human-section. However, -- can appear in
  // other IDs, such as our payment IDs.
  for (const k in result) {
    const val = result[k];
    // The value is not a slug in this case, since slugs are always some text
    // followed by `--{UUID}`
    if (val.length <= UUID_LEN) {
      continue;
    }
    // If we're dealing with a slug value, it will look something like this:
    // my-fancy-course-here--7692468f-6e58-46df-afdd-c66cb1e1d3a9
    // We'll replace the value with just the UUID.
    const delimPos = val.indexOf(DELIM);
    if (delimPos < 0) {
      continue;
    }
    // Save courseSlug as a separate param as it's used to build the URL
    // in a way that's consistent with the currently displayed one.
    if (k === 'courseId') {
      result.courseSlug = val.slice(0, delimPos);
    }
    // Modify result[k] (e.g. result.lessonId, etc) to just hold the ID portion
    // of the vanity URL.
    result[k] = val.slice(delimPos + DELIM.length);
  }

  return result;
}
