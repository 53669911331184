import { UserProfileIcon } from '@components/avatars';

export function UserProfile({ user }: { user: { name: string; email?: string } }) {
  return (
    <span class="flex items-center gap-3">
      <UserProfileIcon user={user} size="size-9" />
      <span class="flex flex-col leading-5">
        <strong>{user.name}</strong>
      </span>
    </span>
  );
}
