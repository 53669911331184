import { RuzcalMgmtPage } from './mgmt-page';
import {
  IcoCalendar,
  IcoClock,
  IcoExternalLink,
  IcoEye,
  IcoHide,
  IcoMapPin,
  IcoPencilSquare,
  IcoShare,
} from '@components/icons';
import { ListAddAction, PageContent, PageHeading, PageSection, defCalRoute } from './common';
import { rpx } from 'client/lib/rpx-client';
import { EventTypeRow } from 'server/types/cal-schema';
import { BtnCopy, Button } from '@components/buttons';
import { ruzcal } from 'shared/urls';
import { LoadedProps } from '@components/router';
import { SummarizeAvailableTimes } from './availability-summary';

export const route = defCalRoute({ authLevel: 'guide', load, Page });

async function load() {
  const [eventTypes, availability, host] = await Promise.all([
    rpx.ruzcalEventTypes.getEventTypesList(),
    rpx.ruzcal.getAvailabilityList(),
    rpx.ruzcal.getHost({}),
    rpx.ruzcal.getURLSuffixList(),
  ]);
  return { eventTypes, availability, urlPrefix: host!.urlPrefix };
}

type Data = Awaited<ReturnType<typeof load>>;

function summarizeDuration(duration: number) {
  return `${duration}m`;
}

function EditLink(
  props: Pick<
    EventTypeRow,
    'id' | 'name' | 'isPrivate' | 'duration' | 'location' | 'locationDetail'
  > & { href: string } & {
    availability?: Data['availability'][0];
    timezone: string;
  },
) {
  const fullUrl = `${window.location.origin}${props.href}`;

  return (
    <span class="border rounded-2xl overflow-hidden">
      <a
        href={`/calendar/event-types/${props.id}`}
        class="flex flex-col p-4 gap-1 hover:bg-gray-100 transition-all text-inherit relative"
      >
        <span class="font-semibold text-base">{props.name}</span>
        <span class="hidden text-inherit sm:inline-flex items-center gap-3 absolute right-6 top-6 border border-gray-300 rounded-full p-6 py-1 hover:border-gray-400 transition-all">
          <IcoPencilSquare class="size-4 stroke-2" />
          Edit
        </span>
        <footer class="grid grid-cols-2 gap-2 sm:flex sm:gap-8 items-center">
          <span class="inline-flex items-center gap-2">
            <IcoClock /> {summarizeDuration(props.duration)}
          </span>
          {props.availability && (
            <span class="inline-flex items-center gap-2">
              <IcoCalendar />
              <SummarizeAvailableTimes
                renderTimeZone={props.timezone}
                scheduleTimeZone={props.availability.timezone}
                timeslots={props.availability.timeslots}
              />
            </span>
          )}
          <span class="inline-flex items-center gap-2 capitalize">
            <IcoMapPin /> {props.location}
          </span>
          {props.isPrivate && (
            <span class="inline-flex items-center gap-2">
              <IcoHide /> Not publicly listed
            </span>
          )}
          {!props.isPrivate && (
            <span class="inline-flex items-center gap-2">
              <IcoEye /> Publicly listed
            </span>
          )}
        </footer>
      </a>
      <span class="flex items-center border-t">
        <BtnCopy
          value={fullUrl}
          copiedText="Booking link copied"
          class="hover:bg-gray-200 transition-all rounded-none rounded-bl-2xl p-2 px-4 gap-2"
          margin=" "
          icon={<IcoShare />}
        >
          Share
        </BtnCopy>
        <Button
          href={fullUrl}
          target="_blank"
          class="hover:bg-gray-200 transition-all rounded-none flex items-center gap-2 text-inherit p-2 px-4"
          onClick={(e) => e.stopPropagation()}
        >
          <IcoExternalLink />
          View
        </Button>
      </span>
    </span>
  );
}

function Page(props: LoadedProps<typeof load>) {
  const { eventTypes, availability, urlPrefix } = props.state;

  return (
    <RuzcalMgmtPage
      title="Event types"
      currentPage="eventtypes"
      actions={<ListAddAction href="/calendar/event-types/new" content="Add event type" />}
    >
      <PageContent>
        <PageSection>
          <PageHeading
            title="Event types"
            subtitle="Manage the types of events that people can book."
          />
          {eventTypes.length > 0 && (
            <>
              {eventTypes.map((x) => (
                <EditLink
                  key={x.id}
                  timezone={props.auth.user!.timezone}
                  id={x.id}
                  name={x.name}
                  href={ruzcal.newBookingUrl({ urlPrefix, urlSuffix: x.urlSuffix })}
                  duration={x.duration}
                  location={x.location}
                  locationDetail={x.locationDetail}
                  isPrivate={x.isPrivate}
                  availability={availability.find((a) => a.id === x.availabilityId)}
                />
              ))}
            </>
          )}
        </PageSection>
      </PageContent>
    </RuzcalMgmtPage>
  );
}
