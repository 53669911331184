import { AssessmentType } from 'server/types';

export function assessmentReviewUrl(opts: {
  type: AssessmentType;
  courseId: string;
  lessonId: string;
  userId: string;
}) {
  return `/manage/courses/${opts.courseId}/assessments?${opts.type}=${opts.lessonId}&user=${opts.userId}`;
}
