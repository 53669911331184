import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import duration from 'dayjs/plugin/duration';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);
dayjs.extend(relativeTime);
dayjs.extend(duration);

// Monday, January 3, 2025
export const fullDate = 'dddd, MMMM D, YYYY';

// Mon, Jan 3, 2025
export const abbreviatedDate = 'ddd, MMM D, YYYY';

/**
 * Display the date differently if it is this year's date
 * vs a future / past year's date.
 */
export function compactDate(dt: string | Date, opts?: { timeZone?: string }) {
  let d = dayjs(dt);
  if (opts?.timeZone) {
    d = d.tz(opts.timeZone);
  }
  return d.format('MMM D' + (d.year() === dayjs().year() ? '' : ', YY'));
}

/**
 * fmtFullDate converts a date to a human-friendly format like Tue, Apr 13 2021
 */
export function fmtFullDate(
  date: Date | string | undefined,
  opts: { useFullNames?: boolean; timeZone?: string } = {},
): string {
  if (!date) {
    return '';
  }

  let dt = dayjs(date);

  if (opts.timeZone) {
    dt = dt.tz(opts.timeZone);
  }

  return dt.format(opts.useFullNames ? fullDate : abbreviatedDate);
}

/**
 * fmtFullTime converts a date to a human-friendly format like Tue Apr 13 2021 2:06PM
 */
export function fmtFullTime(
  date: Date | string | undefined,
  opts: { useFullNames?: boolean; timeZone?: string; includeTimezone?: boolean } = {},
): string {
  if (!date) {
    return '';
  }
  let dt = dayjs(date);
  if (opts.timeZone) {
    dt = dt.tz(opts.timeZone);
  }

  const dateFormat = `${opts.useFullNames ? fullDate : abbreviatedDate} h:mm A${
    opts.includeTimezone ? ' (z)' : ''
  }`;
  return dt.format(dateFormat);
}

/**
 * parseDate converts a date string to a local date, and understands fmtDate format.
 */
export function parseDate(dt: string | Date | undefined): Date | undefined {
  if (!dt) {
    return;
  }
  if (typeof dt !== 'string') {
    return dt;
  }
  return new Date(dt);
}

/**
 * Convert date or string to date, and falsy values to undefined.
 */
export function toNullableDate(dt: Date | string | undefined) {
  return dt ? new Date(dt) : undefined;
}

/**
 * Convert the UTC date to the local date, keeping the value unchanged.
 * That is to say, if it's July 4, 2021 in UTC, this will return
 * July 4, 2021 in local time.
 */
export function toLocalDate(s?: Date | string) {
  if (!s) {
    return;
  }
  if (typeof s === 'string') {
    const [yy, mm, dd, hh, min] = s.split(/[-T:]/);
    return new Date(
      parseInt(yy),
      parseInt(mm) - 1,
      parseInt(dd),
      parseInt(hh) || 0,
      parseInt(min) || 0,
    );
  }
  return new Date(s.getFullYear(), s.getMonth(), s.getDate(), s.getHours(), s.getMinutes());
}

/**
 * The inverse of toLocalDate.
 */
export function toUTCDate(dt?: Date | string) {
  if (!dt) {
    return;
  }
  dt = new Date(dt);
  return new Date(
    Date.UTC(dt.getFullYear(), dt.getMonth(), dt.getDate(), dt.getHours(), dt.getMinutes()),
  ).toISOString();
}

/**
 * Get the UTC offset in hh:mm:ss format.
 */
export function getUtcOffset(offset = new Date().getTimezoneOffset()) {
  const mins = Math.abs(offset);
  const hh = Math.floor(mins / 60)
    .toString()
    .padStart(2, '0');
  const mm = (mins % 60).toString().padStart(2, '0');
  return `${offset < 0 ? '-' : ''}${hh}:${mm}:00`;
}

export function toHumanTime(date?: Date | string, format?: string) {
  if (!date) {
    return '';
  }
  const dt = dayjs(date);
  return `${format === 'compact' ? compactDate(date) : dt.format(format || fullDate)} at ${dt
    .format('h:mm A')
    .replace(':00 ', '')}`;
}

/*
 * Return 12pm instead of 12:00pm
 */
export function timeWithoutZeroes(date: Date | string) {
  return dayjs(date).format('h:mma').replace(':00', '');
}

/**
 * Just a quick way to ensure we properly format a date as ISO.
 */
export function toISOString(dt: Date | string | undefined) {
  return dt ? new Date(dt).toISOString() : undefined;
}

/*
 * Converting the date to ISO format without
 * any special characters. Example output is: `20140127T224000Z`
 */
export function toISO8601(date: Date) {
  // Adding `Z` later because it's a special
  // dayjs character.
  return `${dayjs(date).utc().format('YYYYMMDDTHHmm00')}Z`;
}

/**
 * Takes a standard timezone like "America/New_York" and returns just the city
 * (e.g."New York").
 */
export function timezoneCity(zone: string) {
  const [prefix, suffix] = zone.split('/');
  return (suffix || prefix).replaceAll('_', ' ');
}

/**
 * Given two time zones, this returns a description of how they differ (or an
 * empty string if they are the same timezone).
 */
export function timezoneDiff(current: string, saved: string, date?: Date) {
  if (current === saved) {
    return '';
  }

  try {
    // Given 10:30, this returns 630, so we can easily do math on the difference.
    const toNum = (time: string) =>
      parseInt(time.slice(0, 2), 10) * 60 + parseInt(time.slice(3, 6), 10);

    const timezoneTime = (dt: Date, zone: string) =>
      new Intl.DateTimeFormat('en-US', {
        timeStyle: 'long',
        hourCycle: 'h24',
        timeZone: zone,
      }).format(dt);
    const time = date instanceof Date ? date : date ? new Date(date) : new Date();
    let diff = toNum(timezoneTime(time, current)) - toNum(timezoneTime(time, saved));
    const isAhead = diff > 0;
    diff = Math.abs(diff);
    const hours = Math.floor(diff / 60);
    const mins = diff % 60;

    if (!diff) {
      return '';
    }
    const minSuffix = mins ? (mins / 60).toString().slice(1, 3) : '';

    return `(${hours}${minSuffix} ${hours === 1 && !mins ? 'hour' : 'hours'} ${
      isAhead ? 'ahead of' : 'behind'
    } ${timezoneCity(saved)})`;
  } catch (err) {
    console.error(err);
    return `but your saved timezone is ${timezoneCity(saved)}`;
  }
}

/*
 * Takes a duration in seconds and converts it to a clock format.
 * Example output is: `00:00:00`
 */
export function durationToClockString({
  duration,
  displayZeroHours,
}: {
  duration: number;
  /*
   * If true, the clock will display hours even if they are zero.
   */
  displayZeroHours?: boolean;
}) {
  const nSecondsAgo = dayjs().subtract(duration, 'seconds');
  const dur = dayjs.duration(dayjs().diff(nSecondsAgo));
  const isMoreThanHour = duration > 3600;
  const format = displayZeroHours ? 'HH:mm:ss' : isMoreThanHour ? 'H:mm:ss' : 'mm:ss';
  return dayjs.utc(dur.asMilliseconds()).format(format);
}

/**
 * Given a time string like '4:30pm', return the minutes since midnight.
 */
export function timeToMinutes(time: string) {
  const [h, m, ampm] = Array.from(time.toLowerCase().match(/[0-9]+|[a-z]+/g) || []);
  let hours = parseInt(h || '0', 10);
  const minutes = parseInt(m || '0', 10);
  if (hours === 12 && ampm === 'am') {
    hours = 0;
  } else if (hours < 12 && ampm === 'pm') {
    hours += 12;
  }
  return minutes + hours * 60;
}
