import { useState } from 'preact/hooks';
import { Toggle } from '@components/toggle';
import { IntegrationRow } from './integration-value-item';
import { rpx } from 'client/lib/rpx-client';
import { showError } from '@components/app-error';
import { useDidUpdateEffect } from 'client/utils/use-did-update-effect';

export function CollectAddressToggle(props: { collectAddress: boolean }) {
  const [collectAddress, setCollectAddress] = useState(props.collectAddress);

  useDidUpdateEffect(() => {
    rpx.accounts.setCollectAddress({ collectAddress }).catch(showError);
  }, [collectAddress]);

  return (
    <IntegrationRow icon={<span class="text-2xl font-semibold text-blue-700">Addresses</span>}>
      <label class="flex gap-4 items-center cursor-pointer">
        <Toggle checked={collectAddress} onClick={() => setCollectAddress((x) => !x)} />
        Collect shipping addresses during Stripe / Paypal checkout.
      </label>
    </IntegrationRow>
  );
}
