/**
 * Common email components.
 */

import { ComponentChildren } from 'preact';
import { Internationalize } from 'shared/intl';

/**
 * This registers a custom JSX element type for <template-field> tags
 */
/* eslint-disable @typescript-eslint/no-namespace */
declare module 'preact/src/jsx' {
  namespace JSXInternal {
    interface IntrinsicElements {
      'template-field': any;
    }
  }
}

export function PrimaryAction(props: { href: string; children: ComponentChildren }) {
  return (
    <div style="text-align:center;padding-top:1rem;padding-bottom:2rem">
      <a
        style="display:inline-block;padding:14px 32px;background:#5850ec;border:0;border-radius:1000px;font-size:18px;font-weight:600;color:#fff;text-decoration:none"
        href={props.href}
        target="_blank"
        rel="noreferrer"
      >
        {props.children}
      </a>
    </div>
  );
}

export function MarketingFooter() {
  return (
    <>
      Powered by{' '}
      <a
        href="https://www.ruzuku.com"
        style="color:#5850ec;opacity:0.75;"
        target="_blank"
        rel="noreferrer"
      >
        Ruzuku
      </a>
      {' • '}
      <a
        href="http://blog.ruzuku.com"
        style="color:#5850ec;opacity:0.75;"
        target="_blank"
        rel="noreferrer"
      >
        Our Blog
      </a>
    </>
  );
}

export function SupportFooter() {
  return (
    <a
      href="mailto:support@ruzuku.com"
      style="color:#5850ec;opacity:0.75;"
      target="_blank"
      rel="noreferrer"
    >
      Contact support
    </a>
  );
}

export function BasicEmailWrapper(props: {
  children: ComponentChildren;
  footer: ComponentChildren;
}) {
  return (
    <div style="padding:10px;background-color:#f8fafc;font-family:Inter var, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif;line-height:1.5;color:#333;">
      <div style="padding: 1.5rem">
        <div style="width:100%;max-width:600px;margin:16px auto;padding:32px 48px;background:#fff;border-radius:5px">
          {props.children}
          <div style="clear:both"></div>
        </div>
      </div>
      <div style="text-align:center;color:#667;padding:0 10px;max-width:600px;margin:auto;">
        <div style="margin-top:0;font-size:12px;line-height:20px">{props.footer}</div>
      </div>
    </div>
  );
}

/**
 * Table component for emails
 */
export function Table({ children }: { children: ComponentChildren }) {
  return (
    <table
      width="100%"
      cellPadding="0"
      cellSpacing="0"
      style="padding:10px;font-family:Inter var, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif;line-height:1.5;color:#333;"
    >
      <tbody>{children}</tbody>
    </table>
  );
}

/**
 * H1 heading with standardized styling for emails
 * Note this is a placeholder until we work out email styling formats.
 */
export function Heading({ children }: { children: ComponentChildren }) {
  return <h1>{children}</h1>;
}

/**
 * H3 heading with standardized styling for emails
 */
export function SubHeading({ children }: { children: ComponentChildren }) {
  return (
    <h3 style="margin-top:1.25rem;margin-bottom:1rem;font-size: 1.25rem;line-height: 1.75rem;">
      {children}
    </h3>
  );
}

/**
 * Blockquote email section
 */
export function Blockquote({ children, style }: { children: ComponentChildren; style?: string }) {
  return (
    <blockquote
      style={`margin:0;padding:16px;color:#3f3f46;background-color:#ECFCF4;border-color:#10b981;border-left-style:solid;border-left-width:4px;font-size:16px;font-style:italic;${
        style ? style : ''
      }`}
    >
      {children}
    </blockquote>
  );
}

/**
 * Basic mailto link
 */
export function Mailto({ email, children }: { email: string; children?: ComponentChildren }) {
  return <a href={`mailto:${email}`}>{children || email}</a>;
}

/**
 * Paragraph with standardized styling for emails
 */
export function Line({ style = '', children }: { style?: string; children: ComponentChildren }) {
  return <p style={`margin-bottom:1rem;${style}`}>{children}</p>;
}

/**
 * Centered paragraph for emails
 */
export function CenteredLine({ children }: { children: ComponentChildren }) {
  return <p style="margin-bottom:1rem;text-align:center;">{children}</p>;
}

/**
 * Double paragraph, for spacing in system messages which strip out styling tags
 */
export function DoubleLine({ children }: { children: ComponentChildren }) {
  return (
    <>
      <p>{children}</p>
      <p>
        <br />
      </p>
    </>
  );
}

/**
 * Italic styled span
 */
export function Italic({ children }: { children: ComponentChildren }) {
  return <span style="font-style:italic;">{children}</span>;
}

/**
 * Center-aligned image block
 */
export function Image({ imagePath, title }: { imagePath: string; title?: string }) {
  return (
    <div style="text-align: center;">
      <img
        src={imagePath}
        title={title}
        style="width:40px;height:40px;margin:auto;margin-top:16px;"
      />
    </div>
  );
}

/**
 * Basic styled link for emails
 */
export function Link({ href, children }: { href: string; children?: ComponentChildren }) {
  return (
    <a href={href} style="color:#5850ec;opacity:0.75;" target="_blank" rel="noreferrer">
      {children}
    </a>
  );
}

/**
 * Colored pill for emails
 */
export function Pill({
  color,
  children,
}: {
  color?: 'green' | 'red' | 'gray'; // defaults to gray
  children?: ComponentChildren;
}) {
  let colorStyles: string;
  switch (color) {
    case 'green':
      colorStyles = 'color:#166534;background-color:#dcfce7;';
      break;
    case 'red':
      colorStyles = 'color:#991b1b;background-color:#fee2e2;';
      break;
    default:
      colorStyles = 'color:#636363;background-color:#cac9c9;';
      break;
  }
  return (
    <span
      style={`margin-left:12px; padding:4px 12px;border-radius:999px;font-size:14px;${colorStyles}`}
    >
      {children}
    </span>
  );
}

export function HoriziontalDivider() {
  return <hr style="border-top:1px solid #e5e7eb;margin:1.5rem 0;" />;
}

/**
 * Div wrapper with styling used in a lot of email templates.
 * This should eventually be replaced, and incorporated into the main email template
 */
export function EmailCard({ children }: { children: ComponentChildren }) {
  return (
    <div
      class="messages-content"
      style="display:block;max-width:600px;margin:16px auto 42px;padding:16px 24px;background:#fff;border-radius:5px;"
    >
      {children}
    </div>
  );
}

export type UnsubscribeParams = {
  label: string;
  courseName: string;
  courseUrl: string;
  guide: {
    name: string;
    email: string;
  };
};

/**
 * Unsubscribe block for course emails
 */
export function Unsubscribe({
  courseName,
  courseUrl,
  guide,
  intl,
}: UnsubscribeParams & {
  intl: Internationalize;
}) {
  // Split the translated phrase up into three chunks around the <> and </>
  const firstLine = intl.split(
    'You are receiving this email because you are enrolled in <>{courseName:string}</>.',
    {
      courseName,
    },
  );
  const secondLine = intl.split(
    '<>Click here</> to unsubscribe from emails in {courseName:string}.',
    {
      courseName,
    },
  );
  const thirdLine = intl.split(
    'To unsubscribe from other mailing lists, please contact <>{guideName:string}</>.',
    {
      guideName: guide.name,
    },
  );

  return (
    <div style="max-width: 500px; margin: auto; color: #667; font-size: 0.9rem; clear:both;">
      <Line>
        {firstLine[0]}
        <a href={courseUrl}>{firstLine[1]}</a>
        {firstLine[2]}
      </Line>
      <Line>
        {secondLine[0]}
        <a href="%recipient.unsubscribeUrl%">{secondLine[1]}</a>
        {secondLine[2]}
      </Line>
      <Line>
        {thirdLine[0]}
        <a href={`mailto:${guide.email}`}>{thirdLine[1]}</a>
        {thirdLine[2]}
      </Line>
    </div>
  );
}

/**
 * This returns a <template-field> tag with a raw template variable name wrapped in triple brackets.
 * eg: '{{{guide-name}}}'.
 * These are used by Mustache for interpolating user edited message templates.
 */
export function TemplateField({ name }: { name: string }) {
  const template = `{{{${name}}}}`;
  return <template-field contenteditable="false">{template}</template-field>;
}

export function replacePlaceholders(
  s: string,
  templateParams: Record<string, string>,
  escapeHTML: (s: string) => string,
) {
  return s.replaceAll(/{{{([^}]+)}}}/g, (_, name) => {
    const value = templateParams[name];
    // Raw HTML is supported / required for these.
    if (name === 'course-image' || name === 'bundle-image') {
      return value;
    }
    return escapeHTML(value);
  });
}
