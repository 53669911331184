import { Pill, PillColor } from '@components/pill';

type Tag = 'new' | 'improved';

const TagConfig: Record<Tag, PillColor> = {
  new: 'green',
  improved: 'blue',
};

function ChangeItem({
  title,
  description,
  tags,
  href,
}: {
  title: string;
  description: string;
  tags: Tag[];
  href: string;
}) {
  return (
    <a
      class="rounded-lg bg-white px-4 py-3 border border-transparent hover:border-indigo-500"
      href={href}
      target="_blank"
      rel="noopener noreferrer"
    >
      <span class="flex justify-between">
        <span class="text-sm font-semibold text-gray-600">{title}</span>
        <span class="flex items-start gap-1">
          {tags.map((tag) => (
            <Pill key={tag} color={TagConfig[tag]}>
              {tag}
            </Pill>
          ))}
        </span>
      </span>
      <span class="text-xs text-gray-600 line-clamp-1">{description}</span>
    </a>
  );
}

export function ChangelogWidget() {
  return (
    <div class="bg-linear-to-b from-gray-100 to-indigo-50 p-4 sm:p-6 pb-2 rounded-2xl">
      <div class="flex justify-between mb-4 sm:mb-6 items-center">
        <p class="text-black text-lg xl:text-2xl font-bold">Recent platform updates</p>
        <a
          class="text-sm font-medium text-indigo-600"
          href="https://ruzuku.canny.io/changelog"
          target="_blank"
          rel="noopener noreferrer"
        >
          See all changes
        </a>
      </div>
      <div class="flex flex-col gap-2">
        <ChangeItem
          title="We now support resumable downloads!"
          description="If you have a large video file, for example, that you want to upload to your course or product, the upload may time out depending on the size of the file and your internet speed."
          tags={['improved']}
          href="https://ruzuku.canny.io/changelog/we-now-support-resumable-downloads"
        />
        <ChangeItem
          title="New Ruzuku home page layout and design!"
          description="You may have noticed that your Ruzuku account looks a bit different and has a lot of new and helpful information!"
          tags={['new', 'improved']}
          href="https://ruzuku.canny.io/changelog/new-ruzuku-home-page-layout-and-design"
        />
      </div>
    </div>
  );
}
