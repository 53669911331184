import { debounce } from 'client/utils/debounce';
import { useState, useEffect } from 'preact/hooks';
import { on } from 'minidoc-editor';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [dimensions, setDimensions] = useState(getWindowDimensions);

  useEffect(() => {
    const handleResize = debounce(() => setDimensions(getWindowDimensions()), 500);

    const off = on(window, 'resize', handleResize);
    return off;
  }, []);

  return {
    dimensions,
    isMobile: dimensions.width < 768,
  };
}
