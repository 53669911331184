import { Button } from '@components/buttons';
import { useState } from 'preact/hooks';
import { rpx, RpxResponse } from 'client/lib/rpx-client';
import { showError } from '@components/app-error';
import { SearchBox } from '@components/search-box';
import { useAsyncEffect } from 'client/utils/use-async-effect';
import { useIntl } from 'shared/intl/use-intl';
import { DialogHeader, StandardDialog } from '@components/dialog';

interface Props {
  timezone: string;
  hide(): void;
  onPick(timezone: string): void;
}

const store = rpx.auth;

type SearchableTimezone = RpxResponse<typeof store.getTimezones>[0] & { search: string };

export function TimezoneModal(props: Props) {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(true);
  const [timezones, setTimezones] = useState<SearchableTimezone[]>([]);
  const [filter, setFilter] = useState({
    term: '',
    search: '',
  });
  const filteredTimezones = !filter.search
    ? timezones
    : timezones.filter((t) => t.search.includes(filter.search));

  useAsyncEffect(async () => {
    try {
      const zones = await store.getTimezones();
      setTimezones(
        zones.map((z) => ({
          ...z,
          search: `${z.name.toLowerCase().replace(/[_/\s]/g, '')} ${z.abbrev.toLowerCase()}`,
        })),
      );
    } catch (err) {
      showError(err);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const pickTimezone = (timezone: string) => {
    props.onPick(timezone);
    props.hide();
  };

  return (
    <StandardDialog onClose={props.hide}>
      <DialogHeader
        title={<>{isLoading ? intl('Loading timezones...') : intl('Choose your timezone')}</>}
      />
      {!isLoading && (
        <>
          <SearchBox
            focusOnce
            placeholder="Find a timezone"
            onTermChange={(t) =>
              setFilter({
                term: t,
                search: t.toLowerCase().replace(/[\s]/g, ''),
              })
            }
            onKeyDown={(e) => {
              if (e.code === 'Enter' && filteredTimezones.length) {
                e.preventDefault();
                pickTimezone(filteredTimezones[0].name);
              }
            }}
          />
          <div class="flex flex-col gap-1">
            {filteredTimezones.map((t) => (
              <Button
                key={t.name}
                type="button"
                class="flex justify-between w-full p-2 rounded-md hover:bg-indigo-50 hover:text-indigo-800"
                onClick={() => pickTimezone(t.name)}
              >
                <span>{t.name}</span>
                <span>
                  {t.abbrev} {t.utcOffset}
                </span>
              </Button>
            ))}
          </div>
        </>
      )}
    </StandardDialog>
  );
}
