import { DateRange } from 'shared/scheduling';
import { fullDate, time12Formatter, time24Formatter, timeZoneName } from './dateutil';

export function ScheduleSummary({
  schedule,
  hour12 = true,
}: {
  schedule: DateRange;
  hour12?: boolean;
}) {
  const fmtime = hour12 ? time12Formatter : time24Formatter;
  return (
    <span class="inline-flex flex-col">
      <time datetime={schedule.start.toISOString()}>{fullDate.format(schedule.start)}</time>
      <span>
        {fmtime.format(schedule.start)} - {fmtime.format(schedule.end)} ({timeZoneName()})
      </span>
    </span>
  );
}
