import { showError } from '@components/app-error';
import { useCurrentTenant } from '@components/router/session-context';
import { rpx } from 'client/lib/rpx-client';
import { useEffect, useState } from 'react';
import { FullCourse } from 'server/types';
import { courseLabel } from 'shared/terminology';
import { Toggle } from '@components/toggle';
import { showModalForm } from '@components/modal-form';
import { useTryAsyncData } from 'client/lib/hooks';
import { DefaultSpinner } from '@components/spinner';
import { pluralize } from 'shared/formatting';
import { ProgressBar } from '@components/progress-bar';
import { Dialog } from '@components/dialog';

interface Props {
  course: Pick<FullCourse, 'id' | 'title' | 'status' | 'isBundle' | 'isArchived'>;
  onCancel(): void;
}

interface ArchiveState {
  mode: 'revokeaccess' | 'archive';
  progress: number;
  description: string;
}

function ArchiveProgress({
  courseId,
  revokeStudentAccess,
  signupsOpen,
}: {
  courseId: UUID;
  revokeStudentAccess: boolean;
  signupsOpen: boolean;
}) {
  const [state, setState] = useState<ArchiveState>({
    progress: 0,
    mode: revokeStudentAccess ? 'revokeaccess' : 'archive',
    description: 'Preparing to archive course...',
  });
  const archiveCourse = async () => {
    setState((s) => ({
      ...s,
      description: 'Archiving course...',
      progress: 99, // Just for effect
    }));
    try {
      await rpx.courseSettings.archiveCourse({
        courseId,
        revokeStudentAccess,
        signupsOpen,
      });

      setState((s) => ({ ...s, progress: 100 }));
      // Artificial delay so the progress bar doens't just flash on the screen
      await new Promise((r) => setTimeout(r, 1000));
      location.reload();
      await new Promise(() => {});
    } catch (err) {
      showError(err);
    }
  };

  const revokeAccess = async () => {
    try {
      const purchaseIds = await rpx.subscriptionStatus.getActiveSubscriptions({ courseId });

      for (let i = 0; i < purchaseIds.length; ++i) {
        setState((s) => ({
          ...s,
          description: 'Revoking student access...',
          progress: Math.round(Math.min(99, (i / purchaseIds.length) * 100)),
        }));
        await rpx.subscriptionStatus.cancelPurchase({
          courseId,
          purchaseId: purchaseIds[i],
        });
      }
      setState((s) => ({ ...s, mode: 'archive' }));
    } catch (err) {
      showError(err);
    }
  };

  useEffect(() => {
    if (state.mode === 'archive') {
      archiveCourse();
    } else if (state.mode === 'revokeaccess') {
      revokeAccess();
    }
  }, [state.mode]);

  return (
    <section class="flex flex-col">
      <span>{state.description}</span>
      <ProgressBar progress={state.progress} />
    </section>
  );
}

function ArchiveCourseModal({ course, onCancel }: Props) {
  const tenant = useCurrentTenant();
  const { title } = course;
  const { isLoading, data: stats } = useTryAsyncData(
    () => rpx.courses.getCourseDeletionStats({ courseId: course.id }),
    [course.id],
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [revokeStudentAccess, setRevokeStudentAccess] = useState(false);
  const [signupsOpen, setSignupsOpen] = useState(false);
  const terminologyCourse = courseLabel({
    course,
    tenant,
  });

  return (
    <Dialog
      mode="warn"
      onClose={onCancel}
      title={isSubmitting ? 'Processing...' : <>Archive {terminologyCourse}?</>}
      confirmButtonText={<>Archive {terminologyCourse}</>}
      onSubmit={async () => {
        setIsSubmitting(true);
        return new Promise(() => {});
      }}
    >
      {isLoading && (
        <div class="absolute inset-0 flex justify-center items-center">
          <DefaultSpinner />
        </div>
      )}
      {isSubmitting && (
        <ArchiveProgress
          courseId={course.id}
          revokeStudentAccess={revokeStudentAccess}
          signupsOpen={signupsOpen}
        />
      )}
      {!isSubmitting && (
        <div class={isLoading ? 'invisible' : 'flex flex-col'}>
          <section class="flex flex-col gap-6">
            <p>
              Are you sure you want to archive <strong>{title}</strong>?
            </p>
            <label class="flex items-center gap-3 cursor-pointer">
              <Toggle checked={signupsOpen} onClick={() => setSignupsOpen(!signupsOpen)} />
              <span>Signups open</span>
            </label>

            {stats && (!!stats.numActiveSubs || !!stats.numActiveStudents) && (
              <label class="flex items-center gap-3 cursor-pointer">
                <Toggle
                  checked={revokeStudentAccess}
                  onClick={() => setRevokeStudentAccess(!revokeStudentAccess)}
                />
                <span>
                  Revoke access for all {stats.numActiveStudents} students
                  {stats.numActiveSubs ? (
                    <strong>
                      {' '}
                      and cancel {stats.numActiveSubs}{' '}
                      {pluralize('subscription', stats.numActiveSubs)} and{' '}
                      {pluralize('payment plan', stats.numActiveSubs)}
                    </strong>
                  ) : (
                    ''
                  )}
                  ?
                </span>
              </label>
            )}
          </section>
        </div>
      )}
    </Dialog>
  );
}

function UnarchiveCourseModal({ course, onCancel }: Props) {
  const tenant = useCurrentTenant();
  const { title } = course;
  const [grantStudentAccess, setGrantStudentAccess] = useState(false);
  const [signupsOpen, setSignupsOpen] = useState(true);
  const { isLoading, data: stats } = useTryAsyncData(
    () => rpx.courses.getCourseDeletionStats({ courseId: course.id }),
    [course.id],
  );
  const terminologyCourse = courseLabel({
    course,
    tenant,
  });

  return (
    <Dialog
      mode="custom"
      icon={
        <span class="bg-indigo-100 text-indigo-600 aspect-square grow rounded-full text-xl shrink-0 flex items-center justify-center font-bold">
          ?
        </span>
      }
      title={<>Unarchive {terminologyCourse}</>}
      confirmButtonText={<>Unarchive {terminologyCourse}</>}
      onClose={onCancel}
      onSubmit={async () => {
        try {
          await rpx.courseSettings.unarchiveCourse({
            courseId: course.id,
            grantStudentAccess,
            signupsOpen,
          });

          location.reload();
          await new Promise(() => {});
        } catch (err) {
          showError(err);
        }
      }}
    >
      {isLoading && (
        <div class="absolute inset-0 flex justify-center items-center">
          <DefaultSpinner />
        </div>
      )}
      <section class="flex flex-col gap-6">
        <p>
          Would you like to unarchive <strong>{title}</strong>?
        </p>

        <label class="flex items-center gap-3 cursor-pointer">
          <Toggle checked={signupsOpen} onClick={() => setSignupsOpen(!signupsOpen)} />
          <span>Signups open</span>
        </label>

        {!!stats?.numStudents && (
          <label class="flex items-center gap-4 cursor-pointer">
            <Toggle
              checked={grantStudentAccess}
              onClick={() => setGrantStudentAccess(!grantStudentAccess)}
            />
            Grant access to {stats.numStudents} {pluralize('student', stats.numStudents)}
          </label>
        )}
      </section>
    </Dialog>
  );
}

// Toggle the archived status of a course for the current user.
export async function toggleCourseArchivedState({ course }: { course: Props['course'] }) {
  return showModalForm(({ resolve }) =>
    course.isArchived ? (
      <UnarchiveCourseModal course={course} onCancel={resolve} />
    ) : (
      <ArchiveCourseModal course={course} onCancel={resolve} />
    ),
  );
}
