import { FilePickerResult } from 'client/components/filepicker';
import { FileRec } from 'server/types';

export function toCommentAttachment(result: FilePickerResult): FileRec | undefined {
  if (!result) {
    return;
  }

  return {
    id: result.fileId,
    name: result.name,
    type: result.type,
    url: result.filePath,
  };
}
