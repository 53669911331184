import { render } from 'preact-render-to-string';
import { ComponentChildren } from 'preact';
import { DoubleLine } from './shared';
import { Internationalize } from 'shared/intl';

/**
 * This registers a custom JSX element type for <template-field> tags
 */
/* eslint-disable @typescript-eslint/no-namespace */
declare module 'preact/src/jsx' {
  namespace JSXInternal {
    interface IntrinsicElements {
      'template-field': any;
    }
  }
}

/**
 * Wrapper for system email messages, with "Hi {{username}}" ,
 * ending with "-- {{guide-name}}" template string
 */
function SystemEmailWrapper({
  intl,
  children,
  closingText, // closing message; eg: "See you there!"; defaults to "Thanks!"
  hideRecipientName,
}: {
  intl: Internationalize;
  children: ComponentChildren;
  closingText?: string;
  hideRecipientName?: boolean;
}) {
  return (
    <>
      <DoubleLine>
        {intl('Hi')}
        {!hideRecipientName && (
          <>
            {' '}
            <TemplateField name="recipient-name" />
          </>
        )}
        ,
      </DoubleLine>
      {children}
      <DoubleLine>{closingText ? closingText : intl('Thanks!')}</DoubleLine>
      <DoubleLine>
        -- <TemplateField name="guide-name" />
      </DoubleLine>
    </>
  );
}
/**
 * Course welcome default email template
 * This includes mustache template variables for compatibility with user-edited sytem messages:
 *    'recipient-name'
 *    'bundle-name' | 'course-name'
 *    'guide-name'
 */
export function CourseWelcome({ intl, isBundle }: { intl: Internationalize; isBundle: boolean }) {
  const [greeting, , suffix] = intl.split('Welcome to <>{courseName:string}</>!', {});
  return render(
    <SystemEmailWrapper intl={intl}>
      <DoubleLine>
        {/* Welcome to Special Course! */}
        {greeting}
        <TemplateField name={isBundle ? 'bundle-name' : 'course-name'} />
        {suffix}
      </DoubleLine>
      <DoubleLine>
        {intl('We are excited to have you on board and look forward to seeing your progress.')}
      </DoubleLine>
    </SystemEmailWrapper>,
  );
}

/**
 * "New module available" email
 * This includes mustache template variables for compatibility with user-edited sytem messages:
 *    'recipient-name'
 *    'bundle-name' | 'course-name'
 *    'module-title'
 *    'guide-name'
 */
export function NewModuleAvailable({
  intl,
  isBundle,
}: {
  intl: Internationalize;
  isBundle: boolean;
}) {
  const [newModulePrefix, , newModuleSuffix] = intl.split(
    'There is a new module in <>{courseTitle:string}</>.',
    {
      courseTitle: '',
    },
  );
  const [moduleAvailablePrefix, , moduleAvailableSuffix] = intl.split(
    '<>{moduleTitle:string}</> is now available!',
    {
      moduleTitle: '',
    },
  );

  return render(
    <SystemEmailWrapper intl={intl}>
      <DoubleLine>
        {/* There is a new module in Special Course. */}
        {newModulePrefix} <TemplateField name={isBundle ? 'bundle-name' : 'course-name'} />
        {newModuleSuffix} {/* Example Module is now available! */}
        {moduleAvailablePrefix}
        <TemplateField name="module-title" />
        {moduleAvailableSuffix}
      </DoubleLine>
    </SystemEmailWrapper>,
  );
}

/**
 * Course invitation email
 * This includes mustache template variables for compatibility with user-edited sytem messages:
 *    'recipient-name'
 *    'bundle-name' | 'course-name'
 *    'guide-name'
 */
export function InvitationEmail({ intl, isBundle }: { intl: Internationalize; isBundle: boolean }) {
  const [greeting] = intl.split(`Here's your invitation to <>{courseName:string}</>.`, {});
  return render(
    <SystemEmailWrapper
      intl={intl}
      closingText={intl(`I'm looking forward to seeing you there!`)}
      hideRecipientName
    >
      <DoubleLine>
        {greeting}
        <TemplateField name={isBundle ? 'bundle-name' : 'course-name'} />
      </DoubleLine>
    </SystemEmailWrapper>,
  );
}

/**
 * This returns a <template-field> tag with a raw template variable name wrapped in triple brackets.
 * eg: '{{{guide-name}}}'.
 * These are used by Mustache for interpolating user edited message templates.
 */
function TemplateField({ name }: { name: string }) {
  const template = `{{{${name}}}}`;
  return <template-field contenteditable="false">{template}</template-field>;
}
