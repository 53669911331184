import { showError } from '@components/app-error';
import { Button } from '@components/buttons';
import { IcoTrash } from '@components/icons';
import { showToast } from '@components/toaster';
import { Folder, store } from './state';
import * as urls from './urls';
import { useRouter } from '@components/router';

export function EmptyRowset({
  isSearch,
  folder,
  onDeleteFolder,
}: {
  isSearch: boolean;
  folder?: Folder;
  onDeleteFolder(): void;
}) {
  const router = useRouter();
  return (
    <p class="text-gray-500 p-4 flex items-center">
      {isSearch && `No items match your search.`}
      {!isSearch && !folder && `Folder not found.`}
      {!isSearch && folder && (
        <>
          <span>This folder is empty.</span>

          <Button
            class="inline-flex ml-auto px-2 py-1 items-center text-red-600 hover:bg-red-50 rounded-md"
            onClick={async () => {
              try {
                await store.deleteFolder({ id: folder.id });
                onDeleteFolder();
                router.goto(urls.baseUrl);
                showToast({
                  type: 'ok',
                  title: 'Deleted folder',
                  message: folder.name,
                });
              } catch (err) {
                showError(err);
              }
            }}
          >
            <IcoTrash />
            <span class="ml-1">Delete Folder</span>
          </Button>
        </>
      )}
    </p>
  );
}
