/**
 * This file contains the UI for editing a course's style.
 */

import { Section } from './section';
import { PageNavBar } from '@course/components/page-nav-bar';
import { useState } from 'preact/hooks';
import { PreviewBrowser } from './preview-browser';
import { showError } from '@components/app-error';
import { FontPicker } from '@course/components/font-picker';
import { filepicker } from 'client/components/filepicker';
import { CourseImage, CourseLogo } from '@course/components/course-image';
import { BtnSecondary, BtnWarning } from '@components/buttons';
import { GuideCoursePage, GuideProductPage } from '@course/components/guide-course-page';
import { URLS } from 'shared/urls';
import { rpx, RpxResponse } from 'client/lib/rpx-client';
import { useCurrentTenant } from '@components/router/session-context';
import { Dropdown, MenuItem } from '@components/dropdown';
import { DefaultSpinner } from '@components/spinner';
import { COLOR_SCHEMES, DEFAULT_COLOR_SCHEME } from 'shared/tenant-style-utils';
import { useAsyncEffect } from 'client/utils/use-async-effect';
import { useImageUrl } from 'client/utils/cdn';
import { Toggle } from '@components/toggle';
import { useDidUpdateEffect } from 'client/utils/use-did-update-effect';
import { LoadedProps, RouteLoadProps } from '@components/router';
import { courseStyleCache, defCoursesRoute } from '@course/components/courses-app-router';

const store = rpx.branding;
const brandStyleId = 'brand';
type Brand = RpxResponse<(typeof store)['getBrands']>[0];

export const route = defCoursesRoute({
  load,
  Page,
  authLevel: 'guide',
});

function BrandCard({ brand }: { brand: Brand }) {
  const imageUrl = useImageUrl(brand.iconUrl);
  return (
    <>
      <span class="inline-block px-2">
        {brand?.iconUrl && (
          <img
            class="inline-block rounded-full border-2 border-white h-10 w-10 object-cover"
            src={imageUrl}
          />
        )}
        {!brand?.iconUrl && (
          <span class="inline-block bg-gray-100 border-2 border-white rounded-full h-10 w-10" />
        )}
      </span>
      <span class="inline-block align-middle p-2">{brand?.name || 'Default Brand'}</span>
    </>
  );
}

type Props = LoadedProps<typeof load>;
type Course = Props['state']['course'];

function Content(props: Props) {
  const { setState } = props;
  const tenant = useCurrentTenant();
  const [brands, setBrands] = useState<undefined | Brand[]>();
  const { course, colorSchemes, fonts, defaultColorSchemeId } = props.state;
  const selectedColorScheme =
    colorSchemes.find((s) => s.id === course.colorSchemeId) || COLOR_SCHEMES[DEFAULT_COLOR_SCHEME];

  const [isLoading, setIsLoading] = useState(false);
  const [iframeReloadDate, setIframeReloadDate] = useState(Date.now());

  async function updateStyle(updated: Partial<Course>) {
    setIsLoading(true);
    const newCourse = { ...course, ...updated };

    try {
      await rpx.courses.updateCourseStyle({
        id: course.id,
        image: newCourse.image,
        banner: newCourse.banner,
        contentFont: newCourse.contentFont === brandStyleId ? undefined : newCourse.contentFont,
        brandId: newCourse.brand?.id,
        showTitle: newCourse.showTitle,
        colorSchemeId:
          newCourse.colorSchemeId === defaultColorSchemeId ||
          newCourse.colorSchemeId === brandStyleId
            ? undefined
            : newCourse.colorSchemeId,
      });

      // Reset the couse style cache so it will reload if the
      // guide navigates to the student view.
      courseStyleCache[course.id] = '';

      setState((s) => ({
        ...s,
        course: newCourse,
      }));
    } catch (err) {
      showError(err);
    } finally {
      setIsLoading(false);
    }
  }

  useDidUpdateEffect(() => {
    setIframeReloadDate(Date.now());
  }, [course.showTitle, course.banner, course.image]);

  async function uploadImage(opts: Partial<Parameters<typeof filepicker>[0]>) {
    const result = await filepicker({
      sources: ['filepicker', 'takephoto'],
      accept: 'image/*',
      ...opts,
    });
    return result;
  }

  return (
    <section class="grow flex flex-col md:flex-row flex-nowrap">
      <PageNavBar title="Style" interactive>
        {!tenant.isCore && course.numBrands > 0 && (
          <Section title="Brand">
            <Dropdown
              fullWidth
              triggerClass="border border-gray-300 rounded-sm pr-2 p-1 w-full"
              renderMenu={function BrandPicker() {
                useAsyncEffect(async () => {
                  if (brands) {
                    return;
                  }
                  setBrands(await store.getBrands());
                }, [brands]);

                if (!brands) {
                  return (
                    <div class="p-4 pt-8">
                      <DefaultSpinner />
                    </div>
                  );
                }

                return (
                  <div class="p-2 pb-0 flex flex-col space-y-2">
                    {brands.map((brand) => (
                      <MenuItem
                        key={brand.id}
                        class={`flex items-center cursor-pointer z-10 border rounded-md ${
                          course.brand?.id === brand.id
                            ? 'bg-indigo-50 border-indigo-400'
                            : 'bg-white'
                        } p-2 relative hover:bg-indigo-50 hover:border-indigo-500`}
                        onClick={() => {
                          updateStyle({ brand });
                        }}
                      >
                        <BrandCard brand={brand} />
                      </MenuItem>
                    ))}
                  </div>
                );
              }}
            >
              {course.brand ? <BrandCard brand={course.brand} /> : 'Default'}
            </Dropdown>
          </Section>
        )}
        <Section title="Colors">
          <select
            class="ruz-input p-3 font-medium"
            value={selectedColorScheme.id}
            onChange={(e: any) => updateStyle({ colorSchemeId: e.target.value })}
          >
            {colorSchemes.map((scheme) => (
              <option key={scheme.id} value={scheme.id}>
                {scheme.title}
              </option>
            ))}
          </select>
        </Section>
        {!course.isBundle && (
          <Section title="Typography">
            <FontPicker
              fonts={fonts}
              value={course.contentFont}
              onPick={(font) => updateStyle({ contentFont: font.id })}
            />
          </Section>
        )}
        {!course.isProduct && (
          <Section title="Banner">
            <div class="flex flex-col">
              <CourseLogo
                class="mx-auto mb-3"
                size={course.banner?.path ? '' : 'w-full h-14'}
                image={course.banner?.path}
              />
              <BtnSecondary
                class="block mb-3"
                onClick={async () => {
                  const result = await uploadImage({
                    cropRatio: 5,
                    // TODO: Uncomment this when quikpik can handle
                    // the crop when the image is too small.
                    // requireCrop: true,
                  });
                  if (result) {
                    updateStyle({
                      banner: {
                        id: result.fileId,
                        path: result.filePath,
                      },
                    });
                  }
                }}
              >
                {course.banner ? 'Upload a different banner' : 'Upload a banner'}
              </BtnSecondary>
              {!!course.banner && (
                <BtnWarning
                  onClick={() =>
                    updateStyle({
                      banner: undefined,
                    })
                  }
                >
                  Delete the current banner
                </BtnWarning>
              )}
            </div>
            {course.banner && (
              <label class="py-4 flex gap-4">
                <Toggle
                  name="showTitle"
                  checked={course.showTitle}
                  onClick={() => updateStyle({ showTitle: !course.showTitle })}
                />
                <span>Display banner and title</span>
              </label>
            )}
            <p class="text-gray-600 text-xs mt-3">
              Ideally 768px x 147px. Larger images will be resized. Smaller images won't be altered.
            </p>
          </Section>
        )}
        <Section
          title="Logo"
          description={`We recommend uploading a square 300 x 300px image. Displayed on the checkout, and my-courses pages.`}
        >
          <div class="flex flex-col">
            <CourseImage
              class="mx-auto mb-3"
              image={course.image?.path || course.brand?.courseImageUrl}
            />
            <BtnSecondary
              class="block mb-3"
              onClick={async () => {
                const result = await uploadImage({
                  cropRatio: 1,
                });
                if (result) {
                  updateStyle({
                    image: {
                      id: result.fileId,
                      path: result.filePath,
                    },
                  });
                }
              }}
            >
              {course.image ? 'Upload a different logo' : 'Upload a logo'}
            </BtnSecondary>
            {!!course.image && (
              <BtnWarning
                onClick={() =>
                  updateStyle({
                    image: undefined,
                  })
                }
              >
                Delete the current logo
              </BtnWarning>
            )}
          </div>
        </Section>
      </PageNavBar>

      <div class="p-4 md:p-8 h-screen grow">
        <PreviewBrowser
          isLoading={isLoading}
          course={course}
          brandId={course.brand?.id}
          contentFont={course.contentFont}
          colorScheme={selectedColorScheme}
          reloadDate={iframeReloadDate}
          allowedNavigationPrefix={
            // Product title slugs are not included in the URL for now
            // so we are passing an empty string for the title.
            course.isProduct
              ? URLS.student.product({ course: { id: course.id, title: '' } })
              : URLS.student.course({ course })
          }
          initialURL={
            course.isProduct
              ? URLS.student.productHome({ courseId: course.id })
              : course.isBundle
              ? URLS.student.course({ course })
              : URLS.student.lessons({ courseId: course.id })
          }
        />
      </div>
    </section>
  );
}

function Page(props: Props) {
  if (props.state.course.isProduct) {
    return (
      <GuideProductPage page="style" product={props.state.course}>
        <Content {...props} />
      </GuideProductPage>
    );
  }
  return (
    <GuideCoursePage
      course={props.state.course}
      viewLink={URLS.student.coursePage({
        course: props.state.course,
        page: 'lessons',
      })}
      type="style"
    >
      <Content {...props} />
    </GuideCoursePage>
  );
}

async function load(route: RouteLoadProps) {
  const courseId = route.params.courseId;
  const [course, assets, colorSchemes] = await Promise.all([
    rpx.courses.getGuideCourse({ id: courseId }),
    rpx.courses.getCourseAssets({ id: courseId }),
    rpx.branding.getFontsAndColors(),
  ]);

  if (colorSchemes.supportsBrands) {
    colorSchemes.defaultColorSchemeId = 'brand';
    colorSchemes.colorSchemes.splice(0, 0, {
      id: brandStyleId,
      title: 'Brand',
      navStyle: '',
    });

    colorSchemes.defaultFontId = 'brand';
    colorSchemes.fonts.splice(0, 0, {
      id: brandStyleId,
      title: 'Brand',
      previewImports: '',
      previewCSS: '',
    });
  }

  return {
    ...colorSchemes,
    course: {
      ...course,
      contentFont: course.contentFont || colorSchemes.defaultFontId,
      colorSchemeId: course.colorSchemeId || colorSchemes.defaultColorSchemeId,
      image: assets.image,
      banner: assets.banner,
      numBrands: assets.numBrands,
    },
  };
}
