import type { Dayjs } from 'dayjs';
import { useCalendarAnimation, useEventRangeLoader } from './hooks';
import { dateForView } from './date-for-view';
import dayjs from 'dayjs';
import { DayEvents, HoursSideBar } from './day-events';
import { Button } from '@components/buttons';
import { View } from './types';
import { CalendarNav, HeaderNav } from './header-nav';

type Props = {
  date: Dayjs;
  gotoView(view: View, date?: Dayjs): void;
};

export function WeekView(props: Props) {
  const animation = useCalendarAnimation(props.date);
  const events = useEventRangeLoader(props.date, props.date.endOf('week'));
  const days = new Array(7).fill(0).map((_, i) => props.date.add(i, 'days'));
  const thisWeek = dateForView('week', dayjs());

  return (
    <section class="relative grow">
      <div class="flex flex-col gap-2 rounded-2xl border grow absolute inset-0 overflow-hidden">
        <HeaderNav view="week" gotoView={props.gotoView} showToday={!props.date.isSame(thisWeek)}>
          <CalendarNav
            onPrev={() => props.gotoView('week', props.date.add(-1, 'week'))}
            onNext={() => props.gotoView('week', props.date.add(1, 'week'))}
            title={props.date.format('MMMM YYYY')}
          />
        </HeaderNav>
        <section class="flex flex-col grow pl-6">
          <div class="flex">
            <span class="w-14"></span>
            {days.map((d) => (
              <span key={d} class="flex flex-col grow items-center justify-center">
                <span class="uppercase text-xs">{d.format('ddd')}</span>
                <Button class="text-2xl" onClick={() => props.gotoView('day', d)}>
                  {d.format('D')}
                </Button>
              </span>
            ))}
          </div>
          <div class="flex grow relative">
            <section class="overflow-y-auto overflow-x-hidden absolute inset-0">
              <div key={props.date} class={`flex ${animation}`}>
                <HoursSideBar />
                {days.map((d) => (
                  <DayEvents key={d.format('YY-MM-DD')} day={d} events={events} />
                ))}
              </div>
            </section>
          </div>
        </section>
      </div>
    </section>
  );
}
