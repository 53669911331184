import { BtnSecondary } from '@components/buttons';
import { StandardDialog } from '@components/dialog';
import { IcoChevronDown } from '@components/icons';
import { showModalForm } from '@components/modal-form';

interface Font {
  id: string;
  title: string;
  previewImports: string;
  previewCSS: string;
}

interface FontOpts {
  fonts: Font[];
  value?: string;
}

function FontDemo() {
  return (
    <>
      <span class="h1 text-4xl">This is a title</span>
      <span class="h2 text-2xl">And a subtitle</span>
      <span class="p text-lg">
        This is how normal content will look. If it is bold, <strong>it will look like this</strong>
        , and if it is italic, <em>it will look like this</em>.
      </span>
    </>
  );
}

export function FontPreview({ font }: { font: Font }) {
  const fontCSS = font.previewCSS;
  const previewCSSGeneral = `.font-preview .h1 {font-size: 2rem;}`;

  return (
    <div class="font-preview">
      <style dangerouslySetInnerHTML={{ __html: previewCSSGeneral + fontCSS }}></style>
      <span class={`${font.id} flex flex-col space-y-4`}>
        <FontDemo />
      </span>
    </div>
  );
}

function showFontModal({ fonts, value }: FontOpts) {
  return showModalForm<Font>(({ resolve }) => {
    const hide = () => resolve(undefined);
    const fontImports = fonts.map((f) => f.previewImports || '').join('\n');
    const fontCSS = fonts.map((f) => f.previewCSS).join('\n');
    const previewCSSGeneral = `.font-preview .h1 {font-size: 2rem;}`;

    return (
      <StandardDialog onClose={hide} title="Choose your typography">
        <div class="flex flex-col gap-2 font-preview mb-20">
          <style
            dangerouslySetInnerHTML={{ __html: fontImports + previewCSSGeneral + fontCSS }}
          ></style>
          {fonts.map((f) => {
            return (
              <label
                key={f.id}
                class={`${f.id} ${
                  f.id === value ? 'bg-indigo-50 border-indigo-200' : 'hover:bg-gray-50'
                } p-4 rounded-md border bg-white flex flex-col cursor-pointer space-y-4`}
                onClick={() => resolve(f)}
              >
                <span class="p font-bold mb-2">{f.title}</span>
                <FontDemo />
              </label>
            );
          })}
        </div>
      </StandardDialog>
    );
  });
}

export function FontPicker(props: { fonts: Font[]; value: string; onPick: (font: Font) => void }) {
  const { fonts, value } = props;
  const font = fonts.find((f) => f.id === value);

  return (
    <BtnSecondary
      class="flex items-center justify-between ruz-input gap-8 bg-white py-3"
      onClick={() =>
        showFontModal({
          fonts: props.fonts,
          value: `${props.value || ''}`,
        }).then((x) => x && props.onPick(x))
      }
    >
      <span>{font?.title || value}</span>
      <IcoChevronDown />
    </BtnSecondary>
  );
}
