import { useTryAsyncData } from 'client/lib/hooks';
import { rpx } from 'client/lib/rpx-client';
import type { Dayjs } from 'dayjs';
import { useRef } from 'preact/hooks';

/**
 * Load events for the specified date range for the current host.
 */
export function useEventRangeLoader(start: Dayjs, end: Dayjs) {
  const { data } = useTryAsyncData(async () => {
    const result = await rpx.ruzcalEvents.getEventsInRange({
      start: start.toDate(),
      end: end.toDate(),
    });
    return result.events.map((x) => ({ ...x, start: new Date(x.start), end: new Date(x.end) }));
  }, [start]);

  return data || [];
}

/**
 * Given a date, returns a left / right animation when there is a change.
 */
export function useCalendarAnimation(date: Dayjs) {
  const prevValue = useRef(date);
  const animation = useRef('an-fade-in-left');
  if (prevValue.current.isBefore(date)) {
    animation.current = 'an-fade-in-left';
  } else if (prevValue.current.isAfter(date)) {
    animation.current = 'an-fade-in-right';
  }
  prevValue.current = date;
  return animation.current;
}
