import { ComponentChildren } from 'preact';

interface Props {
  title?: string;
  children?: ComponentChildren;
}

/**
 * The primary means for breaking a form / page into sections.
 */
export function FormSection(props: Props) {
  return (
    <section class={`text-sm ${props.title ? '' : 'pt-4'}`}>
      {props.title && <h2 class="text-gray-900 font-medium mb-2">{props.title}</h2>}
      {props.children}
    </section>
  );
}
