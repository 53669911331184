/**
 * Utilities for reasoning about Stripe ids.
 */

import { Currency } from 'server/types';

const hasPrefix =
  (prefix: string) =>
  (id?: string): id is string =>
    !!id && id.startsWith(prefix);

export const isCharge = hasPrefix('ch_');

export const isPaymentIntent = hasPrefix('pi_');

/**
 * The per-currency Stripe minimum prices, in cents. So, $0.50 is represented
 * as 50.
 *
 * https://stripe.com/docs/currencies#minimum-and-maximum-charge-amounts
 */
export function minPrice(currency: Currency) {
  if (currency === 'GBP') {
    return 30;
  }

  if (currency === 'HKD') {
    return 400;
  }

  // This is the most common minimum in Stripe, so we'll just default to it.
  return 50;
}
