/*
 * This is a `useEffect` helper that lets you skip the
 * initial(mount) render. So it basically acts like the old `componentDidUpdate`.
 */
import { useEffect, useRef, Inputs } from 'preact/hooks';

export function useDidUpdateEffect(fn: () => void, inputs: Inputs) {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) {
      return fn();
    } else {
      didMountRef.current = true;
    }
  }, inputs);
}
