import { isNullish } from 'shared/utils';
import { Plugin } from './index';

export const plugins: Record<string, Plugin> = {
  /**
   * Pluralize a value. If the propName variant is used, the value of
   * args[propName] is pluralized. The value is looked up in `utils.pluralize`
   * first, and if found, the translation there is used.
   *
   * Usage:
   *
   *    {count | pluralize thing things}
   *    {count | pluralize propName}
   */
  pluralize(ctx) {
    const [singular, plural] = ctx.params;
    const val = ctx.value as number;
    const isPlural = val !== 1;

    if (ctx.params.length > 1) {
      return isPlural ? plural : singular;
    }

    // The pluralize plugin was called like this: {count | pluralize propName}
    const propName = singular;
    const propVal = ctx.args?.[propName];
    if (isNullish(propVal)) {
      return '';
    }
    const result = ctx.translation.utils?.pluralization?.[propVal]?.[isPlural ? 1 : 0];
    return result || (isPlural ? `${propVal}s` : propVal);
  },
};
