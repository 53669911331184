import { useConfiguration } from '@components/router/session-context';

export function imageUrl(config: { cdn: string }, path?: string) {
  return path && `${config.cdn}/${path}`;
}

export function useImageUrl(path?: string, opts?: { maxWidth?: number }) {
  const config = useConfiguration();
  if (!path || path.includes('://')) {
    return path;
  }
  if (!config || !path) {
    return;
  }
  if (path.includes('/profilepic')) {
    return path;
  }
  const url = imageUrl(config, path);
  return url + (opts?.maxWidth ? `?width=${opts.maxWidth}` : '');
}

const sessionDate = Date.now();

// Fetch a simple text file from the Wasabi bucket to determine if Wasabi endpoint is accessible.
// See the ticket for more details: https://github.com/morebetterlabs/ruzuku-v2/issues/4558
export async function shouldUseWasabiProxy() {
  // Abort the fetch after 2 seconds
  const controller = new AbortController();
  const timer = setTimeout(() => controller.abort(), 2000);

  try {
    await fetch(
      `https://s3.wasabisys.com/ruzukuprod.ruzukuassets.com/ruzuku/assets/ok.txt?time=${sessionDate}`,
      {
        signal: controller.signal,
      },
    );
    return false;
  } catch (e) {
    return true;
  } finally {
    clearTimeout(timer);
  }
}
