import { Button } from '@components/buttons';
import { ComponentChildren } from 'preact';

export function PrimaryOverviewCard(props: {
  title: ComponentChildren;
  headerActions?: ComponentChildren;
  children: ComponentChildren;
  footerAction?: {
    href?: string;
    onClick?(): void;
    text?: ComponentChildren;
  };
}) {
  return (
    <div class="border border-gray-200 dark:text-gray-300 dark:border-gray-700 rounded-lg w-full overflow-hidden">
      <header class="px-4 md:px-6 py-1 flex items-center justify-between flex-wrap">
        <h2 class="text-base py-2 flex-nowrap">{props.title}</h2>
        <nav class="md:max-w-1/2">{props.headerActions}</nav>
      </header>
      {props.children}
      {props.footerAction && (
        <Button
          class="border-t border-gray-200 border:border-gray-700 w-full px-6 p-4 flex justify-center hover:bg-gray-50 text-inherit"
          onClick={props.footerAction.onClick}
          href={props.footerAction.href}
        >
          {props.footerAction.text || 'View All'}
        </Button>
      )}
    </div>
  );
}

export function OverviewCard(props: {
  title: ComponentChildren;
  headerActions?: ComponentChildren;
  children: ComponentChildren;
  footerAction?: {
    href?: string;
    onClick?(): void;
    text?: ComponentChildren;
  };
}) {
  return (
    <div>
      <header class="flex items-center justify-between px-4">
        <h2 class="font-medium text-lg text-gray-800 dark:text-gray-200">{props.title}</h2>
        {props.headerActions}
      </header>
      {props.children}
      {props.footerAction && (
        <Button
          class="rounded-lg w-full py-3 hover:bg-gray-100 dark:hover:bg-black/25 hover:text-indigo-800 text-indigo-600 dark:text-sky-500 font-medium text-left mt-2 px-4"
          onClick={props.footerAction.onClick}
          href={props.footerAction.href}
        >
          {props.footerAction.text || 'Show More ⤑'}
        </Button>
      )}
    </div>
  );
}
