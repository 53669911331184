import { StudentPage } from '@course/components/student-page';
import { rpx } from 'client/lib/rpx-client';
import { indexBy } from 'shared/utils';
import { Calendar, CalendarDay } from '@course/components/calendar';
import { Button } from '@components/buttons';
import dayjs from 'dayjs';
import { URLS } from 'shared/urls';
import { timeWithoutZeroes, toLocalDate } from 'shared/dateutil';
import { LoadedProps, RouteLoadProps } from '@components/router';
import { defStudentRoute } from '@course/components/courses-app-router';

type PageData = Awaited<ReturnType<typeof load>>;

export const route = defStudentRoute({
  Page,
  authLevel: 'student',
  load,
});

function CellItem({ item, course }: { item: PageData['items'][0]; course: PageData['course'] }) {
  return (
    <Button
      class="relative w-full flex text-left rounded-md hover:bg-zinc-100 dark:hover:bg-zinc-800 p-1 -ml-1 box-content text-gray-500"
      data-tooltip={item.title}
      href={
        item.type === 'meeting'
          ? URLS.student.meeting({
              course,
              meeting: item,
            })
          : URLS.student.module({
              courseId: course.id,
              moduleId: item.id,
            })
      }
    >
      <span class="inline-block items-center">
        <span
          class={`w-2 h-2 inline-block rounded-full ${
            item.type === 'meeting' ? 'bg-indigo-300' : 'bg-blue-500'
          }`}
        ></span>
      </span>
      <time class="opacity-75 mx-0.5 text-xs lg:mx-1.5">{timeWithoutZeroes(item.date)}</time>
      <span class="hidden md:inline overflow-hidden whitespace-nowrap font-semibold">
        {item.title}
      </span>
    </Button>
  );
}

function Page(props: LoadedProps<typeof load>) {
  const routeDate = toLocalDate(props.params.date);
  const { course, items } = props.state;

  return (
    <StudentPage
      p="py-0"
      course={course}
      currentLink="calendar"
      editLink={{
        url: URLS.guide.coursePage({
          courseId: course.id,
          page: 'calendar',
        }),
      }}
    >
      <div class="w-full xl:w-5xl max-w-5xl p-8 pb-24 mx-auto">
        <Calendar
          items={items}
          currentDate={routeDate}
          isReadOnly
          renderCellItems={({ items, date, now }) => (
            <div class="min-h-12 lg:min-h-24">
              <CalendarDay date={date} now={now} />
              {items.map((item) => (
                <CellItem key={item.key} item={item} course={course} />
              ))}
            </div>
          )}
          onChange={(newDate) =>
            props.router.rewrite(
              URLS.student.coursePage({
                course,
                page: `calendar?date=${dayjs(newDate).format('YYYY-MM-DD')}`,
              }),
            )
          }
        />
      </div>
    </StudentPage>
  );
}

async function load(route: RouteLoadProps) {
  const routeParams = route.params;
  const courseId = routeParams.courseId;
  const [course, rawItems] = await Promise.all([
    rpx.courses.getStudentCourse({ id: courseId }),
    rpx.courses.getScheduledItemsForStudent({
      courseId,
    }),
  ]);

  return {
    course,
    items: Object.values(
      indexBy(
        (x) => x.key,
        rawItems.map((x) => ({
          ...x,
          key: `${x.type}-${x.id}`,
          date: new Date(x.date),
        })),
      ),
    ),
  };
}
