import { render } from 'preact-render-to-string';
import { BasicEmailWrapper, Heading, Line, SupportFooter, SubHeading, DoubleLine } from './shared';
import { CourseRow, TenantRow, UserProfileRow } from 'server/types';
import { URLS } from 'shared/urls';
import { summarizeStudentNames } from '../helpers';

type Props = {
  course: Pick<CourseRow, 'id' | 'title'>;
  tenant: Pick<TenantRow, 'domain' | 'name' | 'email'>;
  items: {
    lessonId: UUID;
    lessonTitle: string;
    assessmentType: 'quiz' | 'poll';
    students: Pick<UserProfileRow, 'userId' | 'name' | 'displayName'>[];
  }[];
};

// Maximum rows for display for each type (quiz and poll)
const MAX_ITEMS = 10;

/**
 * Guide notification for new student quiz or poll submissions
 */
export function AssessmentsSubmitted({ course, tenant, items }: Props) {
  const notificationSettingsUrl = URLS.guide.coursePage({
    courseId: course.id,
    domain: tenant.domain,
    page: 'settings/notifications',
  });

  // Summarize the action
  // eg: "Max, Susan, and 14 others submitted quiz responses\s"
  const summaryRows = items.map((row) => {
    const { lessonId, assessmentType, students, lessonTitle } = row;
    const lessonUrl = URLS.guide.lesson({ lessonId, courseId: course.id, domain: tenant.domain });
    const studentNames = row.students.map((x) => x.displayName || x.name || '');
    const summary = `${summarizeStudentNames(studentNames)} submitted ${numeratorForType(
      assessmentType,
      students.length,
    )}`;
    return { lessonId, lessonUrl, lessonTitle, assessmentType, summary };
  });

  // Group these into quiz and poll responses
  const quizItems = summaryRows.filter((x) => x.assessmentType === 'quiz');
  const pollItems = summaryRows.filter((x) => x.assessmentType === 'poll');

  // Counts vs truncated counts
  const numQuizzes = quizItems.length;
  const numPolls = pollItems.length;
  const numOtherQuizzes = numQuizzes > MAX_ITEMS ? numQuizzes - MAX_ITEMS : 0;
  const numOtherPolls = numPolls > MAX_ITEMS ? numPolls - MAX_ITEMS : 0;

  return render(
    <BasicEmailWrapper footer={<SupportFooter />}>
      <Heading>New assessment submissions in {course.title}</Heading>
      <Line>There are new poll or quiz submissions in your in your course {course.title}.</Line>

      {/* Quiz submissions */}
      {!!quizItems.length && (
        <DoubleLine>
          <SubHeading>
            {/* "3 quizzes completed" */}
            {numQuizzes} {numQuizzes === 1 ? 'quiz' : 'quizzes'} completed.
          </SubHeading>

          {/* Row per quiz, up to 10 items */}
          <ul style="margin-top:1.5rem;padding-left:2.6rem;">
            {quizItems.slice(0, MAX_ITEMS).map((row, index) => (
              <li key={`quizItems-${index}`}>
                {row.summary} on <a href={row.lessonUrl}>{row.lessonTitle}</a>
              </li>
            ))}
          </ul>
          {!!numOtherQuizzes && <Line>...and {numOtherQuizzes} more items</Line>}
        </DoubleLine>
      )}

      {/* Poll submissions */}
      {!!pollItems.length && (
        <DoubleLine>
          <SubHeading>
            {/* "3 polls completed" */}
            {numPolls} {numPolls === 1 ? 'poll' : 'polls'} completed.
          </SubHeading>

          {/* Cap display rows at MAX_ITEMS items */}
          <ul style="margin-top:1.5rem;padding-left:2.6rem;">
            {pollItems.slice(0, MAX_ITEMS).map((row, index) => (
              <li key={`pollItems-${index}`}>
                {row.summary} on <a href={row.lessonUrl}>{row.lessonTitle}</a>
              </li>
            ))}
          </ul>
          {!!numOtherPolls && <Line>...and {numOtherPolls} more items</Line>}
        </DoubleLine>
      )}

      <Line>
        <span style="font-size:0.9rem;">
          You can manage notification settings for quizzes and polls in this course at the{' '}
          <a href={notificationSettingsUrl}>course notifications settings page</a>
        </span>
      </Line>
    </BasicEmailWrapper>,
  );
}

/**
 * Natural language sentence for "N (quiz|poll) response(s)"
 */
const numeratorForType = (assessmentType: 'quiz' | 'poll', numStudents: number) => {
  if (assessmentType === 'quiz') {
    return numStudents === 1 ? 'a quiz response' : 'quiz responses';
  }
  return numStudents === 1 ? 'a poll response' : 'poll responses';
};
