import { AccountTabContent } from '@components/password-form';
import { StripeIntegration } from './stripe-integration';
import { PaypalIntegration } from './paypal-integration';
import { ZapierIntegration } from './zapier-integration';
import { ZoomIntegration } from './zoom-integration';
import { rpx } from 'client/lib/rpx-client';
import { useAsyncData } from 'client/lib/hooks';
import { DefaultSpinner } from '@components/spinner';
import { CollectAddressToggle } from './collect-address-toggle';

export function IntegrationsTab() {
  const loader = useAsyncData(() => rpx.accounts.getIntegrations(), []);

  if (loader.isLoading || !loader.data) {
    return <DefaultSpinner />;
  }

  const { stripeAcct, paypalClientId, collectAddress } = loader.data;

  return (
    <AccountTabContent title="Integrations">
      <div class="flex flex-col border rounded-lg divide-y">
        <StripeIntegration stripeAcct={stripeAcct} />
        <PaypalIntegration clientId={paypalClientId} />
        {(stripeAcct || paypalClientId) && <CollectAddressToggle collectAddress={collectAddress} />}
        <ZapierIntegration />
        <ZoomIntegration />
      </div>
    </AccountTabContent>
  );
}
