import { useAuth, useCurrentUser } from 'client/lib/auth';
import { IcoCheck, IcoPlus, IcoTrash } from '@components/icons';
import { RuzcalMgmtPage } from './mgmt-page';
import { useMemo, useRef, useState } from 'preact/hooks';
import dayjs from 'dayjs';
import { BtnPrimary, Button } from '@components/buttons';
import { focusRef } from 'client/utils/autofocus';
import { PageContent, PageHeading, PageSection, defCalRoute } from './common';
import { rpx } from 'client/lib/rpx-client';
import type { TimeSlot } from 'server/types/cal-overrides';
import { AsyncForm } from '@components/async-form';
import { LoadedProps, RouteLoadProps, useRouter } from '@components/router';
import { showModalForm } from '@components/modal-form';
import { useAsyncData } from 'client/lib/hooks';
import { BaseDialog, DialogHeader } from '@components/dialog';
import { DefaultSpinner } from '@components/spinner';
import { AvailabilityRow } from 'server/types/cal-schema';
import { TimeCombobox } from './time-combobox';
import { showError } from '@components/app-error';

type Props = LoadedProps<typeof load>;
type State = Props['state'];

const defaultName = 'Business hours';

export const route = defCalRoute({ load, authLevel: 'guide', Page });

async function load(opts: Pick<RouteLoadProps, 'auth' | 'params'>) {
  const { id } = opts.params;
  const isNew = id === 'new';
  const user = opts.auth.user!;
  if (isNew) {
    return {
      isNew,
      timeslots: [
        {
          key: 'ts-new',
          days: [1, 2, 3, 4, 5],
          start: '9:00am',
          end: '5:00pm',
        },
      ],
      isDefault: true,
      id,
      name: '',
      hostId: user.id,
      timezone: user.timezone,
      createdAt: new Date(),
      updatedAt: new Date(),
    };
  }
  const availability = await rpx.ruzcal.getAvailability({ id });
  return {
    isNew,
    ...availability,
    timeslots: availability.timeslots.map((x, i) => ({ ...x, key: `ts-${i}` })),
  };
}

function addMins(mins: number, time?: string) {
  if (!time) {
    return;
  }

  const date = dayjs('2000-01-01 ' + time).add(mins, 'minute');
  if (date.isValid()) {
    return date.format('h:mma');
  }
}

function EditTimeSlot({
  value,
  onChange,
  onDelete,
  timezone,
}: {
  value: TimeSlot;
  onChange(value: TimeSlot): void;
  onDelete?(): void;
  timezone: string;
}) {
  const days = useMemo(
    () =>
      new Array(7).fill(0).map((_, i) => ({
        day: i,
        desc: dayjs().set('day', i).format('dd'),
      })),
    [],
  );
  return (
    <div class="flex flex-col gap-6 p-6 border border-gray-300 rounded-2xl">
      <section class="flex items-center gap-4">
        <div class="inline-flex items-center gap-2 border rounded-lg border-gray-300 text-sm">
          <TimeCombobox
            name="start"
            placeholder="9:00am"
            value={value.start}
            onChange={(start) => onChange({ ...value, start })}
            hour12
          />
          -
          <TimeCombobox
            name="end"
            placeholder="5:00pm"
            value={value.end}
            onChange={(end) => onChange({ ...value, end })}
            hour12
          />
        </div>
        <span class="hidden sm:text-gray-500">{timezone}</span>
        {onDelete && (
          <Button
            onClick={onDelete}
            data-tooltip="Remove time window"
            class="hover:bg-red-50 size-8 md:size-10 hover:text-red-600 rounded-full aspect-square inline-flex items-center justify-center ml-auto"
          >
            <IcoTrash />
          </Button>
        )}
      </section>
      <section class="grid grid-cols-7 gap-2 select-none max-w-xl">
        {days.map((d) => {
          const checked = value.days.includes(d.day);
          return (
            <label key={d} class="cursor-pointer relative group flex flex-col">
              <input
                type="checkbox"
                class="absolute opacity-0 z-0"
                checked={checked}
                value={d.day}
                name="days[]"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  const toggled = value.days.filter((x) => x !== d.day);
                  if (!checked) {
                    toggled.push(d.day);
                  }
                  onChange({
                    ...value,
                    days: toggled,
                  });
                }}
              />
              <span
                class={`relative z-10 flex items-center justify-center transition-all p-1 font-medium rounded-full ${
                  checked
                    ? 'bg-indigo-500 text-white border-indigo-200 hover:bg-indigo-600'
                    : 'bg-gray-100 hover:bg-gray-200'
                } group-focus-within:ring-2 gap-2 ring-indigo-500 ring-offset-2`}
              >
                {checked && <IcoCheck class="size-4 shrink-0 hidden sm:inline-block" />}
                <span>{d.desc}</span>
              </span>
            </label>
          );
        })}
      </section>
    </div>
  );
}

function AvailabilityForm({
  state,
  setState,
  onSave,
  onCancel,
}: {
  state: Props['state'];
  setState: Props['setState'];
  onCancel?(): void;
  onSave?(result: AvailabilityRow): void;
}) {
  const router = useRouter();
  const user = useCurrentUser()!;
  const slotId = useRef(0);
  return (
    <AsyncForm
      class="flex flex-col gap-8 max-w-2xl"
      onSubmit={async () => {
        try {
          const result = await rpx.ruzcal.saveAvailability({
            name: state.name.trim() || defaultName,
            id: state.id === 'new' ? undefined : state.id,
            timezone: state.timezone,
            timeslots: state.timeslots.map(({ key, ...x }) => x),
            isDefault: state.isDefault,
          });
          if (onSave) {
            onSave(result);
          } else {
            router.goto('/calendar/availability');
            await new Promise((r) => setTimeout(r, 1000));
          }
        } catch (err) {
          showError(err);
        }
      }}
    >
      <label class="flex flex-col gap-1 max-w-80">
        <span class="font-medium">Title</span>
        <input
          type="text"
          name="name"
          class="inline-ruz-input px-4 p-3 text-sm"
          value={state.name}
          placeholder={`e.g. ${defaultName}`}
          ref={focusRef}
          onInput={(e: any) => setState((s) => ({ ...s, name: e.target.value }))}
        />
      </label>
      <section class="flex flex-col gap-1">
        <span class="font-medium">
          Available times{' '}
          <span class="text-gray-500">({user.timezone.replaceAll('_', ' ')} time)</span>
        </span>
        <div class="flex flex-col gap-6">
          {state.timeslots.map((slot) => (
            <EditTimeSlot
              key={slot.key}
              timezone={user.timezone}
              value={slot}
              onDelete={
                state.timeslots.length <= 1
                  ? undefined
                  : () =>
                      setState((s) => ({
                        ...s,
                        timeslots: s.timeslots.filter((x) => x !== slot),
                      }))
              }
              onChange={(value) =>
                setState((s) => ({
                  ...s,
                  timeslots: s.timeslots.map((x) => (x === slot ? { ...x, ...value } : x)),
                }))
              }
            />
          ))}

          <footer>
            <Button
              class="flex items-center gap-2 p-3 px-4 bg-gray-100 text-gray-900 hover:bg-gray-200 rounded-full transition-all"
              onClick={() => {
                const prev = state.timeslots[state.timeslots.length - 1];
                setState((s) => ({
                  ...s,
                  timeslots: [
                    ...s.timeslots,
                    {
                      key: `tsnew-${++slotId.current}`,
                      start: addMins(30, prev?.end) || '9:00am',
                      end: addMins(90, prev?.end) || '5:00pm',
                      days: prev?.days || [1, 2, 3, 4, 5],
                    },
                  ],
                }));
              }}
            >
              <IcoPlus /> Add a time window
            </Button>
          </footer>
        </div>
      </section>
      <footer class="flex gap-4">
        <BtnPrimary class="p-3 px-4 rounded-full">
          {state.isNew ? 'Create' : 'Save'} availability
        </BtnPrimary>
        <Button
          href={onCancel ? undefined : '/calendar/availability'}
          onClick={onCancel}
          class="text-inherit inline-flex items-center justify-center hover:bg-gray-100 px-4 transition-all rounded-full"
        >
          Cancel
        </Button>
      </footer>
    </AsyncForm>
  );
}

export function showAvailabilityModal() {
  return showModalForm<AvailabilityRow | undefined>(({ resolve }) => {
    const auth = useAuth();
    const data = useAsyncData(() => load({ params: { id: 'new' }, auth }), []);

    function LoadedForm(data: State) {
      const [state, setState] = useState(data);
      return (
        <div class="p-10">
          <DialogHeader
            title="Create availability"
            subtitle="Define a window of time when you can be booked for events."
          />
          <AvailabilityForm
            state={state}
            setState={setState}
            onSave={(result) => {
              resolve(result);
            }}
            onCancel={() => {
              resolve();
            }}
          />
        </div>
      );
    }

    return (
      <BaseDialog onClose={resolve}>
        {data.data && <LoadedForm {...data.data} />}
        {!data.data && (
          <div class="aspect-square flex flex-col items-center justify-center">
            <DefaultSpinner />
          </div>
        )}
      </BaseDialog>
    );
  });
}

function Page({ state, setState }: Props) {
  const name = `${state.name || 'Untitled'} availability`;

  return (
    <RuzcalMgmtPage
      title={name}
      currentPage="availability"
      crumbs={[
        {
          text: 'Availability',
          href: '/calendar/availability',
        },
        { text: name },
      ]}
    >
      <PageContent>
        <PageSection>
          <header>
            <PageHeading title={name} />
          </header>
          <AvailabilityForm state={state} setState={setState} />
        </PageSection>
      </PageContent>
    </RuzcalMgmtPage>
  );
}
