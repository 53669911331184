import { showError } from '@components/app-error';
import { UserProfileIcon } from '@components/avatars';
import { Case } from '@components/conditional';
import { Discussion } from '@course/components/discussions';
import { Dropdown, MenuItem } from '@components/dropdown';
import {
  IcoChat,
  IcoChevronLeft,
  IcoClock,
  IcoDotsHorizontal,
  IcoPencil,
  IcoPinned,
  IcoTrash,
} from '@components/icons';
import { ReadonlyMinidoc } from '@components/minidoc/readonly-minidoc';
import { Pill } from '@components/pill';
import { useRouteParams, useRouter } from '@components/router';
import { useCurrentTenant, useCurrentUser } from '@components/router/session-context';
import { rpx } from 'client/lib/rpx-client';
import { useState } from 'preact/hooks';
import { genericDiscussionCategoryIds } from 'shared/consts';
import { timeago } from 'shared/dateutil';
import { useIntl } from 'shared/intl/use-intl';
import { emptyLessonTitle } from 'shared/terminology';
import { URLS } from 'shared/urls';
import { DiscussionForm } from './discussion-form';
import { State } from './index';
import { showDialog } from '@components/dialog';

interface Props {
  discussion: NonNullable<State['selectedDiscussion']>;
  course: State['course'];
  categories: State['categories'];
}

const store = rpx.discussions;

function StartText({ discussion, course }: Pick<Props, 'discussion' | 'course'>) {
  const tenant = useCurrentTenant();
  const intl = useIntl();
  const { lesson } = discussion;

  const [prefix, startLesson, suffix] = lesson
    ? intl.split('Started {timeago:string} ago in <>{lesson:string}</> lesson.', {
        timeago: timeago(discussion.createdAt),
        lesson: lesson?.title || emptyLessonTitle(tenant),
      })
    : intl.split('Started {timeago:string} ago in <>{category:string}</> category.', {
        timeago: timeago(discussion.createdAt),
        category: discussion.category?.title || genericDiscussionCategoryIds.nonLessons,
      });

  return (
    <div class="flex items-center gap-2">
      <IcoClock />
      <span class="line-clamp-1 max-w-md">
        {prefix}
        <a
          class="capitalize"
          href={
            lesson
              ? URLS.student.lesson({
                  course,
                  lesson,
                })
              : URLS.student.discussions({
                  course,
                  categoryId: discussion.category?.id || genericDiscussionCategoryIds.nonLessons,
                })
          }
        >
          {startLesson}
        </a>
        {suffix}
      </span>
    </div>
  );
}

export function DiscussionDetail(props: Props) {
  const { categories, course } = props;

  const [discussion, setDiscussion] = useState(props.discussion);

  const intl = useIntl();
  const {
    commentId: highlightedCommentId,
    replyId: highlightedReplyId,
    categoryId,
  } = useRouteParams();
  const { author } = discussion;
  const currentUser = useCurrentUser();
  const isMyDiscussion = currentUser?.id === author.id;
  const isCourseGuide = course.guide.id === currentUser?.id;
  const router = useRouter();
  const [isEditing, setIsEditing] = useState(false);

  async function deleteDiscussion() {
    const isConfirmed = await showDialog({
      mode: 'warn',
      title: intl('Delete discussion'),
      children: intl(
        'Are you sure you want to delete this discussion? This action cannot be undone.',
      ),
      confirmButtonText: intl('Delete discussion'),
    });

    if (!isConfirmed) {
      return;
    }

    try {
      await store.deleteDiscussion({
        id: discussion.id,
      });
      router.goto(URLS.student.discussions({ course, categoryId }));
    } catch (err) {
      showError(err);
    }
  }

  async function togglePin() {
    try {
      const newVal = !discussion.isPinned;
      await store.togglePin({
        id: discussion.id,
        isPinned: newVal,
      });
      setDiscussion((d) => ({ ...d, isPinned: newVal }));
    } catch (err) {
      showError(err);
    }
  }

  if (isEditing) {
    return (
      <DiscussionForm
        categories={categories}
        initialState={discussion}
        initialCategoryId={discussion.category?.id || genericDiscussionCategoryIds.nonLessons}
        isEdit
        shouldRenderPdfViewer
        onCancel={() => setIsEditing(false)}
        onSuccess={(newDiscussion) => {
          setDiscussion(newDiscussion);
          setIsEditing(false);
        }}
      />
    );
  }

  return (
    <>
      <a
        class="inline-flex items-center text-zinc-500 dark:text-gray-400 pb-4"
        href={URLS.student.discussions({
          course,
          categoryId,
        })}
      >
        <IcoChevronLeft class="mr-1 w-4 h-4" />
        {intl('Discussions')}
      </a>
      <header class="mb-8">
        <div class="flex items-center justify-between relative">
          <h1 class="inline-flex items-start font-display text-4xl leading-snug tracking-tight text-gray-900 dark:text-white">
            {discussion.isPinned && <IcoPinned class="w-4 h-4 min-w-4 mr-2 mt-4" />}
            {discussion.prompt}
          </h1>
          {(isMyDiscussion || isCourseGuide) && (
            <Dropdown
              hideDownIcon
              class="text-gray-500 dark:text-gray-200"
              renderMenu={() => (
                <div class="flex flex-col p-2 pb-0">
                  {isCourseGuide && (
                    <MenuItem onClick={togglePin}>
                      <IcoPinned />
                      <span class="ml-2">
                        {discussion.isPinned ? intl('Unpin discussion') : intl('Pin discussion')}
                      </span>
                    </MenuItem>
                  )}
                  <MenuItem onClick={deleteDiscussion}>
                    <IcoTrash />
                    <span class="ml-2">{intl('Delete')}</span>
                  </MenuItem>
                  {isMyDiscussion && (
                    <MenuItem onClick={() => setIsEditing(true)}>
                      <IcoPencil />
                      <span class="ml-2">{intl('Edit this discussion')}</span>
                    </MenuItem>
                  )}
                </div>
              )}
            >
              <span class="inline-flex rounded-full p-1 hover:bg-gray-100 dark:hover:bg-gray-700">
                <IcoDotsHorizontal class="w-5 h-5" />
              </span>
            </Dropdown>
          )}
        </div>
        <div class="flex flex-col md:flex-row md:items-center gap-1 md:gap-2 text-xs dark:text-gray-400 mt-2">
          <div class="flex items-center space-x-2">
            <UserProfileIcon user={author} size="w-8 h-8" />
            <Case when={!course.hidePeople} fallback={<span>{author.name}</span>}>
              <a
                href={URLS.student.memberProfile({
                  course,
                  user: author,
                })}
              >
                {author.name}
              </a>
            </Case>
            {author.id === course.guide.id && <Pill>{intl('Guide')}</Pill>}
          </div>
        </div>
        <div class="flex flex-col md:flex-row md:items-center gap-2 md:gap-4 mt-2">
          <StartText discussion={discussion} course={course} />
          {discussion.numRootComments > 0 && (
            <>
              <Divider />
              <span class="flex grow items-center gap-1">
                <IcoChat />
                {intl('{count:number} {count | pluralize comment comments}', {
                  count: discussion.numRootComments,
                })}
                {discussion.numReplies > 0 && (
                  <span>
                    {intl('and {count:number} {count | pluralize reply replies}', {
                      count: discussion.numReplies,
                    })}
                  </span>
                )}
              </span>
            </>
          )}
        </div>
        <ReadonlyMinidoc class="mt-4" content={discussion.description} shouldRenderPdfViewer />
      </header>
      <Discussion
        key={discussion.id}
        courseId={course.id}
        discussionId={discussion.id}
        guideId={course.guide.id}
        hidePeople={course.hidePeople}
        highlightedId={highlightedCommentId}
        highlightedReplyId={highlightedReplyId}
      />
    </>
  );
}

function Divider() {
  return <span class="hidden md:inline text-lg opacity-75">·</span>;
}
