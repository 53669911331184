import { BtnPrimary, Button } from '@components/buttons';
import { DraggableProvider, Draggable } from '@components/draggable';
import { Spinner } from '@components/spinner';
import { ComponentChildren } from 'preact';
import { ManageState } from './types';
import { Dispatch, StateUpdater, useEffect, useRef, useState } from 'preact/hooks';
import { scrollToModule } from './scroll-to-module';
import { IcoChevronDown, IcoChevronRight, IcoGripper, IcoPinned, IcoPlus } from '@components/icons';
import { ModuleNavTitle } from '@course/components/module-helpers';
import { useRouteParams, useRouter } from '@components/router';
import { completeCourseStep } from '@course/components/course-checklist';
import { ChecklistDialog } from '@course/components/course-checklist/checklist-dialog';
import { useLocalStorageState, useScrollRef, useTimezone } from 'client/lib/hooks';
import { useCurrentTenant } from '@components/router/session-context';
import { emptyLessonTitle } from 'shared/terminology';
import { LessonBadges } from '@course/components/lesson-badges';
import { LessonRow } from 'server/types';
import { on } from 'minidoc-editor';
import { HeadingPrimary } from '@course/components/headings';
import { Subtext } from '@components/async-form';
import { timezoneCity } from 'shared/dateutil';
import {
  SetState,
  dragReorder,
  editModule,
  getCurrentCourse,
  insertLesson,
  insertModule,
  persistDragOrder,
} from './state';

interface FocusedProps {
  state: ManageState;
  setState: SetState;
}

interface Props extends FocusedProps {
  isPinned: boolean;
  setIsPinned: Dispatch<StateUpdater<boolean>>;
  showOutline: boolean;
  setShowOutline: Dispatch<StateUpdater<boolean>>;
}

interface ModuleProps {
  id: UUID;
  isSelected: boolean;
  isExpanded: boolean;
  setIsExpanded: Dispatch<StateUpdater<boolean>>;
  smaller: boolean;
  onClick(): void;
  title: ComponentChildren;
  lessonCount: number;
  children: ComponentChildren;
}

interface LessonProps {
  courseId: UUID;
  isSelected: boolean;
  isPrerequisite: boolean;
  assessmentType: LessonRow['assessmentType'];
  hasDiscussion?: boolean;
  id: UUID;
  title: string;
  scrollRef?(el: Element | null): void;
}

function PageTitle(props: FocusedProps) {
  const { terminology } = useCurrentTenant();
  const { state } = props;
  const course = getCurrentCourse(state);
  const timezone = useTimezone();

  return (
    <HeadingPrimary
      title={
        <a
          href={`/manage/courses/${course.id}/lessons`}
          class="text-inherit flex items-center gap-1"
        >
          {terminology.Modules} &amp; {terminology.Lessons}
        </a>
      }
    >
      {course.accessFormat === 'scheduled' && course.isAbsoluteSchedule && (
        <Subtext>All dates displayed in {timezoneCity(timezone)} time.</Subtext>
      )}
    </HeadingPrimary>
  );
}

function CourseOutline(props: FocusedProps) {
  const { tour } = useRouteParams();
  const { terminology } = useCurrentTenant();
  const { state, setState } = props;
  const scrollRef = useScrollRef();
  const router = useRouter();
  const [isDraggingModule, setIsDraggingModule] = useState(false);
  const [collapsedModules, setCollapsedModules] = useLocalStorageState<string[]>(
    `collapsed.modules.${state.courseId}`,
    [],
  );

  // prevOrder is used to detect whether or not a drag operation actually changed the order
  // of modules, and if not, we won't do anything on completion.
  const prevOrder = useRef<undefined | UUID[]>(undefined);
  const course = getCurrentCourse(state);
  const createModule = () => {
    completeCourseStep(router, 'firstModule');
    insertModule(setState, { course, modules: state.modules });
  };
  const canManageCourse = state.accessLevel === 'guide' || state.accessLevel === 'facilitator';

  return (
    <>
      <div class="divide-y divide-dashed">
        <div class={`flex justify-end ${state.lessonId ? 'p-1' : 'p-2'}`}>
          <Button
            class={`inline-flex justify-end gap-1 items-center hover:bg-gray-50 rounded text-xs ${
              state.lessonId ? 'p-1' : 'p-2'
            }`}
            onClick={() =>
              collapsedModules.length
                ? setCollapsedModules([])
                : setCollapsedModules(course.modules)
            }
          >
            <span class="text-gray-500">{collapsedModules.length ? 'Expand' : 'Collapse'} all</span>
            {collapsedModules.length ? <IcoChevronRight /> : <IcoChevronDown />}
          </Button>
        </div>
        <DraggableProvider
          onDragComplete={(dragState) => {
            try {
              // If we were reordering modules, but the module order has not changed, we'll do nothing.
              // This prevents us from modifying module schedules unecessarily.
              if (
                isDraggingModule &&
                course.modules.every((id, i) => prevOrder.current && id === prevOrder.current[i])
              ) {
                return;
              }
              persistDragOrder(setState, state, dragState);
            } finally {
              if (isDraggingModule) {
                prevOrder.current = undefined;
                setIsDraggingModule(false);
                setTimeout(() => scrollToModule(dragState.target.id));
              }
            }
          }}
          onTargetChange={(dragState) => {
            if (!prevOrder.current) {
              prevOrder.current = course.modules;
            }
            setIsDraggingModule(dragState.dragging.table === 'modules');
            dragReorder(setState, dragState);
          }}
          canHandleDrop={(dragState, table) =>
            dragState.dragging.table === table || dragState.dragging.table === 'lessons'
          }
        >
          {!course.modules.length &&
            (state.adding ? <Spinner /> : <EmptyCourse createModule={createModule} />)}
          {course.modules.map((moduleId, index) => {
            const module = state.modules[moduleId];
            return (
              <ModuleNavItem
                key={moduleId}
                id={moduleId}
                isSelected={state.moduleId === moduleId}
                lessonCount={module.lessons.length}
                isExpanded={!collapsedModules.includes(moduleId)}
                smaller={!!state.lessonId}
                setIsExpanded={(isExpanded) => {
                  setCollapsedModules(
                    isExpanded
                      ? collapsedModules.filter((id) => id !== moduleId)
                      : [...collapsedModules, moduleId],
                  );
                }}
                onClick={() => editModule(setState, moduleId)}
                title={
                  <ModuleNavTitle
                    isAbsoluteSchedule={course.isAbsoluteSchedule}
                    module={module}
                    accessFormat={course.accessFormat}
                    canManageCourse={canManageCourse}
                  />
                }
              >
                {!isDraggingModule && (
                  <div class="flex flex-col gap-2">
                    {module.lessons.map((lessonId) => {
                      const lesson = state.lessons[lessonId];
                      return (
                        <LessonNavItem
                          key={lessonId}
                          courseId={state.courseId}
                          isSelected={state.lessonId === lessonId}
                          isPrerequisite={lesson.isPrerequisite}
                          hasDiscussion={lesson.discussion?.isEnabled}
                          assessmentType={lesson.assessmentType}
                          id={lessonId}
                          title={lesson.title}
                          scrollRef={state.lessonId === lessonId ? scrollRef : undefined}
                        />
                      );
                    })}
                    <div class="relative">
                      <Button
                        type="button"
                        class="flex items-center gap-2 text-indigo-600 p-2 cursor-pointer"
                        onClick={() => {
                          completeCourseStep(router, 'firstLesson');
                          insertLesson(router, setState, module);
                        }}
                      >
                        <IcoPlus />
                        <span>Add {terminology.Lesson}</span>
                      </Button>
                      {index === 0 && tour === 'firstLesson' && (
                        <ChecklistDialog text="Click this button to add a lesson." />
                      )}
                    </div>
                  </div>
                )}
              </ModuleNavItem>
            );
          })}
        </DraggableProvider>
      </div>
    </>
  );
}

export function ModulesPage(props: FocusedProps) {
  const { tour } = useRouteParams();
  const { terminology } = useCurrentTenant();
  const { state, setState } = props;
  const course = getCurrentCourse(state);
  const router = useRouter();
  const createModule = () => {
    completeCourseStep(router, 'firstModule');
    insertModule(setState, { course, modules: state.modules });
  };

  return (
    <div class="flex flex-col w-full max-w-4xl mx-auto p-4 md:p-10 gap-6">
      <header class="flex items-center justify-between">
        <PageTitle {...props} />
        <div class="flex relative gap-4">
          <BtnPrimary class="px-4" onClick={createModule}>
            Add {terminology.Module}
          </BtnPrimary>
          {tour === 'firstModule' && (
            <ChecklistDialog
              text="Click this button to create a module."
              position="-left-72"
              arrow="right"
            />
          )}
        </div>
      </header>
      <CourseOutline {...props} />
    </div>
  );
}

export function ModulesNav(props: Props) {
  const { terminology } = useCurrentTenant();
  const { state, setState } = props;
  const router = useRouter();
  // prevOrder is used to detect whether or not a drag operation actually changed the order
  // of modules, and if not, we won't do anything on completion.
  const course = getCurrentCourse(state);
  const createModule = () => {
    completeCourseStep(router, 'firstModule');
    insertModule(setState, { course, modules: state.modules });
  };

  useEffect(() => {
    if (props.showOutline) {
      const off = [
        on(document, 'keydown', (e) => e.code === 'Escape' && props.setShowOutline(false)),
        on(document, 'mousedown', () => props.setShowOutline(false)),
      ];
      return () => off.forEach((f) => f());
    }
  }, [props.showOutline]);

  const isPinned = props.isPinned;

  return (
    <nav
      class={`w-80 border-r p-2 bg-white max-h-screen flex flex-col absolute z-50 sm:z-40 inset-y-0 left-0 shadow-lg ${
        props.showOutline ? 'flex' : 'hidden lg:flex'
      }
      ${
        props.showOutline
          ? 'animate-slide-in-sidenav'
          : isPinned
          ? 'max-lg:animate-slide-out-sidenav'
          : 'animate-slide-out-sidenav'
      } ${isPinned ? 'lg:sticky lg:shadow-none' : ''}`}
      onMouseDown={(e) => e.stopPropagation()}
      onClick={() => props.setShowOutline(false)}
    >
      <header class="relative flex flex-col gap-2 p-4 pb-2 bg-white z-10">
        <PageTitle {...props} />

        <Button
          class="hidden p-2 rounded-full hover:bg-gray-100 lg:inline-flex absolute top-4 right-4"
          onClick={() => props.setIsPinned((x) => !x)}
        >
          <IcoPinned class={`w-4 h-4 opacity-75 ${props.isPinned ? '' : 'rotate-45'}`} />
        </Button>
        <Button
          class="flex w-full justify-center items-center gap-2 font-semibold hover:bg-indigo-500 bg-indigo-600 text-white rounded-md p-2 px-3"
          onClick={createModule}
        >
          <span>Add {terminology.Module}</span>
        </Button>
      </header>

      <div class="grow relative">
        <div class="absolute inset-0 overflow-y-auto mini-scroll p-2 pt-4 pb-40">
          <CourseOutline {...props} />
        </div>
      </div>
    </nav>
  );
}

function ModuleNavItem(props: ModuleProps) {
  const { terminology } = useCurrentTenant();
  const { smaller, isExpanded, setIsExpanded } = props;

  return (
    <Draggable id={props.id} table="modules" draggable>
      <section class="flex flex-col gap-2 py-2">
        <header>
          <div class="flex justify-between items-center p-2 gap-4">
            <Button
              class="font-semibold text-indigo-600 cursor-pointer relative group"
              onClick={props.onClick}
            >
              <span class="hidden group-hover:block absolute -left-10 top-0 p-3 py-2 cursor-move text-gray-800">
                <IcoGripper />
              </span>
              {props.title}
            </Button>
            <Button
              class={`inline-flex gap-1 items-center hover:bg-gray-50 rounded ${
                smaller ? 'p-1' : 'p-2'
              }`}
              onClick={(e: Event) => {
                e.stopPropagation();
                setIsExpanded(!isExpanded);
              }}
            >
              {!isExpanded && props.lessonCount > 0 && (
                <span class="text-gray-500 text-xs">
                  {smaller
                    ? props.lessonCount
                    : `Show ${props.lessonCount} ${
                        props.lessonCount === 1 ? terminology.lesson : terminology.lessons
                      }`}
                </span>
              )}
              {!isExpanded && <IcoChevronRight />}
              {isExpanded && <IcoChevronDown />}
            </Button>
          </div>
        </header>
        {isExpanded && props.children}
      </section>
    </Draggable>
  );
}

function LessonNavItem(props: LessonProps) {
  const tenant = useCurrentTenant();
  return (
    <Draggable id={props.id} table="lessons">
      <a
        href={`/manage/courses/${props.courseId}/lessons/${props.id}`}
        ref={props.scrollRef}
        class={`${
          props.isSelected ? 'bg-gray-100' : 'hover:bg-gray-50'
        } cursor-pointer p-2 rounded-md relative group text-inherit flex justify-between`}
      >
        <span class="hidden group-hover:block absolute -left-8 top-0 p-3 cursor-move">
          <IcoGripper />
        </span>
        {props.title || emptyLessonTitle(tenant)}

        <LessonBadges lesson={props} hasDiscussion={props.hasDiscussion} />
      </a>
    </Draggable>
  );
}

function EmptyCourse({ createModule }: { createModule(): void }) {
  return (
    <div class="text-center my-4">
      <p class="text-zinc-700">This course is empty.</p>
      <Button class="btn-primary mt-4" onClick={() => createModule()}>
        Add a module
      </Button>
    </div>
  );
}
