/**
 * A spinning loader.
 */

export function Spinner({ class: className }: { class?: string }) {
  return (
    <span
      class={`spinner-border an-spin border-2 w-4 h-4 rounded-full inline-block ${className || ''}`}
    ></span>
  );
}

export function DefaultSpinner() {
  return (
    <div class="text-center">
      <Spinner class="border-indigo-600 inline-block" />
    </div>
  );
}
