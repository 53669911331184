type HighlightResult = {
  prefix: string;
  highlight?: string;
  suffix?: string;
};

export function highlightTerms(text: string, searchTerm: string): HighlightResult {
  if (!searchTerm) {
    return { prefix: text };
  }

  const [prefix] = text.toLowerCase().split(searchTerm, 1);

  return {
    prefix: text.substring(0, prefix.length),
    highlight: text.substring(prefix.length, prefix.length + searchTerm.length),
    suffix: text.substring(prefix.length + searchTerm.length),
  };
}
