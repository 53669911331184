/**
 * The modal for adding or modifying a course on the storefront page.
 */

import { Button } from '@components/buttons';
import { IcoArrowLeft, IcoChevronRight } from '@components/icons';
import { SearchBox } from '@components/search-box';
import { DefaultSpinner } from '@components/spinner';
import { useTryAsyncData } from 'client/lib/hooks';
import { rpx } from 'client/lib/rpx-client';
import { useImageUrl } from 'client/utils/cdn';
import { ComponentChildren } from 'preact';
import { Dispatch, StateUpdater, useState } from 'preact/hooks';
import { PriceSummary } from '../pmts/components/price-summary';
import { useIntl } from 'shared/intl/use-intl';
import * as fmt from 'shared/payments/fmt';
import { StandardDialog } from '@components/dialog';

type Pane = 'course' | 'price' | 'coupon';
type Course = Awaited<ReturnType<typeof rpx.storefront.getCourses>>[0];
type Price = Awaited<ReturnType<typeof rpx.storefront.getPrices>>[0];
type Coupon = Awaited<ReturnType<typeof rpx.storefront.getCoupons>>[0];

function WizardButton({
  onClick,
  text,
  subtext,
}: {
  text: ComponentChildren;
  subtext: ComponentChildren;
  onClick(): void;
}) {
  return (
    <Button
      class="border rounded-md flex justify-between items-center p-2 pl-4 gap-2 text-left hover:bg-gray-50 overflow-hidden"
      onClick={onClick}
    >
      <span class="flex flex-col">
        <span class="font-medium">{text}</span>
        <span>{subtext}</span>
      </span>
      <span>
        <IcoChevronRight />
      </span>
    </Button>
  );
}

function CourseImage(props: { imagePath?: string }) {
  const src = useImageUrl(props.imagePath);

  return (
    <span class="flex">
      <span class="grow bg-gray-100 overflow-hidden w-16 h-16 rounded-md inline-flex items-center justify-center">
        {src ? <img src={src} /> : ''}
      </span>
    </span>
  );
}

function CoursePicker({
  searchTerm,
  setSearchTerm,
  onPick,
}: {
  searchTerm: string;
  setSearchTerm: Dispatch<StateUpdater<string>>;
  onPick(course: Course): void;
}) {
  const { isLoading, data } = useTryAsyncData(() => {
    return rpx.storefront.getCourses({ searchTerm });
  }, [searchTerm]);

  return (
    <div class="flex flex-col gap-4">
      <SearchBox placeholder="Find a course" value={searchTerm} onTermChange={setSearchTerm} />
      {isLoading && <DefaultSpinner />}
      {!isLoading && !data?.length && (
        <p class="text-center p-4">
          No courses found. Only courses that are open and have sales pages will show up here.
        </p>
      )}
      <section class="flex flex-col gap-2">
        {data?.map((c) => (
          <Button
            key={c.id}
            class="border rounded-md flex justify-between items-center p-2 text-left hover:bg-gray-50 overflow-hidden"
            onClick={() => onPick(c)}
          >
            <span class="flex items-center gap-5">
              <CourseImage imagePath={c.imagePath} />
              <span class="font-medium">{c.title}</span>
            </span>
            <span>
              <IcoChevronRight />
            </span>
          </Button>
        ))}
      </section>
    </div>
  );
}

function PricePicker({ course, onPick }: { course: Course; onPick(price?: Price): void }) {
  const { isLoading, data } = useTryAsyncData(() => {
    return rpx.storefront.getPrices({ courseId: course.id });
  }, []);

  return (
    <div class="flex flex-col gap-2">
      <WizardButton
        onClick={() => onPick()}
        text="No Price"
        subtext={
          <span class="opacity-75">
            Link to the {course.isBundle ? 'bundle' : 'course'} sales page
          </span>
        }
      />
      {isLoading && <DefaultSpinner />}
      {!isLoading && !data?.length && <p class="text-center p-4">No prices found.</p>}
      {data?.map((p) => (
        <WizardButton
          key={p.id}
          onClick={() => onPick(p)}
          text={p.name}
          subtext={<PriceSummary price={p} />}
        />
      ))}
    </div>
  );
}

function CouponPicker({
  course,
  price,
  onPick,
}: {
  course: Course;
  price: Price;
  onPick(coupon?: Coupon): void;
}) {
  const intl = useIntl();
  const { isLoading, data } = useTryAsyncData(() => {
    return rpx.storefront.getCoupons({ courseId: course.id, priceId: price.id });
  }, []);

  return (
    <div class="flex flex-col gap-2">
      <WizardButton
        onClick={() => onPick()}
        text="No Coupon"
        subtext={<span class="opacity-75">List at full price</span>}
      />
      {isLoading && <DefaultSpinner />}
      {data?.map((coupon) => (
        <WizardButton
          key={coupon.id}
          onClick={() => onPick(coupon)}
          text={coupon.code}
          subtext={
            <>
              <span class="opacity-75 mr-2">
                {fmt.discountPrice({ price, coupon })} {fmt.discountSuffix({ price, coupon, intl })}
              </span>
              (<PriceSummary price={price} coupon={coupon} multiline={false} />)
            </>
          }
        />
      ))}
    </div>
  );
}

export function CourseModal(props: {
  course?: Course;
  onCancel(): void;
  onPick(opts: { course: Course; price?: Price; coupon?: Coupon }): void;
}) {
  const [pane, setPane] = useState<Pane>(props.course ? 'price' : 'course');
  const [searchTerm, setSearchTerm] = useState('');
  const [course, setCourse] = useState(props.course);
  const [price, setPrice] = useState<Price | undefined>();

  return (
    <StandardDialog
      onClose={props.onCancel}
      title={<>Choose a {pane}</>}
      subtitle={
        pane === 'coupon' ||
        (pane === 'price' && !props.course && (
          <Button
            class="flex gap-1 items-center text-sm -ml-1"
            onClick={() => setPane(pane === 'price' ? 'course' : 'price')}
          >
            <IcoArrowLeft />
            <span>Back to {pane === 'price' ? 'courses' : 'prices'}</span>
          </Button>
        ))
      }
    >
      {pane === 'course' && (
        <CoursePicker
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          onPick={(c) => {
            setCourse(c);
            setPane('price');
          }}
        />
      )}

      {pane === 'price' && course && (
        <PricePicker
          course={course}
          onPick={(p) => {
            if (!p?.priceInCents) {
              props.onPick({ course, price: p });
              return;
            }
            setPrice(p);
            setPane('coupon');
          }}
        />
      )}

      {pane === 'coupon' && course && price && (
        <CouponPicker
          course={course}
          price={price}
          onPick={(coupon) => {
            props.onPick({ course, price, coupon });
          }}
        />
      )}
    </StandardDialog>
  );
}
