import { Button } from '@components/buttons';
import { IcoCheck, IcoCheckCircle, IcoInfo, IcoExternalLink, IcoX } from '@components/icons';
import { ComponentChildren } from 'preact';
import { useState } from 'preact/hooks';
import { State, Lesson } from '@course/components/module-helpers';
import { getCurrentCourse, getSiblingLessons } from './state';
import { showError } from '@components/app-error';
import { URLS } from 'shared/urls';
import { showToast } from '@components/toaster';
import { useIntl } from 'shared/intl/use-intl';
import { Case } from '@components/conditional';
import { useCurrentTenant, useCurrentUser } from '@components/router/session-context';
import { useLocalStorageState } from 'client/lib/hooks';
import { useRouter } from '@components/router';

interface Props {
  state: State;
  lesson: Lesson;
  nextDisabled: boolean;
  canManageCourse: boolean;
  setLessonComplete(lessonId: UUID, complete: boolean): Promise<unknown>;
}

function FooterLink({
  label,
  text,
  icon,
  type,
  isLoading,
  href,
  target,
  onClick,
  class: className,
}: {
  label: string;
  text: string;
  href?: string;
  isLoading?: boolean;
  icon?: ComponentChildren;
  target?: string;
  type: 'next' | 'prev';
  class?: string;
  onClick?: () => void;
}) {
  return (
    <Button
      class={`flex flex-col gap-1 justify-center p-4 rounded-md border ${
        type === 'next' ? 'items-end' : ''
      } ${
        className || 'hover:bg-gray-50 dark:hover:bg-gray-700/50 text-inherit dark:border-gray-700'
      }`}
      isLoading={isLoading}
      href={href}
      target={target}
      onClick={onClick}
    >
      <span class="block text-sm opacity-75">{label}</span>
      <strong class="grow flex gap-2 items-center">
        {type === 'prev' && <span>{icon}</span>}
        <span class="line-clamp-2">{text}</span>
        {type === 'next' && <span>{icon || '⤑'}</span>}
      </strong>
    </Button>
  );
}

function CompleteLessonButton({
  state,
  lesson,
  nextLesson,
  setLessonComplete,
}: Props & {
  nextLesson?: Lesson;
}) {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const course = getCurrentCourse(state);
  const currentUser = useCurrentUser()!;
  const router = useRouter();
  const nextLessonUrl =
    course && nextLesson
      ? URLS.student.lesson({
          course,
          lesson: nextLesson,
        })
      : undefined;

  async function markAsCompleted() {
    setIsLoading(true);
    try {
      // This odd ordering is to prevent a flicker when marking
      // as complete (e.g. the blue button becomes the split
      // mark incomplete, and green next button for a split second).
      // I think it's fine to navigiate first, since the odds
      // of an error are slim, and we'll show the error regardless.
      await setLessonComplete(lesson.id, true);
      if (nextLessonUrl) {
        router.goto(nextLessonUrl);
      } else if (course.hasEnabledCert) {
        window.open(URLS.student.certificate({ course, userId: currentUser.id }), '_blank');
      }
    } catch (err) {
      setIsLoading(false);

      if (err.data?.commentFirst) {
        showToast({
          type: 'warn',
          title: intl('Comment is required'),
          message: err.message,
        });
        document.querySelector('.js-root-comment-form')?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        });
      } else {
        showError(err);
      }
    }
  }

  return (
    <FooterLink
      isLoading={isLoading}
      class="bg-theme-primary text-white dark:text-indigo-100 bg-indigo-500 hover:bg-indigo-600 dark:bg-indigo-600 dark:hover:text-white dark:hover:bg-indigo-500 border-transparent"
      onClick={markAsCompleted}
      label={nextLesson || course.hasEnabledCert ? intl('Mark as complete and continue to') : ''}
      type="next"
      text={
        nextLesson
          ? nextLesson.title
          : course.hasEnabledCert
          ? intl('Certificate of Completion')
          : intl('Mark as complete.')
      }
      icon={nextLesson ? null : course.hasEnabledCert ? <IcoExternalLink /> : <IcoCheck />}
    />
  );
}

export function NextLessonButton(props: Props & { siblings: { next?: Lesson } }) {
  const { lesson, canManageCourse, state, siblings } = props;
  const intl = useIntl();
  const isComplete = lesson && state.completedLessons.includes(lesson.id);
  const isPreviewMembership = state.accessLevel === 'preview' || state.accessLevel === 'limited';
  const currentUser = useCurrentUser()!;

  if (!isPreviewMembership && !canManageCourse && !isComplete) {
    return <CompleteLessonButton {...props} nextLesson={siblings.next} />;
  }

  if (siblings.next) {
    const nextLessonUrl = URLS.student.lesson({
      course: getCurrentCourse(props.state),
      lesson: siblings.next,
    });

    return (
      <FooterLink
        href={nextLessonUrl}
        type="next"
        text={siblings.next.title}
        label={intl(`Next lesson`)}
      />
    );
  }

  if (props.state.course.hasEnabledCert) {
    return (
      <FooterLink
        href={URLS.student.certificate({ course: props.state.course, userId: currentUser.id })}
        target="_blank"
        label={intl('Continue to')}
        text={intl('Certificate of Completion')}
        icon={<IcoExternalLink />}
        type="next"
      />
    );
  }

  return null;
}

function PrevLessonButton({
  siblings,
  ...props
}: Props & {
  siblings: { prev?: Lesson };
}) {
  const intl = useIntl();
  if (!siblings.prev) {
    return <span></span>;
  }

  const prevLessonUrl = URLS.student.lesson({
    course: getCurrentCourse(props.state),
    lesson: siblings.prev,
  });

  return (
    <FooterLink
      href={prevLessonUrl}
      label={intl('Previous')}
      text={siblings.prev.title}
      icon="⬸"
      type="prev"
    />
  );
}

function UncompleteButton(props: Props) {
  const intl = useIntl();
  const { lesson, setLessonComplete } = props;

  if (props.canManageCourse) {
    return null;
  }

  return (
    <div class="flex flex-wrap leading-8 items-center dark:text-gray-200 space-x-2 px-4 py-2 border dark:border-gray-700 rounded-md mb-6">
      <IcoCheckCircle class="w-6 h-6 opacity-75 text-green-400" />
      <strong>{intl('You completed this lesson.')}</strong>
      <Case when={lesson.assessmentType !== 'assignment' || !lesson.requireAssignmentApproval}>
        <span class="text-gray-500 dark:text-gray-400">{intl('Not finished?')}</span>
        <Button
          class="underline text-gray-500 dark:text-gray-400 dark:hover:text-white"
          onClick={() => setLessonComplete(lesson.id, false)}
        >
          {intl('Mark as incomplete.')}
        </Button>
      </Case>
    </div>
  );
}

function ViewingAsGuideBanner(props: Props) {
  const { terminology } = useCurrentTenant();
  const [isDismissed, setIsDismissed] = useLocalStorageState(
    `dismiss_complete_button_info_${props.state.courseId}`,
    false,
  );

  if (isDismissed) {
    return null;
  }

  const module = props.state.modules[props.lesson.moduleId];

  return (
    <div class="flex justify-between dark:text-gray-200 space-x-2 px-4 py-3 border border-yellow-200 rounded-md mb-6 relative bg-yellow-50 dark:bg-yellow-900 dark:border-yellow-700">
      <div class="flex items-center gap-4">
        <IcoInfo class="size-6 text-yellow-600 dark:text-yellow-200" />
        <span>
          You're viewing this {module?.isDraft && <em>draft content</em>} as a {terminology.guide}.{' '}
          {module.isDraft ? (
            <span>Students will not see any lessons in this module until it is published.</span>
          ) : (
            <span>
              Students will see the <strong>Mark as complete</strong> button.
            </span>
          )}
        </span>
      </div>
      <Button
        class="p-1 text-gray-500 dark:text-gray-300 hover:opacity-75"
        onClick={() => setIsDismissed(true)}
      >
        <IcoX />
      </Button>
    </div>
  );
}

export function LessonFooterNav(props: Props) {
  const { lesson, state } = props;
  const siblings = getSiblingLessons(state);
  const isComplete = lesson && state.completedLessons.includes(lesson.id);
  const isPreviewMembership = state.accessLevel === 'preview' || state.accessLevel === 'limited';

  return (
    <footer class="py-8 dark:text-gray-300">
      {props.canManageCourse && !props.nextDisabled && <ViewingAsGuideBanner {...props} />}
      {isComplete && !isPreviewMembership && <UncompleteButton {...props} />}
      <div class="grid gap-4 sm:grid-cols-2">
        <PrevLessonButton {...props} siblings={siblings} />
        {!props.nextDisabled && <NextLessonButton {...props} siblings={siblings} />}
      </div>
    </footer>
  );
}
