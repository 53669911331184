import { Internationalize } from '../intl';

export function getCourseNoSeatsAvailableMessage(intl: Internationalize, guideEmail: string) {
  return intl(
    '<p>This course is no longer available because it is full. Please contact <a href="mailto:{guideEmail:string}">{guideEmail:string}</a> with any questions.</p>',
    {
      guideEmail,
    },
  );
}
