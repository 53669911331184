import { Tab, Tabs } from '@components/tabs';

interface Props {
  selectedTab: string;
  tabs: Array<{ tab: string; text: string; href: string } | undefined>;
}

export function PmtTabs(props: Props) {
  return (
    <Tabs>
      {props.tabs.map((t) => {
        if (t === undefined) {
          return null;
        }

        return (
          <Tab key={t.tab} href={t.href} isSelected={props.selectedTab === t.tab}>
            {t.text}
          </Tab>
        );
      })}
    </Tabs>
  );
}
