import { Button } from '@components/buttons';
import { IcoArrowCircleLeft } from '@components/icons';
import { useRouteParams } from '@components/router';
import { useCurrentUser } from '@components/router/session-context';
import { CourseWithGuide } from 'server/types';

interface Props {
  course: Pick<CourseWithGuide, 'level'>;
  url?: string;
  displayClass?: string;
  class?: string;
}

/**
 * Display the manage course button if the current user can manage the course.
 */
export function BtnManageCourse({
  course,
  url,
  displayClass = 'inline-flex',
  class: className = '',
}: Props) {
  const { courseId } = useRouteParams();
  const currentUser = useCurrentUser();

  if (!currentUser || (course.level !== 'guide' && course.level !== 'facilitator')) {
    return null;
  }

  return (
    <Button
      class={`btn-primary items-center bg-green-600 text-white p-2 px-4 rounded-lg leading-none hover:opacity-90 dark:text-white hide-on-preview ${displayClass} ${className}`}
      href={url || `/manage/courses/${courseId}`}
    >
      <IcoArrowCircleLeft class="w-5 h-5 mr-2" />
      Return to Editing
    </Button>
  );
}
