import type { Dayjs } from 'dayjs';
import type { View } from './types';

export function dateForView(view: View, date: Dayjs) {
  switch (view) {
    case 'agenda':
    case 'day':
      return date.startOf('day');
    case 'week':
      return date.startOf('week');
    case 'month':
      return date.startOf('month');
  }
}
