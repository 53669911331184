import { TenantRow } from 'server/types';
import { pluralize } from 'shared/formatting';

/**
 * Returns a numerative descriptor for the submitted students; for example: 'John, Anna, and 12 others'
 */
export function summarizeStudentNames(names: string[]) {
  // We need to uniquify the names; because a single user might submit multiple responses.
  const uniqueNames = [...new Set(names)];
  const [first, second, ...restOfNames] = uniqueNames;
  if (uniqueNames.length === 1) {
    return first;
  }
  if (uniqueNames.length === 2) {
    return `${first} and ${second}`;
  }
  return `${first}, ${second} and ${restOfNames.length} ${pluralize('other', restOfNames.length)}`;
}

export function getTenantEmailSender(tenant: Pick<TenantRow, 'domain'>) {
  return `notifications@${tenant.domain}`;
}
