import { useState } from 'preact/hooks';
import { AuditLogWithUser } from 'server/rpx/endpoints/audit-logs';
import { fmtFullDate } from 'shared/dateutil';
import { Button } from '@components/buttons';
import { useRouteParams } from '@components/router';
import { rpx } from 'client/lib/rpx-client';
import { SlideOver } from '@components/slide-over';
import { LoadingIndicator } from '@components/loading-indicator';
import { Tab, Tabs } from '@components/tabs';
import { useAsyncData } from 'client/lib/hooks';

/**
 * Displays a slideover view for user audit log events
 */
export function UserAuditLogsSlider({ closeSlider }: { closeSlider: () => void }) {
  const { userId } = useRouteParams();
  const { data, isLoading } = useAsyncData(
    () => rpx.auditLogs.getUserAuditLogEvents({ userId }),
    [userId],
  );

  return (
    <SlideOver
      close={() => {
        closeSlider();
      }}
    >
      <div class="py-12 px-8">
        <h2 class="text-2xl mb-4">User logs</h2>
        {isLoading && (
          <div>
            Loading data
            <LoadingIndicator />
          </div>
        )}
        {data && (
          <div class="border-t-2 border-gray-200 py-12">
            <UserAuditLogsTable items={data} />
          </div>
        )}
      </div>
    </SlideOver>
  );
}

/**
 * Displays a semi-paginated table of audit-log events for a user
 */
function UserAuditLogsTable({ items }: { items: AuditLogWithUser[] }) {
  const [showingAll, setShowingAll] = useState(false);
  const [currentTab, setCurrentTab] = useState<'userActions' | 'accountChanges' | undefined>(
    undefined,
  );
  if (!items.length) {
    return <span class="my-4 text-gray-400">No log events for this user</span>;
  }
  const maxRows = 5;
  // Return a slice of rows, truncated if we're not expanded.
  const sliceRows = (rows: AuditLogWithUser[], max = 5) => {
    return showingAll ? rows : rows.slice(0, max);
  };

  // Filter log events bv either actions taken by the user, or actions taken
  // by guides/admins affecting their account.
  const filterByType = (logType?: 'userActions' | 'accountChanges') => {
    if (logType === 'userActions') {
      return items.filter((x) => x.byUserId === x.toUserId);
    }
    if (logType === 'accountChanges') {
      return items.filter((x) => x.byUserId !== x.toUserId);
    }
    return items;
  };

  const filtered = filterByType(currentTab);
  return (
    <div>
      <Tabs>
        <Tab isSelected={!currentTab} onClick={() => setCurrentTab(undefined)}>
          All
        </Tab>
        <Tab isSelected={currentTab === 'userActions'} onClick={() => setCurrentTab('userActions')}>
          User actions
        </Tab>
        <Tab
          isSelected={currentTab === 'accountChanges'}
          onClick={() => setCurrentTab('accountChanges')}
        >
          Account changes
        </Tab>
      </Tabs>
      {filtered.length ? (
        <div class="table table-auto bg-white rounded-sm border w-full divide-y mt-6">
          <div class="table-row bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            <div class="table-cell w-30 pl-4 pr-2 py-2">Date</div>
            <div class="table-cell pl-4 pr-2 py-2">Action</div>
            <div class="table-cell pl-4 pr-2 py-2">Details</div>
          </div>
          {sliceRows(filtered, maxRows).map((r) => (
            <UserRow key={r.id} row={r} />
          ))}
        </div>
      ) : (
        <span class="block w-full px-1 py-8">No events</span>
      )}
      {filtered.length > maxRows && (
        <div class="w-full flex justify-center">
          <span className="flex-1">
            <Button
              class="rounded-lg w-full py-3 hover:bg-gray-100 dark:hover:bg-black/25 dark:hover:bg-opacity-25 hover:text-indigo-800 text-indigo-600 dark:text-sky-500 font-medium text-left mt-2 px-4"
              onClick={() => setShowingAll(!showingAll)}
            >
              {showingAll ? 'Show less' : `Show ${filtered.length - maxRows} more items`}
            </Button>
          </span>
        </div>
      )}
    </div>
  );
}

// A link to a user's admin detail view
const userDetail = (email?: string, id?: UUID) => {
  return email && id ? <a href={`/admin/people/${id}`}>{email}</a> : '';
};

// This wraps the messaged details with human-formatted details.
// Example:
// "enrollment was canceled by guide user (some@guide.com)"
const UserRow = ({ row }: { row: AuditLogWithUser }) => {
  const isSameUser = row.byUserId === row.toUserId; // Is the acting user for this action the same as the affected user?
  const action = row.type.replaceAll('_', ' '); // ie: 'enrollment disabled by guide'

  return (
    <span class="table-row text-inherit hover:bg-indigo-50 text-sm">
      <span class="table-cell p-4 border-t align-top">{fmtFullDate(row.createdAt)}</span>
      <span class="table-cell p-4 border-t align-top">{action}</span>
      <span class="table-cell p-4 border-t align-top">
        {row.message}{' '}
        {!isSameUser && (
          <span>
            for user {userDetail(row.toUserEmail, row.toUserId)}{' '}
            {(row.isGuide && 'by guide') || (row.isAdmin && 'by admin')}{' '}
            {userDetail(row.byUserEmail, row.byUserId)}{' '}
          </span>
        )}
        {row.courseId && (
          <span>
            for course{' '}
            <a href={`/admin/courses/${row.courseId}`}>
              {row.courseTitle ? truncateTitle(row.courseTitle) : truncateUUID(row.courseId)}
            </a>
          </span>
        )}
      </span>
    </span>
  );
};

const truncateTitle = (title: string) =>
  `"${title.length > 40 ? title.slice(0, 40) + '...' : title}"`;

const truncateUUID = (uuid: UUID) => `${uuid.toString().slice(0, 7)}...`;
