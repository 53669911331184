import { EmptyPage } from '@components/empty-page';
import { IcoMail } from '@components/icons';
import { courseLabel } from 'shared/terminology';
import { FullCourse } from 'server/types';
import { useCurrentTenant } from '@components/router/session-context';

export function InvitationsGraphic() {
  return (
    <div class="flex items-center -rotate-12">
      <span class="flex flex-col items-end mr-3 absolute right-full">
        <span class="bg-indigo-200 inline-block rounded-lg ml-3 h-2 w-16"></span>
        <span class="bg-gray-200 inline-block rounded-lg ml-3 h-2 w-28 mt-2"></span>
      </span>
      <span class="w-52 h-52 flex items-center justify-center text-indigo-300">
        <IcoMail class="w-32 h-32" />
      </span>
    </div>
  );
}

export function EmptyScreen({
  course,
  onInvite,
}: {
  course: Pick<FullCourse, 'isBundle'>;
  onInvite(): void;
}) {
  const tenant = useCurrentTenant();

  return (
    <EmptyPage
      Ico={InvitationsGraphic}
      actionText="Invite students"
      description={`Invite students to skip the payment form and join your ${courseLabel({
        course,
        tenant,
      })} for free.`}
      title="Invitations"
      onClick={onInvite}
    />
  );
}
