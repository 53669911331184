import { couponStore, Coupon, Price } from './state';
import { NewCouponForm } from '../components/new-coupon-form';
import { ModalFormContext, showModalForm } from '@components/modal-form';
import { useContext } from 'preact/hooks';
import { indexBy } from 'shared/utils';
import { rpx } from 'client/lib/rpx-client';
import { StandardDialog } from '@components/dialog';

const store = rpx.superadminPricing;

export function showNewCouponModal(props: {
  folderId?: string;
  productId: string;
  applicablePrices: Price[];
  onCreate(coupon: Coupon): void;
}) {
  return showModalForm(() => {
    const { resolve } = useContext(ModalFormContext);
    const hide = () => resolve(undefined);

    return (
      <StandardDialog onClose={hide}>
        <NewCouponForm
          applicablePrices={props.applicablePrices}
          onCancel={hide}
          productId={props.productId}
          createCoupon={async (opts) => {
            const { id } = await couponStore.createCoupon({
              ...(opts as any),
              productId: props.productId,
              isCoreCoupon: true,
            });

            if (props.folderId) {
              await store.addFolderItems({
                id: props.folderId,
                items: [id],
              });
            }

            const prices = indexBy((x) => x.id, props.applicablePrices);

            props.onCreate({
              ...opts,
              id,
              numSignups: 0,
              isEnabled: true,
              productId: props.productId,
              createdAt: new Date(),
              updatedAt: new Date(),
              type: 'coupon',
              applyTo: opts.applyTo?.map((id) => prices[id]).filter((x) => !!x),
            });
            hide();
          }}
        />
      </StandardDialog>
    );
  });
}
