import { render } from 'preact-render-to-string';
import {
  BasicEmailWrapper,
  Heading,
  Italic,
  Line,
  Mailto,
  MarketingFooter,
  PrimaryAction,
  SupportFooter,
} from './shared';
import { Currency } from 'server/types';
import dayjs from 'dayjs';
import * as fmt from 'shared/payments/fmt';

export interface Payment {
  currency: Currency;
  amount: number;
  date: Date | string;
}

export interface PaymentReceiptProps extends PaymentEmailParams {
  payment: Payment;
  isAccessDisabled: boolean;
}

export interface PaymentEmailParams {
  title: string;
  imageUrl?: string;
  imageOnly?: boolean;
  description?: string;
  manageUrl: string;
  timezone: string;
  from: string;
  fromName?: string;
  to: string;
}

function paymentDate(date: Payment['date'], timezone: string) {
  return dayjs(date).tz(timezone).format('MMMM D, YYYY');
}

/**
 * Notification email for payment plan cancellation
 */
export function PaymentPlanCanceled(opts: PaymentReceiptProps) {
  return render(
    <BasicEmailWrapper footer={<SupportFooter />}>
      <PaymentBannerImage {...opts} />
      <Heading>Your payment plan has been canceled.</Heading>
      {opts.isAccessDisabled && (
        <Line>
          Your membership is now disabled for <Italic>{opts.title}</Italic>.
        </Line>
      )}
      {!opts.isAccessDisabled && (
        <Line>
          Your membership is still active for <Italic>{opts.title}</Italic>.
        </Line>
      )}
      <Line>
        If you have further questions, please email {opts.fromName} at <Mailto email={opts.from} />
      </Line>
    </BasicEmailWrapper>,
  );
}

/**
 * Payment receipt email
 */
export function PaymentReceipt(opts: PaymentReceiptProps) {
  return render(
    <BasicEmailWrapper footer={<SupportFooter />}>
      <PaymentBannerImage {...opts} />

      <Heading>Thank you!</Heading>
      <Line>
        Your payment for <em>{opts.title}</em> has been processed.
      </Line>
      <PaymentTable opts={opts} totalLabel="Payment Total" />
    </BasicEmailWrapper>,
  );
}

/**
 * Payment refund receipt email
 */
export function PaymentRefundReceipt(opts: PaymentReceiptProps) {
  return render(
    <BasicEmailWrapper footer={<SupportFooter />}>
      <PaymentBannerImage {...opts} />

      <Heading>Refund processed</Heading>
      <Line>
        Your refund for <em>{opts.title}</em> has been processed.
      </Line>
      <PaymentTable opts={opts} totalLabel="Refund Total" />
    </BasicEmailWrapper>,
  );
}

/**
 * User notification for payment failure
 */
export function PaymentFailure(opts: PaymentReceiptProps) {
  return render(
    <BasicEmailWrapper footer={<SupportFooter />}>
      <PaymentBannerImage {...opts} />
      <Heading>Your payment failed</Heading>
      <Line>
        You may need to update your payment method for <em>{opts.title}</em>.
      </Line>
      <PaymentTable opts={opts} totalLabel="Attempted Amount" highlight="#B91C1C" />
      <PrimaryAction href={opts.manageUrl}>Manage Your Payments</PrimaryAction>
      <Line>
        If you have further questions, please email {opts.fromName} at <Mailto email={opts.from} />
      </Line>
    </BasicEmailWrapper>,
  );
}

/**
 * Table view of payments for payment emails
 */
function PaymentTable({
  opts,
  totalLabel,
  highlight,
}: {
  opts: PaymentReceiptProps;
  totalLabel: string;
  highlight?: string;
}) {
  return (
    <table cellPadding="0" cellSpacing="0" style="margin: 2em 0;">
      <tr>
        <td>Date</td>
        <td style="padding-left: 3em; color: #555;">
          <strong>{paymentDate(opts.payment.date, opts.timezone)}</strong>
        </td>
      </tr>
      <tr>
        <td colSpan={2} style="padding: 0.5em"></td>
      </tr>
      <tr>
        <td>{totalLabel}</td>
        <td style={`padding-left: 3em; color: ${highlight || '#15803D'};`}>
          <strong>
            {fmt.price({
              currency: opts.payment.currency,
              priceInCents: opts.payment.amount,
            })}
          </strong>
        </td>
      </tr>
    </table>
  );
}

/**
 * Payment banner image for payment emails
 */
function PaymentBannerImage({ title, imageUrl, imageOnly }: PaymentEmailParams) {
  return (
    <div style="border-bottom: 1px solid #f8fafc; padding-bottom: 2em; margin-bottom: 2em;">
      {imageUrl && (
        <img
          style={`max-width: 50%; vertical-align: middle; margin-right: 2rem; border-radius: 0.25rem; ${
            imageOnly ? 'max-height: 2.5rem' : 'max-height: 5rem;'
          }`}
          src={imageUrl}
          alt={title}
        />
      )}
      {!imageOnly && <em>{title}</em>}
    </div>
  );
}

/**
 * Guide notification email for student payment plan cancellation
 */
export function StudentPlanCanceled({
  title,
  studentName,
  studentEmail,
  manageUrl,
  isAccessDisabled,
}: PaymentEmailParams & { studentName: string; studentEmail: string; isAccessDisabled: boolean }) {
  return render(
    <BasicEmailWrapper footer={<MarketingFooter />}>
      <h2>{title}</h2>
      <Line>
        <strong>
          {studentName}'s (<a href={`mailto:${studentEmail}`}>{studentEmail}</a>)
        </strong>{' '}
        Stripe plan or subscription has been canceled.
      </Line>
      {!isAccessDisabled && (
        <Line>
          They still have access to the course because you explicitly selected to not disable access
          for this subscription.
        </Line>
      )}
      <PrimaryAction href={manageUrl}>View in Ruzuku</PrimaryAction>
    </BasicEmailWrapper>,
  );
}

/**
 * Guide notification email for student payment failure
 */
export function StudentPaymentFailure(
  opts: PaymentEmailParams & { studentName: string; studentEmail: string; payment: Payment },
) {
  return render(
    <BasicEmailWrapper footer={<MarketingFooter />}>
      <h2>{opts.title}</h2>
      <Line>
        <strong>
          {opts.studentName}'s (<a href={`mailto:${opts.studentEmail}`}>{opts.studentEmail}</a>)
        </strong>
        {' payment of '}
        <strong>
          {fmt.price({
            currency: opts.payment.currency,
            priceInCents: opts.payment.amount,
          })}
        </strong>{' '}
        failed on {paymentDate(opts.payment.date, opts.timezone)}.
      </Line>
      <PrimaryAction href={opts.manageUrl}>View in Ruzuku</PrimaryAction>
    </BasicEmailWrapper>,
  );
}
