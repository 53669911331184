import dayjs, { Dayjs } from 'dayjs';
import { DateRange } from 'shared/scheduling';

export const weekdayColumnFormatter = new Intl.DateTimeFormat(undefined, { weekday: 'narrow' });
export const monthFormatter = new Intl.DateTimeFormat(undefined, {
  month: 'long',
  year: 'numeric',
});
export const weekdayFormatter = new Intl.DateTimeFormat(undefined, {
  month: 'short',
  day: 'numeric',
});
export const fullDate = new Intl.DateTimeFormat(undefined, {
  month: 'long',
  day: 'numeric',
  weekday: 'long',
  year: 'numeric',
});
export const summarizeDate = new Intl.DateTimeFormat(undefined, {
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: '2-digit',
  timeZoneName: 'short',
});
export const time12Formatter = new Intl.DateTimeFormat(undefined, {
  hour12: true,
  timeStyle: 'short',
});
export const time24Formatter = new Intl.DateTimeFormat(undefined, {
  hour12: false,
  timeStyle: 'short',
});
export const timeZoneFormatter = new Intl.DateTimeFormat(undefined, { timeZoneName: 'long' });

export function timeZoneName() {
  return timeZoneFormatter.formatToParts().find((x) => x.type === 'timeZoneName')?.value;
}

export function getDate(dt: Date = new Date()) {
  return new Date(dt.getFullYear(), dt.getMonth(), dt.getDate());
}

export function isNowish(range: DateRange, dt: Dayjs = dayjs(), fuzzy = 5) {
  if (dt.isAfter(range.start) && dt.isBefore(range.end)) {
    return true;
  }
  const diff = Math.min(
    Math.abs(dt.diff(range.start, 'minute')),
    Math.abs(dt.diff(range.end, 'minute')),
  );
  return diff < fuzzy;
}

export function summarizeTime(r: DateRange) {
  const start = dayjs(r.start);
  const end = dayjs(r.end);
  const startTime = start.format(start.format('a') === end.format('a') ? 'h:mm' : 'h:mma');
  const endTime = end.format('h:mma');
  return `${startTime} - ${endTime}`;
}

export function isAllDay(r: DateRange) {
  const startTime = new Date(r.start).toTimeString();
  const endTime = new Date(r.end).toTimeString();
  return (
    startTime.startsWith('00:00') && (endTime.startsWith('00:00') || endTime.startsWith('23:59'))
  );
}
