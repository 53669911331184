import {
  IcoChevronDown,
  IcoChevronUp,
  IcoDuplicate,
  IcoPlus,
  IcoSettings,
} from '@components/icons';
import type { ComponentChildren, h } from 'preact';
import { Dispatch, StateUpdater, useMemo } from 'preact/hooks';
import { SalesBlockState, SalespageContent, PaddingSize } from 'server/types';
import { substateUpdater } from 'client/lib/hooks';
import { SalesBlockDefinition } from './types';
import { Button } from '@components/buttons';
import { scrollToSelectedSection } from './scrollutil';
import { Color, autoColor, isLight } from 'shared/colors';

interface ViewProps {
  isFirst: boolean;
  nextSkewed?: boolean;
  state: SalesBlockState;
  definition: SalesBlockDefinition;
}

interface EditProps extends ViewProps {
  definitions: Record<string, SalesBlockDefinition>;
  isSelected: boolean;
  onSelect(): void;
  onAddBlock(): void;
  position: number;
  setState: Dispatch<StateUpdater<SalespageContent>>;
  onClone(id: string): void;
  onMove(id: string, direction: number): void;
}

function Skew(props: { skewed?: boolean; isSelected?: boolean; style: any }) {
  if (!props.skewed) {
    return null;
  }

  return (
    <div
      class={`absolute inset-x-0 -skew-y-6 origin-top-left h-full border-4 top-0 ${
        props.isSelected ? 'border-4 border-b-transparent border-indigo-600' : 'border-transparent'
      }`}
      style={props.style}
    ></div>
  );
}

function BlockWrapper(props: {
  paddingt?: PaddingSize;
  paddingb?: PaddingSize;
  nextSkewed?: boolean;
  skewed?: boolean;
  bgcolor?: string;
  children: ComponentChildren;
}) {
  const paddingRules = {
    top: {
      xl: 'pt-40 md:pt-56',
      lg: 'pt-20 md:pt-40',
      md: 'pt-10 md:pt-20',
      sm: 'pt-4 md:pt-8',
    },
    bottom: {
      xl: 'pb-40 md:pb-56',
      lg: 'pb-20 md:pb-40',
      md: 'pb-10 md:pb-20',
      sm: 'pb-4 md:pb-8',
    },
  };

  const defaultPadding: PaddingSize = 'lg';
  const padding = `${paddingRules.top[props.paddingt || defaultPadding]} ${
    paddingRules.bottom[props.paddingb || defaultPadding]
  }`;

  return (
    <div
      class={`content-block-body max-w-5xl w-full px-4 sm:px-10 ${
        props.skewed ? 'xl:-mt-8' : ''
      } mx-auto relative ${padding}`}
    >
      {props.children}
    </div>
  );
}

export function BlockViewer(props: ViewProps) {
  const { state, definition } = props;
  const elId = `block-${state.id}`;
  const bgcolor = state.bgcolor || Color.white;
  const isBgLight = useMemo(() => isLight(bgcolor), [bgcolor]);
  const fgcolor = state.fgcolor || autoColor(bgcolor);
  const style = blockStyle(state, fgcolor);

  return (
    <div
      id={elId}
      key={state.id}
      style={style}
      data-type={definition.type}
      class={`relative ${isBgLight ? '' : 'sales-page-dark'}`}
    >
      {!props.isFirst && <Skew skewed={state.skewed} style={style} />}
      <BlockWrapper
        bgcolor={bgcolor}
        skewed={state.skewed}
        nextSkewed={props.nextSkewed}
        paddingb={props.state.paddingb}
        paddingt={props.state.paddingt}
      >
        <definition.Viewer
          state={state.state}
          bgcolor={state.bgcolor}
          fgcolor={fgcolor}
          isBgLight={isBgLight}
        />
      </BlockWrapper>
    </div>
  );
}

function BtnToolbar({
  Ico,
  tooltip,
  onClick,
  hover = 'hover:bg-gray-100 hover:text-gray-800',
}: {
  Ico: typeof IcoSettings;
  onClick?(): void;
  tooltip: string;
  hover?: string;
}) {
  return (
    <Button
      class={`${hover} relative rounded-full w-6 h-6 inline-flex items-center justify-center`}
      data-tooltip={tooltip}
      onClick={onClick}
    >
      <Ico />
    </Button>
  );
}

export function BlockToolbar(props: Omit<EditProps, 'nextSkewed' | 'isSelected' | 'onSelect'>) {
  const { state } = props;

  return (
    <nav
      class="animate-slide-in absolute z-40 flex flex-col gap-4 border border-gray-100 top-1/4 left-8 bg-white text-gray-600 p-1 pt-1.5 shadow-xl rounded-full"
      onMouseEnter={() => {
        // When the mosuse enters the section context toolbar, we want to ensure
        // the section is scrolled into view so that it's clear *what* section
        // the user is about to modify.
        scrollToSelectedSection({ force: false });
      }}
    >
      <BtnToolbar Ico={IcoPlus} onClick={props.onAddBlock} tooltip="Add New Section" />
      <span class="border-t"></span>
      <BtnToolbar
        Ico={IcoDuplicate}
        tooltip="Clone Section"
        hover="hover:bg-red-50 hover:text-red-600"
        onClick={() => props.onClone(state.id)}
      />
      <span class="border-t"></span>
      <BtnToolbar
        Ico={IcoChevronUp}
        tooltip="Move Section Up"
        onClick={() => props.onMove(state.id, -1)}
      />
      <BtnToolbar
        Ico={IcoChevronDown}
        tooltip="Move Section Down"
        onClick={() => props.onMove(state.id, 1)}
      />
    </nav>
  );
}

export function BlockEditor(props: Omit<EditProps, 'onClone'>) {
  const { state, definition, onSelect, isSelected } = props;
  const elId = `block-${state.id}`;

  const setState = useMemo(
    () =>
      substateUpdater(
        props.setState,
        (s) => s.blocks[state.id].state,
        (s, sub) => ({
          ...s,
          blocks: {
            ...s.blocks,
            [state.id]: { ...s.blocks[state.id], state: sub },
          },
        }),
      ),
    [state.id],
  );

  const bgcolor = state.bgcolor || Color.white;
  const isBgLight = useMemo(() => isLight(bgcolor), [bgcolor]);
  const fgcolor = state.fgcolor || autoColor(bgcolor);
  const style = blockStyle(state, fgcolor);

  return (
    <div
      id={elId}
      key={state.id}
      onClick={onSelect}
      onFocusCapture={onSelect}
      class={`border-4 ring-inset ring-4 transition-all ${
        isSelected
          ? 'sales-page-active-block ring-white border-indigo-600 shadow-xl z-20'
          : 'ring-transparent border-transparent hover:border-indigo-200 hover:ring-white z-0'
      } ${isBgLight ? '' : 'sales-page-dark'} group relative px-8`}
      style={style}
    >
      {!props.isFirst && <Skew skewed={state.skewed} style={style} isSelected={isSelected} />}
      <BlockWrapper
        bgcolor={bgcolor}
        skewed={state.skewed}
        nextSkewed={props.nextSkewed}
        paddingb={props.state.paddingb}
        paddingt={props.state.paddingt}
      >
        <definition.Editor
          isBgLight={isBgLight}
          state={state.state}
          setState={setState}
          isSelected={isSelected}
          bgcolor={state.bgcolor}
          fgcolor={fgcolor}
        />
      </BlockWrapper>
    </div>
  );
}

export function blockStyle(
  state: SalesBlockState<any>,
  fgcolor: string | undefined,
): h.JSX.CSSProperties {
  return {
    backgroundColor: state.bgcolor || Color.white,
    backgroundImage: state.bgimg && `url(${state.bgimg})`,
    backgroundSize: 'cover',
    color: fgcolor,
  };
}
