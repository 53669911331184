import { showToast } from '@components/toaster';
import { autoFocusSelf } from 'client/utils/autofocus';
import { JSX } from 'preact';
import { useState } from 'preact/hooks';
import { minutesToTimeString } from 'shared/dateutil';
import { useIntl } from 'shared/intl/use-intl';

type Props = {
  prefix?: string;
  name?: string;
  value?: number;
  class?: string;
  onNewDuration: (value: number) => void;
};

const ONE_DAY = 24 * 60;
const ONE_MONTH = 30 * ONE_DAY;

export function MeetingDuration(props: Props) {
  const [customValue, setCustomValue] = useState<number | undefined>(undefined);

  return (
    <DurationSelect
      {...props}
      options={[15, 30, 60, 90, 120, 240]}
      customValue={customValue}
      setCustomValue={setCustomValue}
      placeholder="Custom duration (hours)"
      onNewDuration={props.onNewDuration}
      onInput={(e: any) => setCustomValue(parseFloat(e.target.value) * 60)}
      onBlur={() => {
        if (!customValue) {
          return;
        }
        // Maximum duration is 4 hours.
        if (customValue > 6 * 60) {
          showToast({
            type: 'warn',
            title: 'Duration too long',
            message: 'Maximum meeting duration is 6 hours. Please select a shorter duration.',
          });
          setCustomValue(undefined);
          return;
        }

        if (customValue && customValue > 0) {
          props.onNewDuration(customValue);
        }
        setCustomValue(undefined);
      }}
    />
  );
}

export function CourseAccessDuration(props: Props) {
  const [customValue, setCustomValue] = useState<number | undefined>(undefined);

  return (
    <DurationSelect
      {...props}
      options={[ONE_MONTH, 2 * ONE_MONTH, 3 * ONE_MONTH, 6 * ONE_MONTH, 12 * ONE_MONTH]}
      customValue={customValue}
      setCustomValue={setCustomValue}
      placeholder="Custom duration (days)"
      onNewDuration={props.onNewDuration}
      onInput={(e: any) => setCustomValue(parseFloat(e.target.value) * ONE_DAY)}
      onBlur={() => {
        if (!customValue) {
          return;
        }

        if (customValue && customValue > 0) {
          props.onNewDuration(customValue);
        }
        setCustomValue(undefined);
      }}
    />
  );
}

function DurationSelect(
  props: Props &
    JSX.InputHTMLAttributes<HTMLInputElement> & {
      options: number[];
      customValue: number | undefined;
      setCustomValue: (value: number | undefined) => void;
    },
) {
  const intl = useIntl();
  const {
    customValue,
    value,
    class: className = '',
    onNewDuration,
    setCustomValue,
    name,
    ...inputProps
  } = props;
  const isCustom = !props.options.find((option) => option === value);

  if (customValue !== undefined) {
    return (
      <input
        {...inputProps}
        type="number"
        class="ruz-input max-w-72"
        ref={autoFocusSelf}
        onKeyDown={(e: any) => e.code === 'Enter' && e.target.blur()}
      />
    );
  }

  return (
    <select
      class={`border border-gray-300 cursor-pointer hover:border-indigo-400 text-sm font-medium rounded-sm min-w-48 ${className}`}
      name={name}
      onChange={(e: any) => {
        if (e.target.value === 'custom') {
          setCustomValue(0);
        } else {
          const newVal = parseInt(e.target.value, 10);
          onNewDuration(isNaN(newVal) ? e.target.value : newVal);
        }
      }}
    >
      {props.options.map((option) => (
        <option key={option} value={option} selected={option === value}>
          {props.prefix}
          {minutesToTimeString(option, intl)}
        </option>
      ))}
      {isCustom && value && (
        <option value={value} selected>
          {props.prefix}
          {minutesToTimeString(value, intl)}
        </option>
      )}
      <option value="custom">Custom duration</option>
    </select>
  );
}
