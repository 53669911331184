import { useEffect, useMemo, useRef, useState } from 'preact/hooks';
import { showToast } from '@components/toaster';
import { Button } from '@components/buttons';
import { IcoDesktop, IcoMobile, IcoTablet } from '@components/icons';
import { showError } from '@components/app-error';
import { serialAsync } from 'client/utils/serial-async';
import { ajax } from 'client/lib/ajax';
import { Course } from 'server/types';
import { useDidUpdateEffect } from 'client/utils/use-did-update-effect';
import { Spinner } from '@components/spinner';
import { ComponentChildren } from 'preact';
import { useImageUrl } from 'client/utils/cdn';

interface Props {
  isLoading: boolean;
  course: Pick<Course, 'id' | 'title' | 'isBundle' | 'imagePath'>;
  brandId?: UUID;
  contentFont: string;
  colorScheme: { id: UUID; title: string };
  reloadDate?: number;
  // We don't let the user navigate any URL that does not start with this
  allowedNavigationPrefix: string;
  // The initial URL to load in the iframe
  initialURL: string;
}

const WIDTHS = {
  sm: 480,
  md: 768,
  xl: undefined,
};

function BtnDisplay(props: { selected: boolean; children: ComponentChildren; onClick(): void }) {
  return (
    <button
      class={`rounded-full p-1 px-3 ${
        props.selected ? 'bg-indigo-600 text-white' : 'text-indigo-600'
      }`}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
}

export function PreviewBrowser({
  isLoading,
  contentFont,
  colorScheme,
  brandId,
  reloadDate,
  course,
  allowedNavigationPrefix,
  initialURL,
}: Props) {
  const iframe = useRef<HTMLIFrameElement>(null);
  const [display, setDisplay] = useState<'logo' | 'content'>('content');
  const [width, setWidth] = useState<number | undefined>(WIDTHS.xl);

  /*
   * This method listens every click on the iframe
   * and makes sure users do not leave the student-facing
   * pages of the same course.
   */
  function handleClicks(doc: Document) {
    const fullUrl = new URL(`${window.location.origin}${allowedNavigationPrefix}`);

    doc.addEventListener(
      'click',
      (e) => {
        const target = e.target as HTMLAnchorElement;
        const href = target.href || (target.closest('a') as HTMLAnchorElement)?.href;

        if (!href) {
          return true;
        }

        if (!href.startsWith(`${fullUrl.origin}${fullUrl.pathname}`)) {
          showToast({
            type: 'warn',
            title: 'Link cannot be opened',
            message: 'This link is outside of the preview scope',
          });

          e.preventDefault();
          e.stopImmediatePropagation();
          return false;
        }
        return true;
      },
      true,
    );
  }

  const applyStyle = useMemo(
    () =>
      serialAsync(
        async ({ font, scheme, brandId }: { font: string; scheme: string; brandId?: UUID }) => {
          try {
            const iframeDoc = iframe.current?.contentDocument;
            if (!iframeDoc || !iframeDoc.body || !iframeDoc.head) {
              return;
            }

            const css = await ajax(
              `/api/course_css/${course.id}.css?forceReload=true&font=${font}&scheme=${scheme}&brandId=${brandId}`,
              {
                plain: true,
              },
            );

            // Remove the old style and re-create it for a complete clean-up
            Array.from(iframeDoc.body.querySelectorAll('style,link[rel=stylesheet]')).forEach(
              (el) => el.remove(),
            );

            // `body.preview` selector is used to override css variables
            iframeDoc.body.classList.add('preview');

            const newStyleElement = iframeDoc.createElement('style');
            newStyleElement.id = 'custom-style';
            newStyleElement.textContent = css;

            iframeDoc.body.appendChild(newStyleElement);
          } catch (err) {
            showError(err);
          }
        },
      ),
    [],
  );

  // Re-evaluate the inserted css when a font changes
  useEffect(() => {
    applyStyle({
      font: contentFont,
      scheme: colorScheme.id,
      brandId,
    });
  }, [contentFont, colorScheme, brandId]);

  // Reload the iframe when `reloadDate` changes.
  // It is used to force a reload of the iframe when the user
  // changes the course logo.
  useDidUpdateEffect(() => {
    iframe.current?.contentWindow?.location.reload();
  }, [reloadDate]);

  return (
    <div
      style={{ maxWidth: width }}
      class="w-full h-full max-h-screen-minus-14 rounded-lg shadow-sm drop-shadow-md"
    >
      <div class="h-full w-full">
        <div class="bg-gray-100 flex justify-between px-4 rounded-t-lg">
          <div class="pt-4 pb-3 mr-4 text-sm text-gray-400 whitespace-no-wrap">
            <i class="mx-1 rounded-full w-3 h-3 bg-red-400 inline-block"></i>
            <i class="mx-1 rounded-full w-3 h-3 bg-yellow-400 inline-block"></i>
            <i class="mx-1 rounded-full w-3 h-3 bg-green-400 inline-block"></i>
            {isLoading && (
              <span class="w-4 h-4 inline-flex items-center mx-1">
                <Spinner class="border-gray-400 w-4 h-4" />
              </span>
            )}
          </div>
          <div class="flex items-center">
            <nav class="font-medium flex gap-4">
              <BtnDisplay selected={display === 'content'} onClick={() => setDisplay('content')}>
                Content View
              </BtnDisplay>
              <BtnDisplay selected={display === 'logo'} onClick={() => setDisplay('logo')}>
                Logo View
              </BtnDisplay>
            </nav>
          </div>
          <div class="flex items-center space-x-3">
            <Button
              className={width === WIDTHS.xl ? 'text-indigo-600' : ''}
              onClick={() => setWidth(WIDTHS.xl)}
            >
              <IcoDesktop class="w-6" />
            </Button>
            <Button
              className={width === WIDTHS.md ? 'text-indigo-600' : ''}
              onClick={() => setWidth(WIDTHS.md)}
            >
              <IcoTablet />
            </Button>
            <Button
              className={width === WIDTHS.sm ? 'text-indigo-600' : ''}
              onClick={() => setWidth(WIDTHS.sm)}
            >
              <IcoMobile />
            </Button>
          </div>
        </div>
        {display === 'logo' && <LogoPreview course={course} />}
        {display === 'content' && (
          <iframe
            ref={iframe}
            class="h-full w-full rounded-b-lg"
            title="Student Preview"
            src={initialURL}
            onLoad={() => {
              const iframeDoc = iframe.current?.contentDocument;
              if (!iframeDoc) {
                return;
              }

              applyStyle({
                font: contentFont,
                scheme: colorScheme.id,
                brandId,
              });
              handleClicks(iframeDoc);
            }}
          />
        )}
      </div>
    </div>
  );
}

function LogoPreview({ course }: { course: Pick<Course, 'title' | 'imagePath'> }) {
  const src = useImageUrl(course.imagePath);
  return (
    <div class="p-4 flex flex-wrap gap-6 items-start">
      <section class="border rounded-md overflow-hidden max-w-60">
        <div class="w-full aspect-square">
          <img src={src} class="w-full" />
        </div>
        <footer class="bg-white p-4">
          Here's a preview of how your course logo will display on the My Courses page.
        </footer>
      </section>

      <section class="flex gap-2 items-center rounded-md overflow-hidden w-96 bg-gray-50 p-4">
        <div class="w-24 aspect-square shrink-0">
          <img src={src} class="w-full rounded-md" />
        </div>
        <footer class="bg-gray-50 p-4 flex flex-col overflow-hidden">
          <span>Checkout page</span>
          <span class="block max-w-full font-medium whitespace-nowrap overflow-hidden text-ellipsis">
            {course.title}
          </span>
        </footer>
      </section>
    </div>
  );
}
