/**
 * This file contains a hook for handling the common escape mechanics for
 * e.g. closing a modal, flyout, etc.
 */
import { useEffect } from 'preact/hooks';

const queue: any[] = [];

/**
 * Handle pressing the Esc key, and mousing down on the document.
 * @param {function} onEsc the function to be called when the user attempts escape.
 * @param {boolean} opts.escapeOnly ignores document clicks when true.
 * @param {boolean} opts.respectDefault ignores mousedown if defaultPrevented.
 */
export function useEsc(
  onEsc: () => void,
  opts: {
    respectDefault?: boolean;
    escapeOnly?: boolean;
  } = { respectDefault: false, escapeOnly: false },
) {
  useEffect(() => {
    queue.push(onEsc);

    const closeOnEsc = (e: KeyboardEvent) => {
      if (e.key === 'Escape' && onEsc === queue[queue.length - 1]) {
        onEsc();
      }
    };
    const closeOnMouseDown = (e: MouseEvent) => {
      if (!opts.respectDefault || !e.defaultPrevented) {
        onEsc();
      }
    };

    if (!opts.escapeOnly) {
      document.addEventListener('click', closeOnMouseDown);
    }

    document.addEventListener('keydown', closeOnEsc);
    return () => {
      queue.pop();

      if (!opts.escapeOnly) {
        document.removeEventListener('click', closeOnMouseDown);
      }

      document.removeEventListener('keydown', closeOnEsc);
    };
  }, []);
}
