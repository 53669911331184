/**
 * This is the student detail view for the manage students screen.
 */

import { useRouteParams } from '@components/router';
import * as fmt from 'shared/payments/fmt';
import { rpx, RpxResponse } from 'client/lib/rpx-client';
import { IcoChevronDown, IcoExternalLink } from '@components/icons';
import { UserProfileIcon } from '@components/avatars';
import { fmtFullDate, fmtFullTime } from 'shared/dateutil';
import { Pill } from '@components/pill';
import { urls } from './urls';
import { useMemo, useState } from 'preact/hooks';
import { serialAsync } from 'client/utils/serial-async';
import { showError } from '@components/app-error';
import { SlideOver } from '@components/slide-over';
import { useTryAsyncData } from 'client/lib/hooks';
import { DefaultSpinner } from '@components/spinner';
import { StripeLink } from '../pmts/components/stripe-link';
import { Tab, Tabs } from '@components/tabs';
import { ProgressTab } from './progress-tab';
import { PurchasePane } from '../pmts/components/purchase-pane';
import { URLS } from 'shared/urls';
import { Course } from '@course/components/module-helpers';
import { Button } from '@components/buttons';
import { ModifyAccessModal } from './modify-access-modal';
import { useAsyncEffect } from 'client/utils/use-async-effect';
import { showToast } from '@components/toaster';
import { ReadonlyMinidoc } from '@components/minidoc/readonly-minidoc';
import { Dropdown, MenuItem } from '@components/dropdown';
import { showDialog } from '@components/dialog';

const store = rpx.manageStudents;

export type Student = RpxResponse<typeof store.getStudent>;

interface Props {
  course: Pick<Course, 'id' | 'isBundle' | 'level'>;
  userId: string;
  showProgress: boolean;
  baseUrl: string;
  onChange(student: Student): void;
  onClose(): void;
}

function ProfileTab({ student, courseId }: { courseId: UUID; student: Student }) {
  const loader = useTryAsyncData(async () => {
    if (!student.profileFields) {
      return [];
    }
    return rpx.profileFields.getStudentProfileFields({ courseId, userId: student.id });
  }, []);

  const profileFields = [
    {
      id: 'signup',
      label: 'Signed up',
      value: fmtFullDate(student.signupDate),
      useMinidoc: false,
    },
    {
      id: 'bio',
      label: 'Bio',
      value: student.bio,
    },
    ...(loader.data || []),
  ];

  return (
    <div class="grid grid-cols-1 lg:grid-cols-2 gap-6">
      {!!profileFields &&
        profileFields.map((l) => (
          <div key={l.id} class="flex flex-col">
            <span class="text-gray-500">{l.label}</span>
            {l.id === 'bio' && <ReadonlyMinidoc content={l.value || 'N/A'} />}
            {l.id !== 'bio' && <span>{l.value || 'N/A'}</span>}
          </div>
        ))}
      {loader.isLoading && <DefaultSpinner />}
    </div>
  );
}

const typeDescription: Record<string, string> = {
  'automatic access management': 'student access will be revoked when subscription is canceled',
  'manual access management': 'student will retain access when subscription is canceled',
};

function MembershipLogsTab({ student, courseId }: { courseId: UUID; student: Student }) {
  const { data, isLoading } = useTryAsyncData(
    () => store.getStudentLogs({ courseId, userId: student.id }),
    [],
  );

  if (isLoading) {
    return <DefaultSpinner />;
  }

  if (!data || data.length === 0) {
    return <div>No membership logs found</div>;
  }

  return (
    <div class="table table-auto bg-white rounded-sm border w-full divide-y mt-4">
      <div class="table-row bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
        <div class="table-cell pl-4 pr-2 py-2">Date</div>
        <div class="table-cell pl-4 pr-2 py-2">Action</div>
      </div>
      {data.map((r) => {
        const type = typeDescription[r.type] || r.type;

        return (
          <div key={r.id} class="table-row text-inherit hover:bg-indigo-50 text-sm relative">
            <span class="table-cell p-4 border-t align-top">{fmtFullTime(r.createdAt)}</span>
            <span class="table-cell p-4 border-t align-top">
              <span class="text-gray-800">{type}</span>
              {type !== r.message && <span class="text-gray-500 block">{r.message}</span>}
            </span>
          </div>
        );
      })}
    </div>
  );
}

function StudentDetailPane({
  student,
  setStudent,
  course,
  showProgress,
  baseUrl,
  setShowAccessModal,
}: {
  showProgress: boolean;
  course: Props['course'];
  student: Student;
  baseUrl: string;
  setStudent: (student: Partial<Student>) => void;
  setShowAccessModal(shouldShow: boolean): void;
}) {
  const { id: courseId } = course;
  const userId = student.id;
  const params = useRouteParams();
  const { tab = 'payments' } = params;
  const hasFullAccess = course.level === 'guide';

  const setIsEnabled = useMemo(
    () =>
      serialAsync(async (isEnabled: boolean) => {
        const oldStatus = student.status;
        setStudent({ status: isEnabled ? 'enabled' : 'disabled' });
        try {
          await store.setIsEnabled({
            courseId,
            userId: student.id,
            isEnabled,
          });
        } catch (err) {
          setStudent({ status: oldStatus });
          showError(err);
        }
      }),
    [setStudent],
  );

  const { purchase, price, coupon, bundle } = student;

  async function toggleStatus() {
    const shouldEnable = student.status !== 'enabled';

    if (shouldEnable) {
      setIsEnabled(true);
      return;
    }

    if (!price || !purchase || !fmt.isSubscriptionLike(price) || purchase.status !== 'active') {
      setIsEnabled(false);
      return;
    }

    const isConfirmed = await showDialog({
      mode: 'warn',
      title: 'Cancel subcription',
      children: 'This student has an active subscription. Do you want to cancel it?',
      confirmButtonText: 'Cancel subscription',
      cancelButtonText: 'Keep subscription',
    });
    if (isConfirmed) {
      await rpx.subscriptionStatus.cancelPurchase({
        purchaseId: purchase.id,
        courseId,
      });
      // Hacky way to reset all state
      location.reload();
    }
    setIsEnabled(false);
  }

  return (
    <div class="px-4 grow overflow-y-auto">
      <header class="flex mb-8">
        <UserProfileIcon user={student} size="w-20 h-20 text-2xl" />
        <div class="flex grow flex-col ml-6 justify-center">
          <section class="flex justify-between">
            <h2 class="text-2xl mb-1 break-all">{student.name}</h2>
            {purchase?.stripeCustomerId && hasFullAccess && (
              <span class="pl-2 hidden md:inline-block">
                <StripeLink stripeId={purchase.stripeCustomerId}>
                  View in Stripe
                  <IcoExternalLink class="w-4 h-4 opacity-75 ml-1" />
                </StripeLink>
              </span>
            )}
          </section>
          <div class="flex items-center gap-4 text-gray-500">
            <a class="break-all" href={`mailto:${student.email}`}>
              {student.email}
            </a>
            <span class="pl-2 hidden md:inline-block">{student.timezone}</span>
            {student.wasInvited && <Pill color="green">Invited</Pill>}
            {student.status === 'expired' && <Pill color="gray">Expired</Pill>}
            {(student.status === 'disabled' || student.status === 'enabled') && (
              <Dropdown
                hideDownIcon
                disabled={!hasFullAccess}
                renderMenu={() => (
                  <div class="flex flex-col p-2 pb-0">
                    <MenuItem onClick={toggleStatus}>
                      {student.status === 'disabled' ? 'Grant access' : 'Revoke access'}
                    </MenuItem>
                  </div>
                )}
              >
                <Pill color={student.status === 'disabled' ? 'red' : 'green'}>
                  <span class="flex items-center gap-1">
                    Access {student.status === 'disabled' ? 'revoked' : 'granted'}
                    {hasFullAccess && <IcoChevronDown />}
                  </span>
                </Pill>
              </Dropdown>
            )}
          </div>
        </div>
      </header>

      <Tabs>
        {hasFullAccess && (
          <Tab
            isSelected={tab === 'payments'}
            href={urls.showStudent({ ...params, courseId, userId, tab: 'payments', baseUrl })}
          >
            Payments
          </Tab>
        )}
        {showProgress && (
          <Tab
            isSelected={tab === 'progress'}
            href={urls.showStudent({ ...params, courseId, userId, tab: 'progress', baseUrl })}
          >
            Progress
          </Tab>
        )}
        <Tab
          isSelected={tab === 'profile'}
          href={urls.showStudent({ ...params, courseId, userId, tab: 'profile', baseUrl })}
        >
          Profile
        </Tab>
        {hasFullAccess && (
          <Tab
            isSelected={tab === 'membershipLogs'}
            href={urls.showStudent({
              ...params,
              courseId,
              userId,
              tab: 'membershipLogs',
              baseUrl,
            })}
          >
            Membership Activity
          </Tab>
        )}
      </Tabs>

      {hasFullAccess && student.status !== 'disabled' && (
        <header class="flex items-center gap-2 my-4 justify-end">
          {student.expiresAt && (
            <span>
              Access{' '}
              {`${student.status === 'expired' ? ' expired ' : ' expires '} ${fmtFullDate(
                student.expiresAt,
              )}`}
            </span>
          )}
          {
            <Button class="text-indigo-600 underline" onClick={() => setShowAccessModal(true)}>
              {student.expiresAt ? 'Modify Expiration Date' : 'Set Expiration Date'}
            </Button>
          }
        </header>
      )}

      {tab === 'payments' && hasFullAccess && (
        <>
          <div class="flex gap-2 items-baseline p-6 py-4 border rounded-sm mb-8">
            <span class="text-2xl font-semibold">
              {fmt.price({
                priceInCents: purchase?.totalPaid || 0,
                currency: price?.currency || 'USD',
              })}
            </span>
            <span class="font-semibold text-gray-500">Total Paid</span>
          </div>
          <PurchasePane
            {...student}
            asSeller
            fromBundle={student.bundle?.title}
            priceUrl={
              price
                ? URLS.guide.price({ courseId: bundle?.id || courseId, priceId: price.id })
                : undefined
            }
            couponUrl={coupon ? URLS.guide.coupon({ courseId, couponId: coupon.id }) : undefined}
            onPurchaseChange={(update) => {
              if (student.purchase) {
                setStudent({
                  purchase: {
                    ...student.purchase,
                    ...update,
                  },
                });
              }
            }}
          />
        </>
      )}
      {tab === 'profile' && <ProfileTab student={student} courseId={courseId} />}
      {tab === 'progress' && <ProgressTab student={student} courseId={courseId} />}
      {tab === 'membershipLogs' && hasFullAccess && (
        <MembershipLogsTab student={student} courseId={courseId} />
      )}
    </div>
  );
}

export function StudentDetailSlideOver({
  course,
  showProgress,
  baseUrl,
  userId,
  onClose,
  onChange,
}: Props) {
  const { id: courseId } = course;
  const [student, rawSetStudent] = useState<Student | undefined>();
  const [showAccessModal, setShowAccessModal] = useState(false);

  const setStudent = (update: Partial<Student>) => {
    rawSetStudent((s) => {
      if (!s) {
        return s;
      }

      const result = {
        ...s,
        ...update,
      };
      onChange(result);
      return result;
    });
  };

  useAsyncEffect(async () => {
    const result = await store.getStudent({ courseId, userId });
    rawSetStudent(result);
  });

  return (
    <>
      <SlideOver close={onClose}>
        {!student && <DefaultSpinner />}
        {student && (
          <StudentDetailPane
            showProgress={showProgress}
            course={course}
            baseUrl={baseUrl}
            student={student}
            setStudent={setStudent}
            setShowAccessModal={setShowAccessModal}
          />
        )}
      </SlideOver>
      {showAccessModal && student && (
        <ModifyAccessModal
          courseId={courseId}
          student={student}
          onSuccess={(newExpiresAt) => {
            setStudent({
              ...student,
              expiresAt: newExpiresAt ? new Date(newExpiresAt) : undefined,
            });
            setShowAccessModal(false);
            showToast({
              type: 'ok',
              title: 'Access modified',
              message: 'Expiration date updated',
            });
          }}
          hide={() => setShowAccessModal(false)}
        />
      )}
    </>
  );
}
