import { CourseStatus } from 'server/types';
import { Course, CourseType, NormalizedStatus, Filters } from './types';

export function normalizedStatus(c: {
  status: CourseStatus;
  numStudents?: number;
}): NormalizedStatus {
  if (c.status === 'draft' && !!c.numStudents) {
    return 'closed';
  }

  return c.status;
}

export function filterCourses({
  courses,
  type,
  filters,
}: {
  courses: Course[];
  type: CourseType;
  filters: Pick<Filters, 'type' | 'status' | 'show'>;
}) {
  return courses
    .filter((c) => {
      if (c.isProduct && type !== 'products') {
        return false;
      }
      if (!c.isProduct && type === 'products') {
        return false;
      }
      if (c.isBundle && type !== 'bundles') {
        return false;
      }
      if (!c.isBundle && type === 'bundles') {
        return false;
      }
      if (filters.show === 'all') {
        return true;
      }
      if (filters.show === 'archived') {
        return c.isArchived;
      }
      if (c.isArchived) {
        return false;
      }
      if (filters.show === 'teaching') {
        return c.level === 'guide' || c.level === 'facilitator';
      }
      if (filters.show === 'attending') {
        return c.level === 'student';
      }
      return true;
    })
    .filter((c) => {
      const typeCondition = filters.type === 'all' || c.accessFormat === filters.type;
      const statusCondition = filters.status === 'all' || c.normalizedStatus === filters.status;
      return typeCondition && statusCondition;
    });
}
