import dayjs from 'dayjs';
import { useEffect, useState } from 'preact/hooks';
import { MeetingRow } from 'server/types';
import { getMeetingStatus } from 'shared/meeting-utils';

export function useMeetingStatus({
  scheduledAt,
  durationMinutes,
}: Pick<MeetingRow, 'scheduledAt' | 'durationMinutes'>) {
  const [status, setStatus] = useState(getMeetingStatus({ scheduledAt, durationMinutes }));

  /*
   * Configure a timeout method for the scheduled date of the meeting,
   * and trigger a re-render with the new status.
   */
  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (status === 'scheduled' || status === 'aboutToStart') {
      const diffInMs = dayjs(scheduledAt).diff(dayjs(), 'milliseconds');
      timer = setTimeout(() => {
        const newStatus = getMeetingStatus({ scheduledAt, durationMinutes });
        setStatus(newStatus);
      }, diffInMs);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [scheduledAt]);

  return status;
}
