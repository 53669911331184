/**
 * An add action which is background-color aware.
 */

import { Button } from '@components/buttons';
import { ComponentChildren } from 'preact';

export function BtnAdd(props: {
  onClick(): void;
  isBgLight: boolean;
  class?: string;
  children?: ComponentChildren;
}) {
  return (
    <Button
      class={`${
        props.isBgLight
          ? 'bg-gray-100 text-gray-800 hover:shadow-lg'
          : 'bg-black/50 text-white hover:bg-black/30'
      } ${props.class || ''} p-4 px-6 rounded-full shadow-sm`}
      onClick={props.onClick}
    >
      {props.children || '+ Add Item'}
    </Button>
  );
}
