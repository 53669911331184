import { IcoInfo } from '@components/icons';
import { ComponentChildren } from 'preact';

type Props = { p?: string; children: ComponentChildren };

export function Note({ p = 'p-6 px-8', children }: Props) {
  return (
    <div class={`border-l-8 border-indigo-600 bg-indigo-50 mb-4 ${p}`}>
      <span class="font-semibold text-indigo-600 flex items-center gap-1.5">
        <IcoInfo class="size-5" />
        Note
      </span>
      <div>{children}</div>
    </div>
  );
}
