import { CheckoutForm } from './checkout-form';
import { useIntl } from 'shared/intl/use-intl';
import { useState } from 'preact/hooks';
import * as pmtmath from 'shared/payments/math';
import { CouponRow, PriceRow } from 'server/types';
import { StripeContext, StripeInput } from './stripe';
import { CurrentUser, useCurrentUser } from 'client/lib/auth';
import { FormGroup } from '@components/async-form';
import { showError } from '@components/app-error';
import { CheckoutFn, PurchaseFn, StripeHandlerFn } from 'shared/payments/types';
import { StripeAddressElementChangeEvent } from '@stripe/stripe-js';
import { UpsellStripeCheckoutForm, isUpsellable } from './upsell-stripe-checkout-form';

async function processStripePayment({
  ctx,
  user,
  priceId,
  couponId,
  processPayment,
}: {
  ctx: StripeContext;
  user: NonNullable<CurrentUser>;
  processPayment: PurchaseFn;
  priceId: string;
  couponId?: string;
}) {
  const elementsResult = await ctx.elements.submit();

  if (elementsResult.error) {
    return { type: 'validationError' };
  }

  const addressElement = ctx.elements.getElement('address');
  let addressFormValue: Partial<StripeAddressElementChangeEvent['value']> = {
    name: '',
    address: undefined,
  };

  if (addressElement) {
    const addressFormState = await addressElement.getValue();
    if (!addressFormState.complete) {
      return { type: 'validationError' };
    }
    addressFormValue = addressFormState.value;
  }

  const address = {
    ...addressFormValue.address,
    // Fix the type-signature, as Stripe doesn't like getting its own types!
    line2: addressFormValue.address?.line2 || undefined,
  };

  const { error, paymentMethod } = await ctx.stripe.createPaymentMethod({
    type: 'card',
    card: ctx.elements.getElement('card')!,
    billing_details: {
      name: addressFormValue.name || user.name,
      email: user.email,
      address,
    },
    metadata: {
      userId: user.id,
    },
  });

  if (error) {
    console.error(error);
    throw error;
  }
  if (!paymentMethod) {
    throw new Error(`Failed to create Stripe payment method.`);
  }

  const purchase = () =>
    processPayment({ priceId, couponId, stripePaymentMethod: paymentMethod.id, address });

  const result = await purchase();
  if (!result) {
    return;
  }
  if (result.type !== 'action') {
    return result;
  }

  // Stripe requires further action, such as 3D secure
  const actionResult = await ctx.stripe.confirmCardPayment(result.action, {
    setup_future_usage: 'off_session',
  });
  if (!actionResult.paymentIntent) {
    console.error(actionResult);
    return;
  }

  // Confirm the action with Stripe
  return await purchase();
}

export function StripeCheckoutForm(props: {
  collectAddress: boolean;
  price: PriceRow;
  coupon?: CouponRow;
  isGift?: boolean;
  /**
   * If handleStripe is passed in, the caller is responsible for invoking Stripe
   * checkout themselves-- this is used by the upsell modal to invert control
   * so that it can perform N checkouts in a sequence.
   */
  handleStripe?: StripeHandlerFn;
  beginPurchase: PurchaseFn;
  onPurchaseComplete(): Promise<unknown>;
}) {
  const { collectAddress, price, coupon, isGift, beginPurchase, onPurchaseComplete } = props;
  const intl = useIntl();
  const [ctx, setCtx] = useState<StripeContext | undefined>(undefined);
  const user = useCurrentUser()!;
  const freeTrialPeriod = pmtmath.freeTrialPeriod({ price, coupon });
  const paymentRequired = !freeTrialPeriod && !!pmtmath.initialPrice({ price, coupon });

  if (isUpsellable(price)) {
    return <UpsellStripeCheckoutForm {...props} />;
  }

  return (
    <CheckoutForm
      actionText={
        freeTrialPeriod
          ? intl('Start Free Trial ⤑')
          : isGift
          ? intl('Purchase Gift ⤑')
          : intl('Buy Now ⤑')
      }
      onSubmit={async () => {
        if (!ctx || !user) {
          return;
        }

        const checkout: CheckoutFn = async ({ priceId, couponId }) => {
          const result = await processStripePayment({
            ctx,
            user,
            priceId,
            couponId,
            processPayment: beginPurchase,
          });
          return !!result && result.type !== 'validationError';
        };

        try {
          const success = props.handleStripe
            ? await props.handleStripe({ checkout })
            : await checkout({ priceId: price.id, couponId: coupon?.id });
          if (!success) {
            return;
          }
          await onPurchaseComplete();
        } catch (err) {
          showError(err);
        }
      }}
    >
      <FormGroup prop="paymentMethod">
        <StripeInput
          customerName={user?.name}
          priceId={price.id}
          onReady={setCtx}
          collectAddress={collectAddress || false}
        />
      </FormGroup>
      {!paymentRequired && (
        <p class="text-gray-600 text-center mt-4">
          {!!freeTrialPeriod &&
            intl(`You will not be charged until the end of the free trial period.`)}
          {!freeTrialPeriod && intl(`You will not be charged today.`)}
        </p>
      )}
    </CheckoutForm>
  );
}
