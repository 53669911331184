import { showError } from '@components/app-error';
import { Button } from '@components/buttons';
import { Case } from '@components/conditional';
import { Pill } from '@components/pill';
import { rpx } from 'client/lib/rpx-client';
import { useImageUrl } from 'client/utils/cdn';
import { useState } from 'preact/hooks';
import { URLS } from 'shared/urls';
import { InstantCourse } from './index';
import { useRouter } from '@components/router';

const store = rpx.instantCourses;

export function CourseItem({ course }: { course: InstantCourse }) {
  const [isLoading, setIsLoading] = useState(false);
  const imageUrl = useImageUrl(course.imagePath);
  const router = useRouter();

  async function registerToTemplate() {
    if (isLoading) {
      return;
    }

    setIsLoading(true);
    try {
      await store.registerToInstantCourse({
        courseId: course.id,
      });
      router.goto(
        URLS.student.course({
          course,
        }),
      );
    } catch (err) {
      showError(err);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div class="cursor-pointer hover:opacity-90" onClick={registerToTemplate}>
      <div class="relative">
        <div class="relative w-full h-72 rounded-lg overflow-hidden">
          <Case
            when={!!imageUrl}
            fallback={
              <div class="flex justify-center items-center w-full h-full bg-gray-50">No image</div>
            }
          >
            <img src={imageUrl} class="w-full h-full object-center object-cover" />
          </Case>
          {!!course.isPurchased && (
            <div class="absolute top-4 left-0">
              <Pill color="green">Purchased</Pill>
            </div>
          )}
        </div>
        <div class="relative mt-4">
          <h3 class="text-sm font-medium text-zinc-900 h-10">{course.title}</h3>
          <p class="mt-1 text-sm text-zinc-500 h-12">{course.description}</p>
        </div>
        <div class="absolute top-0 inset-x-0 h-72 rounded-lg p-4 flex items-end justify-end overflow-hidden">
          <div
            aria-hidden="true"
            class="absolute inset-x-0 bottom-0 h-36 bg-linear-to-t from-black opacity-50"
          />
        </div>
      </div>
      <div class="mt-6">
        <Button
          class="w-full bg-zinc-100 rounded-md py-2 px-8 text-sm font-medium text-zinc-900"
          isLoading={isLoading}
        >
          Preview
        </Button>
      </div>
    </div>
  );
}
