/**
 * This file contains the application router constructor.
 */

import { LocationChangeHandler, historyRouter } from './history-router';

type RouteParams = Record<string, string>;

export type Router<T extends { url: string }> = {
  /**
   * Get the definition associated with the url.
   */
  definition(url: string): T;

  /**
   * Add a new route.
   */
  add(def: T): T;

  /**
   * Navigate the app to the specified url.
   */
  goto(url: string): void;

  /**
   * goto the specified URL, and wait a few ms to allow the UI to perform
   * any transitions it desires.
   */
  redirectTo(url: string, ms?: number): Promise<unknown>;

  /**
   * Rewrite the current URL. This removes the
   * current URL from history and replaces it.
   * This does *not* cause the route to re-evaluate.
   */
  rewrite(url: string): void;

  /**
   * Register a  callback to be called when the route changes.
   */
  onLocationChange(callback: LocationChangeHandler): () => void;

  /**
   * Initialize the router. This returns undefined if there
   * is a preprocessor that is handling the current route.
   */
  init(): [string, RouteParams];
};

function routeBuilder<T extends { url: string }>(createRouter: typeof historyRouter): Router<T> {
  const defs: { [k: string]: T } = {};
  let router: ReturnType<typeof createRouter>;

  return {
    /**
     * Get the definition associated with the url.
     */
    definition(url: string) {
      return defs[url];
    },

    /**
     * Add a new route.
     */
    add(def: T) {
      defs[def.url] = def;
      return def;
    },

    /**
     * Navigate the app to the specified url.
     */
    goto(url: string) {
      return router.goto(url);
    },

    /**
     * goto the specified URL, and wait a few ms to allow the UI to perform
     * any transitions it desires.
     */
    async redirectTo(url: string, ms = 1000) {
      this.goto(url);
      await new Promise((r) => setTimeout(r, ms));
    },

    /**
     * Rewrite the current URL. This removes the
     * current URL from history and replaces it.
     * This does *not* cause the route to re-evaluate.
     */
    rewrite(url: string) {
      return router.rewrite(url);
    },

    onLocationChange(callback: LocationChangeHandler) {
      return router.onLocationChange(callback);
    },

    /**
     * Initialize the router.
     */
    init() {
      router = createRouter(Object.keys(defs));
      return router.init();
    },
  };
}

export function createHistoryRouter<T extends { url: string }>() {
  return routeBuilder<T>(historyRouter);
}
