import { PriceRow, CouponRow } from 'server/types';
import { useIntl } from 'shared/intl/use-intl';
import * as fmt from 'shared/payments/fmt';
import * as pmtmath from 'shared/payments/math';

export function PriceSummary({
  price,
  coupon,
  multiline = true,
}: {
  price: PriceRow;
  coupon?: CouponRow;
  multiline?: boolean;
}) {
  const intl = useIntl();
  const { initial, final } = pmtmath.batches({
    price,
    coupon,
  });

  return (
    <>
      {initial && (
        <span>
          {fmt.price(initial)}{' '}
          {fmt.firstBatchSuffix({
            item: initial,
            intl,
          })}
          {multiline ? <br /> : ', '}
          {'then '}
        </span>
      )}
      {`${fmt.price(final)} ${fmt.priceSuffix({
        item: final,
        intl,
      })}`}
    </>
  );
}
