import { IcoCheck, IcoChevronDown, IcoExclamation, IcoPlus } from '@components/icons';
import { AutosizeText } from '@components/autosize-text';
import { useCurrentUser } from 'client/lib/auth';
import { Field, InputField, eventToState } from '../form-helpers';
import { Dropdown, MenuItem } from '@components/dropdown';
import { showAvailabilityModal } from '../cal-availability-edit';
import type { State, SetState } from './types';
import { AvailabilityRow } from 'server/types/cal-schema';
import { SummarizeAvailableTimes } from '../availability-summary';
import { ColorPalette, PaletteColor } from '@components/color-palette';
import { Case } from '@components/conditional';
import { DurationInput } from './duration-input';

type Props = {
  state: State;
  setState: SetState;
};

function AvailabilityItem(props: { availability?: AvailabilityRow; timezone: string }) {
  return (
    <span class="flex flex-col cursor-pointer focus:ring-1 transition-all outline-hidden">
      <span class="font-semibold">{props.availability?.name ?? 'None selected'}</span>
      {props.availability && (
        <SummarizeAvailableTimes
          timeslots={props.availability.timeslots}
          scheduleTimeZone={props.availability.timezone}
          renderTimeZone={props.timezone}
        />
      )}
    </span>
  );
}

export type EventLocationInputsState = Pick<State, 'location' | 'locationDetail'>;

export function EventLocationInputs({
  state,
  onChange,
  hasZoom,
  zoomOauthUrl,
}: {
  state: EventLocationInputsState;
  onChange: (locationState: EventLocationInputsState) => void;
  hasZoom: boolean;
  zoomOauthUrl: string;
}) {
  return (
    <>
      <Field name="location" title="Location">
        <select
          class="inline-ruz-input max-w-fit cursor-pointer"
          name="location"
          value={state.location}
          onInput={(e: any) => onChange({ ...state, location: e.target.value })}
        >
          <option value="zoom">Zoom</option>
          <option value="jitsi">Jitsi</option>
          <option value="external">External</option>
        </select>
        {state.location === 'jitsi' && (
          <span class="text-gray-600 mt-2">
            Jitsi is a free, open-source video conferencing platform.
          </span>
        )}
        {state.location === 'zoom' && (
          <span class="text-gray-600 mt-2">
            <Case
              when={hasZoom}
              fallback={
                <>
                  Please <a href={zoomOauthUrl}>connect your Zoom account</a> to use this option.
                </>
              }
            >
              Note: This option creates a new Zoom meeting for each booking. Please use the external
              option if you need to use your personal room.
            </Case>
          </span>
        )}
      </Field>
      {state.location === 'external' && (
        <Field name="locationDetail" title="External location">
          <AutosizeText
            name="locationDetail"
            containerClass="bg-white"
            class="ruz-input min-h-20 p-3 px-4"
            placeholder="e.g. https://zoom.us/j/1234567890"
            value={state.locationDetail?.external}
            onInput={(e: any) =>
              onChange({
                ...state,
                locationDetail: {
                  external: e.target.value,
                },
              })
            }
          />
        </Field>
      )}
    </>
  );
}

export function BasicsTab({ state, setState }: Props) {
  const user = useCurrentUser()!;
  const status = state.reservedURLSuffixes.includes(state.urlSuffix) ? 'conflict' : 'verified';
  const availability = state.availability.find((a) => a.id === state.availabilityId);

  return (
    <div class="flex flex-col gap-8 max-w-2xl">
      <InputField
        name="name"
        title="Title"
        autoFocus={state.isNew}
        value={state.name}
        onInput={eventToState(setState)}
        placeholder="e.g. 1:1"
        inputWidth="w-96 max-w-full"
      />
      <DurationInput
        propName="duration"
        value={state.duration}
        onChange={(duration) =>
          setState((s) => ({
            ...s,
            duration,
          }))
        }
      />
      <Field name="description" title="Description">
        <AutosizeText
          name="description"
          containerClass="bg-white"
          class="ruz-input min-h-20 p-3 px-4"
          placeholder="Briefly describe the meeting"
          value={state.description}
          onInput={eventToState(setState)}
        />
      </Field>
      <InputField
        title={
          <span class="flex gap-2 items-center">
            URL
            {status === 'conflict' && (
              <span class="text-red-600 text-xs flex gap-2 items-center">
                <IcoExclamation class="size-4" />
                URL already taken
              </span>
            )}
            {status === 'verified' && (
              <span class="text-green-600 text-xs">
                <IcoCheck class="size-4" />
              </span>
            )}
          </span>
        }
        name="urlSuffix"
        prefix={`/${state.urlPrefix}/`}
        value={state.urlSuffix}
        onInput={(e: any) => {
          setState({
            ...state,
            urlSuffix: e.target.value.replaceAll(/[^a-zA-Z0-9-]/g, '').toLowerCase(),
          });
        }}
      />
      <Field name="color" title="Color">
        <ColorPalette
          color={state.color as PaletteColor}
          onPick={(c) =>
            setState({
              ...state,
              color: c,
            })
          }
        ></ColorPalette>
      </Field>
      <EventLocationInputs
        state={state}
        onChange={(locationState) => setState((s) => ({ ...s, ...locationState }))}
        hasZoom={state.hasZoom}
        zoomOauthUrl={state.zoomOauthUrl}
      />
      <Field class="w-fit" name="availabilityId" title="Availability">
        <div>
          <Dropdown
            hideDownIcon
            noPadding
            position="left-0 top-full"
            renderMenu={() => (
              <div class="flex flex-col min-w-80">
                {state.availability.map((a) => (
                  <MenuItem
                    key={a.id}
                    regularCase
                    onClick={(e) => {
                      e.preventDefault();
                      setState((s) => ({ ...s, availabilityId: a.id }));
                    }}
                  >
                    <AvailabilityItem availability={a} timezone={user.timezone} />
                  </MenuItem>
                ))}
                <MenuItem
                  regularCase
                  onClick={async () => {
                    const result = await showAvailabilityModal();
                    if (result) {
                      setState((s) => ({
                        ...s,
                        availabilityId: result.id,
                        availability: [...s.availability, result],
                      }));
                    }
                  }}
                >
                  <span class="flex gap-3 items-center">
                    <IcoPlus />
                    Create new availability window
                  </span>
                </MenuItem>
              </div>
            )}
          >
            <span class="border border-gray-300 rounded-sm overflow-hidden inline-flex p-3 px-4 items-center gap-6 ruz-input cursor-pointer focus:ring-1 transition-all outline-hidden bg-white hover:bg-gray-50 text-left">
              <AvailabilityItem availability={availability} timezone={user.timezone} />
              <IcoChevronDown class="size-4 opacity-75 ml-auto" />
            </span>
          </Dropdown>
        </div>
      </Field>
    </div>
  );
}
