export const moduleElId = (moduleId: UUID) => `module-${moduleId}`;

export function scrollToModule(moduleId: UUID, opts = { highlight: true }) {
  // Scroll the module nav item into view and temporarily animate it
  const el = document.querySelector(`#${moduleElId(moduleId)}`);
  if (!el) {
    return;
  }
  el.scrollIntoView({ block: 'center', behavior: 'smooth' });
  if (opts.highlight) {
    el.classList.remove('an-highlight');
    setTimeout(() => {
      el.classList.add('an-highlight');
      setTimeout(() => el.classList.remove('an-highlight'), 500);
    });
  }
}
