import { Button } from '@components/buttons';
import { Component } from 'preact';

export class ErrHandler extends Component<
  {
    onRemove?(): void;
  },
  any
> {
  static getDerivedStateFromError(error: any) {
    return { error };
  }
  componentDidCatch(error: any) {
    console.error(error);
    this.setState({ error });
  }
  render() {
    if (this.state?.error) {
      const { onRemove } = this.props;
      return (
        <div
          class={`bg-gray-50 ${
            onRemove ? 'border-4 border-red-500' : ''
          } text-gray-500 p-4 text-center relative z-10`}
        >
          An error occurred while rendering this section.
          {onRemove && (
            <Button class="ml-2 text-red-600" onClick={onRemove}>
              Remove section.
            </Button>
          )}
        </div>
      );
    }
    return this.props.children;
  }
}
