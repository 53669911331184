import dayjs from 'dayjs';
import { IcoCheckCircle, IcoGift } from '@components/icons';
import { EmptyPage } from '@components/empty-page';
import { FullCourse } from 'server/types';
import { rpx } from 'client/lib/rpx-client';
import { useAsyncData } from 'client/lib/hooks';
import { LoadingIndicator } from '@components/loading-indicator';
import { URLS } from 'shared/urls';
import { Case } from '@components/conditional';
import { BtnCopy, Button } from '@components/buttons';

interface Props {
  course: FullCourse;
}

const store = rpx.manageStudents;

export function GiftsTable({ course }: Props) {
  const { data: gifts, isLoading } = useAsyncData(
    () => store.getGifts({ courseId: course.id }),
    [course.id],
  );

  if (isLoading || !gifts) {
    return <LoadingIndicator />;
  }

  return (
    <Case
      when={gifts.length > 0}
      fallback={
        <EmptyPage Ico={IcoGift} description="There are no gifts for this course." title="Gifts" />
      }
    >
      <table class="mb-4 text-sm table-auto overflow-x w-full">
        <tr>
          <th>Recipient</th>
          <th>Sender</th>
          <th>Price</th>
          <th>Date</th>
          <th colspan={2}>Status</th>
        </tr>
        {gifts.map((gift) => (
          <tr key={gift.id}>
            <td class="table-cell">
              {gift.email}
              {!!gift.toUserId && (
                <Button
                  class="block mt-1"
                  href={URLS.guide.student({
                    courseId: course.id,
                    userId: gift.toUserId,
                  })}
                >
                  View student
                </Button>
              )}
            </td>
            <td class="table-cell">
              <Case when={gift.sender.isMember} fallback={gift.sender.email}>
                <a
                  class="text-indigo-600 block mt-1"
                  href={URLS.guide.student({
                    courseId: course.id,
                    userId: gift.sender.id,
                  })}
                >
                  {gift.sender.name}
                </a>
              </Case>
            </td>
            <td class="table-cell">
              <Button
                class="block mt-1"
                href={URLS.guide.price({
                  courseId: course.id,
                  priceId: gift.price.id,
                })}
              >
                {gift.price.name}
              </Button>
            </td>
            <td class="table-cell">{dayjs(gift.createdAt).format('MMM D, YYYY')}</td>
            <td>
              {!gift.toUserId && <span class="text-gray-500">Pending</span>}
              {!!gift.toUserId && (
                <span class="text-green-600 flex items-center">
                  <IcoCheckCircle class="text-green-500 bg-white rounded-full w-4 h-4 mr-2" />
                  Accepted
                </span>
              )}
            </td>
            <td class="text-right">
              {gift.url && (
                <BtnCopy
                  class="flex-row-reverse bg-gray-100 text-gray-600 rounded-md p-1 px-2 hover:bg-gray-200 hover:text-gray-700"
                  value={gift.url}
                  copiedText="Copied"
                >
                  Copy Link
                </BtnCopy>
              )}
            </td>
          </tr>
        ))}
      </table>
    </Case>
  );
}
