/**
 * This file contains the logic for managing global notifications (e.g. the little
 * notifications that slide in from the right).
 */

import { IcoCheckCircle, IcoExclamation, IcoX } from '@components/icons';
import { genId } from 'client/utils/gen-id';
import { useEffect } from 'preact/hooks';
import { Button } from '@components/buttons';
import { signal } from '@preact/signals';
import { ComponentChildren } from 'preact';

type PublicToastItem = {
  type: 'ok' | 'warn';
  title: ComponentChildren;
  message: ComponentChildren;
  timeout?: number;
};

type ToastItem = PublicToastItem & {
  id: number;
  timeout: number;
  removing: boolean;
};

const toastStack = signal<ToastItem[]>([]);

export const showToast = (item: PublicToastItem) => {
  toastStack.value = [
    ...toastStack.value,
    {
      ...item,
      id: genId(),
      timeout: Date.now() + (item.timeout || 4000),
      removing: false,
    },
  ];
};

export function Toaster() {
  if (!toastStack.value.length) {
    return null;
  }

  return <ToastItems />;
}

function remove(item: ToastItem) {
  toastStack.value = toastStack.value.filter((x) => x.id !== item.id);
}

function beginRemove(item: ToastItem) {
  if (!item.removing) {
    item.removing = true;
    // We'll remove the element once it's animated out, but we'll also
    // remove it after 0.25 seconds, if the animation complete never fires.
    item.timeout = Date.now() + 250;
    toastStack.value = [...toastStack.value];
  }
}

function ToastItems() {
  useEffect(() => {
    let timeout = setTimeout(function removeExpired() {
      const now = Date.now();
      const removing = toastStack.value.find((x) => !x.removing && x.timeout < now);
      if (removing) {
        beginRemove(removing);
      }
      // Fallback in case the animation complete thing didn't work
      const expired = toastStack.value.find((x) => x.removing && x.timeout < now);
      if (expired) {
        remove(expired);
      }
      timeout = setTimeout(removeExpired, 1000);
    }, 1000);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <div class="fixed right-6 top-6 z-50 flex flex-col-reverse w-80 md:w-96">
      {toastStack.value.map((item) => (
        <div
          key={item.id}
          class={`bg-white shadow-lg rounded-lg ${
            item.removing ? 'an-slide-out-right' : 'an-fade-in-left'
          } overflow-hidden ring ring-gray-800/5 p-4 mb-2`}
          onAnimationEnd={(e) => {
            if (e.animationName === 'an-slide-out-right') {
              remove(item);
            }
          }}
        >
          <div class="flex items-start gap-4">
            <div class="shrink-0">
              {item.type === 'warn' ? (
                <IcoExclamation class="size-6 text-red-500" />
              ) : (
                <IcoCheckCircle class="size-6 text-green-400" />
              )}
            </div>
            <div class="grow">
              <h4 class="text-sm leading-5 font-medium text-gray-900">{item.title}</h4>
              <p class="text-sm leading-5 text-gray-500">{item.message}</p>
            </div>
            <div class="shrink-0">
              <Button
                class="inline-flex text-gray-400 focus:outline-hidden"
                onClick={() => beginRemove(item)}
              >
                <IcoX class="size-5" />
              </Button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
