/**
 * A basic internationalization helper.
 */

import { Terminology, TerminologyKeys, Translation } from 'server/types';
import { defaultTerminology } from 'shared/terminology';
import { capitalizeFirst } from 'shared/utils';
import { IntlFn } from './types';

// Used to replace "Hello, {name}!" the phrases.
const templateRe = /{([^}]+)}/g;

// Used to handle the split function `intl.split('Hello <>world</> n stuff.')`
const tagRegex = /<\/?>/g;

export interface PluginContext {
  translation: Translation;
  propName: string;
  pluginName: string;
  args: any;
  params: string[];
  value: any;
}

export type Plugin = (ctx: PluginContext) => string;

export type Internationalize = IntlFn & {
  split(s: string, args?: any): string[];
};

type CompiledFn = (args?: any) => string;

function removeDisambiguationPrefix(rule: string): string {
  const prefix = ':::';
  const i = rule.indexOf(prefix);
  if (i < 0) {
    return rule;
  }
  return rule.slice(i + prefix.length);
}

/**
 * Make a function that will return the internationalized phrase. The created
 * function should always be called intl so that the source parsing tool will
 * find all phrases.
 */
export function internationalize(
  translation: Translation,
  plugins: Record<string, Plugin>,
  terminology: Terminology = defaultTerminology,
) {
  const { phrases } = translation;
  // It's pretty expensive to translate rules every time they're requested,
  // and we do this many times per render loop, so instead we "compile" rules
  // into efficient functions and cache them here.
  const cache: Record<string, Plugin> = {};

  // Filter out non-custom terminology values
  const customTerminology = Object.keys(terminology).filter((k) => {
    const key = k as TerminologyKeys;

    // Filter out Capital keys
    if (key !== key.toLowerCase()) {
      return false;
    }

    const value = terminology[key];
    return !!value && value !== defaultTerminology[key];
  });

  let terminologyMatcher: RegExp | undefined;
  if (customTerminology.length > 0) {
    // Plurals should come first in the regex, so that they are matched first.
    const sorted = customTerminology.sort((a, b) => b.length - a.length);
    // Match custom terminology keys with a word boundary
    terminologyMatcher = new RegExp(`\\b(?:${sorted.join('|')})\\b`, 'gi');
  }

  const compile = (rule: string): CompiledFn => {
    // Odd items are template items
    // "{name} and {dob}" becomes ["", "name", " and ", "dob", ""]
    rule = removeDisambiguationPrefix(rule);
    const pieces = rule.split(templateRe);
    const templateFn = (s: string): CompiledFn => {
      const [propAndType, ...pipeNames] = s.split('|').map((x) => x.trim());
      // name:string becomes propName: name
      const [propName] = propAndType.split(':').map((x) => x.trim());
      const pipes = pipeNames.map((x) => {
        const [pluginName, ...params] = x.split(/\s+/g);
        const plugin = plugins?.[pluginName];
        if (!plugin) {
          return (ctx: PluginContext) => ctx.value;
        }
        return (ctx: PluginContext) => {
          ctx.pluginName = pluginName;
          ctx.params = params;
          return plugin(ctx);
        };
      });
      return (args) => {
        const ctx: PluginContext = {
          translation,
          propName,
          args,
          pluginName: '',
          params: [],
          value: args?.[propName],
        };
        pipes.forEach((pipe) => {
          ctx.value = applyTerminology(pipe(ctx));
        });
        return ctx.value;
      };
    };
    const applyTerminology = (str: string) => {
      if (!terminologyMatcher) {
        return str;
      }

      return str.replace(terminologyMatcher, (matched) => {
        const isUpperCase = matched[0] === matched[0].toUpperCase();
        const replaced = (terminology as any)[matched.toLowerCase()];
        return isUpperCase ? capitalizeFirst(replaced) : replaced;
      });
    };

    const fns = pieces.map((s, i) => {
      if (i % 2) {
        return templateFn(s);
      }
      return () => applyTerminology(s);
    });

    return (args) => fns.map((f) => f(args)).join('');
  };

  function baseIntl(phrase: string, args?: any): string {
    const fn = cache[phrase] || compile(phrases[phrase] || phrase);
    cache[phrase] = fn;
    return fn(args);
  }

  const result = baseIntl as Internationalize;

  /**
   * Split the string on <> </> delimiters so that we can extract parts of
   * the phrase for styling and other purposes.
   */
  result.split = (phrase, args) => {
    const s = phrases[phrase] || phrase;
    return s.split(tagRegex).map((x) => (baseIntl as any)(x, args));
  };

  return result;
}
