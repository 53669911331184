import { Dispatch, StateUpdater, useMemo, useState } from 'preact/hooks';
import { IcoChevronDown, IcoChevronRight, IcoFilter, IcoGrid, IcoList } from '@components/icons';
import { AutoPager } from '../../components/auto-pager';
import { BtnSecondary, Button } from '@components/buttons';
import { Course, Filters } from './types';
import { useCurrentTenant, useCurrentUser } from '@components/router/session-context';
import { SearchBox } from '@components/search-box';
import { EmptyScreen } from './empty-screen';
import { intl, useIntl } from 'shared/intl/use-intl';
import { useLocalStorageState } from 'client/lib/hooks';
import { filterCourses } from './utils';
import { ComponentChildren } from 'preact';
import { partition } from 'shared/utils';
import { Dropdown, MenuItem } from '@components/dropdown';

export type ListType = 'grid' | 'list';

type Props = {
  courses: Course[];
  noBorder?: boolean;
  renderItem: (props: {
    course: Course;
    listType: ListType;
    searchTerm: string;
    displayDate: 'createdAt' | 'lastOpenedAt';
  }) => JSX.Element;
  type: 'courses' | 'bundles' | 'products';
};

const PAGE_SIZE = 20;

function RuzukuPromoLink() {
  const intl = useIntl();
  const tenant = useCurrentTenant();
  const user = useCurrentUser();

  if (user?.level !== 'student' || !tenant.isCore) {
    return null;
  }

  return (
    <div>
      <a
        class="flex flex-col p-6 rounded-lg border hover:border-indigo-600"
        href="https://www.ruzuku.com/?utm_source=student&utm_content=mycourses&utm_medium=link"
      >
        <span class="text-gray-600 mb-2">{intl('Curious about creating your own course?')}</span>
        <span>{intl('Learn more about how Ruzuku can help ⤑')}</span>
      </a>
    </div>
  );
}

function RenderCourses({
  listType,
  courses,
  renderItem,
  searchTerm,
  displayDate,
  children,
}: {
  listType: ListType;
  courses: Course[];
  searchTerm: string;
  renderItem: Props['renderItem'];
  displayDate: 'createdAt' | 'lastOpenedAt';
  children?: ComponentChildren;
}) {
  return (
    <div
      class={
        listType === 'grid'
          ? 'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8'
          : 'flex flex-col gap-4'
      }
    >
      {courses.map((course) =>
        renderItem({
          course,
          searchTerm,
          listType,
          displayDate,
        }),
      )}
      {children}
    </div>
  );
}

function StudentFilters({
  filters,
  setFilters,
  searchTerm,
  setSearchTerm,
  type,
}: {
  filters: Filters;
  setFilters: Dispatch<StateUpdater<Filters>>;
  searchTerm: string;
  setSearchTerm: Dispatch<string>;
  type: Props['type'];
}) {
  return (
    <section>
      <div class="flex items-center">
        <Dropdown
          hideDownIcon
          triggerClass="bg-gray-50 text-gray-600 border border-r-0 border-gray-300 rounded-full rounded-r-none px-4 whitespace-nowrap hidden md:inline-flex items-center gap-2 py-2"
          renderMenu={() => (
            <div class="flex flex-col p-2 pb-0">
              <MenuItem
                onClick={() =>
                  setFilters((s) => ({
                    ...s,
                    sort: 'lastOpenedAt',
                  }))
                }
              >
                {intl('Recently opened by me')}
              </MenuItem>
              <MenuItem
                onClick={() =>
                  setFilters((s) => ({
                    ...s,
                    sort: 'title',
                    sortDirection: -1,
                  }))
                }
              >
                {intl('Title')}
              </MenuItem>
            </div>
          )}
        >
          <IcoFilter />
          {filters.sort === 'title' ? intl('Sort by title') : intl('Sort by recently opened')}
        </Dropdown>
        <SearchBox
          hidePrefix
          class="ruz-input pl-4 text-sm rounded-full md:rounded-l-none"
          onTermChange={(term) => setSearchTerm(term.toLowerCase())}
          placeholder={intl('Find a {model:string}', {
            model:
              type === 'bundles'
                ? intl('bundle')
                : type === 'products'
                ? intl('product')
                : intl('course'),
          })}
          value={searchTerm}
        />
      </div>
    </section>
  );
}

function GuideFilters({
  filters,
  setFilters,
  searchTerm,
  setSearchTerm,
  type,
}: {
  filters: Filters;
  setFilters: Dispatch<StateUpdater<Filters>>;
  searchTerm: string;
  setSearchTerm: Dispatch<string>;
  type: Props['type'];
}) {
  const intl = useIntl();

  return (
    <section class="bg-gray-50 p-8 mt-2 rounded-xl">
      <div class="flex flex-col md:flex-row gap-8">
        <FilterOptions
          field="show"
          currentValue={filters.show}
          setFilters={setFilters}
          {...filterDefinitions.show}
        />
        <FilterOptions
          field="sort"
          currentValue={filters.sort}
          setFilters={setFilters}
          {...filterDefinitions.sort}
        />
        <FilterOptions
          field="status"
          currentValue={filters.status}
          setFilters={setFilters}
          {...filterDefinitions.status}
        />
        {type !== 'bundles' && (
          <FilterOptions
            field="type"
            currentValue={filters.type}
            setFilters={setFilters}
            {...filterDefinitions.type}
          />
        )}
      </div>
      <div class="flex pt-4 items-center gap-2">
        <SearchBox
          hidePrefix
          class="ruz-input w-1/2 text-sm rounded-full"
          onTermChange={(term) => setSearchTerm(term.toLowerCase())}
          placeholder={intl('Filter by {model:string} title or ID', {
            model: type === 'bundles' ? 'bundle' : type === 'products' ? 'product' : 'course',
          })}
          value={searchTerm}
        />
      </div>
    </section>
  );
}

export function CourseList(props: Props) {
  const intl = useIntl();
  const { type, courses } = props;
  const { terminology } = useCurrentTenant();
  const [filters, setFilters] = useState(defaultFilters);
  const [searchTerm, setRawSearchTerm] = useState('');
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const setSearchTerm = (term: string) => {
    setRawSearchTerm(term);
    setPageSize(PAGE_SIZE);
  };
  const [listType, setListType] = useLocalStorageState<ListType>('mycourses.listType', 'grid');
  const currentUser = useCurrentUser()!;
  const isStudentOnly = currentUser.level === 'student';

  const filtered = useMemo(() => {
    return filterCourses({
      courses,
      filters,
      type,
    }).sort((a, b) => {
      const v1 = a[filters.sort];
      const v2 = b[filters.sort];
      return v1 === v2 ? 0 : v1 > v2 ? -filters.sortDirection : filters.sortDirection;
    });
  }, [courses, filters, type, searchTerm]);

  const searched =
    searchTerm.length === 0 ? filtered : filtered.filter((c) => c.search.includes(searchTerm));

  const hasFilters = !areDefaultFilters(filters) || searchTerm.length > 0;
  const visibleCourses = searched.slice(0, pageSize);
  const hasMorePages = visibleCourses.length !== searched.length;
  const [archivedCourses, activeCourses] = partition((x) => x.isArchived, visibleCourses);

  return (
    <>
      <header class={`mb-10 ${props.noBorder ? '' : 'border-b pb-10'}`}>
        <div class="flex justify-between gap-8">
          {!isStudentOnly && (
            <BtnSecondary
              class="rounded-full px-4 bg-gray-50 text-gray-600"
              onClick={() => setIsFilterOpen(!isFilterOpen)}
            >
              <IcoFilter class="mr-2" />
              {intl('Filters')}
              {isFilterOpen ? <IcoChevronDown class="ml-2" /> : <IcoChevronRight class="ml-2" />}
            </BtnSecondary>
          )}
          {isStudentOnly && (
            <StudentFilters
              filters={filters}
              setFilters={setFilters}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              type={type}
            />
          )}
          <nav class="hidden lg:flex items-center justify-end gap-2">
            <Button
              data-tooltip="Grid View"
              class={`${
                listType === 'grid' ? 'bg-gray-100' : ''
              } rounded inline-flex h-8 w-8 items-center justify-center relative hover:bg-gray-200`}
              onClick={() => setListType('grid')}
            >
              <span class="flex gap-3 items-center">
                <IcoGrid />
              </span>
            </Button>
            <Button
              data-tooltip="List View"
              class={`${
                listType === 'list' ? 'bg-gray-100' : ''
              } rounded inline-flex h-8 w-8 items-center justify-center relative hover:bg-gray-200`}
              onClick={() => setListType('list')}
            >
              <span class="flex gap-3 items-center">
                <IcoList />
              </span>
            </Button>
          </nav>
        </div>
        {!isStudentOnly && isFilterOpen && (
          <GuideFilters
            filters={filters}
            setFilters={setFilters}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            type={type}
          />
        )}
      </header>
      {!filtered.length && (
        <EmptyScreen
          title={
            type === 'bundles'
              ? intl('No Bundles')
              : type === 'products'
              ? intl('No Products')
              : intl('No Courses')
          }
          subtext={
            isStudentOnly
              ? intl("When you register for {type:string}, they'll show up here.", {
                  type,
                })
              : hasFilters
              ? intl('No {type:string} found with the current filters.', {
                  type,
                })
              : intl("When you create {type:string}, they'll show up here.", {
                  type,
                })
          }
        />
      )}
      <section class="flex flex-col gap-10">
        <RenderCourses
          courses={activeCourses}
          listType={listType}
          searchTerm={searchTerm}
          renderItem={props.renderItem}
          displayDate={filters.sort === 'createdAt' ? 'createdAt' : 'lastOpenedAt'}
        >
          {!hasFilters && type === 'courses' && <RuzukuPromoLink />}
        </RenderCourses>

        {!!archivedCourses.length && (
          <>
            <h2 class="font-semibold">Archived {terminology.courses}</h2>
            <RenderCourses
              courses={archivedCourses}
              listType={listType}
              searchTerm={searchTerm}
              renderItem={props.renderItem}
              displayDate={filters.sort === 'createdAt' ? 'createdAt' : 'lastOpenedAt'}
            >
              {!hasFilters && type !== 'bundles' && <RuzukuPromoLink />}
            </RenderCourses>
          </>
        )}
      </section>
      {hasMorePages && <AutoPager onLoadMore={() => setPageSize((p) => p + PAGE_SIZE)} />}
    </>
  );
}

const filterDefinitions = {
  show: {
    title: 'Show',
    options: [
      { title: intl('All'), value: 'all' },
      { title: intl('Teaching'), value: 'teaching' },
      { title: intl('Attending'), value: 'attending' },
      { title: intl('Archived'), value: 'archived' },
    ],
  },
  sort: {
    title: 'Sort by',
    options: [
      { title: intl('Recently opened by me'), value: 'lastOpenedAt' },
      { title: intl('Created at'), value: 'createdAt' },
      { title: intl('Title'), value: 'search', sortDirection: -1 },
      { title: intl('Number of students'), value: 'numStudents' },
    ],
  },
  status: {
    title: 'Status',
    options: [
      { title: intl('All'), value: 'all' },
      { title: intl('Draft'), value: 'draft' },
      { title: intl('Signups open'), value: 'published' },
      { title: intl('Closed'), value: 'closed' },
    ],
  },
  type: {
    title: 'Type',
    options: [
      { title: intl('All'), value: 'all' },
      { title: intl('Drip (Individual)'), value: 'ondemand' },
      { title: intl('Full Access'), value: 'openaccess' },
      { title: intl('Drip (Calendar)'), value: 'scheduled' },
    ],
  },
};

const defaultFilters: Filters = {
  sort: 'lastOpenedAt',
  sortDirection: 1,
  status: 'all',
  type: 'all',
  show: 'all',
};

function FilterOptions<K extends keyof Filters>({
  title,
  field,
  options,
  currentValue,
  setFilters,
}: {
  title: string;
  field: K;
  currentValue: Filters[K];
  options: Array<{ title: string; value: any; sortDirection?: number }>;
  setFilters: Dispatch<StateUpdater<Filters>>;
}) {
  return (
    <div class="flex flex-col space-y-2 text-sm min-w-40">
      <h3 class="uppercase text-xs font-bold tracking-wider text-gray-500 border-b pb-2">
        {title}
      </h3>
      {options.map((o) => (
        <Button
          key={o.value}
          class={`${o.value === currentValue ? 'font-bold' : ''} text-left`}
          onClick={() =>
            setFilters((s) => ({
              ...s,
              [field]: o.value,
              sortDirection: o.sortDirection || s.sortDirection,
            }))
          }
        >
          {o.title}
        </Button>
      ))}
    </div>
  );
}

function areDefaultFilters(filters: Filters) {
  return (
    filters.show === defaultFilters.show &&
    filters.sort === defaultFilters.sort &&
    filters.status === defaultFilters.status &&
    filters.type === defaultFilters.type
  );
}
