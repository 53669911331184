import { ManualDom } from '@components/manual-dom';
import { useMinidoc } from '@components/minidoc';
import { mediaCard, mediaMiddleware } from '@components/media-card';
import { cardMiddleware, defaultToolbarActions, minidocToolbar, placeholder } from 'minidoc-editor';
import { toolbarDropdown } from '@components/toolbar-dropdown';
import { useBasicAutosaver } from '@components/autosaver';
import { useState } from 'preact/hooks';
import { LoadingIndicator } from '@components/loading-indicator';
import { SaveStatus } from '@components/save-status';
import { rpx } from 'client/lib/rpx-client';
import { showError } from '@components/app-error';
import { EditorWrapper } from '@components/minidoc/minidoc-root';
import { useIntl } from 'shared/intl/use-intl';

interface Props {
  userId: UUID;
  notes: string;
}

export function AdminNotes({ userId, notes }: Props) {
  const intl = useIntl();
  const [state, setState] = useState({
    content: notes,
  });
  const [isSaving, setIsSaving] = useState(false);
  const editor = useMinidoc({
    doc: state.content,
    middleware: () => [
      placeholder('Notes for superadmins'),
      minidocToolbar([
        ...defaultToolbarActions.filter((x) => x.id !== 'h1'),
        toolbarDropdown({
          mediaOnly: true,
          intl,
        }),
      ]),
      cardMiddleware([mediaCard]),
      mediaMiddleware(),
    ],
    onChange: (doc) => {
      setState({
        content: doc,
      });
    },
  });

  const autosaver = useBasicAutosaver(state, async (val) => {
    try {
      setIsSaving(true);
      await rpx.admin.upsertTenantUserNotes({
        userId,
        notes: val.content,
      });
    } catch (err) {
      showError(err);
    } finally {
      setIsSaving(false);
    }
  });

  return (
    <div class="border rounded-lg">
      {isSaving && <LoadingIndicator />}
      <nav class="flex items-center px-8">
        <div class="grow">
          <ManualDom el={editor.toolbar.root} />
        </div>
        <SaveStatus isDirty={autosaver.isDirty} />
      </nav>
      <div class="bg-white dark:bg-gray-900 p-4 rounded-b-lg">
        <EditorWrapper class="prose dark:prose-invert" editor={editor} />
      </div>
    </div>
  );
}
