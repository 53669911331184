import { Button } from '@components/buttons';
import { IcoDocument, IcoMail } from '@components/icons';
import { Pill } from '@components/pill';
import { Toggle } from '@components/toggle';
import { useState } from 'preact/hooks';
import { minutesToTime, minutesToTimeString } from 'shared/dateutil';
import { useIntl } from 'shared/intl/use-intl';
import { MessageEditor } from './message-editor';
import { TimeEditModal } from '@course/components/time-edit-modal';
import { Email } from 'server/types';
import { useCurrentUser } from 'client/lib/auth';
import { RpxResponse, rpx } from 'client/lib/rpx-client';
import { showError } from '@components/app-error';
import { showToast } from '@components/toaster';
import { Case } from '@components/conditional';
import { useAsyncState } from 'client/lib/hooks';
import { LoadingIndicator } from '@components/loading-indicator';
import { showDialog } from '@components/dialog';
import { PreviewModal } from '@components/messages';

type Props = {
  eventTypeId: string;
};

type Message = RpxResponse<typeof rpx.ruzcalEventTypes.getMessages>[0];

function MessageRow({
  eventTypeId,
  message,
  onUpdate,
}: {
  eventTypeId: string;
  message: Message;
  onUpdate: (message: Message) => void;
}) {
  const intl = useIntl();
  const user = useCurrentUser();
  const { isEnabled } = message;

  const [isEditingThreshold, setIsEditingThreshold] = useState(false);
  const [isSendingPreview, setIsSendingPreview] = useState(false);
  const [previewEmail, setPreviewEmail] = useState<Email | undefined>(undefined);

  const time = minutesToTime(message.offsetMins);

  async function displayEmailPreview() {
    setIsSendingPreview(true);
    try {
      const result = await rpx.ruzcalEventTypes.getMessagePreview({
        eventTypeId,
        type: message.type,
      });
      setPreviewEmail(result);
    } catch (err) {
      showError(err);
    } finally {
      setIsSendingPreview(false);
    }
  }

  async function sendPreview() {
    setIsSendingPreview(true);
    try {
      await rpx.ruzcalEventTypes.sendMessagePreview({
        eventTypeId,
        type: message.type,
      });
      showToast({
        type: 'ok',
        title: 'Message sent',
        message: 'Event type message preview message sent successfully.',
      });
      setPreviewEmail(undefined);
    } catch (err) {
      showError(err);
    } finally {
      setIsSendingPreview(false);
    }
  }

  async function saveMetadata(updates: { isEnabled: boolean; offsetMins: number }) {
    try {
      await rpx.ruzcalEventTypes.setMessageMetadata({
        eventTypeId,
        type: message.type,
        isEnabled: updates.isEnabled,
        offsetMins: updates.offsetMins,
      });
      onUpdate({
        ...message,
        isEnabled: updates.isEnabled,
        offsetMins: updates.offsetMins,
      });
    } catch (err) {
      showError(err);
    }
  }

  return (
    <div class="border rounded-2xl relative">
      <div class={`flex w-full flex-col p-4 gap-1 transition-all text-inherit relative text-left`}>
        <header class="flex flex-col md:flex-row md:items-center md:justify-between gap-2">
          <span
            class={`flex items-center gap-1 font-semibold text-base ${
              isEnabled ? '' : 'opacity-50'
            }`}
          >
            {message.type === 'beforeEvent' ? 'Reminder' : 'Follow-up'}:{' '}
            <Button
              class="text-indigo-500 underline"
              disabled={!isEnabled}
              onClick={() => setIsEditingThreshold(true)}
            >
              {minutesToTimeString(message.offsetMins, intl)}{' '}
            </Button>
            {message.type === 'beforeEvent' ? 'before event starts' : 'after event ends'}
            {isEditingThreshold && (
              <TimeEditModal
                days={time.days}
                hours={time.hours}
                minutes={time.minutes}
                onHide={() => setIsEditingThreshold(false)}
                onApply={(newThreshold) => {
                  setIsEditingThreshold(false);
                  saveMetadata({ ...message, offsetMins: newThreshold });
                }}
              />
            )}
          </span>
          <label class="flex items-center gap-2 cursor-pointer">
            {isEnabled && <Pill color="green">Enabled</Pill>}
            {!isEnabled && <Pill color="gray">Disabled</Pill>}
            <Toggle
              checked={isEnabled}
              onClick={() =>
                saveMetadata({
                  ...message,
                  isEnabled: !isEnabled,
                })
              }
            />
          </label>
        </header>
        {isEnabled && (
          <div class="grid grid-cols-2 gap-2 sm:flex sm:gap-8 items-center">
            <span class="inline-flex items-center gap-2">
              <IcoMail /> Email to attendees
            </span>
            <span class="inline-flex items-center gap-2 capitalize">
              <IcoDocument /> {message.isCustomized ? 'Custom' : 'Default'} content
            </span>
          </div>
        )}
      </div>
      {isEnabled && (
        <div class="p-2 border-t rounded-b-lg">
          {previewEmail && (
            <PreviewModal
              email={previewEmail}
              recipientsText={user?.email || ''}
              isSending={isSendingPreview}
              onCancel={() => setPreviewEmail(undefined)}
              onSend={sendPreview}
            />
          )}
          <MessageEditor
            title={message.title}
            content={message.content}
            isSending={false}
            onSave={async (content, title) => {
              try {
                await rpx.ruzcalEventTypes.setCustomMessageContent({
                  eventTypeId,
                  type: message.type,
                  content,
                  title,
                });
                onUpdate({ ...message, title, content, isCustomized: true });
              } catch (err) {
                showError(err);
              }
            }}
            onSend={displayEmailPreview}
          />
          <Case when={message.isCustomized}>
            <p class="italic text-right mb-4">
              You've customized this template.{' '}
              <Button
                class="text-blue-400 font-bold"
                onClick={async () => {
                  const isConfirmed = await showDialog({
                    mode: 'warn',
                    title: 'Reset message template',
                    children: `Are you sure you want to reset the message template to the default version?`,
                    confirmButtonText: 'Reset',
                  });
                  if (!isConfirmed) {
                    return;
                  }

                  try {
                    await rpx.ruzcalEventTypes.setCustomMessageContent({
                      eventTypeId,
                      type: message.type,
                      content: undefined,
                      title: undefined,
                    });
                    location.reload();
                  } catch (err) {
                    showError(err);
                  }
                }}
              >
                Click here
              </Button>{' '}
              to reset it to the default version.
            </p>
          </Case>
        </div>
      )}
    </div>
  );
}

export function MessagesTab({ eventTypeId }: Props) {
  const [messages, setMessages] = useAsyncState(async () => {
    const result = await rpx.ruzcalEventTypes.getMessages({
      eventTypeId,
    });
    return result;
  }, []);

  if (!messages) {
    return <LoadingIndicator />;
  }

  return (
    <div class="flex flex-col gap-8">
      {messages.map((message) => (
        <MessageRow
          key={message.type}
          message={message}
          eventTypeId={eventTypeId}
          onUpdate={(newMessage) => {
            setMessages((m) => {
              if (!m) {
                return m;
              }
              return m.map((m) => (m.type === newMessage.type ? newMessage : m));
            });
          }}
        />
      ))}
    </div>
  );
}
