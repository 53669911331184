/* eslint @typescript-eslint/ban-types: 0 */
// eslint throws errors because of Symbols here.
const re = /["'&<>]/g;
const charMap: { [k: string]: string } = {
  '"': '&quot;',
  '&': '&amp;',
  "'": '&#39;',
  '<': '&lt;',
  '>': '&gt;',
};
const isTrusted = Symbol();

interface TrustedString {
  isTrusted: Symbol;
  raw: string;
}

export function esc(s: string | { isTrusted: Symbol; raw: string }) {
  if (s == null) {
    return '';
  }
  if (typeof s === 'object' && s.isTrusted === isTrusted) {
    return s.raw;
  }
  return String(s).replace(re, (c) => charMap[c]);
}

/**
 * Escape values in a template literal to prevent XSS. Use htm.trust
 * to pass a value through unescaped.
 *
 * Usage: htm`<h1>hello ${name}</h1>`
 */
export function htm(safeStrs: TemplateStringsArray, ...unsafeStrs: Array<string | TrustedString>) {
  const result = [];
  for (let i = 0; i < safeStrs.length; ++i) {
    result.push(esc(unsafeStrs[i - 1]));
    result.push(safeStrs[i]);
  }
  return result.join('');
}

/**
 * Pass the specified value through unescaped.
 */
export const rawValue = (raw: string) => ({ raw, isTrusted });
