import { evt } from 'client/lib/app-evt';
import { User } from 'server/types';

type NewMessageCallbackArgs = {
  user: Pick<User, 'id' | 'name' | 'profilePhotoUrl'>;
  courseId: UUID;
};

const SEND_MESSAGE_EVENT = '$send_private_message';
const CREATE_CHAT_ROOM_EVENT = '$create_chat_room';
const COURSE_CHAT_TOGGLED_EVENT = '$course_chat_toggled_event';

export function sendPrivateMessage(opts: NewMessageCallbackArgs) {
  evt.emit(SEND_MESSAGE_EVENT, opts);
}

export function onNewMessageRequest(callback: (args: NewMessageCallbackArgs) => void) {
  return evt.on((name, args: NewMessageCallbackArgs) => {
    if (name === SEND_MESSAGE_EVENT) {
      callback(args);
    }
  });
}

type NewRoomCallbackArgs = {
  user: NewMessageCallbackArgs['user'];
  courseId: UUID;
  roomId: UUID;
  message: {
    content: string;
    createdAt: Date;
  };
};

export function invokeNewChatRoomEvent(opts: NewRoomCallbackArgs) {
  evt.emit(CREATE_CHAT_ROOM_EVENT, opts);
}

export function onNewChatRoomCreated(callback: (args: NewRoomCallbackArgs) => void) {
  return evt.on((name, args: NewRoomCallbackArgs) => {
    if (name === CREATE_CHAT_ROOM_EVENT) {
      callback(args);
    }
  });
}

type CourseChatToggledCallbackArgs = {
  courseId: UUID;
  enabled: boolean;
};

export function invokeCourseChatToggledEvent(opts: CourseChatToggledCallbackArgs) {
  evt.emit(COURSE_CHAT_TOGGLED_EVENT, opts);
}

export function onCourseChatToggled(callback: (args: CourseChatToggledCallbackArgs) => void) {
  return evt.on((name, args: CourseChatToggledCallbackArgs) => {
    if (name === COURSE_CHAT_TOGGLED_EVENT) {
      callback(args);
    }
  });
}
