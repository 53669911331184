import { IcoClock } from '@components/icons';
import { ComponentChildren } from 'preact';

export function BulletIco({
  Ico,
  children,
  multiline,
  class: className = '',
  onClick,
}: {
  Ico: typeof IcoClock;
  children: ComponentChildren;
  multiline?: boolean;
  class?: string;
  onClick?(): void;
}) {
  return (
    <div class={`flex ${multiline ? '' : 'items-center'} gap-3 ${className}`} onClick={onClick}>
      <Ico strokeWidth="2" class={`shrink-0 size-5 opacity-75 ${multiline ? 'mt-0.5' : ''}`} />
      <p>{children}</p>
    </div>
  );
}
