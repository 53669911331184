import { FormGroup, InputLabel } from '@components/async-form';
import { useState } from 'preact/hooks';
import { rpx } from 'client/lib/rpx-client';
import { Meeting } from 'server/types';
import { DateInputWithTimezone } from '@components/date-picker';
import { showError } from '@components/app-error';
import { useRouteParams } from '@components/router';
import { ModalForm } from '@components/modal-form';

interface Props {
  meeting: Pick<Meeting, 'id' | 'title' | 'scheduledAt'>;
  onSuccess: (newMeeting: Meeting) => void;
  hide(): void;
}

interface FormData {
  title: string;
  scheduledAt?: string;
}

export function DuplicateModal({ meeting, onSuccess, hide }: Props) {
  const { courseId } = useRouteParams();
  const [scheduledAt, setScheduledAt] = useState<Date | undefined>();

  async function duplicate({ title }: FormData) {
    try {
      const newMeeting = await rpx.meetings.duplicateMeeting({
        courseId,
        meetingId: meeting.id,
        title,
        scheduledAt: scheduledAt ? new Date(scheduledAt).toISOString() : undefined,
      });
      onSuccess(newMeeting);
    } catch (err) {
      showError(err);
    }
  }

  return (
    <ModalForm
      title={
        <>
          Duplicate "<strong>{meeting.title}</strong>" Meeting
        </>
      }
      confirmButtonText="Duplicate meeting"
      onClose={hide}
      onSubmit={duplicate}
    >
      <section class="flex flex-col gap-6">
        <FormGroup prop="title">
          <InputLabel>Title</InputLabel>
          <input
            type="text"
            placeholder="Meeting title"
            name="title"
            class="ruz-input"
            // @ts-ignore
            defaultValue={`Copy of ${meeting.title}`}
          />
        </FormGroup>
        <FormGroup class="mb-6" prop="scheduledAt">
          <InputLabel>Scheduled Start</InputLabel>
          <DateInputWithTimezone
            name="scheduledAt"
            includeTime
            value={scheduledAt}
            onChange={setScheduledAt}
            placeholder="Scheduled start date"
          />
        </FormGroup>
      </section>
    </ModalForm>
  );
}
