import { saveBeforeUnload } from '@components/router/before-unload';
import { isNetworkError } from 'client/lib/ajax';
import { useCtrlSaveKey } from 'client/lib/hooks';
import { serialAsync } from 'client/utils/serial-async';
import { on } from 'minidoc-editor';
import { useEffect, useMemo, useRef, useState } from 'preact/hooks';

const AUTOSAVE_INTERVAL = 2500;

export type AutosaveStatus = 'saved' | 'saving' | 'connecting';

/**
 * A little helper that wraps an async function. If the async function fails
 * due to a network issue, we report it, and retry indefinitely with a relatively
 * slow retry loop.
 */
function asyncRetryer<T>(opts: {
  save(): Promise<T>;
  onNetworkChanged(isConnected: boolean): void;
}) {
  let isConnected = true;

  // If we encounter a network failure, we'll retry in 50ms, then 75ms, then
  // 112.5 ms, etc until we've hit a max of 3s retries.
  const startingDelay = 50;
  const maxDelay = 3000;

  const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

  const setIsConnected = (val: boolean) => {
    if (val !== isConnected) {
      isConnected = val;
      opts.onNetworkChanged(val);
    }
  };

  return async function asyncRetry() {
    let sleepDuration = startingDelay;
    while (sleepDuration) {
      try {
        const result = await opts.save();
        setIsConnected(true);
        return result;
      } catch (err) {
        if (!isNetworkError(err)) {
          throw err;
        }
      }
      setIsConnected(false);
      await sleep(sleepDuration);
      sleepDuration = Math.min(maxDelay, sleepDuration * 1.5);
    }
  };
}

/**
 * A basic autosaving mechanism. When state changes, this will wait a while and then
 * invoke the save function.
 * @param state
 * @param save
 */
export function useBasicAutosaver<T>(state: T, save: (state: T) => Promise<unknown>) {
  const ref = useRef({ latestState: state, savedState: state });
  ref.current.latestState = state;
  const [result, setResult] = useState({
    isDirty: false,
    isConnected: true,
    save: async () => {},
  });
  const isDirty = useMemo(() => () => ref.current.latestState !== ref.current.savedState, []);
  const serialSave = useMemo(
    () =>
      serialAsync(
        asyncRetryer({
          save: async () => {
            if (!isDirty()) {
              return;
            }
            const saveState = ref.current.latestState;
            await save(saveState);
            ref.current.savedState = saveState;
            setResult((s) => ({ ...s, isDirty: isDirty() }));
          },
          onNetworkChanged(isConnected) {
            setResult((s) => ({ ...s, isConnected }));
          },
        }),
      ),
    [save],
  );

  // If there is a change, save it after AUTOSAVE_INTERVAL milliseconds
  // passes with no subsequent changes.
  useEffect(() => {
    if (!isDirty()) {
      return;
    }
    setResult((s) => ({ ...s, isDirty: true }));
    const timeout = setTimeout(serialSave, AUTOSAVE_INTERVAL);
    return () => clearTimeout(timeout);
  }, [state]);

  // Buffer a save when the user presses Ctrl / Cmd + S, or when the mouse moves
  // the mouse move approach is a hack to ensure that save is done before the user
  // clicks on something that will navigate them away.
  useCtrlSaveKey(serialSave);

  useEffect(
    () =>
      on(document, 'mouseover', (e: any) => {
        if (e.target.tagName === 'A') {
          serialSave();
        }
      }),
    [serialSave],
  );

  useEffect(() => {
    return saveBeforeUnload(serialSave, isDirty);
  }, []);

  useEffect(() => on(window, 'popstate', serialSave), []);

  result.save = serialSave;

  return result;
}
