import { UserProfileIcon } from '@components/avatars';
import { Button } from '@components/buttons';
import { Dropdown } from '@components/dropdown';
import { IcoCalendar, IcoList, IcoX } from '@components/icons';
import { Breadcrumbs, Crumb } from '@course/components/breadcrumbs';
import { useCurrentUser } from 'client/lib/auth';
import { useDocumentTitle } from 'client/utils/use-document-title';
import { useEsc } from 'client/utils/use-esc';
import { ComponentChildren } from 'preact';
import { useState } from 'preact/hooks';

type RootPageProps = {
  title: string;
  logo: ComponentChildren;
  nav: ComponentChildren;
  children: ComponentChildren;
  actions?: ComponentChildren;
  crumbs?: Crumb[];
  accountMenu: ComponentChildren;
};

function MobileMenu(props: { nav: ComponentChildren; onClose(): void }) {
  useEsc(props.onClose, { respectDefault: true });
  return (
    <div
      class="absolute top-full inset-x-0 animate-slide-bottom bg-white p-2 py-6 flex flex-col shadow-xl z-20"
      onClick={(e) => e.preventDefault()}
    >
      {props.nav}
    </div>
  );
}

export function RootUserMenu(props: { renderMenu(): JSX.Element }) {
  const user = useCurrentUser()!;
  return (
    <Dropdown
      triggerClass="max-w-full"
      hideDownIcon
      noPadding
      class="max-w-full"
      renderMenu={() => <div class="p-2 gap-2 flex flex-col">{props.renderMenu()}</div>}
    >
      <span class="flex items-center gap-4 grow max-w-full">
        <UserProfileIcon user={user} size="size-10 shrink-0" ring />
      </span>
    </Dropdown>
  );
}

export function RootNav(props: { children: ComponentChildren }) {
  return <nav class="p-2 flex flex-col grow gap-3">{props.children}</nav>;
}

export function RootNavlink(props: {
  href: string;
  isSelected?: boolean;
  Ico: typeof IcoCalendar;
  text: ComponentChildren;
}) {
  return (
    <a
      href={props.href}
      class={`${
        props.isSelected ? 'text-indigo-800 bg-indigo-100' : 'text-inherit'
      } p-2 px-3 font-semibold rounded-md transition-all flex items-center gap-3 hover:text-indigo-700 hover:bg-indigo-100`}
    >
      <props.Ico class="size-5 shrink-0 opacity-75" />
      {props.text}
    </a>
  );
}

export function RootPage(props: RootPageProps) {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useDocumentTitle([props.title]);

  return (
    <section class="relative isolate flex flex-col min-h-svh w-full bg-white">
      <header class="flex flex-col relative">
        {isMobileMenuOpen && (
          <MobileMenu nav={props.nav} onClose={() => setIsMobileMenuOpen(false)} />
        )}
        <nav class="lg:hidden p-3 border-b flex justify-between gap-4">
          {props.logo}
          {props.accountMenu}
        </nav>
        <nav class="lg:hidden flex items-center border-b relative z-20 font-bold text-gray-900">
          <Button
            class="inline-flex items-center gap-3 p-3"
            onClick={() => setIsMobileMenuOpen((x) => !x)}
          >
            <span class="size-6 relative">
              <IcoList
                class={`size-6 absolute top-0 left-0 transition-all ${
                  isMobileMenuOpen ? 'opacity-0' : 'opacity-75'
                }`}
              />
              <IcoX
                class={`size-6 absolute top-0 left-0 transition-all ${
                  isMobileMenuOpen ? 'opacity-75' : 'opacity-0'
                }`}
              />
            </span>
          </Button>
          <h1 class="font-normal">
            {props.crumbs && <Breadcrumbs crumbs={props.crumbs} />}
            {!props.crumbs && props.title}
          </h1>
        </nav>
      </header>

      <div class="flex grow">
        <div class="hidden lg:flex flex-col w-content min-w-64 py-4 bg-gray-50 border-r">
          <header class="px-4 mb-4">{props.logo}</header>
          {props.nav}
        </div>
        <div class="flex flex-col grow rounded-lg">
          <header class="hidden lg:flex items-center justify-between p-4 border-b gap-8">
            <h1 class="font-semibold">
              {props.crumbs && <Breadcrumbs crumbs={props.crumbs} />}
              {!props.crumbs && props.title}
            </h1>
            <div class="flex gap-8">
              <nav class="flex gap-2">{props.actions}</nav>
              {props.accountMenu}
            </div>
          </header>
          {props.children}
        </div>
      </div>
    </section>
  );
}
