import type { Dayjs } from 'dayjs';
import { useCalendarAnimation, useEventRangeLoader } from './hooks';
import { dateForView } from './date-for-view';
import dayjs from 'dayjs';
import { CalendarEvent, View } from './types';
import { CalendarNav, HeaderNav } from './header-nav';
import { Button } from '@components/buttons';
import { ruzcal } from 'shared/urls';
import { getTailwindColorClasses } from '@components/color-palette';

type Props = {
  date: Dayjs;
  gotoView(view: View, date?: Dayjs): void;
};

export function MonthView(props: Props) {
  const animation = useCalendarAnimation(props.date);
  const events = useEventRangeLoader(props.date, props.date.add(1, 'month').endOf('week'));
  const start = props.date.startOf('week');
  const days = new Array(5 * 7).fill(0).map((_, i) => start.add(i, 'days'));
  const now = dayjs();
  const todayDateStr = now.toDate().toDateString();
  const thisMonth = dateForView('month', dayjs());
  const dailyEvents = events.reduce<Record<string, CalendarEvent[]>>((acc, e) => {
    const day = e.start.toDateString();
    const arr = acc[day] || [];
    arr.push(e);
    acc[day] = arr;
    return acc;
  }, {});

  return (
    <section class="relative grow">
      <div class="flex flex-col rounded-2xl border grow absolute inset-0 overflow-hidden">
        <HeaderNav view="month" gotoView={props.gotoView} showToday={!props.date.isSame(thisMonth)}>
          <CalendarNav
            onPrev={() => props.gotoView('month', props.date.add(-1, 'month'))}
            onNext={() => props.gotoView('month', props.date.add(1, 'month'))}
            title={props.date.format('MMMM YYYY')}
          />
        </HeaderNav>
        <section
          key={props.date}
          class={`${animation} grid grid-cols-7 auto-rows-fr grow overflow-hidden`}
        >
          {days.map((d, i) => (
            <div
              key={d.toString()}
              class={`flex flex-col p-1 pt-3 overflow-hidden ${i >= 7 ? 'border-t' : ''} ${
                i % 7 ? 'border-l' : ''
              }`}
            >
              <header class="flex flex-col items-center">
                {i < 7 && <span class="text-xs uppercase">{d.format('ddd')}</span>}
                <Button
                  class={`font-semibold text-xs rounded-full aspect-square flex items-center justify-center p-1.5 ${
                    d.toDate().toDateString() === todayDateStr
                      ? 'bg-indigo-600 text-white'
                      : 'hover:bg-gray-200'
                  }`}
                  onClick={() => props.gotoView('day', d)}
                >
                  {d.format(d.date() === 1 ? 'MMM D' : 'D')}
                </Button>
              </header>
              <div class="flex flex-col">
                {dailyEvents[d.toDate().toDateString()]?.map((e) => {
                  console.log(e);
                  const isPast = now.isAfter(e.end);
                  return (
                    <Button
                      key={e.id}
                      class={`text-xs rounded-md overflow-hidden flex gap-1 text-left text-nowrap hover:bg-gray-200 px-2 p-1 text-inherit ${
                        isPast ? 'opacity-50' : ''
                      }`}
                      href={ruzcal.existingBookingUrl({
                        id: e.id,
                      })}
                    >
                      <span>
                        <span
                          class={`inline-flex size-2 rounded-full mr-1.5 ${
                            isPast ? 'bg-gray-500' : `${getTailwindColorClasses(e.color)}`
                          }`}
                        ></span>
                        {dayjs(e.start).format('h:mma').replace(':00', '')}
                      </span>
                      <span class="font-semibold">
                        {' '}
                        {e.name} with {e.attendee.name}
                      </span>
                    </Button>
                  );
                })}
              </div>
            </div>
          ))}
        </section>
      </div>
    </section>
  );
}
