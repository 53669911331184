import { UserProfileIcon } from '@components/avatars';
import { Button } from '@components/buttons';
import { Case } from '@components/conditional';
import { IcoClock } from '@components/icons';
import { courseLabel } from 'shared/terminology';
import { Course } from 'server/types';
import { timeago } from 'shared/dateutil';
import { URLS } from 'shared/urls';
import { PrimaryOverviewCard } from './overview-card';
import { useCurrentTenant } from '@components/router/session-context';
import type { Props as PageProps } from './load';

interface Props {
  course: Pick<Course, 'id' | 'isBundle'>;
  signups: PageProps['state']['recentSignups']['signups'];
  hasMore: boolean;
}

export function RecentSignups({ course, signups, hasMore }: Props) {
  const tenant = useCurrentTenant();

  return (
    <PrimaryOverviewCard
      title="Recent Signups"
      footerAction={
        hasMore
          ? {
              href: URLS.guide.coursePage({
                courseId: course.id,
                page: 'students',
              }),
            }
          : undefined
      }
    >
      <Case
        when={signups.length > 0}
        fallback={
          <p class="px-6 pb-4">
            No signups so far for this{' '}
            {courseLabel({
              course,
              tenant,
            })}
            .
          </p>
        }
      >
        {signups.map((signup) => (
          <Button
            key={signup.user.id}
            class="flex items-center p-6 py-4 border-t border-gray-200 space-x-4 hover:bg-gray-50"
            href={URLS.guide.student({
              courseId: course.id,
              userId: signup.user.id,
            })}
          >
            <div class="shrink-0">
              <UserProfileIcon user={signup.user} size="h-10 w-10" />
            </div>
            <div class="flex-1 min-w-0">
              <p class="text-sm font-medium text-gray-900 truncate">{signup.user.name}</p>
              <dl class="flex space-x-2 text-xs text-gray-500 dark:text-gray-200">
                <dd class="hidden md:flex items-center space-x-1">
                  <IcoClock class="h-4 w-4" />
                  <span>{timeago(signup.date)}</span>
                </dd>
              </dl>
            </div>
          </Button>
        ))}
      </Case>
    </PrimaryOverviewCard>
  );
}
