import { ComponentChildren } from 'preact';

interface Props {
  centered?: boolean;
  class?: string;
  children?: ComponentChildren;
}

/**
 * Subtext is subtly gray text.
 */
export function Subtext(props: Props) {
  return (
    <p
      class={`text-gray-600 text-sm mb-3 -mt-1 ${props.centered ? 'text-center' : ''} ${
        props.class || ''
      }`}
    >
      {props.children}
    </p>
  );
}
