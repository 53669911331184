import { Button } from '@components/buttons';
import { IcoChevronLeft, IcoChevronRight } from '@components/icons';
import { useAnimator } from 'client/lib/hooks';
import dayjs, { Dayjs } from 'dayjs';
import { JSX } from 'preact';
import { useState } from 'preact/hooks';
import { groupBy } from 'shared/utils';

export interface CalendarItem {
  key: string;
  date: Date;
}

export interface RenderCellProps<T extends CalendarItem> {
  items: T[];
  dateKey: string;
  date: Dayjs;
  now: Dayjs;
  isPast: boolean;
}

interface Props<T extends CalendarItem> {
  /**
   * The date (time ignored) to be displayed. Defaults to today.
   */
  currentDate?: Date;

  /**
   * The items to be displayed.
   */
  items: T[];

  /**
   * Render the items into the view.
   */
  renderCellItems(props: RenderCellProps<T>): JSX.Element;

  /**
   * Called when the date displayed changes (e.g. from July to August)
   */
  onChange?(date: Date): void;

  /*
   * Whether the calendar is read-only.
   */
  isReadOnly?: boolean;
}

interface CellProps<T extends CalendarItem> {
  date: Dayjs;
  now: Dayjs;
  items: Record<string, T[]>;
  isReadOnly?: boolean;
  renderCellItems: Props<T>['renderCellItems'];
}

// The weekdays Sun, Mon, etc...
const weekdays = new Array(7).fill(0).map((_x, i) => dayjs().set('day', i).format('ddd'));

// We'll always display 6 weeks
const days = new Array(6 * 7).fill(0).map((_, i) => i);

// Extract the date portion of the specified datetime as a string.
const itemDate = (dt: Date | string) => {
  if (typeof dt === 'string') {
    return dt.split('T')[0];
  }
  return `${dt.getFullYear()}-${(dt.getMonth() + 1).toString().padStart(2, '0')}-${dt
    .getDate()
    .toString()
    .padStart(2, '0')}`;
};

function CalendarCell<T extends CalendarItem>({
  date,
  now,
  items,
  isReadOnly,
  renderCellItems,
}: CellProps<T>) {
  const key = itemDate(date.toDate());
  const arr = items[key] || [];
  const isPast = date.isBefore(now);

  return (
    <div
      class={`relative md:py-2 px-1 lg:px-3 ${
        isPast
          ? 'bg-gray-50 dark:bg-gray-900 text-gray-500'
          : 'bg-white dark:bg-gray-800 dark:text-gray-300'
      }
      ${isReadOnly || isPast ? '' : 'hover:bg-opacity-90 cursor-pointer'}`}
      id={key}
    >
      {renderCellItems({ items: arr, dateKey: key, date, now, isPast })}
    </div>
  );
}

/**
 * Render the day of the month in a calendar cell.
 */
export function CalendarDay({ date, now }: { date: Dayjs; now: Dayjs }) {
  return (
    <time dateTime={date.toISOString()}>
      <span
        class={`${
          date.isSame(now)
            ? 'inline-flex h-6 w-6 items-center justify-center rounded-full bg-indigo-500 font-semibold text-white'
            : ''
        }`}
      >
        {date.format('D')}
      </span>

      {date.get('date') === 1 && <span class="ml-2 opacity-50">{date.format('MMM')}</span>}
    </time>
  );
}

/**
 * Render a basic calendar component.
 */
export function Calendar<T extends CalendarItem>(props: Props<T>) {
  const [month, setMonth] = useState(() =>
    dayjs(props.currentDate).date(1).hour(0).minute(0).second(0).millisecond(0),
  );
  const now = dayjs(new Date().setHours(0, 0, 0, 0));
  const dt = month.set('day', 0);
  const animator = useAnimator(
    (curr, prev) => {
      if (!prev || prev.isSame(curr)) {
        return;
      }
      return curr.isAfter(prev) ? 'an-fade-in-left' : 'an-fade-in-right';
    },
    150,
    month,
  );

  const itemsByDate = groupBy(
    (x) => itemDate(x.date),
    Object.values(props.items).sort((a, b) => (a.date > b.date ? 1 : -1)),
  );

  const moveDirection = (direction: number) => {
    const newMonth = month.set('month', month.get('month') + direction);
    setMonth(newMonth);
    props.onChange && props.onChange(newMonth.toDate());
  };

  return (
    <>
      <header class="mb-4 flex items-center text-gray-900 dark:text-gray-200">
        <Button class="p-4 -ml-2" onClick={() => moveDirection(-1)}>
          <IcoChevronLeft />
        </Button>
        <Button class="p-4" onClick={() => moveDirection(1)}>
          <IcoChevronRight />
        </Button>
        <h2 class="text-xl ml-2">{month.format('MMMM YYYY')}</h2>
      </header>
      <div
        ref={animator.ref}
        class="bg-gray-200 dark:bg-gray-600 border dark:border-gray-600 text-xs"
      >
        <div class="grid grid-cols-7 gap-px border-b dark:border-gray-600 text-center text-xs font-semibold leading-6 text-gray-700 lg:flex-none">
          {weekdays.map((s) => (
            <div
              key={s}
              class="py-2 text-center bg-white dark:bg-gray-900 font-semibold text-gray-700 dark:text-gray-300"
            >
              {s}
            </div>
          ))}
        </div>
        <div class="grid grid-cols-7 grid-rows-6 gap-px text-xs">
          {days.map((d) => (
            <CalendarCell
              key={d}
              items={itemsByDate}
              date={dt.set('date', dt.get('date') + d)}
              now={now}
              renderCellItems={props.renderCellItems}
              isReadOnly={props.isReadOnly}
            />
          ))}
        </div>
      </div>
    </>
  );
}
