import { reverseRoute } from 'shared/urls';
import { Item } from './state';

export const baseUrl = '/admin/pricing/:productId';
export const folderUrl = '/admin/pricing/:productId/:folderId';

export const folderRoute = (opts: { productId: string; folderId: string }) =>
  reverseRoute(folderUrl, opts);

export const itemUrl = ({
  item,
  productId,
  folderId,
}: {
  item: Pick<Item, 'id' | 'type'>;
  productId: string;
  folderId?: string;
}) => {
  if (item.type === 'folder') {
    return folderRoute({ productId, folderId: item.id });
  }

  const paramName = item.type === 'price' ? 'priceId' : 'couponId';
  const queryStr = `?${paramName}=${item.id}`;
  if (folderId) {
    return folderRoute({ productId, folderId }) + queryStr;
  }
  return queryStr;
};
