import { rpx, RpxResponse } from 'client/lib/rpx-client';
import { AccountTier, MembershipLevel, UserLevel } from 'server/types';
import { FilterDefinitions, PaginatedList } from '@course/components/tenants';

type TenantUsers = Awaited<RpxResponse<typeof rpx.admin.getTenantUsers>>;
export type TenantUser = TenantUsers['users'][0];

type BaseProps = {
  onSelect?(user: TenantUser): void;
  tableHeaders: string[];
  searchTerm?: string;
  tenantId?: UUID;
  users?: TenantUsers['users'];
  cursor?: TenantUsers['cursor'];
  userLevels?: UserLevel[];
  render(props: { user: TenantUser; href: string }): JSX.Element;
};

type Props =
  | (BaseProps & {
      type: 'course';
      courseId: UUID;
      membershipLevel?: MembershipLevel;
    })
  | (BaseProps & { type: 'all' });

const filterDefinitions: FilterDefinitions<Filters, 'userLevel' | 'userTier'> = {
  userLevel: {
    title: 'Role',
    level: 'admin',
    options: [
      { title: 'All', value: 'all' },
      { title: 'Student', value: 'student' },
      { title: 'Guide', value: 'guide' },
      { title: 'Admin', value: 'admin' },
      { title: 'Superadmin', value: 'superadmin' },
    ],
  },
  userTier: {
    title: 'Tier',
    level: 'superadmin',
    options: [
      { title: 'All', value: 'all' },
      { title: 'Free', value: 'free' },
      { title: 'Core', value: 'core' },
      { title: 'Pro', value: 'pro' },
    ],
  },
};

interface Filters {
  userLevel: UserLevel | 'all';
  userTier: AccountTier | 'all';
}

type State = Filters & { searchTerm: string };

const defaultFilters: State = {
  searchTerm: '',
  userLevel: 'all',
  userTier: 'all',
};

export function TenantUsersList(props: Props) {
  return (
    <PaginatedList
      fetcher={async (opts, cursor) => {
        const userLevel = (opts as any).userLevel;
        const userTier = (opts as any).userTier;
        const result = await rpx.admin.getTenantUsers({
          tenantId: props.tenantId,
          searchTerm: opts.searchTerm,
          cursor,
          courseId: props.type === 'course' ? props.courseId : undefined,
          membershipLevel: props.type === 'course' ? props.membershipLevel : undefined,
          userLevels:
            props.userLevels || (userLevel && userLevel !== 'all' ? [userLevel] : undefined),
          userTiers: userTier && userTier !== 'all' ? [userTier] : undefined,
        });
        return { items: result.users, cursor: result.cursor };
      }}
      initialState={defaultFilters}
      searchBox={{
        placeholder: 'Search by id, name, or email',
      }}
      tableHeaders={props.tableHeaders}
      cursor={props.cursor}
      filterDefinitions={
        props.userLevels || props.type === 'course' ? undefined : filterDefinitions
      }
      items={props.users}
      onSelect={props.onSelect}
      renderItem={({ item }) => {
        return props.render({
          user: item as any,
          href: `/admin/people/${item.id}`,
        });
      }}
    />
  );
}
