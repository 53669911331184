import { AccountTier } from 'server/types';

export const BULK_INSERT_LIMIT = 1000;

export const COURSE_STUDENT_LIMIT = 20000;

export const V1_USER = 'v1user';

export const RUZUKU_ASSETS_BASE_URL = 'https://cdn.ruzuku.com/ruzuku/assets';
export const ruzukuLogoURL = `${RUZUKU_ASSETS_BASE_URL}/ruzuku-logo.svg`;
export const ruzukuPngLogoURL = `${RUZUKU_ASSETS_BASE_URL}/ruzuku-logo.png`;

export const COURSE_CHECKLIST_STEPS = [
  'watchVideo',
  'accessFormat',
  'firstModule',
  'firstLesson',
  'createMeeting',
  'courseStyle',
  'paymentIntegration',
  'pricePoint',
  'salesPage',
  'welcomeEmail',
  'publishCourse',
  'share',
] as const;

export const ACCOUNT_TIERS: AccountTier[] = ['free', 'core', 'pro'];

/**
 * Students can upload up to 300MB files
 */
export const MAX_STUDENT_FILESIZE = 1024 * 1024 * 300;

/**
 * Guides can upload up to 2GB files
 */
export const MAX_GUIDE_FILESIZE = 1024 * 1024 * 1024 * 2;

/**
 * Non-pro accounts can only create 3 upsells.
 */
export const MAX_CORE_UPSELLS = 3;

/**
 * Tenant guides can upload up to 4GB files
 */
export const MAX_TENANT_GUIDE_FILESIZE = 1024 * 1024 * 1024 * 4;

/**
 * Maximum number of student enrollments across all courses for free/trial tier accounts
 */
export const MAX_FREE_TIER_STUDENTS = 5;

/**
 * Maximum number of invitations daily for free/trial tier accounts
 */
export const MAX_FREE_TIER_DAILY_INVITATIONS = 5;

/**
 * Maximum memberships per course, period.
 */
export const MAX_COURSE_MEMBERSHIPS = 20000;

/**
 * Maximum invitations per course.
 */
export const MAX_INVITATIONS = 20000;

export const CORE_DOMAIN = 'courses.ruzuku.com';

export const genericDiscussionCategoryIds = {
  all: 'all',
  lessons: 'lessons',
  nonLessons: 'general',
  new: 'new',
};

export const ERROR_CODES = {
  zoomNeedsRefresh: 'zoom_needs_refresh',
  zoomMeetingNotFound: 'zoom_meeting_not_found',
  zoomNoAccount: 'zoom_no_account',
};

// This token will be used as a special character for Meilisearch
// that separates the text. See soft separators at
// https://www.meilisearch.com/docs/learn/engine/datatypes#separators
export const CAPTIONS_SEPERATOR_TOKEN = '&&&';
