import { render } from 'preact-render-to-string';
import {
  BasicEmailWrapper,
  PrimaryAction,
  Heading,
  Line,
  SupportFooter,
  CenteredLine,
} from './shared';
import { summarizeStudentNames } from '../helpers';

/**
 * Assignment submission info for a single lesson, including summarized respondee actions
 */
export type AssignmentSubmissionSummary = {
  lessonId: UUID;
  lessonTitle: string;
  students: string[]; // names of student respondees
  lessonUrl: string;
  reviewUrl: string;
};

type Props = {
  course: { id: UUID; title: string };
  items: AssignmentSubmissionSummary[];
};

/**
 * Guide notification for new student assignment submissions
 */
export function AssignmentsSubmitted({ course, items }: Props) {
  return render(
    <BasicEmailWrapper footer={<SupportFooter />}>
      <Heading>New assignment submissions in {course.title}</Heading>

      {/* Provide a summary section per assessment per lesson */}
      {items.map((row) => (
        <div
          key={row.lessonId}
          style="font-family:Helvetica Neue, Helvetica, Arial, sans-serif; margin-top:1.5rem; margin-bottom:1.5rem;"
        >
          <Line>
            {summarizeStudentNames(row.students)} submitted{' '}
            {row.students.length === 1 ? 'an assignment' : 'assignments'} for {row.lessonTitle}.
          </Line>
          <PrimaryAction href={row.reviewUrl}>Review submissions</PrimaryAction>
          <CenteredLine>
            <span style="font-size: 0.75rem;color:#636363">
              <a href={row.lessonUrl}>Manage notification settings for {row.lessonTitle}</a>
            </span>
          </CenteredLine>
        </div>
      ))}
    </BasicEmailWrapper>,
  );
}
