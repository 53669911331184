import { on } from 'minidoc-editor';

/*
 * This event is triggered by our router when the user navigates to a new page.
 * We're not using original `beforeunload` event in this case as firing that
 * is somehow breaking LogRocket recording functionality.
 */
const FAKE_EVENT = 'ruz-beforeunload';
export function triggerBeforeUnload() {
  const e: any = new Event(FAKE_EVENT, { cancelable: true });
  window.dispatchEvent(e);
  return e;
}

export function onBeforeUnload(handler: () => string | undefined) {
  function handle(e: any) {
    const message = handler();
    if (message) {
      e.returnValue = message;
      e.prompt = message;
    }
    return message;
  }

  const removeNativeListener = on(window, 'beforeunload', handle);
  const removeFakeListener = on(window, FAKE_EVENT, handle);

  return () => {
    removeNativeListener();
    removeFakeListener();
  };
}

/*
 * Save some data before the page unloads.
 */
export function saveBeforeUnload(saveFn: () => void, isDirty?: () => boolean) {
  // Firefox can save changes on `pagehide`
  // Chrome can save changes on `beforeunload`
  // Webkit / Safari doesn't work in any situation.
  const agent = window.navigator.userAgent.toLowerCase();
  const isChrome = agent.includes('chrome');
  const isFirefox = agent.includes('firefox');
  const supportsUnloadSave = isChrome || isFirefox;

  const offBeforeUnload = onBeforeUnload(() =>
    isDirty?.()
      ? 'Your unsaved changes will be lost. Do you still want to navigate away?'
      : undefined,
  );

  if (!supportsUnloadSave) {
    return offBeforeUnload;
  }

  const removeNativeListener = on(window, isFirefox ? 'pagehide' : 'beforeunload', saveFn);
  const removeFakeListener = on(window, FAKE_EVENT, saveFn);

  return () => {
    removeNativeListener();
    removeFakeListener();
    offBeforeUnload();
  };
}
