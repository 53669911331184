import { FullCourse } from 'server/types';
import { AsyncForm, FormGroup, FormSubSection, InputLabel, Subtext } from '@components/async-form';
import { rpx } from 'client/lib/rpx-client';
import { BtnPrimary } from '@components/buttons';
import { showToast } from '@components/toaster';
import { COURSE_STUDENT_LIMIT } from 'shared/consts';
import { useCurrentTenant } from '@components/router/session-context';
import { HeadingSecondary } from '@course/components/headings';
import { useMinidoc } from '@components/minidoc';
import { ManualDom } from '@components/manual-dom';
import { EditorWrapper } from '@components/minidoc/minidoc-root';
import { cardMiddleware, defaultToolbarActions, minidocToolbar, placeholder } from 'minidoc-editor';
import { toolbarDropdown } from '@components/toolbar-dropdown';
import { useIntl } from 'shared/intl/use-intl';
import { getCourseNoSeatsAvailableMessage } from 'shared/messages';
import { mediaCard, mediaMiddleware } from '@components/media-card';
import { useAsyncState } from 'client/lib/hooks';
import { Spinner } from '@components/spinner';

const store = rpx.courseSettings;

interface Props {
  course: FullCourse;
  onUpdate: (updates: Pick<FullCourse, 'maxMemberships'>) => void;
}

function NoSeatsAvailableMessageEditor({
  message,
  onChange,
}: {
  message: string;
  onChange: (doc: string) => void;
}) {
  const intl = useIntl();
  const editor = useMinidoc({
    doc: message,
    middleware: () => [
      placeholder('No seats available message'),
      minidocToolbar([
        ...defaultToolbarActions.filter((a) => a.id !== 'h1'),
        toolbarDropdown({
          mediaOnly: true,
          intl,
        }),
      ]),
      cardMiddleware([mediaCard]),
      mediaMiddleware({
        isPublic: true,
      }),
    ],
    onChange: (doc) => {
      onChange(doc);
    },
  });

  return (
    <div class="dark:bg-gray-800 border dark:border-gray-700 rounded-sm">
      <ManualDom el={editor.toolbar.root} />
      <div class="bg-white dark:bg-gray-900 p-4 rounded-b-lg">
        <EditorWrapper class="prose" editor={editor} />
      </div>
    </div>
  );
}

export function LimitSettings({ course, onUpdate }: Props) {
  const intl = useIntl();
  const { terminology } = useCurrentTenant();
  const [settings, setSettings] = useAsyncState(
    () => store.getCourseMembershipLimits({ courseId: course.id }),
    [course.id],
  );

  async function submit(formData: { maxMemberships: number }) {
    const newLimit = formData.maxMemberships
      ? Number(formData.maxMemberships)
      : COURSE_STUDENT_LIMIT;
    const isDefaultMessage =
      settings?.noSeatsAvailableMessage ===
      getCourseNoSeatsAvailableMessage(intl, course.guide.email);

    await store.setCourseMembershipLimits({
      courseId: course.id,
      maxMemberships: newLimit,
      noSeatsAvailableMessage: isDefaultMessage ? undefined : settings?.noSeatsAvailableMessage,
    });
    showToast({
      title: 'Settings changed',
      message: `${terminology.course} limit settings have been updated.`,
      type: 'ok',
    });
    onUpdate({
      maxMemberships: newLimit,
    });
  }

  if (!settings) {
    return (
      <div class="flex justify-center">
        <Spinner class="border-sky-500 w-8 h-8" />
      </div>
    );
  }

  return (
    <AsyncForm class="flex flex-col gap-6" onSubmit={submit}>
      <HeadingSecondary>{terminology.course} Limits</HeadingSecondary>
      <FormSubSection>
        <InputLabel>Seats available for this {terminology.course}</InputLabel>
        <Subtext>Invitations do not count towards this limit.</Subtext>
        <FormGroup prop="maxMemberships">
          <input
            class="inline-ruz-input w-full"
            type="text"
            name="maxMemberships"
            placeholder="Unlimited"
            defaultValue={`${settings.maxMemberships || COURSE_STUDENT_LIMIT}`}
          />
        </FormGroup>
      </FormSubSection>
      <FormSubSection>
        <InputLabel>No seats available message</InputLabel>
        <Subtext>
          This message is displayed to students when the {terminology.course} is full
        </Subtext>
        <NoSeatsAvailableMessageEditor
          message={
            settings.noSeatsAvailableMessage ||
            getCourseNoSeatsAvailableMessage(intl, course.guide.email)
          }
          onChange={(message) => setSettings({ ...settings, noSeatsAvailableMessage: message })}
        />
      </FormSubSection>
      <footer>
        <BtnPrimary class="rounded-full px-12">Save</BtnPrimary>
      </footer>
    </AsyncForm>
  );
}
