import { ComponentChildren, ComponentChild } from 'preact';

export function PageTitle({ children }: { children: ComponentChildren }) {
  return <h1 class="text-xl font-medium text-gray-900 capitalize">{children}</h1>;
}

/**
 * The primary heading. Children are right-aligned, and are generally a single primary action.
 */
export function HeadingPrimary(props: {
  title: ComponentChild;
  class?: string;
  children?: ComponentChildren;
}) {
  return (
    <header class={`flex flex-col gap-2 ${props.class || ''}`}>
      <PageTitle>{props.title}</PageTitle>
      <nav class="flex items-center flex-wrap gap-2">{props.children}</nav>
    </header>
  );
}

export function HeadingSecondary({ children }: { children: ComponentChildren }) {
  return <h2 class="text-lg capitalize font-medium">{children}</h2>;
}
