import { ComponentChildren } from 'preact';
import { OrderBy } from './state';
import { IcoChevronDown, IcoChevronUp, IcoSelector } from '@components/icons';

export function SortableColumn({
  col,
  orderBy,
  hiddenOnMobile,
  onClick,
  children,
}: {
  col: string;
  orderBy: OrderBy;
  hiddenOnMobile?: boolean;
  onClick(): void;
  children: ComponentChildren;
}) {
  const isCurrent = orderBy.orderBy === col;

  return (
    <label
      class={`pl-4 pr-2 py-2 cursor-pointer whitespace-nowrap ${
        isCurrent ? 'bg-gray-100 text-gray-800' : ''
      } ${hiddenOnMobile ? 'hidden md:table-cell' : 'table-cell'}`}
      onClick={onClick}
    >
      <span class="flex justify-between items-center">
        {children}
        <span class={`${isCurrent ? '' : 'opacity-50'} ml-1`}>
          {!isCurrent && <IcoSelector class="w-3 h-3" />}
          {isCurrent && orderBy.orderDirection === 'desc' && <IcoChevronDown class="w-3 h-3" />}
          {isCurrent && orderBy.orderDirection === 'asc' && <IcoChevronUp class="w-3 h-3" />}
        </span>
      </span>
    </label>
  );
}

export function HCell(props: { class?: string; children: ComponentChildren }) {
  return <div class={`hidden md:table-cell p-4 py-2 ${props.class}`}>{props.children}</div>;
}

export function Cell(props: { children: ComponentChildren }) {
  return (
    <span class="hidden first-of-type:table-cell md:table-cell p-4 border-t align-middle">
      {props.children}
    </span>
  );
}
