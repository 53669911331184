// Contains a function to generate tenant related emails
import { renderToString } from 'preact-render-to-string';
import { BasicEmailWrapper } from '../templates';
import { esc } from 'shared/htm';

export interface TenantEmailOpts {
  html: string;
  subject: string;
}

export function renderTenantEmail(opts: TenantEmailOpts) {
  const { html } = opts;

  const template = (
    <BasicEmailWrapper footer={null}>
      <div dangerouslySetInnerHTML={{ __html: html }}></div>
    </BasicEmailWrapper>
  );

  const templateParams: any = {
    // Modifying user-name tags in Mailgun recipient-variables format.
    'recipient-name': '%recipient.name%',
  };

  return {
    subject: replacePlaceholders(opts.subject, templateParams, (s) => s),
    body: replacePlaceholders(renderToString(template), templateParams, esc),
  };
}

function replacePlaceholders(
  s: string,
  templateParams: Record<string, string>,
  escapeHTML: (s: string) => string,
) {
  return s.replaceAll(/{{{([^}]+)}}}/g, (_, name) => {
    const value = templateParams[name];
    return escapeHTML(value);
  });
}
