import { CourseRow } from 'server/types';

export type AnnouncementMessageTabs = 'draft' | 'sent';

export const messagesUrl = (
  course: Pick<CourseRow, 'id' | 'isProduct'>,
  messageId?: string | number,
) => {
  return `/manage/${course.isProduct ? 'products' : 'courses'}/${course.id}/messages/${
    messageId || ''
  }`;
};
