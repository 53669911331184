import { showError } from '@components/app-error';
import { LoadingIndicator } from '@components/loading-indicator';
import { ModalForm } from '@components/modal-form';
import { useAsyncData } from 'client/lib/hooks';
import { rpx } from 'client/lib/rpx-client';
import { BuyCreditsBanner } from 'client/courses/pages/guide-instant-courses/buy-credits-banner';
import { Course } from 'server/types';

interface Props {
  course: Pick<Course, 'id' | 'title'>;
  onCancel: () => void;
}

const store = rpx.instantCourses;

export function PurchaseInstantCourseModal({ course, onCancel }: Props) {
  const { data } = useAsyncData(() => store.getInstantCourseCredits(), []);

  async function purchase() {
    try {
      await store.purchaseInstantCourse({
        courseId: course.id,
      });
      await store.registerToInstantCourse({
        courseId: course.id,
      });
      window.location.reload();
    } catch (err) {
      showError(err);
    }
  }

  if (!data) {
    return <LoadingIndicator />;
  }

  const remaining = data.credits - data.usage;

  if (!data.isUnlimited && remaining <= 0) {
    return <BuyCreditsBanner credits={data.credits} usage={data.usage} expanded />;
  }

  return (
    <ModalForm
      title="Purchase Instant Course"
      onClose={onCancel}
      onSubmit={purchase}
      confirmButtonText="Purchase Instant Course"
    >
      <p>
        Do you want to purchase "{course.title}" instant course? You have {remaining} credits
        remaining.
      </p>
    </ModalForm>
  );
}
