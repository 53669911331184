/**
 * This file contains the form that we display in the limits tab for prices
 * (and maybe coupons), as well as in the "new price" screen.
 */
import { FormGroup, FormSubSection, InputLabel } from '@components/async-form';
import { DateInput } from '@components/date-picker';
import { parseDate } from 'shared/dateutil';
import { useState } from 'preact/hooks';
import { PriceRow } from 'server/types';
import * as dtfmt from 'shared/dateutil';
import { Pill } from '@components/pill';

export type PriceLimits = Pick<
  PriceRow,
  'maxPurchases' | 'expiresOn' | 'availableOn' | 'accessDuration'
>;

interface Props {
  item: PriceLimits;
  itemName: string;
  saveLimits(p: PriceLimits): Promise<unknown>;
}

function CompactTime({ time }: { time?: Date }) {
  return <span class="font-medium">{dtfmt.toHumanTime(time, 'compact')}</span>;
}

export function AvailableDates({
  item,
}: {
  item: {
    expiresOn?: Date;
    availableOn?: Date;
  };
}) {
  if (!item.availableOn && !item.expiresOn) {
    return null;
  }

  if (item.expiresOn && dtfmt.toISO8601(item.expiresOn) < dtfmt.toISO8601(new Date())) {
    return (
      <span>
        <Pill color="gray">Expired</Pill>
        <span class="ml-2">No longer available for enrollment.</span>
      </span>
    );
  }

  if (!item.expiresOn) {
    return (
      <span>
        Available <CompactTime time={item.availableOn} />
      </span>
    );
  }

  if (!item.availableOn) {
    return (
      <span>
        Expires <CompactTime time={item.expiresOn} />
      </span>
    );
  }

  return (
    <span>
      Available from <CompactTime time={item.availableOn} />
      {' until '}
      <CompactTime time={item.expiresOn} />
    </span>
  );
}

function maybeParse<T>(s: string, fn: (s: string) => T): null | undefined | T {
  if (s === '') {
    return null;
  }
  if (s) {
    return fn(s);
  }
}

export function formDataToPriceLimits(formData: any) {
  return {
    availableOn: maybeParse(formData.availableOn, parseDate),
    expiresOn: maybeParse(formData.expiresOn, parseDate),
    maxPurchases: maybeParse(formData.maxPurchases, parseInt),
  };
}

export function SignupLimitsFormFields(props: { itemName: string; item?: Props['item'] }) {
  const [state, setState] = useState(() => ({
    maxPurchases: props.item?.maxPurchases || '',
    availableOn: props.item?.availableOn,
    expiresOn: props.item?.expiresOn,
  }));

  return (
    <section class="flex flex-col gap-4">
      <FormSubSection>
        <InputLabel>Seats available for this {props.itemName}</InputLabel>
        <FormGroup prop="maxPurchases">
          <input
            class="inline-ruz-input"
            type="text"
            name="maxPurchases"
            placeholder="Unlimited"
            value={state.maxPurchases}
            onInput={(e: any) => setState((s) => ({ ...s, maxPurchases: e.target.value }))}
          />
        </FormGroup>
      </FormSubSection>
      <FormSubSection>
        <InputLabel>Allow signups starting</InputLabel>
        <FormGroup prop="availableOn">
          <DateInput
            name="availableOn"
            includeTime
            placeholder="Now"
            value={state.availableOn}
            allowClear
            onChange={(availableOn) => setState((s) => ({ ...s, availableOn }))}
          />
        </FormGroup>
      </FormSubSection>
      <FormSubSection>
        <InputLabel>Expires</InputLabel>
        <FormGroup prop="expiresOn">
          <DateInput
            name="expiresOn"
            includeTime
            placeholder="Never"
            min={state.availableOn}
            value={state.expiresOn}
            allowClear
            onChange={(expiresOn) => setState((s) => ({ ...s, expiresOn }))}
          />
        </FormGroup>
      </FormSubSection>
    </section>
  );
}
