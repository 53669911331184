const Colors = [
  'indigo',
  'red',
  'orange',
  'amber',
  'lime',
  'emerald',
  'teal',
  'cyan',
  'sky',
  'blue',
  'violet',
  'purple',
  'fuchsia',
  'pink',
  'rose',
  'slate',
] as const;
export type PaletteColor = (typeof Colors)[number];

const ColorPaletteTailwindClasses: Record<string, string> = {
  indigo: 'bg-indigo-500 hover:bg-indigo-600',
  red: 'bg-red-500 hover:bg-red-600',
  orange: 'bg-orange-500 hover:bg-orange-600',
  amber: 'bg-amber-500 hover:bg-amber-600',
  lime: '!text-black bg-lime-500 hover:bg-lime-600',
  emerald: 'bg-emerald-500 hover:bg-emerald-600',
  teal: 'bg-teal-500 hover:bg-teal-600',
  cyan: 'bg-cyan-500 hover:bg-cyan-600',
  sky: 'bg-sky-500 hover:bg-sky-600',
  blue: 'bg-blue-500 hover:bg-blue-600',
  violet: 'bg-violet-500 hover:bg-violet-600',
  purple: 'bg-purple-500 hover:bg-purple-600',
  fuchsia: 'bg-fuchsia-500 hover:bg-fuchsia-600',
  pink: 'bg-pink-500 hover:bg-pink-600',
  rose: 'bg-rose-500 hover:bg-rose-600',
  slate: 'bg-slate-500 hover:bg-slate-600',
};

interface ColorPaletteProps {
  color: PaletteColor;
  onPick(color: PaletteColor): void;
}

export function ColorPalette({ color, onPick }: ColorPaletteProps) {
  return (
    <div class="flex flex-wrap gap-2">
      {Colors.map((c) => (
        <label
          class="relative flex items-left cursor-pointer gap-1 font-medium rounded"
          onClick={() => onPick(c)}
          key={c}
        >
          <span
            class={`w-6 h-6 min-w-6 rounded-full inline-block ring-2 shrink-0 hover:ring-current hover:ring-offset-2 ${
              color == c ? 'ring-offset-2 ring-current' : ''
            } ${getTailwindColorClasses(c)}`}
          ></span>
        </label>
      ))}
    </div>
  );
}

export function getTailwindColorClasses(color: string): string {
  // Check if the color exists in the ColorPaletteTailwindClasses
  const validColor = Colors.includes(color as PaletteColor) ? (color as PaletteColor) : 'indigo';
  return ColorPaletteTailwindClasses[validColor];
}
