import { showError } from '@components/app-error';
import { UserProfileIcon } from '@components/avatars';
import { Button } from '@components/buttons';
import { IcoClock, IcoDownload } from '@components/icons';
import { LoadingIndicator } from '@components/loading-indicator';
import { showModalForm } from '@components/modal-form';
import { QuizResultsModal } from '@course/components/quiz/quiz-results';
import { useRouteParams } from '@components/router';
import { rpx, RpxResponse } from 'client/lib/rpx-client';
import { useAsyncEffect } from 'client/utils/use-async-effect';
import { ComponentChildren } from 'preact';
import { useState } from 'preact/hooks';
import { LessonRow } from 'server/types';
import { timeago } from 'shared/dateutil';
import { pluralize } from 'shared/formatting';

type Stats = RpxResponse<typeof rpx.assessments.getQuizStats>;
type SubmissionFeed = RpxResponse<typeof rpx.assessments.getQuizSubmissions>;

interface Props {
  lesson: Pick<LessonRow, 'id' | 'title'>;
  totalPoints: number;
}

function Stat({ title, children }: { title: string; children: ComponentChildren }) {
  return (
    <div class="px-4 py-5 bg-white shadow-sm rounded-lg overflow-hidden sm:p-6 text-center">
      <dt class="text-lg font-semibold text-gray-900 truncate">{title}</dt>
      <dd class="mt-1 text-sm text-gray-500">{children}</dd>
    </div>
  );
}

const percentToPoints = (score: number, totalPoints: number) =>
  Math.round((score / 100) * totalPoints);

function DescribeScore({ score, totalPoints }: { score: number; totalPoints: number }) {
  return (
    <span class="flex justify-center gap-2">
      <span>{percentToPoints(score, totalPoints)} points</span>
      <span>|</span>
      <span>{score}%</span>
    </span>
  );
}

export function QuizSummary({ lesson, totalPoints }: Props) {
  const { courseId } = useRouteParams();
  const [isLoading, setIsLoading] = useState(true);
  const [stats, setStats] = useState<Stats | undefined>(undefined);
  const [feed, setFeed] = useState<SubmissionFeed>({
    submissions: [],
    hasMore: true,
    cursor: '',
  });

  async function loadNextPage() {
    setIsLoading(true);
    try {
      const result = await rpx.assessments.getQuizSubmissions({
        lessonId: lesson.id,
        cursor: feed.cursor,
      });
      setFeed({
        submissions: feed.submissions.concat(result.submissions),
        hasMore: result.hasMore,
        cursor: result.cursor,
      });
    } catch (e) {
      showError(e);
    } finally {
      setIsLoading(false);
    }
  }

  // Load the first page
  useAsyncEffect(async () => {
    loadNextPage();
  }, []);

  useAsyncEffect(async () => {
    try {
      const result = await rpx.assessments.getQuizStats({ lessonId: lesson.id });
      setStats(result);
    } catch (err) {
      showError(err);
    }
  }, []);

  if (!stats) {
    return <LoadingIndicator />;
  }

  const { submissions } = feed;

  return (
    <div class="space-y-8">
      <div class="flex justify-between mt-4">
        <h3 class="text-lg font-bold">
          {stats.total} {pluralize('submission', stats.total)}
        </h3>
        {submissions.length > 0 && (
          <Button
            class="inline-flex items-center gap-1"
            download={`quiz-${lesson.title}.csv`}
            href={`/csv/lessons/${lesson.id}/quiz.csv`}
          >
            <IcoDownload />
            Download CSV
          </Button>
        )}
      </div>
      {submissions.length > 0 && (
        <dl class="grid grid-cols-1 gap-5 sm:grid-cols-3">
          <Stat title="Average Score">
            <DescribeScore score={stats.average} totalPoints={totalPoints} />
          </Stat>
          <Stat title="Median Score">
            <DescribeScore score={stats.median} totalPoints={totalPoints} />
          </Stat>
          <Stat title="Range">
            {percentToPoints(stats.min, totalPoints)} - {percentToPoints(stats.max, totalPoints)}{' '}
            points
          </Stat>
        </dl>
      )}

      <div class="space-y-2">
        {submissions.map((submission) => (
          <Button
            key={submission.user.id}
            class="flex p-4 w-full bg-white shadow-sm hover:bg-indigo-50"
            onClick={() =>
              showModalForm(({ resolve }) => (
                <QuizResultsModal
                  courseId={courseId}
                  lesson={submission.lesson}
                  user={submission.user}
                  hide={resolve}
                />
              ))
            }
          >
            <div class="flex items-center space-x-4">
              <div class="shrink-0">
                <UserProfileIcon user={submission.user} size="w-10 h-10" />
              </div>
              <div class="flex-1 min-w-0">
                <p class="text-left text-sm font-medium text-gray-900">
                  <strong>{submission.user.name}</strong> completed{' '}
                  <strong>{submission.lesson.title}</strong> with a score of {submission.score}%.
                </p>
                <dl class="flex space-x-2 text-xs text-gray-500 dark:text-gray-200">
                  <dd class="hidden md:flex items-center space-x-1">
                    <IcoClock class="h-4 w-4" />
                    <span>{timeago(submission.submittedAt)}</span>
                  </dd>
                </dl>
              </div>
            </div>
          </Button>
        ))}
      </div>
      {feed.hasMore && (
        <Button
          class="text-indigo-600 outline-hidden focus:ring-2 focus:ring-indigo-400 ml-2"
          spinnerClass="border-indigo-800"
          isLoading={isLoading}
          onClick={loadNextPage}
        >
          View More
        </Button>
      )}
    </div>
  );
}
