import { Button, ButtonProps } from '@components/buttons';
import { ComponentChildren } from 'preact';

interface TabProps extends ButtonProps {
  isSelected?: boolean;
  padding?: string;
  textSize?: string;
}

interface TabsProps {
  class?: string;
  children: ComponentChildren;
}

export function Tab(props: TabProps) {
  const {
    isSelected,
    padding = 'py-2 px-1',
    textSize = 'text-sm',
    children,
    ...restOfProps
  } = props;
  const className = isSelected
    ? 'border-indigo-500 text-indigo-600'
    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300';
  return (
    <Button
      {...restOfProps}
      class={`${className} whitespace-nowrap ${padding} inline-flex gap-1.5 items-center border-b-2 font-semibold ${textSize}`}
    >
      {children}
    </Button>
  );
}

export function Tabs({ class: className = '', children }: TabsProps) {
  return (
    <div class={`border-b mb-4 ${className}`}>
      <nav class="-mb-px flex flex-wrap lg:flex-nowrap space-x-6 md:space-x-8" aria-label="Tabs">
        {children}
      </nav>
    </div>
  );
}

export * from './vertical-tabs';
