import { addUrlSearchParam, deleteUrlSearchParam } from 'client/utils/url';
import { CourseStep } from 'server/types';
import { evt } from 'client/lib/app-evt';
import { AppRouter } from '@components/router';

type CallbackArgs = {
  step: CourseStep;
  scrollToTop: boolean;
};

const COMPLETE_STEP_EVENT = '$complete_course_step';

export function completeCourseStep(router: AppRouter, step: CourseStep, scrollToTop = true) {
  evt.emit(COMPLETE_STEP_EVENT, {
    step,
    scrollToTop,
  });

  const params = window.location.search;

  if (!params.includes('tour=')) {
    return;
  }

  let newUrl = deleteUrlSearchParam('tour');
  if (step === 'firstModule' && window.location.search.includes('tour=firstModule')) {
    newUrl = addUrlSearchParam('tour', 'firstLesson');
  }
  router.rewrite(newUrl);
}

export function onCourseStepCompleted(callback: (args: CallbackArgs) => void) {
  return evt.on((name, args: CallbackArgs) => {
    if (name === COMPLETE_STEP_EVENT) {
      callback(args);
    }
  });
}
