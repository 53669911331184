// typescript weirdly complains about importing 'h' here
// @ts-ignore
import { ComponentChildren } from 'preact';

type Props = {
  children: ComponentChildren;
  fallback?: ComponentChildren;
  when: any;
};

/**
 * Renders `children` or `fallback` props based on `case`.
 */
export function Case({ when: condition, children, fallback = null }: Props) {
  return <>{condition ? children : fallback}</>;
}

export const Default = (props: { children: ComponentChildren }) => <>{props.children}</>;

/**
 * A declarative switch / case statement. Its children should be When or Default
 * components.
 */
export function Do(props: { children: ComponentChildren }) {
  const children: any = Array.isArray(props.children) ? props.children : [props.children];
  for (const c of children) {
    if (c.props.when || c.type === Default) {
      return c.props.children;
    }
  }
  return null;
}
