import { FixedContent, FixedPage } from '@course/components/fixed-page';
import { AdminNav, AdminTabHeader, AdminTabWrapper } from '@course/components/admin-nav';
import { PaginatedList } from '@course/components/tenants';
import { BtnBasicCopy, Button } from '@components/buttons';
import { showError } from '@components/app-error';
import { URLS } from 'shared/urls';
import { compactDate, minutesToTimeString } from 'shared/dateutil';
import { rpx, RpxResponse } from 'client/lib/rpx-client';
import { compactId } from 'shared/utils';
import { IcoClipboard } from '@components/icons';
import { useIntl } from 'shared/intl/use-intl';
import { defRoute } from '@components/router';

type Item = RpxResponse<typeof rpx.admin.getInvitationsAsAdmin>['items'][0];

export const route = defRoute({
  authLevel: 'superadmin',
  Page,
});

const filterDefinitions = {
  status: {
    title: 'Status',
    options: [
      { title: 'All', value: 'all' },
      { title: 'Pending', value: 'pending' },
      { title: 'Accepted', value: 'accepted' },
    ],
  },
};

interface Filters {
  status: 'all' | 'pending' | 'accepted';
}

type State = Filters & { searchTerm: string };

const defaultFilters: State = {
  searchTerm: '',
  status: 'all',
};

function Page() {
  const intl = useIntl();

  return (
    <FixedPage>
      <FixedContent class="bg-white">
        <AdminNav currentPage="invitations" />
        <AdminTabWrapper>
          <AdminTabHeader title="Invitations" />
          <PaginatedList
            fetcher={async (opts, cursor) => {
              const status = (opts as any).status;
              const result = await rpx.admin.getInvitationsAsAdmin({
                searchTerm: opts.searchTerm,
                cursor,
                status,
              });
              return { items: result.items, cursor: result.cursor };
            }}
            initialState={defaultFilters}
            dontRewriteUrl
            searchBox={{
              placeholder: 'By user(id, email) or course(id, title)',
            }}
            tableHeaders={['User', 'Status', 'Course', ' ']}
            filterDefinitions={filterDefinitions}
            renderItem={({ item }: { item: Item }) => (
              <div class="table-row text-inherit hover:bg-indigo-50 text-sm relative">
                <span class="table-cell p-4 border-t">
                  <BtnBasicCopy
                    class="w-full items-start"
                    icon={<span></span>}
                    copiedText={item.userId ? 'Copied User ID' : 'Copied Invitation Link'}
                    value={
                      item.userId ||
                      URLS.student.invitation({
                        course: { id: item.courseId, title: item.courseTitle },
                        invitation: { id: item.id, code: item.code },
                        domain: item.tenantDomain,
                      })
                    }
                  >
                    <span class="flex flex-col text-left grow">
                      <span class="font-semibold" data-private>
                        {item.email}
                      </span>
                      {item.userId && (
                        <span class="flex items-center gap-1.5">
                          <IcoClipboard />
                          User{' '}
                          <span class="font-semibold text-gray-500">{compactId(item.userId)}</span>
                        </span>
                      )}
                      {!item.userId && (
                        <span class="flex items-center gap-1.5 text-indigo-600">
                          <IcoClipboard />
                          Copy invitation link
                        </span>
                      )}
                      {!!item.accessDuration && (
                        <span class="text-gray-500">
                          Access lasts {minutesToTimeString(item.accessDuration, intl)} days after
                          acceptance
                        </span>
                      )}
                    </span>
                  </BtnBasicCopy>
                </span>
                <span class="table-cell p-4 align-top border-t">
                  <span class="flex flex-col">
                    <span class="text-gray-500">{compactDate(item.createdAt)}</span>
                    {item.userId && <span class="text-green-600">Accepted</span>}
                    {!item.userId && <span class="text-gray-600">Pending</span>}
                  </span>
                </span>
                <span class="table-cell p-4 border-t align-top">
                  <span class="flex flex-col">
                    <span class="font-semibold">{item.courseTitle}</span>
                    <BtnBasicCopy value={item.courseId}>
                      Course{' '}
                      <span class="font-semibold text-gray-500">{compactId(item.courseId)}</span>
                    </BtnBasicCopy>
                    <em>Tenant: {item.tenantName}</em>
                  </span>
                </span>
                <span class="table-cell p-4 border-t">
                  <Button
                    class="border bg-gray-50 rounded-md px-2 p-1"
                    onClick={async () => {
                      try {
                        await rpx.admin.mimicUser({ userId: item.guideId });
                        location.assign(
                          URLS.guide.coursePage({
                            courseId: item.courseId,
                            page: `invitations?q=${item.email}`,
                          }),
                        );
                      } catch (err) {
                        showError(err);
                      }
                    }}
                  >
                    Mimic &amp; view
                  </Button>
                </span>
              </div>
            )}
          />
        </AdminTabWrapper>
      </FixedContent>
    </FixedPage>
  );
}
