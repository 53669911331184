import { RpxResponse, rpx } from 'client/lib/rpx-client';
import dayjs from 'dayjs';
import { useState } from 'preact/hooks';
import { Currency } from 'server/types';
import { compactDate } from 'shared/dateutil';
import * as fmt from 'shared/payments/fmt';

type Data = RpxResponse<typeof rpx.guideOverview.getGuideStats>;

type Props = {
  data: Data;
};

type IntervalKeys = 7 | 14 | 30 | 90 | 180 | 365;

const INTERVALS: {
  key: IntervalKeys;
  label: string;
}[] = [
  {
    key: 7,
    label: 'Last week',
  },
  {
    key: 14,
    label: 'Last two weeks',
  },
  {
    key: 30,
    label: 'Last month',
  },
  {
    key: 90,
    label: 'Last three months',
  },
  {
    key: 180,
    label: 'Last six months',
  },
  {
    key: 365,
    label: 'Last year',
  },
];

function StatCard({
  title,
  value = 0,
  change = 0,
  currency,
  comparisionText,
}: {
  title: string;
  value?: number;
  change?: number;
  currency?: Currency;
  comparisionText: string;
}) {
  return (
    <div>
      <hr role="presentation" class="w-full border-t" />
      <div class="mt-6 font-medium">{title}</div>
      <div class="mt-3 text-2xl font-semibold">
        {currency
          ? fmt.price({
              priceInCents: value,
              currency,
            })
          : value}
      </div>
      <div class="mt-3 text-xs">
        {change !== 0 && (
          <span
            class={`inline-flex items-center gap-x-1.5 rounded-md px-1.5 py-0.5 font-medium forced-colors:outline ${
              change > 0 ? 'bg-green-400/20 text-green-700' : 'bg-pink-400/15 text-pink-700'
            }`}
          >
            {currency ? fmt.price({ priceInCents: change, currency }) : change}
          </span>
        )}
        {change === 0 && <span class="text-gray-500">no change</span>}
        <span class="text-gray-500 ml-1">{comparisionText}</span>
      </div>
    </div>
  );
}

export function GuideStats({ data }: Props) {
  const availableCurrencies = data.revenue?.map((r) => r?.currency) || ['USD'];
  const [currency, setCurrency] = useState<Currency>(availableCurrencies[0] || 'USD');
  // Use yearly interval by default
  const [interval, setInterval] = useState(INTERVALS[INTERVALS.length - 1]);
  const intervalStartDay = compactDate(
    dayjs().subtract(interval.key, 'day').startOf('day').toDate(),
  );
  const revenue = data.revenue.find((r) => r?.currency === currency);

  return (
    <>
      <div class="flex flex-col md:flex-row md:items-end justify-between">
        <h2 class="hidden lg:inline text-lg font-semibold text-gray-900">Your overview</h2>
        <div class="flex gap-4">
          {availableCurrencies.length > 1 && (
            <select
              class="inline-ruz-input"
              value={currency}
              onChange={(e: any) => setCurrency(e.target.value)}
            >
              {availableCurrencies.map((c) => (
                <option key={c} value={c}>
                  {c}
                </option>
              ))}
            </select>
          )}
          <select
            class="inline-ruz-input"
            value={interval.key}
            onChange={(e: any) => {
              const selectedKey = parseInt(e.target.value, 10) as IntervalKeys;
              setInterval(INTERVALS.find((i) => i.key === selectedKey)!);
            }}
          >
            {INTERVALS.map(({ key, label }) => (
              <option key={key} value={key}>
                {label}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div class="mt-4 grid gap-8 sm:grid-cols-3">
        <StatCard
          title="Total revenue"
          value={revenue?.totalRevenue || 0}
          change={revenue ? revenue[`revenueInterval${interval.key}`] : 0}
          currency={currency}
          comparisionText={`since ${intervalStartDay}`}
        />
        <StatCard
          title="Average purchase value"
          value={(revenue?.totalRevenue || 0) / (revenue?.totalPurchaseCount || 1)}
          change={
            (revenue?.[`revenueInterval${interval.key}`] || 0) /
            (revenue?.[`purchaseInterval${interval.key}`] || 1)
          }
          currency={currency}
          comparisionText={`since ${intervalStartDay}`}
        />
        <StatCard
          title="Enrolled students"
          value={data.students?.totalCount}
          change={data.students?.[`interval${interval.key}`]}
          comparisionText={`since ${intervalStartDay}`}
        />
      </div>
    </>
  );
}
