import { UserProfileIcon } from '@components/avatars';
import { ComponentChildren } from 'preact';
import { shortTimeAgo, toHumanTime } from 'shared/dateutil';
import { URLS } from 'shared/urls';
import { genericDiscussionCategoryIds } from 'shared/consts';
import { RpxResponse, rpx } from 'client/lib/rpx-client';
import { IcoChat, IcoFile, IcoUser } from '@components/icons';
import { courseLabel } from 'shared/terminology';
import { defaultIntl } from 'shared/intl/use-intl';
import { useCurrentTenant } from 'client/lib/auth';

type Activity = RpxResponse<typeof rpx.guideOverview.getActivitiesFeed>['activities'][number];

type Props = {
  item: Activity;
};

export function ActivityItem({ item }: Props) {
  if (item.type === 'comment') {
    return (
      <Item
        user={item.user}
        action={
          <>
            commented on{' '}
            <a
              href={
                item.lesson
                  ? URLS.student.comment({
                      course: item.course,
                      lesson: item.lesson,
                      commentId: item.id,
                      parentCommentId: item.parentId,
                    })
                  : URLS.student.discussionComment({
                      course: item.course,
                      discussionId: item.discussion.id,
                      categoryId: genericDiscussionCategoryIds.all,
                      commentId: item.id,
                      parentCommentId: item.parentId,
                    })
              }
            >
              {item.discussion.prompt || item.lesson?.title || ''}
            </a>{' '}
            discussion
          </>
        }
        date={item.date}
        course={item.course}
        subtext={item.content}
        icon={<IcoChat class="w-4 h-4" />}
      />
    );
  }

  if (item.type === 'signup') {
    return (
      <Item
        user={item.user}
        action={
          <>
            signed up
            {item.price && (
              <>
                {' '}
                using{' '}
                <a
                  href={URLS.guide.price({
                    courseId: item.course.id,
                    priceId: item.price.id,
                  })}
                >
                  {item.price.name}
                </a>{' '}
                price point
              </>
            )}
          </>
        }
        date={item.date}
        course={item.course}
        icon={<IcoUser class="w-4 h-4" />}
      />
    );
  }

  return (
    <Item
      user={item.user}
      action={
        <>
          submitted an assignment for{' '}
          <a
            href={URLS.guide.assessmentReview({
              type: 'assignment',
              courseId: item.course.id,
              lessonId: item.id,
              userId: item.user.id,
            })}
          >
            {item.title}
          </a>{' '}
          lesson
        </>
      }
      date={item.date}
      course={item.course}
      icon={<IcoFile class="w-4 h-4" />}
    />
  );
}

function Item({
  user,
  action,
  date,
  course,
  subtext,
  icon,
}: {
  user: Activity['user'];
  course: Activity['course'];
  action: ComponentChildren;
  date: Date;
  subtext?: string;
  icon: ComponentChildren;
}) {
  const tenant = useCurrentTenant();

  return (
    <div class="relative flex items-start space-x-3">
      <div class="relative">
        {user.profilePhotoUrl && <UserProfileIcon user={user} size="size-10" />}
        <span
          class={`${
            user.profilePhotoUrl
              ? 'absolute top-1/2 left-1/2 p-1 bg-indigo-500 text-white'
              : 'block ml-1 p-2 bg-indigo-100 text-gray-800'
          } rounded-full border border-white`}
        >
          {icon}
        </span>
      </div>
      <div class="min-w-0 flex-1">
        <div>
          <div class="text-sm">
            <a
              class="font-medium text-gray-900 mr-1"
              href={URLS.guide.student({
                courseId: course.id,
                userId: user.id,
              })}
            >
              {user.name}
            </a>
            <span>{action}</span>
          </div>
          <p class="mt-0.5 text-sm text-gray-500">
            <span class="relative" data-tooltip={toHumanTime(date)}>
              {shortTimeAgo(date)} on{' '}
            </span>
            <a
              class="font-medium text-gray-700"
              href={URLS.guide.course({
                courseId: course.id,
              })}
            >
              {course.title}
            </a>{' '}
            {courseLabel({
              course,
              intl: defaultIntl,
              tenant,
            })}
          </p>
        </div>
        {!!subtext && (
          <div class="mt-2 text-sm text-gray-700">
            <p>{subtext}</p>
          </div>
        )}
      </div>
    </div>
  );
}
