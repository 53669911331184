/*
 * This helper encapculates Sentry methods.
 */
import { RuzukuGlobal, SessionUser } from 'server/types';
import * as Sentry from '@sentry/browser';

let isReplayIntegrated = false;

// Do not record request and response bodies for these paths
const PRIVATE_PATHS = ['auth', 'paypal-integration', 'zapier-integration'];

export function initSentry(configuration: RuzukuGlobal) {
  const { env, version, tenant, user } = configuration;
  if (env !== 'dev') {
    Sentry.init({
      dsn: configuration.sentryDsn,
      environment: env,
      release: version,
      // Sample rate is 0 when the user is logged in as a student.
      // It is 0.2 if there isn't a user yet as we want to start
      // recording as soon as the user logs in.
      replaysSessionSampleRate: user?.level === 'student' ? 0 : 0.2,
      // Record all sessions that throws an error
      replaysOnErrorSampleRate: 1.0,
      integrations: [],
    });
    Sentry.getCurrentScope().setExtra('tenant', tenant.id);
  }
}

export function setSentryUser(user?: SessionUser) {
  // Add Replay integration for all logged in users.
  // Students will be filtered out by the sample rate for regular sessions.
  // And we will be able to see all sessions that throws an error.
  if (user && !isReplayIntegrated) {
    import('@sentry/browser').then((lazyLoadedSentry) => {
      Sentry.addIntegration(
        lazyLoadedSentry.replayIntegration({
          stickySession: true,
          maskAllText: false,
          blockAllMedia: false,
          maskAllInputs: false,
          // Mask texts with the following patterns
          mask: ['[data-private]'],
          // Ignore inputs with the following patterns
          ignore: ['[data-private]'],

          // We want to record all /rpx/* requests except `${PRIVATE_PATHS}`.
          // Sentry API is a bit weird for that case as they record the request
          // if it matches just one of the patterns in the array.
          // So we need to do it all in a single regex.
          networkDetailAllowUrls: [
            new RegExp(`^${window.location.origin}/rpx/(?!${PRIVATE_PATHS.join('|')}/)(.*)$`),
          ],
          // Extra headers to record
          networkResponseHeaders: ['cf-ray', 'cf-cache-status', 'rndr-id'],
        }),
      );
    });
    isReplayIntegrated = true;
  }

  Sentry.setUser(
    user
      ? {
          id: user.id,
          username: user.name,
          segment: user.level,
          timezone: user.timezone,
        }
      : null,
  );
}

export function setSentryExtra(key: string, value: string) {
  Sentry.getCurrentScope().setExtra(key, value);
}

export function captureException(err: Error) {
  Sentry.captureException(err);
}
