export type Align = 'left' | 'center' | 'right';

export type RenderOpts = {
  state: MediaState;
};

/**
 * The shape of the JSON embedded in the media-card.
 */
export interface MediaState {
  /**
   * If set, this is the size the user has specified, overriding width.
   * It is a percent, for example, 120, 100, 50, 25
   */
  size?: string;

  /**
   * If set, this is the alignment (left, center, right).
   */
  align?: Align;
}

/**
 * The styles which will be applied to the image container when the user
 * selects a different image alignment.
 */
export const imageAlign: Record<Align, any> = {
  left: {
    float: 'left',
    marginRight: '2rem',
    zIndex: '5',
    clear: 'left',
  },
  right: {
    float: 'right',
    paddingLeft: '2rem',
    marginLeft: '2rem',
    zIndex: '5',
    boxSizing: 'content-box',
    clear: 'right',
  },
  center: {
    float: 'none',
    marginRight: 'auto',
    marginLeft: 'auto',
    clear: 'both',
  },
};

interface ImageWidthConfig {
  width: string;
  marginLeft?: string;
  dontApplyOnMobile?: boolean;
}

/**
 * The styles which will be applied to the image container when the user
 * selects a different image size.
 */
export const imageWidth: Record<number, ImageWidthConfig> = {
  120: {
    width: '120%',
    marginLeft: '-10%',
    // 120% breaks the layout on mobile, so we don't apply it there.
    dontApplyOnMobile: true,
  },
  100: {
    width: '100%',
  },
  50: {
    width: '50%',
  },
  25: {
    width: '25%',
  },
};

/**
 * This is an object which clears all the styles defined by imageWidth or
 * imageAlign.
 */
const unset = Object.values(imageWidth)
  .concat(Object.values(imageAlign))
  .reduce((acc: any, obj) => {
    Object.keys(obj).forEach((k) => {
      acc[k] = null;
    });
    return acc;
  }, {});

export const getSize = (opts: RenderOpts) => opts.state.size || '100';
export const getAlign = (opts: RenderOpts) => opts.state.align || 'center';

/**
 * Apply the image size and alignment rules to the image container element.
 */
export function getContainerStyle(opts: { isMobile: boolean; state: MediaState }) {
  const percent = parseInt(getSize(opts));
  let containerStyles: any = { ...unset, clear: 'both' };
  if (percent < 100) {
    containerStyles = { ...containerStyles, ...imageAlign[getAlign(opts)] };
  }
  const widthConfig = imageWidth[percent as keyof typeof imageWidth];
  if (!opts.isMobile || !widthConfig.dontApplyOnMobile) {
    containerStyles = { ...containerStyles, ...widthConfig };
  }
  return containerStyles;
}
