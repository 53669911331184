import { ComponentChildren } from 'preact';
import { RootPageLayout } from '@components/root-page-layout';

export function UpsellsPage({
  title = 'Upsells',
  children,
  buttons,
}: {
  title?: string;
  children: ComponentChildren;
  buttons?: ComponentChildren;
}) {
  return (
    <RootPageLayout title={title} activeTab="upsells" actions={buttons}>
      <div class="mt-6">{children}</div>
    </RootPageLayout>
  );
}
