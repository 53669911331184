import { showError } from '@components/app-error';
import { rpx } from 'client/lib/rpx-client';
import { UserProfileIcon } from '@components/avatars';
import { IcoUserGroup } from '@components/icons';
import { truncateId } from 'shared/utils';
import { Pill } from '@components/pill';
import { describeAccessFormat } from 'client/utils/courses';
import { compactDate } from 'shared/dateutil';
import { PaginatedList } from '@course/components/tenants';
import { Button } from '@components/buttons';
import { Case } from '@components/conditional';
import { useMemo } from 'preact/hooks';
import { Data } from './load';
import { useCurrentTenant } from 'client/lib/auth';

type TenantCourseOptions = Parameters<typeof rpx.tenants.getTenantCourses>[0];
type SearchOpts = Omit<TenantCourseOptions, 'cursor'>;
type Cursor = TenantCourseOptions['cursor'];

type TypeProps =
  | { type: 'user'; userId: UUID; canMimic: boolean }
  | {
      type: 'instantCourseSelections';
    }
  | {
      type: 'allWithNoData';
    }
  | (Data & { type: 'all'; searchTerm?: string; cursor: Cursor });

type Props = TypeProps & {
  dontRewriteUrl?: boolean;
  onItemSelect?: (item: Data['courses'][0]) => void;
};

const staticFilterDefinitions = {
  orderBy: {
    title: 'Sort by',
    options: [
      { title: 'Created at', value: 'createdAt' },
      { title: 'Title', value: 'title' },
      { title: 'Number of students', value: 'numStudents' },
    ],
  },
  status: {
    title: 'Status',
    options: [
      { title: 'All', value: 'all' },
      { title: 'Draft', value: 'draft' },
      { title: 'Published', value: 'published' },
      { title: 'Closed', value: 'closed' },
    ],
  },
  type: {
    title: 'Type',
    options: [
      { title: 'All', value: 'all' },
      { title: 'Drip (Individual)', value: 'ondemand' },
      { title: 'Full Access', value: 'openaccess' },
      { title: 'Drip (Calendar)', value: 'scheduled' },
    ],
  },
};

const defaultFilters: SearchOpts = {
  searchTerm: '',
  secondSearchTerm: '',
  orderBy: 'createdAt',
  status: 'all',
  type: 'all',
  brandId: 'all',
  membership: 'all',
};

export function TenantCoursesList(props: Props) {
  const { type } = props;
  const canMimic = type === 'user' && props.canMimic;
  const tenant = useCurrentTenant();
  const filterDefinitions = useMemo(() => {
    if (props.type !== 'all' || tenant.isCore) {
      return staticFilterDefinitions;
    }
    return {
      ...staticFilterDefinitions,
      brandId: {
        title: 'Brand',
        options: [
          { title: 'All', value: 'all' },
          ...props.brands.map((x) => ({ title: x.name, value: x.id })),
        ],
      },
    };
  }, []);

  const mimicAndView = async (courseId: UUID) => {
    if (type !== 'user') {
      return;
    }
    try {
      await rpx.admin.mimicUser({ userId: props.userId });
      location.assign(`/courses/${courseId}`);
    } catch (err) {
      showError(err);
    }
  };

  return (
    <PaginatedList
      fetcher={async (opts: any, cursor) => {
        const result = await rpx.tenants.getTenantCourses({
          forUserId: type === 'user' ? props.userId : undefined,
          ...opts,
          isTemplate: type === 'instantCourseSelections' ? true : false,
          cursor,
        });
        return { items: result.courses, cursor: result.cursor || '' };
      }}
      initialState={defaultFilters}
      searchBox={{
        prefix: 'Course:',
        placeholder: 'By id or title',
      }}
      secondSearchBox={{
        prefix: 'Guide:',
        placeholder: 'By id, name, or email',
      }}
      tableHeaders={['Courses']}
      cursor={type === 'all' ? props.cursor : undefined}
      filterDefinitions={filterDefinitions}
      items={type === 'all' ? props.courses : undefined}
      dontRewriteUrl={props.dontRewriteUrl}
      renderItem={({ item }) => {
        const isRemovedFromCourse = type === 'user' && !item.hasAccess;

        return (
          <span class="table-row">
            <span class="table-cell relative">
              <Button
                class="w-full block text-inherit hover:bg-indigo-50 text-sm p-4 text-gray-500"
                href={props.onItemSelect ? undefined : `/admin/courses/${item.id}`}
                onClick={() => props.onItemSelect?.(item)}
              >
                <span class="flex flex-col space-y-2">
                  <span class="flex justify-between items-start">
                    <strong class={`block ${isRemovedFromCourse ? 'opacity-50' : ''}`}>
                      {item.title}
                    </strong>
                    <span class="inline-flex items-center">
                      <span class="inline-block text-right space-x-2">
                        <Case
                          when={!isRemovedFromCourse}
                          fallback={<Pill color="red">Removed</Pill>}
                        >
                          {item.isProduct && <Pill color="blue">Product</Pill>}
                          {item.isBundle && <Pill color="yellow">Bundle</Pill>}
                          {item.status === 'draft' && <Pill color="gray">Draft</Pill>}
                          {item.status === 'published' && <Pill color="green">Published</Pill>}
                        </Case>
                      </span>
                      {canMimic && !isRemovedFromCourse && (
                        // This is a placeholder to make room for the absolutely positioned
                        // button futher down
                        <span class="mx-4 invisible inline-block">Mimic &amp; view</span>
                      )}
                    </span>
                  </span>
                  <span class="flex items-center justify-between whitespace-nowrap">
                    <span class="inline-flex items-center space-x-2">
                      <UserProfileIcon user={item.guide} size="w-6 h-6 text-xs" />
                      <span>{item.guide.name}</span>
                      <span class="border-l pl-2" data-private>
                        {item.guide.email}
                      </span>
                    </span>
                    <span class="flex items-center space-x-4 ml-2">
                      <span>
                        <strong class="text-xs">ID: </strong>
                        <span class="font-mono">{truncateId(item.id)}</span>
                      </span>
                      <span class="inline-flex border-l pl-4 items-center w-20 justify-end">
                        <IcoUserGroup class="w-4 h-4 mr-1 opacity-75" />
                        {item.numStudents || 0}
                      </span>
                      <span class="border-l pl-4 inline-block w-24 line-clamp-1 text-ellipsis">
                        {describeAccessFormat(item.accessFormat)}
                      </span>
                      <span class="border-l pl-4 inline-block w-32 text-right">
                        Created {compactDate(item.createdAt)}
                      </span>
                    </span>
                  </span>
                </span>
              </Button>
              {canMimic && !isRemovedFromCourse && (
                <Button
                  class="absolute top-0 right-0 bottom-0 group flex items-start p-4"
                  onClick={() => mimicAndView(item.id)}
                >
                  <span class="inline-block -mt-0.5 px-2 text-sm py-1 bg-gray-50 border group-hover:bg-indigo-600 group-hover:text-white group-hover:border-indigo-600 rounded-sm whitespace-nowrap">
                    Mimic &amp; view
                  </span>
                </Button>
              )}
            </span>
          </span>
        );
      }}
    />
  );
}
