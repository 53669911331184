import { rpx } from 'client/lib/rpx-client';
import { Spinner } from '@components/spinner';
import { useAsyncData } from 'client/lib/hooks';
import { AccountTabContent } from '@components/password-form';
import { useIntl } from 'shared/intl/use-intl';
import { Case } from '@components/conditional';
import { fmtFullTime } from 'shared/dateutil';
import { Pill } from '@components/pill';
import { BtnCopy } from '@components/buttons';

export function GiftsTab() {
  const { data: gifts, isLoading } = useAsyncData(() => rpx.gifts.getMyGifts(), []);
  const intl = useIntl();

  if (isLoading || !gifts) {
    return (
      <div class="p-8 text-center">
        <Spinner class="border-indigo-400 w-16 h-16 inline-block" />
      </div>
    );
  }

  return (
    <AccountTabContent title={intl('Gifts')}>
      <div class="text-gray-600">
        <Case
          when={gifts.length > 0}
          fallback={<div>{intl("You haven't gifted anything yet.")}</div>}
        >
          <div class="max-w-(screen-16) overflow-x-auto">
            <table class="mb-4 text-sm table-auto overflow-x w-full">
              <tr>
                <th>{intl('Gift')}</th>
                <th>{intl('Status')}</th>
                <th>{intl('Purchase Date')}</th>
                <th></th>
              </tr>
              {gifts.map((g) => (
                <tr key={g.id}>
                  <td class="table-cell">
                    <p>{g.course.title}</p>
                    <p class="italic">{g.email}</p>
                  </td>
                  <td class="table-cell">
                    {g.toUserId && <Pill color="green">{intl('Accepted')}</Pill>}
                    {!g.toUserId && <Pill color="gray">{intl('Pending')}</Pill>}
                  </td>
                  <td class="table-cell">{fmtFullTime(g.createdAt)}</td>
                  <td class="table-cell">
                    <div class="flex justify-between">
                      {!g.toUserId && (
                        <BtnCopy class="text-indigo-600" value={g.url}>
                          {intl('Copy gift link')}
                        </BtnCopy>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </table>
          </div>
        </Case>
      </div>
    </AccountTabContent>
  );
}
