/**
 * This component manages the sign in screen for the course enrollment
 * process. If the user is already signed in, this screen is skipped.
 *
 * We ask the user for their email address. If the email is associated
 * with an existing account, we show the login form. If the email is not
 * associated with with an account, we show the registration form.
 */

import { Dispatch, StateUpdater, useState } from 'preact/hooks';
import { rpx, RpxResponse } from 'client/lib/rpx-client';
import { BtnPrimary, BtnSecondary } from '@components/buttons';
import { AsyncForm, FormGroupTwoCol } from '@components/async-form';
import { showToast } from '@components/toaster';
import { UserProfileIconChanger } from '@components/avatars';
import { TimezoneModal } from '@components/timezone-modal';
import { IcoChevronDown } from '@components/icons';
import { User } from 'server/types';
import { useAsyncEffect } from 'client/utils/use-async-effect';
import { showError } from '@components/app-error';
import { LoadingIndicator } from '@components/loading-indicator';
import { BioEditor } from '@course/components/bio-editor';
import { useIntl } from 'shared/intl/use-intl';
import { generateDisplayName } from 'client/lib/user-display-name/generate-display-name';
import { AccountTabContent } from '@components/password-form';
import { useAuth } from 'client/lib/auth';

const store = rpx.auth;

export function ProfileTab(props: {
  user: User;
  setUser: Dispatch<StateUpdater<User | undefined>>;
}) {
  const auth = useAuth();
  const intl = useIntl();
  const [timezoneModalOpen, setTimezoneModalOpen] = useState(false);
  const { user, setUser } = props;
  const [fields, setFields] = useState<
    undefined | RpxResponse<typeof rpx.profileFields.getStudentProfileFields>
  >(undefined);

  const setField = (id: UUID, value: string) => {
    setFields((xs) => xs?.map((x) => (x.id === id ? { ...x, value } : x)));
  };

  useAsyncEffect(async () => {
    try {
      const result = await rpx.profileFields.getStudentProfileFields({});
      setFields(result);
    } catch (err) {
      showError(err);
    }
  }, []);

  return (
    <AccountTabContent title="Account">
      {timezoneModalOpen && (
        <TimezoneModal
          timezone={user.timezone}
          hide={() => setTimezoneModalOpen(false)}
          onPick={(timezone) => setUser((s) => ({ ...s!, timezone }))}
        />
      )}
      {/* Profile picture */}
      <FormGroupTwoCol prop="profile-picture" labelText={intl('Profile picture')}>
        <UserProfileIconChanger user={user} size="w-20 h-20 text-2xl" />
      </FormGroupTwoCol>
      {!fields && <LoadingIndicator />}
      {fields && (
        <AsyncForm
          onSubmit={async (data) => {
            const { id } = user;
            const displayName = data.displayName || generateDisplayName(user.name);

            await Promise.all([
              store.updateUser({ ...data, displayName, bio: user.bio, id }),
              fields.length
                ? rpx.profileFields.saveStudentProfileFields({ profileFields: fields })
                : Promise.resolve(),
            ]);
            setUser((x) => ({ ...x, ...data }));

            if (!user.metadata?.updatedProfile) {
              await auth.updateUserMetadata({ updatedProfile: true });
            }

            showToast({
              type: 'ok',
              title: intl('Updated'),
              message: intl('Settings saved'),
            });
          }}
        >
          {/* Full name */}
          <FormGroupTwoCol prop="name" labelText={intl('Full Name')}>
            <input
              type="text"
              name="name"
              required
              class="ruz-input"
              value={user.name}
              onInput={(e: any) => setUser((u) => ({ ...u!, name: e.target.value }))}
            />
          </FormGroupTwoCol>

          {/* Preferred name */}
          <FormGroupTwoCol
            prop="displayName"
            labelText={intl('Display Name')}
            subText={intl('This is a short name that will be used in your email notifications.')}
          >
            <input
              type="text"
              name="displayName"
              class="ruz-input"
              placeholder={generateDisplayName(user.name)}
              value={user.displayName}
              onInput={(e: any) => setUser((u) => ({ ...u!, displayName: e.target.value }))}
            />
          </FormGroupTwoCol>

          {/* Email */}
          <FormGroupTwoCol prop="email" labelText={intl('Email')}>
            <input
              type="text"
              name="email"
              required
              class="ruz-input"
              value={user.email}
              data-private
              onInput={(e: any) => setUser((u) => ({ ...u!, email: e.target.value }))}
            />
          </FormGroupTwoCol>

          <FormGroupTwoCol
            prop="bio"
            labelText={intl('Short Bio')}
            subText={intl('Your biography is PUBLIC and will be shared with other users.')}
          >
            <BioEditor
              value={user.bio || ''}
              onChange={(bio) => setUser((u) => ({ ...u!, bio }))}
            />
          </FormGroupTwoCol>

          {/* Timezone */}
          <FormGroupTwoCol prop="timezone" labelText={intl('Timezone')}>
            <input type="hidden" name="timezone" value={user.timezone} />
            <BtnSecondary onClick={() => setTimezoneModalOpen(true)}>
              <span class="mr-2">{user.timezone}</span>
              <IcoChevronDown />
            </BtnSecondary>
          </FormGroupTwoCol>

          {fields.map((f) => (
            <FormGroupTwoCol prop={f.id} labelText={f.label} key={f.id}>
              <input
                type="text"
                class="ruz-input"
                required
                value={f.value}
                placeholder={f.label}
                onInput={(e: any) => setField(f.id, e.target.value)}
              />
            </FormGroupTwoCol>
          ))}

          <BtnPrimary class="rounded-full mt-8 px-8">{intl('Update')}</BtnPrimary>
        </AsyncForm>
      )}
    </AccountTabContent>
  );
}
