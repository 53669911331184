import { UserProfileIcon } from '@components/avatars';
import { ReadonlyMinidoc } from '@components/minidoc/readonly-minidoc';
import { Pill } from '@components/pill';
import { CourseWithGuide } from 'server/types';
import { useIntl } from 'shared/intl/use-intl';

interface Props {
  guide: CourseWithGuide['guide'];
}

export function GuideCard({ guide }: Props) {
  const intl = useIntl();

  return (
    <div class="flex lg:flex-col items-center dark:bg-gray-800 dark:text-gray-100 sm:rounded-lg relative px-4">
      <div>
        <UserProfileIcon user={guide} size="w-20 h-20 lg:w-24 lg:h-24 text-2xl" />
      </div>
      <div class="ml-6 lg:ml-0 lg:mt-2">
        <div class="flex items-center md:mb-1">
          <h2 class="text-lg text-gray-600 dark:text-gray-300 font-bold lg:text-2xl mr-2">
            {guide.name}
          </h2>
          <Pill>{intl('Guide')}</Pill>
        </div>
        <div class="text-sm space-y-2">
          {guide.bio && <ReadonlyMinidoc content={guide.bio} />}
          <p data-private>
            {intl('Questions? Comments?')}{' '}
            <a class="dark:text-sky-500" href={`mailto:${guide.email}`}>
              {intl('Email Me')}
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}
