import { UpsellsPage } from './upsells-page';
import { Button } from '@components/buttons';
import { IcoArrowLeft } from '@components/icons';
import { showError } from '@components/app-error';
import { rpx } from 'client/lib/rpx-client';
import { ApplyToTab } from './apply-to-tab';
import { FullUpsell } from './types';
import { OffersTab } from './offers-tab';
import { SignupsTab } from './signups-tab';
import { Note } from '@course/components/note';
import { UpsellHeader } from './upsell-header';
import { LoadedProps, RouteLoadProps } from '@components/router';
import { defCoursesRoute } from '@course/components/courses-app-router';

type Tab = 'offers' | 'applyto' | 'signups';

type State = {
  upsell: FullUpsell;
};

export const route = defCoursesRoute({
  Page,
  authLevel: 'guide',
  load,
});

async function load(props: RouteLoadProps): Promise<State> {
  const upsellId = props.params.id;
  const upsell = await rpx.upsells.getUpsell({ upsellId });
  if (!upsell) {
    throw new Error(`Upsell not found!`);
  }
  rpx.upsells.updateUpsellOpenedAt({ upsellId }).catch(console.error);
  return { upsell };
}

type Props = LoadedProps<typeof load>;

function Tab(props: { href: string; text: string; count: number; isSelected: boolean }) {
  return (
    <Button
      href={props.href}
      class={`flex items-center gap-2 rounded-md font-semibold p-1 px-4 ${
        props.isSelected ? 'bg-indigo-100 text-indigo-800' : 'hover:bg-gray-100 text-inherit'
      }`}
    >
      <span>{props.text}</span>
      <span
        class={`hidden rounded-full px-2.5 text-xs font-medium sm:inline-flex items-center ml-auto ${
          props.isSelected ? 'bg-indigo-200' : 'bg-gray-100'
        }`}
      >
        {props.count}
      </span>
    </Button>
  );
}

function Page(props: Props) {
  const { setState } = props;
  const { upsell } = props.state;
  const tab: Tab = (props.params.tab as Tab) || 'offers';

  return (
    <UpsellsPage title={upsell.title}>
      <section class="flex flex-col gap-4">
        <header class="flex flex-col py-4 border-b">
          <nav>
            <Button href="/upsells" class="gap-2 inline-flex items-center text-gray-500 text-sm">
              <IcoArrowLeft /> Back to upsells
            </Button>
          </nav>
          <UpsellHeader
            upsell={upsell}
            setUpsell={(fn) => setState((x) => ({ ...x, upsell: fn(x.upsell) }))}
          />
        </header>

        <div class="flex gap-10 py-6">
          <nav class="flex flex-col gap-4 min-w-60">
            <Tab
              href="?tab=offers"
              isSelected={tab === 'offers'}
              text="Offers"
              count={upsell.numOffers}
            />
            <Tab
              href="?tab=applyto"
              isSelected={tab === 'applyto'}
              text="Apply to"
              count={upsell.numAppliedPrices}
            />
            <Tab
              href="?tab=signups"
              isSelected={tab === 'signups'}
              text="Signups"
              count={upsell.numSignups}
            />
          </nav>

          <section class="flex flex-col gap-4 grow">
            {!upsell.numAppliedPrices && (
              <Note>
                This upsell is not applied to any courses. It won't be offered to anyone until you{' '}
                <a class="underline" href="/upsells/up-S7zTbt-Qxgu2-c60DLFdUw?tab=applyto">
                  apply the upsell
                </a>{' '}
                to a course or product.
              </Note>
            )}
            {tab === 'applyto' && <ApplyToTab upsell={upsell} />}
            {tab === 'offers' && (
              <OffersTab
                upsell={upsell}
                offers={upsell.offers.map((o) => ({ ...o, id: o.productId }))}
                onReorder={(offers) => {
                  rpx.upsells
                    .reorderOffers({
                      upsellId: upsell.id,
                      productIds: offers.map((o) => o.productId),
                    })
                    .catch(showError);
                  setState((s) => ({ ...s, upsell: { ...s.upsell, offers } }));
                }}
              />
            )}
            {tab === 'signups' && <SignupsTab upsell={upsell} />}
          </section>
        </div>
      </section>
    </UpsellsPage>
  );
}
