import { Button } from '@components/buttons';
import { Case } from '@components/conditional';
import { IcoBook, IcoUsers } from '@components/icons';
import { useRouteParams } from '@components/router';
import { useAsyncData } from 'client/lib/hooks';
import { courseLabel } from 'shared/terminology';
import { ComponentChildren } from 'preact';
import { Course } from 'server/types';
import * as fmt from 'shared/payments/fmt';
import { URLS } from 'shared/urls';
import { useCurrentTenant } from '@components/router/session-context';
import { rpx } from 'client/lib/rpx-client';
import { PageTitle } from '@course/components/headings';

const store = rpx.courseOverview;

interface CardProps {
  label: string;
  value?: string | number;
  diff?: string | number;
  isIncrease?: boolean;
  icon: ComponentChildren;
  href?: string;
}

function StatsCard({ href, label, value, diff, isIncrease, icon }: CardProps) {
  return (
    <Button
      href={href}
      class="relative text-left border border-gray-200 p-6 rounded-lg hover:bg-gray-50 text-inherit"
      data-tooltip={
        isIncrease ? `Increased by ${diff} in the last week` : 'No change in the last week'
      }
    >
      <span>
        <span class="absolute bg-indigo-500 rounded-md p-3">{icon}</span>
        <span class="ml-16 text-sm font-medium text-gray-500 truncate">{label}</span>
      </span>
      <span class="ml-16 flex flex-col">
        <span class="text-xl font-semibold text-gray-900">{value || '-'}</span>
        <span class="flex space-x-2 items-center">
          <span class="text-sm font-light text-gray-500">vs last week:</span>
          <Case
            when={isIncrease !== undefined}
            fallback={<span class="mb-1 flex items-baseline text-sm font-semibold">-</span>}
          >
            <Case when={isIncrease} fallback={<span>no change</span>}>
              <span class="text-green-600 flex items-baseline text-sm font-semibold">+{diff}</span>
            </Case>
          </Case>
        </span>
      </span>
    </Button>
  );
}

export function CourseStats({
  course,
  hideLessons,
  class: className = '',
}: {
  course: Pick<Course, 'isBundle'>;
  hideLessons?: boolean;
  class?: string;
}) {
  const { courseId } = useRouteParams();
  const tenant = useCurrentTenant();
  const { data } = useAsyncData(() => store.getCourseStats({ courseId }), []);

  const revenueHref = URLS.guide.prices({ courseId });

  return (
    <div class={className}>
      <PageTitle>
        {courseLabel({
          course,
          tenant,
        })}{' '}
        stats
      </PageTitle>
      <p class="text-sm pt-2 pb-6 text-gray-500">
        Revenue is calculated from purchases only made through Ruzuku.
      </p>
      <div class={`grid grid-cols-1 md:grid-cols-2 ${hideLessons ? '' : 'lg:grid-cols-3'} gap-5`}>
        <Case
          when={data && data.revenue.length > 0}
          fallback={
            <StatsCard
              label="Revenue"
              href={revenueHref}
              value={fmt.price({ priceInCents: 0, currency: 'USD' })}
              diff={0}
              isIncrease={false}
              icon={
                <span class="flex items-center justify-center text-white text-lg w-6 h-6">$</span>
              }
            />
          }
        >
          {data &&
            data.revenue.map((r) => (
              <StatsCard
                key={r.currency}
                label="Revenue"
                href={revenueHref}
                value={fmt.price({ priceInCents: r.amount, currency: r.currency })}
                diff={fmt.price({ priceInCents: r.lastWeek, currency: r.currency })}
                isIncrease={r.lastWeek > 0}
                icon={
                  <span class="flex items-center justify-center text-white text-lg w-6 h-6">
                    {fmt.currencySymbol(r.currency)}
                  </span>
                }
              />
            ))}
        </Case>
        <StatsCard
          label="Enrolled Students"
          href={URLS.guide.students({ courseId })}
          value={data?.enrollments.count}
          diff={data?.enrollments.lastWeek}
          isIncrease={data && data?.enrollments.lastWeek > 0}
          icon={<IcoUsers class="w-6 h-6 text-white" />}
        />
        {!hideLessons && (
          <StatsCard
            label="Completed Lessons"
            href={URLS.guide.lessons({ courseId })}
            value={data?.completedLessons.count}
            diff={data?.completedLessons.lastWeek}
            isIncrease={data && data.completedLessons.lastWeek > 0}
            icon={<IcoBook class="w-6 h-6 text-white" />}
          />
        )}
      </div>
    </div>
  );
}
