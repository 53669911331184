interface UrlParams {
  courseId: UUID;
  meetingId?: UUID;
  page?: string;
}

const baseUrl = (params: Pick<UrlParams, 'courseId'>) => `/manage/courses/${params.courseId}`;

/**
 * Create a meeting URL with the parameters populated.
 */
export const meetingUrl = (params: UrlParams) =>
  `${baseUrl(params)}/meetings/${params.meetingId ? params.meetingId + '/' : ''}${
    params.page || ''
  }`;
