/**
 * Temporary:
 *
 * This file contains upsell info for the upcoming May / June Ruzuku promos and
 * should be removed after that promo completes.
 */

export type UpsellOffer = {
  /**
   * A human-friendly name for the offer (e.g. 'upsell-one-on-one-coaching').
   * This is not globally unique, but should be unique in the set of upsells.
   * Should start with "upsell-"
   */
  name: string;
  /**
   * The name we'll display when summarizing the service.
   */
  displayName: string;
  /**
   * The price of the offer as an integer.
   */
  priceInCents: number;
  /**
   * The promotional verbiage, etc that we'll show the user.
   */
  markup: string;

  /**
   * The text of the accept button.
   */
  acceptText: string;

  /**
   * The text of the reject button.
   */
  rejectText: string;
};

export const offers: UpsellOffer[] = [
  {
    name: 'upsell-course-business-jumpstart-with-abe',
    displayName: 'Course Business Jumpstart With Abe',
    priceInCents: 29700,
    markup: `
      <h2 class="text-3xl font-semibold mb-2">
        Course Business Jumpstart with Abe ($297)
      </h2>
      <p class="text-gray-500">
        In this private mentoring experience, you’ll get expert help to define your course strategy, design your course, implement your course, or market it effectively. We’ll focus on what’s most valuable for you!
      </p>
      <p>There are three components to this mentoring experience (a $297 investment):</p>
      <ol class="list-disc ml-4 flex flex-col gap-2">
        <li><b class="font-semibold">Intake:</b> <span class="text-gray-500">Clarify your situation, goals, and challenges through a strategic exploration.</span></li>
        <li><b class="font-semibold">Mentoring:</b> <span class="text-gray-500">A 60-minute mentoring session (via video call) to unpack your questions and challenges — and collaboratively identify solutions.</span></li>
        <li><b class="font-semibold">Follow-up:</b> <span class="text-gray-500">You’ll get a full recording and transcript of the mentoring session. And you’ll have my direct email, so you can ask me follow-up questions or get feedback on any of your ideas.</span></li>
      </ol>
      <p>
        Plus, I’ll invite you to any of our private courses that are relevant to the questions we discuss in our session — free of charge :)
      </p>
      <p>So to help you do that, we're giving you this one time offer!</p>
     `,
    acceptText: 'Yes! I want private mentoring with Abe ⤑',
    rejectText:
      'No thanks, just complete my purchase. I understand this offer will not be available after I leave this page.',
  },
  {
    name: 'upsell-done-with-you',
    displayName: 'Done With You',
    priceInCents: 49700,
    markup: `
      <h2 class="text-3xl font-semibold mb-2">
        ProCourse “Done With You” Service ($497)
      </h2>
      <p class="text-gray-500">
        Ready to get started FAST with concierge setup & support?
      </p>
      <p>Imagine that just a few weeks from now…</p>
      <p>You’ve already launched a new, high-quality online course… and your students love it.</p>
      <p>And imagine that you could have an expert help you set it up and customize it so you didn’t have to worry about doing all of it yourself.</p>
      <p>In short: you were able to quickly launch a high-quality, lead-generating course… and with minimal effort.</p>
      <p>If that sounds appealing, we’d love to support you with our ProCourse “Done With You” Service (a $497 investment).</p>
      <p>You’ll meet with Ruzuku’s CEO, Abe Crystal for a 30-minute kickoff session to define your course goals and identify where you need support.</p>
      <p>Then, Ruzuku’s concierge service team will jump into action, meeting with you for 4 fast-paced calls to help you set up and customize an Instant Course (or upload your own course, if you prefer!) We’ll walk you through every step, AND we’ll “teach you how to fish” so you can create and manage courses on your own in the future.</p>
      <p>We look forward to seeing your course launch very soon!</p>
     `,
    acceptText: 'Yes, accelerate my courses with hands-on concierge setup ⤑',
    rejectText:
      'No thanks, just complete my purchase. I understand this offer will not be available after I leave this page.',
  },
];
