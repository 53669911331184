import { Dropdown, MenuItem } from '@components/dropdown';
import { useRef } from 'preact/hooks';

/**
 * A dropdown that mimics an HTML select component.
 */
export function Filter({
  label,
  options,
  name,
  value,
  class: className,
}: {
  class?: string;
  label: string;
  name: string;
  value: string;
  options: Array<{ title: string; value: string }>;
}) {
  const option = options.find((o) => o.value === value);
  const input = useRef<HTMLInputElement>();

  return (
    <Dropdown
      class={`bg-gray-50 text-gray-700 border border-r-0 border-gray-300 rounded-none inline-flex h-full whitespace-nowrap text-sm first:rounded-l last:rounded-r last:border-r ${className}`}
      position="left-0 top-full mt-2"
      triggerClass="px-4 p-2"
      renderMenu={() => (
        <>
          {/*
            This span is a hack to prevent triggering the underlying row's hover effect
            when the user's mouse moves from the menu trigger to the flyout.
          */}
          <span class="absolute -top-4 left-0 right-0 h-4 opacity-0"></span>
          <h3 class="uppercase text-xs font-bold tracking-wider text-gray-400 border-b p-2 px-4">
            {label}
          </h3>
          {options.map((o) => (
            <MenuItem
              key={o.value}
              class={`${o.value === value ? 'font-bold' : ''} p-2 px-4 text-left hover:bg-gray-50`}
              onClick={() => {
                const txt = input.current;
                if (txt) {
                  txt.value = o.value;
                  const e = new CustomEvent('input', {
                    bubbles: true,
                  });
                  txt.dispatchEvent(e);
                }
              }}
            >
              {o.title}
            </MenuItem>
          ))}
        </>
      )}
    >
      <input type="hidden" name={name} value={value} ref={(x) => x && (input.current = x)} />
      <span class="mr-1 opacity-75">{label}:</span>
      <span class="lowercase">{option?.title}</span>
    </Dropdown>
  );
}
