import { Cardable, MinidocCardDefinition } from 'minidoc-editor/dist/types';
import { h } from 'minidoc-editor';
import { showRawHtmlModal } from './raw-html-modal';
import { Minidoc, mediaContextMenu } from 'client/lib/minidoc';

interface RawHtmlProps {
  content: string;
}

/**
 * The minidoc card responsible for rendering arbitrary HTML.
 */
export const rawHtmlCard: MinidocCardDefinition<RawHtmlProps> = {
  type: 'raw',

  /**
   * This card will take over any element that matches this selector.
   */
  selector: 'raw-html',

  /**
   * Derive the state from the specified element.
   */
  deriveState(el) {
    return { content: el.innerHTML };
  },

  /**
   * Convert the specified state to HTML.
   */
  serialize({ state }) {
    return `<raw-html>${state.content}</raw-html>`;
  },

  /**
   * Convert the specified state to a DOM element.
   */
  render(opts) {
    const el = h('raw-html', { innerHTML: opts.state.content });

    if (!opts.readonly) {
      const contextMenu = mediaContextMenu(
        { label: 'HTML' },
        h('button', {
          onclick() {
            showRawHtmlModal({ content: opts.state.content, isEdit: true }).then((result) => {
              if (!result) {
                return;
              }
              // We're going to replace the current raw-html card with the new
              // embed-html card on save. We do this with selection, rather than
              // direct DOM manipulation, since this approach allows the editor
              // to properly manage the insertion, save-state, undo / redo, etc.
              const editor = opts.editor as unknown as Minidoc & Cardable;
              const sel = document.getSelection()!;
              const range = document.createRange();
              range.selectNode(el);
              sel.removeAllRanges();
              sel.addRange(range);
              editor.insertCard('embed', result);
            });
          },
          type: 'button',
          class: 'p-2',
          // Pencil icon
          innerHTML:
            '<svg viewBox="0 0 20 20" fill="currentColor" class="h-5 w-5"><path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" /></svg>',
        }),
      );

      el.append(contextMenu);
    }

    return el;
  },
};
