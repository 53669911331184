import { Inputs, useEffect, useMemo } from 'preact/hooks';

/**
 * Use memo that automatically calls .dispose() on the memoized result when it changes.
 */
export function useDisposableMemo<T extends { dispose(): void }>(fn: () => T, inputs: Inputs = []) {
  const result = useMemo(fn, inputs);
  useEffect(() => () => result.dispose(), [result]);
  return result;
}
