import type { Dayjs } from 'dayjs';
import { useCalendarAnimation, useEventRangeLoader } from './hooks';
import { dateForView } from './date-for-view';
import dayjs from 'dayjs';
import { CalendarNav, HeaderNav } from './header-nav';
import { View } from './types';
import { DayEvents, HoursSideBar } from './day-events';

type Props = {
  date: Dayjs;
  gotoView(view: View, date?: Dayjs): void;
};

export function DayView(props: Props) {
  const animation = useCalendarAnimation(props.date);
  const events = useEventRangeLoader(props.date, props.date.add(1, 'day'));
  const today = dateForView('day', dayjs());
  const title = props.date.format('dddd, MMMM D, YYYY');

  return (
    <section class="relative grow">
      <div class="flex flex-col gap-2 rounded-2xl border grow absolute inset-0 overflow-hidden">
        <HeaderNav view="day" gotoView={props.gotoView} showToday={!props.date.isSame(today)}>
          <CalendarNav
            onPrev={() => props.gotoView('day', props.date.add(-1, 'day'))}
            onNext={() => props.gotoView('day', props.date.add(1, 'day'))}
            title={title}
          />
        </HeaderNav>
        <header class="sm:hidden font-semibold border-b p-4 py-2">{title}</header>
        <section class="relative overflow-y-auto overflow-x-hidden">
          <div key={props.date} class={`flex pl-6 ${animation}`}>
            <HoursSideBar />
            <DayEvents events={events} day={props.date} />
          </div>
        </section>
      </div>
    </section>
  );
}
