// User-editable system message templates
import { courseLabel } from 'shared/terminology';
import { SystemMessage, SystemMessageTypes, TenantRow } from 'server/types';
import { Internationalize } from '../intl';
import { CourseWelcome, InvitationEmail, NewModuleAvailable } from 'server/mailer/templates';

export const getDefaultSystemMessages = (
  isBundle: boolean,
  intl: Internationalize,
  tenant: Pick<TenantRow, 'terminology'>,
): Record<SystemMessageTypes, SystemMessage> => {
  return {
    welcome: {
      title: intl(`Welcome to "{courseName:string}" with {guide:string}`, {
        courseName: isBundle ? '{{{bundle-name}}}' : '{{{course-name}}}',
        guide: '{{{guide-name}}}',
      }),
      content: CourseWelcome({ intl, isBundle }),
      isEnabled: true,
      isCustomized: false,
      type: 'welcome',
    },
    moduleStart: {
      title: intl('A new module, "{moduleTitle:string}" is available.', {
        moduleTitle: '{{{module-title}}}',
      }),
      content: NewModuleAvailable({ intl, isBundle }),
      isEnabled: true,
      isCustomized: false,
      type: 'moduleStart',
    },
    invitation: {
      title: intl(`You're invited to join my {courseOrBundle:string}: "{courseTitle:string}"`, {
        courseOrBundle: courseLabel({
          course: {
            isBundle,
          },
          intl,
          tenant,
        }),
        courseTitle: '{{{course-name}}}',
      }),
      content: InvitationEmail({ intl, isBundle }),
      isEnabled: true,
      isCustomized: false,
      type: 'invitation',
    },
  };
};
