import { AppRouter, LoadedProps, RouteLoadProps, useRouteParams } from '@components/router';
import { StudentPage } from '@course/components/student-page';
import { intl, useIntl } from 'shared/intl/use-intl';
import { rpx, RpxResponse } from 'client/lib/rpx-client';
import { DiscussionDetail } from './discussion-detail';
import { ListItem } from './list-item';
import { URLS } from 'shared/urls';
import { IcoChat, IcoPlus } from '@components/icons';
import { EmptyPage } from '@components/empty-page';
import { Case } from '@components/conditional';
import { NavBar } from './nav-bar';
import { StateUpdater, useState, Dispatch } from 'preact/hooks';
import { BtnPrimary, BtnSecondary } from '@components/buttons';
import { DiscussionForm } from './discussion-form';
import { genericDiscussionCategoryIds } from 'shared/consts';
import { showError } from '@components/app-error';
import { useStudentSearchModal } from '@course/components/student-search-modal';
import { SearchBox } from '@components/search-box';
import { defStudentRoute } from '@course/components/courses-app-router';

const store = rpx.discussions;

type Data = Awaited<ReturnType<typeof load>>;
type Category = RpxResponse<typeof store.getCategories>['categories'][0] & {
  noNewDiscussions?: boolean;
};
export type SelectedDiscussion = RpxResponse<typeof store.getDiscussion>;

export interface State extends Data {
  selectedDiscussion: Data['selectedDiscussion'];
}

export const studentDiscussions = {
  route: defStudentRoute({
    load,
    Page,
    authLevel: 'student',
  }),
};

export const studentDiscussionsByCategory = {
  route: defStudentRoute({
    Page,
    authLevel: 'student',
    load,
    key: ({ params }) => `discussions_${params.courseId}`,
    async loadSubroute(
      { params: { courseId, categoryId = genericDiscussionCategoryIds.all } },
      setState: Dispatch<StateUpdater<State>>,
    ) {
      const [feed, { discussions: pinnedDiscussions }] = await Promise.all([
        store.getDiscussions({
          courseId,
          categoryId,
        }),
        store.getDiscussions({
          courseId,
          categoryId,
          pinnedOnly: true,
        }),
      ]);
      setState((s) => ({
        ...s,
        selectedDiscussion: undefined,
        feed: {
          ...feed,
          discussions: [...pinnedDiscussions, ...feed.discussions],
        },
      }));
    },
  }),
};

export const studentDiscussion = {
  route: defStudentRoute({
    load,
    async loadSubroute(
      { router, params: { courseId, discussionId } },
      setState: Dispatch<StateUpdater<State>>,
    ) {
      if (!discussionId || discussionId === genericDiscussionCategoryIds.new) {
        setState((s) => ({ ...s, selectedDiscussion: undefined }));
        return;
      }
      const discussion = await getDiscussion({
        router,
        courseId,
        discussionId,
      });
      setState((s) => ({ ...s, selectedDiscussion: discussion }));
    },
    key: ({ params }) => `discussions_${params.courseId}`,
    Page,
    authLevel: 'student',
  }),
};

function Page({ router, state, setState }: LoadedProps<typeof load>) {
  const intl = useIntl();
  const { course, feed, categories, selectedDiscussion } = state;
  const { categoryId, discussionId } = useRouteParams();
  const showSearchModal = useStudentSearchModal(course, {
    initialType: 'discussion',
    listenCtrlKey: false,
  });

  const [isLoading, setIsLoading] = useState(false);

  const selectedCategory = categories.find((c) => c.id === categoryId) || categories[0];
  const newDiscussionUrl = URLS.student.discussion({
    course,
    categoryId: selectedCategory.id,
    discussionId: genericDiscussionCategoryIds.new,
  });
  const canCreateDiscussion =
    !course.restrictStudentDiscussions ||
    course.level === 'guide' ||
    course.level === 'facilitator';

  async function fetchMore() {
    setIsLoading(true);
    try {
      const newFeed = await store.getDiscussions({
        courseId: course.id,
        categoryId,
        cursor: feed.cursor,
      });
      setState((s) => ({
        ...s,
        feed: {
          discussions: [...s.feed.discussions, ...newFeed.discussions],
          hasMore: newFeed.hasMore,
          cursor: newFeed.cursor,
        },
      }));
    } catch (err) {
      showError(err);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <StudentPage
      course={course}
      accessLevel={course.accessLevel}
      currentLink="discussions"
      editLink={{
        url: selectedDiscussion?.lesson
          ? URLS.guide.lesson({
              courseId: course.id,
              lessonId: selectedDiscussion.lesson.id,
            })
          : URLS.guide.coursePage({
              courseId: course.id,
              page: 'discussions',
            }),
      }}
      sideNavContent={<NavBar course={course} categories={categories} />}
      documentTitle={selectedDiscussion?.prompt}
    >
      <div class="w-full lg:w-readable max-w-readable px-6">
        <Case
          when={discussionId !== genericDiscussionCategoryIds.new}
          fallback={
            <DiscussionForm
              categories={categories}
              initialCategoryId={categoryId}
              onSuccess={(newDiscussion) => {
                router.goto(
                  URLS.student.discussion({
                    course,
                    categoryId,
                    discussionId: newDiscussion.id,
                  }),
                );
              }}
            />
          }
        >
          {!selectedDiscussion && (
            <Case
              when={feed.discussions.length > 0}
              fallback={
                <EmptyPage
                  Ico={IcoChat}
                  title={intl('No discussions yet')}
                  description={
                    selectedCategory.id === genericDiscussionCategoryIds.all
                      ? intl('There are no active discussions in this course.')
                      : intl('There are no active discussions in this category.')
                  }
                  centeredDescription
                  actionText={canCreateDiscussion ? intl('Start a discussion') : undefined}
                  href={newDiscussionUrl}
                />
              }
            >
              <header class="mb-8">
                <div class="flex flex-col md:flex-row md:items-center justify-between mb-6 gap-2">
                  <h1 class="text-4xl leading-snug tracking-tight text-gray-900 dark:text-white">
                    {selectedCategory.title}
                  </h1>
                  {canCreateDiscussion && (
                    <BtnPrimary href={newDiscussionUrl}>
                      <IcoPlus class="w-4 h-4 mr-2" /> {intl('New Discussion')}
                    </BtnPrimary>
                  )}
                </div>
                <SearchBox
                  class="inline-ruz-input pl-9 text-sm  md:rounded-l-none cursor-pointer hover:border-indigo-400"
                  placeholder={intl('Search discussions')}
                  onClick={showSearchModal}
                />
              </header>
              {feed.discussions.map((discussion) => (
                <ListItem key={discussion.id} course={course} discussion={discussion} />
              ))}
              {feed.hasMore && (
                <div class="flex justify-center mt-4 mb-4">
                  <BtnSecondary isLoading={isLoading} onClick={fetchMore}>
                    {intl('Load More')}
                  </BtnSecondary>
                </div>
              )}
            </Case>
          )}
          {selectedDiscussion && (
            <DiscussionDetail
              key={selectedDiscussion.id}
              discussion={selectedDiscussion}
              categories={categories}
              course={course}
            />
          )}
        </Case>
      </div>
    </StudentPage>
  );
}

async function getDiscussion({
  router,
  courseId,
  discussionId,
}: {
  router: AppRouter;
  courseId: string;
  discussionId: string;
}) {
  try {
    return await store.getDiscussion({ discussionId });
  } catch (err) {
    // This is a hacky, but quick way to get 404 and other errors
    // to leave the student in a workable page rather than an error
    // state when they follow a link to a deleted discussion.
    router.rewrite(`/courses/${courseId}/discussions`);
    await new Promise((r) => setTimeout(r, 150));
    throw err;
  }
}

async function load(route: RouteLoadProps) {
  const { courseId, categoryId = genericDiscussionCategoryIds.all, discussionId } = route.params;
  const [course, feed, { discussions: pinnedDiscussions }, categoriesData, selectedDiscussion] =
    await Promise.all([
      rpx.courses.getStudentCourse({ id: courseId }),
      store.getDiscussions({
        courseId,
        categoryId,
      }),
      store.getDiscussions({
        courseId,
        categoryId,
        pinnedOnly: true,
      }),
      store.getCategories({ courseId }),
      discussionId && discussionId !== genericDiscussionCategoryIds.new
        ? getDiscussion({ router: route.router, courseId, discussionId })
        : Promise.resolve(undefined),
    ]);

  const categories: Category[] = [
    {
      id: genericDiscussionCategoryIds.all,
      title: intl('All Discussions'),
      color: '#9ca3af',
      numDiscussions: categoriesData.numDiscussions,
      noNewDiscussions: true,
    },
    {
      id: genericDiscussionCategoryIds.nonLessons,
      title: intl('General'),
      color: '#06b6d4',
      numDiscussions: categoriesData.numGeneralDiscussions,
    },
  ];
  if (!course.hideLessonDiscussionsCategory) {
    categories.push({
      id: genericDiscussionCategoryIds.lessons,
      title: intl('Lesson Discussions'),
      color: '#fbbf24',
      numDiscussions: categoriesData.numLessonDiscussions,
      noNewDiscussions: true,
    });
  }

  return {
    course,
    feed: {
      ...feed,
      discussions: [...pinnedDiscussions, ...feed.discussions],
    },
    selectedDiscussion,
    categories: [...categories, ...categoriesData.categories] as Category[],
  };
}
