/**
 * This module contains the logic for rendering a readonly minidoc. It uses
 * minidoc in readonly mode in order to ensure the minidoc cards
 * render properly.
 */
import { cardMiddleware, defaultToolbarActions, minidoc, minidocToolbar } from 'minidoc-editor';
import { ManualDom } from '@components/manual-dom';
import { generateMediaCard, mediaMiddleware } from '@components/media-card';
import { useDisposableMemo } from 'client/lib/hooks';
import { rawHtmlCard, embedHtmlCard } from '@components/raw-html-card';

interface Props {
  id?: any;
  content?: string;
  shouldRenderPdfViewer?: boolean;
  hideCaptions?: boolean;
  class?: string;
}

/**
 * Create a minidoc instance to render the read-only view.
 */
export function createReadonlyMinidoc({
  content = '',
  shouldRenderPdfViewer,
  hideCaptions,
}: {
  content?: string;
  shouldRenderPdfViewer?: boolean;
  hideCaptions?: boolean;
}) {
  return minidoc({
    /**
     * This is the document markup as a string.
     */
    doc: content,
    /**
     * This is a student view / guide preview. No editing!
     */
    readonly: true,
    /**
     * This is the minidoc middleware we're using.
     */
    middleware: [
      /**
       * Here is where we register the cards we want to use. Media is for files.
       * In the future, we'll register surveys, quizes, etc here.
       */
      cardMiddleware([
        generateMediaCard({
          shouldRenderPdfViewer,
          hideCaptions,
        }),
        rawHtmlCard,
        embedHtmlCard,
      ]),
      /**
       * Initialize the editor with textcolor support... We may want to do this
       * without requiring the entire toolbar middlware.
       */
      minidocToolbar(defaultToolbarActions.filter((a) => a.id === 'textcolor')),
      /**
       * This extends the editor to have an `insertMedia` method which is used
       * by the media toolbar action.
       */
      mediaMiddleware(),
    ],
  });
}

/**
 * The minidoc render component manages rendering a minidoc in read-only / student view.
 */
export function ReadonlyMinidoc({
  id,
  content,
  shouldRenderPdfViewer,
  hideCaptions,
  class: className = '',
}: Props) {
  const editor = useDisposableMemo(() => {
    const result = createReadonlyMinidoc({
      content,
      shouldRenderPdfViewer,
      hideCaptions,
    });
    const { origin } = window.location;

    result.root.querySelectorAll('a').forEach((el) => {
      const { href } = el;
      if (!href.startsWith(origin) || href.startsWith(`${origin}/files/`)) {
        el.target = '_blank';
        el.rel = 'noopener noreferrer';
      }
    });

    return result;
  }, [id]);

  return (
    <ManualDom
      el={editor.root}
      class={`minidoc-content prose prose-indigo dark:prose-invert font-studentcontent ${className}`}
    />
  );
}
