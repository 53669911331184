import { RuzcalMgmtPage } from './mgmt-page';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect } from 'preact/hooks';
import { PageContent, PageSection, defCalRoute } from './common';
import { rpx } from 'client/lib/rpx-client';
import { LoadedProps, RouteLoadProps } from '@components/router';
import { DayView } from './booking-views/day-view';
import { WeekView } from './booking-views/week-view';
import { MonthView } from './booking-views/month-view';
import { dateForView } from './booking-views/date-for-view';
import { AgendaView } from './booking-views/agenda-view';
import { nowLineId } from './booking-views/day-events';
import { IcoClipboard, IcoDownload, IcoSettings, IcoShare } from '@components/icons';
import { BtnBasicCopy, Button } from '@components/buttons';
import { ModalForm, showModalForm } from '@components/modal-form';
import { useTryAsyncData } from 'client/lib/hooks';
import { DefaultSpinner } from '@components/spinner';
import { ruzcal } from 'shared/urls';
import { Dropdown, MenuItem } from '@components/dropdown';
import { ComponentChildren } from 'preact';

type View = 'agenda' | 'day' | 'week' | 'month';

export const route = defCalRoute({ load, Page });

async function load(props: RouteLoadProps) {
  const host = await rpx.ruzcal.getHost({});
  if (!host) {
    props.router.goto('/calendar/onboarding');
  }
  const view = localStorage.ruzcalView || ('agenda' as View);

  // Attempt to get date from URL parameter if available, otherwise fallback to the current date
  const viewDate = props.params.date
    ? dayjs(props.params.date).isValid()
      ? dayjs(props.params.date)
      : dayjs()
    : dayjs();

  const savedDate = dateForView(view, viewDate);
  return { view, date: savedDate };
}

type Props = LoadedProps<typeof load>;

function BtnMeetingURL(props: { pathname: string; title: string; subtitle: ComponentChildren }) {
  return (
    <BtnBasicCopy
      value={location.origin + props.pathname}
      noIcon
      inset
      class="rounded-2xl border border-gray-300 hover:border-gray-400 p-4 transition-all"
    >
      <span class="flex gap-4">
        <span class="py-1">
          <IcoClipboard />
        </span>
        <span class="flex flex-col items-start text-left">
          <span class="font-semibold">{props.title}</span>
          <span>{props.subtitle}</span>
          <span class="text-nowrap overflow-hidden bg-gray-100 border px-2 text-xs rounded-md font-mono mt-3">
            {props.pathname}
          </span>
        </span>
      </span>
    </BtnBasicCopy>
  );
}

function showShareModal() {
  showModalForm(({ resolve }) => {
    const { data, isLoading } = useTryAsyncData(async () => {
      const [eventTypes, host] = await Promise.all([
        rpx.ruzcalEventTypes.getEventTypesList(),
        rpx.ruzcal.getHost({}),
      ]);
      return { eventTypes, host };
    }, []);
    const host = data?.host;
    return (
      <ModalForm
        onClose={resolve}
        title="Share your calendar"
        subtitle="Grab a booking URL and share it so that others can book meetings with you."
        hideCancel
        confirmButtonText="Close"
      >
        {isLoading && (
          <div class="flex items-center justify-center h-20">
            <DefaultSpinner />
          </div>
        )}
        {host && (
          <>
            <BtnMeetingURL
              title="Your public bookings URL"
              subtitle="Let your client choose to book any of your public meeting types."
              pathname={ruzcal.newBookingUrl({
                urlPrefix: host.urlPrefix,
                urlSuffix: '',
              })}
            />
            {data?.eventTypes.map((e) => {
              const pathname = ruzcal.newBookingUrl({
                urlPrefix: host.urlPrefix,
                urlSuffix: e.urlSuffix,
              });

              return (
                <BtnMeetingURL
                  key={e.id}
                  pathname={pathname}
                  title={e.name}
                  subtitle={
                    <>
                      {e.duration} minutes <span class="capitalize">{e.location}</span>
                    </>
                  }
                />
              );
            })}
          </>
        )}
      </ModalForm>
    );
  });
}

function Page({ state, setState, router }: Props) {
  useEffect(() => {
    /**
     * Redraw every 60 seconds to shift the live view, etc.
     */
    const interval = 60000;
    let timeout = setTimeout(function tick() {
      setState((s) => s);
      timeout = setTimeout(tick, interval);
    }, interval);
    return () => clearTimeout(timeout);
  }, []);
  useEffect(() => {
    // Remember the view for next time
    localStorage.ruzcalView = state.view;

    // Scroll the now line into view if it exists, otherwise, scroll the earliest event
    // into view.
    setTimeout(() => {
      const el = document.getElementById(nowLineId) || document.querySelector('.js-event');
      el?.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }, 100);
  }, [state.view]);

  const gotoView = (view: View, date: Dayjs = dayjs()) => {
    // Save current  date interval as a URL parameter
    router.rewrite(`?date=${encodeURIComponent(date.format('YYYY-MM-DD'))}`);
    setState((s) => ({ ...s, view, date: dateForView(view, date) }));
  };

  return (
    <RuzcalMgmtPage
      title="Calendar"
      currentPage="bookings"
      actions={
        <>
          <Button
            class="p-1 px-3 inline-flex items-center rounded-full transition-all hover:bg-gray-100 gap-2 border"
            onClick={showShareModal}
          >
            <IcoShare />
            Share
          </Button>
          <div class="hidden sm:inline-flex">
            <Dropdown
              noPadding
              triggerClass="p-1 px-3 rounded-full transition-all hover:bg-gray-100 gap-2 border"
              renderMenu={() => (
                <>
                  <MenuItem target="_blank" href="/export/calendar/bookings.ics" regularCase>
                    <IcoDownload />
                    Download iCalendar file
                  </MenuItem>
                  <MenuItem href="/calendar/integrations" regularCase>
                    <IcoSettings />
                    Synchronization options
                  </MenuItem>
                </>
              )}
            >
              Export
            </Dropdown>
          </div>
        </>
      }
    >
      <PageContent w="max-w-5xl w-full mx-auto">
        <PageSection grow>
          {state.view === 'day' && <DayView date={state.date} gotoView={gotoView} />}
          {state.view === 'week' && <WeekView date={state.date} gotoView={gotoView} />}
          {state.view === 'month' && <MonthView date={state.date} gotoView={gotoView} />}
          {state.view === 'agenda' && <AgendaView gotoView={gotoView} />}
        </PageSection>
      </PageContent>
    </RuzcalMgmtPage>
  );
}
