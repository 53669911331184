import { Comment } from 'server/types';
import { showError } from '@components/app-error';
import { rpx } from 'client/lib/rpx-client';
import { unique } from 'shared/utils';
import { StateUpdater } from 'preact/hooks';
import { Dispatch } from 'react';

const store = rpx.comments;

export interface State {
  /**
   * The id of the discussion whose comments are being displayed.
   */
  discussionId: UUID;

  /**
   * The id of the course guide.
   */
  guideId: UUID;

  /**
   * The ordered list of root comment ids.
   */
  rootCommentIds: UUID[];

  /*
   * All the parent and sub comments are stored in this <id, Comment> dictionary.
   */
  comments: Record<UUID, Comment>;

  /**
   * The cursor returned from the previous fetch.
   */
  cursor?: string;

  /*
   * This indicates whether the feed is fully loaded or not.
   */
  hasMore: boolean;

  /*
   * This is true when we're loading the initial or next page of the feed.
   */
  loading: boolean;

  /*
   * This removes any links to the people page when true.
   */
  hidePeople: boolean;
}

type SetState = Dispatch<StateUpdater<State>>;

const PAGE_SIZE = 50;

export const initialState: State = {
  discussionId: '',
  guideId: '',
  comments: {},
  rootCommentIds: [],
  hasMore: false,
  loading: false,
  hidePeople: false,
};

export function deleted(setState: SetState, id: UUID) {
  setState((state) => {
    const comment = state.comments[id];
    const parent = comment.parentId && state.comments[comment.parentId];
    const comments = { ...state.comments };
    const result = { ...state, comments };
    delete comments[id];
    if (parent) {
      comments[parent.id] = { ...parent, replyIds: parent.replyIds?.filter((r) => r !== id) };
    } else {
      result.rootCommentIds = result.rootCommentIds.filter((r) => r !== id);
    }
    return result;
  });
}

export function saved(setState: SetState, comment: Comment) {
  setState((state) => {
    const isNew = !state.comments[comment.id];
    const comments = { ...state.comments };
    const parent = comment.parentId && comments[comment.parentId];

    comments[comment.id] = comment;

    if (isNew && parent) {
      const replyIds = [...(parent.replyIds || []), comment.id];
      comments[parent.id] = { ...parent, replyIds };
    }

    return {
      ...state,
      rootCommentIds:
        isNew && !parent ? [comment.id, ...state.rootCommentIds] : state.rootCommentIds,
      comments,
    };
  });
}

export function loaded(
  setState: SetState,
  opts: {
    comments: Comment[];
    hasMore?: boolean;
    cursor: State['cursor'];
  },
) {
  setState((state) => {
    const { comments, hasMore, cursor } = opts;
    return {
      ...state,
      rootCommentIds: unique([
        ...state.rootCommentIds,
        // Extracts reply ids
        ...comments.filter((r) => !r.parentId).map((r) => r.id),
      ]),
      // Convert our array of comments into a dictionary of comments.
      comments: comments.reduce((acc, r) => {
        acc[r.id] = r;
        return acc;
      }, state.comments),
      hasMore: hasMore ?? state.hasMore,
      cursor,
    };
  });
}

export function toggleLike(setState: SetState, opts: { id: UUID; isLiked: boolean }) {
  setState((state) => {
    const { id, isLiked } = opts;
    const comment = state.comments[id];
    return {
      ...state,
      comments: {
        ...state.comments,
        [id]: { ...comment, isLiked, likeCount: comment.likeCount + (isLiked ? 1 : -1) },
      },
    };
  });
}

/**
 * Loads next page of the comments.
 */
export async function loadNextPage(state: State, setState: Dispatch<StateUpdater<State>>) {
  const { discussionId } = state;
  setState((s) => ({ ...s, loading: true }));

  try {
    const result = await store.getComments({
      discussionId,
      limit: PAGE_SIZE,
      cursor: state.cursor,
    });
    loaded(setState, result);
  } catch (err) {
    if (err.data?.type !== 'noPreview') {
      showError(err);
    }
  } finally {
    setState((s) => ({ ...s, loading: false }));
  }
}
