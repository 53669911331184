import { errRedirect } from '@components/router';
import type { RouteLoadProps, PageDef } from '@components/router/async-router';
import { rpx } from 'client/lib/rpx-client';
import { processRouteParams } from './process-route-params';

/**
 * A cache of courseId -> CSS
 */
export const courseStyleCache: Record<string, string> = {};

/**
 * A regex for detecting v1 URLs.
 */
const v1CoursePattern = /^\/courses\/[0-9]+(\/|$)/;

/**
 * As part of page loading, if there's a courseId, we'll pre-fetch
 * that course's CSS and cache it so there's no flicker when we
 * render the page.
 */
async function prefetchCourseStyles(courseId: string) {
  if (!courseId || courseStyleCache[courseId]) {
    return;
  }
  const res = await fetch(`/api/course_css/${courseId}.css`);
  const css = await res.text();
  courseStyleCache[courseId] = css;
}

/**
 * Preprocess routes before the router kicks in in order to
 * handle legacy v1 routes.
 */
async function processV1URLs() {
  if (!v1CoursePattern.test(location.pathname)) {
    return;
  }

  const { newUrl } = await rpx.courses.getV1Redirect({ v1Url: location.href });
  if (newUrl) {
    throw errRedirect(newUrl, true);
  }
}

/**
 * For course pages, if the load function throws a 403, we want to redirect
 * to a course-specific 403 page that allows quick mimicking.
 */
function onPageLoadError(props: RouteLoadProps, err: any) {
  if (props.params.courseId && err.statusCode === 403 && !err.data?.redirectTo) {
    const prefix =
      props.url.startsWith('products') || props.url.startsWith('manage/products')
        ? 'products'
        : 'courses';
    throw errRedirect(
      `/${prefix}/${props.params.courseId}/not-authorized?redirect=${encodeURIComponent(
        location.href,
      )}`,
    );
  }
}

/**
 * Define a route for the courses app.
 *
 * - Handle v1 URLs
 * - Handle certain page load errors
 */
export function defCoursesRoute<T>(def: PageDef<T>) {
  const { load } = def;

  // Add preprocessing before full page routes load.
  const wrappedLoad: any = async (props: RouteLoadProps) => {
    // Detect v1 requests and redirect
    await processV1URLs();
    // Perform the underlying load, and also load the course-specific CSS
    // if there is any and it hasn't already been cached.
    try {
      const [result] = await Promise.all([
        load?.(props),
        prefetchCourseStyles(props.params.courseId),
      ]);
      return result;
    } catch (err) {
      onPageLoadError(props, err);
      throw err;
    }
  };

  def.load = wrappedLoad;

  return def;
}

/**
 * Define routes for student pages. This will preprocess the route
 * params to handle slugs, load the course CSS, etc.
 */
export function defStudentRoute<T>(def: PageDef<T>) {
  def.processParams ??= processRouteParams;
  return defCoursesRoute(def);
}
