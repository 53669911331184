/*
 * This module contains the meat of the editor. The rich text editor being used here
 * is minidoc, which is not yet documented.
 *
 * Minidoc is an extensible rich content editor. It is a light-weight sanitization layer
 * on top of the browser-native contenteditable. The editor can be extended via middleware
 * or via cards. Middleware adds new functionality to the editor itself (e.g. adding new
 * methods or behaviors). Cards add new rich content types to the editor (e.g. video,
 * polls, quizzes).
 *
 * A minidoc instance has a `root` property which is the root element of the editor. This
 * is the contenteditable DOM element, and needs to be manually inserted into the DOM wherever
 * is appropriate for your usecase. The place it is inserted should be free from React / Preact
 * changes (see ManualDom usage below).
 *
 * The toolbar, if desired, is a mixin. If used, it adds a `toolbar` property to the editor,
 * and the toolbar has its own root DOM element which must be manually inserted into the DOM
 * `editor.toolbar.root`.
 */

import { ManualDom } from '@components/manual-dom';
import { generateMediaCard, mediaMiddleware } from '@components/media-card';
import { cardMiddleware, defaultToolbarActions, minidocToolbar, placeholder } from 'minidoc-editor';
import { ComponentChildren } from 'preact';
import { Course, Lesson } from './types';
import { useBasicAutosaver } from '@components/autosaver';
import { useEffect, useMemo, useState } from 'preact/hooks';
import { Toggle } from '@components/toggle';
import { toolbarDropdown } from '@components/toolbar-dropdown';
import { rawHtmlCard, embedHtmlCard } from '@components/raw-html-card';
import { keyboardEditorToTitle } from 'client/lib/minidoc';
import { showError } from '@components/app-error';
import { useMinidoc } from '@components/minidoc';
import { DownloadsEditor, emptyState } from './downloads';
import { AssessmentType, Downloads } from 'server/types';
import { substateUpdater } from 'client/lib/hooks';
import {
  IcoBan,
  IcoChartBar,
  IcoChat,
  IcoChevronDown,
  IcoChevronUp,
  IcoDotsVertical,
  IcoDownload,
  IcoDuplicate,
  IcoFile,
  IcoList,
  IcoQuestion,
  IcoTrash,
  redoArrow,
  undoArrow,
} from '@components/icons';
import { AssignmentEditor } from './assignment-editor';
import { AutosizeText } from '@components/autosize-text';
import { SaveStatus } from '@components/save-status';
import { Dropdown } from '@components/dropdown';
import { Button } from '@components/buttons';
import { AssessmentEditor } from './assessment-editor';
import { showToast } from '@components/toaster';
import { EditorWrapper } from '@components/minidoc/minidoc-root';
import { isNetworkError } from 'client/lib/ajax';
import { useIntl } from 'shared/intl/use-intl';
import { useCurrentTenant } from '@components/router/session-context';
import { DiscussionEditor } from './discussion-editor';
import { generateUUID } from 'shared/utils';
import { captureException } from 'client/lib/sentry';
import { showDialog } from '@components/dialog';

interface Props {
  course: Course;
  lesson: Lesson;
  onSave(lesson: Omit<Lesson, 'type' | 'moduleId'>): Promise<unknown>;
  onDeleteLesson(id: UUID): void;
  onCopyLesson(id: UUID): void;
  showOutlineButton: boolean;
  onOutlineClick(): void;
}

export interface DiscussionState {
  id: UUID;
  prompt?: string;
  description?: string;
  required: boolean;
  isEnabled: boolean;
}

interface SaveState {
  id: UUID;
  title: string;
  discussion?: DiscussionState;
  content: string;
  isPrerequisite: boolean;
  assessmentType?: AssessmentType;
  downloads?: Downloads;
  mediaFiles: UUID[];
}

function initialState(lesson: Lesson): SaveState {
  return {
    id: lesson.id,
    title: lesson.title,
    discussion: lesson.discussion,
    isPrerequisite: !!lesson.isPrerequisite,
    content: lesson.content || '',
    assessmentType: lesson.assessmentType,
    downloads: lesson.downloads,
    mediaFiles: lesson.mediaFiles || [],
  };
}

// A simple regex to match UUID patterns
const uuidRegex = /^[\w]{8}-[\w]{4}-[\w]{4}-[\w]{4}-[\w]{12}$/i;

// Takes a file URL in `/files/:id.[extension]` format
// and returns the file ID if it's a valid UUID
function fileURLToID(url: string) {
  const id = url.split('/')[2].split('.')[0];
  if (uuidRegex.test(id)) {
    return id;
  }
}

// Extract media files from the lesson content
function extractMediaFiles(content: string) {
  try {
    const parser = new DOMParser();
    const document = parser.parseFromString(content, 'text/html');
    const ids = Array.from(document.querySelectorAll('video,audio')).map((el) => {
      const mediaState = JSON.parse(el.getAttribute('data-state') || '{}');
      if (mediaState?.url) {
        // Takes a file URL in `/files/:id.[extension]` format
        // and returns the file ID if it's a valid UUID
        // return mediaState.url.split('/')[2].split('.')[0];
        const fileId = fileURLToID(mediaState.url);
        return fileId;
      }
    });
    return ids.filter((x) => !!x) as UUID[];
  } catch (e) {
    // Log the error to Sentry and fall back to an empty array
    console.error(e);
    captureException(e);
    return [];
  }
}

const btnFooterClass =
  'text-gray-100 hover:text-gray-100 hover:bg-gray-800 p-2 py-1 rounded-full relative inline-flex items-center cursor-pointer capitalize';

function BtnFooter({
  Icon,
  isActive,
  tooltip,
  children,
  onClick,
}: {
  Icon?: typeof IcoChat;
  isActive?: boolean;
  tooltip?: string;
  onClick?(): void;
  children: ComponentChildren;
}) {
  return (
    <Button data-tooltip={tooltip} onClick={onClick} class={btnFooterClass}>
      {Icon && (
        <Icon
          class={`w-8 h-8 mr-2 rounded-full p-2 ${isActive ? 'bg-green-500' : 'bg-gray-600'}`}
        />
      )}
      {children}
    </Button>
  );
}

export function MenuItemFooter({
  isSelected,
  onClick,
  children,
}: {
  isSelected?: boolean;
  onClick: () => void;
  children: ComponentChildren;
}) {
  return (
    <Button
      type="button"
      class={`inline-flex items-center px-4 py-2 text-left text-sm leading-5 hover:bg-gray-600 focus:outline-hidden focus:bg-gray-500 rounded ${
        isSelected ? 'text-green-400' : 'text-gray-100'
      }`}
      role="menuitem"
      onClick={onClick}
    >
      {children}
    </Button>
  );
}

function Divider(props: { class?: string }) {
  return (
    <span class={`${props.class || ''} flex items-center`}>
      <span class="border-r border-gray-500 inline-flex h-6"></span>
    </span>
  );
}

export function LessonEditor(props: Props) {
  const intl = useIntl();
  const { terminology } = useCurrentTenant();
  const [state, setState] = useState<SaveState>(initialState(props.lesson));
  const [showMobileToolbar, setShowMobileToolbar] = useState(false);
  const [showDownloads, setShowDownloads] = useState(
    () => !!state.downloads?.files.some((x) => x.isEnabled),
  );
  const [toolbarEnabled, setToolbarEnabled] = useState(true);

  const setDownloads = useMemo(
    () =>
      substateUpdater(
        setState,
        (s) => s.downloads || emptyState,
        (s, downloads) => ({ ...s, downloads }),
      ),
    [setState],
  );

  const disableDownloads = () => {
    setDownloads((xs) => ({
      ...xs,
      files: xs.files.map((x) => ({ ...x, isEnabled: false })),
    }));
    setShowDownloads(false);
  };

  const setAssessmentType = async (type?: AssessmentType) => {
    if (props.lesson.hasAssessmentSubmissions) {
      showToast({
        type: 'warn',
        title: 'Assessment type cannot be changed',
        message: `You can't switch the assessment type because the current assessment has student submissions already.`,
      });
      return;
    }

    const hasSwitchedToDifferentType =
      !!type && !!state.assessmentType && type !== state.assessmentType;
    if (hasSwitchedToDifferentType) {
      const confirmed = await showDialog({
        mode: 'warn',
        title: 'Change assessment type?',
        children: `A ${terminology.lesson} can have only one assessment. Do you want to change the assessment type from ${state.assessmentType} to ${type}?`,
        confirmButtonText: 'Change assessment type',
      });

      if (!confirmed) {
        return;
      }
    }
    setState((s) => ({ ...s, assessmentType: type }));
  };

  const editor = useMinidoc({
    doc: state.content,
    autoFocus: !!state.title,
    middleware: () => [
      placeholder(
        `Add the information you’d like your students to learn, here! You can type text, choose formatting, or insert images, videos and documents.`,
      ),
      minidocToolbar([
        {
          id: 'undo',
          label: 'Undo',
          html: undoArrow(),
          init() {},
          run() {
            editor.undo();
          },
        },
        {
          id: 'redo',
          label: 'Redo',
          html: redoArrow(),
          init() {},
          run() {
            editor.redo();
          },
        },
        ...defaultToolbarActions.filter((x) => x.id !== 'h1'),
        {
          id: 'hr_insert',
          label: 'Horizontal Rule',
          html: `<span>━</span>`,
          init() {},
          run() {
            const root = editor.root;
            root.focus();
            const range = document.getSelection()?.getRangeAt(0);
            if (!range) {
              return;
            }
            let leaf = range?.startContainer as HTMLElement | null;
            while (leaf && leaf.parentElement !== root) {
              leaf = leaf.parentElement;
            }
            if (leaf) {
              leaf.insertAdjacentHTML('beforebegin', '<hr />');
            }
          },
        },
        toolbarDropdown({
          intl,
        }),
      ]),
      cardMiddleware([
        generateMediaCard({
          shouldRenderPdfViewer: true,
        }),
        rawHtmlCard,
        embedHtmlCard,
      ]),
      mediaMiddleware(),
    ],
    onChange(doc) {
      setState((s) => ({ ...s, content: doc }));
    },
  });

  /**
   * Create a new instance of minidoc editor whenever the lesson
   * changes.
   */
  useEffect(() => {
    editor.root.addEventListener('keydown', (e: any) =>
      keyboardEditorToTitle(e, editor, '.js-edit-lesson-title textarea'),
    );
  }, [editor]);

  function saveLesson(val: SaveState) {
    return props.onSave({
      ...val,
      mediaFiles: extractMediaFiles(val.content),
    });
  }

  const autosaver = useBasicAutosaver(state, (val) => {
    const promise = saveLesson(val);
    promise.catch((err) => {
      if (!isNetworkError(err)) {
        showError(err);
      }
    });
    return promise;
  });

  const focusEditor = () => {
    (editor.root as any).focus();
  };

  return (
    <>
      <div
        class={`sticky top-0 bg-gray-100 rounded-md flex-col sm:flex-row lesson-editor-toolbar flex sm:items-center z-20 px-2 md:whitespace-nowrap`}
      >
        <span class={`inline-flex ${props.showOutlineButton ? '' : 'lg:hidden'} gap-2`}>
          <Button
            class="minidoc-toolbar-btn relative whitespace-nowrap hidden sm:inline-flex items-center gap-2 font-semibold border-r border-gray-300"
            data-tooltip="Show Course Outline"
            onClick={props.onOutlineClick}
          >
            <span>
              <IcoList />
            </span>
            <span class="hidden md:static">Outline</span>
          </Button>
          <Divider class="opacity-25 md:hidden" />
          <Button
            class="inline-flex gap-2 px-2 items-center md:hidden"
            onClick={() => setShowMobileToolbar((visible) => !visible)}
          >
            Formatting {showMobileToolbar ? <IcoChevronUp /> : <IcoChevronDown />}
          </Button>
        </span>
        <Divider
          class={`opacity-25 hidden md:inline-flex ${
            props.showOutlineButton ? '' : 'lg:hidden'
          } ml-2`}
        />
        <div
          class={`${showMobileToolbar ? 'flex' : 'hidden'} sm:flex grow justify-center relative`}
        >
          <ManualDom el={editor.toolbar.root} />
          {!toolbarEnabled && <div class="absolute inset-0 bg-gray-50/50 z-20 rounded-full"></div>}
        </div>
        {
          // Hack: If the outline button is hidden, this pushes the toolbar over on large screens. We use
          // absoulute positioning so that the toolbar ends up being centered.
        }
        <span
          class={`hidden ${
            props.showOutlineButton ? '' : 'lg:absolute right-2 inset-y-0'
          } lg:flex items-center ml-auto`}
        >
          <Divider class="opacity-25 ml-2" />
          <SaveStatus isDirty={autosaver.isDirty} isConnected={autosaver.isConnected} />
        </span>
      </div>
      <div class="w-full max-w-2xl mx-auto pt-10 pb-32">
        <AutosizeText
          containerClass="js-edit-lesson-title text-3xl font-semibold font-studentcontent leading-snug w-full -mx-2"
          focusSelf={!state.title}
          onFocusInCapture={() => setToolbarEnabled(false)}
          onFocusOutCapture={() => setToolbarEnabled(true)}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              e.preventDefault();
              focusEditor();
            } else if (e.code === 'ArrowDown') {
              setTimeout(() => {
                // This is a hacky approximation of detecting when the
                // user presses the down arrow on the last line of the
                // textarea. It's not 100%, but it's better than nothing.
                const ta = e.target as HTMLTextAreaElement;
                if (ta.selectionStart === ta.value.length) {
                  focusEditor();
                }
              });
            }
          }}
          class="border-0 ring-2 ring-transparent focus:ring-2 focus:ring-indigo-600 p-2 hover:ring-indigo-400 rounded-sm"
          placeholder={`Type your ${terminology.lesson} title here.`}
          onInput={(e: any) => setState((s) => ({ ...s, title: e.target.value }))}
          value={state.title}
        />
        <EditorWrapper
          editor={editor}
          class="leading-7 pt-4 pb-10 font-studentcontent text-base w-full cursor-text text-gray-700"
        />

        {props.lesson.type === 'full' &&
          (state.assessmentType === 'quiz' || state.assessmentType === 'poll') && (
            <AssessmentEditor
              lesson={props.lesson}
              isQuiz={state.assessmentType === 'quiz'}
              hide={() => setState((s) => ({ ...s, assessmentType: undefined }))}
            />
          )}
        {state.assessmentType === 'assignment' && (
          <AssignmentEditor
            lesson={props.lesson}
            hide={() => setState((s) => ({ ...s, assessmentType: undefined }))}
          />
        )}
        <DownloadsEditor
          isVisible={showDownloads}
          onCancel={disableDownloads}
          downloads={state.downloads || emptyState}
          setDownloads={setDownloads}
          editor={editor}
        />
        {state.discussion && state.discussion.isEnabled && (
          <DiscussionEditor
            state={state.discussion}
            setState={(discussion) => {
              setState((s) => ({
                ...s,
                discussion: {
                  ...s.discussion!,
                  ...discussion,
                },
              }));
            }}
          />
        )}
      </div>
      <footer class="fixed lg:sticky bottom-0 md:bottom-12 left-0 right-0 an-slide-up md:p-4 z-40 flex items-center justify-center">
        <nav class="bg-gray-700 drop-shadow-2xl shadow-md mx-auto md:rounded-full an-slide-up p-1 pr-2 space-x-4 flex flex-wrap lg:flex-nowrap justify-center">
          <BtnFooter
            Icon={IcoDownload}
            isActive={showDownloads}
            tooltip={showDownloads ? 'Disable downloadable content' : 'Add downloadable content'}
            onClick={() => (showDownloads ? disableDownloads() : setShowDownloads(true))}
          >
            Downloads
          </BtnFooter>
          <Dropdown
            hideDownIcon
            position="bottom-10 -right-10"
            bg="bg-gray-700"
            renderMenu={() => (
              <div class="flex flex-col text-gray-100 space-y-2 p-2">
                <MenuItemFooter
                  isSelected={state.assessmentType === 'quiz'}
                  onClick={() => setAssessmentType('quiz')}
                >
                  <IcoQuestion class="w-4 h-4 opacity-75 mr-2" /> Quiz
                </MenuItemFooter>
                <MenuItemFooter
                  isSelected={state.assessmentType === 'poll'}
                  onClick={() => setAssessmentType('poll')}
                >
                  <IcoChartBar class="w-4 h-4 opacity-75 mr-2" /> Poll
                </MenuItemFooter>
                <MenuItemFooter
                  isSelected={state.assessmentType === 'assignment'}
                  onClick={() => setAssessmentType('assignment')}
                >
                  <IcoFile class="w-4 h-4 opacity-75 mr-2" /> Assignment
                </MenuItemFooter>
                <MenuItemFooter
                  isSelected={state.assessmentType === undefined}
                  onClick={() => setAssessmentType(undefined)}
                >
                  <IcoBan class="w-4 h-4 opacity-75 mr-2" /> None
                </MenuItemFooter>
              </div>
            )}
          >
            <BtnFooter
              Icon={IcoChartBar}
              isActive={!!state.assessmentType}
              tooltip="Add quiz, poll, or assignment"
            >
              Assessment
            </BtnFooter>
          </Dropdown>
          <BtnFooter
            Icon={IcoChat}
            isActive={state.discussion?.isEnabled}
            tooltip={
              state.discussion?.isEnabled ? 'Disable discussions' : 'Allow students to comment'
            }
            onClick={() =>
              setState((s) => ({
                ...s,
                discussion: s.discussion
                  ? { ...s.discussion, isEnabled: !s.discussion?.isEnabled }
                  : {
                      id: generateUUID(),
                      required: false,
                      isEnabled: true,
                    },
              }))
            }
          >
            {terminology.discussions}
          </BtnFooter>
          <Divider />
          <label
            class={btnFooterClass}
            data-tooltip={`Require students to complete this ${terminology.lesson} before viewing subsequent modules.`}
          >
            <Toggle
              checked={state.isPrerequisite}
              onClick={() => setState((s) => ({ ...s, isPrerequisite: !s.isPrerequisite }))}
              class="h-4 w-8 mr-2"
            />
            Required
          </label>
          <Divider />

          <Dropdown
            hideDownIcon
            position="bottom-10 -right-10"
            bg="bg-gray-700"
            renderMenu={() => (
              <div class="flex flex-col text-gray-100 space-y-2 p-2">
                <MenuItemFooter onClick={() => props.onCopyLesson(state.id)}>
                  <IcoDuplicate class="w-4 h-4 opacity-75 mr-2" />
                  Copy {terminology.lesson}
                </MenuItemFooter>
                <MenuItemFooter onClick={() => props.onDeleteLesson(state.id)}>
                  <IcoTrash class="w-4 h-4 opacity-75 mr-2" />
                  Delete {terminology.lesson}
                </MenuItemFooter>
              </div>
            )}
          >
            <BtnFooter
              Icon={IcoDotsVertical}
              isActive={!!state.assessmentType}
              tooltip={`Copy or delete this ${terminology.lesson}`}
            >
              Actions
            </BtnFooter>
          </Dropdown>
        </nav>
      </footer>
    </>
  );
}
