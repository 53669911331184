import { FormGroup } from '@components/async-form';
import { UserLevel } from 'server/types';

export function UserLevelFormField({
  level,
  currentUser,
  onChange,
}: {
  level: UserLevel;
  currentUser?: { level: UserLevel };
  onChange?: (level: UserLevel) => void;
}) {
  return (
    <FormGroup prop="level">
      <label class="space-y-1">
        <span>Role</span>
        <select
          value={level}
          class="ruz-input"
          name="level"
          onChange={(e: any) => onChange?.(e.target.value as UserLevel)}
        >
          <option value="student">Student</option>
          <option value="guide">Guide</option>
          <option value="admin">Admin</option>
          {currentUser?.level === 'superadmin' && <option value="superadmin">Superadmin</option>}
        </select>
      </label>
    </FormGroup>
  );
}
