import { CheckoutForm } from '@course/components/checkout';
import { useState } from 'preact/hooks';
import { useAsyncEffect } from 'client/utils/use-async-effect';
import { ProgressBar } from '@components/progress-bar';
import { showError } from '@components/app-error';
import { useIntl } from 'shared/intl/use-intl';
import { PurchaseRow } from 'server/types';
import { useRouter } from '@components/router';

type Props = {
  purchase?: PurchaseRow;
  /**
   * Provision the purchased item (account, membership, etc), and return the
   * URL to which the user should be redirected.
   */
  provision(): Promise<string>;
};

export function ProvisioningForm(props: Props) {
  const intl = useIntl();
  const [isProcessing, setIsProcessing] = useState(true);
  const [progress, setProgress] = useState(0);
  const router = useRouter();
  const { purchase } = props;

  const provision = async () => {
    try {
      const result = props.provision();
      // This is a total hack, but the experience is improved, as it prevents
      // a brief flicker, and gives the user confirmation of their purchase
      // before passing them along to the final page.
      for (let i = 0; i <= 100; i += 10) {
        setProgress(i);
        await new Promise((r) => setTimeout(r, 50));
      }
      await new Promise((r) => setTimeout(r, 1000));
      await router.redirectTo(await result);
    } catch (err) {
      showError(err);
      setIsProcessing(false);
    }
  };

  useAsyncEffect(provision, []);

  if (!purchase) {
    return <p>{intl('Could not find a valid purchase.')}</p>;
  }

  if (isProcessing) {
    return (
      <div class="flex flex-col gap-4">
        <p>{intl('Please wait while we finish setting things up...')}</p>
        <ProgressBar showPercent={false} progress={progress} />
      </div>
    );
  }

  return (
    <CheckoutForm actionText={intl('Try Again ⤑')} onSubmit={provision}>
      <p>{intl('An error occurred while setting up your account.')}</p>
    </CheckoutForm>
  );
}
