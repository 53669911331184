import * as TestimonialBlock from './testimonials-block';
import * as FocusedTestimonialBlock from './testimonial-block';
import * as FAQBlock from './faq-block';
import * as ContentBlockBlock from './content-block';
import * as AboutMeBlock from './about-me-block';
import * as SplitBlock from './split-block';
import * as ChecklistBlock from './checklist-block';

export * as TestimonialBlock from './testimonials-block';
export * as FocusedTestimonialBlock from './testimonial-block';
export * as FAQBlock from './faq-block';
export * as ContentBlock from './content-block';
export * as AboutMeBlock from './about-me-block';
export * as SplitBlock from './split-block';
export * as ChecklistBlock from './checklist-block';

import { SalesBlockDefinition } from './types';

function buildDefinitions(definitions: SalesBlockDefinition[]) {
  return definitions.reduce((acc, b) => {
    acc[b.type] = b;
    return acc;
  }, {} as Record<string, SalesBlockDefinition>);
}

/*
 * Note on block definitions: these are expected to be in a certain structure, in
 * the course copier logic. (see server/dbx/courses.ts `copyCourse`)
 * If you are updating block definitions, this may break the copier.
 */
export const blockDefinitions = buildDefinitions([
  ChecklistBlock,
  FocusedTestimonialBlock,
  SplitBlock,
  TestimonialBlock,
  ContentBlockBlock,
  FAQBlock,
  AboutMeBlock,
]);
