import { UserLevel } from 'server/types';

const userAuthLevels: Record<UserLevel, number> = {
  student: 0,
  guide: 1,
  admin: 2,
  superadmin: 3,
};

/**
 * Generate a cryptographically secure random string of between min and
 * max values in a browser-compatible implementation.
 */
export function randomString(min: number, max: number) {
  const size = Math.floor(Math.random() * (max - min + 1) + min);
  const passwordArr = new Uint8Array(size);
  crypto.getRandomValues(passwordArr);
  const binString = Array.from(passwordArr, (byte) => String.fromCodePoint(byte)).join('');
  return btoa(binString).replaceAll('=', '').slice(0, size);
}

export function hasLevel(user: { level: UserLevel } | undefined, wanted: UserLevel) {
  if (!user) {
    return false;
  }

  const currentLevel = userAuthLevels[user.level];
  const wantedLevel = userAuthLevels[wanted];

  return currentLevel >= wantedLevel;
}
