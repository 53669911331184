import { Toggle } from '@components/toggle';
import { Field, InputField, eventToState } from '../form-helpers';
import type { State, SetState, Duration } from './types';
import { pluralize } from 'shared/formatting';

type Props = {
  state: State;
  setState: SetState;
};

function DurationInput(props: {
  title: string;
  subtitle?: string;
  name: string;
  duration: Duration;
  onChange(value: Duration): void;
}) {
  const numVal = parseInt(props.duration.value, 10);
  return (
    <Field name={props.name} title={props.title} subtitle={props.subtitle}>
      <section>
        <div class="border border-gray-300 rounded-sm inline-flex">
          <input
            name={props.name}
            inputMode="numeric"
            value={props.duration.value}
            onInput={(e: any) => props.onChange({ ...props.duration, value: e.target.value })}
            class="px-4 p-3 border-0 focus:z-10 focus:ring-2 transition-all rounded-sm ring-indigo-500 w-16 text-center text-sm"
          />
          <select
            value={props.duration.unit}
            onInput={(e: any) => props.onChange({ ...props.duration, unit: e.target.value })}
            class="px-4 p-3 pr-10 border-0 focus:z-10 focus:ring-2 transition-all ring-indigo-500 cursor-pointer bg-gray-50 border-l border-gray-300 rounded-r-sm text-sm"
          >
            <option value="minute">{pluralize('Minute', numVal)}</option>
            <option value="hour">{pluralize('Hour', numVal)}</option>
            <option value="day">{pluralize('Day', numVal)}</option>
          </select>
        </div>
      </section>
    </Field>
  );
}

export function LimitsTab({ state, setState }: Props) {
  return (
    <div class="flex flex-col gap-8 max-w-2xl">
      <DurationInput
        title="Reserve time before the event"
        name="bufferMinsBefore"
        duration={state.bufferMinsBefore}
        onChange={(value) => setState((s) => ({ ...s, bufferMinsBefore: value }))}
      />

      <DurationInput
        title="Reserve time after the event"
        name="bufferMinsAfter"
        duration={state.bufferMinsAfter}
        onChange={(value) => setState((s) => ({ ...s, bufferMinsAfter: value }))}
      />

      <DurationInput
        title="Minimum notice for scheduling and rescheduling"
        name="minNotice"
        duration={state.minNotice}
        onChange={(value) => setState((s) => ({ ...s, minNotice: value }))}
      />

      <section class="flex flex-col gap-4">
        <label class="flex gap-3 items-center cursor-pointer">
          <Toggle
            checked={state.hasDailyLimit}
            onClick={() =>
              setState((s) => ({
                ...s,
                hasDailyLimit: !s.hasDailyLimit,
                maxPerDay: s.hasDailyLimit ? '0' : '4',
              }))
            }
          />
          <span>Limit how many events can be booked in a day</span>
        </label>
        {state.hasDailyLimit && (
          <InputField
            name="maxPerDay"
            inputMode="numeric"
            value={state.maxPerDay}
            onInput={eventToState(setState)}
            inputWidth="w-14"
            suffix="events"
          />
        )}
      </section>
      <section class="flex flex-col gap-4">
        <label class="flex gap-3 items-center cursor-pointer">
          <Toggle
            checked={!state.isPrivate}
            onClick={() => setState((s) => ({ ...s, isPrivate: !s.isPrivate }))}
          />
          <span>Show this event type on my public page</span>
        </label>
      </section>
    </div>
  );
}
