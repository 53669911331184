/**
 * When there is no store content (e.g. on the first run, or after all
 * sections are deleted and the guide refreshes), this is the page we'll show.
 */

import { BtnPrimary } from '@components/buttons';
import { IcoSlides } from '@components/icons';

export function EmptyScreen({ onCreate }: { onCreate(): void }) {
  return (
    <div class="flex gap-6">
      <IcoSlides class="w-20 h-20 p-4 bg-indigo-100 text-indigo-400 rounded-full" />
      <div class="flex flex-col gap-3">
        <h1 class="text-2xl ">Storefront</h1>
        <p>
          The storefront is the marketing page for your site. You can add any courses or content you
          like to it.
        </p>
        <footer>
          <BtnPrimary onClick={onCreate}>Create Storefront</BtnPrimary>
        </footer>
      </div>
    </div>
  );
}
