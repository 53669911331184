import { BtnPrimary } from '@components/buttons';
import { StandardDialog } from '@components/dialog';
import { showModalForm } from '@components/modal-form';

export function showUpgradeToProModal() {
  return showModalForm(({ resolve }) => {
    return (
      <StandardDialog title="Ruzuku Pro Plan" onClose={resolve}>
        <section class="flex flex-col gap-4">
          <p>
            Offer eBooks, services, downloadable content, and other non-course products with Ruzuku
            Pro. Upgrade to Ruzuku Pro to start creating products now.
          </p>

          <div
            style="padding:62.46% 0 0 0;position:relative;"
            class="border rounded-lg overflow-hidden mb-4"
          >
            <iframe
              src="https://player.vimeo.com/video/1006725403?h=83196e9838&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
              style="position:absolute;top:0;left:0;width:100%;height:100%;"
              title="Ruzuku products (September 2024)"
            ></iframe>
          </div>
          <footer class="flex items-center gap-4">
            <BtnPrimary
              href="/account/billing?coreBilling=true"
              class="rounded-full px-4"
              onClick={resolve}
            >
              Upgrade to Pro
            </BtnPrimary>
            <a href="https://ruzuku.appointlet.com/s/ruzuku-pro-consultation-with-peter/peter-smiley">
              Or book a consultation to learn more
            </a>
          </footer>
        </section>
      </StandardDialog>
    );
  });
}
