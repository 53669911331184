/**
 * Forms for managing external calendars (Google, ics URLs, etc)
 */

import { showDialog } from '@components/dialog';
import { IcoCalendar } from '@components/icons';
import { rpx } from 'client/lib/rpx-client';
import { IntegrationRow } from './integration-row';
import { useRouter } from '@components/router';
import { showToast } from '@components/toaster';
import { fieldError } from 'shared/utils';

type Props = {
  hasGoogle: boolean;
  disconnectGoogle(): void;
  externalIcalUrl: undefined | string;
  setExternalIcalUrl(value: string): void;
};

export function ExternalCalendarForm(props: Props) {
  const router = useRouter();
  return (
    <>
      {!props.externalIcalUrl && (
        <IntegrationRow
          name="Google Calendar"
          icon={
            <span class="bg-blue-500 text-white rounded-full flex items-center justify-center aspect-square shrink-0 size-10 text-xl font-semibold">
              G
            </span>
          }
          description={
            props.hasGoogle
              ? `Your Google Calendar is connected.`
              : `Connect your Google Calendar to prevent double-booking.`
          }
          isInstalled={props.hasGoogle}
          onIntegrate={() => {
            router.goto('/calendar/oauth-google?init=true');
          }}
          onDisconnect={async () => {
            await rpx.google.disconnect();
            props.disconnectGoogle();
          }}
        />
      )}

      {!props.hasGoogle && (
        <IntegrationRow
          name="External Calendar"
          icon={
            <span class="bg-blue-500 text-white rounded-full flex items-center justify-center aspect-square shrink-0 size-10 text-xl font-semibold">
              <IcoCalendar class="size-5" />
            </span>
          }
          description={
            props.externalIcalUrl
              ? `Your calendar at ${new URL(props.externalIcalUrl).hostname} is connected.`
              : `If your calendar provides an ics URL, you can connect it here to prevent double-booking.`
          }
          isInstalled={!!props.externalIcalUrl}
          onIntegrate={() => {
            showDialog({
              mode: 'info',
              title: 'Connect your calendar',
              children: (
                <label class="flex flex-col gap-1">
                  <span>Enter your calendar's ics URL here.</span>
                  <input
                    class="ruz-input"
                    name="externalIcalUrl"
                    defaultValue={props.externalIcalUrl}
                    placeholder="https://example.com/cal.ics"
                  />
                </label>
              ),
              confirmButtonText: 'Connect',
              async onSubmit(opts) {
                const externalIcalUrl = opts.externalIcalUrl.trim();
                if (!externalIcalUrl) {
                  throw fieldError({
                    field: 'externalIcalUrl',
                    message: 'The URL cannot be empty.',
                  });
                }
                await rpx.ruzcal.saveExternalIcalUrl({ externalIcalUrl });
                props.setExternalIcalUrl(externalIcalUrl);
                showToast({
                  type: 'ok',
                  title: 'Calendar connected',
                  message: 'Your calendar was successfully connected.',
                });
              },
            });
          }}
          onDisconnect={async () => {
            await rpx.ruzcal.saveExternalIcalUrl({ externalIcalUrl: '' });
            props.setExternalIcalUrl('');
          }}
        />
      )}
    </>
  );
}
